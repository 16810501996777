import { Toast } from '../components/common';
import { useState, useEffect, createContext, useContext } from 'react';

const ToastContext = createContext();

const useToast2 = () => {
  const context = useContext(ToastContext);

  if (!context) {
    return {
      toast: null,
      showToast: () => { }
    }
  }

  return context;
}

const ToastProvider2 = ({ children }) => {
  const [toast, setToast] = useState(null);

  useEffect(() => {
    if (!toast) return;
    const timer = setTimeout(() => {
      setToast(null);
    }, 4000);

    return () => clearTimeout(timer);
  }, [toast]);

  const showToast = (props) => {
    setToast(props ? { ...props, show: true } : null);
  };

  const toastContextValue = {
    toast,
    showToast
  };

  return (
    <ToastContext.Provider value={toastContextValue}>
      {children}
      {toast && <Toast {...toast} />}
    </ToastContext.Provider>
  )
}

export { ToastProvider2, useToast2 };


