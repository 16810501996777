import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import './assets/scss/toastr.scss';

import { Suspense } from 'react';
import { publicRoutes, protectedRoutes } from './routes/app/routes';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage, Page404 } from './pages';
import Providers from './Providers';
import Authmiddleware from './routes/app/Authmiddleware';

export default function App() {
  return (
    <Providers>
      <Suspense fallback={true}>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          {/* routing */}
          <BrowserRouter>
            <Routes>
              {publicRoutes.map((route, idx) => (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <Authmiddleware isAuthProtected={false}>
                      {route.element}
                    </Authmiddleware>
                  }
                />
              ))}

              {protectedRoutes.map((route, idx) => (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <Authmiddleware isAuthProtected={true}>
                      {route.element}
                    </Authmiddleware>
                  }
                />
              ))}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </Suspense>
    </Providers>
  );
}
