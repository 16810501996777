import { Menu, Transition } from '@headlessui/react';
import { FolderIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../config/utils';
import { Fragment, useState, useRef, useEffect, useContext } from 'react';
import { HiEllipsisHorizontal } from 'react-icons/hi2';
import useAuthContext from '../../hooks/useAuthContext';
import { useMutation, useQueryClient } from 'react-query';
import { Skeleton } from '../../components/common';
import { useTranslation } from 'react-i18next';
import { putRenameFolder } from '../../services/library/LibraryService';
import { FolderContext } from '../../contexts/folderContext';
import { querys } from '../../pages/Library/tools/querys';
import { useToast2 } from '../../hooks/useToast2';
import { getTypeFilesByCategory } from '../../pages/Library/tools/types';
import { Tooltip } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

export function FolderItem({ item, id, zIndex = 0 }) {
  /* STATES */
  const [toggleRename, setToggleRename] = useState(false);
  const [rename, setRename] = useState({
    name: '',
    id,
    action: 'rename',
  });
  const [name, setName] = useState({
    prevName: item.name,
    newName: item.name,
  });
  const [error, setError] = useState(false);

  /* CONTEXT */
  const {
    idFolder,
    setIdFolder,
    currentFolder,
    setCurrentFolder,
    library,
    toggleArchive,
    foldersSelected,
    setFoldersSelected,
    refsNeverDeselectFolder,
    foldersLoading,
    addFoldersLoading,
    removeFoldersLoading,
    addFoldersSelected,
    removeFoldersSelected,
    checkMode,
    setCheckMode,
    setModals,
    setDeleteItemsProps,
    handleRemoveTempFolder,
  } = useContext(FolderContext);

  const active = foldersSelected.includes(id);

  /* REFS */
  const container = useRef(null);
  const inputRef = useRef(null);
  const menuRef = useRef(null);
  const clickTimerRef = useRef(false);

  /* HOOKS */
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isAgente = user?.role === 'Agente';

  const { createFolder, moveFolderQuery, deleteFolderQuery, changeStatusFolder } = querys();

  const {
    mutate: mutateCreateFolder,
    isLoading: isLoadingCreateFolder,
    error: errorCreateFolder,
    reset: resetCreateFolder,
  } = createFolder(library);

  const {
    mutate: mutateMoveFolder,
    isLoading: isLoadingMoveFolder,
    error: errorMoveFolder,
  } = moveFolderQuery(library);

  const {
    mutate: mutateDeleteFolder,
    isLoading: isLoadingDeleteFolder,
    error: errorDeleteFolder,
  } = deleteFolderQuery(library);

  const {
    mutate: mutateStatusFolder,
    isLoading: isLoadingStatusFolder,
    error: errorStatusFolder,
  } = changeStatusFolder(library);

  /* REACT QUERY */
  const { mutate, isLoading: isLoadingRenameFolder } = useMutation((opt) => putRenameFolder(opt), {
    onMutate: () => {
      addFoldersLoading(id);
    },
    onSuccess: (data) => {
      setTimeout(() => {
        setRename({
          action: 'rename',
          name: '',
          id,
        });
        setName((prev) => ({ ...prev, prevName: prev.newName }));
      }, 1000);
      removeFoldersLoading(id);
      queryClient.invalidateQueries(library);
    },
    onError: (err) => {
      setRename({
        action: 'rename',
        name: '',
        id,
      });
      showToast({ show: true, type: 'error', message: err });
      setError(true);
      removeFoldersLoading(id);
      queryClient.invalidateQueries(library);
    },
  });

  /* CONST */
  const menu = !toggleArchive
    ? [
      !toggleArchive && {
        id: 1,
        title: t('Library.menu.rename'),
        type: 'rename',
      },
      {
        id: 2,
        title: t('Library.menu.move'),
        type: 'move',
      },
      {
        id: 3,
        title: toggleArchive ? t('Library.menu.unarchive') : t('Library.menu.archive'),
        type: toggleArchive ? 'unarchive' : 'archive',
      },
      {
        id: 4,
        title: t('Library.menu.duplicate'),
        type: 'duplicate',
      },
      {
        id: 5,
        title: t('Library.menu.delete'),
        type: 'delete',
      },
    ]
    : [
      {
        id: 3,
        title: toggleArchive ? t('Library.menu.unarchive') : t('Library.menu.archive'),
        type: toggleArchive ? 'unarchive' : 'archive',
      },
      {
        id: 5,
        title: t('Library.menu.delete'),
        type: 'delete',
      },
    ];

  /* EFFECTS */
  useEffect(() => {
    //catch if item has a status
    if (item?.status === 'creating') {
      startRename();
    }

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        // back to name before try change it
        setName((prev) => ({ ...prev, newName: prev.prevName }));
      }, 2000);
    }
  }, [error]);

  useEffect(() => {
    if (errorCreateFolder) {
      showToast({ show: true, type: 'error', message: t('Message.folder.error.create') });
      resetCreateFolder();
      handleRemoveTempFolder();
      queryClient.invalidateQueries(library);
    }
  }, [errorCreateFolder]);

  useEffect(() => {
    setName({
      prevName: item.name,
      newName: item.name,
    });
    item.status === 'duplicating' && addFoldersLoading(id);
    item.status !== 'duplicating' && removeFoldersLoading(id);
  }, [item]);

  /* FUNCTIONS */
  const handleRename = () => {
    setToggleRename(false);
    removeFoldersSelected(id);
    if (name.newName.length === 0) return setName((prev) => ({ ...prev, newName: prev.prevName }));

    // si el nombre no cambió, no hacer nada
    if (name.newName === name.prevName && item.status !== 'creating') return;

    //escapar en caso de que exista otra carpeta con el mismo nombre en este nivel
    const coincident = currentFolder.children.some((e) => e.name === rename.name);
    if (coincident) {
      setError(true);
      showToast({ show: true, type: 'error', message: t('Message.folder.error.already_exists') });
      return;
    }
    if (item.status === 'creating') {
      //rename when creating a folder
      mutateCreateFolder({
        token: user.access_token,
        name: rename.name || item.name,
        parentId: idFolder,
        type: getTypeFilesByCategory(library),
      });
      return addFoldersLoading(id);
    }

    if (rename.name.length > 0) {
      //normal rename
      mutate({
        ...rename,
        token: user.access_token,
      });
    }

    if (!rename.name && item.status !== 'creating') handleRemoveTempFolder();
  };

  const startRename = () => {
    if (isAgente && library === 'corporative') return;
    setToggleRename(true);
    setRename({ ...rename, id });
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  };

  const generateName = () => {
    let count = 2;
    let nameGenerated = `${name.newName} (${count})`;
    let coincident = currentFolder.children.some((e) => e.name === nameGenerated);

    while (coincident) {
      count += 1;
      nameGenerated = `${name.newName} (${count})`;
      coincident = currentFolder.children.some((e) => e.name === nameGenerated);
    }

    return nameGenerated;
  };

  const duplicateFolder = () => {
    const newName = generateName();

    setCurrentFolder((prev) => {
      const newFolder = {
        id: 'temp',
        name: newName,
        parentId: item.parentId,
        children: [],
        status: 'duplicating',
        isInitialLoading: true,
      };
      return { ...prev, children: [...prev.children, newFolder] };
    });
    mutateCreateFolder({
      token: user.access_token,
      name: newName,
      parentId: idFolder,
      type: library,
    });
  };

  const handleMoveFolder = (idFolder) => {
    setCheckMode(true);
    setFoldersSelected([id]);
  };

  const switchSelected = () => {
    if (foldersSelected.includes(id)) return removeFoldersSelected(id);
    addFoldersSelected(id);
  };

  const handleClick = () => {
    // if the user arrive here from a search, remove searchParam from url
    if (location.search) navigate(location.pathname);
    
    if (checkMode) {
      switchSelected();
      return;
    }

    if (foldersLoading.includes(id) || toggleRename) return;
    if (!active) {
      setFoldersSelected([id]);
    }

    // single and double click handler
    if (clickTimerRef.current) {
      clearTimeout(clickTimerRef.current);
      clickTimerRef.current = null;
      handleDoubleClick();
    } else {
      clickTimerRef.current = setTimeout(() => {
        clickTimerRef.current = null;
        if (active && !toggleArchive) startRename();
      }, 400);
    }
  };

  const handleDoubleClick = () => {
    if (toggleArchive) return; //no dobleclick en archivados
    setIdFolder(id);
    setFoldersSelected([]);
  };

  const handleOnChangeName = (e) => {
    setName({ ...name, newName: e.target.value });
    setRename({ ...rename, name: e.target.value });
  };

  const hasFolderItemAncestor = (element) => {
    if (!element) return false;
    if (element.classList.contains('folderItem')) {
      return true;
    }
    return hasFolderItemAncestor(element.parentElement);
  };

  const handleDocumentClick = (event) => {
    // Verificar si se clickeo en cualquier instancia de este comp
    if (hasFolderItemAncestor(event.target)) return;

    const elementToCheck = [...refsNeverDeselectFolder, container, menuRef];
    let validate = false;
    elementToCheck.forEach((item) => {
      if (item.current?.contains(event.target)) {
        validate = true;
      }
    });

    if (!validate) {
      removeFoldersSelected(id); // desactivar al hacer clic fuera de él
      setCheckMode(false);
    }
  };

  const handleDelete = () => {
    setDeleteItemsProps((prev) =>
    ({
      ...prev,
      label: t('Library.the_folder'),
      params: {
        foldersSelected: [item.id],
        filesSelected: [],
        type: getTypeFilesByCategory(library),
      }
    }))
    setModals((prev) => ({...prev, deleteItems: true}))
  };

  const handleArchive = () => {
    mutateStatusFolder({ token: user.access_token, folderId: item.id, action: 'archive' });
    addFoldersLoading(id);
  };

  const handleUnarchive = () => {
    mutateStatusFolder({ token: user.access_token, folderId: item.id, action: 'unarchive' });
    addFoldersLoading(id);
  };

  return (
    <Tooltip label={name.newName} openDelay={1000}>
      <div
        ref={container}
        onClick={handleClick}
        className={classNames(
          'folderItem' /* para diferenciar este elemento de los demas */,
          item.color ? '' : 'bg-gray-200 border drop-shadow-md',
          'bg-gray-video flex space-x-2 2xl:space-x-3 items-center justify-start px-3 rounded-lg w-60 2xl:w-72 h-14 2xl:h-16 relative z-0',
          active && 'border-primary border-2',
          !foldersLoading.includes(id) && 'cursor-pointer',
        )}
        style={{ zIndex }}
      >
        {checkMode ? (
          <input
            type="checkbox"
            name="folder"
            id="folder"
            className="w-5 h-5 2xl:w-6 2xl:h-6 rounded-md border border-gray-400 active:border-primary/50 checked:bg-primary"
            checked={foldersSelected.includes(id)}
            onChange={switchSelected}
          />
        ) : (
          <FolderIcon
            className={classNames('w-6 h-6 ml-2', foldersLoading.includes(id) && 'text-gray-600')}
          />
        )}
        <div
          className={classNames(
            'flex justify-between items-center grow overflow-hidden',
            foldersLoading.includes(id) && 'text-gray-600',
          )}
        >
          {/* toggle the classname, to preserve the ref */}
          <input
            ref={inputRef}
            type="text"
            className={classNames(
              'border-none bg-gray-video focus:ring-0 focus:bg-blue-400/30 px-0 py-0 focus:text-black text-black font-bold text-sm 2xl:text-md w-full',
              toggleRename ? 'block' : 'hidden',
            )}
            placeholder={name.newName}
            name={name.newName}
            value={name.newName}
            onChange={handleOnChangeName}
            /* press enter to handleRename */
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRename();
              }
            }}
            onBlur={handleRename}
            disabled={foldersLoading.includes(id)}
          />
          <div
            className={classNames(
              'text-sm 2xl:text-md w-full font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden select-none',
              toggleRename ? 'hidden' : 'block',
              error && 'text-red-error',
              )}
              >
            {name.newName}
          </div>
        </div>
        <div className="h-full flex items-center ">
          <Menu as="div" className="relative inline-block text-left z-50">
            <div
              className={classNames(
                'h-full flex items-center rounded-full',
                !foldersLoading.includes(id) && !(isAgente && library === 'corporative') ? ' cursor-pointer hover:shadow-md' : 'opacity-40',
              )}
            >
              <Menu.Button
                className="inline-flex w-full h-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 "
                disabled={foldersLoading.includes(id) || (isAgente && library === 'corporative')}
                onClick={(e) => e.stopPropagation()}
              >
                <HiEllipsisHorizontal
                  className={classNames(
                    'text-3xl 2xl:text-4xl font-bold',
                    foldersLoading.includes(id) && 'text-gray-600',
                  )}
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={menuRef}
                className="absolute p-2 right-0 z-50 mt-2 w-36 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  {menu.map((item) => (
                    <Menu.Item key={item.id} className="rounded-md pl-6 hover:bg-gray-menu-shadow">
                      {({ active }) => (
                        <span
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            switch (item.type) {
                              case 'duplicate':
                                duplicateFolder();
                                break;
                              case 'move':
                                handleMoveFolder(item.id);
                                break;
                              case 'rename':
                                startRename();
                                break;
                              case 'archive':
                                handleArchive();
                                break;
                              case 'unarchive':
                                handleUnarchive();
                                break;
                              case 'delete':
                                handleDelete();
                                break;

                              default:
                                break;
                            }
                          }}
                        >
                          {item.title}
                        </span>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        {/* loader to rename or another async operation */}
        <div className="absolute h-full bottom-0 inset-x-0 -left-2 flex items-end overflow-hidden rounded-b-lg">
          <div
            className={classNames(
              'w-full transition-all duration-150',
              foldersLoading.includes(id) || error ? 'h-full' : 'h-0',
            )}
          >
            {foldersLoading.includes(id) && <Skeleton />}
            {error && <div className="w-full h-full animate-pulse bg-red-error" />}
          </div>
        </div>
      </div>
    </Tooltip>
  );
}
