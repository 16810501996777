import { compairArrays } from "../../../../config/utils";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom";

const tableContext = createContext();

const TableProvider = ({ children, ...rest }) => {
  // states
  const [selectedRow, setSelectedRow] = useState(rest.selectedItems.map(s => ({id: s.id})) ||
   []);

  // hooks
  const navigate = useNavigate();
  
  // effects
  useEffect(() => {
    rest.onSelect(selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    if (compairArrays(rest.selectedItems, selectedRow)) return;
    setSelectedRow(rest.selectedItems.map(s => ({id: s.id})));
  }, [rest.selectedItems]);

  // functions
  const handleButtonClick = (route) => {
    rest.onShow ? rest.onShow(route) : navigate(route);
  };

  const handleSelectRow = useCallback((id) => {
    setSelectedRow((prevSelectedRows) => {
      if (prevSelectedRows.some((c) => c.id === id)) 
        return prevSelectedRows.filter((c) => c.id !== id);

      if (rest.hasSelectable && !rest.hasCheckable)
        return [{id}];
      
      return [...prevSelectedRows, {id}];
    });
  }, []);

  const handleSelectedAll = (checked) => {
    checked
      ? // only select clients included in body elements representation
        setSelectedRow(rest.clients
          ?.filter((c) => rest.body
            .find((b) => b[0].id === c.id)
            .map(b => ({id: b.id}))
          ) || [])
      : setSelectedRow([]);
  };

  // returned value
  const value = useMemo(() => ({
    ...rest,
    selectedRow,
    setSelectedRow,
    handleButtonClick,
    handleSelectRow,
    handleSelectedAll,
  }), [
    rest,
    selectedRow,
    setSelectedRow,
    handleButtonClick,
    handleSelectRow,
    handleSelectedAll,
  ]);

  return (
    <tableContext.Provider value={value}>
      {children}
    </tableContext.Provider>
  )
}

export const useTable = () => {
  const context = useContext(tableContext);
  if (!context) {
    throw new Error('useTable must be used within a TableProvider');
  }
  return context;
}

export default TableProvider;