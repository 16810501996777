import ContactsTable from './ContactsStepElements/ContactsTable';
import ContactsStepProvider from './ContactsStepElements/context/ContactsStepContext';

export const ContactsStep = () => {
  return (
    <div
      className="w-full mt-3 2xl:mt-14"
      style={{
        height: 'calc(100vh - 160px)',
        '@media (minWidth: 1536px)': {
          // Tamaño para 2xl
          height: 'calc(100vh - 370px)',
        },
      }}
    >
      <ContactsStepProvider >
        <ContactsTable />
      </ContactsStepProvider>
    </div>
  );
};
