import { Button } from '@chakra-ui/react'
import Img404 from '../../assets/img/error.png'
import { useNavigate } from 'react-router-dom'
import { HOME } from '../../routes/app/paths';

const Page404 = ({obj}) => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex w-10/12 h-fit justify-center items-center">
        <div className="flex flex-col flex-1 gap-5 justify-center">
          <h1 className="text-5xl font-bold text-secondary-900">Error 404</h1>
          <span className="text-xl mb-10 font-semibold text-primary">La página que estás buscando no existe</span>
          <Button maxW={300} colorScheme='red' onClick={() => navigate(HOME)}>
            Volver al inicio
          </Button>
        </div>

        <div className="flex flex-col flex-1 gap-5">
          <img src={Img404} alt="404" className="mt-11 md:mt-5 z-10" />
        </div>
      </div>
    </div>
  )
}
export default Page404