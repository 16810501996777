import AuthContextProvider from './contexts/authContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastProvider } from './hooks/ToastManager';
import { ToastProvider2 } from './hooks/useToast2';
import { ChakraProvider } from '@chakra-ui/react';

export const queryClient = new QueryClient();
const isOpenRQD = import.meta.env.VITE_RQ_DEVTOOLS === 'true';

const Providers = ({ children }) => {
  return (
    <ChakraProvider>
      <ToastProvider>
        <ToastProvider2>
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>{children}</AuthContextProvider>
            {isOpenRQD && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </ToastProvider2>
      </ToastProvider>
    </ChakraProvider>
  );
};
export default Providers;
