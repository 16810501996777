import { EncryptStorage } from 'encrypt-storage';

// config
import { STORAGE_SECRET_KEY } from './env';

const options = {
  prefix: "@vvv",
  storageType: "localStorage",
  stateManagementUse: false
}

export const encryptStorage = new EncryptStorage(STORAGE_SECRET_KEY, options);