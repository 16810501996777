import { TemplateSelectorMini } from "../../../../components/video/templateLanding";
import { classNames, copyToClipboard, generateImageUrl } from "../../../../config/utils";
import { selectCreatedVideoData, selectFrontPage } from "../../../../features/videos/videosSlice";
import useAuthContext from "../../../../hooks/useAuthContext";
import { useToast2 } from "../../../../hooks/useToast2";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLink, AiOutlineMail } from "react-icons/ai";
import { HiOutlineCode } from "react-icons/hi";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { SendLandingLink } from "../../../../services/video/VideoService";
import { DOMAIN } from "../../../../routes/server/paths";

const Sidebar = ({ templateNumber, dataSelected, onDataChange, finishedRendering = false }) => {
  const { user } = useAuthContext();

  const [data, setData] = useState(dataSelected);

  // Por si cambia de cliente en la lista
  useEffect(() => {
    if (dataSelected) {
      setData(dataSelected);
    }
  }, [dataSelected]);

  const [emailSubject, setEmailSubject] = useState('');

  const { t } = useTranslation();
  const { showToast } = useToast2();

  const createdVideoData = useSelector(selectCreatedVideoData);

  const frontPage = useSelector(selectFrontPage);

  const buttonCopyRef = useRef(null);

  const tokenLanding = useMemo(() => {
    const tokens = createdVideoData?.sendingTo || [];
    const tokenData = tokens.find((token) => token.id == data.id);
    return tokenData?.token;
  }, [createdVideoData, data]);

  const mutationSendLandingLink = useMutation('SEND_LANDING_LINK', SendLandingLink, {
    onError: () => {
      showToast({ show: true, type: 'error', message: t('Message.video.error.send_email') });
    },
    onSuccess: (response) => {
      if (response.status == 200) {
        showToast({
          show: true,
          type: 'success',
          message: t('Message.video.success.send_email'),
        });
      }
    },
  });

  const handleChangeEmailSubject = (event) => {
    setEmailSubject(event.target.value);
  };

  const handleCopyLink = async () => {
    if (tokenLanding) {
      const response = copyToClipboard(`${window.location.origin}/landing/${tokenLanding}`);

      if (response) {
        showToast({ show: true, type: 'success', message: t('Message.video.copy_link') });
      } else {
        showToast({ show: true, type: 'error', message: t('Message.video.error.copy_link') });
      }
    }
  };

  const handleCopyLinkAndFrontPage = () => {
    if (frontPage?.file) {
      const domainParsed = DOMAIN.slice(0, -1);
      const url = `${domainParsed}${frontPage.file}`;

      const response = copyToClipboard(url);

      if (response) showToast({ show: true, type: 'success', message: t('Message.video.copy_link_frontpage') });
    }
  };

  const handleSendLandingLink = () => {
    if (tokenLanding) {
      const email = {
        correo: data.email,
        asunto: emailSubject,
        token: tokenLanding,
      };

      mutationSendLandingLink.mutate({ token: user.access_token, emailData: { emails: [email] } });
    }
  };

  return (
    <div className="w-full flex flex-col shadow-xl p-6 space-y-7 rounded-xl border-[1px] border-gray-200">
      <div className="flex space-x-2">
        <button
          className="p-2.5 py-1 flex items-center space-x-1 bg-primary text-gray-200 text-[10px] 2xl:text-xs rounded-xl cursor-pointer disabled:cursor-default disabled:bg-gray-400"
          title={finishedRendering ? null : t('Message.video.info.wait_rendering')}
          disabled={!finishedRendering || !tokenLanding}
          style={{ backgroundColor: finishedRendering ? '' : '#9ca3af' }}
          onClick={handleCopyLink}
        >
          <span className="whitespace-nowrap">Copiar enlace</span>
          <AiOutlineLink className="text-lg 2xl:text-xl" />
        </button>
        <button
          ref={buttonCopyRef}
          className={classNames("p-2.5 py-1 flex items-center space-x-1 bg-black text-gray-200 text-[10px] 2xl:text-xs rounded-xl cursor-pointer disabled:cursor-default", !frontPage.file && 'hidden')}
          title={finishedRendering ? null : t('Message.video.info.wait_rendering')}
          disabled={!finishedRendering}
          style={{ backgroundColor: finishedRendering ? '' : '#9ca3af' }}
          onClick={handleCopyLinkAndFrontPage}
        >
          <span className="whitespace-nowrap">{t('Video.copy_link_frontPage')}</span>
          <AiOutlineLink className="text-lg 2xl:text-xl" />
        </button>
      </div>
      <div className="flex flex-col space-y-5">
        <div className="flex space-x-3 w-full border-0 bg-gray-100 rounded-md p-3 py-2">
          <AiOutlineMail className="text-lg 2xl:text-xl" />
          <span className="text-xs 2xl:text-sm font-semibold text-gray-800">{t('Video.email')}</span>
        </div>
        <span className="text-xs 2xl:text-sm text-gray-800">
          {t('Video.send_info_email')}
        </span>
        <div className="flex flex-col space-y-1">
          <span className="text-xs 2xl:text-sm font-semibold text-gray-800">
            {t('Video.email_address')}
          </span>
          <input
            type="text"
            className="w-full border-0 bg-gray-100 text-sm rounded-md p-4 py-2 placeholder:text-gray-300"
            placeholder={t('Placeholder.write_email')}
            value={dataSelected?.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
              onDataChange({ ...data, email: e.target.value });
            }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-xs 2xl:text-sm font-semibold text-gray-800">{t('Video.subject')}</span>
          <input
            type="text"
            className="w-full border-0 bg-gray-100 text-sm rounded-md p-4 py-2 placeholder:text-gray-300"
            placeholder="Escribe el asunto..."
            value={emailSubject}
            onChange={handleChangeEmailSubject}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-xs 2xl:text-sm font-semibold text-gray-800">{t('Video.message')}</span>
          <div className="w-full flex justify-center items-center border-0 bg-gray-100 rounded-md p-3">
            <TemplateSelectorMini number={templateNumber} />
          </div>
        </div>
        <button
          className="p-6 py-2 w-fit cursor-pointer bg-primary select-none text-gray-100 text-xs 2xl:text-sm rounded-xl active:scale-[0.97]"
          title={finishedRendering ? null : t('Message.video.info.wait_rendering')}
          disabled={!finishedRendering}
          style={{ backgroundColor: finishedRendering ? '' : '#9ca3af' }}
          onClick={handleSendLandingLink}
        >
          {t('Video.send')}
        </button>
        <div className="space-x-3 w-full border-0 bg-gray-100 rounded-md p-3 py-2 hidden">
          <HiOutlineCode size={20} />
          <span className="text-sm font-semibold text-gray-800">{t('Video.imbibe')}</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar