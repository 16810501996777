import InsightsVideo from '../../components/insights/InsightsVideo';

export function VideoInsights() {
  const insights = [
    {
      id: 1,
      contact: 'Nombre Empresa',
      agent: 'Agustina',
      creationDate: '10/03/2023',
      visualizations: 15,
      clickCtas: 10,
      downloads: 20,
    },
    {
      id: 2,
      contact: 'Nombre Empresa',
      agent: 'Agustina',
      creationDate: '10/03/2023',
      visualizations: 11,
      clickCtas: 23,
      downloads: 12,
    },
    {
      id: 3,
      contact: 'Nombre Empresa',
      agent: 'Agustina',
      creationDate: '10/03/2023',
      visualizations: 32,
      clickCtas: 21,
      downloads: 12,
    },
  ];

  return (
    <>
      {/* <EquipVideos insights={insights} /> */}
      <InsightsVideo insights={insights} />
    </>
  );
}
