import { useState, useRef, useEffect } from 'react';
import { BiBookmark } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';
import { Skeleton } from '../common';
import { useMutation, useQueryClient } from 'react-query';
import { IoIosArrowDown } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const BoxOption = ({ icon, option, onClick, isAdding }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'relative flex w-fit items-center justify-between rounded-md px-3 py-1 space-x-2 bg-gray-200 text-gray-600 text-[13px] select-none whitespace-nowrap',
        onClick !== undefined && !isAdding 
          ? 'hover:bg-gray-300 hover:shadow-lg active:scale-[0.97] cursor-pointer'
          : 'pointer-event-none',
      )}
    >
      {icon}
      <span className={classNames('flex items-center', isAdding ? 'opacity-40 text-primary' : '')}>
        {option}
      </span>
      {isAdding && (
        <div className="absolute inset-0 -left-2">
          <Skeleton />
        </div>
      )}
    </div>
  );
};

const Option = ({ option, inputValue, isSelected, onClick, isAdding }) => {
  const {t} = useTranslation();
  const highlightedText = option.replace(
    new RegExp(`(${inputValue})`, 'gi'),
    `<strong class="${
      isAdding ? 'text-red-error' : isSelected ? 'text-white' : 'text-gray-700'
    }">$1</strong>`,
  );

  return (
    <div
      onClick={onClick}
      className={classNames(
        'px-4 py-2 select-none whitespace-nowrap',
        isAdding
          ? 'relative flex text-red-error cursor-not-allowed'
          : isSelected
          ? 'bg-gray-100 hover:bg-primary-lighter text-gray-600 font-semibold cursor-pointer'
          : 'bg-gray-100 hover:bg-primary-lighter text-gray-400 cursor-pointer',
      )}
    >
      <div dangerouslySetInnerHTML={{ __html: highlightedText }} />
      {isAdding && (
        <>
          <span className="ml-3 italic font-extralight">{t('Common.autocomplete_multiselect.adding')}</span>
          <div className="absolute inset-0">
            <Skeleton className="opacity-75" />
          </div>
        </>
      )}
    </div>
  );
};

const types = {
  list: {
    icon: null,
  },
  tag: {
    icon: <BiBookmark size={15} className="text-gray-700" />,
  },
};

export const AutocompleteMultiselect = ({
  placeholder = 'Escriba su texto aqui',
  listOptions = [],
  type = 'list',
  recents = [],
  recentsTitle,
  isLoading = false,
  onChange,
  onCreateOption,
  mutationConfig, // { mutationFn, mutationKey, token }
}) => {
  /* STATES */
  const [options, setOptions] = useState(listOptions);
  const [recentOptions, setRecentOptions] = useState(recents);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [highlightedOption, setHighlightedOption] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isAddingOption, setIsAddingOption] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const optionsContainerRef = useRef(null);
  const inputRef = useRef(null);
  const arrowRef = useRef(null);

  /* REACT QUERY */
  const queryClient = useQueryClient();
  const { mutate: addNewOptionMutation } = useMutation(mutationConfig?.mutationFn, {
    onSuccess: (data) => {
      const value = data.data.data.label;

      // Actualizar el estado local (listOptions) con el nuevo elemento
      queryClient.setQueryData(mutationConfig?.mutationKey, (prevData) => {
        if (prevData === undefined) return [data];
        return [...prevData, data];
      });

      setIsAddingOption((prevOptions) => {
        // Eliminar la opción correspondiente del estado después de un retraso
        setTimeout(() => {
          setIsAddingOption((prevOptions) => prevOptions.filter((option) => option !== value));
        }, 100);

        return [...prevOptions, value];
      });
    },

    onError: (error, variables) => {
      const value = variables[type].title;

      setIsAddingOption((prevOptions) => {
        setTimeout(() => {
          setIsAddingOption((prevOptions) => prevOptions.filter((option) => option !== value));
        }, 100);
        return [...prevOptions, value];
      });
      // eliminarlo de la lista de opciones y los seleccionados
      setOptions((prevOptions) => prevOptions.filter((option) => option !== value));
      setSelectedOptions((prevOptions) => prevOptions.filter((option) => option !== value));
    },
  });

  /* EFFECTS */
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (listOptions?.length) {
      setOptions(listOptions);
    }
  }, [listOptions]);

  useEffect(() => {
    if (!recents?.length) return;
    setRecentOptions((prev) => {
      const newRecents = [...prev];
      recents.forEach((recent) => {
        if (!newRecents.includes(recent) && !selectedOptions.includes(recent))
          newRecents.push(recent);
      });
      return newRecents;
    });
  }, [recents]);

  /* FUNCTIONS */
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
      onChange && onChange(selectedOptions.filter((selectedOption) => selectedOption !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
      onChange && onChange([...selectedOptions, option]);
    }
    setInputValue('');
    setShowOptions(false);

    if (!recentOptions.includes(option) && recents.includes(option)) {
      setRecentOptions([...recentOptions, option]);
    } else {
      setRecentOptions(recentOptions.filter((recentOption) => recentOption !== option));
    }
    setHighlightedOption(null);
    setHighlightedIndex(-1);

  };

  const handleCreateOption = () => {
    if (inputValue.trim() !== '' && !options.includes(inputValue)) {
      setOptions([...options, inputValue]);
      setSelectedOptions([...selectedOptions, inputValue]);
      onChange && onChange([...selectedOptions, inputValue]);
      setInputValue('');
      setShowOptions(false);

      if (onCreateOption !== undefined) onCreateOption(inputValue);

      if (mutationConfig !== undefined) {
        try {
          setIsAddingOption([...isAddingOption, inputValue]);
          addNewOptionMutation({
            token: mutationConfig?.token,
            [type]: {
              title: inputValue,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (inputValue !== '' && !options.includes(inputValue)) {
        handleCreateOption();
      } else if (highlightedOption !== null) {
        handleOptionClick(highlightedOption);
      } else if (options.length > 0) {
        optionsContainerRef.current?.focus();
      }
    }
  };

  const handleRecentClick = (option) => {
    handleOptionClick(option);
  };

  const handleDocumentClick = (event) => {
    if (
      !optionsContainerRef.current?.contains(event.target) &&
      !inputRef.current?.contains(event.target) &&
      !arrowRef.current?.contains(event.target)
    ) {
      setShowOptions(false); // Ocultar contenedor de opciones al hacer clic fuera de él
    }
  };

  return (
    <div>
      <div className="relative rounded-xl group select-none">
        <input
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onKeyUp={handleKeyPress}
          onKeyDown={handleKeyPress}
          onKeyPress={handleKeyPress}
          ref={inputRef}
          disabled={isLoading}
          className={classNames(
            isLoading && 'opacity-50',
            'w-full py-3 px-4 2xl:py-4border border-gray-300 rounded-xl focus:outline-none focus:ring-1 group-[inpuy] focus:ring-primary focus:border-primary focus:ring-opacity-50 placeholder:text-gray-300 duration-100 text-sm 2xl:text-md cursor-default',
            !isLoading && 'group-hover:border-gray-400',
          )}
          onFocus={() => setShowOptions(true)}
        />
        {(inputValue !== '' || showOptions) && (
          <div
            className="absolute w-full max-h-[280px] mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-50 overflow-y-auto"
            onKeyDown={(event) => {
              if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                inputRef.current.focus();
              }
            }}
            tabIndex={0}
            ref={optionsContainerRef}
            onBlur={() => {
              setHighlightedOption(null);
              setHighlightedIndex(-1);
              setShowOptions(false);
            }}
          >
            {inputValue !== ''
              ? options
                  .filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()))
                  .map((option, index) => (
                    <Option
                      key={index + 'optionMultiselect'}
                      option={option}
                      inputValue={inputValue}
                      isSelected={selectedOptions.includes(option)}
                      onClick={() => {
                        if (!isAddingOption.includes(option)) handleOptionClick(option);
                      }}
                      isAdding={isAddingOption.includes(option)}
                    />
                  ))
              : options.map((option, index) => (
                  <Option
                    key={index + 'optionMultiselect'}
                    option={option}
                    inputValue={inputValue}
                    isSelected={selectedOptions.includes(option)}
                    onClick={() => {
                      if (!isAddingOption.includes(option)) handleOptionClick(option);
                    }}
                    isAdding={isAddingOption.includes(option)}
                  />
                ))}
            {!options.includes(inputValue) && (
              <div
                onClick={handleCreateOption}
                className="flex items-center px-4 py-2 cursor-pointer hover:bg-primary-lighter bg-gray-100 "
              >
                <BsPlus size={30} className="text-gray-700 font-bold mr-2" /> {inputValue}
              </div>
            )}
          </div>
        )}
        <div
          ref={arrowRef}
          className="absolute right-0 top-0 pr-4 h-full flex items-center flex-row-reverse gap-x-2 pointer-events-none"
        >
          <IoIosArrowDown
            className={classNames(
              isLoading && 'opacity-50',
              'flex items-center w-6 h-6 text-gray-400',
              !isLoading && 'group-hover:text-gray-700 group-[input]-active:text-primary',
            )}
            onClick={() => {
              if (isLoading) return;
              setShowOptions((prev) => !prev);
            }}
          />
          {selectedOptions.length > 1 && (
            <span className="text-gray-400 text-xs">+{selectedOptions.length - 1}</span>
          )}
          {selectedOptions.length > 0 && (
            <BoxOption
              option={selectedOptions[0]}
              icon={types[type].icon || undefined}
              isAdding={isAddingOption.includes(selectedOptions[0])}
            />
          )}
        </div>
        <div className="-z-10 absolute inset-0">
          {isLoading && <Skeleton height={46} className="rounded-xl" />}
        </div>
      </div>

      {Array.isArray(recentOptions) && recentOptions.length > 0 && (
        <div className="mt-4 w-full">
          {recentsTitle !== undefined && (
            <h3 className="text-gray-500 text-sm 2xl:text-md font-semibold mb-2 select-none">
              {recentsTitle}
            </h3>
          )}
          <div className="w-full max-h-[130px] flex gap-3 flex-wrap transition-all duration-300 overflow-auto">
            {recentOptions
              .filter((x) => options.includes(x))
              .map((recentOption, index) => {
                return (
                  <BoxOption
                    key={index}
                    icon={types[type].icon || undefined}
                    option={recentOption}
                    onClick={() => handleRecentClick(recentOption)}
                    isAdding={isAddingOption.includes(recentOption)}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};
