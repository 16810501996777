import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles/TimeLine.css';
import SimulatedBars from './others/SimulatedBars';
import { Skeleton } from '@chakra-ui/react';
import { classNames } from '../../../config/utils';

// CUIDADO!!: Por ahora sera un pixel un segundo
const pixelsToDuration = (pixels) => {
  return pixels / 2;
};

// CUIDADO!!: Por ahora sera un segundo un pixel
const durationToPixels = (duration) => {
  return duration * 2;
};

const TimeLineItem = (props) => {
  const [isDragging, setIsDragging] = useState(false);

  const [cursor, setCursor] = useState('pointer');

  // Para cambiar el tamaño de un video por dlante y por detrás
  function handleResizeStart(event, resizeDirection) {
    event.preventDefault();

    // Este sería la posición donde el usuario comienza a maver el mouse para cambiar el tamaño de un li
    const startX = event.pageX;

    function handleResizeMove(event) {
      // Calcula cantidad de pixeles que se movio el mouse por la ventana desde la posición startX
      const diffX = event.pageX - startX;

      if (resizeDirection === 'left') {
        const restWidth = pixelsToDuration(props.width - diffX);

        // Para que no se pase del tamaño original y tampoco pueda ser menor a cero segundos
        const newStartSecond = props.item.endSecond - restWidth;

        if (newStartSecond >= props.item.originalStartSecond && props.width - diffX > 1) {
          props.handleCut(props.index, props.width - diffX, 'left', newStartSecond);
        } else {
          if (props.item.startSecond !== props.item.originalStartSecond)
            props.restoreStartSecond(props.index);
        }
      } else if (resizeDirection === 'right') {
        const restWidth = pixelsToDuration(props.width + diffX);

        // Para que no pueda ser menor que 0 segundo
        const newEndSecond = props.item.startSecond + restWidth;

        if (newEndSecond <= props.item.originalEndSecond && props.width + diffX > 1) {
          props.handleCut(props.index, props.width + diffX, 'right', newEndSecond);
        } else {
          if (props.item.endSecond !== props.item.originalEndSecond)
            props.restoreEndSecond(props.index);
        }
      }
    }

    /**
     * Esto es para agregar y eliminar el evento de la ventana para
     * que se cambie el tamaño, pero no se quede pegado cuando se suelte
     */
    function handleResizeEnd() {
      props.handleSyncChanges();
      document.removeEventListener('mousemove', handleResizeMove);
      document.removeEventListener('mouseup', handleResizeEnd);
    }

    document.addEventListener('mousemove', handleResizeMove);
    document.addEventListener('mouseup', handleResizeEnd);
  }

  const isLoading = props?.item?.isLoading;
  
  return (
    <li
      className={classNames("time_line_item", isLoading && 'pointer-events-none')}
      draggable
      onMouseDown={() => {
        props.handleSelect(props.index);
        setCursor('pointer');
      }}
      onDragStart={(e) => {
        setIsDragging(true);
        setCursor('move');
        props.handleDragStart(e, props.index);
      }}
      onDragOver={(e) => props.handleDragOver(e)}
      onDrop={(e) => props.handleDrop(e, props.index)}
      onDragEnd={(e) => {
        // Para guardar cuando se termine de cambiar de posición
        e.preventDefault();
        e.currentTarget.classList.remove('time_line-dragging');
        setIsDragging(false);
      }}
      style={{
        minWidth: props.width,
        borderColor: props.isSelected ? '#f73757' : 'grey',
        zIndex: props.isSelected ? 1 : 0,
        cursor: props.isSelected ? 'move' : 'pointer',
      }}
    >
      {!props.isSelected || (
        <div>
          <div
            className="time_line_item-resize resize_left"
            onMouseDown={(event) => {
              handleResizeStart(event, 'left');
              setCursor('pointer');
            }}
          ></div>
          <div
            className="time_line_item-resize resize_rigth"
            onMouseDown={(event) => {
              handleResizeStart(event, 'right');
              setCursor('pointer');
            }}
          ></div>
        </div>
      )}
      <Skeleton h='full' isLoaded={!isLoading} className='flex justify-center items-center overflow-hidden'>
        {props.item && (
          <SimulatedBars
            width={durationToPixels(props.item.duration > 3 ? props.item.duration - 3 : props.item.duration)}
            onSelectEvent={(e) => {
              e.preventDefault();
              props.handleSelect(props.index);
            }}
          />
        )}

        {!props.isSelected || (
          <input
            className="time_line_item-slider"
            type="range"
            min={props.item.startSecond}
            max={props.item.endSecond}
            onChange={props.handleTimeChange}
            onDoubleClick={props.handleTimeChange}
            onKeyDown={props.handleTimeChangeByKeyDown}
            value={props.currentTime || 0}
            step={0.1}
            style={{
              minWidth: props.width,
              zIndex: 2,
              cursor: cursor,
            }}
          />
        )}
      </Skeleton>
    </li>
  );
};

export default function TimeLine({
  videos,
  currentVideoIndex,
  currentTime,
  syncChanges,
  handleSelectItem,
  handleTimeChange,
  handleTimeChangeByKeyDown,
}) {
  const [draggedIndex, setDraggedIndex] = useState(null);

  const [editedVideos, setEditedVideos] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (videos) {
      setEditedVideos(videos);
      setCurrentIndex(currentVideoIndex);
    }
  }, [currentVideoIndex, videos]);

  // Esto es para solo sincronizar los cambios, en caso de que no se pase el valor se toma los estados como valor por defecto
  const handleSyncChanges = (changedVideos = editedVideos, index = currentIndex) => {
    syncChanges(changedVideos, index);
  };

  // Esto es para guardar el indice que se está moviendo para saber si se va a mover a otro indice o se mantiene en el lugar
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
    e.currentTarget.classList.add('time_line-dragging');
    setDraggedIndex(index);
  };

  // Para cambiar la posición de un video
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    e.preventDefault();

    setCurrentIndex(newIndex);
    // Si no vas a dejar el item en el mismo lugar
    if (newIndex !== draggedIndex) {
      // hace una copia de los items actuales con sus posiciones
      const newItems = [...editedVideos];
      // Tomo el elemento a mover
      const draggedItem = newItems[draggedIndex];
      // Elimino de su posición el elemento a mover
      newItems.splice(draggedIndex, 1);
      // Copio el elemento en su nueva posición
      newItems.splice(newIndex, 0, draggedItem);
      // Cambio el indice movido
      setDraggedIndex(newIndex);

      setEditedVideos(newItems);
      setCurrentIndex(newIndex);
      handleSyncChanges(newItems, newIndex);
    }
  };

  // Permite acualizar la duración de un video cuando este se corta por delante y po detras
  const handleCutItem = (index, newWidth, direction, value) => {
    if (editedVideos.length) {
      // Convierto los pixeles de ancho en la duración
      const newDuration = pixelsToDuration(newWidth);
      const newItems = [...editedVideos];
      let cutItem = newItems[index];
      cutItem.duration = newDuration;

      // Si se corta por la izquierda el segundo de inicio del video cambia
      if (direction === 'left') {
        cutItem.startSecond = value;
      } else if (direction === 'right') {
        cutItem.endSecond = value;
      }

      newItems.splice(index, 1);
      newItems.splice(index, 0, cutItem);

      setEditedVideos(newItems);
      setCurrentIndex(index);
    }
  };

  /**
   * Este metodo es para cuando se insista en volver el video al tamaño original por su inicio
   * no se pierdan los milisegundos que se perdían del inicio
   * @param {Number} index
   */
  const handleRestoreOriginalStartSecond = (index) => {
    const cutItem = editedVideos[index];
    const originalStartSecond = cutItem.originalStartSecond;
    const currentEndSecond = cutItem.endSecond;
    const newWidth = currentEndSecond - originalStartSecond;

    const newDuration = pixelsToDuration(newWidth);
    const newItems = [...editedVideos];
    cutItem.duration = newDuration;
    cutItem.startSecond = originalStartSecond;

    newItems.splice(index, 1);
    newItems.splice(index, 0, cutItem);

    setEditedVideos(newItems);
    setCurrentIndex(index);
  };

  /**
   * Este metodo es para cuando se insista en volver el video al tamaño original por su final
   * no se pierdan los milisegundos que se perdían del final
   * @param {Number} index
   */
  const handleRestoreOriginalEndSecond = (index) => {
    const cutItem = editedVideos[index];
    const originalEndSecond = cutItem.originalEndSecond;
    const currentStartSecond = cutItem.startSecond;
    const newWidth = originalEndSecond - currentStartSecond;

    const newDuration = pixelsToDuration(newWidth);
    const newItems = [...editedVideos];
    cutItem.duration = newDuration;
    cutItem.endSecond = originalEndSecond;

    newItems.splice(index, 1);
    newItems.splice(index, 0, cutItem);

    setEditedVideos(newItems);
    setCurrentIndex(index);
  };

  // Para eliminar un item de la lista
  const handleRemoveItem = (index) => {
    const newItems = [...editedVideos];
    newItems.splice(index, 1);
    setEditedVideos(newItems);
    setCurrentIndex(index);
    handleSyncChanges(newItems, index);
  };

  console.log(editedVideos)
  return (
    <div className="time_line">
      <ul className="time_line-list" onDragOver={(e) => e.preventDefault()}>
        {editedVideos.map((item, index) => (
          <TimeLineItem
            key={`timeLineItem${index}`}
            index={index}
            item={item}
            onRemoveItem={() => handleRemoveItem(item)}
            isSelected={index === currentVideoIndex}
            width={durationToPixels(item.duration)}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleCut={handleCutItem}
            handleSelect={() => handleSelectItem(index)}
            handleTimeChange={handleTimeChange}
            handleTimeChangeByKeyDown={handleTimeChangeByKeyDown}
            currentTime={currentTime}
            restoreStartSecond={handleRestoreOriginalStartSecond}
            restoreEndSecond={handleRestoreOriginalEndSecond}
            handleSyncChanges={handleSyncChanges}
          />
        ))}
      </ul>
    </div>
  );
}

TimeLineItem.propTypes = {
  handleDragStart: PropTypes.func.isRequired, // Función para poder mover li
  handleDragOver: PropTypes.func.isRequired,
  handleDrop: PropTypes.func,
  handleCut: PropTypes.func.isRequired, // Manejar el evento de cortar
  index: PropTypes.number.isRequired, // Para saber el indice del li
  onRemoveItem: PropTypes.func, // Para eliminar un item de la lista
  item: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired, // Para saber el ancho que se convierte a segundos
  isSelected: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  handleTimeChange: PropTypes.func,
  currentTime: PropTypes.number,
  handleTimeChangeByKeyDown: PropTypes.func,
  restoreStartSecond: PropTypes.func,
  restoreEndSecond: PropTypes.func,
  handleSyncChanges: PropTypes.func,
};

TimeLine.propTypes = {
  videos: PropTypes.array.isRequired,
  syncChanges: PropTypes.func.isRequired, // Permite sincronizar los cambios entre la línea de tiempo y el reproductor
  handleTimeChange: PropTypes.func,
  currentTime: PropTypes.number,
  currentVideoIndex: PropTypes.number,
  handleSelectItem: PropTypes.func,
  handleTimeChangeByKeyDown: PropTypes.func,
};
