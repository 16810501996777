import { useEffect, useRef, useState } from 'react';
import { FaPhone, FaPlay } from 'react-icons/fa';
import { MdOutlineEmail, MdOutlineFileDownload } from 'react-icons/md';
import { IoIosArrowForward } from 'react-icons/io';
import Logo from '../../../assets/img/logo-light.png';
import { BsWhatsapp } from 'react-icons/bs';
import { classNames, generateImageUrl } from '../../../config/utils';
import { useSelector } from 'react-redux';
import { selectFrontPage, templateSelected } from '../../../features/videos/videosSlice';
import { PlayerTemplate } from './elements/PlayerTemplate';
import { useTranslation } from 'react-i18next';
import SocialLinksTemplate from './elements/SocialLinksTemplate';

const FirstTemplateMini = () => {
  const mainRef = useRef(null);

  useEffect(() => {
    const container = mainRef.current.parentElement;
    const mainWidth = mainRef.current.offsetWidth;
    const newWidth = container.offsetWidth;

    /* escalar mainRef segun el width del contenedor */
    const onResize = () => {
      const scale = newWidth / mainWidth;
      mainRef.current.style.transform = `scale(${scale})`;
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div
      ref={mainRef}
      className="w-[346px] h-[262px] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end"
    >
      <div className="w-[700px] h-[700px] absolute top-[50%] bg-gray-400 rounded-full" />
      <div className="relative flex flex-col justify-center w-full h-[55%] bg-white m-8 z-10 rounded-xl">
        <div className="flex absolute w-full px-10 -top-[48px] justify-evenly">
          <span className="uppercase text-[11px] text-gray-400 font-bold scale-x-[1.1]">
            logo
          </span>
          <span className="uppercase text-[11px] text-gray-400 font-bold scale-x-[1.1]">logo</span>
        </div>
        <div
          className="flex justify-center items-center absolute bg-gray-600 h-4/6 m-10 rounded-md -top-[65px] "
          style={{ width: 'calc(100% - 80px)' }}
        >
          <div className="w-8 h-8 border-2 border-white rounded-full flex justify-center items-center">
            <FaPlay className="text-white ml-0.5" size={14} />
          </div>
        </div>
        <span className="absolute top-20 -mt-1 w-full uppercase font-semibold text-center text-gray-400">
          ¡Text!
        </span>
        <div className="w-full flex justify-center">
          <span className="absolute top-24 w-40 h-1 pt-[6px] mt-[4px] bg-gray-200" />
        </div>
        <div className="w-full px-16 absolute top-28 mt-1 flex justify-evenly">
          <div className="w-2/5 h-3 bg-primary rounded-full" />
          <div className="w-2/5 h-3 border-2 border-gray-200 rounded-full" />
        </div>
      </div>
    </div>
  );
};
const FirstTemplate = ({
  video,
  color = '7482F6',
  config,
  className = '',
  isSmall = false,
  selected = null,
}) => {
  const mainRef = useRef(null);
  const [menuShow, setMenuShow] = useState(false);
  const [dinamicTitle, setDinamicTitle] = useState(null);
  const portrait = useSelector(selectFrontPage);
  const { t } = useTranslation();
  const [imagesNotFound, setImagesNotFound] = useState({
    firstLogo: false,
    secondLogo: false,
    mainImage: false,
  });
  
  /* functions */
  const urlParsed = (url) => {
    return /^https?:\/\//.test(url) ? url : `http://${url}`;
  };

  const handleAddImgNotFound = (img) => {
    setImagesNotFound((prev) => ({ ...prev, [img]: true }));
  };

  /* effects */
  useEffect(() => {
    // check changes in images
    setImagesNotFound({
      firstLogo: false,
      secondLogo: false,
      mainImage: false,
    });
    if (config?.images?.firstLogo) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.firstLogo);
      img.onerror = () => handleAddImgNotFound('firstLogo');
    }
    if (config?.images?.secondLogo) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.secondLogo);
      img.onerror = () => handleAddImgNotFound('secondLogo');
    }
    if (config?.images?.mainImage) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.mainImage);
      img.onerror = () => handleAddImgNotFound('mainImage');
    }
  }, [config?.images]);

  selected &&
    useEffect(() => {
      // Puse esta condicional porque estaba dando error cuando no se especificaba el titulo de la landing que es opcional
      if (config?.texts?.title) {
        let title = config?.texts.title.split('*');
        const temp = title.map((e) => {
          if (e === '|NOMBRE|' || e === '|NAME|') {
            return selected.name;
          } else return e;
        });
        const temp2 = temp.join('');
        setDinamicTitle(temp2);
      } else {
        setDinamicTitle('');
      }
    }, [selected, config?.texts]);

  return (
    <div
      ref={mainRef}
      className={classNames(
        className,
        isSmall && '2xl:w-[430px] 2xl:h-[320px]',
        'w-[430px] h-[320px] 2xl:w-[800px] 2xl:h-[600px] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end transform origin-top-left shadow-lg',
      )}
    >
      <div className="overflow-hidden rounded-xl flex items-center justify-center" style={{ width: '100%', height: '100%' }}>
        <div
          className={`w-[200%] h-[230%] absolute top-[45%] rounded-full overflow-hidden flex justify-center items-start`}
          style={{ backgroundColor: '#' + color }}
        >
          {config?.images?.mainImage && !imagesNotFound.mainImage && (
            <img
              src={generateImageUrl(config.images.mainImage)}
              alt="Imagen de base"
              className="w-1/2 object-cover"
            />
          )}
        </div>
      </div>

      <div
        className={classNames(
          isSmall && '2xl:space-y-2',
          'w-full h-full absolute flex flex-col p-[12%] py-[3%] space-y-2 2xl:space-y-5 justify-end items-center',
        )}
      >
        <div className="relative flex flex-col justify-center items-center w-full h-[60%] bg-white z-10 rounded-3xl px-[16%]">
          <div
            className={classNames(
              isSmall && '2xl:space-y-2',
              'absolute w-full flex flex-col items-center bottom-0 px-[14%] pb-[3%] space-y-2 2xl:space-y-5',
            )}
          >
            {/* Logos */}
            <div className="flex w-full px-10 justify-evenly items-center">
              {config?.images?.firstLogo && !imagesNotFound.firstLogo && (
                <img
                  src={generateImageUrl(config?.images.firstLogo)}
                  alt="logo"
                  className="w-12 rounded-lg"
                />
              )}
              {config?.images?.secondLogo && !imagesNotFound.secondLogo && (
                <img
                  src={generateImageUrl(config?.images.secondLogo)}
                  alt="logo"
                  className="w-12 rounded-lg"
                />
              )}
            </div>

            {/* Video preview */}
            <div
              className="flex justify-center items-center overflow-hidden relative bg-gray-600 flex-grow w-full rounded-md"
              style={{ aspectRatio: '400/187' }}
            >
              <div
                className={classNames(
                  isSmall && '2xl:w-4 2xl:h-4',
                  `absolute z-20 w-4 2xl:w-8 2xl:p-1 h-4 2xl:h-8 border-2 border-white hover:border-[#${color}] rounded-full flex justify-center items-center scale-150 opacity-60 cursor-pointer`,
                )}
              >
                <FaPlay
                  className={classNames(
                    isSmall && '2xl:text-[10px]',
                    'text-white ml-0.5 2xl:ml-1 text-[10px] 2xl:text-[30px]',
                  )}
                />
              </div>
              <div className="absolute w-full h-full bg-gradient-to-t z-10 from-black via-gray-500 to-gray-500 opacity-50" />
              {portrait?.file && (
                <img
                  src={generateImageUrl(portrait.file)}
                  alt=""
                  className="w-full h-full object-cover select-none"
                />
              )}
            </div>

            {/* Texts */}
            <div
              className={classNames(
                isSmall && '2xl:space-y-0',
                'text-center flex flex-col 2xl:space-y-3',
              )}
            >
              <span
                className={
                  (isSmall && '2xl:text-[16px]',
                  'w-full font-bold text-[16px] 2xl:text-[24px] text-center text-gray-500')
                }
              >
                {config?.texts?.title || <span className="text-gray-400 italic">{t('Landing.hello')}</span>}
              </span>
              <div
                className={
                  (isSmall && '2xl:text-[10px]',
                  'w-full flex justify-center text-[10px] 2xl:text-[16px] text-gray-400')
                }
              >
                {config?.texts?.message}
              </div>
            </div>

            {/* Buttons */}
            <div
              className={
                (isSmall && '2xl:text-[8px]',
                'w-full px-[10%] flex justify-center gap-4 text-[8px] 2xl:text-[16px]')
              }
            >
              <div
                className={classNames(
                  isSmall && '2xl:p-0.5',
                  `rounded-lg px-3 p-0.5 2xl:p-1 flex items-center space-x-2 cursor-pointer hover:shadow-lg`,
                  !config?.pdf_links?.pdf?.dowloadButton?.text && 'hidden'
                )}
                style={{ backgroundColor: `${config?.pdf_links?.pdf?.dowloadButton?.buttonColor}` }}
              >
                <span
                  className="font-semibold select-none whitespace-pre-wrap text-center"
                  style={{ color: `${config?.pdf_links?.pdf?.dowloadButton?.textColor}` }}
                >
                  {config?.pdf_links?.pdf?.dowloadButton?.text}
                </span>
                <MdOutlineFileDownload
                  className={classNames('text-xs 2xl:text-lg')}
                  style={{ color: `${config?.pdf_links?.pdf?.dowloadButton?.textColor}` }}
                />
              </div>
              {config?.pdf_links?.links?.link.length > 1 ? (
                <div
                  className={`relative border-[1px] rounded-lg p-1 px-3 flex items-center space-x-2 cursor-pointer hover:shadow-lg`}
                  style={{ borderColor: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  onClick={() => setMenuShow(!menuShow)}
                >
                  <span
                    className={`text-primary text-md font-semibold select-none whitespace-pre-wrap text-center`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  >
                    {t('Landing.links')}
                  </span>
                  <IoIosArrowForward
                    size={20}
                    className={`text-primary`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  />

                  {menuShow && (
                    <div
                      className={`absolute -top-1 left-[100%] w-fit bg-white z-10 rounded-xl shadow-lg p-1 pb-0 flex flex-col justify-center items-center space-y-0 border-[3px] border-gray-300`}
                    >
                      {config?.pdf_links?.links?.link.map((link, index) => (
                        <a
                          href={urlParsed(link.urlLink)}
                          target={'_blank'}
                          className={classNames(
                            `w-full text-center text-sm py-1 px-3 font-semibold select-none whitespace-nowrap hover:underline hover:text-primary`,
                            index > 0 && 'border-t border-gray-300',
                          )}
                          style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                          key={index}
                        >
                          {link.textLink}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`border-[1px] rounded-lg p-1 px-3 flex items-center space-x-2 cursor-pointer hover:shadow-lg`}
                  style={{
                    borderColor: `${config?.pdf_links.links.colorButtonsLinks}`,
                    color: `${config?.pdf_links.links.colorButtonsLinks}`,
                  }}
                >
                  <a
                    href={urlParsed(config?.pdf_links?.links?.link[0]?.urlLink)}
                    target={'_blank'}
                    className={` text-md font-semibold select-none whitespace-nowrap`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  >
                    {config?.pdf_links?.links?.link[0]?.textLink}
                  </a>
                </div>
              )}
            </div>

            {/* Contacts */}
            <div
              className={classNames(
                isSmall && '2xl:text-[8px]',
                'w-full flex justify-center space-x-5 px-[10%] text-xs 2xl:text-[16px]',
              )}
            >
              <SocialLinksTemplate contacts={config?.contacts} />
            </div>
          </div>
        </div>
        {/* footer */}
        <div
          className={classNames(
            isSmall && '2xl:text-[4px]',
            'w-full flex items-center justify-center space-x-5 text-[4px] 2xl:text-[8px]',
          )}
        >
          <img src={Logo} alt="" className={classNames(isSmall && '2xl:h-4', 'h-4 2xl:h-7')} />
          <span className="text-white ">
            {t('Landing.copyright')}
          </span>
          <div className="flex space-x-2">
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.privacy')}
            </a>
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.conditions')}
            </a>
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.cookies')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const FirstTemplateFull = ({ video, texConfig, portrait, config, className = '' }) => {
  const mainRef = useRef(null);
  const [menuShow, setMenuShow] = useState(false);
  const { t } = useTranslation();

  /* functions */
  const urlParsed = (url) => {
    return /^https?:\/\//.test(url) ? url : `http://${url}`;
  };
  
  return (
    <div
      ref={mainRef}
      className={classNames(
        className,
        'h-full aspect-[430/320] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end transform origin-top-left',
      )}
    >
      <div className="overflow-hidden rounded-xl flex items-center justify-center" style={{ width: '100%', height: '100%' }}>
        <div
          className={`w-[200%] h-[230%] absolute top-[45%] rounded-full overflow-hidden flex justify-center items-start bg-gray-600`}
        >
          {config?.images?.mainImage && (
            <img
              src={generateImageUrl(config?.images?.mainImage)}
              alt="Imagen de base"
              className="w-1/2 object-cover"
            />
          )}
        </div>
      </div>

      <div
        className={classNames(
          'w-full h-full absolute flex flex-col p-[12%] py-[3%] space-y-7 justify-end items-center',
        )}
      >
        <div className="relative flex flex-col justify-center items-center w-full h-[60%] bg-white z-10 rounded-3xl px-[16%]">
          <div
            className={classNames(
              'absolute w-full flex flex-col items-center bottom-0 px-[14%] pb-[3%] space-y-4',
            )}
          >
            {/* Logos */}
            <div className="flex w-full px-10 justify-evenly items-center">
              {config?.images?.firstLogo && (
                <img
                  src={generateImageUrl(config?.images.firstLogo)}
                  alt="logo"
                  className="w-12 rounded-lg"
                />
              )}
              {config?.images?.secondLogo && (
                <img
                  src={generateImageUrl(config?.images?.secondLogo)}
                  alt="logo"
                  className="w-12 rounded-lg"
                />
              )}
            </div>

            {/* Video preview */}
            <div
              className="flex justify-center items-center overflow-hidden relative bg-gray-600 flex-grow w-full rounded-md"
              style={{ aspectRatio: '400/187' }}
            >
              <PlayerTemplate
                video={video}
                texConfig={texConfig}
                portrait={generateImageUrl(portrait)}
              />
            </div>

            {/* Texts */}
            <div className={classNames('text-center flex flex-col space-y-1')}>
              <span className={'w-full font-bold text-2xl text-center text-gray-500'}>
                {config?.texts?.title || <span className="text-gray-400 italic">{t('Landing.hello')}</span>}
              </span>
              <div className={'w-full flex justify-center text-lg text-gray-400'}>
                {config?.texts?.message}
              </div>
            </div>

            {/* Buttons */}
            <div className={'w-full px-[10%] flex justify-center space-x-4 text-md'}>
              {config?.pdf_links?.pdf?.fileName && <a
                className={classNames(
                  `rounded-lg bg-primary px-3 p-0.5 2xl:p-1 flex items-center space-x-2 cursor-pointer hover:shadow-lg`,
                )}
                style={{ backgroundColor: `${config?.pdf_links?.pdf?.dowloadButton?.buttonColor}` }}
                href={generateImageUrl(config?.pdf_links?.pdf?.fileName)}
                target="_blank"
                download
              >
                <span
                  className="font-semibold select-none whitespace-nowrap"
                  style={{ color: `${config?.pdf_links?.pdf?.dowloadButton?.textColor}` }}
                >
                  {config?.pdf_links?.pdf?.dowloadButton?.text}
                </span>
                <MdOutlineFileDownload
                  className={classNames('text-lg')}
                  style={{ color: `${config?.pdf_links?.pdf?.dowloadButton?.textColor}` }}
                />
              </a>}
              {config?.pdf_links?.links?.link.length > 1 ? (
                <div
                  className={`relative border-[1px] rounded-lg p-1 px-3 flex items-center space-x-2 cursor-pointer hover:shadow-lg`}
                  style={{ borderColor: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  onClick={() => setMenuShow(!menuShow)}
                >
                  <span
                    className={`text-primary text-md font-semibold select-none whitespace-nowrap`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  >
                    {t('Landing.links')}
                  </span>
                  <IoIosArrowForward
                    size={20}
                    className={`text-primary`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  />

                  {menuShow && (
                    <div
                      className={`absolute -top-1 left-[100%] w-fit bg-white z-10 rounded-xl shadow-lg p-1 pb-0 flex flex-col justify-center items-center space-y-0 border-[3px] border-gray-300`}
                    >
                      {config?.pdf_links?.links?.link.map((link, index) => (
                        <a
                          href={urlParsed(link.urlLink)}
                          target={'_blank'}
                          className={classNames(
                            `w-full text-center text-sm py-1 px-3 font-semibold select-none whitespace-nowrap hover:underline hover:text-primary`,
                            index > 0 && 'border-t border-gray-300',
                          )}
                          style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                          key={index}
                        >
                          {link.textLink}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`border-[1px] rounded-lg p-1 px-3 flex items-center space-x-2 cursor-pointer hover:shadow-lg`}
                  style={{
                    borderColor: `${config?.pdf_links.links.colorButtonsLinks}`,
                    color: `${config?.pdf_links.links.colorButtonsLinks}`,
                  }}
                >
                  <a
                    href={urlParsed(config?.pdf_links?.links?.link[0]?.urlLink)}
                    target={'_blank'}
                    className={` text-md font-semibold select-none whitespace-nowrap`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  >
                    {config?.pdf_links?.links?.link[0]?.textLink}
                  </a>
                </div>
              )}
            </div>

            {/* Contacts */}
            <div
              className={classNames(
                'w-full flex justify-center space-x-5 px-[10%] text-md 2xl:text-lg',
              )}
            >
              <SocialLinksTemplate contacts={config?.contacts} />
            </div>
          </div>
        </div>
        {/* footer */}
        <div
          className={classNames(
            'w-full flex items-center justify-center space-x-5 text-[8px] 2xl:text-[10px]',
          )}
        >
          <img src={Logo} alt="" className={classNames('h-5 2xl:h-7')} />
          <span className="text-white ">
            {t('Landing.copyright')}
          </span>
          <div className="flex space-x-2">
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.privacy')}
            </a>
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.conditions')}
            </a>
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.cookies')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FirstTemplateMini, FirstTemplate, FirstTemplateFull };
