import { classNames } from '../../../../config/utils';
import './Skeleton.css';

const types = {
  circle: 'rounded-full',
  rectangule: 'rounded-md',
};

export const Skeleton = ({ width, height, type = 'rectangule', className = '' }) => {
  const style = {
    width: width || '100%',
    height: height || '100%',
  };

  const styleBar = {
    width: '100%',
    height: '100%',
    background: `linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 75%
    )`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '200% 100%',
    animation: 'skeleton-loading 1.5s ease-in-out infinite',
  };

  return (
    <div
      className={classNames(
        className,
        'relative bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 overflow-hidden animate-pulse',
        types[type],
      )}
      style={style}
    >
      <div style={styleBar} />
    </div>
  );
};

export default Skeleton;
