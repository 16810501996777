import GlobalUploader from "./GlobalUploader"
import { RenderProgressModal } from "./RenderProgressModal"

const GeneralProgressModal = () => {
  return (
    <div className='flex flex-col items-end gap-3 z-50 w-fit fixed bottom-5 right-5'>
      <GlobalUploader />
      <RenderProgressModal />
    </div>
  )
}
export default GeneralProgressModal