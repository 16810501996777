import { useRef, useState } from 'react';
import NewContactImg from '../../assets/img/newContact.png';
import useAuthContext from '../../hooks/useAuthContext';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientAdd } from '../../services/client/ClientService';
import { AddList, AddTag, associatedList, tagList } from '../../services/client/TagService';
import Select from 'react-select/creatable';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Spin, Subtitle, Title } from '../../components/common';
import { NewContactSchema } from '../../config/formsValidators';
import { useToast2 } from '../../hooks/useToast2';
import { CustomMultiValue } from '../../components/common/SelectComponents';

export function NewContact() {
  /* State */
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [lists, setLists] = useState([]);
  const [isLoadingTag, setIsLoadingTag] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [tag, setTag] = useState([]);
  const [list, setList] = useState([]);

  /* Hooks */
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      last_name: '',
      second_last_name: '',
      phone: '',
      society: '',
    },
    resolver: yupResolver(NewContactSchema),
  });

  /* Querys */
  useQuery(['TAG_LIST', { token: user.access_token }], tagList, {
    onError: (error) => {
      showToast({show: true, type: 'error', message: t('Message.list.error.load_all')});
    },
    onSuccess: (data) => {
      setTags(data.data.data);
    },
  });

  useQuery(['ASSOCIATED_LIST', { token: user.access_token }], associatedList, {
    onError: (error) => {
      showToast({show: true, type: 'error', message: t('Message.tag.error.load_all')});
    },
    onSuccess: (data) => {
      setLists(data.data.data);
    },
  });

  /* Mutations */
  const mutationClientAdd = useMutation('CREATE_CLIENT', ClientAdd, {
    onError: (error) => {
      setLoading(false);
      if (error.status === 403) {
        showToast({ show: true, type: 'error', message: 'El contacto ya existe, pruebe con otro.' });
        return;
      }
      showToast({ show: true, type: 'error', message: t('Message.problem') });
    },
    onSuccess: (data) => {
      formRef.current.reset();
      setLoading(false);
      showToast({ show: true, type: 'success', message: t('Message.contact.success.add') });
      setList(null);
      setTag(null);
      navigate(-1)
    },
  });

  /* Functions */

  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });
  
  const handleCreateTag = (inputValue) => {
    setIsLoadingTag(true);
    AddTag({ token: user.access_token, tag: { title: inputValue } })
      .then(() => {
        const newOption = createOption(inputValue);
        setIsLoadingTag(false);
        setTags((prev) => [...prev, newOption]);
        setTag((prev) => [...prev, newOption]);
      })
      .catch(() => {
        showToast({show: true, type: 'error', message: t('Message.tag.error.add')});
      })
      .finally(() => {
        setIsLoadingTag(false);
      });
  };
  
  const handleCreateList = (inputValue) => {
    setIsLoadingList(true);
    AddList({ token: user.access_token, list: { title: inputValue } })
    .then(() => {
      const newOption = createOption(inputValue);
        setIsLoadingList(false);
        setLists((prev) => [...prev, newOption]);
        setList((prev) => [...prev, newOption]);
      })
      .catch(() => {
        showToast({show: true, type: 'error', message: t('Message.list.error.add')});        
      })
      .finally(() => {
        setIsLoadingList(false);
      });
  };

  const onSubmit = (data) => {
    setLoading(true);
    Object.assign(data, {
      tag,
      list,
    });
    const isValid = Object.keys(errors).length === 0;
    if (isValid) mutationClientAdd.mutate({ token: user.access_token, client: data });
  };

  return (
    <>
      {/*  <MetaTags>
        <title>{t('ClientNew.meta')}</title>
      </MetaTags> */}
      <Title title={t('Contact.new.meta')} />

      <div className="flex space-x-6 justify-between">
        <div className="w-full">
          <div className="mt-8">
            <Subtitle title={t('Contact.new.details')} />
          </div>
          <form ref={formRef} className="form-horizontal pl-2" onSubmit={handleSubmit(onSubmit)}>
            {/* Fields */}
            <div className="form">
              <div className="relative mt-2 rounded-md shadow-sm w-full">
                <input
                  {...register('email', { required: true })}
                  name="email"
                  className={`${errors.email && 'border-red-400 focus:border-red-400'
                    }  h-12 rounded-md border-2 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus:border-black sm:text-sm sm:leading-6`}
                  placeholder={t('Placeholder.email')}
                  type="email"
                  aria-invalid={errors.email ? 'true' : 'false'}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span> * </span>
                </div>
              </div>
              {errors.email && (
                <p className="text-red-500" role="alert">
                  {t(errors.email.message)}
                </p>
              )}

              <div className="relative mt-2 rounded-md shadow-sm w-full">
                <input
                  {...register('name', { required: true })}
                  name="name"
                  className={`${errors.name && 'border-red-400 focus:border-red-400'
                    } h-12 rounded-md border-2 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:ring-1 focus:border-black focus:ring-gray-300`}
                  placeholder={t('Placeholder.name')}
                  type="text"
                  aria-invalid={errors.name ? 'true' : 'false'}
                />

                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span> * </span>
                </div>
              </div>
              {errors.name && (
                <p className="text-red-500" role="alert">
                  {t(errors.name.message)}
                </p>
              )}

              <div className="relative mt-2 rounded-md shadow-sm w-full">
                <input
                  {...register('last_name')}
                  name="last_name"
                  className="focus:ring-1 focus:ring-gray-300 h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  placeholder={t('Placeholder.surname')}
                  type="text"
                />
              </div>

              <div className="relative mt-2 rounded-md shadow-sm w-full">
                <input
                  {...register('second_last_name')}
                  name="second_last_name"
                  className="focus:ring-1 focus:ring-gray-300 h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  placeholder={t('Placeholder.second_surname')}
                  type="text"
                />
              </div>

              <div className="relative mt-2 rounded-md shadow-sm w-full">
                <input
                  {...register('phone')}
                  name="phone"
                  className={`${errors.phone && 'border-red-400 border-2 focus:border-red-400'
                    } focus:ring-1 focus:ring-gray-300 h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                  placeholder={t('Placeholder.phone')}
                  type="tel"
                  // eslint-disable-next-line react/no-unknown-property
                  validate={{
                    required: { value: true, errorMessage: t('Validation.phone') },
                    valueAsNumber: { value: true, errorMessage: 'El telefono debe ser numerico' },
                  }}
                />
              </div>
              {errors.phone && (
                <p className="text-red-500" role="alert">
                  {t(errors.phone.message)}
                </p>
              )}

              <div className="relative mt-2 rounded-md shadow-sm w-full">
                <input
                  {...register('society')}
                  name="society"
                  className="focus:ring-1 focus:ring-gray-300 h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  placeholder={t('Placeholder.company')}
                  type="text"
                />
              </div>

              <div className="mt-8">
                <Subtitle dense title={t('Contact.new.search_create_list')} />
                <Select
                  components={{ MultiValue: CustomMultiValue }}
                  placeholder={t('Placeholder.select')}
                  value={list}
                  isMulti
                  isClearable
                  options={lists}
                  isLoading={isLoadingList}
                  onChange={(newValue) => setList(newValue)}
                  onCreateOption={handleCreateList}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: 6,
                      width: '100%',
                      flex: 1,
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isFocused ? '#E5E5E5' : 'white',
                      color: 'black',
                    }),
                  }}
                />
              </div>

              <div className="mt-8">
                <Subtitle dense title={t('Contact.new.search_create_tag')} />
                <Select
                  components={{ MultiValue: CustomMultiValue }}
                  placeholder={t('Placeholder.select')}
                  value={tag}
                  isClearable
                  isMulti
                  isDisabled={isLoadingTag}
                  isLoading={isLoadingTag}
                  onChange={(newValue) => setTag(newValue)}
                  onCreateOption={handleCreateTag}
                  options={tags}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: 6,
                      width: '100%',
                      flex: 1,
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isFocused ? '#E5E5E5' : 'white',
                      color: 'black',
                    }),
                  }}
                />
              </div>
            </div>

            <div className="flex space-x-4 mt-10 p-1">
              <button
                type='button'
                className="py-2.5 px-6 text-black-soft rounded-md bg-white ring-2 ring-gray-button flex justify-center items-center"
                onClick={() => navigate(-1)}
              >
                {t('Contact.new.back')}
              </button>
              <button className="flex py-2.5 px-6 text-white rounded-md bg-primary justify-center relative disabled:bg-gray-500" disabled={loading}>
                {
                  <>
                    <span className={`${!loading && 'opacity-0 '} absolute`}>
                      <Spin />
                    </span>
                    <span className={`${loading && 'opacity-0'}`}>
                      {t('Contact.new.save')}
                    </span>
                  </>
                }
              </button>
            </div>
          </form>
        </div>
        <div className="w-full mt-16">
          <img src={NewContactImg} className="max-w-xl -mr-14" />
        </div>
      </div>
    </>
  );
}
