import Pusher from 'pusher-js';
import { broadcastAuth } from '../../routes/api/paths';

Pusher.logToConsole = false;

const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY || '', {
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER || '',
    channelAuthorization: {
        endpoint: broadcastAuth,
    },
    forceTLS: true,
})

export default pusher;