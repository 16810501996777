import { getType } from "@reduxjs/toolkit";

export const typesRequestLibrary = {
  ALL: 'all',
  VIDEO: 'video',
  CORPORATIVE: 'corporative',
  RECORD: 'record',
  FRONTPAGE: 'frontPage',
  headers: (token) => {
    return {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };
  },
};

export const typeCategoryLibrary = {
  VIDEO: 'video',
  CORPORATIVE: 'corporative',
  RECORD: 'record',
  PORTRAIT: 'portrait',
}

export const typesFilesByCategory = {
  VIDEO: 'video',
  CORPORATIVE: 'corporative',
  RECORD: 'record',
  PORTRAIT: 'frontPage',
}

export const getTypeFilesByCategory = (category) => {
  switch (category) {
    case typeCategoryLibrary.VIDEO:
      return typesFilesByCategory.VIDEO;
    case typeCategoryLibrary.CORPORATIVE:
      return typesFilesByCategory.CORPORATIVE;
    case typeCategoryLibrary.RECORD:
      return typesFilesByCategory.RECORD;
    case typeCategoryLibrary.PORTRAIT:
      return typesFilesByCategory.PORTRAIT;
    default:
      return typesFilesByCategory.VIDEO;
  }
}

export const EndpointsLibrary = {
  LIST_FOLDER: 'api/v1/list-folder/',
  ADD_FOLDER: 'api/v1/addFile',
  DELETE_FOLDER: 'api/v1/deleteFolder',
  RENAME_FOLDER: 'api/v1/editFile',
  MOVE_FOLDER: 'api/v1/moveFolder',
  DUPLICATE_FOLDER: 'api/v1/duplicateFolder'
};

export const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};
