import { useState, useRef } from 'react';
import { BiInfoCircle } from 'react-icons/bi';

export const InfoBox = ({ icon, message = '', size = 22, positionMsg = 'left', onClick }) => {
  const [show, setShow] = useState(false);
  const over = useRef(false);
  const handleMouseEnter = () => {
    over.current = true;
    setTimeout(() => {
      if (over.current) setShow(true);
    }, 300);
  };
  const handleMouseLeave = () => {
    over.current = false;
    setShow(false);
  };

  const handleOnClick = (e) => {
    if (onClick) onClick(e);
  };

  const configMsg = () => {
    switch (positionMsg) {
      case 'top':
        return { bottom: 7 + size };
      case 'bottom':
        return { top: 7 + size };
      default:
        return { left: 7 + size, bottom: -1 };
    }
  };

  return (
    <div>
      <div
        className={`relative aspect-square cursor-pointer`}
        style={{ height: size }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOnClick}
      >
        {icon ? (
          icon
        ) : (
          <BiInfoCircle
            size={size}
            className="text-gray-400 cursor-pointer absolute left-0 group hover:opacity-80"
          />
        )}
        <div
          className={`absolute w-max max-w-[250px] cursor-default bg-gray-100 z-50 text-sm text-gray-500 rounded-sm px-4 py-2 ${
            show ? 'opacity-100 block' : 'opacity-0 hidden'
          } transition-opacity duration-100`}
          style={configMsg()}
        >
          {message}
        </div>
      </div>
    </div>
  );
};
