import { useEffect, useState } from 'react';
import { ReRenderizedVideo } from '../../../services/video/VideoService';
import useAuthContext from '../../../hooks/useAuthContext';
import { useToast2 } from '../../../hooks/useToast2';
import { classNames } from '../../../config/utils';

const RenderBadge = ({ id, renderized, processing }) => {
  // context
  const { user } = useAuthContext();

  // hooks
  const { showToast } = useToast2();

  // states
  const [isLoading, setIsLoading] = useState(false);  
  const [isProcessing, setIsProcessing] = useState(false);

  // effects
  useEffect(() => {
    setIsProcessing(processing);
  }, [processing]);
  
  // functions
  const handleProcessing = () => {
    if (isLoading || isProcessing) return;
    
    setIsLoading(true);
    ReRenderizedVideo({
      token: user?.access_token,
      id,
    })
      .then(() => {
        setIsProcessing(true);
        showToast({
          show: true,
          type: 'success',
          message: 'EL video se esta preparando...',
        });
      })
      .catch(() => {
        setIsProcessing(false);
        showToast({
          show: true,
          type: 'error',
          message: 'No se pudo procesar el video',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  if (renderized === undefined || renderized) return null;

  return (
    <div 
      className={classNames("relative overflow-hidden w-28 h-6 rounded-sm text-[9px] font-semibold uppercase flex justify-center items-center group whitespace-nowrap duration-500 select-none", isProcessing ? 'bg-gray-700' : 'bg-red-error/70 hover:bg-gray-800/80 cursor-pointer ')}
      onClick={handleProcessing}
    >
      {!isProcessing && !isLoading ?
      <>
        <span
          className={'absolute inset-0 flex justify-center items-center transition-transform transition-color duration-300 translate-y-[120%] group-hover:translate-y-0 group-hover:text-white text-black group-active:text-red-200'}
        >
          pre-procesar
        </span>
        <span
          className={'absolute inset-0 flex justify-center items-center transition-transform transition-color duration-300 translate-y-0 group-hover:translate-y-[120%] group-hover:text-black text-white'}
        >
          sin procesar
        </span>
      </>
      : <span
          className={'absolute inset-0 flex justify-center items-center text-white animate-pulse'}
        >
          {'procesando...'}
        </span>
      }
    </div>
  );
};

export default RenderBadge;
