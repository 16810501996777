import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterComp } from "../../components/login"

export const Register = () => {
    // redireccion temporal para no permitir acceder por url a registro de usuario
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/login');
    }, []);

    return (
        <RegisterComp />
    )
}