import useAuthContext from "../../hooks/useAuthContext";
import { useQuery } from "react-query";
import { SearchService } from "./SearchService";

export const SearchQuery = () => {
  const { user } = useAuthContext();
  
  const search = (query) => useQuery(
    ['search', query],
    () => SearchService({ token: user?.access_token, query }),
    {
      enabled: !!user?.access_token && !!query && query.length > 2,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  return {
    search,
  }
};