import { Fragment, useContext } from 'react';
import { Transition } from '@headlessui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoCameraOutline, IoImageOutline } from 'react-icons/io5';
import FolderList from '../../components/library/Video/Destination/FolderList';
import { FolderContext } from '../../contexts/folderContext';
import { useState } from 'react';
import { filterMostRecent, generateImageUrl } from '../../config/utils';
import { useEffect } from 'react';
import { getElementById, getFilesByIds } from '../../pages/Library/tools/pathUtils';

import './styles/UploadImage.css';
import { useDispatch } from 'react-redux';
import { saveFrontPage } from '../../features/videos/videosSlice';
import { useMutation } from 'react-query';
import { postAddPortrait } from '../../services/library/LibraryService';

import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../hooks/useToast2';
import useAuthContext from '../../hooks/useAuthContext';
import { DestinationView } from '../../components/library/Video/Destination/DestinationView';
import { Spinner } from '../../components/common';
import moment from 'moment';

const FrontPage = ({ image, setSelectImage }) => {
  const frontPageUrl = generateImageUrl(image.file);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div
      className="relative w-[183.3px] h-[128px] rounded-md shadow-md cursor-pointer"
      style={{ backgroundColor: '#979797' }}
    >
      <img
        className="w-[183.3px] h-[128px] rounded-md shadow-md cursor-pointer"
        src={frontPageUrl}
        alt="Portada para video"
        onLoad={handleImageLoad}
        style={{ display: !imageLoaded ? 'none' : 'block' }}
      />

      <IoImageOutline
        size={50}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white opacity-40 font-light"
        style={{ display: imageLoaded ? 'none' : 'block' }}
      />

      {imageLoaded && (
        <input
          type="radio"
          value={image.id}
          className="absolute top-1 left-1 w-6 cursor-pointer h-6 border-0 focus:border-0 focus:outline-0 rounded-md text-primary ring-primary outline-primary focus:ring-0"
          onChange={() => setSelectImage(image)}
          name="selectFrontPage"
        />
      )}

      {image.title && (
        <div className="absolute bottom-0 left-0 w-full h-10 flex items-center justify-start mx-3">
          <span className="text-sm text-gray-200 select-none">
            {image.title.substring(0, 20)}
            {image.title.length > 20 ? '...' : ''}
          </span>
        </div>
      )}
    </div>
  );
};

const FrontPageList = ({ title, images = [], setSelectImage }) => {
  return (
    <div className="w-full mb-7">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg text-gray-700 font-semibold">{title}</h3>
      </div>
      <div className="grid grid-cols-4 gap-4">
        {images.map((img) => (
          <FrontPage key={img.id} image={img} setSelectImage={() => setSelectImage(img)} />
        ))}
      </div>
    </div>
  );
};

const UploadImage = ({ open, onClose, rootFolder, onOpenShootPicture, isLoading }) => {
  const dispatch = useDispatch();

  const { user } = useAuthContext();

  const { t } = useTranslation();
  const { showToast } = useToast2();

  const [files, setFiles] = useState({
    lastFiles: [],
    restFiles: [],
  });

  const { idFolder, currentFolder, setCurrentFolder, filesSelected, setFilesSelected } =
    useContext(FolderContext);

  // Esto va a servir tanto para si es una imagen seleccionada de las ya creadas o si sube un File
  const [selectedFrontPage, setSelectedFrontPage] = useState(null);

  const { mutate, isLoading: isLoadingMutate } = useMutation('CREATE_FRONT_PAGE', postAddPortrait, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.frontPage.error.could_not_uploaded') });
    },
    onSuccess: (response) => {
      if (response) {
        setTimeout(
          () =>
            showToast({
              show: true,
              type: 'success',
              message: t('Message.frontPage.success.uploaded_established_video'),
            }),
          1000,
        );

        const data = JSON.parse(response);

        const frontPage = {
          id: data.id,
          file: data.src,
        };

        dispatch(saveFrontPage(frontPage));
        onClose();
      }
    },
  });

  /* Effects */
  useEffect(() => {
    const getFolder = () => {
      if (!idFolder) return rootFolder;
      return getElementById(idFolder, rootFolder);
    };

    setCurrentFolder(getFolder());
  }, [idFolder, rootFolder]);

  useEffect(() => {
    if (filesSelected.length > 0) {
      const filesById = getFilesByIds(rootFolder, filesSelected);
      if (filesById.length) setSelectedFrontPage(filesById[0]);
    }
  }, [filesSelected]);

  // Para filtrar los elementos más recientes
  useEffect(() => {
    const files = currentFolder?.files
      ?.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
    const { lastElements, restElements } = filterMostRecent(files);

    setFiles({ lastFiles: lastElements, restFiles: restElements });

    return () => {
      setFiles({ lastFiles: [], restFiles: [] });
    };
  }, [currentFolder]);

  const handleSaveFrontPage = () => {
    if (selectedFrontPage) {
      dispatch(saveFrontPage(selectedFrontPage));
      onClose();
    }
  };

  const handleImportFrontPage = () => {
    const handleFileChange = (event) => {
      const file = event.target.files[0];

      if(!file.type.includes('image')) return;

      if (file) {
        setSelectedFrontPage(file);

        setTimeout(() => {
          mutate({
            token: user.access_token,
            title: file.name || 'Front page photo',
            folderId: idFolder,
            image: file,
          });
        }, 500);
      }
    };

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', handleFileChange);
    fileInput.click();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed backdrop-filter backdrop-blur-sm inset-0 bg-gray-900/80"
              onClick={onClose}
            />
          </Transition.Child>

          {/* Modal panel, show/hide based on modal state. */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          >
            {/* Dialog */}
            <div className="relative transform overflow-hidden rounded-[15px] bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all my-4 2xl:my-8 h-10/12 max-w-7xl w-2/4 p-6">
              <div className="px-8 pt-8 2xl:pt-10">
                <DestinationView
                  onClose={onClose}
                  getFileId
                  uploadImage={{
                    handleSaveFrontPage,
                    handleImportFrontPage,
                    onOpenShootPicture,
                  }}
                  noSelectView
                />
                <Spinner
                  open={isLoadingMutate}
                  title={t('Message.frontPage.uploading')}
                  message={t('Message.frontPage.wait_message')}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
};

export default UploadImage;
