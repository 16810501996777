import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import { classNames } from '../../../../config/utils';
import { motion } from 'framer-motion';
import ElementOverlayContainer from '../../../../components/video/edition/others/ElementOverlayContainer';

export const PlayerTemplate = ({
  video,
  texConfig = [],
  portrait,
  noControls,
  playing,
  setPlaying = () => {}, // Estaba dando error parece que no siempre se le pasa setPlaying
}) => {
  const [played, setPlayed] = useState(playing || false);
  const [imageNotFound, setImageNotFound] = useState(false);
  const videoRef = useRef(null);

  const parentRef = useRef(null);

  // Hay que usar esto porque con videoRef.current.currentTime no funciona
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    if (played) {
      const playPromise = videoElement.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.error('Error al reproducir el video:', error);
        });
      }
    } else {
      videoElement.pause();
    }

    const handleVideoEnd = () => {
      setPlayed(false);
      setPlaying(false);
    };

    videoElement.addEventListener('ended', handleVideoEnd);

    return () => {
      videoElement.removeEventListener('ended', handleVideoEnd);
      videoElement.pause();
    };
  }, [played]);

  useEffect(() => {
    setPlayed(playing);
  }, [playing]);

  const handleClick = () => {
    setPlayed(!played);
    setPlaying(!played);
  };

  const handleTimeUpdate = (event) => {
    setCurrentTime(event.target.currentTime);
  };

  return (
    <div className="w-full h-full relative flex justify-center items-center group">
      {noControls === undefined && !played ? (
        <div
          className="absolute z-40 w-10 p-1 h-10 border-4 border-white hover:border-primary rounded-full flex justify-center items-center opacity-60 cursor-pointer"
          onClick={handleClick}
        >
          <FaPlay className={classNames('text-white ml-1 text-[20px]')} />
        </div>
      ) : (
        noControls === undefined && (
          <div
            className="absolute z-40 w-10 p-1 h-10 border-4 border-white hover:border-primary group-hover:opacity-60 rounded-full flex justify-center items-center opacity-0 cursor-pointer"
            onClick={handleClick}
          >
            <FaPause className={classNames('text-white text-[20px]')} />
          </div>
        )
      )}

      <div className="z-10 overflow-hidden absolute inset-0">
        {portrait && !imageNotFound && !played && (
          <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0 rounded-lg group-hover:scale-110 duration-500"
          >
            <img 
              src={portrait}  
              className="w-full h-full object-cover rounded-lg" 
              onError={() => setImageNotFound(true)}
            />
          </motion.div>
        )}
      </div>

      <div
        className={classNames(
          'absolute inset-0 w-full h-full flex justify-center items-center bg-gradient-to-t z-20 from-black via-gray-500 to-gray-500',
          noControls !== undefined || played ? 'opacity-0 group-hover:opacity-20 transition-opacity' : 'opacity-50',
        )}
      />
      <div ref={parentRef} className="absolute w-full h-full inset-0">
        {video && (
          <video
            ref={videoRef}
            src={video}
            className="w-full h-full inset-0 select-none"
            onTimeUpdate={handleTimeUpdate}
          />
        )}
        <ElementOverlayContainer
          videoRef={videoRef}
          containerRef={parentRef}
          currentTime={currentTime || 0}
          overlayElements={texConfig}
          editableElem={false}
        />
      </div>
    </div>
  );
};
