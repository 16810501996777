import { Fragment, useRef, useContext, useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { classNames, generateImageUrl } from '../../config/utils';
import useAuthContext from '../../hooks/useAuthContext';
import { querys } from '../../pages/Library/tools/querys';
import { FolderContext } from '../../contexts/folderContext';
import { postUpdatePortrait } from '../../services/library/LibraryService';
import { Skeleton, DialogV2 } from '../../components/common';
import { useTranslation } from 'react-i18next';
import { getTypeFilesByCategory } from '../../pages/Library/tools/types';
import { useLocation } from 'react-router-dom';

export function PortraitItem({ file, editPortrait }) {
  //states
  const [toggleRename, setToggleRename] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [name, setName] = useState({
    prevName: file.title,
    newName: file.title,
  });
  const [rename, setRename] = useState({
    name: '',
    id: file.id,
    action: 'rename',
  });
  const [hasImage, setHasImage] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const id = file.id;

  //hooks
  const { user } = useAuthContext();
  const inputRef = useRef(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idParam = Number(searchParams.get('id'));
  
  //const
  const portraitDate = file.created_at.split('T')[0].split('-').reverse().join('/');
  const {
    toggleArchive,
    library, filesSelected,
    removeFilesSelected,
    addFilesSelected,
    itemsDisplay,
    filesLoading,
    setDeleteItemsProps,
    setModals,
    addFilesLoading,
  } = useContext(FolderContext);
  const menuItems = !toggleArchive ? [
    {
      id: 1,
      title: t('Library.menu.rename'),
      type: 'rename',
    },
    {
      id: 2,
      title: t('Library.menu.edit'),
      type: 'edit',
    },
    {
      id: 3,
      title: toggleArchive ? t('Library.menu.unarchive') : t('Library.menu.archive'),
      type: 'archive',
    },
    {
      id: 4,
      title: t('Library.menu.delete'),
      type: 'delete',
    },
  ] : [
    {
      id: 3,
      title: toggleArchive ? t('Library.menu.unarchive') : t('Library.menu.archive'),
      type: 'archive',
    },
    {
      id: 4,
      title: t('Library.menu.delete'),
      type: 'delete',
    },
  ];

  const { changeStatusPortrait, deletePortraitQuery } = querys();

  const { mutate: mutateStatusPortrait } = changeStatusPortrait();
  const { mutate: mutateDeletePortrait } = deletePortraitQuery();

  /* REACT QUERY */
  const { mutate, isLoading: isLoadingRenamePortrait } = useMutation(
    (opt) => postUpdatePortrait(opt),
    {
      onSuccess: () => {
        setTimeout(() => {
          setRename({
            action: 'rename',
            name: '',
            id,
          });
          setName((prev) => ({ ...prev, prevName: prev.newName }));
        }, 1000);
        queryClient.invalidateQueries(library);
      },
      onError: () => {
        setRename({
          action: 'rename',
          name: '',
          id,
        });
        setError(true);
        queryClient.invalidateQueries(library);
      },
    },
  );

  //functions
  const handleOnChangeName = (e) => {
    setName({ ...name, newName: e.target.value });
    setRename({ ...rename, name: e.target.value });
  };
  const startRename = () => {
    setToggleRename(true);
    setRename({ ...rename, id: file.id });
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  };

  //handlers
  const handleRename = () => {
    //dara error hasta q anesito cambie el parametro image a opcional
    setToggleRename(false);
    if (name.newName.length === 0) return setName((prev) => ({ ...prev, newName: prev.prevName }));

    if (rename.name.length > 0) {
      //normal rename
      mutate({
        token: user.access_token,
        portraitId: id,
        name: rename.name,
      });
    }
  };

  const handleArchive = () => {
    mutateStatusPortrait({ token: user.access_token, portraitId: id });
    addFilesLoading(id);
  };

  const handleDelete = () => {
    setDeleteItemsProps((prev) =>
    ({
      ...prev,
      label: t('Library.the_frontPage'),
      params: {
        foldersSelected: [],
        filesSelected: [id],
        type: getTypeFilesByCategory(library),
      }
    }))
    setModals((prev) => ({ ...prev, deleteItems: true }))
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setHasImage(false);
    setIsLoading(false);
  };

  const handleSelectFile = () => {
    if (filesSelected.includes(id)) return removeFilesSelected(id);
    addFilesSelected(id);
  };

  return itemsDisplay === 'list' ? (
    <div className={classNames("relative bg-gray-100 py-6 px-4 rounded-lg", 
    idParam === id && 'border-[1px] border-primary shadow-md shadow-primary')}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div>
            <input
              type="checkbox"
              className="rounded w-5 h-5 focus:ring-0 focus:outline-none outline-none ring-0  text-primary bg-white border-[1px] border-gray-400 focus:border-[1px] cursor-pointer shadow-md"
              checked={filesSelected.includes(id)}
              onChange={handleSelectFile}
            />
          </div>
          <div
            className="w-[5.5rem] h-[3.2rem] ml-6 border rounded-lg bg-gray-dark-portrait text-gray-portrait cursor-pointer hover:shadow-md hover:shadow-gray-400 flex justify-center items-center overflow-hidden"
            onClick={() => setShowPreview(true)}
          >
            <img
              className={classNames(
                'object-cover max-w-full',
                hasImage ? 'block' : 'hidden',
              )}
              src={generateImageUrl(file.file)}
              onLoad={handleImageLoad}
              onError={handleImageError}
              alt={file.title}
            />
            {!hasImage && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 26 26"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-image"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <circle cx="8.5" cy="8.5" r="1.5"></circle>
                <polyline points="21 15 16 10 5 21"></polyline>
              </svg>
            )}
          </div>
          <div className="ml-6 select-none">
            {/* toggle the classname, to preserve the ref */}
            <input
              ref={inputRef}
              type="text"
              className={classNames(
                'border-none bg-gray-video focus:ring-0 focus:bg-blue-400/30 px-0 py-0 focus:text-black text-black font-bold text-sm 2xl:text-md w-full',
                toggleRename ? 'block' : 'hidden',
              )}
              placeholder={name.newName}
              name={name.newName}
              value={name.newName}
              onChange={handleOnChangeName}
              /* press enter to handleRename */
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRename();
                }
              }}
              onBlur={handleRename}
              disabled={isLoading}
            />
            <p
              className={classNames(
                'font-bold text-md whitespace-normal overflow-hidden overflow-ellipsis max-w-[500px] 2xl:max-w-[900px]',
                toggleRename ? 'hidden' : 'block',
                error && 'text-red-error',
              )}
            >
              {file.title}
            </p>
            <p className="font-light text-xs">{portraitDate}</p>
          </div>
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 ">
              <EllipsisHorizontalIcon className="w-6 h-6 font-bold cursor-pointer" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute p-2 right-0 z-10 mt-2 w-36 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {menuItems.map((item) => (
                  <Menu.Item key={item.id} className="rounded-md pl-6 hover:bg-gray-menu-shadow">
                    {({ active }) => (
                      <span
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer',
                        )}
                        onClick={() => {
                          switch (item?.type) {
                            case 'rename':
                              startRename();
                              break;
                            case 'archive':
                              handleArchive();
                              break;
                            case 'delete':
                              handleDelete();
                              break;
                            case 'edit':
                              if (editPortrait) editPortrait(file);
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        {item.title}
                      </span>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {/* loader to async operations */}
      <div
        className={classNames(
          'absolute w-full bottom-0 inset-x-0 transition-all duration-150 overflow-hidden',
          filesLoading.includes(id) || error ? 'h-full' : 'h-0',
        )}
      >
        {filesLoading.includes(id) && <Skeleton />}
        {error && <div className="w-full h-full animate-pulse bg-red-error" />}
      </div>
      <DialogV2 open={showPreview} onClose={() => setShowPreview(false)}>
        {file.file ? (
          <div className="relative w-full h-full mt-1">
            <img className="w-full h-full" src={`${generateImageUrl(file.file)}`} alt={file.title} />
          </div>
        ) : (
          <div className="relative w-full h-full">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-sm font-light text-gray-500">{t('Message.frontPage.error.not_found')}</p>
            </span>
          </div>
        )}
      </DialogV2>
    </div>
  ) : (
    <div id={id} className={classNames("relative bg-gray-video rounded-lg min-w-[252px] max-w-[252px]",
    idParam === id && 'border-[1px] border-primary shadow-md shadow-primary')}>
      <div className="px-3 bg-gray- w-full h-[194px] rounded-lg relative  overflow-hidden">
        <input
          type="checkbox"
          className="rounded w-5 h-5 focus:ring-0 focus:outline-none outline-none ring-0  text-primary bg-white border-[1px] border-gray-400 focus:border-[1px] cursor-pointer shadow-md absolute top-5 left-5"
          checked={filesSelected.includes(file.id)}
          onChange={handleSelectFile}
        />
        <div
          className={classNames(
            'flex flex-1 align-middle self-center justify-center items-center text-gray-dark-portrait w-full h-full',
            !isLoading && 'cursor-pointer',
          )}
          onClick={() => !isLoading && setShowPreview(true)}
        >
          {isLoading && (
            <div className="absolute inset-0">
              <Skeleton />
            </div>
          )}
          <img
            className={classNames(
              'object-cover max-w-full max-h-full',
              hasImage ? 'block' : 'hidden',
            )}
            src={generateImageUrl(file.file)}
            onLoad={handleImageLoad}
            onError={handleImageError}
            alt={file.title}
          />
          {!hasImage && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 26 26"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-image"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <circle cx="8.5" cy="8.5" r="1.5"></circle>
              <polyline points="21 15 16 10 5 21"></polyline>
            </svg>
          )}
        </div>
      </div>

      <div className="p-3 rounded-lg flex justify-between bg-gray-video items-start mt-[10px]">
        <div>
          {/* toggle the classname, to preserve the ref */}
          <input
            ref={inputRef}
            type="text"
            className={classNames(
              'border-none bg-gray-video focus:ring-0 focus:bg-blue-400/30 px-0 py-0 focus:text-black text-black font-bold text-sm 2xl:text-md w-full',
              toggleRename ? 'block' : 'hidden',
            )}
            placeholder={name.newName}
            name={name.newName}
            value={name.newName}
            onChange={handleOnChangeName}
            /* press enter to handleRename */
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRename();
              }
            }}
            onBlur={handleRename}
            disabled={isLoading}
          />
          <p
            className={classNames(
              'font-regular text-md whitespace-normal overflow-hidden overflow-ellipsis max-w-[100px] 2xl:max-w-[220px]',
              toggleRename ? 'hidden' : 'block',
              error && 'text-red-error',
            )}
          >
            {name.newName}
          </p>
          <p className="font-light text-xs">{portraitDate}</p>
        </div>
        <div>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-full text-sm font-semibold text-gray-900 hover:shadow-md">
                <EllipsisHorizontalIcon className="w-6 h-6 font-bold cursor-pointer" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute p-2 right-0 z-10 mt-2 w-36 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {menuItems.map((item, index) => (
                    <Menu.Item
                      key={item.id}
                      className="rounded-md pl-6 hover:bg-gray-menu-shadow cursor-pointer"
                    >
                      {({ active }) => (
                        <span
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          )}
                          onClick={() => {
                            switch (item?.type) {
                              case 'rename':
                                startRename();
                                break;
                              case 'archive':
                                handleArchive();
                                break;
                              case 'delete':
                                handleDelete();
                                break;
                              case 'edit':
                                if (editPortrait) editPortrait(file);
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          {item.title}
                        </span>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {file.stored && (
        <div className="mt-4">
          <button className="flex items-center py-1.5 bg-primary px-4 rounded-md text-sm text-white">
            <PaperAirplaneIcon className="w-6 h-6 mr-1 cursor-pointer" /> {t('Library.send')}
          </button>
        </div>
      )}

      {/* loader to async operations */}
      <div
        className={classNames(
          'absolute w-full bottom-0 inset-x-0 transition-all duration-150 overflow-hidden',
          filesLoading.includes(id) || error ? 'h-full' : 'h-0',
        )}
      >
        {filesLoading.includes(id) && <Skeleton />}
        {error && <div className="w-full h-full animate-pulse bg-red-error" />}
      </div>
      
      <DialogV2 open={showPreview} onClose={() => setShowPreview(false)}>
        {file.file ? (
          <div className="relative w-full h-full mt-1">
            <img className="w-full h-full" src={generateImageUrl(file.file)} alt={file.title} />
          </div>
        ) : (
          <div className="relative w-full h-full">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-sm font-light text-gray-500">{t('Message.frontPage.error.not_found')}</p>
            </span>
          </div>
        )}
      </DialogV2>
    </div>
  );
}


