import { useTranslation } from 'react-i18next';
import { Step } from './Step';

export function StepsList() {

  const { t } = useTranslation();

  const steps = [
    { id: '1', name: t('Video.steps.step1'), href: '#', status: 'current' },
    { id: '2', name: t('Video.steps.step2'), href: '#', status: 'upcoming' },
    { id: '3', name: t('Video.steps.step3'), href: '#', status: 'upcoming' },
    { id: '4', name: t('Video.steps.step4'), href: '#', status: 'upcoming' },
    { id: '5', name: t('Video.steps.step5'), href: '#', status: 'upcoming' },
  ];

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-gray-300 rounded-xl border border-gray-300 md:flex md:divide-y-0 mb-8"
      >
        {steps.map((step, stepIdx) => (
          <Step key={stepIdx} index={stepIdx} step={step} />
        ))}
      </ol>
    </nav>
  );
}
