import { useEffect, useState } from 'react';

export function Accordion({ title, children, className = '', index, open, onOpen }) {
  const [isOpen, setIsOpen] = useState(open || false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    if (onOpen && index !== undefined && !isOpen)
    onOpen(index);
  }

  return (
    <div className={className + ' rounded-md'}>
      <div
        className="flex justify-between items-center p-4 py-2 cursor-pointer select-none"
        onClick={handleClick}
      >
        <h2 className="text-md font-semibold pl-1">{title}</h2>
        <svg
          className={`w-6 h-6 transition-transform transform ${isOpen ? 'rotate-270' : '-rotate-90'}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </div>
      <div className={`duration-150 ${!isOpen ? 'h-0 overflow-hidden opacity-0' : 'p-4 px-5 border-t border-gray-300 opacity-100'}`}>{children}</div>
    </div>
  );
}

export function AccordionContainer({ children, className = '', classNameChildren = ''}) {
  return (
    <div className= {className + "shadow-md border border-gray-200 rounded-xl"}>
      {children.map((child, index) => (
        <div key={index} className={ classNameChildren + ` ${index !== 0 ? 'border-t border-gray-300' : ''}`}>
          {child}
        </div>
      ))}
    </div>
  );
}
