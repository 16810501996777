import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  action,
  elements,
  labelFor,
  open,
  resetStore,
  setOpen,
  setRefetch,
} from '../../../features/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAuthContext from '../../../hooks/useAuthContext';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useMutation } from 'react-query';
import { ClientDeleteMultiple } from '../../../services/client/ClientService';
import { useTranslation } from 'react-i18next';
import { ListDeleteMultiple, tagDelete } from '../../../services/client/TagService';
import { useToast2 } from '../../../hooks/useToast2';

export function ConfirmModal() {
  /* Hooks */
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { showToast } = useToast2();
  const isOpen = useSelector(open);
  const label = useSelector(labelFor);
  const acceptAction = useSelector(action);
  const ids = useSelector(elements);

  /* Consts */

  /* Mutations */
  const mutationClientDelete = useMutation('DELETE_CLIENT_MULTIPLE', ClientDeleteMultiple, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: error });
    },
    onSuccess: (data) => {
      setTimeout(() => {
        showToast({
          show: true,
          type: 'success',
          message: t('Message.client.success.delete'),
        });
      }, 1000);
      dispatch(setRefetch(true));
      dispatch(setOpen(false));
    },
  });

  const mutationListDelete = useMutation('DELETE_LIST_MULTIPLE', ListDeleteMultiple, {
    onError: (error) => {
      showToast('error', t('Message.error'), t('Message.client.error.delete'));
    },
    onSuccess: (data) => {
      setTimeout(
        () => showToast('success', t('Message.info'), t('Message.client.success.delete')),
        1000,
      );
      dispatch(setRefetch(true));
      dispatch(setOpen(false));
    },
  });

  const mutationTagDelete = useMutation('DELETE_TAG_MULTIPLE', tagDelete, {
    onError: (error) => {
      showToast('error', t('Message.error'), t('Message.client.error.delete'));
    },
    onSuccess: (data) => {
      setTimeout(
        () => showToast('success', t('Message.info'), t('Message.client.success.delete')),
        1000,
      );
      dispatch(setRefetch(true));
      dispatch(setOpen(false));
    },
  });

  const handleRemove = () => {
    switch (acceptAction) {
      case 'removeClient':
        mutationClientDelete.mutate({ token: user.access_token, ids });
        break;
      case 'removeList':
        mutationListDelete.mutate({ token: user.access_token, ids });
        break;
      case 'removeTag':
        mutationTagDelete.mutate({ token: user.access_token, ids });
        break;
    }
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          dispatch(setOpen(false));
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white px-4 text-left shadow-xl transition-all sm:my-8 max-w-xl w-full py-16">
                <div className="px-8 pt-10 flex flex-col items-center justify-center">
                  <ExclamationTriangleIcon className="w-24 h-24 text-primary" />
                  <p className="text-xl font-light mt-8">
                    {t('ModalDeleteWithText.title', {text: label})}
                  </p>
                  <div className="flex space-x-4 mt-16 mb-8">
                    <button
                      className="px-4 py-2.5 border-gray-300 border rounded-md text-gray-300"
                      onClick={() => dispatch(resetStore())}
                    >
                      {t('ModalDeleteWithText.cancel')}
                    </button>
                    <button
                      className="px-10 py-2.5 border-primary border rounded-md text-white bg-primary"
                      onClick={handleRemove}
                    >
                      {t('ModalDeleteWithText.confirm')}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
