import useAuthContext from '../../hooks/useAuthContext';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Routes from '../../routes/app/paths';
import { classNames } from '../../config/utils';
import { useToast2 } from '../../hooks/useToast2';
import { useTranslation } from 'react-i18next';
import GeneralConfirModal from '../../components/common/modal/GeneralConfirModal';
import { motion } from 'framer-motion';

const getCurrentItem = (item) => {
  return item.route === location.pathname;
};

const getCurrentParentItem = (item) => {
  return item.route === location.pathname || location.pathname.toString().includes(item.route);
};

function MenuIcon({ item }) {
  return item.feather ? (
    <ReactSVG
      src={item.icon}
      className={classNames(
        getCurrentParentItem(item) ? 'text-white' : 'text-gray-400 group-hover:text-white',
        'shrink-0',
      )}
    />
  ) : (
    <item.icon
      className={classNames(
        getCurrentParentItem(item) ? 'text-white' : 'text-gray-400 group-hover:text-white',
        'h-8 w-8 shrink-0',
      )}
      aria-hidden="true"
    />
  );
}

export default function MenuItem({ item, onClose }) {
  /* Hooks */
  const location = useLocation();
  const { t } = useTranslation();
  /* State */
  const [opened, setOpened] = useState(false);
  const { logout } = useAuthContext();
  const navigate = useNavigate();
  const { showToast } = useToast2();
  const [openModalLogout, setOpenModalLogout] = useState(false);

  const handleOnClick = () => {
    if (item.type === 'logout') {
      setOpenModalLogout(true);
      return;
    }
    setOpened(!opened);
  };

  const handleConfirmLogout = (confirm) => {
    confirm
      ? (logout(),
        showToast({
          show: true,
          type: 'success',
          message: t('Message.auth.success.logout'),
        }),
        navigate(Routes.login))
      : setOpenModalLogout(false)
  };

  /* Functions */
  const isActivated = useMemo(() => 
      item.route === location.pathname ||
      location.pathname.toString().includes(item.route) ||
      (item.children &&
        item.children.find((child) => child.route === location.pathname))
    , [location.pathname]);

  useEffect(() => {
    if (isActivated) {
      setOpened(true);
    }
  }, [location.pathname]);

  return (
    <li key={item.name}>
      {!item.children && item.type !== 'logout' ? (
        <Link
          key={item.name}
          to={item.href}
          className={classNames(
            getCurrentParentItem(item)
              ? 'bg-primary text-white'
              : 'text-gray-700 hover:text-white hover:bg-primary',
            'group flex gap-x-3 items-center rounded-md px-2 py-2 text-md leading-6 font-semibold',
          )}
        >
          <MenuIcon item={item} />
          {item.name}
        </Link>
      ) : item.type === 'logout' ? (
        <button
          onClick={handleOnClick}
          className={classNames(
            'text-gray-700 hover:text-white hover:bg-primary',
            'group w-full flex gap-x-3 items-center rounded-md px-2 py-2 text-md leading-6 font-semibold',
          )}
        >
          <MenuIcon item={item} />
          {item.name}
        </button>
      ) : (
        <div>
          <button
            onClick={() => setOpened(!opened)}
            className={classNames(
              getCurrentParentItem(item)
                ? 'bg-primary text-white'
                : 'text-gray-700 hover:text-white hover:bg-primary',
              'group w-full flex gap-x-3 items-center rounded-md px-2 py-2 text-md leading-6 font-semibold',
            )}
          >
            <MenuIcon item={item} />
            {item.name}
          </button>
          <motion.ul 
              initial="closed"
              animate={opened ? "open" : "closed"}
              variants={{
                open: { height: "auto" },
                closed: { height: 0 }
              }}
              transition={{ duration: 0.2 }}
              className="px-2"
              style={{ overflow: "hidden" }}
            >
              {item.children.map((subItem) => (
                <li key={subItem.name}>
                  <Link
                    to={subItem.route}
                    onClick={onClose}
                    className={classNames(
                      getCurrentItem(subItem) ? 'text-primary' : '',
                      'hover:text-primary font-medium flex text-sm items-center w-full p-2 text-gray-900 transition duration-75 rounded-tl-lg rounded-bl-lg mb-2 pl-11 group ',
                    )}
                  >
                    {subItem.name}
                  </Link>
                </li>
              ))}
            </motion.ul>
        </div>
      )}
      <GeneralConfirModal isOpen={openModalLogout} action={'logout'} onConfirm={handleConfirmLogout} />
    </li>
  );
}
