import { useState } from 'react';
import { Input } from '.';
import { useTranslation } from 'react-i18next';
import '../../../config/i18next-config';
import { Spin, Toast } from '../../common';
import { classNames } from '../../../config/utils';
import { Link } from 'react-router-dom';
import { LOGIN } from '../../../routes/app/paths';
import { useToast2 } from '../../../hooks/useToast2';

export const RegisterForm = () => {
  const [data, setData] = useState({
    name: '',
    surname: '',
    second_surname: '',
    email: '',
    password: '',
    confirm_password: '',
    conditions_checked: false,
    marketing_checked: true,
  });
  const [errors, setErrors] = useState({
    name: null,
    surname: null,
    second_surname: null,
    email: null,
    password: null,
    confirm_password: null,
  });
  const [passSuccess, setPassSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { showToast } = useToast2();

  /* Handlers */
  const handleChange = (event, type) => {
    // handle checkbox
    if (['conditions_checked', 'marketing_checked'].includes(type)) {
      return setData((prev) => ({
        ...prev,
        [type]: event.target.checked,
      }));
    }
    // handle other inputs
    setData((prev) => ({
      ...prev,
      [type]: event.target.value,
    }));

    // handle password
    if (['password', 'confirm_password'].includes(type)) {
      const otherType = type === 'password' ? 'confirm_password' : 'password';
      setPassSuccess(data[otherType] === event.target.value && data.password !== '');
    }

    // reset errors
    setErrors((prev) => ({ ...prev, [type]: null }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    if (loading) return;

    if (!validatedFields()) return;

    //register endpoint
    // run a false endpoint simulate
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      showToast({
        show: true,
        type: 'error',
        message: t('Auth.Register.error'),
      });
    }, 2000);
    showToast((prev) => ({
      ...prev,
      show: false,
    }));
  };

  const validatedFields = () => {
    let hasErrors = false;

    // validate name
    const regexName = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
    if (!regexName.test(data.name)) {
      setErrors((prev) => ({
        ...prev,
        name: {},
      }));
      hasErrors = true;
    }

    // validate surname
    if (!regexName.test(data.surname)) {
      setErrors((prev) => ({
        ...prev,
        surname: {},
      }));
      hasErrors = true;
    }

    // validate email
    const regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!regexEmail.test(data.email)) {
      setErrors((prev) => ({
        ...prev,
        email: {
          message: t('Validation.not_email'),
        },
      }));
      hasErrors = true;
    }

    // validate password
    if (data.password.length < 8) {
      setErrors((prev) => ({
        ...prev,
        password: {
          message: t('Validation.password_length'),
        },
      }));
      hasErrors = true;
    }
    if (!passSuccess) {
      setErrors((prev) => ({
        ...prev,
        confirm_password: {
          message: t('Validation.not_match_password'),
        },
      }));
      hasErrors = true;
    }

    return !hasErrors;
  };

  return (
    <form
      onSubmit={handleSubmitForm}
      className="w-3/5 p-4 select-none flex flex-col space-y-10 overflow-auto"
    >
      <div className="flex flex-col space-y-1">
        <div className="flex">
          <h3 className="text-gray-700 text-2xl font-bold mr-2">
            {t('Auth.Register.title_first')}
          </h3>
          <h3 className="text-primary text-2xl font-bold">{t('Auth.Register.title_second')}</h3>
        </div>
        <div className="flex space-x-2 text-xs">
          <h3 className="text-gray-700  ">{t('Auth.Register.subtitle')}</h3>
          <Link
            to={LOGIN}
            className={classNames(
              'text-primary font-bold',
              !loading ? 'hover:underline cursor-pointer' : 'opacity-50',
            )}
          >
            {t('Auth.Register.has_account')}
          </Link>
        </div>
      </div>

      <div className="flex flex-col w-full space-y-2">
        <div className="flex flex-col space-y-4">
          <Input
            type="text"
            placeholder={t('Placeholder.name')}
            value={data.name}
            onChange={(e) => handleChange(e, 'name')}
            showError={errors.name}
            disabled={loading}
          />
          <Input
            type="text"
            placeholder={t('Placeholder.surname')}
            value={data.surname}
            onChange={(e) => handleChange(e, 'surname')}
            showError={errors.surname}
            disabled={loading}
          />
          <Input
            type="text"
            placeholder={t('Placeholder.second_surname')}
            value={data.second_surname}
            onChange={(e) => handleChange(e, 'second_surname')}
            disabled={loading}
          />
          <Input
            type="email"
            placeholder={t('Placeholder.email')}
            value={data.email}
            onChange={(e) => handleChange(e, 'email')}
            showError={errors.email}
            disabled={loading}
          />
          <Input
            type="password"
            placeholder={t('Placeholder.password')}
            value={data.password}
            onChange={(e) => handleChange(e, 'password')}
            showError={errors.password}
            showSuccess={passSuccess}
            disabled={loading}
          />
          <Input
            type="password"
            placeholder={t('Placeholder.confirm_password')}
            value={data.confirm_password}
            onChange={(e) => handleChange(e, 'confirm_password')}
            showError={errors.confirm_password}
            showSuccess={passSuccess}
            disabled={loading}
          />
          <p className="text-[8px] text-gray-700">
            {t('Auth.Register.text')}
            <span className="ml-1 font-bold underline cursor-pointer">
              {t('Auth.Register.read_more')}
            </span>
          </p>
          <div className="flex flex-col space-y-1">
            <div className="flex space-x-2 items-center text-[9px]">
              <input
                type="checkbox"
                className={classNames(
                  'w-5 h-5 rounded-md border-2 scale-75 border-gray-400 focus:outline-none cursor-pointer  duration-100 checked:bg-primary checked:border-transparent focus:ring-0 focus:ring-offset-0 focus:ring-primary focus:text-primary hover:text-primary hover:border-primary',
                  loading && 'cursor-not-allowed opacity-50',
                )}
                checked={data.conditions_checked}
                onChange={(e) => handleChange(e, 'conditions_checked')}
                disabled={loading}
              />
              <p className="mt-0.5">
                {t('Auth.Register.politic')}
                <span className="ml-1 font-bold underline cursor-pointer">
                  {t('Footer.conditions')}
                </span>
                <span className="ml-1">{t('Auth.Register.and')}</span>
                <span className="ml-1 font-bold underline cursor-pointer">
                  {t('Footer.privacy')}
                </span>
              </p>
            </div>
            <div className="flex space-x-2 items-center text-[9px]">
              <input
                type="checkbox"
                className={classNames(
                  'w-5 h-5 rounded-md border-2 scale-75 border-gray-400 focus:outline-none cursor-pointer  duration-100 checked:bg-primary checked:border-transparent focus:ring-0 focus:ring-offset-0 focus:ring-primary focus:text-primary hover:text-primary hover:border-primary',
                  loading && 'cursor-not-allowed opacity-50',
                )}
                checked={data.marketing_checked}
                onChange={(e) => handleChange(e, 'marketing_checked')}
                disabled={loading}
              />
              <p className="mt-0.5">{t('Auth.Register.subscribe')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          type="submit"
          className={classNames(
            'w-full flex justify-center items-center text-white rounded-lg px-4 py-2',
            !loading
              ? 'bg-primary hover:shadow-xl hover:shadow-gray-300 active:font-semibold'
              : 'bg-red-300',
          )}
          disabled={loading}
        >
          {loading && <Spin />}
          {loading ? t('Auth.Register.registering') : t('Auth.Register.register')}
        </button>
      </div>
    </form>
  );
};
