import ApiRoutes from '../../routes/api/paths';
import { headers } from '../../config/headers';

/**
 * @description List all tags
 * @param {*} access_token
 * @returns tags
 */

export const tagList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token),
  };
  return fetch(ApiRoutes.tagList, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

/**
 * @description List all associated list
 * @param {*} access_token
 * @returns associated lists
 */
export const associatedList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token),
  };
  return fetch(ApiRoutes.associatedList, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const getClientsByParams = async ({ tags, lists, token }) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify({ tags, lists }),
  };
  return fetch(ApiRoutes.filterByParams, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const assignMultipleTags = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify({
      tagIds: params.tagIds,
      clientIds: params.clientIds
    }),
  };
  return fetch(ApiRoutes.assignMultipleTags, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const assignMultipleList = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify({
      listIds: params.listIds,
      clientIds: params.clientIds
    }),
  };
  return fetch(ApiRoutes.assignMultipleList, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const updateList = async ({ token, list }) => {

  const newData = {
    title: list.title,
  };

  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify(newData),
  };

  return fetch(`${ApiRoutes.listUpdate}/${list.id}`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ListDeleteMultiple = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      listIds: params.ids,
    }),
  };
  return fetch(ApiRoutes.listDeleteMultiple, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const AddList = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.list),
  };
  return fetch(ApiRoutes.listAdd, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const AddTag = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.tag),
  };
  return fetch(ApiRoutes.tagAdd, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const tagUpdate = async ({ token, tag }) => {

  const newData = {
    title: tag.title,
  };

  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify(newData),
  };

  return fetch(`${ApiRoutes.tagUpdate}/${tag.id}`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();

      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const tagDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      tagIds: params.ids,
    }),
  };
  return fetch(ApiRoutes.tagDelete, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

