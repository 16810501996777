import { useState } from 'react';
import { TemplateSelector } from '../templateLanding';
import { IoIosArrowBack } from 'react-icons/io';
import { classNames } from '../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeStep,
  resetState,
  selectCreatedVideoData,
  templateSelected,
} from '../../../features/videos/videosSlice';
import useAuthContext from '../../../hooks/useAuthContext';
import { useMutation } from 'react-query';
import { DownloadLandingLinks, SendLandingLink } from '../../../services/video/VideoService';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../../hooks/useToast2';
import { useVideo } from '../context/VideoContext';
import ListContacts from './SendStepElements/ListContacts';
import Sidebar from './SendStepElements/Sidebar';

export const SendStep = () => {
  const { config, isRendering, isSending, isUploading, selectedContacts } = useVideo();
  const dataToSend = { contacts: selectedContacts };
  const dispatch = useDispatch();

  const [data, setData] = useState(dataToSend);
  const [selected, setSelected] = useState(0);

  const { user } = useAuthContext();

  const templateNum = useSelector(templateSelected);

  const { t } = useTranslation();
  const { showToast } = useToast2();

  // Id del video creado
  const createdVideoData = useSelector(selectCreatedVideoData);

  const mutationDownloadLinks = useMutation('DOWNLOAD_LINKS', DownloadLandingLinks, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.video.error.download_links') });
    },
    onSuccess: (blob) => {
      const url = URL.createObjectURL(blob.data);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'landing-links.xlsx';
      document.body.appendChild(link);

      // Simula un clic en el enlace para descargar el archivo
      link.click();

      // Elimina el objeto URL y el enlace del DOM
      URL.revokeObjectURL(url);
      link.remove();
    },
  });

  const mutationSendLandingLink = useMutation('SEND_LANDING_LINK', SendLandingLink, {
    onError: () => {
      showToast({ show: true, type: 'error', message: t('Message.video.error.send_email') });
    },
    onSuccess: (response) => {
      if (response.status == 200) {
        showToast({
          show: true,
          type: 'success',
          message: t('Message.video.success.send_email'),
        });
      }
    },
  });

  const handlePrev = () => {
    dispatch(changeStep(3));
  };

  /**
   * Aqui se coge el id del video creado para descargar los links
   * solo se descarga si el id ya está definido
   */
  const handleDownLoadLandignLinks = () => {
    if (createdVideoData)
      mutationDownloadLinks.mutate({ token: user.access_token, videoId: createdVideoData.id });
  };

  const handleCompletionVideoCreation = () => {
    const tokens =
      createdVideoData && createdVideoData?.sendingTo ? createdVideoData?.sendingTo : [];

    const emails = data.contacts.map(({ id, email }) => {
      const tokenData = tokens.find((token) => token.id == id);
      return {
        correo: email,
        asunto: '',
        token: tokenData.token,
      };
    });

    setTimeout(() => {
      dispatch(resetState());
    }, 500);

    mutationSendLandingLink.mutate({ token: user.access_token, emailData: { emails } });
  };

  return (
    <div className="w-full mt-12 relative flex flex-col">
      <div className="flex space-x-8">
        <div
          className={classNames(
            'flex-shrink overflow-hidden 2xl:block',
            data?.contacts.length > 1 && data?.contacts.length < 10 && 'hidden',
          )}
        >
          <TemplateSelector
            number={templateNum}
            className={classNames(
              data?.contacts.length > 1 && data?.contacts.length < 10
                ? 'scale-50'
                : 'scale-90 2xl:scale-100',
            )}
            video={'https://www.youtube.com/watch?v=5qap5aO4i9A'}
            color={'1E4372'}
            config={config}
            selected={data.contacts[selected]}
          />
        </div>

        {data?.contacts.length > 1 && data?.contacts.length < 10 && (
          <div className="w-[310px] 2xl:w-[343px]">
            <ListContacts
              contacts={dataToSend.contacts} // Aqui no se usa data.contacts porque la tabla no debe cambiar cuando se cambie el correo en el formulario de enviar correo
              onSelected={setSelected}
              onDownloadLinks={handleDownLoadLandignLinks}
              finishedRendering={!isRendering && !isUploading && !isSending}
            />
          </div>
        )}

        {data?.contacts.length < 10 && (
          <div className="w-[310px] 2xl:w-[343px]">
            <Sidebar
              templateNumber={templateNum}
              dataSelected={data.contacts[selected]}
              onDataChange={(data) =>
                setData((prev) => {
                  const newData = [...prev.contacts];
                  newData[selected] = data;
                  return { ...prev, contacts: newData };
                })
              }
              finishedRendering={!isRendering && !isUploading && !isSending}
            />
          </div>
        )}
      </div>
      <div className="flex mt-5 2xl:mt-10">
        <button
          type="button"
          className="flex items-center justify-center h-12 px-2 mr-4 rounded-xl  text-gray-300 border-2 border-gray-300 font-semibold text-lg hover:shadow-lg active:scale-[0.98] disabled:opacity-40"
          onClick={handlePrev}
          disabled={isUploading}
        >
          <IoIosArrowBack size={30} />
        </button>
        <button
          type="button"
          className={classNames(
            'flex items-center justify-center h-12 px-4 rounded-xl bg-primary text-white font-semibold text-lg hover:shadow-lg active:scale-[0.98]',
            data?.contacts.length >= 10 ? '-bottom-6' : 'bottom-6',
          )}
          onClick={handleCompletionVideoCreation}
        >
          {t('Video.finish')}
        </button>
      </div>
    </div>
  );
};
