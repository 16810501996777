import { Bars3Icon, MagnifyingGlassIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Routes from '../../routes/app/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateSelect from 'react-select/creatable';
import Select from 'react-select';
import { useMutation, useQuery } from 'react-query';
import {
  AddList,
  AddTag,
  assignMultipleList,
  assignMultipleTags,
  associatedList,
  getClientsByParams,
  tagList,
} from '../../services/client/TagService';
import useAuthContext from '../../hooks/useAuthContext';
import { ClientsList } from '../../services/client/ClientService';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  open,
  refetch,
  setAcceptAction,
  setElements,
  setLabelFor,
  setOpen,
  setRefetch,
} from '../../features/common/commonSlice';
import { setStep } from '../../features/client/clientSlice';
import { Table } from '../../components/common/Table';
import { useToast2 } from '../../hooks/useToast2';
import { useMemo } from 'react';
import { CustomMultiValue } from '../../components/common/SelectComponents';

export default function ContactList() {
  /* State */
  const [selectedItem, setSelectedItem] = useState([]);
  const [tags, setTags] = useState([]);
  const [lists, setLists] = useState([]);
  const [isLoadingTag, setIsLoadingTag] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingAssignedTags, setIsLoadingAssignedTags] = useState(false);
  const [isLoadingAssignedList, setIsLoadingAssignedList] = useState(false);
  const [tag, setTag] = useState([]);
  const [list, setList] = useState([]);
  const [clients, setClients] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [query, setQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [params, setParams] = useState(null);
  const [assignedTags, setAssignedTags] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [order, setOrder] = useState({ column: 'name', type: 'desc' });

  /* Hooks */
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { showToast } = useToast2();
  const dispatch = useDispatch();
  const refetching = useSelector(refetch);
  const location = useLocation();

  //  effects
  useEffect(() => {
    if (params !== null && tag.length === 0 && list.length === 0) {
      navigate(Routes.contacts);
    }

    setQuery('');
    tagListFilter();
    setIsLoadingTag(false);
    setIsLoadingList(false);
  }, [tag, list]);

  useEffect(() => {
    refetching && getClients();
  }, [refetching]);

  /* Querys */
  const { refetch: getClients, data: dataClients, isLoading } = useQuery(
    ['CLIENTS', { token: user.access_token }],
    ClientsList,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        showToast({ show: true, type: 'error', message: t('Message.client.error.load_all') });
      },
    },
  );

  useEffect(() => {
    if (dataClients && dataClients.data) {
      const { data } = dataClients.data;
      const searchParams = new URLSearchParams(location.search);

      setClients(data);
      setFilteredList(data.sort((a, b) => (a.name > b.name ? -1 : 1)));
      // Set selectedItem with filtered elements
      const filteredSelectedItems = selectedItem
        .filter((item) =>
          data.some((client) => client.id === item.id)
        )
        .sort((a, b) => (a.name > b.name ? -1 : 1));
      setSelectedItem(filteredSelectedItems);

      dispatch(setRefetch(false));
      setSelectedItem([]);

      if (params !== null) {
        const defaultTag = tags.find((item) => item.value == params.id);
        if (params.filter === 'tag') {
          setTag([defaultTag]);
          tagListFilter();
        } else {
          setTag([]);
        }
        const defaultList = lists.find((item) => item.value == params.id);
        if (params.filter === 'list') {
          setList([defaultList]);
          tagListFilter();
        } else {
          setList([]);
        }
      }

      if (searchParams.size > 0)
        setParams({
          filter: searchParams.get('filter'),
          id: searchParams.get('id'),
        });
    }
  }, [dataClients, refetching]);

  useQuery(['TAG_LIST', { token: user.access_token }], tagList, {
    onError: (error) => {
      showToast({show: true, type: 'error', message: t('Message.client.error.load_all')});
    },
    onSuccess: (response) => {
      const { data } = response.data;
      setTags(data);

      if (params !== null) {
        const defaultV = data.find((item) => item.value == params.id);
        if (params.filter === 'tag') {
          setTag([defaultV]);
          tagListFilter();
        } else {
          setTag([]);
        }
      }
    },
  });

  useQuery(['ASSOCIATED_LIST', { token: user.access_token }], associatedList, {
    onError: (error) => {
      showToast({show: true, type: 'error', message: t('Message.client.error.load_all')});
    },
    onSuccess: (response) => {
      const { data } = response.data;
      setLists(data);

      if (params !== null) {
        const defaultV = data.find((item) => item.value == params.id);
        if (params.filter === 'list') {
          setList([defaultV]);
          tagListFilter();
        } else {
          setList([]);
        }
      }
    },
  });

  /* Mutations */
  const mutationListAdd = useMutation(['CREATE_LIST_ITEM'], AddList, {
    onError: (error) => {
      setIsLoadingAssignedList(false);
      showToast({show: true, type: 'error', message: t('Message.client.error.created_list')});
    },
    onSuccess: (response) => {
      const { data } = response.data;
      setLists([...lists, data]);
      setList([...list, data]);
      setAssignedList([...assignedList, data]);
      addMultipleList([data]);
      setTimeout(
        () => showToast({show: true, type: 'success', message: t('Message.client.success.created_list')}),
        1000,
      );
      setIsLoadingAssignedList(false);
      dispatch(setRefetch(true));
    },
  });

  const mutationTagAdd = useMutation(['CREATE_TAG_ITEM'], AddTag, {
    onError: (error) => {
      setIsLoadingAssignedTags(false);
      showToast({show: true, type: 'error', message: t('Message.client.error.created_tag')});
    },
    onSuccess: (response) => {
      const { data } = response.data;
      setTags([...tags, data]);
      setTag([...tag, data]);
      setAssignedTags([...assignedTags, data]);
      addMultipleTags([data]);
      setTimeout(
        () => showToast({show: true, type: 'success', message: t('Message.client.success.created_tag')}),
        1000,
      );
      setIsLoadingAssignedTags(false);
      dispatch(setRefetch(true));
    },
  });

  /* Consts */
  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: 10,
      border: '2px solid #c0c0c0',
      color: '#c0c0c0',
      fontWeight: 600,
      width: '100%',
      flex: 1,
      padding: '0 0.2rem',
      marginRight: '1.8rem',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '12px', // Tamaño base del texto
      '@media (min-width: 1536px)': {
        padding: '0 0.1rem',
        fontSize: '18px', // Tamaño para 2xl
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'white',
      color: 'gray',
      fontSize: '12px', // Tamaño base del texto
      '@media (min-width: 1536px)': {
        fontSize: '18px', // Tamaño para 2xl
      },
      '&:hover': {
        backgroundColor: '#ccc',
      },
    }),
  };

  const headersTable = [
    { title: t('Contact.table.name'), dropdown: true },
    { title: t('Contact.table.last_name'), dropdown: true },
    { title: t('Contact.table.email'), dropdown: true },
    { title: t('Contact.table.sent_videos'), dropdown: true },
    { title: t('Contact.table.last_send'), dropdown: true },
    { title: t('Contact.table.info'), dropdown: false },
  ];

  /* Functions */
  const bodyTable = useMemo(() => {
    return filteredList
    ?.map((item) => {
      const tdName = { type: 'text', value: item.name, name: 'name', id: item.id };
      const tdLastName = { type: 'text', value: item.last_name, name: 'last_name' };
      const tdEmail = { type: 'text', value: item.email, name: 'email' };
      const tdSendVideos = { type: 'number', value: item.client_video.length, name: 'send_videos' };
      const tdLastVideo = {
        type: 'date',
        value:
          item.client_video.length > 0
            ? item.client_video[item.client_video.length - 1]?.updated_at.split('T')[0]
            : '',
        name: 'last_video',
      };
      const tdInfo = { type: 'button', value: t('Contact.info_button'), route: `${Routes.contactProfile}/${item.id}` };
      return [tdName, tdLastName, tdEmail, tdSendVideos, tdLastVideo, tdInfo];
    })
    .sort((a, b) => {
      let x = null;
      let y = null;
      if (order.column === t('Contact.table.name')) {
        x = a[0].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[0].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (order.column === t('Contact.table.last_name')) {
        x = a[1].value !== null ? a[1].value.toLowerCase() : 'zzz';
        y = b[1].value !== null ? b[1].value.toLowerCase() : 'zzz';
      } else if (order.column === t('Contact.table.email')) {
        x = a[2].value !== null ? a[2].value.toLowerCase() : 'zzz';
        y = b[2].value !== null ? b[2].value.toLowerCase() : 'zzz';
      } else if (order.column === t('Contact.table.sent_videos')) {
        x = a[3].value;
        y = b[3].value;
      } else if (order.column === t('Contact.table.last_send')) {
        x = a[4].value !== null ? a[4].value.toLowerCase() : 'zzz';
        y = b[4].value !== null ? b[4].value.toLowerCase() : 'zzz';
      }
      if (x < y) {
        return order.type === 'asc' ? -1 : 1;
      }
      if (x > y) {
        return order.type === 'asc' ? 1 : -1;
      }
      return 0;
    }) || [];
  }, [filteredList, order]);

  const orderTable = (column) => {
    let orderType = 'asc';
    if (order.column === column && order.type === 'asc') {
      orderType = 'desc';
    }
    // Actualizamos el estado del orden
    setOrder({
      column: column,
      type: orderType,
    });
  };

  //funciones de filtrado
  const handleSearchQuery = (searchValue) => {
    setQuery(searchValue);
    let searchClients = clients;

    const searchFilter = (data) => {
      return data.filter(
        (e) =>
          e.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          e.last_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          e.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
          e.client_video?.length.toString() === searchValue ||
          moment(e.client_video[e.client_video.length - 1]?.video.created_at)
            .format('YYYY-MM-DD')
            .includes(searchValue.toLowerCase()),
      );
    };

    searchValue !== ''
      ? tag.length > 0 || list.length > 0
        ? (searchClients = searchFilter(filteredList))
        : (searchClients = searchFilter(clients))
      : tag.length > 0 || list.length > 0
        ? (searchClients = clients)
        : (searchClients = clients);

    setFilteredList(searchClients);
    
    // Set selectedItem with filtered elements
    const filteredSelectedItems = selectedItem.filter((item) =>
      searchClients.some((client) => client.id === item.id)
    );
    setSelectedItem(filteredSelectedItems);
  };

  const tagListFilter = () => {
    const tagsIds = tag?.length > 0 ? tag.map((t) => t.value) : [];
    const listIds = list?.length > 0 ? list.map((l) => l.value) : [];

    getClientsByParams({ tags: tagsIds, lists: listIds, token: user.access_token })
      .then((response) => {
        const { data } = response.data;
        setFilteredList(data);
        // Set selectedItem with filtered elements
        const filteredSelectedItems = selectedItem.filter((item) =>
          data.some((client) => client.id === item.id)
        );
        setSelectedItem(filteredSelectedItems);
      })
      .catch((err) =>
        showToast({
          show: true,
          type: 'error',
          message: t('Message.service_not_available') + err.status,
        }),
      )
      .finally(() => {
        setIsLoadingTag(false);
        setIsLoadingList(false);
      });
  };

  const handleCreateList = (inputValue) => {
    if (inputValue.length <= 20) {
      setIsLoadingAssignedList(true);
      const newOption = createOption(inputValue);
      mutationListAdd.mutate({ token: user.access_token, list: { title: newOption.label } });
    } else {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.client.error.list_size'),
      });
    }
  };

  const handleCreateTag = (inputValue) => {
    if (inputValue.length <= 20) {
      setIsLoadingAssignedTags(true);
      const newOption = createOption(inputValue);
      mutationTagAdd.mutate({ token: user.access_token, tag: { title: newOption.label } });
    } else {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.client.error.tag_size'),
      });
    }
  };

  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  const addMultipleTags = (newTags) => {
    if (selectedItem.length > 0) {
      const params = {
        tagIds: newTags.map((tag) => tag.value),
        clientIds: selectedItem.map((user) => user.id),
        token: user.access_token,
      };
      assignMultipleTags(params)
        .then((res) => {
          showToast({ show: true, type: 'success', message: t('Message.tag.success.assing') });
        })
        .catch((err) => showToast({ show: true, type: 'error', message: err }));
    }
  };

  const addMultipleList = (newTags) => {
    if (selectedItem.length > 0) {
      const params = {
        listIds: newTags.map((tag) => tag.value),
        clientIds: selectedItem.map((user) => user.id),
        token: user.access_token,
      };
      assignMultipleList(params)
        .then((res) => {
          showToast({
            show: true,
            type: 'success',
            message: t('Message.list.success.assing'),
          });
        })
        .catch((err) => showToast({ show: true, type: 'error', message: err }));
    }
  };

  const removeItems = () => {
    if (selectedItem.length > 0) {
      const ids = selectedItem.map((client) => client.id);

      dispatch(setLabelFor('cliente'));
      dispatch(setOpen(true));
      dispatch(setElements(ids));
      dispatch(setAcceptAction('removeClient'));
    }
  };
  
  const isAgente = user?.role === 'Agente';
  
  return (
    <>
      <div className="flex p-1 space-x-5 mt-4 items-start justify-between">
        <div className="flex space-x-1 2xl:space-x-2">
          <Select
            components={{ MultiValue: CustomMultiValue }}
            options={tags}
            isMulti
            isClearable={false}
            value={tag}
            isLoading={isLoadingTag}
            placeholder={t('Placeholder.filter_by_tag')}
            styles={styles}
            className={'z-30'}
            onChange={(newValue) => {
              setIsLoadingTag(true);
              setTag(newValue);
            }}
          />
          <Select
            components={{ MultiValue: CustomMultiValue }}
            options={lists}
            isMulti
            isClearable={false}
            value={list}
            isLoading={isLoadingList}
            placeholder={t('Placeholder.filter_by_list')}
            styles={styles}
            className={'z-30'}
            onChange={(newValue) => {
              setIsLoadingList(true);
              setList(newValue);
            }}
          />
          <CreateSelect
            components={{ MultiValue: CustomMultiValue }}
            onCreateOption={handleCreateTag}
            options={tags}
            isMulti
            isLoading={isLoadingAssignedTags}
            value={assignedTags}
            isClearable={false}
            className={'z-30'}
            onChange={(newValue) => {
              setAssignedTags(newValue);
              addMultipleTags(newValue);
            }}
            placeholder={t('Placeholder.create_assing_tag')}
            styles={styles}
          />
          <CreateSelect
            components={{ MultiValue: CustomMultiValue }}
            onCreateOption={handleCreateList}
            options={lists}
            isMulti
            isLoading={isLoadingAssignedList}
            isClearable={false}
            value={assignedList}
            className={'z-30'}
            onChange={(newValue) => {
              setAssignedList(newValue);
              addMultipleList(newValue);
            }}
            placeholder={t('Placeholder.create_assing_list')}
            styles={styles}
          />
        </div>
        <div className="flex items-center space-x-2 2xl:space-x-4 flex-grow justify-end w-full">
          <input
            type="text"
            placeholder={t('Placeholder.search')}
            value={query}
            className="border-gray-300 text-sm 2xl:text-md rounded-lg focus:border-gray-300 focus:ring-1 focus:ring-gray-300 w-32 2xl:w-36 placeholder:text-gray-400"
            onChange={(e) => handleSearchQuery(e.target.value)}
          />
          {/* </Transition> */}
          <MagnifyingGlassIcon
            className={classNames(
              'text-primary',
              'font-extralight w-6 2xl:w-8 h-6 2xl:h-8 cursor-pointer',
            )}
            onClick={() => {
              setShowSearchInput(!showSearchInput);
            }}
          />
          {/*           <ArrowUpTrayIcon className="font-extralight w-8 h-8 cursor-pointer" />
           */}{' '}
          { !isAgente && <TrashIcon
            className={classNames(
              selectedItem.length !== 0
                ? 'cursor-pointer text-black focus:text-primary'
                : 'cursor-not-allowed text-gray-200',
              'font-extralight w-6 2xl:w-8 h-6 2xl:h-8',
            )}
            onClick={removeItems}
          />}
        </div>
      </div>
      <div className="sm:px-6 lg:px-0">
        <div className="mt-4 2xl:mt-8 flow-root">
          <div className="-ml-7">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative h-[330px] 2xl:h-[490px]">
                <Table
                  header={headersTable}
                  body={bodyTable}
                  hasCheckable="true"
                  selectedItems={selectedItem}
                  clients={clients}
                  onSelect={setSelectedItem}
                  onOrder={orderTable}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
          <div className="flex space-x-4 mt-8 2xl:mt-16 text-sm 2xl:text-md items-center py-2">
            <button
              className="flex items-center py-2.5 px-3 text-white rounded-md bg-primary hover:shadow-xl"
              onClick={() => navigate(Routes.newContact)}
            >
              <PlusIcon className="mr-2 w-5 2xl:w-6 h-5 2xl:h-6" />
              <span>{t('Contact.new_contact')}</span>
            </button>
            <button
              onClick={() => {
                dispatch(setStep(1));
                navigate(Routes.importContacts);
              }}
              className="flex items-center py-2.5 px-3 text-black-soft rounded-md bg-white ring-2 ring-gray-button hover:shadow-xl"
            >
              <Bars3Icon className="mr-2 w-5 2xl:w-6 h-5 2xl:h-6" />
              {t('Contact.import_list')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
