import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { Spin, Spinner, Title } from '../../components/common'
import { Avatar } from '../../components/common/Avatar'
import { ChangePasswordSchema } from '../../config/formsValidators'
import useAuthContext from '../../hooks/useAuthContext';
import { ProfileForm } from '../../components/common/ProfileForm';
import { useToast2 } from '../../hooks/useToast2'
import { putUpdatePass } from '../../services/profile/profileService'
import { getUserOrCompanyById } from '../../services/admin/adminService'
import { UserService } from '../../services/auth/AuthService'
import { Input } from '../../components/common/Input'
import { DOMAIN } from '../../routes/server/paths'
import { generateImageUrl } from '../../config/utils'

export const Account = () => {
  // states
  const [userInfo, setUserInfo] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmedPassword: ''
    },
    resolver: yupResolver(ChangePasswordSchema),
  });

  // hooks
  const { t } = useTranslation();
  const { user, updateUser } = useAuthContext();
  const { showToast } = useToast2();
  const queryClient = useQueryClient();

  // querys
  const { data: userData, isLoading: loadingUser, error: errorUser } = useQuery(['USER'],
    () => getUserOrCompanyById({ token: user.access_token, search: 'user', id: user.id }), {
    onError: (err) => {
      console.log(err);
    }
  });

  useEffect(effect => {
    if (userData) {
      setUserInfo(userData.data);
    }
  }, [userData]);
  
  const { mutate: mutateUpdatePass, isLoading: loadingUpdatePass, error: errorUpdatePass } = useMutation((opt) => putUpdatePass(opt), {
    onSuccess: async () => {
      reset();
      showToast({ show: true, type: 'success', message: t('Message.password.success.update') });
    },
    onError: (err) => {
      console.log(err);
      showToast({ show: true, type: 'error', message: t('Message.password.error.update') })
    }
  });

  const onSubmitChangePass = (data) => {
    const isValid = Object.keys(errors).length === 0;
    isValid && mutateUpdatePass({ token: user.access_token, id: user.id, data });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setValue('avatar', file);
      setSelectedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const updateField = (field, value) => {
    setValue(field, value)
  };

  // functions
  const updateUserAuthData = async () => {
    try {
      const fetchUser = await queryClient.fetchQuery([user.access_token], UserService);
      if (fetchUser.status === 200) {
        updateUser(fetchUser.user);
      }
    } catch (error) {
      if (error.status === 400) {
        showToast({ show: true, type: 'error', message: t('Message.auth.error.credential') });
      } else {
        showToast({ show: true, type: 'error', message: t('Message.error') });
      }
    }
  };

  const isUrlWithoutFile = (url) => url.substring(url.length-1, url.length) === '/';
  
  return (
    <>
      <div className='grid grid-cols-3 gap-2 mt-10'>
        <span className={'col-start-2 col-span-2 mb-3'} >
          <Title title={t('Profile.profile')} size={'text-lg'} />
        </span>
        <div className='flex justify-center'>
          <Avatar selectedImage={selectedImage} avatar={userInfo?.avatar && !isUrlWithoutFile(userInfo?.avatar) && generateImageUrl(userInfo?.avatar)} onUploadImage={handleImageUpload} />
        </div>
        <div className='col-span-2'>
          <ProfileForm onSubmit={updateUserAuthData} currentProfile={userInfo} avatar={watch().avatar || userInfo?.avatar} />
          <hr className='border-1 mt-8 mb-6' />
          <div>
            <div className={'mb-3'}>
              <Title title={t('Auth.PasswordChange.meta')} size={'text-lg'} />
            </div>
            <form onSubmit={handleSubmit(onSubmitChangePass)}>
              <div className='grid grid-cols-2 gap-y-8 gap-x-24'>
                <Input
                  {...register('currentPassword')}
                  value={watch().currentPassword}
                  onChange={(e) => updateField('currentPassword', e.target.value)}
                  className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                  type="password"
                  placeholder={t('Placeholder.current_password')}
                  showError={errors.currentPassword ? { message: errors.currentPassword?.message } : ''}
                />
                <div></div>
                <Input
                  {...register('newPassword')}
                  value={watch().newPassword}
                  onChange={(e) => updateField('newPassword', e.target.value)}
                  className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                  type="password"
                  placeholder={t('Placeholder.new_password')}
                  showError={errors.newPassword ? { message: errors.newPassword?.message } : ''}
                />
                <div></div>
                <Input
                  {...register('confirmedPassword')}
                  value={watch().confirmedPassword}
                  onChange={(e) => updateField('confirmedPassword', e.target.value)}
                  className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                  type="password"
                  placeholder={t('Placeholder.confirmed_password')}
                  showError={errors.confirmedPassword ? { message: errors.confirmedPassword?.message } : ''}
                />
                <div></div>
              </div>
              <button
                type="submit"
                className="flex gap-2 items-center justify-center h-10 2xl:h-10 px-5 mt-5 ms-2 rounded-xl bg-primary text-white font-semibold hover:shadow-lg active:scale-[0.98] disabled:bg-gray-600"
                disabled={loadingUpdatePass}
              >
                { loadingUpdatePass && <Spin />}
                {!loadingUpdatePass ? t('Auth.PasswordChange.update') : t('Auth.PasswordChange.updating')}
              </button>
            </form>
          </div>
          <div className='flex mt-12'>
            <span className='text-gray-400 underline mr-5 cursor-pointer'>{t('Links.legal_terms')}</span>
            <span className='text-gray-400 underline mx-5 cursor-pointer'>{t('Links.cookie_preferences')}</span>
          </div>
        </div>

        {/* Loader */}
        <Spinner open={loadingUser} title={t('Library.loading')} />
      </div>
    </>
  )
}
