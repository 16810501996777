import { motion } from 'framer-motion'

const HeightIn = ({ children }) => {
  const transition={
    height: { duration: 0.25 },
    opacity: { duration: 0.25 },
    exit: { duration: 0.25, delay: 0.5 }
  }

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={transition}
    >
      {children}
    </motion.div>
  )
}

export default HeightIn