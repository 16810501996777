import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 1,
  replaceClient: false,
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  tags: [],
  list: [],
  previewClients: [],
  finalClients: [],
  file: null,
  openAddTagListModal: false,
  formType: '',
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    logout: () => initialState,
    
    increment: (state) => {
      state.step += 1;
    },
    decrement: (state) => {
      state.step -= 1;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    replaceClients: (state, replacing) => {
      state.replaceClient = replacing.payload;
    },
    addTags: (state, tags) => {
      state.tags = tags.payload;
    },
    addList: (state, list) => {
      state.list = list.payload;
    },
    setPreviewClients: (state, items) => {
      state.previewClients = items.payload;
    },
    setFinalClients: (state, items) => {
      state.finalClients = items.payload;
    },
    setFile: (state, file) => {
      state.file = file.payload;
    },
    setOpenAddTagListModal: (state, action) => {
      state.openAddTagListModal = action.payload;
    },
    setFormType: (state, action) => {
      state.formType = action.payload;
    },
    resetStore: (state) => {
      state.step = 1;
      state.replaceClient = false;
      state.tags = [];
      state.list = [];
      state.previewClients = [];
      state.finalClients = [];
      state.file = null;
      state.openAddTagListModal = false;
    },
  },
});

export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    //dispatch(incrementByAmount(amount));
  }, 1000);
};

//instrucciones para useSelector
export const stepStatus = (state) => state.client.step;
export const previewClients = (state) => state.client.previewClients;
export const replace = (state) => state.client.replaceClient;
export const hasTags = (state) => state.client.tags.length;
export const hasList = (state) => state.client.list.length;
export const tags = (state) => state.client.tags;
export const list = (state) => state.client.list;
export const open = (state) => state.client.openAddTagListModal;
export const formType = (state) => state.client.formType;
export const loading = (state) => state.client.loading;
export const finalClients = (state) => state.client.finalClients;
export const file = (state) => state.client.file;

//actions
export const {
  logout,
  increment,
  decrement,
  replaceClients,
  addTags,
  addList,
  setPreviewClients,
  setFinalClients,
  setFile,
  setFormType,
  setOpenAddTagListModal,
  resetStore,
  setStep,
} = clientSlice.actions;
export default clientSlice.reducer;
