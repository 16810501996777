import {
  FolderIcon,
  ListBulletIcon,
  PlusIcon,
  Squares2X2Icon,
  TrashIcon,
} from '@heroicons/react/24/outline';

export const sectionList = [
  {
    id: 1,
    title: 'Videos nuevos',
    videos: [
      {
        id: 1,
        owner: true,
        linked: false,
        title: 'Video Nuevo 1',
      },
      {
        id: 2,
        owner: false,
        linked: false,
        title: 'Video Nuevo 2',
      },
      {
        id: 3,
        owner: false,
        linked: true,
        title: 'Video Nuevo 3',
      },
      {
        id: 4,
        owner: false,
        linked: false,
        title: 'Video Nuevo 4',
      },
    ],
  },
  {
    id: 2,
    title: 'Videos Apple',
    videos: [
      {
        id: 1,
        owner: true,
        linked: false,
        title: 'Videos Apple 1',
      },
      {
        id: 2,
        owner: false,
        linked: true,
        title: 'Videos Apple 2',
      },
      {
        id: 3,
        owner: false,
        linked: false,
        title: 'Videos Apple 3',
      },
      {
        id: 4,
        owner: true,
        linked: false,
        title: 'Videos Apple 4',
      },
    ],
  },
];

export function getFolders() {
  return [
    {
      id: 1,
      title: 'Carpeta sin titulo',
    },
    {
      id: 2,
      title: 'Video Ford',
    },
    {
      id: 3,
      title: 'Otra',
    },
  ];
}

export const NamesLibrary = {
  corporative: 'Videos de Empresa',
  video: 'Videos creados',
  record: 'Grabaciones',
  frontPage: 'Portadas',
};

const options = {
  videoOptions: [
    { id: 1, title: 'Library.options.create_folder', action: 'create_folder' },
    { id: 2, title: 'Library.options.create_video', action: 'create_video' }
  ],
  corporativeOptions: [
    { id: 1, title: 'Library.options.create_folder', action: 'create_folder' },
    { id: 2, title: 'Library.options.upload_clip', action: 'upload_clip' }
  ],
  recordOPtions: [
    { id: 1, title: 'Library.options.create_folder', action: 'create_folder' },
    { id: 3, title: 'Library.options.create_recording', action: 'create_recording' },
  ],
  portraitsOptions: [
    { id: 1, title: 'Library.options.create_folder', action: 'create_folder' },
    // { id: 2, title: 'Library.options.create_portrait', action: 'create_portrait' },
    { id: 3, title: 'Library.options.upload_portrait', action: 'upload_portrait' },
  ]
}

export function getOptions(view = 'index', { folders, library }) {
  return [
    {
      id: 1,
      header: false,
      icon: PlusIcon,
      footer: false,
      options:
        library === 'video' && options.videoOptions ||
        library === 'corporative' && options.corporativeOptions ||
        library === 'record' && options.recordOPtions ||
        library === 'portrait' && options.portraitsOptions
    },
    {
      id: 2,
      icon: FolderIcon,
      header: true,
      subheader: {
        title: NamesLibrary[library],
        icon: FolderIcon,
        options: folders.map((item) => {
          return {
            ...item,
          };
        }),
      },
      title: 'Library.options.move',
      footer: true,
      options: [],
      show: true,
    },
    {
      id: 3,
      icon: TrashIcon,
      action: 'delete',
      header: false,
      footer: false,
      options: [],
    },
    {
      id: 4,
      icon: ListBulletIcon,
      action: 'list',
      header: false,
      footer: false,
      options: [],
    },
    {
      id: 5,
      icon: Squares2X2Icon,
      action: 'miniature',
      header: false,
      footer: false,
      options: [],
    },
  ];
}

export function getTabs() {
  return [
    {
      id: 1,
      title: 'Videos',
      selected: true,
    },
    {
      id: 2,
      title: 'Archivados',
      selected: false,
    },
  ];
}

export const people = [
  { value: 1, label: 'Wade Cooper' },
  { value: 2, label: 'Arlene Mccoy' },
  { value: 3, label: 'Devon Webb' },
  { value: 4, label: 'Tom Cook' },
  { value: 5, label: 'Tanya Fox' },
  { value: 6, label: 'Hellen Schmvaluet' },
  { value: 7, label: 'Caroline Schultz' },
  { value: 8, label: 'Mason Heaney' },
  { value: 9, label: 'Claudie Smitham' },
  { value: 10, label: 'Emil Schaefer' },
];
