import HeaderField from "./Header/HeaderField"
import { useTable } from "./context/TableContext"

const HeaderTable = () => {
  const { header, handleSelectedAll, clients, selectedItems, onOrder, hasCheckable  } = useTable();

  return (
    <thead className="sticky top-0 z-20">
      <tr>
        {hasCheckable && (
          <HeaderField
            isCheckable={true}
            title={''}
            index={0}
            onSelect={handleSelectedAll}
            clients={clients}
            selectedItems={selectedItems}
          />
        )}
        {header.map((header, index) => (
          <HeaderField
            key={index}
            title={header.title}
            index={index}
            dropdown={header.dropdown}
            width={header.width}
            maxWidth={header.maxWidth}
            type={header.type}
            onOrder={onOrder}
            orderColumn={header.title}
            configCheckbox={{
              checked: header.checked,
            }}
            textWrap={header.textWrap}
          />
        ))}
      </tr>
    </thead>
  )
}
export default HeaderTable