import { Title } from ".";


export function Section({ title, option, color,size, children }) {
    return (
      <div className="my-16">
        <div className="max-w-2xl select-none">
         <Title size={size} weight="semi-bold" title={title} />
        </div>
        <div className="mx-auto mt-[15px]">
         {children}
        </div>
      </div>
    );
  }