import { IconButton, Input, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { LinkIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import useBatchLoader from '../../../hooks/useBatchLoader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { classNames } from '../../../config/utils';
import useDebounce from '../../../hooks/useDebounce';

const SearchInput = ({ onQueryChange }) => {
  const [localQuery, setLocalQuery] = useState('');
  const debouncedQuery = useDebounce(localQuery);

  useEffect(() => {
    onQueryChange(debouncedQuery);
  }, [debouncedQuery, onQueryChange]);
  
  const handleChange = (e) => {
    setLocalQuery(e.target.value);
  };

  return (
    <Input
      autoFocus
      colorScheme={'gray'}
      type="search"
      placeholder="Buscar cliente..."
      onChange={handleChange}
      value={localQuery}
    />
  );
};

const ClientsMenu = ({ clients, handleCopyUrl }) => {
  const [query, setQuery] = useState('');
  const items = useMemo(
    () =>
      query
        ? clients.filter((client) => client.name.toLowerCase().includes(query.toLowerCase()))
        : clients,
    [clients, query],
  );
  
  const id = useRef(Math.random().toString(36).substring(2, 9));
  
  const loadedItems = useBatchLoader(items, 10, `loadMoreTrigger-${id.current}`);

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={
          <UserGroupIcon className="w-6 h-6 p-1 rounded-full bg-gray-video cursor-pointer hover:text-primary" />
        }
        size={'xs'}
        borderRadius="full"
      />
      <MenuList pt={0} sx={{ maxHeight: '200px', overflowY: 'auto' }}>
        {/* search */}
        <div
          className={classNames(
            'sticky top-0 z-10 px-2 py-1 bg-white border-b border-gray-200',
            clients.length < 11 && 'hidden',
          )}
        >
          <SearchInput onQueryChange={setQuery} />
        </div>
        {loadedItems.map((client, index) => (
          <MenuItem
            key={`menuitem-${id}-${index}`}
            icon={<LinkIcon className="w-6 h-6 p-1" />}
            onClick={() => handleCopyUrl(client.token)}
          >
            {client.name || 'Sin nombre'}
          </MenuItem>
        ))}
        <div id={`loadMoreTrigger-${id.current}`}/>
      </MenuList>
    </Menu>
  );
};
export default ClientsMenu;
