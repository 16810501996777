import { useRef } from "react"
import useBatchLoader from "../../../../hooks/useBatchLoader"
import { useTable } from "../context/TableContext"
import ItemRow from "./ItemRow"
import SkeletonRow from "./SkeletonRow"

const ListItemRow = () => {
  const { body, header, hasCheckable } = useTable()

  const idRef = useRef(Math.random().toString(36).substring(7));
  const id = `table-loadMoreTrigger-${idRef.current}`

  const loadedItems = useBatchLoader(body, 50, id)

  const isLoading = body?.length > 0 && loadedItems.length === 0;
  
  return (
    <>
      {loadedItems.map((row, index) => (
        <ItemRow
          key={row[0]?.id || `rowTable-${index}`}
          index={index}
          row={row}
        />
      ))}
      {isLoading && (
        <>
          <SkeletonRow header={header} hasCheckable={hasCheckable} />
          <SkeletonRow header={header} hasCheckable={hasCheckable} />
          <SkeletonRow header={header} hasCheckable={hasCheckable} />
        </>
      )}
      <tr id={id} className="opacity-0" />
    </>
  )
}
export default ListItemRow