import { useEffect, useMemo, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../config/utils';
import useAuthContext from '../../hooks/useAuthContext';
import { useToast2 } from '../../hooks/useToast2';
import { archiveCompany, deleteCompany, getCompanies, restoreCompany } from '../../services/admin/adminService';
import { Table } from '../common';
import Routes from '../../routes/app/paths';
import { MdSettingsBackupRestore } from 'react-icons/md';
import ConfirmDelete from './elements/ConfirmDelete';
import { set } from 'react-hook-form';

export const CompanyList = ({ archived }) => {
  /* state */
  const [companies, setCompanies] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);
  const [order, setOrder] = useState({ column: 'name', type: 'desc' });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenRestore, setModalOpenRestore] = useState(false);

  /* hooks */
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const headersTable = [
    { title: t('AdminZone.company.table.name'), dropdown: true },
    { title: t('AdminZone.company.table.email'), dropdown: true },
    { title: t('AdminZone.company.table.phone'), dropdown: true },
    { title: t('AdminZone.company.table.web'), dropdown: true },
    { title: 'Info.', dropdown: false },
  ];

  //Effects
  useEffect(() => {
    setSelectedItem([]);
  }, [archived]);

  //Querys
  const { data: dataCompanies, isLoading: loadingCompanies, error: errorCompanies } = useQuery(['COMPANIES'], 
    () => getCompanies({ token: user.access_token }), {
    onError: (err) => {
      console.log(err);
    }
  });

  useMemo(() => {
    if (archived) {
      return setCompanies(dataCompanies?.data?.filter(c => c.deleted_at) || []);
    }
    return setCompanies(dataCompanies?.data?.filter(c => !c.deleted_at) || []);
  }, [dataCompanies, archived]);

  const { mutate: mutateDeleteCompany, isLoading: loadingDelete, error: errorDelete } = useMutation((opt) => {
    if (opt.restore) return restoreCompany(opt);
    return opt.permanent || archived
      ? deleteCompany(opt)
      : archiveCompany({
        ...opt,
        permanent: undefined,
      })
    },
  {
    onSuccess: async (_, opt) => {
      await queryClient.invalidateQueries(['COMPANIES']);
      handleSearchQuery(query);
      setSelectedItem([])
      showToast({ show: true, type: 'success', message: opt.restore 
        ? t('Message.company.success.restore')
        : t('Message.company.success.delete') });
      setModalOpen(false);
      setModalOpenRestore(false);
    },
    onError: (err, opt) => {
      queryClient.invalidateQueries(['COMPANIES']);
      console.log(err);
      showToast({ show: true, type: 'error', message: opt.restore 
        ? t('Message.company.error.restore')
        : t('Message.company.error.delete') })
    }
  });

  /* handlers */
  const handleSearchQuery = (query) => {
    setQuery(query);
  };

  const hanldleNewCompany = () => {
    navigate('/admin-zone/company-new');
  };

  
  const removeItems = () => {
    if (selectedItem.length === 0) return;
    setModalOpen(true);
    setModalOpenRestore(false);
  };
  
  const restoreItems = () => {
    if (selectedItem.length === 0) return;
    setModalOpenRestore(true);
    setModalOpen(false);
  };
  
  const handleConfirmModal = (confirm, permanent, restore) => {
    confirm
      ?
      mutateDeleteCompany({ token: user.access_token, companyId: selectedItem[0].id, permanent, restore })
      :
      (setModalOpen(false), setModalOpenRestore(false));
  };

  /* other functions */
  //Creando el cuerpo de la tabla
   const bodyTable = useMemo(() => {
    return companies
    // filter by query
    ?.filter(c => 
      query !== '' ?
      (
        c.name?.toLowerCase().includes(query.toLowerCase()) ||      
        c.email?.toLowerCase().includes(query.toLowerCase()) ||      
        c.phone?.toLowerCase().includes(query.toLowerCase()) ||      
        c.web?.toLowerCase().includes(query.toLowerCase())
      ) : c // si no hay query, retorna todos
    )
    // map to table body
    ?.map((item) => {
      const tdName = { type: 'text', value: item.name, name: 'name', id: item.id };
      const tdEmail = { type: 'text', value: item.email, name: 'email' };
      const tdPhone = { type: 'text', value: item.phone, name: 'phone' };
      const tdWeb = { type: 'text', value: item.web, name: 'web' };
      const tdInfo = {
        type: 'button',
        value: 'Ver',
        route: `${Routes.companyProfile}/${item.id}`,
      };
      return[tdName, tdEmail, tdPhone, tdWeb, tdInfo];
    })
    // sort by column
    .sort((a, b) => {
      let x = null;
      let y = null;
      if (order.column === t('AdminZone.company.table.name')) {
        x = a[0].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[0].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (order.column === t('AdminZone.company.table.email')) {
        x = a[1].value !== null ? a[1].value.toLowerCase() : 'zzz';
        y = b[1].value !== null ? b[1].value.toLowerCase() : 'zzz';
      } else if (order.column === t('AdminZone.company.table.phone')) {
        x = a[2].value !== null ? a[2].value.toLowerCase() : 'zzz';
        y = b[2].value !== null ? b[2].value.toLowerCase() : 'zzz';
      } else if (order.column === t('AdminZone.company.table.web')) {
        x = a[3].value !== null ? a[3].value.toLowerCase() : 'zzz';
        y = b[3].value !== null ? b[3].value.toLowerCase() : 'zzz';
      }
      if (x < y) {
        return order.type === 'asc' ? -1 : 1;
      }
      if (x > y) {
        return order.type === 'asc' ? 1 : -1;
      }
      return 0;
    }) || [];
  }, [companies, order, query]);

  const orderTable = (column) => {
    let orderType = 'asc';
    if (order.column === column && order.type === 'asc') {
      orderType = 'desc';
    }

    setOrder({
      column: column,
      type: orderType,
    });
  };

  const isSelfCompany = useMemo(() => selectedItem.length !== 0 && selectedItem[0].id === user.company_id, [selectedItem, user]);
    
  return (
    <>
      <div className="flex p-1 space-x-5 mt-5 items-center justify-between">
        <div className="flex space-x-1 2xl:space-x-2">
          <h1 className="text-md 2xl:text-xl font-bold text-gray-800">{t('Company.meta')}</h1>
        </div>
        <div className="flex items-center space-x-2 2xl:space-x-4 justify-end w-full">
          <button
            onClick={hanldleNewCompany}
            className="bg-primary flex text-white px-4 py-2 rounded-xl select-none hover:shadow-md active:scale-[0.98] transition-shadow text-sm 2xl:text-md disabled:opacity-0"
            disabled={archived}
          >
            {t('Company.new')}
          </button>
          <input
            type="text"
            placeholder={t('Placeholder.search')}
            value={query}
            className="border-gray-300 text-sm 2xl:text-md rounded-lg focus:border-gray-300 focus:ring-1 focus:ring-gray-300 w-32 2xl:w-56 placeholder:text-gray-300"
            onChange={(e) => handleSearchQuery(e.target.value)}
          />
          {archived && <MdSettingsBackupRestore
            className={classNames(
              selectedItem.length !== 0
                ? 'cursor-pointer text-black hover:text-primary'
                : 'cursor-not-allowed text-gray-200',
                'font-extralight w-6 2xl:w-8 h-6 2xl:h-8',
            )}
            disabled={selectedItem.length === 0}
            onClick={restoreItems}
          />}
          <div className="relative">
            <TrashIcon
              className={classNames(
                selectedItem.length !== 0 && !isSelfCompany
                  ? 'cursor-pointer text-black hover:text-primary'
                  : 'cursor-not-allowed text-gray-200',
                'font-extralight w-6 2xl:w-8 h-6 2xl:h-8',
              )}
              disabled={selectedItem.length === 0 || isSelfCompany}
              onClick={removeItems}
            />{isSelfCompany && <div className='absolute -top-10 left-0 w-full h-full flex items-center justify-center'>
            <span className='bg-black bg-opacity-50 p-1 rounded-md whitespace-nowrap text-white text-xs 2xl:text-sm'>{t('User.cant_delete_self')}</span>
          </div>}
        </div>
        </div>
      </div>
      <div className="sm:px-6 lg:px-0">
        <div className="flow-root">
          <div className="-ml-7">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 2xl:pr-0">
              <div className="relative h-[330px] 2xl:h-[490px]">
                <Table
                  hasSelectable={true}
                  header={headersTable}
                  body={bodyTable}
                  selectedItems={selectedItem}
                  clients={companies}
                  onSelect={setSelectedItem}
                  onOrder={orderTable}
                  isLoading={loadingCompanies}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDelete
        isOpen={modalOpen}
        label='Confirme que desea eliminar la compañía'
        subtitle={!archived ? 'Si elige la opción de Eliminar permanentemente TODOS los datos de la compañía serán eliminados sin opción de recuperación.' : 'Se eliminará permanentemente. Esta acción es irreversible.'}
        action='custom'
        alternativeText={!archived && 'Eliminar permanentemente'}
        onAlternative={(confirmed) => handleConfirmModal(confirmed, true)}
        onConfirm={(confirmed) => handleConfirmModal(confirmed)}
        loading={loadingDelete}
      />
      <ConfirmDelete // Restore, no delete
        isOpen={modalOpenRestore}
        label='Confirme que desea restaurar la compañía'
        action='custom'
        onConfirm={(confirmed) => handleConfirmModal(confirmed, undefined, true)}
        loading={loadingDelete}
      />
    </>
  );
};
