import { useContext } from "react";
import { RecordContext } from "./RecordContext";

// hook to use the context
const useRecordContext = () => {
  const context = useContext(RecordContext);
  if (!context) throw new Error('useRecordContext must be used within a RecordProvider')
  return context;
};

export default useRecordContext;