import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TrashIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcumbs, Tabs } from '../../components/library';
import { Spin, Spinner, Table, Title } from '../../components/common';
import { classNames, dataURLToFile, generateImageUrl, generateUpdateLandingData } from '../../config/utils';
import useAuthContext from '../../hooks/useAuthContext';
import { useToast2 } from '../../hooks/useToast2';
import {
  deleteUserCompany,
  getUserOrCompanyById,
  getUsersCompanies,
  postAddCompany,
  postUpdateCompany,
  postUpdateLandingDefault,
  restoreUserCompany,
} from '../../services/admin/adminService';
import { NewCompanySchema } from '../../config/formsValidators';
import { EditLanding } from '../../components/video';
import Routes, { HOME } from '../../routes/app/paths';
import {
  changeLandingConfig,
  changeTemplate,
  selectEditLanding,
} from '../../features/videos/videosSlice';
import { Avatar } from '../../components/common/Avatar';
import VideoProvider from '../../components/video/context/VideoContext';
import ConfirmDelete from './elements/ConfirmDelete';
import { MdSettingsBackupRestore } from 'react-icons/md';

const bodyEmpty = [
  [
    { value: '', type: 'text' },
    { value: '', type: 'text' },
    { value: '', type: 'text' },
    { value: '', type: 'text' },
    { value: '', type: 'text' },
    { value: '', type: 'button' },
  ],
];

export const CompanyProfile = () => {
  // states
  const [users, setUsers] = useState([]);
  const [toggleTab, setToggleTab] = useState(false);
  const [archivedUserTab, setArchivedUserTab] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);
  const [order, setOrder] = useState({ column: 'name', type: 'desc' });
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenRestore, setModalOpenRestore] = useState(false);

  //hooks
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { id } = useParams();
  const { showToast } = useToast2();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const config = useSelector(selectEditLanding);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      avatar: null,
      name: '',
      email: '',
      phone: '',
      web: '',
    },
    resolver: yupResolver(NewCompanySchema),
  });

  //const
  const headersTable = [
    { title: t('AdminZone.user.table.name'), dropdown: true, maxWidth: 150 },
    { title: t('AdminZone.user.table.last_name'), dropdown: true },
    { title: t('AdminZone.user.table.email'), dropdown: true },
    { title: t('AdminZone.user.table.role'), dropdown: true },
    { title: t('AdminZone.user.table.last_send'), dropdown: true },
    { title: 'Info.', dropdown: false },
  ];

  //Effects
  useEffect(() => {
    if (location.pathname === Routes.newCompany) {
      reset();
      setSelectedImage(null);
      dispatch(changeTemplate(''));
      dispatch(changeLandingConfig({}));
    }
    return () => {
      reset();
      setSelectedImage(null);
      dispatch(changeTemplate(''));
      dispatch(changeLandingConfig({}));
    }
  }, [location]);

  //Querys
  const {
    data: dataCompany,
    isLoading: loadingCompany,
    error: errorCompany,
  } = useQuery(
    ['COMPANY', id],
    () => id ? getUserOrCompanyById({ token: user.access_token, search: 'company', id }) : null,
    {
      onError: (err) => {
        console.log(err);
        navigate(-1);        
      },
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (!dataCompany) return;
    setValue('id', dataCompany?.data.id);
    if (dataCompany?.data.avatar) setValue('avatar', generateImageUrl(dataCompany.data.avatar));
    setValue('name', dataCompany?.data.name);
    setValue('email', dataCompany?.data.email);
    setValue('phone', dataCompany?.data.phone);
    setValue('web', dataCompany?.data.web);
    setValue('landing_default', dataCompany?.data.landing_default);
    dispatch(changeTemplate(Number(dataCompany?.data.landing_default.template)));
    dispatch(changeLandingConfig(dataCompany?.data.landing_default.config));
  }, [dataCompany?.data?.id, id]);

  const {
    data: dataUsers,
    isLoading: loadingUsersCompanies,
    error: errorUsersCompanies,
  } = useQuery(
    ['USERS_COMPANIES', id],
    () => id ? getUsersCompanies({ token: user.access_token, companyId: id }) : null,
    {
      onSuccess: (resp) => {
        setUsers(resp.data.data);
      },
      onError: (err) => {
        console.log(err);
        navigate(-1)
      },
      enabled: !!id,
    },
  );

  const {
    mutate: mutateCreateCompany,
    isLoading: loadingCreate,
    error: errorCreate,
  } = useMutation((opt) => postAddCompany(opt), {
    onSuccess: async (res) => {
      await queryClient.invalidateQueries(['COMPANIES']);
      showToast({ show: true, type: 'success', message: t('Message.company.success.add') });
      res?.data?.data?.id && navigate(`${Routes.companyProfile}/${res.data.data.id}`);
    },
    onError: (err) => {
      queryClient.invalidateQueries(['COMPANIES']);
      console.log(err);
      showToast({ show: true, type: 'error', message: t('Message.company.error.add') });
    },
  });

  const {
    mutate: mutateUpdateCompany,
    isLoading: loadingUpdate,
    error: errorUpdate,
  } = useMutation((opt) => postUpdateCompany(opt), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['COMPANIES']);
      showToast({ show: true, type: 'success', message: t('Message.company.success.update') });
    },
    onError: (err) => {
      queryClient.invalidateQueries(['COMPANIES']);
      console.log(err);
      showToast({ show: true, type: 'error', message: t('Message.company.error.update') });
    },
  });

  const {
    mutate: mutateUpdateLandingDefault,
    isLoading: loadingUpdateLanding,
    error: errorUpdateLanding,
  } = useMutation((opt) => postUpdateLandingDefault(opt), {
    onSuccess: async () => {
      // await queryClient.invalidateQueries('COMPANY');
      showToast({ show: true, type: 'success', message: t('Message.company.success.update') });
    },
    onError: (err) => {
      // queryClient.invalidateQueries('COMPANY');
      console.log(err);
      showToast({ show: true, type: 'error', message: t('Message.company.error.update') });
    },
  });

  const {
    mutate: mutateDeleteUsersCompany,
    isLoading: loadingDelete,
    errorDelete,
  } = useMutation((opt) => {
    if (opt.restore) return restoreUserCompany(opt);
    return deleteUserCompany(opt)
  }, {
    onSuccess: async (_, opt) => {
      await queryClient.invalidateQueries(['USERS_COMPANIES', id]);
      setSelectedItem([]);
      setQuery(query)
      showToast({ show: true, type: 'success', message: opt.restore
      ? t('Message.user.success.restore') 
      : t('Message.user.success.delete') });
      setModalOpen(false);
      setModalOpenRestore(false)
    },
    onError: (err, opt) => {
      queryClient.invalidateQueries(['USERS_COMPANIES', id]);
      console.log(err);
      setQuery(query)
      showToast({ show: true, type: 'error', message: opt.restore
        ? t('Message.user.error.restore')
        : t('Message.user.error.delete') });
      setModalOpen(false);
      setModalOpenRestore(false)
    },
  });

  //handlers
  const handleSelect = (item) => {
    if (item.title === t('Company.tabs.landing')) {
      return setToggleTab(true);
    }
    setToggleTab(false);
  };
  
  const handleSelectArchived = (item) => {
    if (item.title === t('AdminZone.user.meta')) {
      return setArchivedUserTab(false);
    }
    setArchivedUserTab(true);
  };

  const filteredList = useMemo(() => 
    query !== '' &&
      (users.filter(
        (c) =>
          c.name?.toLowerCase().includes(query.toLowerCase()) ||
          c.last_name?.toLowerCase().includes(query.toLowerCase()) ||
          c.email?.toLowerCase().includes(query.toLowerCase()) ||
          c.role?.toLowerCase().includes(query.toLowerCase()),
        // c.last_send?.toLowerCase().includes(query.toLowerCase())
      ))
    || users
  , [query, users]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setValue('avatar', file);
      setSelectedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const hanldleNewUser = () => {
    navigate(`${Routes.newUser}/${watch().name}`);
  };

  const removeItems = (confirmed) => {
    confirmed 
      ? mutateDeleteUsersCompany({ token: user.access_token, user_ids: [selectedItem[0].id] })
      : setModalOpen(false);
  };
  const restoreItems = (confirmed) => {
    confirmed
      ? mutateDeleteUsersCompany({ token: user.access_token, user_ids: selectedItem.map((item) => item.id), restore: true })
      : setModalOpenRestore(false);
  };

  const handleUpdateLanding = () => {
    const data = generateUpdateLandingData(config);
    function verifyImg(binary) {
      return binary?.split('/')[0] === 'data:image'
    }
    const firstLogo = config.config.images.firstLogo
    const secondLogo = config.config.images.secondLogo
    const mainImage = config.config.images.mainImage;

    const finalData = { 
      ...data,
      first_logo: verifyImg(firstLogo) ? dataURLToFile(firstLogo, 'first-logo') : undefined,
      second_logo: verifyImg(secondLogo) ? dataURLToFile(secondLogo, 'second-logo') : undefined,
      main_image: verifyImg(mainImage) ? dataURLToFile(mainImage, 'main-image') : undefined,
    }
    mutateUpdateLandingDefault({ token: user.access_token, companyId: id, data: finalData });
  };

  const handleBreadcumbClick = (pathId) => {
    pathId === 0 && navigate(Routes.companies);
  };

  const onSubmit = ({ avatar, name, email, phone, web }) => {
    id
      ? mutateUpdateCompany({
          token: user.access_token,
          companyId: id,
          avatar,
          name,
          email,
          phone,
          web,
        })
      : mutateCreateCompany({ token: user.access_token, avatar, name, email, phone, web });
  };

  //functions
  //Creando el cuerpo de la tabla
  const bodyTable = useMemo(() => {
    return filteredList
    ?.filter((item) => archivedUserTab ? item.deleted_at : !item.deleted_at)
    ?.map((item) => {
      const tdName = { type: 'text', value: item.name, name: 'name', id: item.id };
      const tdLastName = { type: 'text', value: item.last_name, name: 'last_name' };
      const tdEmail = { type: 'text', value: item.email, name: 'email' };
      const tdRole = { type: 'text', value: item.role, name: 'role' };
      const tdLastSend = { type: 'text', value: item.lastVideoDate?.split('T')[0], name: 'last_send' };
      const tdInfo = {
        type: 'button',
        value: 'Ver',
        route: user?.role === 'Manager' 
          ? `${Routes.userProfile}/${user?.company.name}/${item.id}` 
          : `${Routes.userProfile}/${item.id}`,
      };
      return [tdName, tdLastName, tdEmail, tdRole, tdLastSend, tdInfo];
    })
    ?.sort((a, b) => {
      let x = null;
      let y = null;
      if (order.column === t('AdminZone.user.table.name')) {
        x = a[0].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[0].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (order.column === t('AdminZone.user.table.last_name')) {
        x = a[1].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[1].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (order.column === t('AdminZone.user.table.email')) {
        x = a[2].value !== null ? a[1].value.toLowerCase() : 'zzz';
        y = b[2].value !== null ? b[1].value.toLowerCase() : 'zzz';
      } else if (order.column === t('AdminZone.user.table.role')) {
        x = a[3].value !== null ? a[2].value.toLowerCase() : 'zzz';
        y = b[3].value !== null ? b[2].value.toLowerCase() : 'zzz';
      } else if (order.column === t('AdminZone.user.table.last_send')) {
        x = a[4].value === null || a[4].value === undefined ? 'zzz' : a[3].value.toLowerCase();
        y = b[4].value === null || b[4].value === undefined ? 'zzz' : b[3].value.toLowerCase();
      }
      if (x < y) {
        return order.type === 'asc' ? -1 : 1;
      }
      if (x > y) {
        return order.type === 'asc' ? 1 : -1;
      }
      return 0;
    })
    || []
  }, [filteredList, order, archivedUserTab]);

  const orderTable = (column) => {
    let orderType = 'asc';
    if (order.column === column && order.type === 'asc') {
      orderType = 'desc';
    }

    setOrder({
      column: column,
      type: orderType,
    });
  };

  // effects
  useEffect(() => {
    if (user?.role !== 'Admin' && user?.role !== 'Manager') {
      navigate(HOME);
      showToast({
        show: true,
        type: 'error', 
        message: t('AdminZone.error')
      });
    }
  }, [user, navigate]);

  const isSelfUserSelected = useMemo(() => selectedItem.some((item) => item.id === user?.id), [selectedItem, user]);
  
  return (
    <>
      {user?.role === 'Admin' && <Breadcumbs
        category={'admin'}
        path={[
          { id: 0, name: t('AdminZone.title') },
          watch().name
            ? { id: 1, name: watch().name, companyId: watch().id }
            : { id: 1, name: t('Company.new'), companyId: 0 },
        ]}
        onClick={handleBreadcumbClick}
      />}
      <div className="mt-[15px]">
        <Tabs
          tabs={[
            { id: 1, title: t('Company.tabs.profile'), selected: !toggleTab },
            { id: 2, title: t('Company.tabs.landing'), selected: toggleTab, disabled: !id},
          ]}
          onSelect={handleSelect}
        />
      </div>
      {!toggleTab ? (
        <>
          <div className="grid grid-cols-3 gap-2 my-10">
            <div className="p-4 col-span-1 flex justify-center items-center">
              <Avatar
                selectedImage={selectedImage}
                avatar={watch()?.avatar}
                onUploadImage={handleImageUpload}
              />
            </div>
            <div className="p-4 col-span-2">
              <Title title={t('Company.form_title')} clases="mb-5 text-xl font-semibold" />
              <form className="grid grid-cols-2 gap-6 my-2">
                <div>
                  <input
                    {...register('name')}
                    className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    type="text"
                    placeholder={t('Placeholder.name')}
                  />
                  {errors.name && (
                    <p className="text-red-500" role="alert">
                      {t(errors.name.message)}
                    </p>
                  )}
                </div>
                <div>
                  <input
                    {...register('email')}
                    className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    type="text"
                    placeholder={t('Placeholder.email')}
                  />
                  {errors.email && (
                    <p className="text-red-500" role="alert">
                      {t(errors.email.message)}
                    </p>
                  )}
                </div>
                <div>
                  <input
                    {...register('phone')}
                    className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    type="text"
                    placeholder={t('Placeholder.phone')}
                  />
                  {errors.phone && (
                    <p className="text-red-500" role="alert">
                      {t(errors.phone.message)}
                    </p>
                  )}
                </div>
                <div>
                  <input
                    {...register('web')}
                    className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    type="text"
                    placeholder={t('Placeholder.web')}
                  />
                  {errors.web && (
                    <p className="text-red-500" role="alert">
                      {t(errors.web.message)}
                    </p>
                  )}
                </div>
              </form>
              <button
                disabled={loadingCreate || loadingUpdate}
                onClick={handleSubmit(onSubmit)}
                className="p-2 mt-6 bg-primary rounded-lg text-white flex gap-3 disabled:bg-gray-400"
              >
                {t('Company.save')} {(loadingCreate || loadingUpdate) && <Spin />}
              </button>
            </div>
          </div>

          {/* table */}
          <div className="flex p-1 space-x-5 mt-5 items-center justify-between">
            <div className="flex space-x-1 2xl:space-x-2">
              <Tabs
                tabs={[
                  { id: 1, title: t('AdminZone.user.meta'), selected: !archivedUserTab },
                  { id: 2, title: t('Library.tabs.archived'), selected: archivedUserTab },
                ]}
                onSelect={handleSelectArchived}
              />
            </div>
            <div className="flex items-center space-x-2 2xl:space-x-4 justify-end w-full">
              {!archivedUserTab && <button
                disabled={!id}
                onClick={hanldleNewUser}
                className="bg-primary flex text-white px-4 py-2 rounded-xl select-none hover:shadow-md active:scale-[0.98] transition-shadow text-sm 2xl:text-md disabled:bg-gray-400"
              >
                {t('AdminZone.user.new')}
              </button>}
              <input
                disabled={!id}
                type="text"
                placeholder={t('Placeholder.search')}
                value={query}
                className="border-gray-300 text-sm 2xl:text-md rounded-lg focus:border-gray-300 focus:ring-1 focus:ring-gray-300 w-32 2xl:w-56 placeholder:text-gray-300"
                onChange={(e) => setQuery(e.target.value)}
              />
              {!archivedUserTab 
                ? <div className='relative'>
                    <TrashIcon
                    className={classNames(
                      selectedItem.length !== 0 && !isSelfUserSelected
                        ? 'cursor-pointer text-black hover:text-primary'
                        : 'cursor-not-allowed text-gray-200',
                      'font-extralight w-6 2xl:w-8 h-6 2xl:h-8',
                        )}
                        onClick={() => !isSelfUserSelected && setModalOpen(true)}
                      />{isSelfUserSelected && <div className='absolute -top-10 left-0 w-full h-full flex items-center justify-center'>
                      <span className='bg-black bg-opacity-50 p-1 rounded-md whitespace-nowrap text-white text-xs 2xl:text-sm'>{t('User.cant_delete_self')}</span>
                    </div>}
                  </div>
                : <MdSettingsBackupRestore
                className={classNames(
                  selectedItem.length !== 0
                    ? 'cursor-pointer text-black hover:text-primary'
                    : 'cursor-not-allowed text-gray-200',
                    'font-extralight w-6 2xl:w-8 h-6 2xl:h-8',
                )}
                disabled={selectedItem.length === 0}
                onClick={() => setModalOpenRestore(true)}
              />
              }
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="flow-root">
              <div className="-ml-7">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 2xl:pr-0">
                  <div className="relative">
                    {id && users.length > 0 && (
                      <Table
                        hasCheckable={true}
                        header={headersTable}
                        body={bodyTable}
                        selectedItems={selectedItem}
                        clients={users.filter((item) => archivedUserTab ? item.deleted_at : !item.deleted_at)}
                        onSelect={setSelectedItem}
                        onOrder={orderTable}
                      />
                    )}
                    {users.length === 0 && (<span className="text-gray-500 text-sm">No hay datos que mostrar</span>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <VideoProvider>{!loadingCompany && <EditLanding scope="admin" />}</VideoProvider>
      )}
      <div className="flex">
        <button
          type="button"
          className="flex items-center justify-center h-10 2xl:h-12 px-5 my-3 rounded-xl border-2 border-gray-400 text-gray-400 font-semibold hover:shadow-lg active:scale-[0.98]"
          onClick={() => {
            toggleTab ? setToggleTab(false) : navigate(-1);
          }}
        >
          {t('Company.back')}
        </button>
        {toggleTab && (
          <button
            type="button"
            className="flex items-center gap-2 justify-center h-10 2xl:h-12 px-5 mt-3 ms-2 rounded-xl bg-primary text-white font-semibold hover:shadow-lg active:scale-[0.98] disabled:bg-gray-400"
            onClick={handleUpdateLanding}
            disabled={loadingUpdateLanding}
          >
            {loadingUpdateLanding && <Spin />}
            {t('Company.save')}
          </button>
        )}
      </div>
      <ConfirmDelete
        isOpen={modalOpen}
        label={'el usuario'}
        onConfirm={removeItems}
        loading={loadingDelete}
      />
      <ConfirmDelete
        isOpen={modalOpenRestore}
        label='Confirme que desea restaurar el usuario'
        action='custom'
        onConfirm={(confirmed) => restoreItems(confirmed, true)}
        loading={loadingDelete}
      />

      {/* Loader */}
      <Spinner open={loadingCompany || loadingUsersCompanies} title={t('Library.loading')} />
    </>
  );
};
