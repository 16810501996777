import { classNames } from "../../../../config/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ItemBody from "./ItemBody";
import { BsDownload } from "react-icons/bs";

const ListContacts = ({
  contacts = [],
  onSelected,
  onDownloadLinks,
  finishedRendering = false,
}) => {
  const [selected, setSelected] = useState(0);

  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col shadow-xl rounded-xl">
      <div className="flex flex-col space-y-5">
        <table className="border-separate border-spacing-x-[1px] max-w-full">
          {/* Header */}
          <thead className="">
            <tr>
              <th scope="col" className="">
                <div
                  className={classNames('flex items-center p-3 px-4 rounded-l-lg bg-gray-subtitle')}
                >
                  <p className="text-gray-300 whitespace-nowrap hover:text-primary-lighter px-2 font-semibold">
                    {t('Video.contact')}
                  </p>
                </div>
              </th>
              <th scope="col" className="">
                <div
                  className={classNames('flex items-center p-3 px-4 rounded-r-lg bg-gray-subtitle')}
                >
                  <p className="text-gray-300 whitespace-nowrap hover:text-primary-lighter px-2 font-semibold">
                    {t('Video.email')}
                  </p>
                </div>
              </th>
            </tr>
          </thead>

          {/* Body */}
          <tbody className="divide-y divide-gray-200 bg-white overflow-y-hidden">
            {contacts.map((row, index) => (
              <tr
                key={`tr-${index}`}
                className={classNames(
                  selected === index && 'bg-primary text-white',
                  'relative px-6 sm:w-12 sm:px-rounded-tl-xl rounded-bl-xl',
                  index === contacts.length - 1 && 'border-b-2 border-gray-300',
                )}
              >
                <ItemBody
                  item={row.name}
                  key={`td${row.name}${index}`}
                  selected={selected === index}
                  className="max-w-[110px] 2xl:max-w-[120px]"
                  onClick={() => {
                    setSelected(index);
                    onSelected(index);
                  }}
                />
                <ItemBody
                  item={row.email}
                  key={`td${row.name}${index - 1}`}
                  selected={selected === index}
                  className="max-w-[200px] 2xl:max-w-[223px]"
                  onClick={() => {
                    setSelected(index);
                    onSelected(index);
                  }}
                />
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className="flex text-primary active:text-red-400 pb-6 px-6 space-x-2 cursor-pointer"
          onClick={onDownloadLinks}
          title={
            finishedRendering
              ? t('Message.video.info.download_links')
              : t('Message.video.info.wait_rendering')
          }
          disabled={!finishedRendering}
        >
          <span
            className="text-xs 2xl:text-sm font-semibold select-none underline"
            style={{ color: finishedRendering ? '' : '#9ca3af' }}
          >
            {t('Video.dowload_links')}
          </span>
          <BsDownload size={20} color={finishedRendering ? '' : '#9ca3af'} />
        </button>
      </div>
    </div>
  );
};

export default ListContacts