/**
 * La función `getPathById` toma un `id` y una `estructura` como entrada y devuelve la ruta al elemento
 * con el `id` dado en la estructura.
 * @param id - El parámetro `id` es el identificador único del elemento para el que desea encontrar la
 * ruta.
 * @param structure - El parámetro `estructura` es una matriz que representa una estructura jerárquica.
 * Cada elemento de la matriz representa un nodo en la estructura y tiene las siguientes propiedades:
 * @returns La función `getPathById` devuelve una matriz de objetos que representan la ruta al elemento
 * con el `id` especificado en la `estructura` dada. Cada objeto en la matriz tiene propiedades `id` y
 * `name`, que representan la identificación y el nombre del elemento en la ruta.
 */
export const getPathById = (id, structure) => {
  let path = [];
  if (id === undefined || !Object.keys(structure).length) return path;
  const findPath = (childrens, arrParent) => {
    for (let i = 0; i < childrens.length; i++) {
      const item = childrens[i];
      if (item.id === id) {
        path = [...arrParent];
        path.push({
          id: item.id,
          name: item.name,
        });
        break;
      }
      if (item.children) {
        const parent = arrParent.concat([
          {
            id: item.id,
            name: item.name,
          },
        ]);
        findPath(item.children, parent);
      }
    }
  };
  findPath(structure?.children, []);
  return path;
};

/**
 * La función `getElementById` busca un elemento con una identificación específica dentro de una
 * estructura dada y devuelve el elemento si lo encuentra.
 * @param id - El parámetro id es el id del elemento que desea encontrar en la estructura.
 * @param structure - El parámetro `estructura` es una matriz que representa la estructura jerárquica
 * de los elementos. Cada elemento de la matriz es un objeto con propiedades como `id` y `childrens`. La
 * propiedad `id` representa el identificador único del elemento, y la propiedad `childrens` es una matriz
 * de elementos secundarios
 * @returns el elemento con el id especificado de la estructura dada.
 */
export const getElementById = (id, structure) => {
  if (id === undefined || !Object.keys(structure).length) return {};
  if (id === 0) return structure;
  let element = null;

  const findElement = (childrens) => {
    for (let i = 0; i < childrens.length; i++) {
      if (childrens[i].id === id) {
        element = childrens[i];
        break;
      }
      if (childrens[i].children) {
        findElement(childrens[i].children);
      }
    }
  };
  findElement(structure?.children || []);
  return element;
};

// actualzar la estructura, agregando un nuevo children segun el id de su padre
export const updateStructureByParentId = (
  structure,
  { parentId, id = 'temp', name = 'Nueva carpeta', status = 'new' },
) => {
  if (id === undefined || !Object.keys(structure).length) return {};
  if (id === 0) return structure;
  const element = {
    id,
    parent_id: parentId,
    archived: false,
    files: [],
    children: [],
    name,
    status,
  };
  if (parentId === 0) {
    if (structure?.children) {
      structure.children.push(element);
      return structure;
    }
    structure.push(element);
    return structure;
  }
  const findElement = (childrens) => {
    for (let i = 0; i < childrens.length; i++) {
      if (childrens[i].id === parentId) {
        // update when recibe a null | 0 parentId, that mean is a root | first level folder
        childrens[i].children.push(element);
        break;
      }
      if (childrens[i].children) {
        findElement(childrens[i].children);
      }
    }
  };
  findElement(structure?.children || structure || []);
  return structure;
};

export const addFileInStructureByParentId = (
  structure,
  parentId,
  filesToAdd, // accept an array of files or an obj ({ id = 'temp', title = 'Nuevo archivo', status = 'new' })
  additions = 1,
) => {
  if (parentId === undefined || !Object.keys(structure).length) return {};
  // if (parentId === 0) return structure;

  if (!Array.isArray(filesToAdd)) {
    const element = {
      id: filesToAdd.id || 'temp',
      title: filesToAdd.title || 'Nuevo archivo',
      status: filesToAdd.status || 'new',
    };
    filesToAdd = Array.from({ length: additions }, () => element);
  };

  if (parentId === 0) {
    if (Array.isArray(structure)){
      structure[0].files = structure[0].files.concat(filesToAdd);
      return structure;
    } else {
      if (structure?.files) {
        structure.files = structure.files.concat(filesToAdd);
        return structure;
      }
    }
  }

  const findElement = (currentFolder) => {
    if (currentFolder.id === parentId) {
      currentFolder.files = currentFolder.files.concat(filesToAdd);
      return;
    }
    if (currentFolder.children) {
      currentFolder.children.forEach(child => findElement(child));
    }
  };
  findElement(Array.isArray(structure) ? structure[0] : structure);
  return structure;
};

export const deleteFoldersByIds = (structure, ids) => {
  ids = Array.isArray(ids) ? ids : [ids];
  if (!ids?.length || !Object.keys(structure).length) return {};
  if (ids.includes(0)) return structure;

  let found = false;

  const findElement = (childrens) => {
    if (found) return; // Si se ha encontrado, se detiene la recursión
    const filteredChildren = [];
    for (let i = 0; i < childrens.length; i++) {
      if (ids.includes(childrens[i].id)) {
        found = true; // Se ha encontrado, se marca la bandera como verdadera
      } else {
        filteredChildren.push(childrens[i]);
      }
    }
    for (const child of filteredChildren) {
      if (child.children) {
        child.children = findElement(child.children);
      }
    }
    return filteredChildren;
  };

  structure.children = findElement(structure?.children || []);
  return structure;
};

export const deleteFilesByIds = (structure, ids) => {
  ids = Array.isArray(ids) ? ids : [ids];
  if (!ids?.length || !Object.keys(structure).length) return structure;

  let found = false;

  const findElement = (currentFolder) => {
    if (found) return;
    const filteredFolder = { ...currentFolder };
    if (currentFolder.files) {
      filteredFolder.files = currentFolder.files.filter((file) => {
        if (ids.includes(file.id)) found = true;
        return !ids.includes(file.id);
      });
    }
    if (currentFolder.children && !found) {
      filteredFolder.children = currentFolder.children.map((child) => {
        const foundChild = findElement(child);
        if (foundChild) return foundChild;
        return child;
      });
    }
    return filteredFolder;
  };

  structure = findElement(structure);
  return structure;
};

export const getFilesByIds = (structure, ids) => {
  ids = Array.isArray(ids) ? ids : [ids];
  if (!ids?.length || !Object.keys(structure).length) return [];

  let files = [];
  
  const findElement = (currentFolder) => {
    if (currentFolder.files) {
      files = files.concat(currentFolder.files.filter((file) => ids.includes(file.id)));
    }
    if (currentFolder.children) {
      currentFolder.children.map((child) => findElement(child));
    }
    return currentFolder;
  };

  findElement(structure);
  return files;
}