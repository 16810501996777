import { BsCheckLg, BsDash } from 'react-icons/bs';
import { classNames } from '../../config/utils';
import { useVideo } from './context/VideoContext';

export function Step({ step, index }) {
  const { videoStep, stepDisabled } = useVideo();

  const isDisabled = stepDisabled.includes(index);

  return (
    <li key={step.name} className={classNames("relative md:flex md:flex-1", isDisabled && 'opacity-40')}>
      <span
        // href={step.href}
        className="flex items-center px-3 py-3 text-xs 2xl:text-sm font-medium"
        aria-current="step"
      >
        {videoStep > index ? (
          <div className={classNames("flex h-7 w-7 2xl:h-8 2xl:w-8 flex-shrink-0 items-center justify-center rounded-full", isDisabled ? 'bg-gray-500' : 'bg-primary')}>
            { isDisabled 
              ? <BsDash size={20} className="flex-shrink-0 text-white" aria-hidden="true" />
              : <BsCheckLg size={20} className="flex-shrink-0 mr-0.5 text-white" aria-hidden="true" />
            }
          </div>
        ) : (
          <span
            className={`flex h-7 w-7 2xl:h-8 2xl:w-8 flex-shrink-0 items-center justify-center rounded-full border-2 ${
              videoStep === index ? 'border-primary' : 'border-gray-400'
            } font-bold`}
          >
            <span className={videoStep === index ? 'text-primary' : 'text-gray-500'}>
              {step.id}
            </span>
          </span>
        )}
        <span
          className={`ml-2 text-md 2xl:text-lg font-semibold ${
            videoStep === index ? 'text-primary' : 'text-gray-500'
          }`}
        >
          {step.name}
        </span>
      </span>

      {index !== 4 ? (
        <>
          {/* Arrow separator for lg screens and up */}
          <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </>
      ) : (
        <></>
      )}
    </li>
  );
}
