import { useTranslation } from 'react-i18next';
import { useVideo } from './context/VideoContext';
import { Progress } from '@chakra-ui/react';

export const ProgressModal = () => {
	const { isRendering, isUploading } = useVideo();
  const { t } = useTranslation();

	if (!isRendering && !isUploading) return null;
	
  return (
    <div className="w-[350px] 2xl:w-[422px] fixed top-10 right-24 2xl:right-32 flex flex-col rounded-xl border-[1px] border-gray-200 z-30 bg-white overflow-hidden">
      <h3 className="mt-5 mb-3 mx-6 text-lg font-bold">
        {t('Message.video.progress_modal.message1')}
      </h3>
			<span className="text-xs mx-6 mb-4">{
				isUploading && t('Message.video.progress_modal.message3')
			}</span>
      <span className="text-xs mx-6 mb-4">{
				isRendering && t('Message.video.progress_modal.message2')
			}</span>

			<Progress size='sm' isIndeterminate colorScheme='green' />
    </div>
  );
};
