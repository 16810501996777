import { classNames } from "../../../../config/utils";

const ItemBody = ({ item, selected, onClick, className = '' }) => {
  return (
    <td
      className={classNames(
        className,
        'border-r border-gray-border first:rounded-tl-xl first:rounded-bl-xl',
        'last:rounded-r-xl last:border-r-0 whitespace-nowrap overflow-x-hidden text-ellipsis',
        'py-3 px-6 text-xs 2xl:text-sm text-left font-medium border-r border-gray-border select-none cursor-pointer',
        selected ? 'text-gray-100 bg-primary font-semibold' : 'text-gray-400',
      )}
      onClick={onClick}
    >
      {item}
      {/* <div className="absolute inset-y-0 right-0 w-6 h-full bg-gradient-to-r from-transparent to-white"></div> */}
    </td>
  );
};

export default ItemBody;