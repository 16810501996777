import { useMutation, useQueryClient } from "react-query";
import { deleteFoldersByIds, deleteFilesByIds, updateStructureByParentId, addFileInStructureByParentId } from "./pathUtils";
import { useToast2 } from "../../../hooks/useToast2";
import { deleteClip, deleteFolder, postAddFolder, putStatusFolder, putMoveFolder, postStatusClip, putStatusPortrait, deletePortrait, postStatusRecording, deleteRecording, deleteFiles, moveFiles, postAddMultiplePortraits, postAddClip, postAddMultipleClips } from "../../../services/library/LibraryService";
import { VideoChangeStatus, VideoDelete } from "../../../services/video/VideoService";
import React, { useMemo } from "react";

/**
 * Utiliza `useMutation` para enviar
 * una solicitud para agregar una carpeta, y devuelve la función `mutate`, el booleano `isLoading` y el
 * objeto `error`. Actualiza la caché de la biblioteca cuando la solicitud se completa con éxito.
 * @param library - El parámetro `library` es el nombre o identificador de la biblioteca donde se
 * creará la carpeta.
 * @returns La función `createFolder` devuelve un objeto con tres propiedades: `mutate`, `isLoading` y
 * `error`.
 */
export const querys = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast2();

  /*QUERYS TO FOLDERS*/
  const createFolder = (library) => {
    const { mutate, isLoading, error, ...rest } = useMutation((opt) => postAddFolder(opt), {
      onSuccess: (res) => {
        //update data in cache
        const prevData = queryClient.getQueryData(library)
        if (!prevData) return queryClient.invalidateQueries(library); // if no data in cache, update from server
        const newFolder = {
          id: res.data.id,
          parent_id: res.data.parent_id,
          name: res.data.name,
        }
        const newDataClient = updateStructureByParentId(prevData.data, newFolder)
        queryClient.setQueryData(library, { data: newDataClient })
        queryClient.invalidateQueries(library); //update from server after update cache, for better UX
      },
      onError: (err) => {
        queryClient.invalidateQueries(library); //para refrescar igualmente la cache, y por ende todo los directorios
        console.log(err);
      }
    });

    return { mutate, isLoading, error, ...rest }
    /*mutate({ token, name, type, parentId }); */
  };


  const deleteFolderQuery = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => deleteFolder(opt), {
      onMutate: ({ids}) => {
        queryClient.setQueryData(library, (oldData) => {
          return {
            data: [deleteFoldersByIds(oldData.data[0], ids)]
          }
        });
        queryClient.setQueryData(`${library}_arch`, (oldData) => {
          return {
            data: [deleteFoldersByIds(oldData.data[0], ids)]
          }
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        showToast({ show: true, type: 'success', message: 'Carpeta eliminada satisfactoriamente.' })
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        showToast({ show: true, type: 'error', message: 'No se pudo eliminar. Por favor, inténtelo más tarde' })
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };
  
  const deleteFilesQuery = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => deleteFiles(opt), {
      onMutate: ({ids}) => {
        queryClient.setQueryData(library, (oldData) => {
          return {
            data: [deleteFilesByIds(oldData.data[0], ids)]
          }
        });
        queryClient.setQueryData(`${library}_arch`, (oldData) => {
          return {
            data: [deleteFilesByIds(oldData.data[0], ids)]
          }
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        showToast({ show: true, type: 'success', message: 'Archivo(s) eliminado satisfactoriamente.' })
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        showToast({ show: true, type: 'error', message: 'No se pudo eliminar. Por favor, inténtelo más tarde' })
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };
  
  const moveFilesQuery = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => moveFiles(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        showToast({ show: true, type: 'success', message: 'Archivo(s) movido satisfactoriamente.' })
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };


  const changeStatusFolder = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => putStatusFolder(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  const moveFolderQuery = (library) => {
    const queryClient = useQueryClient();

    const { mutate, isLoading, error } = useMutation((opt) => putMoveFolder(opt), {
      onSuccess: (res, opt) => {
        //update data in cache const { token, folderId, newParentId, type } = params;
        const prevData = queryClient.getQueryData(library)
        if (!prevData) return queryClient.invalidateQueries(library); // if no data in cache, update from server
        const newFolder = {
          id: opt.folderId,
          parent_id: opt.newParentId,
          name: opt.name
        }
        const newDataClient = updateStructureByParentId(prevData.data, newFolder)
        queryClient.setQueryData(library, { data: newDataClient })
        queryClient.invalidateQueries(library); //update from server after update cache, for better UX
      },
      onError: (err) => {
        queryClient.invalidateQueries(library); //para refrescar igualmente la cache, y por ende todo los directorios
        console.log(err);
      }
    });

    return { mutate, isLoading, error }
  };

  /*QUERYS TO VIDEOS*/
  const changeStatusVideo = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => VideoChangeStatus(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  const deleteVideoQuery = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => VideoDelete(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        showToast({ show: true, type: 'success', message: 'Video eliminado satisfactoriamente.' })
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  /*QUERYS TO RECORDINGS*/
  const changeStatusRecording = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => postStatusRecording(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  const deleteRecordingQuery = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => deleteRecording(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  /*QUERYS TO CLIPS*/
  const addClip = (library) => {
    const [progress, setProgress] = React.useState([]);
    const [data, setData] = React.useState([]);
    const dataProgress = useMemo(() => data, [data])
    
    const { mutate, isLoading, error } = useMutation(
      (opt) => postAddMultipleClips(opt, setProgress),
      {
        onMutate: (param) => {
          setProgress(
            param.titles.map((title, index) => ({
              progress: 0,
              total: param.clips[index].size,
            }))
          );
          setData(
            param.titles.map((title, index) => ({
              title,
              preview: URL.createObjectURL(param.clips[index]),
              type: 'video'
            }))
          );
        },
        onSuccess: (_, opt) => {
          const prevData = queryClient.getQueryData(library);
          if (!prevData) return queryClient.invalidateQueries(library);
          const newFiles = opt.titles.map((title, index) => ({
            id: 'temp',
            title,
            status: 'temp',
            preview: URL.createObjectURL(opt.clips[index]),
          }));
          const newDataClient = addFileInStructureByParentId(prevData.data, opt.folderId, newFiles);
          queryClient.setQueryData(library, {data: newDataClient});
          queryClient.invalidateQueries(library)
          const timer = setInterval(() => {
            queryClient.invalidateQueries(library);
            setTimeout(() => {
              clearInterval(timer);
            }, 10000);
          }, 2000);
        },
        onError: (err) => {
          queryClient.invalidateQueries(library);
          showToast({
            show: true,
            type: 'error',
            message: 'Error al agregar el clip. Inténtelo más tarde.',
          });
          console.log(err);
        },
      }
    );
    return { mutate, isLoading, error, progress, dataProgress };
};

  const changeStatusClip = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => postStatusClip(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  const deleteClipQuery = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => deleteClip(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  /*QUERYS TO PORTRAITS*/
  const changeStatusPortrait = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => putStatusPortrait(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  const deletePortraitQuery = (library) => {
    const { mutate, isLoading, error } = useMutation((opt) => deletePortrait(opt), {
      onSuccess: () => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
      },
      onError: (err) => {
        queryClient.invalidateQueries(library);
        queryClient.invalidateQueries(`${library}_arch`);
        console.log(err);
      }
    });
    return { mutate, isLoading, error }
  };

  /* PORTRAIT */
  const addPortrait = (library) => {
    const [progress, setProgress] = React.useState([]);
    const [data, setData] = React.useState([]);
    const dataProgress = useMemo(() => data, [data])
    
    const { mutate, isLoading, error } = useMutation(
      (opt) => postAddMultiplePortraits(opt, setProgress).then(() => opt),
      {
        onMutate: (param) => {
          setProgress(
            param.titles.map((title, index) => ({
              progress: 0,
              total: param.images[index].size,
            }))
          );
          setData(
            param.titles.map((title, index) => ({
              title,
              preview: URL.createObjectURL(param.images[index]),
            }))
          );
        },
        onSuccess: (_, opt) => {
          const prevData = queryClient.getQueryData(library);
          if (!prevData) return queryClient.invalidateQueries(library);
          const newFiles = opt.titles.map((title, index) => ({
            id: 'temp',
            title,
            status: 'temp',
            file: URL.createObjectURL(opt.images[index]),
          }));
          const newDataClient = addFileInStructureByParentId(prevData.data, opt.folderId, newFiles);
          queryClient.setQueryData(library, {data: newDataClient});
          queryClient.invalidateQueries(library);
          const timer = setInterval(() => {
            queryClient.invalidateQueries(library);
            setTimeout(() => {
              clearInterval(timer);
            }, 10000);
          }, 2000);
        },
        onError: (err) => {
          queryClient.invalidateQueries(library);
          queryClient.invalidateQueries(`${library}_arch`);
          showToast({
            show: true,
            type: 'error',
            message: 'Error al agregar la portada. Inténtelo más tarde.',
          });
          console.log(err);
        },
      }
    );
    return { mutate, isLoading, error, progress, dataProgress };
};


  return {
    deleteFilesQuery,
    moveFilesQuery,
    createFolder,
    deleteFolderQuery,
    changeStatusFolder,
    moveFolderQuery,
    changeStatusVideo,
    deleteVideoQuery,
    addClip,
    changeStatusClip,
    deleteClipQuery,
    changeStatusRecording,
    deleteRecordingQuery,
    changeStatusPortrait,
    deletePortraitQuery,
    /* PORTRAIT */
    addPortrait,
  }
};