
import TableProvider from './Table/context/TableContext';
import BodyTable from './Table/BodyTable';
import HeaderTable from './Table/HeaderTable';
import WrapperTable from './Table/WrapperTable';

export const Table = ({
  hasCheckable,
  hasSelectable,
  header = [],
  body = [],
  className = '',
  selectedItems,
  onSelect,
  clients = [],
  onOrder,
  isLoading = false,
  onShow,
}) => {
  
  return (
    <TableProvider
      hasCheckable={hasCheckable}
      hasSelectable={hasSelectable}
      header={header}
      body={body}
      selectedItems={selectedItems}
      onSelect={onSelect}
      clients={clients}
      onOrder={onOrder}
      isLoading={isLoading}
      onShow={onShow}
    >
      <WrapperTable className={className}>
        <HeaderTable />
        <BodyTable />
      </WrapperTable>
    </TableProvider>
  );
};
