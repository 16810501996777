import { useEffect, useRef, useState } from 'react';
import ListLinkedVideo from '../../components/contacts/ListLinkedVideo';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../hooks/useAuthContext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ClientGet, ClientUpdate } from '../../services/client/ClientService';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AddList, AddTag, associatedList, tagList } from '../../services/client/TagService';
import Select from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { Spin, Spinner, Subtitle, Title } from '../../components/common';
import { useToast2 } from '../../hooks/useToast2';

const remapListsTags = (data) => (
  data.map((item) => ({
    label: item.title,
    value: item.id,
    created: item.created_at,
  }))
)

export function ContactProfile() {
  /* State */
  const formRef = useRef();
  const [client, setClient] = useState(null);
  const [tags, setTags] = useState([]);
  const [isLoadingTag, setIsLoadingTag] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [tag, setTag] = useState(null);
  const [list, setList] = useState(null);

  /* Hooks */
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm();

  /* Queries */
  useQuery(['CLIENT_GET', { token: user.access_token, id }], ClientGet, {
    enabled: !!id,
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.contact.error.load') });
    },
    onSuccess: (response) => {
      const { data } = response.data;

      setClient(data);
      setTag(data.defaultTags || remapListsTags(data.tags || []));
      setList(data.defaultList || remapListsTags(data.lists || []));
    },
  });
  useQuery(['TAG_LIST', { token: user.access_token }], tagList, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.list.error.load_all') });
    },
    onSuccess: (data) => {
      setTags(data.data.data);
    },
  });
  const { data, isLoading } = useQuery(
    ['ASSOCIATED_LIST', { token: user.access_token }],
    associatedList,
    {
      onError: (error) => {
        showToast({ show: true, type: 'error', message: t('Message.tag.error.load_all') });
      },
    },
  );

  const lists = data?.data?.data || [];

  /* Mutations */
  const mutationClientUpdate = useMutation('UPDATE_CLIENT', ClientUpdate, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: `Error ${error.status}` });
    },
    onSuccess: (data) => {
      showToast({ show: true, type: 'success', message: t('Message.contact.success.update') });
    },
  });

  // Effects
  useEffect(() => {
    if (client) {
      setValue('email', client.email);
      setValue('name', client.name);
      setValue('last_name', client.last_name);
      setValue('second_last_name', client.second_last_name);
      setValue('phone', client.phone);
      setValue('society', client.society);
      trigger();
    }
  }, [client]);

  /* Functions */
  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  const handleCreateTag = (inputValue) => {
    AddTag({ token: user.access_token, tag: { title: inputValue } });
    setIsLoadingTag(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingTag(false);
      setTags((prev) => [...prev, newOption]);
      setTag((prev) => [...prev, newOption]);
    }, 1000);
  };

  const handleCreateList = (inputValue) => {
    setIsLoadingList(true);
    AddList({ token: user.access_token, list: { title: inputValue } })
      .then((response) => {
        const newOption = createOption(inputValue);
        setList((prev) => [...prev, newOption]);

        const { data } = response.data;
        queryClient.setQueryData(['ASSOCIATED_LIST', { token: user.access_token }], (prev) => {
          return {
            ...prev,
            data: {
              ...prev.data,
              data: [...prev.data.data, data],
            },
          };
        });
      })
      .catch((error) => {
        showToast({ show: true, type: 'error', message: `Error al crear el contacto` });
      })
      .finally(() => {
        setIsLoadingList(false);
      });
  };

  const onSubmit = (data) => {
    Object.assign(data, {
      id: client.id,
      tag,
      list,
    });
    const isValid = Object.keys(errors).length === 0;

    if (isValid) mutationClientUpdate.mutate({ token: user.access_token, client: data });
  };
  
  return (
    <>
      <Title title={t('Contact.profile.meta')} />
      <div className="relative">
        {!client && (
          <div className="absolute inset-0 z-10 backdrop-filter backdrop-blur-sm">
            <div className="relative w-full h-full aspect-square flex justify-center items-center">
              
              <div className="bg-gray-900/0 p-2 backdrop-filter backdrop-blur-sm flex flex-col whitespace-nowrap justify-center items-center z-20">
                <span className="text-2xl">Cargando contacto</span>
              </div>
              
              {/* Loader */}
              <div className="absolute inset-0 flex justify-center items-center">
                <div className="animate-spin rounded-full w-40 aspect-square border-t-2 border-b-2 border-primary" />
              </div>
            </div>
          </div>
        )} 
        <div className="flex space-x-20 ">
          <div className="w-3/4 pl-2">
            <div className="mt-8">
              <Subtitle title={t('Contact.profile.details')} />
            </div>
            <form ref={formRef} className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
              {/* Fields */}
              <div className="form">
                <div className="">
                  <div className="relative mt-2 w-full rounded-md shadow-sm">
                    <input
                      {...register('email', { required: true })}
                      type="email"
                      name="email"
                      id="email"
                      className=" h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder={t('Placeholder.email')}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span> * </span>
                    </div>
                  </div>
                  { errors.email && <span className="text-red-500 text-sm">{t('Validation.required')}</span> }
                </div>
                <div>
                  <div className="relative mt-2 rounded-md shadow-sm w-full">
                    <input
                      {...register('name', { required: true })}
                      type="text"
                      name="name"
                      id="name"
                      className=" h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder={t('Placeholder.name')}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span> * </span>
                    </div>
                  </div>
                  { errors.name && <span className="text-red-500 text-sm">{t('Validation.required')}</span> }
                </div>
                <div className="relative mt-2 rounded-md shadow-sm w-full">
                  <input
                    {...register('last_name')}
                    type="text"
                    name="last_name"
                    id="surname"
                    className=" h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder={t('Placeholder.surname')}
                  />
                </div>
                <div className="relative mt-2 disabled rounded-md shadow-sm w-full">
                  <input
                    {...register('second_last_name')}
                    type="text"
                    name="second_last_name"
                    className="disabled h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder={t('Placeholder.second_surname')}
                  />
                </div>
                <div className="relative mt-2 rounded-md shadow-sm w-full">
                  <input
                    {...register('phone')}
                    type="text"
                    name="phone"
                    id="phone"
                    className=" h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder={t('Placeholder.phone')}
                  />
                </div>
                <div className="relative mt-2 rounded-md shadow-sm w-full">
                  <input
                    {...register('society')}
                    type="text"
                    name="society"
                    id="society"
                    className=" h-12 rounded-md border-0 w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder={t('Placeholder.company')}
                  />
                </div>

                <div className="mt-8">
                  <Subtitle dense title={t('Contact.profile.search_create_list')} />
                  <Select
                    components={animatedComponents}
                    placeholder={t('Placeholder.select')}
                    value={list}
                    isMulti
                    isClearable
                    isLoading={isLoadingList}
                    options={lists}
                    onChange={(newValue) => setList(newValue)}
                    onCreateOption={handleCreateList}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 6,
                        width: '100%',
                        flex: 1,
                      }),
                    }}
                  />
                </div>
                <div className="mt-8">
                  <Subtitle dense title={t('Contact.profile.search_create_tag')} />
                  <Select
                    placeholder={t('Placeholder.select')}
                    value={tag}
                    isClearable
                    components={animatedComponents}
                    isMulti
                    isDisabled={isLoadingTag}
                    isLoading={isLoadingTag}
                    onChange={(newValue) => setTag(newValue)}
                    onCreateOption={handleCreateTag}
                    options={tags}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 6,
                        width: '100%',
                        flex: 1,
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="flex space-x-4 mt-10 p-1">
                <input
                  type="button"
                  value={t('Contact.profile.back')}
                  onClick={() => navigate(-1)}
                  className="flex py-2.5 px-6 text-black-soft rounded-md bg-white ring-2 ring-gray-button  cursor-pointer"
                />
                <button
                  type="submit"
                  className="flex gap-2 items-center py-2.5 px-6 text-white rounded-md bg-primary cursor-pointer disabled:bg-gray-400"
                  disabled={mutationClientUpdate.isLoading}
                >
                  {mutationClientUpdate.isLoading && <Spin />}
                  {t('Contact.profile.save')}
                </button>
              </div>
            </form>
          </div>
          <div className=" w-full mx-auto">
            <div className="mt-8">
              <Subtitle title={t('Contact.profile.linked_videos.meta')} />
            </div>
            <div className="mt-4 overflow-auto" style={{ height: 'calc(100vh - 200px)' }}>
              {client && <ListLinkedVideo items={client.videos} />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
