
import useSearch from "./hook/useSearch";

const ItemListSearch = ({item, query, onClose}) => {
  const { categories } = useSearch();
  
  const highlightedName = (item?.title || item?.name || '')
    .replace(
      new RegExp(`(${query})`, 'gi'),
      `<strong class=text-gray-700>$1</strong>`, // TODO: map types for i18n
    )

  const finalText = `${highlightedName} (${categories[item?.category || 'unknown'].label})`;

  const handleClick = () => {
    item.onClick && item.onClick();
    onClose();
  }
  
  return (
    <div 
      className="bg-white flex items-center gap-4 text-gray-400 p-1 px-3 cursor-pointer hover:bg-gray-100"
      onClick={handleClick}
    >
      {categories[item.category].icon}
      <div
        className="text-lg truncate"
        dangerouslySetInnerHTML={{ __html: finalText }}
      />
    </div>
  )
}
export default ItemListSearch;