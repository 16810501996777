import { useContext, useMemo } from 'react';
import { OptionItem } from './OptionItem';
import { FolderContext } from '../../contexts/folderContext';
import { getOptions } from '../../config/data';
import useAuthContext from '../../hooks/useAuthContext';

export function Options() {
  const { user } = useAuthContext();
  const { 
    toggleArchive, 
    library, 
    foldersSelected, 
    filesSelected, 
    rootFolders 
  } = useContext(FolderContext);

  const isAgente = user?.role === 'Agente';

  const options = useMemo(() => {
    const initialOpt = getOptions('index', { folders: rootFolders, library });
    const idItemsToDisabled = [2, 3];
    if (isAgente && library === 'corporative') {
      idItemsToDisabled.push(1);
    }
    const opt = initialOpt.map((item) => {
      if (idItemsToDisabled.includes(item.id)) {
        item.disabled = (isAgente && library === 'corporative') || (!foldersSelected.length && !filesSelected.length);
      }
      return item;
    });
    return opt || [];
  }, [rootFolders, library, foldersSelected, filesSelected]);

  const finalItems = toggleArchive
    ? options.filter((item) => item.id !== 1 && item.id !== 2)
    : options;
  return (
    <div className="flex justify-end items-start sticky top-0 z-20 mt-20 w-fit">
      <div className="flex space-x-2 px-2 bg-white rounded-xl shadow-md">
        {finalItems.map((option) => (
          <OptionItem key={option.id} item={option} />
        ))}
      </div>
    </div>
  );
}
