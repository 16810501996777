import { EllipsisHorizontalIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import VideoItem from './VideoItem';
import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { DialogV2 } from '../../components/common';
import { useState } from 'react';
import SendVideo from '../../components/library/Video/SendVideo';
import { useNavigate } from 'react-router-dom';
import Routes from '../../routes/app/paths';

export default function LinkedVideo({ video, index }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSendVideo, setShowSendVideo] = useState(false);
  
  return (
    <div className="p-3 bg-gray-video rounded-lg"
      style={{
        zIndex: index
      }}
    >
      <VideoItem videoData={video} />
      <div
        className="flex justify-between items-center mt-2"
        style={
          {
            maxWidth: 'calc(100% - 0px)'
          }
        }
      >
        <div className=""
          style={{
            maxWidth: 'calc(100% - 30px)'
          }}
        >
          <p className="font-bold text-md truncate w-full">{video.title}</p>
          <p className="font-light text-sm">{moment(video.updated_at).format('DD/MM/YYYY')}</p>
        </div>
        <Menu>
          <MenuButton
            as={IconButton}
            className="p-1 rounded-full hover:bg-gray-100"
            rounded='full'
            shadow='sm'
            icon={
              <EllipsisHorizontalIcon className="w-6 h-6 font-bold" />
            }
          >
          </MenuButton>
          <MenuList className="bg-white p-1 rounded-md shadow-md">
            <MenuItem className='hover:bg-gray-100 p-1 px-2'
              isDisabled={true}
            >
              Estadísticas
            </MenuItem>
            <MenuItem className='hover:bg-gray-100 p-1 px-2'
              onClick={() => navigate(`${Routes.reuseVideos}/${video.id}`)}
            >
              Reutilizar video
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
      <div className="mt-4">
        <Button className="flex items-center py-1.5 bg-primary px-4 rounded-md text-sm"
          leftIcon={<PaperAirplaneIcon className="w-6 h-6" />}
          onClick={() => setShowSendVideo(true)}
          colorScheme={'primary'}
          color='white'
        >
          {t('Library.send')}
        </Button>
      </div>

      <DialogV2 open={showSendVideo} onClose={() => {setShowSendVideo(false)}} width='w-10/12' noScroll >
        <SendVideo id={video.id} onClose={() => {setShowSendVideo(false)}} />
      </DialogV2>
    </div>
  );
}
