// i18next-config.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import translationES from '../locales/es/translation.json';
import translationEN from '../locales/en/translation.json';

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      es: {
        translation: translationES,
      },
    },
    fallbackLng: 'es',
    debug: true, // Activa los mensajes de depuración en la consola
    interpolation: {
      escapeValue: false, // Permite el uso de variables en las traducciones
    },
  });

export default i18n;