import { useState, useRef, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import ElementOverlayContainer from '../../components/video/edition/others/ElementOverlayContainer';

import './style/EditFrontPageModal.css';
import { Accordion, AccordionContainer, Skeleton, Spin } from '../../components/common';
import { postGetImg, postUpdateImagePortrait } from '../../services/library/LibraryService';
import useAuthContext from '../../hooks/useAuthContext';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../hooks/useToast2';
import html2canvas from 'html2canvas';
import { AiOutlineClose } from 'react-icons/ai';
import { classNames, generateImageUrl } from '../../config/utils';

export default function EditFrontPageModal({ open, onClose, frontPage }) {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();

  // Hay que crear una url local a partir de la que hay en el servidor porque sino no captura la imagen
  const [localUrl, setLocalUrl] = useState('');

  const parentRef = useRef(null);

  const imageRef = useRef(null);

  const [overlayElements, setOverlayElement] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();
  const { showToast } = useToast2();

  // Para crear una url local a partir de la del servidor
  useEffect(() => {
    !open && setOverlayElement([]);
  }, [open]);

  useEffect(() => {
    if (!frontPage?.id || !open) return;
    // fetch
    postGetImg({
      token: user?.access_token,
      id: frontPage.id,
      type: 'portada'
    })
      .then(res => res.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob)
        setLocalUrl(url)
      })
      .catch(err => {
        showToast({
          show: true,
          type: 'error',
          message: 'No se puede editar en estos momentos. Intente mas tarde.'
        })
        onClose()
      })
  }, [frontPage?.id, open])    

  // Para guardar los cambios realizados sobre la imagen
  const mutationFrontPageEdit = useMutation('EDIT_FRONT_PAGE', postUpdateImagePortrait, {
    onError: () => {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.template.error.update'),
      });
      queryClient.invalidateQueries(['portrait'])
      setIsSaving(false);
      setLocalUrl(null)
      onClose();
    },
    onSuccess: (data) => {
      if (data) {
        setTimeout(
          () =>
            showToast({
              show: true,
              type: 'success',
              message: t('Message.template.success.update'),
            }), 1000);
        queryClient.invalidateQueries(['portrait'])
        setIsSaving(false);
        setLocalUrl(null)
        onClose();
      }
    },
  });

  // Funciones para controlar los textos e imagenes superpuestos
  const onAddElementOverlay = (type = 'text') => {
    if (type === 'text') {
      const newElements = [...overlayElements];
      const newText = {
        text: '',
        position: { x: 0, y: 0 },
        styleText: {
          color: '#ffffff',
          fontSize: 16,
          fontFamily: 'Arial',
        },
        startSecond: 0,
        duration: 0, // Los textos duran 10 segundos por defecto luego se pueden cambiar
      };

      newElements.push(newText);

      const elementsCopy = JSON.parse(JSON.stringify(newElements));

      setOverlayElement(elementsCopy);
    } else {
      const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
          const imgUrl = URL.createObjectURL(file);

          const newElements = [...overlayElements];
          const newImg = {
            imgUrl,
            position: { x: 0, y: 0 },
            width: 100,
          };

          newElements.push(newImg);

          const elementsCopy = JSON.parse(JSON.stringify(newElements));

          setOverlayElement(elementsCopy);
        }
      };

      // Se le asigna un evento click y se da click programaticamente
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      fileInput.addEventListener('change', handleFileChange);
      fileInput.click();
    }
  };

  const onRemoveElementOverlay = (index) => {
    const newElements = [...overlayElements];
    newElements.splice(index, 1);

    const elementsCopy = JSON.parse(JSON.stringify(newElements));

    setOverlayElement(elementsCopy);
  };

  const onSaveElementOverlay = (index, data) => {
    const newElements = [...overlayElements];
    newElements.splice(index, 1);
    newElements.splice(index, 0, data);

    const elementsCopy = JSON.parse(JSON.stringify(newElements));

    setOverlayElement(elementsCopy);
  };

  const handleOnEditFrontPage = () => {
    setIsSaving(true);
    // Para que no aparezca con bordes la imagen
    imageRef.current.style.borderRadius = 0;

    const findEl = document.getElementById('capture-frame');
    if (!findEl) return console.log('Error: elemento no encontrado')
    
    html2canvas(findEl, { scale: 2 })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          const imgFile = new File([blob], 'editing_fp.jpg', { type: 'image/jpeg' });
          const url = URL.createObjectURL(blob)
          
          mutationFrontPageEdit.mutate({
            token: user.access_token,
            name: frontPage.title,
            portraitId: frontPage.id,
            image: imgFile,
          });
        }, 'image/jpeg');
      });

    imageRef.current.style.borderRadius = '8px';
  };
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed backdrop-filter backdrop-blur-sm inset-0 bg-[#000000] bg-opacity-75 transition-opacity"
              onClick={() => {
                onClose();
                setLocalUrl(null)
              }}
            />
          </Transition.Child>

          {/* Modal panel, show/hide based on modal state. */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          >
            <div className={classNames("edit_front-page lg:w-[886px] bg-white px-10 py-10", isSaving && 'pointer-events-none')}>
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold"></h3>
                <button
                  type="button"
                  className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none hover:shadow-md"
                  onClick={() => {
                    onClose();
                    setLocalUrl(null)
                  }}
                >
                  <span className="sr-only">{t('Library.close')}</span>
                  <AiOutlineClose className="h-6 w-6" />
                </button>
              </div>
              <div className="space-y-10">
                <div className="header-title">
                  <h3>{t('Library.frontPage_edition')}</h3>
                  <span>-</span>
                  <span>{frontPage?.title || ''}</span>
                </div>
                <div className="flex justify-between">
                  <section className="editing-section edit_front-page flex space-y-10 select-none">
                    <div id="capture-frame" className="relative flex gap-1" ref={parentRef}>
                      <div >
                        {!localUrl && (
                          <div className="relative flex justify-center items-center border-[1px] border-gray-200 rounded-lg"
                            style={{
                              width:451,
                              height:294
                            }}
                          >
                            <div className="absolute inset-0">
                              <Skeleton />
                            </div>
                            <Spin color='blue' />
                          </div>
                        )}
                        <img
                          className={classNames("editing_image select-none", !localUrl && 'hidden')}
                          ref={imageRef}
                          src={localUrl}
                          alt="Portada en edición"
                          width={451}
                          height={294}
                        />
                      </div>
                      <ElementOverlayContainer
                        className="element_overlay-fp"
                        classNameItems="element-fp-item"
                        key="textContainer2"
                        videoRef={imageRef}
                        containerRef={parentRef}
                        overlayElements={overlayElements}
                        type="image"
                        onDelete={onRemoveElementOverlay}
                        onSave={onSaveElementOverlay}
                        mergeTag={false}
                        onInEdition={setIsEditing}
                      />
                    </div>

                    <div className="py-3">
                      <button className="edit_img-button flex gap-2 justify-center items-center disabled:opacity-50" onClick={handleOnEditFrontPage} 
                        disabled={isSaving || isEditing}
                      >
                        {isSaving ?
                        <span>{t('Library.editing_image')}</span>
                        :<span>{t('Library.edit_image')}</span>}

                        {isSaving && <Spin />}
                      </button>
                    </div>
                  </section>
                  <section className="acordion-section">
                    <AccordionContainer className="select-none">
                      <Accordion title={t('Library.texts')}>
                        <button className="add-button select-none" onClick={() => onAddElementOverlay('text')}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="30"
                            viewBox="0 -960 960 960"
                            width="30"
                            fill="#545454"
                            style={{ marginRight: 5 }}
                          >
                            <path d="M450-200v-250H200v-60h250v-250h60v250h250v60H510v250h-60Z" />
                          </svg>
                          <span>{t('Library.add_text')}</span>
                        </button>
                      </Accordion>
                      <Accordion title={t('Library.images')}>
                        <button className="add-button" onClick={() => onAddElementOverlay('img')}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="30"
                            viewBox="0 -960 960 960"
                            width="30"
                            fill="#545454"
                            style={{ marginRight: 5 }}
                          >
                            <path d="M450-200v-250H200v-60h250v-250h60v250h250v60H510v250h-60Z" />
                          </svg>
                          <span>{t('Library.add_image')}</span>
                        </button>
                      </Accordion>
                    </AccordionContainer>
                  </section>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
}
