import { useState } from "react";

const Toggle = ({ onToggle, Active, register, registerValue }) => {
  const [isActive, setIsActive] = useState(Active);

  const handleToggle = () => {
    setIsActive(!isActive)
    onToggle(!isActive)
  };

  return (
    <div className="relative inline-block w-10 h-6 bg-gray-300 rounded-full">
      <input
        {...register(registerValue)}
        type="checkbox"
        id="toggle"
        className="absolute top-0 left-0 w-4 h-4 m-1 rounded-full appearance-none cursor-pointer"
        checked={isActive}
        onChange={handleToggle}
      />
      <label
        htmlFor="toggle"
        className={`absolute top-0 left-0 w-11 h-6 rounded-full shadow-md cursor-pointer ${isActive ? 'bg-green-400' : 'bg-gray-300'}`}
      >
        <span
          className={`inline-block w-5 h-5 m-[2px] rounded-full bg-white transform transition-transform duration-300 ease-in-out ${isActive ? 'translate-x-5' : ''
            }`}
        ></span>
      </label>
    </div>
  );
};

export default Toggle;