import { CustomMultiValue, CustomSingleValue } from '../../../../components/common/SelectComponents';
import Select from 'react-select/creatable';

const styles = {
  control: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 10,
    fontSize: '0.8rem',
    border: '2px solid #c0c0c0',
    color: '#c0c0c0',
    fontWeight: 600,
    width: '100%',
    flex: 1,
    padding: '0 0.2rem',
    marginRight: '1.8rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  }),
};

const SelectFilter = ({
  options,
  placeholder,
  value,
  onChange,
  isMulti = false,
  isLoading = false,
}) => {
  const component = isMulti ? { MultiValue: CustomMultiValue } : { SingleValue: CustomSingleValue };
  
  return (
    <div className="z-30">
      <Select
        components={component}
        options={options}
        placeholder={placeholder}
        styles={styles}
        onChange={onChange}
        value={value}
        isClearable
        isMulti={isMulti}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SelectFilter;