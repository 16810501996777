const OverlayVideos = ({ children, overlay }) => {
  return (
    <div className="relative flex gap-2 rounded-md overflow-hidden border-[1px] border-gray-50">
      <div className="w-full h-full overflow-hidden">
        {children}
      </div>

      <div className="absolute w-[150px] h-[150px] bottom-2 right-2 rounded-xl overflow-hidden">
        {overlay}
      </div>          
    </div>
  )
}

export default OverlayVideos