import { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import TextOverlay from './TextOverlay';
import ImageOverlay from './ImageOverlay';

import './styles/ElementOverlayContainer.css';
import { useCallback } from 'react';

const ElementOverlayContainer = forwardRef(function ElementOverlayContainer(
  {
    className = '',
    videoRef,
    videoIndex = 0,
    containerRef,
    overlayElements = [],
    type = 'video',
    onDelete = () => {},
    onSave = () => {},
    currentTime = 0,
    classNameItems = '',
    editableElem = true, // Para los contextos en los que no se pueda editar nada solo sea para ver
    guideGrid = false,
    mergeTag = true,
    onInEdition = () => {},
  },
  ref,
) {
  const classname = `element_overlay-container ${className}`;

  const [containerStyle, setContainerStyle] = useState({
    width: 0,
    height: 0,
  });

  useImperativeHandle(ref, () => ({
    containerRef: containerRef.current,
  }));

  useEffect(() => {
    containerRef.current.style.position = 'relative'; // Establecer position: relative en el componente padre
  }, [containerRef]);

  /**
   * Para calcular el espacio que ocupa el video en sí en caso de que no
   * coincida la relación de aspecto del video o imagen con la etiqueta
   * de video o imagen respectivamente y queden franjas negras a los lados
   */
  const calculateContainerSize = useCallback(() => {
    const videoElement = videoRef.current;
    const containerElement = containerRef.current;

    if (type && videoElement && containerElement) {
      const videoWidth = type === 'video' ? videoElement.videoWidth : videoElement.naturalWidth; // En el caso de que sea una imagen

      const videoHeight = type === 'video' ? videoElement.videoHeight : videoElement.naturalHeight; // En el caso de que sea una imagen

      const { offsetWidth, offsetHeight } = videoElement;

      const widthRatio = offsetWidth / videoWidth;
      const heightRatio = offsetHeight / videoHeight;

      const aspectRatio = Math.min(widthRatio, heightRatio);

      const containerStyle = {
        width: videoWidth * aspectRatio || 0,
        height: videoHeight * aspectRatio || 0,
      };

      setContainerStyle(containerStyle);
    }
  }, [videoRef, containerRef, type]);

  useEffect(() => {
    calculateContainerSize();
  }, []);

  useEffect(() => {
    if (type === 'video' && videoRef && videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', calculateContainerSize);
    } else if (type === 'image' && videoRef && videoRef.current) {
      videoRef.current.addEventListener('load', calculateContainerSize);
    }

    window.addEventListener('resize', calculateContainerSize);

    return () => {
      window.removeEventListener('resize', calculateContainerSize);
    };
  }, [calculateContainerSize, type, videoRef, videoIndex]);

  const parentRef = useRef(null);

  const handleInEdition = (inEdition) => {
    onInEdition(inEdition);
  }

  return (
    <div
      className={classname}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        ...containerStyle,
      }}
    >
      {guideGrid && (
        <div className="grid-container">
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
        </div>
      )}

      <div className="drag_container" ref={parentRef} style={{ ...containerStyle }}>
        {overlayElements.map((data, index) => {
          if (data.styleText) {
            return (
              <TextOverlay
                key={`textOverlay${index}`}
                index={index}
                data={data}
                parentRef={parentRef}
                onDelete={onDelete}
                onSave={onSave}
                currentTime={currentTime}
                className={classNameItems}
                editable={editableElem}
                mergeTag={mergeTag}
                onInEdition={handleInEdition}
              />
            );
          } else {
            return (
              <ImageOverlay
                key={`imageOverlay${index}`}
                index={index}
                data={data}
                parentRef={parentRef}
                onDelete={onDelete}
                onSave={onSave}
                className={classNameItems}
                editable={editableElem}
                onInEdition={handleInEdition}
              />
            );
          }
        })}
      </div>
    </div>
  );
});

ElementOverlayContainer.propTypes = {
  videoRef: PropTypes.object,
  containerRef: PropTypes.object,
  overlayElements: PropTypes.array,
  type: PropTypes.string,
  videoIndex: PropTypes.number,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  currentTime: PropTypes.number,
};

export default ElementOverlayContainer;
