import { useTranslation } from 'react-i18next';
import { getFolders } from '../../services/library/LibraryService';
import { useQuery } from 'react-query';
import useAuthContext from '../../hooks/useAuthContext';
import { useEffect, useMemo } from 'react';
import { Title } from '../../components/common';
import moment from 'moment';
import { Item } from './Item';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from '../../routes/app/paths';

export function LibraryList() {
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(LOGIN);
    }
  }, [isAuthenticated]);


  const { data, isLoading } = useQuery(
    ['HOME'],
    () =>
      getFolders({
        token: user?.access_token,
        type: 'video',
        archived: 0,
      }),
    {
      enabled: !!user?.access_token,
    },
  );

  const files = useMemo(() => {
    if (!data?.data?.length || !data?.data[0]?.files.length) return [];
    return data.data[0].files
      // sort by created_at desc using moment
      .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
      // get only 6 files
      .slice(0, 6)
  }, [data]);

  return (
    <div className="bg-white mt-6 ">
      <div className="my-16">
        <div className="max-w-2xl">
          {files?.length > 0 && <Title title={'Recientes'} />}
        </div>
        <div className="mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {files.map((file, i) => (
            <Item key={i} item={file}/>
          ))}
        </div>
      </div>
    </div>
  );
}
