import { useState } from "react";
import ContactsStepProvider from "../../../components/video/videoSteps/ContactsStepElements/context/ContactsStepContext";
import ContactsPicker from "./SendVideoElement/ContactsPicker";
import { ResendVideo } from "../../../services/video/VideoService";
import useAuthContext from "../../../hooks/useAuthContext";
import { useToast2 } from "../../../hooks/useToast2";
import { classNames } from "../../../config/utils";

const SendVideo = ({ id, onClose }) => {
  const { user } = useAuthContext();
  const [subject, setSubject] = useState('');
  const { showToast } = useToast2();
  const [isSending, setIsSending] = useState(false);

  const sendVideo = (clients) => {
    if (!clients?.length || !id || !subject.length) return;
    setIsSending(true);

    ResendVideo({
      token: user?.access_token,
      id_video: id,
      id_client: clients.map(c => c.id),
      asunto_email: subject,
    })
      .then((res) => {
        showToast({
          show: true,
          type: 'success',
          message: 'Video reenviado correctamente',
        })
      })
      .catch((err) => {
        showToast({
          show: true,
          type: 'error',
          message: 'Error al reenviar el video',
        })
      })
      .finally(() => {
        setIsSending(false);
        onClose();
      })
  }
  
  return (
    <div className="p-2 px-2">
      <ContactsStepProvider>
        <div className={classNames("flex gap-2 mb-2 items-end", isSending && 'pointer-events-none opacity-90')}>
          <div className="">
            <h1 className={`font-bold tracking-tight text-black text-2xl 2xl:text-3xl`}>
              Reenviar video
            </h1>  
            <h3 className={`font-semibold tracking-tight text-gray-700 text-sm 2xl:text-md whitespace-nowrap`}>
              Seleccione los contacto y escriba un asunto
            </h3>  
          </div>
          <input
            type="text"
            placeholder={'Escriba el asunto del correo'}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            autoComplete="off"
            className="h-full flex-1 mx-5 rounded-xl placeholder:text-gray-400 text-xs font-semibold border-2 border-gray-500 focus:outline-none hover:border-gray-600 duration-100"
          />
        </div>
        <ContactsPicker 
          id={id} 
          onSendClients={sendVideo} 
          isValid={subject?.length > 0} 
          isSending={isSending}
        />
      </ContactsStepProvider>
    </div>
  );
};

export default SendVideo;
