import { useState } from "react";
import { classNames } from "../../../../config/utils";
import { FaRegEdit } from "react-icons/fa";

const HeaderField = ({
  isCheckable,
  title,
  index,
  dropdown = true,
  width,
  maxWidth,
  type = 'text',
  configCheckbox,
  onOrder,
  onSelect,
  clients,
  selectedItems,
  orderColumn,
  textWrap,
}) => {
  // states
  const [checked, setChecked] = useState(configCheckbox?.checked || false);
  const [dropdownDirection, setDropdownDirection] = useState(false);

  const classHeader = classNames(
    isCheckable && 'w-10 cursor-default',
    'whitespace-nowrap text-center py-3 2xl:pt-[6px] 2xl:pb-[6.5px] text-left text-xs 2xl:text-sm select-none sm:w-12 bg-black-soft first:rounded-tl-xl first:rounded-bl-xl last:rounded-tr-xl last:rounded-br-xl border-l border-gray-600 w-full',
    dropdown && !isCheckable && 'cursor-pointer',
  );

  if (isCheckable) {
    return (
      <th scope="col" className={classHeader} style={{ minWidth: 40 }}>
        <div className="">
          <input
            checked={checked && selectedItems.length === clients.length}
            type={'checkbox'}
            className="w-4 2xl:w-6 h-4 2xl:h-6 rounded-md border-2 focus:outline-none cursor-pointer hover:border-primary duration-100 checked:bg-primary checked:border-transparent focus:ring-0 focus:ring-offset-0 focus:ring-primary hover:text-primary focus:text-primary"
            onChange={() => {
              setChecked((prev) => !prev);
              onSelect(!checked);
            }}
          />
        </div>
      </th>
    );
  }

  switch (type) {
    default: //or text
      return (
        <th
          scope="col"
          className={classHeader}
          style={{
            minWidth: width,
            maxWidth,
          }}
        >
          <div
            className={classNames(
              'flex items-center px-1 2xl:p-2 ',
              dropdown ? 'justify-between' : 'justify-center',
            )}
          >
            <p
              className={classNames(
                'text-gray-300 hover:text-primary-lighter px-2 font-semibold',
                !textWrap ? 'whitespace-nowrap' : 'whitespace-normal',
              )}
            >
              {title}
            </p>
            {dropdown && (
              <svg
                className={`w-6 h-6 text-gray-300 transition-transform transform ${
                  dropdownDirection ? '-rotate-180' : '-rotate-270'
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => {
                  setDropdownDirection((prev) => !prev);
                  onOrder(orderColumn);
                }}
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            )}
          </div>
        </th>
      );
    case 'edit-checkbox':
      return (
        <th scope="col" className={classHeader} style={width && { minWidth: width }}>
          <div
            className={classNames(
              'flex items-center p-2 px-3',
              dropdown ? 'justify-between' : 'justify-center',
            )}
          >
            <div className="flex space-x-2 flex-grow">
              <FaRegEdit className="text-gray-300" size={18} />
              <p className="text-gray-300 whitespace-nowrap px-2 font-semibold">{title}</p>
            </div>

            <div className="flex items-center">
              <input
                type={'checkbox'}
                className="w-5 h-5 rounded-md border-2 focus:outline-none cursor-pointer hover:border-gray-400 duration-100 checked:bg-gray-400 checked:border-transparent focus:ring-0 focus:ring-offset-0 focus:ring-gray-100 hover:text-gray-400  focus:text-gray-400"
                checked={checked}
                onChange={(e) => {
                  configCheckbox?.onChange && configCheckbox?.onChange(e);
                  setChecked(!checked);
                }}
              />
            </div>
          </div>
        </th>
      );
  }
};

export default HeaderField;