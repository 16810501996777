import {
  ArrowDownTrayIcon,
  LinkIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { classNames, copyToClipboard, downloadVideo } from '../../config/utils';
import { useRef, useState } from 'react';
import { DOMAIN } from '../../routes/server/paths';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { BASE_URL, LANDING } from '../../routes/app/paths';
import { useToast2 } from '../../hooks/useToast2';
import { useTranslation } from 'react-i18next';
import { DialogV2 } from '../../components/common';
import { DownloadLandingLinks } from '../../services/video/VideoService';
import useAuthContext from '../../hooks/useAuthContext';

export default function VideoItem({ videoData, ...rest }) {
  const srcVideo = `${DOMAIN}${videoData.video}`;
  const srcThumbnail = videoData.thumbnail && `${DOMAIN}${videoData.thumbnail}`;

  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const isVideoAtStart = videoRef?.current?.currentTime === 0 || videoRef?.current?.ended || 0;
  const [showPreview, setShowPreview] = useState(false);

  const { showToast } = useToast2();
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const handleCopyUrl = (token) => {
    copyToClipboard(`${BASE_URL}/landing/${token}`)
      ? showToast({
          show: true,
          type: 'success',
          message: t('Message.copy.success', { text: 'URL' }),
        })
      : showToast({ show: true, type: 'error', message: t('Message.copy.error', { text: 'URL' }) });
  };

  const downloadXlsx = (id) => {
    DownloadLandingLinks({
      token: user?.access_token,
      videoId: id,
    })
      .then((blob) => {
        const url = URL.createObjectURL(blob.data);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'landing-links.xlsx';
        document.body.appendChild(link);

        // Simula un clic en el enlace para descargar el archivo
        link.click();

        // Elimina el objeto URL y el enlace del DOM
        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="w-full h-32 rounded-lg p-2 relative bg-gray-500">
      {/* Controls */}
      <div
        className={classNames(
          'absolute inset-0 z-20 p-2 rounded-lg transition flex flex-col items-end',
          playing
            ? 'opacity-0 hover:opacity-100 hover:backdrop-brightness-75'
            : 'backdrop-brightness-50',
        )}
      >
        <div className="flex flex-col items-end space-y-2 relative w-fit z-40">
          {videoData?.clients?.length > 1 && (
            <div className="flex flex-col items-end space-y-2">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={
                    <UserGroupIcon className="w-6 h-6 p-1 rounded-full bg-gray-video cursor-pointer hover:text-primary" />
                  }
                  size={'xs'}
                  borderRadius="full"
                />
                <MenuList>
                  {videoData.clients.map((client, index) => (
                    <MenuItem
                      key={index}
                      icon={<LinkIcon className="w-6 h-6 p-1" />}
                      onClick={() => handleCopyUrl(client.token)}
                    >
                      {client.name || 'Sin nombre'}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <ArrowDownTrayIcon
                className="w-6 h-6 p-1 rounded-full bg-gray-video cursor-pointer hover:text-primary"
                onClick={() => downloadXlsx(videoData.id)}
              />
            </div>
          )}
          {videoData?.clients?.length === 1 && (
            <div className="flex flex-col items-end space-y-2">
              <IconButton
                aria-label="Options"
                icon={
                  <LinkIcon className="w-6 h-6 p-1 rounded-full bg-gray-video cursor-pointer hover:text-primary" />
                }
                size={'xs'}
                borderRadius="full"
                onClick={() => handleCopyUrl(videoData.clients[0].token)}
              />
            </div>
          )}
        </div>
        <div
          className="absolute -m-4 z-10 inset-x-0 flex justify-center"
          style={{
            top: 'calc(50%)',
          }}
        >
          {!playing ? (
            <PlayCircleIcon
              onClick={() => setShowPreview(true)}
              className="w-9 h-9 text-gray-primary cursor-pointer"
            />
          ) : (
            <PauseCircleIcon
              onClick={() => setShowPreview(true)}
              className="w-9 h-9 text-gray-primary cursor-pointer"
            />
          )}
        </div>
      </div>

      {/* Thumbnail */}
      {srcThumbnail && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0 z-10 rounded-lg"
        >
          <img src={srcThumbnail} className="w-full h-full object-cover rounded-lg" />
        </motion.div>
      )}

      <DialogV2 open={showPreview} onClose={() => setShowPreview(false)}>
        {srcVideo ? (
          <div className="relative w-full h-full mt-1">
            <video className="w-full h-full" controls autoPlay src={srcVideo} type="video/mp4" />
          </div>
        ) : (
          <div className="relative w-full h-full">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-2xl font-bold text-gray-500">
                {t('Message.video.error.cant_not_play')}
              </p>
              <p className="text-sm font-light text-gray-500">
                {t('Message.video.error.not_found')}
              </p>
            </span>
          </div>
        )}
      </DialogV2>
    </div>
  );
}
