import { classNames, copyToClipboard } from '../../../../../config/utils';
import {memo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineMore } from 'react-icons/ai';
import { useToast2 } from '../../../../../hooks/useToast2';
import { BASE_URL, LANDING } from '../../../../../routes/app/paths';
import DropdownMenu from '../../../DropdownMenu';
import { useTable } from '../../context/TableContext';

const Field = memo(({ item, selected, onClick }) => {
  // states
  const [openInfoOptions, setOpenInfoOptions] = useState(false);
  const { header } = useTable();

  // hooks
  const { t } = useTranslation();
  // const infoOptionsRef = useRef(null);
  const buttonRef = useRef(null);
  const { showToast } = useToast2();

  // const 
  const infoOptionsList = [
    { 
      label: t('AdminZone.user.videos_table.show_video'),
      onClick: () => onClick(item?.route)
    },
    { 
      label: t('AdminZone.user.videos_table.show_landing'),
      onClick: () => window.open(LANDING + '/' + item.landing)
    },
    { 
      label: t('AdminZone.user.videos_table.copy_url'),
      onClick: () => copyToClipboard(`${BASE_URL}${LANDING}/${item?.landing}`)
        ? showToast({ show: true, type: 'success', message: t('Message.copy.success', { text: 'URL' }) })
        : showToast({ show: true, type: 'error', message: t('Message.copy.error', { text: 'URL' }) })
    },
  ];

  return (
    <td
      className={classNames(
        'relative sm:w-10 2xl:w-12 border-r border-gray-border first:rounded-tl-xl first:rounded-bl-xl truncate',
        'last:rounded-r-xl last:border-r-0',
        'whitespace-nowrap p-1 pl-2 2xl:p-2 2xl:pl-4 text-xs 2xl:text-sm text-left font-medium border-r border-gray-border ',
        selected ? 'text-black font-bold' : 'text-gray-text',
        typeof(item.value) === 'number' && 'text-center'
      )}
      style={{ maxWidth: header?.[item?.index]?.maxWidth }}
    >
      {(item?.type === 'text' || item?.type === 'number' || item?.type === 'date') &&
        item?.value &&
        item?.value}
      {item?.type === 'button' && item?.value !== t('AdminZone.user.videos_table.select') && (
        <button
          onClick={() => onClick(item?.route)}
          className="bg-primary block w-full text-white px-6 2xl:px-8 py-2.5 rounded-md m-auto select-none"
          style={{ maxWidth: '100px' }}
        >
          {item?.value}
        </button>
      )}
      {item?.type === 'button' && item?.value === t('AdminZone.user.videos_table.select') &&
        <>
          <button
            ref={buttonRef}
            // onClick={handleSelectButton}
            className="bg-primary w-full text-white px-2 2xl:px-3 py-2.5 rounded-md m-auto select-none flex justify-center items-center"
            style={{ maxWidth: '120px' }}
          >
            {item?.value}
            <AiOutlineMore className='ml-2' />
          </button>
          <DropdownMenu trigger={buttonRef.current} options={infoOptionsList} />
        </>
      }
    </td>
  );
});
Field.displayName = 'Field'; // Add display name to the component

export default Field;