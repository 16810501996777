import {
  ArrowLeftOnRectangleIcon,
  ChartBarIcon,
  PlayCircleIcon,
  TrophyIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { RiShieldUserLine } from 'react-icons/ri';
import Logo from '../../assets/img/logo@3x.png';
import ImgSvg from '../../assets/svg/picture.svg';
import MenuItem from '../menu/MenuItem';
import useAuthContext from '../../hooks/useAuthContext';
import Routes from '../../routes/app/paths';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlus } from 'react-icons/ai';
import { classNames } from '../../config/utils';

export function Sidebar({ movil, onClose }) {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const navigation = [
    {
      name: t('Sidebar.videos.title'),
      route: Routes.videos,
      icon: PlayCircleIcon,
      feather: false,
      children: [
        { name: t('Sidebar.videos.children.first'), route: Routes.videos },
        { name: t('Sidebar.videos.children.second'), route: Routes.reuseVideos },
      ],
    },
    {
      name: t('Sidebar.library.title'),
      feather: true,
      route: Routes.library.substring(0, Routes.library.length - 1),
      href: '#',
      icon: ImgSvg,
      children: [
        { name: t('Sidebar.library.children.videos'), route: Routes.library.substring(0, Routes.library.length - 1) },
        { name: t('Sidebar.library.children.company'), route: Routes.corporativeVideos },
        { name: t('Sidebar.library.children.records'), route: Routes.videoRecords },
        { name: t('Sidebar.library.children.frontPage'), route: Routes.portraits },
      ],
    },
    // {
    //   name: t('Sidebar.statistics.title'),
    //   href: '#',
    //   icon: ChartBarIcon,
    //   route: Routes.insights,
    //   children: [
    //     { name: t('Sidebar.statistics.children.first'), route: Routes.insights },
    //     { name: t('Sidebar.statistics.children.second'), route: Routes.generalInsights },
    //   ],
    //   feather: false,
    // },
    {
      name: t('Sidebar.contacts.title'),
      route: Routes.contacts,
      icon: UserIcon,
      children: [
        { name: t('Sidebar.contacts.children.first'), route: Routes.contacts },
        { name: t('Sidebar.contacts.children.second'), route: Routes.manageList },
      ],
    },
  ];

  const navigationS = [
    // Admin zone its added only if the user is Admin
    {
      name: t('Sidebar.tips'),
      href: '#',
      icon: TrophyIcon,
    },
    {
      name: t('Sidebar.leave'),
      icon: ArrowLeftOnRectangleIcon,
      type: 'logout',
    },
  ];

  const { user, isAuthenticated } = useAuthContext();

  if (user?.role === 'Manager' || user?.role === 'Admin') {
    navigation.splice(2,0, {
      name: t('Sidebar.statistics.title'),
      href: '#',
      icon: ChartBarIcon,
      route: Routes.insights,
      children: [
        { name: t('Sidebar.statistics.children.first'), route: Routes.insights },
        { name: t('Sidebar.statistics.children.second'), route: Routes.generalInsights },
      ],
      feather: false,
    })
  }

  if (user?.role === 'Admin') {
    navigationS.splice(0, 0, {
      name: t('Sidebar.admin.title'),
      route: 'admin-zone',
      icon: RiShieldUserLine,
      children: [
        { name: t('Sidebar.admin.children.first'), route: Routes.companies },
        { name: t('Sidebar.admin.children.second'), route: Routes.usersAdmin },
      ],
    })
  }

  if (user?.role === 'Manager' && user?.company_id) {
    navigationS.splice(0, 0, {
      name: t('Sidebar.manager.title'),
      href: `${Routes.companyProfile}/${user.company_id}`,
      icon: RiShieldUserLine,      
    })
  }
  
  return (
    <div className={classNames(" z-20", !movil && 'hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col w-[354px] mt-9 ml-9')}>
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="overflow-y-scroll">
        <div className=" origin-top-left scale-90 2xl:scale-100 h-[90%] 2xl:h-full">
          <div className="flex grow flex-col gap-y-5  border-gray-200 bg-gray-primary pb-4 pt-8  rounded-tl-3xl rounded-tr-3xl ">
            <div className="flex h-16 shrink-0 items-center px-10 cursor-pointer" onClick={() => navigate(Routes.home)} >
              <img className="w-4/5 aspect-auto" src={Logo} alt="Your Company" />
            </div>
            <div className="pl-6 rounded-tl-3xl bg-gray-secondary h-full rounded-tr-3xl">
              <nav className="flex flex-1 flex-col  overflow-hidden">
                <div className="">
                  <div className=" px-6 py-10">
                    <h1 className="mt-10 max-w-lg font-bold tracking-tight text-gray-soft text-4xl">
                      {isAuthenticated ? `${user.name} ${user.last_name}` : 'Usuario'}
                    </h1>
                    <p className=" text-gray-soft" style={{ fontSize: 24 }}>
                      {t("Sidebar.title", { role: user?.role })}
                    </p>
                  </div>
                  <ul role="list" className="flex flex-1 flex-col gap-y-7 ">
                    <li className="pt-6">
                      <ul role="list" className="-mx-2 space-y-1 pl-6">
                        {navigation.map((item, index) => (
                          <MenuItem key={index} item={item} onClose={onClose}/>
                        ))}

                        {/* Divider */}
                        <div className="relative">
                          <div className="w-3/4 border-t border-1 border-gray-400 my-8" />
                        </div>

                        {/* Secondary Nav */}
                        {navigationS.map((item, index) => (
                          <MenuItem key={index} item={item} onClose={onClose}/>
                        ))}

                        <div className="py-10">
                          <Link
                            to={Routes.videos}
                            className="flex bg-primary py-4 px-6 w-4/5 rounded-md justify-center items-center gap-2 text-white"
                          >
                            <AiOutlinePlus />{t('Sidebar.create_new_video')}
                          </Link>
                        </div>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
