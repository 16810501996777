import useAuthContext from '../../../hooks/useAuthContext';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { AddList, AddTag, associatedList, tagList } from '../../../services/client/TagService';
import { increment, replaceClients } from '../../../features/client/clientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AutocompleteMultiselect, Dropzone, Subtitle } from '../../../components/common';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../../hooks/useToast2';

export function Step1({ onUpload, errorUpload, setTagsSelected, setListsSelected }) {
  /* State */
  const [file, setFile] = useState(null);

  /* Hooks */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { showToast } = useToast2();
  const previewClients = useSelector((state) => state.client.previewClients);

  /* Querys */
  const { isLoading: isLoadingTag, data: dataTag } = useQuery(
    ['TAG_LIST', { token: user.access_token }],
    tagList,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        showToast({
          show: true,
          type: 'error',
          message: t('Message.tag.error.load_all'),
        });
      },
    },
  );
  
  const { isLoading: isLoadingList, data: dataList } = useQuery(
    ['ASSOCIATED_LIST', { token: user.access_token }],
    associatedList,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        showToast({
          show: true,
          type: 'error',
          message: t('Message.list.error.load_all'),
        });
      },
    },
  );

  /* Functions */
  const handleUploadedFile = (e) => {
    const file = e.target?.files[0] || e;

    onUpload(file);
    setFile(file);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const recentList = useMemo(() => 
    !isLoadingList && dataList?.data?.data 
      ?.filter(e => e.recent)
      ?.sort((a, b) => b.recent - a.recent) // order by recent property (its a number, the greater the more recent)
      .slice(0, 6)                          // only show the first 6
      .map((item) => item.label || item)
      || []
  , [dataList, isLoadingList]);

  const recentTag = useMemo(() =>
    !isLoadingTag && dataTag?.data?.data 
      ?.filter(e => e.recent)
      ?.sort((a, b) => b.recent - a.recent) // order by recent property (its a number, the greater the more recent)
      .slice(0, 6)                          // only show the first 6
      .map((item) => item.label || item)
      || []
  , [dataTag, isLoadingTag]);
  
  return (
    <div className="flex space-x-20 justify-between p-1">
      <div className="w-full">
        <div className="mt-8">
          <Subtitle title={t('Contact.import_contacts.step1.upload_file')} weight="bold" />
        </div>
        <form className="mt-10">
          <Dropzone onUpload={(e) => handleUploadedFile(e)} errorState={errorUpload} />
          <div className="mt-8">
            <Subtitle title={t('Contact.import_contacts.step1.update_contacts')} />

            <div className="relative flex items-start mt-2">
              <div className="flex h-6 items-center">
                <input
                  id="accept-rewrite"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  onChange={(e) => {
                    dispatch(replaceClients(e.target.checked));
                  }}
                />
              </div>
              <div className="ml-3 text-sm leading-6 ">
                <label
                  htmlFor="accept-rewrite"
                  id=" comments-description"
                  className="cursor-pointer text-gray-500"
                >
                  {t('Contact.import_contacts.step1.update_contacts_info')}
                </label>
              </div>
            </div>
          </div>
        </form>
        <div className="flex space-x-4 mt-10">
          <button
            onClick={() => {
              // dispatch(decrement());
              navigate(-1);
            }}
            className="flex py-2 px-6 text-black-soft rounded-md bg-white ring-2 ring-gray-button"
          >
            {t('Contact.import_contacts.step1.back')}
          </button>
          <button
            onClick={() => {
              if (file !== null) dispatch(increment());
            }}
            className={classNames(
              file && previewClients.clients?.length > 0
                ? 'bg-primary cursor-pointer'
                : 'bg-gray-600 cursor-not-allowed',
              'flex py-2 px-6 text-white rounded-md',
            )}
            disabled={!previewClients.clients?.length > 0}
          >
            {t('Contact.import_contacts.step1.continue')}
          </button>
        </div>
      </div>
      <div className="w-full">
        <div className="mt-8">
          <Subtitle title={t('Contact.import_contacts.step1.organize_contacts')} />
        </div>

        <div className="mt-4">
          <Subtitle dense title={t('Contact.import_contacts.step1.search_create_list')} className="whitespace-nowrap mb-4" />
          <AutocompleteMultiselect
            placeholder={t('Placeholder.select')}
            isLoading={isLoadingList}
            type="list"
            listOptions={
              !isLoadingList && dataList?.data?.data?.map((item) => item.label || item) || []
            }
            recentsTitle={recentList.length ? t('Contact.import_contacts.step1.recents_lists') : ''}
            recents={recentList}
            onChange={setListsSelected}
            mutationConfig={{
              mutationKey: 'ADD_LIST',
              mutationFn: AddList,
              token: user.access_token,
            }}
          />
        </div>
        <div className="mt-10">
          <Subtitle dense title={t('Contact.import_contacts.step1.search_create_tag')} className="whitespace-nowrap mb-4" />
          <AutocompleteMultiselect
            placeholder={t('Placeholder.select')}
            isLoading={isLoadingTag}
            type="tag"
            listOptions={
              !isLoadingTag && dataTag?.data?.data?.map((item) => item.label || item) || []
            }
            recentsTitle={recentTag.length ? t('Contact.import_contacts.step1.popular_tags') : ''}
            recents={recentTag}
            onChange={setTagsSelected}
            mutationConfig={{
              mutationKey: 'ADD_TAG',
              mutationFn: AddTag,
              token: user.access_token,
            }}
          />
        </div>
      </div>
    </div>
  );
}
