import { useEffect, useRef, useState } from 'react';
import {
  refetch,
  resetStore,
  setAcceptAction,
  setElements,
  setLabelFor,
  setOpen,
  setRefetch,
} from '../../features/common/commonSlice';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { setOpenAddTagListModal, setStep } from '../../features/client/clientSlice';
import { useMutation, useQuery } from 'react-query';
import { AddTag, tagList, tagUpdate } from '../../services/client/TagService';
import { useDispatch, useSelector } from 'react-redux';
import useAuthContext from '../../hooks/useAuthContext';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from '../../routes/app/paths';
import moment from 'moment';
import { ContextMenu, HeaderControl } from './ClientItems';
import { useToast2 } from '../../hooks/useToast2';
import ClientSkeleton from './ClientSkeleton';
import { classNames } from '../../config/utils';

export default function ClientTag() {
  /* State */
  const [selectedItem, setSelectedItem] = useState([]);
  const [tags, setTags] = useState([]);
  const [query, setQuery] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);
  const inputRefs = useRef([]);
  const [order, setOrder] = useState({
    column: 'name',
    type: 'desc',
  });
  const [showMenu, setShowMenu] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setSelectedItem([]);
  }, []);

  /* Hooks */
  const dispatch = useDispatch();
  const refetching = useSelector(refetch);
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idParam = Number(searchParams.get('id'));

  /* Querys */
  const { refetch: refetchTags, isLoading } = useQuery(['TAG_LIST', { token: user.access_token }], tagList, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.client.error.load_all') });
    },
    onSuccess: (response) => {
      const { data } = response?.data || {};
      setTags(data || []);

      data.map((item) => {
        item.selected = false;
        item.prevText = item.label;
        return item;
      });
      setIsEditing(false);
      dispatch(resetStore());
      setFilteredTags(data.sort((a, b) => (a.label > b.label ? -1 : 1)));
    },
  });

  /* Mutations */
  const mutationUpdateTag = useMutation('UPDATE_TAG', tagUpdate, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.client.error.update') });
    },
    onSuccess: (response) => {
      refetchTags();
      setIsEditing(false);
      setTimeout(
        () => showToast({ show: true, type: 'success', message: t('Message.client.success.update') }),
        1000,
      );
    },
  });

  const mutationTagAdd = useMutation('CREATE_TAG_ITEM', AddTag, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: error });
    },
    onSuccess: (data) => {
      showToast({ show: true, type: 'success', message: t('Message.client.success.created_tag') });
      dispatch(setRefetch(true));
      dispatch(setOpenAddTagListModal(false));
      setIsEditing(false);
    },
  });

  /* Functions */
  const handleMenuItem = (tag, item) => {
    switch (item.title) {
      case t('Contact.manage_list_tag.options.rename'):{
        tag.selected = true;
        setTags([...tags]);
        const index = tags.findIndex((item) => item.value === tag.value);
        setTimeout(() => inputRefs.current[index].select(), 100);
        setIsEditing(false);
        break;
      }
      case t('Contact.manage_list_tag.options.delete'):{
        dispatch(setOpen(true));
        dispatch(setLabelFor('Tag'));
        dispatch(setAcceptAction('removeTag'));
        dispatch(setElements([tag.value]));
        setIsEditing(false);
        break;
      }
    }
  };
  const add = (item) => {
    item.label.length > 20
      ? showToast({
        show: true,
        type: 'error',
        message: t('Message.client.error.tag_size'),
      })
      : mutationTagAdd.mutate({ token: user.access_token, tag: { title: item.label } });
  };

  const update = async (item) => {
    const tag = {
      id: item.value,
      title: item.label,
    };

    if (item.label.length > 20) {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.client.error.tag_size'),
      });
    } else {
      mutationUpdateTag.mutate({ token: user.access_token, tag });
      item.selected = false;
      setTags([...tags]);
    }
  };

  /* Consts */
  const menuOptions = [
    { id: 1, title: t('Contact.manage_list_tag.options.rename') },
    { id: 2, title: t('Contact.manage_list_tag.options.delete') },
  ];

  const setItemLabel = (tag, val) => {
    tag.label = val;
    tag.newText = val;

    setTags([...tags]);
  };

  const cancelEditing = (item) => {
    item.selected = false;
    item.label = item.prevText;
    setIsEditing(false);
    setTags([...tags]);
  };

  if (refetching) {
    refetchTags();
  }

  const handleSearchQuery = (e) => {
    const value = e.target.value;
    setQuery(value);
    const newTags =
      value === ''
        ? tags
        : tags.filter((tag) => tag.label.toLowerCase().includes(value.toLowerCase()));
    setFilteredTags(newTags);
  };

  const createTags = () => {
    const element = {
      value: -1,
      label: t('Contact.manage_list_tag.new_element'),
      selected: true,
      created: moment().format('DD/MM/YYYY'),
    };
    setFilteredTags([element, ...filteredTags]);
    setIsEditing(true);
    setTimeout(() => {
      inputRefs.current[0].select();
    }, 100);
  };

  const handleKeyDown = (e, tag) => {
    if (e.keyCode === 13) {
      if (tag.value !== -1) {
        update(tag);
      } else {
        add(tag);
      }
    } else if (e.keyCode === 27) {
      if (tag.value === -1) {
        const newList = filteredTags.filter((item) => item.value !== tag.value);
        setFilteredTags([...newList]);
      }
    }
  };

  const removeItems = () => {
    if (selectedItem.length > 0) {
      const ids = selectedItem.map((tag) => tag.value);

      dispatch(setLabelFor('tag'));
      dispatch(setOpen(true));
      dispatch(setElements(ids));
      dispatch(setAcceptAction('removeTag'));
      setSelectedItem([]);
    }
  };

  const orderTable = () => {
    let orderType = 'asc';

    if (order.type === 'asc') {
      orderType = 'desc';
    }

    // Actualizamos el estado del orden
    setOrder({
      column: 'label',
      type: orderType,
    });

    // Creamos una copia de los clientes para no modificar el estado directamente
    const orderedList = [...filteredTags];

    orderedList.sort((a, b) => {
      if (String(a.label).toLowerCase() < String(b.label).toLowerCase()) {
        return orderType === 'asc' ? -1 : 1;
      }
      if (String(a.label).toLowerCase() > String(b.label).toLowerCase()) {
        return orderType === 'asc' ? 1 : -1;
      }
      return 0;
    });

    // Actualizamos el estado con la tabla ordenada
    setFilteredTags(orderedList);
  };

  // effects
  useEffect(() => { //scroll to the element when is searched
    if (!idParam || !filteredTags?.length) return;
    let counter = 0;
    let element = null;
    const intervalId = setInterval(() => {
      if (counter === 20 || element) clearInterval(intervalId);
      counter ++;
      element = document.getElementById(idParam);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        clearInterval(intervalId);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    }
  }, [idParam, filteredTags]);

  const isAgente = user?.role === 'Agente';

  return (
    <div>
      {HeaderControl(
        t('Contact.manage_list_tag.tag'),
        orderTable,
        query,
        handleSearchQuery,
        createTags,
        selectedItem,
        removeItems,
        isEditing,
      )}

      <div className='h-[55vh] overflow-y-auto px-1'>
        {isLoading && Array.from({ length: 4}).map((_, index) => (
          <ClientSkeleton key={`list-client-${index}`} />
        ))}
        {filteredTags.map((tag, index) => (
          <div id={tag.value} key={index} className={classNames("my-3 bg-gray-list py-1.5 px-4 rounded-xl flex justify-between",
            idParam === tag.value && 'border-[1px] border-primary shadow-md shadow-primary')}>
            <div className="flex space-x-3 items-center w-full">
              <div>
                <input
                  className="rounded w-5 h-5 cursor-pointer border-gray-200 focus:ring-primary text-primary disabled:opacity-0 disabled:cursor-default"
                  type="checkbox"
                  disabled={isAgente}
                  checked={selectedItem.includes(tag)}
                  onChange={(e) =>
                    setSelectedItem(
                      e.target.checked
                        ? [...selectedItem, tag]
                        : selectedItem.filter((p) => p !== tag),
                    )
                  }
                />
              </div>
              {!tag.selected ? (
                <div className="py-[4px] flex flex-col">
                  <span className="font-bold text-gray-soft text-sm 2xl:text-md">{tag.label}</span>
                  <span className="text-xs 2xl:text-sm font-light text-gray-soft -mt-1">
                    {`${t('Contact.manage_list_tag.created_on')} ${tag.created}`}
                  </span>
                </div>
              ) : (
                <div className="flex py-[4px] flex-col">
                  <input
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    onKeyDown={(e) => handleKeyDown(e, tag)}
                    onChange={(e) => setItemLabel(tag, e.target.value)}
                    className="font-bold px-1 bg-white rounded-md focus:rounded-gray-300 focus:ring-1 focus:ring-gray-200 text-sm 2xl:text-md"
                    value={tag.label}
                  />
                  <span className="text-xs 2xl:text-sm font-light text-gray-soft">
                    {`${t('Contact.manage_list_tag.created_on')} ${tag.created}`}
                  </span>
                </div>
              )}
            </div>

            <div className="flex justify-center items-center space-x-2">
              {!isAgente && <div className="relative inline-block text-left">
                <div className="flex items-center">
                  <div className="inline-flex w-full justify-center items-center  gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 ">
                    <EllipsisHorizontalIcon
                      className="w-8 2xl:w-10 h-8 2xl:h-10 cursor-pointer text-gray-600 rounded-full p-0 hover:shadow-md active:text-primary active:scale-[0.97]"
                      onClick={() =>
                        setShowMenu((prev) => ({ ...prev, [index]: !prev[index] || true }))
                      }
                    />
                  </div>
                </div>

                <ContextMenu
                  showMenu={showMenu}
                  setShowMenu={setShowMenu}
                  index={index}
                  menuOptions={menuOptions}
                  handleMenuItem={handleMenuItem}
                  list={tag}
                />
              </div>}
              <button
                onClick={() => {
                  dispatch(setStep(0));
                  navigate(`${Routes.contacts}?filter=tag&id=${tag.value}`);
                }}
                className="bg-gray-soft text-white text-xs 2xl:text-sm rounded-lg px-8 py-1.5 hover:shadow-md active:text-primary-lighter active:scale-[0.97]"
              >
                {t('Contact.manage_list_tag.show')}
              </button>
            </div>
          </div>
        ))}
        
        {filteredTags.length === 0 && !isLoading && <span className='text-gray-600 italic'>No hay tags que mostrar, cree una nueva</span>}
      </div>
    </div>
  );
}
