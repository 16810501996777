// BASE
// export const BASE_URL = 'http://localhost:3000';
export const BASE_URL = import.meta.env.VITE_FRONT_DOMAIN || 'https://app.virtualvendorvideo.com';
//export const BASE_URL = 'https://market.danngos.com';
// export const BASE_URL = 'https://devvv.codeals.es';

// AUTH
export const HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const REGISTER_SUCCESS = '/register-success';
export const LOGOUT = '/logout';
export const PASSWORD_RECOVERY = '/password-recovery';
export const PASSWORD_CHANGE = '/reset/password/:token';
export const VALIDATE_EMAIL = '/confirm/account/:token';

// DASHBOARD
export const DASHBOARD = '/dashboard';

// PROFILE
export const PROFILE = '/profile';

// TUTORIAL
export const TUTORIAL = '/tutorial';

// VIDEOS
export const VIDEOS = '/videos';
export const VIDEO_NEW = '/video-new';

// PRESENTATIONS
export const PRESENTATIONS = '/presentations';
export const PRESENTATION_NEW = '/presentation-new';

// CLIENTS
export const CLIENTS = '/clients';
export const CLIENT_NEW = '/client-new';
export const MULTIPLE_CLIENT_NEW = '/import_clients';
export const CLIENT_DETAIL = '/client-detail';
export const CLIENT_DETAIL_ID = `${CLIENT_DETAIL}/:client`;

// STATISTICS
export const STATISTICS = '/statistics';
export const STATISTIC_DETAIL_ID = `${STATISTICS}/:video`;

// CLIPS
export const CLIPS = '/clips';
export const CLIP_NEW = '/clip-new';

// USERS
export const USERS = '/users';
export const USERS_ADMIN = '/users-admin';
export const USER_NEW = '/user-new';
export const USER_DETAIL = '/user-detail';
export const USER_DETAIL_ID = `${USER_DETAIL}/:user_id`;

// CATEGORIES
export const CATEGORIES = '/categories';
export const CATEGORY_DETAIL = '/category-detail';
export const CATEGORY_DETAIL_ID = `${CATEGORY_DETAIL}/:category`;

// SUBCATEGORY
export const SUBCATEGORY_DETAIL = '/subcategory';
export const SUBCATEGORY_DETAIL_ID = `${SUBCATEGORY_DETAIL}/:subcategory`;

// TEMPLATES
export const TEMPLATES = '/templates';
export const TEMPLATE_NEW = '/template-new';

// COMPANIES
export const COMPANIES = '/companies';
export const COMPANY_NEW = '/company-new';

// PRODUCTS
export const PRODUCTS = '/products';
export const PRODUCT_NEW = '/product-new';

// LANDING
export const LANDING = '/landing';
export const LANDING_PREVIEW = '/landing-preview';
export const LANDING_TOKEN = `${LANDING}/:token`;

// INFO
export const POLICIES = '/policies';
export const COOKIES = '/cookies';
export const LEGAL = '/aviso-legal';
export const CONDITIONS = '/condiciones-uso';
export const VIDEO = '/videor';

//TICKETS
export const TICKETS = '/tickets';

const Routes = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',

  /* Contacts Routes */
  contacts: '/contacts',
  newContact: '/contacts/new',
  contactProfile: '/contacts/profile',
  importContacts: '/contacts/import-contacts',
  checkImportedList: '/contacts/check-imported-list',
  manageList: '/contacts/manage-lists',

  /* Library Routes */
  library: '/library/',
  corporativeVideos: '/library/corporative',
  videoRecords: '/library/recordings',
  portraits: '/library/front-pages',

  /* Insights */
  insights: '/statistics',
  generalInsights: '/statistics/general',

  /* Videos */
  videos: '/videos',
  reuseVideos: '/videos/reuse-videos',
  videoNewContacts: '/videos/new-contact',
  videoImportContacts: '/videos/import-contacts',
  videoContactProfile: '/videos/profile',

  /* Admin Zone */
  companies: `/admin-zone${COMPANIES}`,
  newCompany: `/admin-zone${COMPANY_NEW}`,
  companyProfile: `/admin-zone/company-profile`,  
  usersAdmin: `/admin-zone${USERS}`,
  newUser: `/admin-zone/new-user`,
  userProfile: `/admin-zone/user-profile`,

  /* Profile Routes */
  account: '/account',
  account_preferences: '/account-preferences',
};

export default Routes;
