import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Table, Title } from '../../components/common'
import { Tabs } from '../../components/library'
import Routes from '../../routes/app/paths'
import { classNames } from '../../config/utils'
import { EditLanding } from '../../components/video'
import useAuthContext from '../../hooks/useAuthContext'
import { getUsersCompanies } from '../../services/admin/adminService'

const bodyExample = [
  [
    { value: 'Empresa 1', type: 'text' },
    { value: 'empresa1@text.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa1.com', type: 'text' },
    { value: 'www.empresa1.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 2', type: 'text' },
    { value: 'empresa2@text.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa2.com', type: 'text' },
    { value: 'www.empresa2.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 3', type: 'text' },
    { value: 'empresa3@test.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 3', type: 'text' },
    { value: 'empresa3@test.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 3', type: 'text' },
    { value: 'empresa3@test.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 3', type: 'text' },
    { value: 'empresa3@test.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 3', type: 'text' },
    { value: 'empresa3@test.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 3', type: 'text' },
    { value: 'empresa3@test.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 3', type: 'text' },
    { value: 'empresa3@test.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
  [
    { value: 'Empresa 3', type: 'text' },
    { value: 'empresa3@test.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'www.empresa3.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
];

export const Preferences = () => {
  // states
  const [users, setUsers] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);
  const [bodyTable, setBodyTable] = useState(bodyExample);
  const [order, setOrder] = useState({ column: 'name', type: 'desc' });
  const [filteredList, setFilteredList] = useState([]);

  // hooks
  const { t } = useTranslation();
  const { user } = useAuthContext();

  //const
  const headersTable = [
    { title: t('AdminZone.user.table.name'), dropdown: true },
    { title: t('AdminZone.user.table.last_name'), dropdown: true },
    { title: t('AdminZone.user.table.email'), dropdown: true },
    { title: t('AdminZone.user.table.role'), dropdown: true },
    { title: t('AdminZone.user.table.last_send'), dropdown: true },
    { title: 'Info.', dropdown: false },
  ];

  // effects
  useEffect(() => {
    createBodyTable(filteredList);
  }, [filteredList]);

  useEffect(() => {
    createBodyTable(users);
  }, [users]);

  // querys
  const { data: dataUsers, isLoading: loadingUsersCompanies, error: errorUsersCompanies } = useQuery(['USERS_COMPANIES'],
    () => getUsersCompanies({ token: user.access_token, companyId: user.company.id }), {
    onSuccess: (resp) => {
      setUsers(resp.data.data);
    },
    onError: (err) => {
      console.log(err);
    }
  });

  // handlers
  const handleSelectedTab = (tab) => {
    setSelectedTab(tab.id);
  };

  const handleSearchQuery = (query) => {
    setQuery(query);
    let search = users;

    query !== '' &&
      (search = search.filter(c =>
        c.name?.toLowerCase().includes(query.toLowerCase()) ||
        c.last_name?.toLowerCase().includes(query.toLowerCase()) ||
        c.email?.toLowerCase().includes(query.toLowerCase()) ||
        c.role?.toLowerCase().includes(query.toLowerCase())
        // c.last_send?.toLowerCase().includes(query.toLowerCase())
      ))

    setFilteredList(search);
  };

  const removeItems = () => {
    // mutateDeleteUsersCompany({ token: user.access_token, user_ids: [selectedItem[0].id] });
  }

  // functions  //Creando el cuerpo de la tabla
  const createBodyTable = (data) => {
    setBodyTable([]);
    let tdName,
      tdLastName,
      tdEmail,
      tdRole,
      tdLastSend,
      tdInfo = {};
    let array = [];
    data.map((item) => {
      const row = [];
      tdName = { type: 'text', value: item.name, name: 'name', id: item.id };
      tdLastName = { type: 'text', value: item.last_name, name: 'last_name' };
      tdEmail = { type: 'text', value: item.email, name: 'email' };
      tdRole = { type: 'text', value: item.role, name: 'role' };
      tdLastSend = { type: 'text', value: item.lastVideoDate?.split('T')[0], name: 'last_send' };
      tdInfo = {
        type: 'button',
        value: 'Ver',
        route: `${Routes.userProfile}/${item.id}`,
      };
      row.push(tdName, tdLastName, tdEmail, tdRole, tdLastSend, tdInfo);
      array.push(row);
    });
    setBodyTable(array);
  };

  const orderTable = (column) => {
    let orderType = 'asc';
    if (order.column === column && order.type === 'asc') {
      orderType = 'desc';
    }

    setOrder({
      column: column,
      type: orderType,
    });

    const orderedList = [...bodyTable];
    orderedList.sort((a, b) => {
      let x = null;
      let y = null;
      if (column === t('AdminZone.user.table.name')) {
        x = a[0].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[0].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (column === t('AdminZone.user.table.last_name')) {
        x = a[1].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[1].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (column === t('AdminZone.user.table.email')) {
        x = a[2].value !== null ? a[1].value.toLowerCase() : 'zzz';
        y = b[2].value !== null ? b[1].value.toLowerCase() : 'zzz';
      } else if (column === t('AdminZone.user.table.role')) {
        x = a[3].value !== null ? a[2].value.toLowerCase() : 'zzz';
        y = b[3].value !== null ? b[2].value.toLowerCase() : 'zzz';
      } else if (column === t('AdminZone.user.table.last_send')) {
        x = a[4].value === null || a[4].value === undefined ? 'zzz' : a[3].value.toLowerCase();
        y = b[4].value === null || b[4].value === undefined ? 'zzz' : b[3].value.toLowerCase();
      }
      if (x < y) {
        return orderType === 'asc' ? -1 : 1;
      }
      if (x > y) {
        return orderType === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setBodyTable(orderedList);
  };

  return (
    <>
      <div className='mb-8'>
        <Title title={t('Profile.preferences.meta')} size={'text-2xl'} />
      </div>
      <Tabs
        tabs={[
          { id: 0, title: t('Profile.preferences.tabs.plan'), selected: selectedTab === 0 },
          { id: 1, title: t('Profile.preferences.tabs.users'), selected: selectedTab === 1 },
          // { id: 2, title: t('Profile.preferences.tabs.landings'), selected: selectedTab === 2 }, //ocultado temporalmente
        ]}
        onSelect={handleSelectedTab}
      />
      {
        selectedTab === 0 &&
        <>
          <section className='mb-10'>
            <div className='my-6'>
              <div className='text-primary font-bold text-xl mb-4'>
                {t('Profile.preferences.plans.basic')}
              </div>
              <div className='w-[70%]'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi excepturi doloremque culpa. Facere, vel? Inventore eius autem ex, eligendi odio dolor quasi. Nobis quibusdam saepe suscipit deleniti incidunt fugiat placeat!
              </div>
            </div>
            <div className='my-6'>
              <div className='text-primary font-bold text-xl mb-4'>
                {t('Profile.discharge_date')}
              </div>
              <span className='text-gray-500 font-semibold'>
                07/12/2022
              </span>
            </div>
            <div className='my-6'>
              <div className='text-primary font-bold text-xl mb-4'>
                {t('Profile.renew_date')}
              </div>
              <span className='text-black font-semibold'>
                07/12/2022
              </span>
            </div>
            <div className='flex gap-3'>
              <button
                // onClick={}
                className="bg-primary flex text-white px-4 py-2 rounded-xl select-none hover:shadow-md active:scale-[0.98] transition-shadow text-sm 2xl:text-md"
              >
                {t('Profile.upgrade_plan')}
              </button>
              <button
                type="button"
                className="border-2 border-gray-400 text-gray-400 font-semibold px-4 py-2 rounded-xl select-none hover:shadow-md active:scale-[0.98] transition-shadow text-sm 2xl:text-md"
              // onClick={}
              >
                {t('Profile.unsubscribe')}
              </button>
            </div>
          </section>
          <section>
            <div className='font-bold text-md mb-4'>
              {t('Profile.preferences.other_plans')}
            </div>
            <div className='flex gap-10'>
              <div className='w-40 h-40 shadow-lg border-2 border-gray-200 rounded-md'></div>
              <div className='w-40 h-40 shadow-lg border-2 border-gray-200 rounded-md'></div>
              <div className='w-40 h-40 shadow-lg border-2 border-gray-200 rounded-md'></div>
            </div>
          </section>
        </>
      }
      {
        selectedTab === 1 &&
        <>
          <div className="flex p-1 space-x-5 mt-5 items-center justify-between">
            <div className="flex space-x-1 2xl:space-x-2">
              <h1 className="text-md 2xl:text-md whitespace-nowrap font-bold text-primary">{t('AdminZone.user.meta')}</h1>
            </div>
            <div className="flex items-center space-x-2 2xl:space-x-4 justify-end w-full">
              {/* <button      //solo desde admin por el momento
                onClick={hanldleNewUser}
                className="bg-primary flex text-white px-4 py-2 rounded-xl select-none hover:shadow-md active:scale-[0.98] transition-shadow text-sm 2xl:text-md"
              >
                {t('AdminZone.user.new')}
              </button> */}
              <input
                type="text"
                placeholder={t('Placeholder.search')}
                value={query}
                className="border-gray-300 text-sm 2xl:text-md rounded-lg focus:border-gray-300 focus:ring-1 focus:ring-gray-300 w-32 2xl:w-56 placeholder:text-gray-300"
                onChange={(e) => handleSearchQuery(e.target.value)}
              />
              {/* <TrashIcon        //solo desde admin por el momento
                className={classNames(
                  selectedItem.length !== 0
                    ? 'cursor-pointer text-black focus:text-primary'
                    : 'cursor-not-allowed text-gray-200',
                  'font-extralight w-6 2xl:w-8 h-6 2xl:h-8',
                )}
                onClick={removeItems}
              /> */}
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="flow-root">
              <div className="-ml-7">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 2xl:pr-0">
                  <div className="relative h-[330px] 2xl:h-[490px]">
                    {
                      users.length > 0 &&
                      <Table
                        header={headersTable}
                        body={bodyTable}
                        selectedItems={selectedItem}
                        clients={users}
                        onSelect={setSelectedItem}
                        onOrder={orderTable}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {/* ocultado temporalmente */}
      {/* {
        selectedTab === 2 &&
        <>
          <EditLanding scope='profile' />
          <button
            onClick={hanldleNewUser}
            className="bg-primary flex text-white px-4 py-2 rounded-lg select-none hover:shadow-md active:scale-[0.98] transition-shadow text-sm 2xl:text-md"
          >
            {t('Profile.save')}
          </button>
        </>
      } */}
    </>
  )
}
