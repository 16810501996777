import { Fragment, createRef, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { AiOutlineClose } from 'react-icons/ai';
import VideoPlayer from './edition/VideoPlayer';
import { useEffect } from 'react';

import './styles/SelectFrame.css';
import useAuthContext from '../../hooks/useAuthContext';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../hooks/useToast2';
import { saveFrontPage } from '../../features/videos/videosSlice';
import { useMutation } from 'react-query';
import { postSelectedFrame } from '../../services/library/LibraryService';
import { DestinationView } from '../../components/library/Video/Destination/DestinationView';
import { Spinner } from '../../components/common';
import { classNames, generateImageUrl } from '../../config/utils';

const FrameSelector = ({ maxTime = 0, onTimeChange }) => {
  useEffect(() => {
    document.getElementById('myinput').oninput = function () {
      var value = ((this.value - this.min) / (this.max - this.min)) * 100;
      this.style.background =
        'linear-gradient(to right, #f73757 0%, #f73757 ' +
        value +
        '%, #b2b2b2 ' +
        value +
        '%, #b2b2b2 100%)';
    };
  }, []);

  return (
    <div>
      <input
        id="myinput"
        className="frame-selector relative w-full h-2 z-20 bg-gray-200 rounded-md cursor-pointer"
        type="range"
        min={0}
        max={maxTime}
        onChange={onTimeChange}
        step={0.1}
      />
    </div>
  );
};

const FramePreview = ({ videos = [], onClose, setSelectedFrame }) => {
  const [maxTime, setMaxTime] = useState(0);
  
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  
  const videoRefs = videos.map(() => createRef(null));
  const imageRef = useRef(null);
  const { t } = useTranslation();

  const handleChangeTime = (event) => {
    const sliderValue = Number(event.target.value);

    if (sliderValue < 0 || sliderValue > maxTime) return;

    let accumulate = 0;
    let newTime = 0;

    for (let i = 0; i < videos.length; i++) {
      const video = videos[i];
      accumulate += video.duration;

      if (sliderValue <= accumulate) {
        newTime = sliderValue - (accumulate - video.duration);

        videoRefs[currentVideoIndex].current.currentTime = newTime;
        setCurrentVideoIndex(i);
        break;
      }
    }
  };

  useEffect(() => {
    if (videos && videos.length) {
      const countTime = videos.reduce((prevValue, video) => {
        return prevValue + video.duration;
      }, 0);

      setMaxTime(countTime);
    } else {
      setMaxTime(0);
    }
  }, [videos]);
  
  const handleSelectFrame = () => {
    setSelectedFrame({
      name: `frame_${videos[currentVideoIndex].id}_${new Date().toLocaleDateString()}`,
      video_id: videos[currentVideoIndex].id,
      current_time: videoRefs[currentVideoIndex].current.currentTime,
      libraryType: videos[currentVideoIndex].libraryType,
    });
  };

  return (
    <Fragment>
      <div className="frame-preview">
        {/* reemplazar aqui la imagen por un video */}
        {videos.map((video, index) => (
          <video 
          key={index} 
          ref={videoRefs[index]} 
          src={generateImageUrl(video.src)}
          className={classNames("absolute inset-0 w-full h-full", currentVideoIndex === index ? 'block' : 'hidden')}
        />
        ))}

        <div className="frame-preview-slider">
          <FrameSelector maxTime={maxTime} onTimeChange={handleChangeTime} />
        </div>
      </div>
      <div className="flex w-full justify-center">
        <button
          type="button"
          className="flex items-center justify-center h-12 px-4 mr-4 rounded-xl  text-gray-300 border-2 border-gray-300 font-semibold text-lg hover:shadow-lg active:scale-[0.98]"
          onClick={onClose}
        >
          {t('Video.cancel')}
        </button>
        <button
          type="button"
          className="flex items-center justify-center h-12 px-4 rounded-xl bg-primary text-white font-semibold text-lg hover:shadow-lg active:scale-[0.98]"
          onClick={handleSelectFrame}
        >
          {t('Video.select')}
        </button>
      </div>
    </Fragment>
  );
};

const SelectFrame = ({ open, onClose, videos = [] }) => {
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [showDestination, setShowDestination] = useState(false);
  const [folderId, setFolderId] = useState(0);

  const { user } = useAuthContext();

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { showToast } = useToast2();

  const onSuccess = (response) => {
    if (response) {
      setTimeout(
        () =>
          showToast({
            show: true,
            type: 'success',
            message: t('Message.template.success.add'),
          }),
        1000,
      );

      const data = response?.data || {};

      const frontPage = {
        id: data.id,
        file: data.src,
      };

      dispatch(saveFrontPage(frontPage));
      setShowDestination(false);
      onClose();
    }
  }
  const onError = () => {
    showToast({
      show: true,
      type: 'error',
      message: t('Message.template.error.load_all'),
    });
    onClose();
  };

  const {mutate: mutationSelectedFrame, isLoading: isLoadingSelectedFrame} = useMutation('CREATE_FRONT_PAGE', postSelectedFrame, {
    onError,
    onSuccess,
  });

  const handleSelectDestination = () => {
    // random title by date
    const title = `Front_page_frame_${new Date().toLocaleDateString()}`;
    // Mandamos a guardar la portada
    mutationSelectedFrame({
      token: user.access_token,
      name: selectedFrame.name,
      type: selectedFrame.libraryType, // clip | record
      folderId,
      video_id: selectedFrame.video_id,
      current_time: selectedFrame.current_time,
    });
  }

  const handleSelectFrame = (frame) => {
    setShowDestination(true);
    setSelectedFrame(frame);
  };

  const handleOnClose = () => {
    setSelectedFrame(null);
    onClose();
    setTimeout(() => {
      setShowDestination(false);
    }, 1000);
  }
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed backdrop-filter backdrop-blur-sm inset-0 bg-gray-900/80"
              onClick={handleOnClose}
            />
          </Transition.Child>

          {/* Modal panel, show/hide based on modal state. */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          >
            <div className="lg:w-[917px] bg-white px-3 pt-3 pb-8 space-y-8">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold"></h3>
                <button
                  type="button"
                  className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none hover:shadow-md"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <AiOutlineClose className="h-6 w-6" />
                </button>
              </div>
              <div
                className="relative space-y-10"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!showDestination ? (
                  <FramePreview
                    videos={videos}
                    onClose={onClose}
                    setSelectedFrame={handleSelectFrame}
                  />
                  ) : (
                    <div className="mt-4 2xl:mt-8">
                      <DestinationView 
                        onClose={handleOnClose} 
                        onSelected={handleSelectDestination}
                      />
                    </div>
                  )
                }
              </div>
              <Spinner open={isLoadingSelectedFrame} title={t('Message.frontPage.adding_frontPage')} />
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
};

export default SelectFrame;
