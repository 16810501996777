import { useTranslation } from 'react-i18next';
import SelectFilter from '../SelectFilter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setStep } from '../../../../../features/client/clientSlice';
import Routes from '../../../../../routes/app/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { InfoBox } from '../../../../../components/common';
import { AiOutlinePlus } from 'react-icons/ai';
import { TbUpload } from 'react-icons/tb';
import { useContactsStepContext } from '../context/ContactsStepContext';
import { useQuery } from 'react-query';
import useAuthContext from '../../../../../hooks/useAuthContext';
import { useToast2 } from '../../../../../hooks/useToast2';
import {
  associatedList,
  getClientsByParams,
  tagList,
} from '../../../../../services/client/TagService';

const ContactsFilters = () => {
  // hooks
  const { clients, setFilteredList } = useContactsStepContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { showToast } = useToast2();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { filter: filterParam, id } = Object.fromEntries(searchParams);

  // states
  const [lastVideoFilter, setLastVideoFilter] = useState(null);
  const [companyFilter, setCompanyFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [tag, setTag] = useState([]);
  const [tags, setTags] = useState([]);
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [query, setQuery] = useState('');
  const [isLoadingTag, setIsLoadingTag] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);

  // rq
  useQuery(['TAG_LIST', { token: user?.access_token }], tagList, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.tag.error.load_all') });
    },
    onSuccess: (response) => {
      const { data } = response.data;
      setTags(data || []);

      if (id) {
        const defaultV = data.find((item) => item.value == id);
        if (filterParam === 'tag') {
          setTag([defaultV]);
          tagListFilter();
        } else {
          setTag([]);
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  useQuery(['ASSOCIATED_LIST', { token: user.access_token }], associatedList, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.list.error.load_all') });
    },
    onSuccess: (response) => {
      const { data } = response.data;
      setLists(data || []);

      if (id) {
        const defaultV = data.find((item) => item.value == id);
        if (filterParam === 'list') {
          setList([defaultV]);
        } else {
          setList(null);
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  // constants
  const last_send = useMemo(
    () =>
      clients
        ?.map((c, i) => ({
          value: i,
          label:
            c.client_video.length > 0
              ? c.client_video[c.client_video.length - 1]?.updated_at.split('T')[0]
              : '',
        }))
        // remove duplicate
        .filter((item, index, self) => item && index === self.findIndex((t) => t.label === item.label)) ||
      [],
    [clients],
  );

  const company = useMemo(
    () =>
      clients
        ?.filter((c) => c.society !== null && c.society !== '')
        ?.map((c, i) => ({
          value: i,
          label: c.society,
        })) || [],
    [clients],
  );

  const date = useMemo(
    () =>
      clients?.map((c, i) => ({
        value: i,
        label: c.created_at.split('T')[0],
      })) || [],
    [clients],
  );

  // for the selects
  const selectData = useMemo(
    () => [
      {
        options: last_send,
        placeholder: t('Placeholder.last_send'),
        onChange: (newValue) => {
          handleFilterChange(newValue, 0);
        },
        value: lastVideoFilter,
      },
      {
        options: company,
        placeholder: t('Placeholder.company'),
        onChange: (newValue) => {
          handleFilterChange(newValue, 1);
        },
        value: companyFilter,
      },
      {
        options: date,
        placeholder: t('Placeholder.date'),
        onChange: (newValue) => {
          handleFilterChange(newValue, 2);
        },
        value: dateFilter,
      },
      {
        options: lists,
        placeholder: t('Placeholder.list'),
        isMulti: true,
        isLoading: isLoadingList,
        onChange: (newValue) => setList(newValue),
        value: list,
      },
      {
        options: tags,
        placeholder: t('Placeholder.tag'),
        isMulti: true,
        isLoading: isLoadingTag,
        onChange: (newValue) => setTag(newValue),
        value: tag,
      },
    ],
    [
      last_send,
      company,
      date,
      lists,
      tags,
      isLoadingList,
      isLoadingTag,
      lastVideoFilter,
      companyFilter,
      dateFilter,
      list,
      tag,
    ],
  );

  // handlers
  const handleFilterChange = (newValue, key) => {
    switch (key) {
      case 0:
        newValue !== null ? setLastVideoFilter(newValue) : setLastVideoFilter(null);
        break;
      case 1:
        newValue !== null ? setCompanyFilter(newValue) : setCompanyFilter(null);
        break;
      case 2:
        newValue !== null ? setDateFilter(newValue) : setDateFilter(null);
        break;

      default:
        break;
    }
  };

  const handleSearchQuery = (e) => {
    const value = e.target.value;
    setQuery(value);
    const newClients =
      value === ''
        ? clients
        : clients.filter(
            (client) =>
              client.name.toLowerCase().includes(value.toLowerCase()) ||
              client.last_name?.toLowerCase().includes(value.toLowerCase()) ||
              client.email.toLowerCase().includes(value.toLowerCase()) ||
              client.client_video.length.toString() === value ||
              moment(client.client_video[client.client_video.length - 1]?.video.created_at)
                .format('YYYY-MM-DD')
                .includes(value.toLowerCase()),
          );
    setFilteredList(newClients);
  };

  const handleImportContact = () => {
    dispatch(setStep(1));
    navigate(Routes.videoImportContacts);
  };

  const handleNewContact = () => {
    navigate(Routes.videoNewContacts);
  };

  const runFilter = useCallback(() => {
    let filter = clients;

    lastVideoFilter !== null &&
      (filter = filter.filter((e) =>
        e.client_video.length > 0
          ? e.client_video[e.client_video.length - 1]?.updated_at.split('T')[0]
          : '' === lastVideoFilter.label,
      ));
    companyFilter !== null && (filter = filter.filter((e) => e.society === companyFilter.label));
    dateFilter !== null &&
      (filter = filter.filter((e) => e.created_at.split('T')[0] === dateFilter.label));

    setFilteredList(filter);
  }, [clients, companyFilter, dateFilter, lastVideoFilter]);

  const tagListFilter = useCallback(() => {
    const tagsIds = tag?.length > 0 ? tag.map((t) => t.value) : [];
    const listIds = list?.length > 0 ? list.map((l) => l.value) : [];

    getClientsByParams({
      tags: tagsIds,
      lists: listIds,
      token: user.access_token,
    })
      .then((response) => {
        const { data } = response.data;
        setFilteredList(data);
      })
      .catch((err) =>
        showToast({ show: true, type: 'error', message: t('Message.tag.error.load_all') }),
      )
      .finally(() => {
        setIsLoadingTag(false);
        setIsLoadingList(false);
      });
  }, [list, tag, user.access_token]);

  // effects
  useEffect(() => {
    runFilter();
  }, [lastVideoFilter, companyFilter, dateFilter, runFilter]);

  useEffect(() => {
    // if ((id || filterParam) && tag.length === 0 && list.length === 0) {
    //   navigate(Routes.contacts);
    // }
    setQuery('');
    tagListFilter();
    setIsLoadingTag(false);
    setIsLoadingList(false);
  }, [tag, list, id, filterParam, tagListFilter, navigate]);

  return (
    <div className="flex w-full justify-between">
      {/* Left fields */}
      <div className="flex space-x-2">
        {selectData.map((select, index) => (
          <SelectFilter
            key={index}
            options={select.options}
            placeholder={select.placeholder}
            onChange={select.onChange}
            value={select.value}
            isMulti={select.isMulti}
            isLoading={select.isLoading}
          />
        ))}
      </div>
      {/* Right options */}
      <div className="flex space-x-4 items-center">
        <input
          type="text"
          placeholder={t('Placeholder.search')}
          onChange={handleSearchQuery}
          value={query}
          autoComplete="off"
          className="w-full h-full mx-5 rounded-xl placeholder:text-gray-300 text-xs font-semibold border-2 border-gray-200 focus:outline-none hover:border-gray-400 duration-100"
        />
        <InfoBox
          message={t('Contact.create_new_contact')}
          size={35}
          positionMsg="bottom"
          icon={
            <AiOutlinePlus
              size={35}
              className="text-gray-600 rounded-full hover:text-primary hover:shadow active:scale-[0.95]"
            />
          }
          onClick={handleNewContact}
        />
        <InfoBox
          message={t('Contact.upload_new_contact')}
          size={35}
          positionMsg="top"
          icon={
            <TbUpload
              size={36}
              className="text-gray-600 rounded-full hover:text-primary hover:shadow p-1 active:scale-[0.95]"
            />
          }
          onClick={handleImportContact}
        />
      </div>
    </div>
  );
};
export default ContactsFilters;
