import { classNames } from '../../config/utils';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const optionsExample = [
  {
    label: 'Option 1',
    value: 'option-1',
    onClick: () => console.log('Option 1 clicked'),
    style: {
      color: 'red'
      // etc...
    },
    disabled: false,
    className: 'bg-white',
    // ...
  }
]

const DropdownMenu = ({ trigger, options }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if(!trigger) return;
    const handleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const dataTrigger = trigger.getBoundingClientRect();
      setPosition({ 
        x: dataTrigger.x, 
        y: dataTrigger.y + dataTrigger.height 
      });
      setIsVisible(true);
    };

    const handleOutsideClick = (event) => {
      if (event.target !== trigger) {
        setIsVisible(false);
      }
    };

    const handleGlobalScroll = () => {
      setIsVisible(false);
    }

    trigger.addEventListener('click', handleClick);
    document.addEventListener('click', handleOutsideClick);

    return () => {
      trigger.removeEventListener('click', handleClick);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [trigger]);
  
  return isVisible
    ? ReactDOM.createPortal(
        <ul 
          className='bg-white py-3 mt-1 rounded-md shadow-md select-none'
          style={{ position: 'absolute', top: position.y, left: position.x }}>
          {options.map((option, index) => (
            <li 
              key={`item-d-${index}`} 
              className={classNames('px-3 hover:bg-gray-100 cursor-pointer', option.className)}
              {...option}
            >
              {option.label}
            </li>
          ))}
        </ul>,
        document.body
      )
    : null;
};

export default DropdownMenu;