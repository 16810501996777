import { StepsList } from './StepsList';
import { CreateVideo } from './videoSteps/CreateVideo';
import { SelectLanding } from './videoSteps/SelectLanding';
import { EditLanding } from './videoSteps/EditLanding';
import { ContactsStep } from './videoSteps/ContactsStep';
import { SendStep } from './videoSteps/SendStep';
import { ReuseVideoDialog } from './ReuseVideoDialog';
import { Spinner } from '../../components/common';
import { ProgressModal } from './ProgressModal';
import { useVideo } from './context/VideoContext';
import VideoStepsProvider from './videoSteps/context/VIdeoStepsContext';

const VideoMain = () => {
  const { stepTitles, videoStep, isLoadingDataReuse } = useVideo();

  return (
    <>
      <VideoStepsProvider>
        <h1 className={`font-bold tracking-tight text-black text-2xl 2xl:text-3xl`}>
          {stepTitles[videoStep]}
        </h1>
        <div className="mt-4">
          <StepsList />
        </div>

        {videoStep === 0 && <CreateVideo />}
        {videoStep === 1 && <SelectLanding />}
        {videoStep === 2 && <EditLanding />}
        {videoStep === 3 && <ContactsStep />}
        {videoStep === 4 && <SendStep />}

        <ReuseVideoDialog />

        <Spinner
          open={isLoadingDataReuse}
          title="Cargando..."
          message="Se está cargando la información a reutilizar"
        />
      </VideoStepsProvider>
      
      <ProgressModal />
    </>
  );
};
export default VideoMain;
