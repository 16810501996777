import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import { Breadcumbs } from '../../components/library';
import Toggle from '../../components/common/Toggle';
import { DialogV2, Spin, Spinner, Table } from '../../components/common';
import {
  deleteUserCompany,
  getCompanies,
  getUserOrCompanyById,
  getVideoByUsers,
  postAddUser,
  postUpdateUser,
  restoreUserCompany,
} from '../../services/admin/adminService';
import useAuthContext from '../../hooks/useAuthContext';
import { useToast2 } from '../../hooks/useToast2';
import { generateImageUrl } from '../../config/utils';
import { NewUserSchema } from '../../config/formsValidators';
import Routes, { HOME } from '../../routes/app/paths';
import GeneralConfirModal from '../../components/common/modal/GeneralConfirModal';
import { CustomSingleValue } from '../../components/common/SelectComponents';

const bodyExample = [
  [
    { value: 'Empresa 1', type: 'text' },
    { value: 'empresa1@text.com', type: 'text' },
    { value: '123456789', type: 'text' },
    { value: 'www.empresa1.com', type: 'text' },
    { value: 'www.empresa1.com', type: 'text' },
    { value: 'Ver', type: 'button' },
  ],
];

const styles = {
  control: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: 'white',
    padding: '.3rem',
    border: '2px  solid rgba(0, 0, 0, .3)',
    borderRadius: '8px',
    zIndex: 1,
    '&:disabled': {
      opacity: 0.5,
    },
  }),
  menu: () => ({
    marginTop: -5,
    padding: 0,
    borderRadius: '0 0 8px 8px',
    position: 'absolute',
    width: '100%',
    zIndex: 2,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    display: state.isSelected && 'none',
    backgroundColor: 'rgb(245,245,245)',
    cursor: 'pointer',
    fontSize: '17px', // Tamaño base del texto
    '@media (min-width: 1536px)': {
      fontSize: '16px', // Tamaño para 2xl
    },
    '&:hover': {
      backgroundColor: '#ccc',
    },
  }),
};

export const UserProfile = () => {
  // states
  const [videos, setVideos] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [query, setQuery] = useState('');
  const [bodyTable, setBodyTable] = useState(bodyExample);
  const [selectedItem, setSelectedItem] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [order, setOrder] = useState({ column: 'name', type: 'desc' });
  const [modalOpen, setModalOpen] = useState(false);
  const [showPreview, setShowPreview] = useState({
    show: false,
    path: null,
  });

  // hooks
  const { t } = useTranslation();
  const { id, companyOwner } = useParams();
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  // const
  const managerValid = user?.role === 'Manager' && user?.company?.name === companyOwner;
  const headersTable = [
    { title: t('AdminZone.user.videos_table.name'), dropdown: true },
    { title: t('AdminZone.user.videos_table.crate_at'), dropdown: true },
    { title: t('AdminZone.user.videos_table.visualizations'), dropdown: true },
    { title: t('AdminZone.user.videos_table.clicks'), dropdown: true },
    { title: t('AdminZone.user.videos_table.downloads'), dropdown: true },
    { title: 'Info.', dropdown: false },
  ];

  const rolesExample = useMemo(() => {
    const roles = [
      { value: 0, label: 'Agente' },
      { value: 1, label: 'Manager' },
    ];
    if (!managerValid) { // its admin
      roles.push({ value: 2, label: 'Admin' });
    }
    return roles;
  }, [managerValid]);


  //Effects
  useEffect(() => {
    createBodyTable(filteredList);
  }, [filteredList]);

  useEffect(() => {
    createBodyTable(videos);
  }, [videos]);

  useEffect(() => {
    !id && !watch().status && setValue('status', 1);
    setValue('role', rolesExample[0].label)
  }, []);

  useEffect(() => {
    if(!!id && dataUser?.data && companies.length > 0){
      const idComp = companies.find((c) => c.value === dataUser?.data?.company?.id)?.value;
      const nameComp = companies.find((c) => c.value === dataUser?.data?.company?.id)?.label;
      setValue('company_id', idComp || companies[0].value);
      setValue('company', nameComp || companies[0].label);
    }
  }, [companies]);
  
  useEffect(() => {
    if (user?.role === 'Admin') return;
    if (user?.role === 'Manager' && user?.company?.name === companyOwner) return;
    navigate(HOME);
    showToast({
      show: true,
      type: 'error', 
      message: t('AdminZone.error')
    });
  }, [user, navigate]);

  //Querys
  const { data: dataUser, isLoading: loadingUser, error: errorUser } = useQuery(['USER', id],
    () => id ? getUserOrCompanyById({ token: user.access_token, search: 'user', id }) : null, {
    onError: (err) => {
      console.log(err);
      if (user?.role === 'Manager') return navigate(Routes.companyProfile + '/' + user?.company.id)
    },
    enabled: !!id
  });

  useEffect(() => {
    const resp = dataUser;
    setValue('id', resp?.data?.id)
    setValue('company_id', !managerValid ? resp?.data?.company.id : user?.company?.id)
    setValue('company', !managerValid ? resp?.data?.company.name : user?.company?.name)
    setValue('name', resp?.data?.name)
    setValue('email', resp?.data?.email)
    setValue('surname', resp?.data?.last_name)
    setValue('phone', resp?.data?.phone)
    setValue('second_surname', resp?.data?.second_last_name)
    setValue('department', resp?.data?.department)
    setValue('status', resp?.data?.status)
    setValue('role', resp?.data?.role)
  }, [dataUser?.data?.id, managerValid, user]);

  const {
    data,
    isLoading: loadingVideosUser,
    error: errorVideosUser,
  } = useQuery(['VIDEOS_USER'], () => id ? getVideoByUsers({ token: user.access_token, userId: id }) : null, {
    onSuccess: (resp) => {
      setVideos(resp.data.data);
    },
    onError: (err) => {
      console.log(err);
    },
    enabled: !!id
  });

  const {
    mutate: mutateAddUser,
    isLoading: loadingAddUser,
    error: errorAddUser,
  } = useMutation((opt) => postAddUser(opt), {
    onSuccess: () => {
      queryClient.invalidateQueries(['USERS']);
      showToast({ show: true, type: 'success', message: t('Message.user.success.add') });
      navigate(-1);
    },
    onError: (err) => {
      console.log(err);
      queryClient.invalidateQueries(['USERS']);
      showToast({ show: true, type: 'error', message: t('Message.user.error.add') });
    },
  });

  const {
    mutate: mutateUpdateUser,
    isLoading: loadingUpdateUser,
    error: errorUpdateUser,
  } = useMutation((opt) => postUpdateUser(opt), {
    onSuccess: () => {
      queryClient.invalidateQueries(['USERS']);
      showToast({ show: true, type: 'success', message: t('Message.user.success.update') });
    },
    onError: (err) => {
      console.log(err);
      queryClient.invalidateQueries(['USERS']);
      showToast({ show: true, type: 'error', message: t('Message.user.error.update') });
    },
  });

  const {
    mutate: mutateDeleteUsers,
    isLoading: loadingDeleteUser,
    error: errorDeleteUser,
  } = useMutation((opt) => {
    if (opt.restore) return restoreUserCompany(opt);
    return deleteUserCompany(opt)
  }, {
    onSuccess: async (_, opt) => {
      await queryClient.invalidateQueries(['USERS']);
      handleSearchQuery(query);
      setSelectedItem([]);
      setModalOpen(false);
      navigate(Routes.usersAdmin);
      showToast({ show: true, type: 'success', message: opt.restore
        ? t('Message.user.success.restore')
        : t('Message.user.success.delete')
     });
    },
    onError: (err, opt) => {
      console.log(err);
      queryClient.invalidateQueries(['USERS']);
      setModalOpen(false);
      showToast({ show: true, type: 'error', message: opt.restore
        ? t('Message.user.error.restore')
        : t('Message.user.error.delete') });
    },
  });

  const {
    data: dataCompanies,
    isLoading: loadingCompanies,
    error: errorCompanies,
    isFetching: isFetchingCompanies,
  } = useQuery(['COMPANIES'], () => getCompanies({ token: user.access_token }), {
    onSuccess: (resp) => {
      setCompanies(resp?.data?.map((c) => ({ value: c.id, label: c.name })) || []);
      companyOwner && setValue('company', companyOwner);
      companyOwner &&
        setValue('company_id', resp.data.filter((c) => c.name === companyOwner)[0]?.id);
    },
    onError: (err) => {
      console.log(err);
    },
    enabled: !managerValid // only if admin
  });

  // handlers
  const handleSearchQuery = (query) => {
    setQuery(query);
    let search = videos;

    query !== '' &&
      (search = search.filter(
        (v) =>
          v.title?.toLowerCase().includes(query.toLowerCase()) ||
          v.count_played == query.toLowerCase() ||
          v.created_at.split('T')[0]?.toLowerCase().includes(query.toLowerCase()) ||
          v.email_clicks + v.phone_clicks + v.url_clicks + v.whatsapp_clicks ==
          query.toLowerCase() ||
          v.count_downloads == query.toLowerCase(),
      ));

    setFilteredList(search);
  };

  const handleShowVideo = (route) => {
    setShowPreview({ show: true, path: route });
  };

  const handleConfirmModal = (confirm) => {
    confirm
      ? mutateDeleteUsers({ token: user.access_token, user_ids: [watch().id], restore: isArchived })
      : setModalOpen(false);
  };

  const handleToggle = (toggle) => {
    setValue('status', toggle ? 1 : 0);
  };

  const removeItems = () => {
    setModalOpen(true);
  };
  
  const handleBreadcumbClick = (pathId, companyId) => {
    pathId === 0 && navigate(Routes.usersAdmin);
    pathId === 1 && !companyId && navigate(Routes.usersAdmin);
    pathId === 1 && companyId && navigate(`${Routes.companyProfile}/${companyId}`);
  };

  const onSubmit = (data) => {
    Object.assign(data, { company_id: watch().company_id });
    const isValid = Object.keys(errors).length === 0;
    id
      ? isValid && mutateUpdateUser({ token: user.access_token, data, userId: watch().id })
      : isValid && mutateAddUser({ token: user.access_token, data });
  };

  // funtions
  //Creando el cuerpo de la tabla
  const createBodyTable = (data) => {
    setBodyTable([]);
    let tdContact,
      tdCreationDate,
      tdVisualizations,
      tdClicks,
      tdDownloads,
      tdInfo = {};
    let array = [];
    data?.map((item) => {
      const row = [];
      tdContact = { type: 'text', value: item.title, name: 'title', id: item.id };
      tdCreationDate = { type: 'text', value: item.created_at.split('T')[0], name: 'creationDate' };
      tdVisualizations = { type: 'text', value: item.count_played, name: 'visualizations' };
      tdClicks = {
        type: 'text',
        value: item.email_clicks + item.phone_clicks + item.url_clicks + item.whatsapp_clicks,
        name: 'clicks',
      };
      tdDownloads = { type: 'text', value: item.count_downloads, name: 'downloads' };
      tdInfo = {
        type: 'button',
        value: t('AdminZone.user.videos_table.select'),
        route: item.video_path,
        landing: item.token,
      };
      row.push(tdContact, tdCreationDate, tdVisualizations, tdClicks, tdDownloads, tdInfo);
      array.push(row);
    });
    setBodyTable(array);
  };

  const orderTable = (column) => {
    let orderType = 'asc';
    if (order.column === column && order.type === 'asc') {
      orderType = 'desc';
    }

    setOrder({
      column: column,
      type: orderType,
    });

    const orderedList = [...bodyTable];
    orderedList.sort((a, b) => {
      let x = null;
      let y = null;
      if (column === t('AdminZone.user.videos_table.contact')) {
        x = a[0].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[0].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (column === t('AdminZone.user.videos_table.crate_at')) {
        x = a[1].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[1].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (column === t('AdminZone.user.videos_table.visualizations')) {
        x = a[2].value !== null ? a[1].value : 'zzz';
        y = b[2].value !== null ? b[1].value : 'zzz';
      } else if (column === t('AdminZone.user.videos_table.clicks')) {
        x = a[2].value !== null ? a[2].value : 'zzz';
        y = b[2].value !== null ? b[2].value : 'zzz';
      } else if (column === t('AdminZone.user.videos_table.downloads')) {
        x = a[3].value !== null ? a[3].value : 'zzz';
        y = b[3].value !== null ? b[3].value : 'zzz';
      }
      if (x < y) {
        return orderType === 'asc' ? -1 : 1;
      }
      if (x > y) {
        return orderType === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setBodyTable(orderedList);
  };

  const watchAllFields = watch();

  const companySelected = watchAllFields.company ? { value: watchAllFields.company_id, label: watchAllFields.company } : null
  const isSelf = user?.id === watchAllFields.id;
  
  const isArchived = dataUser?.data?.deleted_at;
  
  return (
    <>
      <Breadcumbs
        category={'admin'}
        path={[
          { id: 0, name: t('AdminZone.title') },
          watch().company ? { id: 1, name: watch().company, companyId: watch().company_id } : { id: 1, name: t('User.meta') },
          { id: 2, name: t('UserDetail.meta') },
        ]}
        onClick={handleBreadcumbClick}
      />
      <div className="flex space-x-1 2xl:space-x-2 mt-3 mb-8 justify-between items-center">
        <h1 className="text-md 2xl:text-2xl font-bold text-gray-800">{t('UserDetail.meta')}</h1>
        {watch()?.status !== undefined && (
          <Toggle
            onToggle={handleToggle}
            register={register}
            registerValue="active"
            Active={watch()?.status == 1 ? true : false}
          />
        )}
      </div>
      <div>
        <form className="grid grid-cols-2 gap-y-8 gap-x-24 p-2" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              {...register('name')}
              className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              type="text"
              placeholder={t('Placeholder.name')}
            />
            {errors.name && (
              <p className="text-red-500" role="alert">
                {t(errors.name.message)}
              </p>
            )}
          </div>
          <div>
            <input
              {...register('email')}
              className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:opacity-50"
              type="text"
              placeholder={t('Placeholder.email')}
              disabled={!!id}
            />
            {errors.email && (
              <p className="text-red-500" role="alert">
                {t(errors.email.message)}
              </p>
            )}
          </div>
          <div>
            <input
              {...register('surname')}
              className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              type="text"
              placeholder={t('Placeholder.surname')}
            />
            {errors.surname && (
              <p className="text-red-500" role="alert">
                {t(errors.surname.message)}
              </p>
            )}
          </div>
          <div>
            <input
              {...register('phone')}
              className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              type="text"
              placeholder={t('Placeholder.phone')}
            />
            {errors.phone && (
              <p className="text-red-500" role="alert">
                {t(errors.phone.message)}
              </p>
            )}
          </div>
          <div>
            <input
              {...register('second_surname')}
              className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              type="text"
              placeholder={t('Placeholder.second_surname')}
            />
            {errors.second_surname && (
              <p className="text-red-500" role="alert">
                {t(errors.second_surname.message)}
              </p>
            )}
          </div>
          <div>
            <input
              {...register('department')}
              className="focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              type="text"
              placeholder={t('Placeholder.department')}
            />
            {errors.department && (
              <p className="text-red-500" role="alert">
                {t(errors.department.message)}
              </p>
            )}
          </div>
          <div>
            <Select
              components={{ SingleValue: CustomSingleValue }}
              options={companies}
              value={companySelected}
              placeholder={t('Placeholder.company')}
              styles={styles}
              onChange={(newValue) => {
                setValue('company_id', newValue.value);
                setValue('company', newValue.label);
                trigger();
              }}
              isLoading={loadingCompanies || isFetchingCompanies}
              isDisabled={user?.role === 'Manager' && user?.company?.name === companyOwner}
            />
            {errors.company && (
              <p className="text-red-500" role="alert">
                {t(errors.company.message)}
              </p>
            )}
          </div>
          <div>
            <Select
              components={{ SingleValue: CustomSingleValue }}
              options={rolesExample}
              value={
                watchAllFields.role
                  ? {
                    value: rolesExample.findIndex((r) => r.label === watchAllFields.role),
                    label: watchAllFields.role,
                  }
                  : null
              }
              placeholder={t('Placeholder.role')}
              styles={styles}
              onChange={(newValue) => {
                setValue('role', newValue.label);
                trigger()
              }}
            />
            {errors.role && (
              <p className="text-red-500" role="alert">
                {t(errors.role.message)}
              </p>
            )}
            </div>
          <div className="flex col-span-2">
            {id && (
              <button
                type="button"
                className="flex items-center justify-center gap-2 h-10 2xl:h-10 px-5 mt-3 rounded-xl bg-black text-white font-semibold hover:shadow-lg active:scale-[0.98] disabled:bg-gray-500 disabled:cursor-default disabled:shadow-none disabled:scale-100"
                onClick={removeItems}
                disabled={loadingDeleteUser || loadingUpdateUser || loadingAddUser || isSelf}
              >
                {loadingDeleteUser || loadingUpdateUser || loadingAddUser && (
                  <Spin /> 
                )}
                {!isArchived 
                ? t('AdminZone.user.delete')
                : t('AdminZone.user.restore')
                }
              </button>
            )}
            <button
              type="submit"
              className="flex items-center justify-center gap-2 h-10 2xl:h-10 px-5 mt-3 ms-2 rounded-xl bg-primary text-white font-semibold hover:shadow-lg active:scale-[0.98] disabled:bg-gray-500 disabled:cursor-default"
              disabled={loadingDeleteUser || loadingUpdateUser || loadingAddUser}
            >
              {loadingDeleteUser || loadingUpdateUser || loadingAddUser && (
                <Spin /> 
              )}
              {t('AdminZone.user.save')}
            </button>
          </div>
        </form>
      </div>
      <hr className="border-1 mt-10 mb-14" />
      {id && videos.length > 0 && (
        <>
          <div className="flex p-1 space-x-5 mt-5 items-center justify-between">
            <div className="flex space-x-1 2xl:space-x-2">
              <h1 className="text-md 2xl:text-md whitespace-nowrap font-bold text-gray-800">
                {t('AdminZone.user.videos_meta')}
              </h1>
            </div>
            <div className="flex items-center space-x-2 2xl:space-x-4 justify-end w-full">
              <input
                type="text"
                placeholder={t('Placeholder.search')}
                value={query}
                className="border-gray-300 text-sm 2xl:text-md rounded-lg focus:border-gray-300 focus:ring-1 focus:ring-gray-300 w-32 2xl:w-56 placeholder:text-gray-300"
                onChange={(e) => handleSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="flow-root">
              <div className="-ml-7">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 2xl:pr-0">
                  <div className={`relative ${videos.length > 10 && 'h-80'}`}>
                    <Table
                      header={headersTable}
                      body={bodyTable}
                      selectedItems={selectedItem}
                      clients={videos}
                      onSelect={setSelectedItem}
                      onOrder={orderTable}
                      onShow={handleShowVideo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <button
        type="button"
        className="flex items-center justify-center h-10 2xl:h-12 px-5 mt-3 mb-2 rounded-xl border-2 border-gray-400 text-gray-400 font-semibold hover:shadow-lg active:scale-[0.98]"
        onClick={() => navigate(-1)}
      >
        {t('AdminZone.user.back')}
      </button>
      <DialogV2 open={showPreview.show} onClose={() => setShowPreview({ show: false, path: null })}>
        {showPreview.path ? (
          <div className="relative w-full h-full mt-1">
            <video
              className="w-full h-full"
              controls
              autoPlay
              src={generateImageUrl(showPreview.path)}
              type="video/mp4"
            />
          </div>
        ) : (
          <div className="relative w-full h-full">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-2xl font-bold text-gray-500">No se puede reproducir</p>
              <p className="text-sm font-light text-gray-500">No se encuentra el video</p>
            </span>
          </div>
        )}
      </DialogV2>
      <GeneralConfirModal
        isOpen={modalOpen}
        label={t('AdminZone.user.label')}
        action={isArchived ? 'restore' : 'delete'}
        onConfirm={handleConfirmModal}
        loading={loadingDeleteUser}
      />

      {/* Loader */}
      <Spinner open={loadingUser} title={t('Library.loading')} />
    </>
  );
};
