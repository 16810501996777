import { selectFrontPage } from '../../../features/videos/videosSlice';
import { useEffect, useRef, useState } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Logo from '../../../assets/img/logo-light.png';
import { classNames, generateImageUrl, urlParsed } from '../../../config/utils';
import { MenuLinks } from './elements/MenuLinks';
import { PlayerTemplate } from './elements/PlayerTemplate';
import { useTranslation } from 'react-i18next';
import SocialLinksTemplate from './elements/SocialLinksTemplate';
import { motion } from 'framer-motion'

const ThirdTemplateMini = () => {
  const mainRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const container = mainRef.current.parentElement;
    const mainWidth = mainRef.current.offsetWidth;
    const newWidth = container.offsetWidth;

    /* escalar mainRef segun el width del contenedor */
    const onResize = () => {
      const scale = newWidth / mainWidth;
      mainRef.current.style.transform = `scale(${scale})`;
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div
      ref={mainRef}
      className="w-[346px] h-[262px] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end p-3"
    >
      <div className="relative flex justify-center items-center w-full h-full bg-gray-600 z-10 rounded-md p-6 space-x-5">
        {/* info */}
        <div className="flex flex-col justify-center h-full space-y-2">
          <div className="flex w-full flex-grow justify-between">
            <span className="uppercase text-[9px] text-gray-400 font-bold">logo</span>
            <span className="uppercase text-[9px] text-gray-400 font-bold">logo</span>
          </div>
          <div className="w-6 h-6 border-2 border-white rounded-full flex justify-center items-center">
            <FaPlay className="text-white ml-0.5" size={11} />
          </div>
          <span className="uppercase font-bold text-[9px] text-gray-200">¡Text!</span>
          <div className="w-full flex flex-col">
            <span className="w-20 h-2 pt-[6px] mt-[4px] bg-gray-200" />
            <span className="w-16 h-2 pt-[6px] mt-[2px] bg-gray-200" />
          </div>
          <div className="w-full flex justify-evenly space-x-1">
            <div className="w-full h-3 bg-primary rounded-sm" />
            <div className="w-4/5 h-3 border-2 border-gray-500 rounded-sm" />
          </div>
        </div>
        {/* video */}
        <div className="flex w-[100%] justify-center items-center bg-gray-600 h-4/6 rounded-md"></div>
      </div>
    </div>
  );
};

const ThirdTemplate = ({
  video,
  color = '7482F6',
  config,
  className = '',
  isSmall = false,
  selected = null,
}) => {
  const mainRef = useRef(null);
  const [menuShow, setMenuShow] = useState(false);
  const [dinamicTitle, setDinamicTitle] = useState(null);
  const [imageNotFound, setImageNotFound] = useState(false)
  const portrait = useSelector(selectFrontPage);
  const { t } = useTranslation();
  const [imagesNotFound, setImagesNotFound] = useState({
    firstLogo: false,
    secondLogo: false,
    mainImage: false,
  });
  
  /* functions */
  const handleAddImgNotFound = (img) => {
    setImagesNotFound((prev) => ({ ...prev, [img]: true }));
  };

  /* effects */
  useEffect(() => {
    // check changes in images
    setImagesNotFound({
      firstLogo: false,
      secondLogo: false,
      mainImage: false,
    });
    if (config?.images?.firstLogo) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.firstLogo);
      img.onerror = () => handleAddImgNotFound('firstLogo');
    }
    if (config?.images?.secondLogo) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.secondLogo);
      img.onerror = () => handleAddImgNotFound('secondLogo');
    }
    if (config?.images?.mainImage) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.mainImage);
      img.onerror = () => handleAddImgNotFound('mainImage');
    }
  }, [config?.images]);

  selected &&
    useEffect(() => {
      if (config?.texts?.title) {
        let title = config?.texts.title.split('*');
        const temp = title.map((e) => {
          if (e === '|NOMBRE|') {
            return selected.name;
          } else return e;
        });
        const temp2 = temp.join('');
        setDinamicTitle(temp2);
      } else {
        setDinamicTitle('');
      }
    }, [selected, config?.texts]);

  useEffect(() => {
    if (!imageNotFound && portrait?.file) setImagesNotFound(false)
  }, [portrait])

  /* functions */
  const urlParsed = (url) => {
    return /^https?:\/\//.test(url) ? url : `http://${url}`;
  };

  return (
    <div
      ref={mainRef}
      className={classNames(
        className,
        isSmall && '2xl:w-[430px] 2xl:h-[320px]',
        'w-[430px] h-[320px] 2xl:w-[800px] 2xl:h-[600px] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end transform origin-top-left p-3 shadow-lg',
      )}
    >
      <div className="relative flex justify-center items-center w-full h-full bg-gray-600 z-10 rounded-md overflow-hidden">
        {/* info side*/}
        <div className="absolute left-0 w-2/5 flex flex-col justify-center h-full space-y-2 p-7 2xl:p-14 pb-12 2xl:pb-20 z-20">
          {/* Logo */}
          <div className="flex flex-grow w-full items-start">
            <div className="flex w-full justify-between items-center">
              {config?.images?.firstLogo && !imagesNotFound.firstLogo && (
                <img

                  src={generateImageUrl(config?.images?.firstLogo)}

                  alt="logo"
                  className="w-8 2xl:w-10 rounded-lg"
                />
              )}
              {config?.images?.secondLogo && !imagesNotFound.secondLogo && (
                <img

                  src={generateImageUrl(config?.images?.secondLogo)}

                  alt="logo"
                  className="w-8 2xl:w-10 rounded-lg"
                />
              )}
            </div>
          </div>

          {/* Play */}
          <div className="w-6 h-6 border-2 border-white rounded-full flex justify-center items-center cursor-pointer group hover:border-gray-300">
            <FaPlay className="text-white group-hover:text-gray-300" size={11} />
          </div>

          {/* Texts */}
          <div>
            <span
              className={
                (isSmall && '2xl:text-[16px]',
                  'w-full font-bold text-[16px] 2xl:text-[24px] text-white')
              }
            >
              {config?.texts?.title || <span className="text-gray-400 italic">{t('Landing.hello')}</span>}
            </span>
            <div
              className={
                (isSmall && '2xl:text-[10px]', 'w-full flex text-[10px] 2xl:text-[16px] text-white')
              }
            >
              {config?.texts?.message}
            </div>
          </div>

          {/* Buttons */}
          <div className="w-full flex justify-between text-[7px] 2xl:text-[12px]">
            <div
              className={classNames(
                isSmall && '2xl:p-0.5',
                `rounded-sm  w-6/12 bg-primary px-2 2xl:px-3 p-0.5 2xl:p-1 flex items-center space-x-2 cursor-pointer hover:shadow-lg`,
                !config?.pdf_links?.pdf?.dowloadButton?.text && 'hidden'
              )}
              style={{ backgroundColor: `${config?.pdf_links?.pdf?.dowloadButton?.buttonColor}` }}
            >
              <span
                className="font-semibold select-none whitespace-pre-wrap text-center"
                style={{ color: `${config?.pdf_links?.pdf?.dowloadButton?.textColor}` }}
              >
                {config?.pdf_links?.pdf?.dowloadButton?.text}
              </span>
            </div>
            {config?.pdf_links?.links?.link.length > 1 ? (
              <div
                className={`relative w-5/12 border-[1px] rounded-sm p-1 px-2 2xl:px-3 flex items-center space-x-1 cursor-pointer hover:shadow-lg`}
                style={{ borderColor: `${config?.pdf_links.links.colorButtonsLinks}` }}
                onClick={() => setMenuShow(!menuShow)}
              >
                <span
                  className={`text-primary text-md font-semibold select-none whitespace-pre-wrap text-center`}
                  style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                >
                  {t('Landing.links')}
                </span>
                {/* <IoIosArrowForward size={10} className={`text-primary`} style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }} /> */}

                {menuShow && <MenuLinks links={config?.pdf_links.links} />}
              </div>
            ) : (
              <div
                className={`border-[1px] w-5/12 rounded-sm p-1 px-2 2xl:px-3 truncate flex items-center space-x-2 cursor-pointer hover:shadow-lg`}
                style={{
                  borderColor: `${config?.pdf_links.links.colorButtonsLinks}`,
                  color: `${config?.pdf_links.links.colorButtonsLinks}`,
                }}
              >
                <a
                  href={urlParsed(config?.pdf_links?.links?.link[0]?.urlLink)}
                  target={'_blank'}
                  className={` text-md font-semibold select-none whitespace-nowrap truncate`}
                  style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                >
                  {config?.pdf_links?.links?.link[0]?.textLink}
                </a>
              </div>
            )}
          </div>
        </div>

        {/* contact side */}
        <div className="absolute right-0 w-2/5 flex flex-col justify-start items-end h-full space-y-2 p-7 2xl:p-14 z-20">
          <div className="rounded-md p-1 z-20 bg-gray-900/20">
            {/* Contacts */}
            <div
              className={classNames(
                isSmall && '2xl:text-[8px]',
                'w-full flex flex-col space-y-1 justify-center text-[8px] 2xl:text-[12px] p-1 text-white font-light',
              )}
            >
              <SocialLinksTemplate contacts={config?.contacts} />
            </div>
          </div>
        </div>

        <div className="absolute inset-y-0 left-0 w-1/2 z-10 bg-gradient-to-l from-transparent to-gray-900 opacity-80" />

        {/* video */}
        <div className="flex w-[100%] absolute inset-0 opacity-50 justify-center items-center bg-gray-900 rounded-md z-0">
        </div>

        {/* portrait */}
        <div className="z-0 overflow-hidden absolute inset-0 opacity-80">
        {portrait?.file && !imageNotFound && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute inset-0 rounded-lg group-hover:scale-110 duration-500"
            >
              <img 
                src={generateImageUrl(portrait.file)}  
                className="w-full h-full object-cover rounded-lg" 
                onError={() => setImageNotFound(true)}
              />
            </motion.div>
          )}
        </div>
        
        {/* footer */}
        <div
          className={classNames(
            isSmall && '2xl:text-[4px]',
            'w-full flex items-center justify-center space-x-5 text-[4px] 2xl:text-[8px] absolute bottom-0 py-1 z-20 bg-gray-900/20',
          )}
        >
          <img src={Logo} alt="" className={classNames(isSmall && '2xl:h-4', 'h-4 2xl:h-7')} />
          <span className="text-white ">
            {t('Landing.copyright')}
          </span>
          <div className="flex space-x-2">
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.privacy')}
            </a>
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.conditions')}
            </a>
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.cookies')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ThirdTemplateFull = ({
  video,
  texConfig,
  portrait,
  color = '7482F6',
  config,
  className = '',
}) => {
  const mainRef = useRef(null);
  const [menuShow, setMenuShow] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [hide, setHide] = useState(false);
  const { t } = useTranslation();

  let hideTimeout;

  useEffect(() => {
    hideButtonAfterTimeout();

    return () => {
      clearTimeout(hideTimeout);
    };
  }, []);

  const hideButtonAfterTimeout = () => {
    hideTimeout = setTimeout(() => {
      setHide(true);
    }, 3000);
  };

  const handleMouseMove = () => {
    setHide(false);
    clearTimeout(hideTimeout);
    hideButtonAfterTimeout();
  };

  const handleMouseLeave = () => {
    hideButtonAfterTimeout();
  };

  return (
    <div
      ref={mainRef}
      className={classNames(
        className,
        'h-full aspect-[430/320] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end transform origin-top-left p-3',
      )}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className="relative flex justify-center items-center w-full h-full bg-gray-600 z-10 rounded-md overflow-hidden">
        {/* info side*/}
        <div className="absolute left-0 w-2/5 flex flex-col justify-center h-full space-y-5 p-10 pb-20 z-20">
          {/* Logo */}
          <div
            className={classNames(
              'flex flex-grow w-full items-start transition-opacity duration-300',
              hide && playing ? 'opacity-0' : 'opacity-100',
            )}
          >
            <div className="flex w-full justify-between items-center">
              {config?.images?.firstLogo && (
                <img
                  src={generateImageUrl(config?.images?.firstLogo)}
                  alt="logo"
                  className="w-12 rounded-lg"
                />
              )}
              {config?.images?.secondLogo && (
                <img
                  src={generateImageUrl(config?.images?.secondLogo)}
                  alt="logo"
                  className="w-12 rounded-lg"
                />
              )}
            </div>
          </div>

          {/* Play */}
          <div
            className={classNames(
              'w-14 aspect-square border-4 border-white rounded-full flex justify-center items-center cursor-pointer group hover:border-gray-300 transition-opacity duration-300',
              hide && playing ? 'opacity-0' : 'opacity-100',
            )}
            onClick={() => setPlaying(!playing)}
          >
            {playing ? (
              <FaPause className="text-white group-hover:text-gray-300" size={25} />
            ) : (
              <FaPlay className="text-white group-hover:text-gray-300 pl-0.5" size={25} />
            )}
          </div>

          {/* Texts */}
          <div
            className={classNames(
              'transition-opacity duration-300',
              hide && playing ? 'opacity-0' : 'opacity-100',
            )}
          >
            <span className={classNames('w-full font-bold text-xl text-white')}>
              {config?.texts?.title || <span className="text-gray-400 italic">{t('Landing.hello')}</span>}
            </span>
            <div className={'w-full flex text-md text-white'}>{config?.texts?.message}</div>
          </div>

          {/* Buttons */}
          <div
            className={classNames(
              'w-full flex justify-between text-sm transition-opacity duration-300',
              hide && playing ? 'opacity-0' : 'opacity-100',
            )}
          >
            {config?.pdf_links?.pdf?.fileName && <a
              className={classNames(
                `rounded-sm w-6/12 bg-primary px-2 2xl:px-3 p-0.5 2xl:p-1 flex items-center justify-center space-x-2 cursor-pointer hover:shadow-lg`,
              )}
              style={{ backgroundColor: `${config?.pdf_links?.pdf?.dowloadButton?.buttonColor}` }}
              href={generateImageUrl(config?.pdf_links?.pdf?.fileName)}
              target="_blank"
              download
            >
              <span
                className="font-semibold select-none whitespace-nowrap"
                style={{ color: `${config?.pdf_links?.pdf?.dowloadButton?.textColor}` }}
              >
                {config?.pdf_links?.pdf?.dowloadButton?.text}
              </span>
            </a>}
            {config?.pdf_links?.links?.link.length > 1 ? (
              <div
                className={`relative w-5/12 border-[1px] rounded-sm p-1 px-2 2xl:px-3 flex justify-center items-center space-x-1 cursor-pointer hover:shadow-lg`}
                style={{ borderColor: `${config?.pdf_links.links.colorButtonsLinks}` }}
                onClick={() => setMenuShow(!menuShow)}
              >
                <span
                  className={`text-primary text-center text-md font-semibold select-none truncate`}
                  style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                >
                  {t('Landing.links')}
                </span>
                {/* <IoIosArrowForward size={10} className={`text-primary`} style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }} /> */}

                {menuShow && <MenuLinks links={config?.pdf_links.links} />}
              </div>
            ) : (
              <div
                className={`border-[1px] w-5/12 rounded-sm p-1 px-2 2xl:px-3 truncate flex justify-center items-center space-x-2 cursor-pointer hover:shadow-lg`}
                style={{
                  borderColor: `${config?.pdf_links.links.colorButtonsLinks}`,
                  color: `${config?.pdf_links.links.colorButtonsLinks}`,
                }}
              >
                <a
                  href={urlParsed(config?.pdf_links?.links?.link[0]?.urlLink)}
                  target={'_blank'}
                  className={` text-md font-semibold select-none whitespace-nowrap truncate`}
                  style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                >
                  {config?.pdf_links?.links?.link[0]?.textLink}
                </a>
              </div>
            )}
          </div>
        </div>

        {/* contact side */}
        <div
          className={classNames(
            'absolute right-0 w-2/5 flex flex-col justify-start items-end h-full space-y-2 p-10 z-20 transition-opacity duration-300',
            hide && playing ? 'opacity-0' : 'opacity-100',
          )}
        >
          <div className="rounded-md p-1 z-20 bg-gray-900/20">
            {/* Contacts */}
            <div
              className={classNames(
                'w-full flex flex-col space-y-2 justify-center text-xs p-1 text-white font-light opacity-70',
              )}
            >
              <SocialLinksTemplate contacts={config?.contacts} />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            'absolute inset-y-0 -left-10 w-1/2 z-10 bg-gradient-to-l from-transparent to-gray-900 transition-opacity duration-300',
            hide && playing ? 'opacity-0' : 'opacity-80',
          )}
        />

        {/* video */}
        <div
          className={classNames(
            'flex w-[100%] absolute inset-0 justify-center items-center bg-gray-900 rounded-md z-0 transition-opacity duration-300',
            hide && playing ? 'opacity-100' : 'opacity-50',
          )}
        >
          <PlayerTemplate
            video={video}
            texConfig={texConfig}
            portrait={generateImageUrl(portrait)}
            noControls
            playing={playing}
            setPlaying={setPlaying}
          />
        </div>

        {/* footer */}
        <div
          className={classNames(
            'w-full flex items-center justify-center space-x-5 text-[10px] absolute bottom-0 py-2 z-20 bg-gray-900/20 transition-opacity duration-300',
            hide && playing ? 'opacity-0' : 'opacity-100',
          )}
        >
          <img src={Logo} alt="" className={classNames('h-6')} />
          <span className="text-white ">
            {t('Landing.copyright')}
          </span>
          <div className="flex space-x-2">
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.privacy')}
            </a>
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.conditions')}
            </a>
            <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.cookies')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ThirdTemplateMini, ThirdTemplate, ThirdTemplateFull };
