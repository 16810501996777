// este componente servirá para mostrar un modal con una lista de todos lo procesos de subida de archivos. obtendrá la información del global state. pa luego...

import { CheckCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../config/utils";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaSync } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { cancelUpload, removeFromUploadingFiles, uploadingFiles } from '../../../features/videos/videosSlice';
import HeightIn from "../Animation/HeightIn";

const examplesFile = [
  {
    id: 1, // uniqueId()
    title: 'example_video_con un nombre largo.mp4',
    preview: 'https://www.w3schools.com/tags/movie.mp4',
    type: 'video',
    progress: 20, // progress of upload
    total: 100,
  }
]

const ItemLoader = (item) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeItem = () => {
    dispatch(cancelUpload(item.id));
  }

  const isLoading = item.progress !== 100;
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-4 min-w-0 my-2 rounded-lg">
        {item.type && item.type === 'video' ? (
          <video
            src={item.preview}
            className="block w-16 h-10 object-cover object-center shadow-lg"
            // eslint-disable-next-line react/no-unknown-property
            onLoad={() => {
              URL.revokeObjectURL(item.preview);
            }}
          />  
        ) : (
          <img
            src={item.preview}
            className="block w-16 h-10 object-cover object-center shadow-lg"
            onLoad={() => {
              URL.revokeObjectURL(item.preview);
            }}
          />
        )}

        <div className="shrink truncate select-none"
          style={{
            maxWidth: 110
          }}
        >
          <p
            className={classNames(
              'text-xs 2xl:text-sm truncate',
              isLoading ? 'text-gray-400' : 'text-gray-800',
            )}
          >
            {item.title}
          </p>
          <p
            className={classNames(
              'text-[10px] 2xl:text-[12px]',
              isLoading ? 'text-gray-300' : 'text-gray-400',
            )}
          >
            {!isLoading
              ? parseFloat(item.total / Math.pow(1000, 2)).toFixed(1) + ' MB'
              : t('Library.loading')}
          </p>
        </div>
      </div>

      <div className="w-16 h-16 flex justify-end items-center gap-2">
        {item.progress === 100 ? (
          <>
            <CheckCircleIcon className="w-10 h-10 text-green-progress cursor-pointer" />
          </>
        ) : (
          <div className="w-8 h-8">
            <CircularProgressbar
              value={item.progress}
              maxValue={100}
              styles={buildStyles({
                pathColor: '#5cdc55',
              })}
            />
          </div>
        )}
      </div>
        
      <div className="w-6 h-6 ml-2">
        <TrashIcon className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer" onClick={removeItem}/>
      </div>
    </div>
  );
};

const GlobalUploader = () => {
  const dispatch = useDispatch();
  const items = useSelector(uploadingFiles);
  
  const { t } = useTranslation();

  const uploadedCount = items.filter((item) => item.progress === 100).length;

  useEffect(() => {
    if (uploadedCount) {
      const ids = items.filter((item) => item.progress === 100).map((item) => item.id);
      const timer = setTimeout(() => {
        ids.forEach((id) => {
          dispatch(removeFromUploadingFiles(id));
        });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [items, uploadedCount]);
  
  return (
    <div className={classNames("w-80 rounded-xl z-30 bg-white overflow-hidden shadow-md duration-200", items.length === 0 ? 'h-0' : 'h-auto px-5 p-2 border-[1px] border-gray-200 ')}
    >
      <div className="w-full flex space-x-4 items-center my-1">
        <div className="w-7 h-7 rounded-full bg-blue-200 flex justify-center items-center">
          <FaSync className="w-4 h-4 text-blue-300 animate-spin" />
        </div>
        <span className="text-gray-600 font-semibold text-xs 2xl:text-sm">
          {t('Library.uploading')} {items.length > 1 ? 'archivos' : 'archivo'}
        </span>
      </div>
      <div className="overflow-y-scroll p-0 m-0"
        style={{
          maxHeight: 208
        }}
      >
        {items.map((item, index) => (
          <HeightIn key={index}>
            <ItemLoader {...item} />
          </HeightIn>
        ))}
      </div>
    </div>
  )
}
export default GlobalUploader