import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../../config/utils";
import { Tooltip } from "react-tooltip";

const TableWithoutErrors = ({
  clients,
  headers,
  selectedHeaders,
  setSelectedHeaders,
  selectedPeople,
  setSelectedPeople,
  replaceClients,
  checkIsCorrect,
}) => {
  return (
    <table className="w-full border-separate border-spacing-y-3 border-spacing-x-[1px] ">
      <thead>
        <tr>
          {headers.labels.map((header, index) => (
            <th
              key={`header-${index}`}
              scope="col"
              className="bg-black-soft py-1 pr-3 text-left text-sm font-semibold px-2 last:rounded-tr-xl last:rounded-br-xl first:rounded-tl-xl first:rounded-bl-xl"
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-1">
                  {/* <PencilSquareIcon className="w-5 h-5 text-white cursor-pointer" />   //ocultado por desuso */}
                  <p className="text-white font-light text-lg">
                    {' '}
                    {header.name.charAt(0).toUpperCase() + header.name.slice(1)}
                  </p>
                </div>
                <div className="py-2.5 flex justify-between items-center">
                  <input
                    type="checkbox"
                    className="rounded h-5 w-5 focus:ring-0 focus:outline-1 ring-1 ring-black !text-primary focus:text-primary"
                    checked={selectedHeaders.includes(header)}
                    onChange={(e) => {
                      setSelectedHeaders(
                        e.target.checked
                          ? [...selectedHeaders, header]
                          : selectedHeaders.filter((p) => p !== header),
                      );

                      checkIsCorrect();
                    }}
                  />
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {clients.map((item, index) => (
          <tr
            key={`tr-${index}`}
            className={classNames(
              selectedPeople.includes(item) ? 'bg-gray-50' : undefined,
              'bg-gray-100 relative px-6 sm:w-12 sm:px-rounded-tl-xl rounded-bl-xl',
            )}
          >
            { headers.labels.map((header, i) => {
              
              const value = item[header.real_name];
              return (
                  <td
                    key={`item-${header.real_name}-${index}`}
                    className={classNames(
                      item.error && item.error[header.real_name]
                        ? `${item.error[header.real_name].level === 'bg-red-error' && !(replaceClients && item.error[header.real_name].description === 'Ya existe un usuario con este correo')
                          ? 'bg-red-error text-white'
                          : 'bg-yellow-error text-gray-soft'
                        }  `
                        : 'text-gray-text',
                      'whitespace-nowrap py-3 px-4 text-sm  text-center font-medium border-r border-gray-border first:rounded-tl-xl first:rounded-bl-xl last:rounded-br-xl last:rounded-tr-xl',
                    )}
                  >
                    <div className="flex justify-between items-center">
                      {value || ' -'}

                      {item.error && item.error[header.real_name] && (
                        <div>
                          <a data-tooltip-id={item[header.real_name] || `empty-${index}-${i}`} data-tooltip-place="top">
                            <InformationCircleIcon
                              className={classNames(
                                item.error && item.error[header.real_name]
                                  ? `${item.error[header.real_name].level === 'bg-red-error'
                                    ? ' text-white'
                                    : 'text-gray-soft'
                                  } `
                                  : '',
                                'w-7 h-7 text-gray-text-darker cursor-pointer',
                              )}
                            />
                          </a>
                          <Tooltip
                            className="z-50 !opacity-80 !rounded-lg !-top-0 !mr-10"
                            id={item[header.real_name] || `empty-${index}-${i}`}
                            place="top"
                            content={item.error[header.real_name].description}
                            noArrow
                            openOnClick
                          />
                        </div>
                      )}
                    </div>
                  </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableWithoutErrors;