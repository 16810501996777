import { useTranslation } from 'react-i18next';
import LinkedVideo from './LinkedVideo';

export default function ListLinkedVideo({ items }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        {items
          .filter((item) => item.id)
          .map((item, i) => (
          <LinkedVideo key={item.id} video={item} index={items.length - i} />
        ))}
        {items.length === 0 && (
          <div className="col-span-2 text-center">
            <p className="text-gray-500 italic">{t('contacts.noLinkedVideo')}</p>
          </div>
        )}
      </div>
    </>
  );
}
