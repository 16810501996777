import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles/ImageOverlay.css';

const ImageOverlayMenu = ({ isOpen, width, setWidth, onDelete, onChanged }) => {

  const { t } = useTranslation();

  const handleWidthChange = (event) => {
    setWidth(event.target.value);
    onChanged(true);
  };

  return (
    <div
      className="image_overlay-menu"
      style={{ display: isOpen ? 'block' : 'none', zIndex: 99999 }}
    >
      <ul className="image_overlay-menu-list">
        <li className="font-size-option">
          <label htmlFor="fontSize">{t('Edition.size')}</label>
          <input
            type="number"
            id="fontSize"
            min={5}
            max={200}
            value={width}
            checked={width < 200 && width > 1}
            onChange={handleWidthChange}
            autoComplete="off"
            autoSave="off"
            autoFocus={false}
          ></input>
        </li>

        <li onClick={onDelete}>
          <span>{t('Edition.ToolBar.delete')}</span>
        </li>
      </ul>
    </div>
  );
};

export default function ImageOverlay({
  parentRef,
  index,
  data,
  onDelete,
  onSave,
  className = '',
  editable = true,
  onInEdition,
}) {
  const classname = `image_overlay ${className}`;

  const draggableRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  // Para saber si hubo algun cambio
  const [changed, setChanged] = useState(false);

  // Para abrir y cerrar el menu de opciones
  const [isOpen, setIsOpen] = useState(false);

  // Para mostrar los controles u ocultarlos
  const [isFocus, setIsFocus] = useState(false);

  // Referencia a la etiqueta img
  const imageRef = useRef(null);

  // Datos que se pueden editar de la imagen
  const [width, setWidth] = useState(100);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Para copiar los props
  useEffect(() => {
    if (data) {
      // Esto es para que me permita editar
      const widthCopy = JSON.parse(JSON.stringify(data.width || 100));
      const positionXcopy = JSON.parse(JSON.stringify(data.position?.x || 0));
      const positionYcopy = JSON.parse(JSON.stringify(data.position?.y || 0));

      setWidth(widthCopy);
      setPosition({ x: positionXcopy, y: positionYcopy });
    }
  }, [data]);

  // Para guadar los cambios realizados sobre la imagen
  const handleOnSave = useCallback(() => {
    if (changed) {
      const newData = {
        imgUrl: data.imgUrl,
        position,
        width,
      };

      onSave(index, newData);

      setIsFocus(false);
      setIsOpen(false);
      setChanged(false);
    }
  }, [changed, index, onDelete, onSave, position, width]);

  /**
   * Para ajustar según la posición y el tamaño
   * que se le quiera dar a la imagen para que no se salga del contenedor
   */
  const setMaxSize = useCallback(() => {
    if (imageRef && imageRef.current) {
      const containerWidth = parentRef.current.offsetWidth;
      const containerHeight = parentRef.current.offsetHeight;

      const image = imageRef.current;
      image.style.maxWidth = `${containerWidth - position.x}px`;
      image.style.maxHeight = `${containerHeight - position.y}px`;
    }
  }, [parentRef, position.x, position.y]);

  // Para el drag and drop
  useEffect(() => {
    if (parentRef) {
      const containerRect = parentRef.current.getBoundingClientRect();
      const draggableRect = draggableRef.current.getBoundingClientRect();

      const containerWidth = containerRect.width;
      const containerHeight = containerRect.height;

      const draggableWidth = draggableRect.width;
      const draggableHeight = draggableRect.height;

      const handleMouseMove = (e) => {
        if (!isDragging) return;

        const offsetX = e.clientX - dragStart.x;
        const offsetY = e.clientY - dragStart.y;

        let newX = position.x + offsetX;
        let newY = position.y + offsetY;

        // Verificar límites del contenedor
        if (newX < 0) newX = 0;
        if (newY < 0) newY = 0;
        if (newX + draggableWidth > containerWidth) newX = containerWidth - draggableWidth;
        if (newY + draggableHeight > containerHeight) newY = containerHeight - draggableHeight;

        setPosition({ x: newX, y: newY });
        setDragStart({ x: e.clientX, y: e.clientY });

        // Ajustar tamaño máximo de acuerdo a posicón
        setMaxSize();
        setChanged(true);
      };

      const handleMouseUp = () => {
        setIsDragging(false);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isDragging, dragStart, position, parentRef, setMaxSize]);

  // Para ocultar el menu y quitar el foco del textarea cuando se da un click fuera de este
  useEffect(() => {
    if (parentRef) {
      const showOptions = (event) => {
        if (draggableRef.current && !draggableRef.current.contains(event.target)) {
          setIsOpen(false);
          setIsFocus(false);
        }
      };

      parentRef.current.addEventListener('click', showOptions, true);
    }
  }, [parentRef]);

  // Para cuando se entre a mover
  const handleMouseDown = (e) => {
    setDragStart({ x: e.clientX, y: e.clientY });
    setIsDragging(true);
  };

  // Para cuando se de un click en la imagen mostrar los controles
  const handleOnFocus = () => {
    if (editable) {
      setIsFocus(true);
    }
  };

  // para mandar que ya no esta enfocado
  useEffect(() => {
    if (onInEdition) onInEdition(isFocus)
  }, [isFocus])

  // Para mostrar o cerrar menu
  const toggleMenu = () => {
    if (imageRef && imageRef.current) {
      setIsOpen(!isOpen);
      imageRef.current.focus();
    }
  };

  return (
    <div
      ref={draggableRef}
      className={classname}
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
      }}
    >
      <div
        className="image_overlay-move"
        onMouseDown={handleMouseDown}
        style={{ display: isFocus ? 'flex' : 'none' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          viewBox="0 -960 960 960"
          width="18"
          fill="#faebd7"
        >
          <path d="M480-80 317-243l44-44 89 89v-252H198l84 84-44 44L80-480l159-159 44 44-85 85h252v-252l-84 84-44-44 158-158 158 158-44 44-84-84v252h252l-84-84 44-44 158 158-158 158-44-44 84-84H510v252l89-89 44 44L480-80Z" />
        </svg>
      </div>

      <div className="image_overlay-options" style={{ display: isFocus ? 'flex' : 'none' }}>
        <button className="image_overlay-options-button" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            viewBox="0 -960 960 960"
            width="18"
            fill="#faebd7"
          >
            <path d="M479.858-160Q460-160 446-174.142q-14-14.141-14-34Q432-228 446.142-242q14.141-14 34-14Q500-256 514-241.858q14 14.141 14 34Q528-188 513.858-174q-14.141 14-34 14Zm0-272Q460-432 446-446.142q-14-14.141-14-34Q432-500 446.142-514q14.141-14 34-14Q500-528 514-513.858q14 14.141 14 34Q528-460 513.858-446q-14.141 14-34 14Zm0-272Q460-704 446-718.142q-14-14.141-14-34Q432-772 446.142-786q14.141-14 34-14Q500-800 514-785.858q14 14.141 14 34Q528-732 513.858-718q-14.141 14-34 14Z" />
          </svg>
        </button>

        <button className="image_overlay-save-button" onClick={() => handleOnSave(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            viewBox="0 -960 960 960"
            width="18"
            fill="#faebd7"
          >
            <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
          </svg>
        </button>

        {/* Este es un menú desplegable */}
        <ImageOverlayMenu
          isOpen={isOpen}
          width={width}
          setWidth={setWidth}
          onDelete={() => onDelete(index)}
          onChanged={setChanged}
        />
      </div>

      <img
        ref={imageRef}
        src={data.imgUrl}
        alt="Imagen en edición"
        onClick={handleOnFocus}
        width={width}
      />
    </div>
  );
}
