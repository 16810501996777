import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineFileUnknown } from 'react-icons/ai';
import { FaPhotoVideo, FaRegUserCircle } from 'react-icons/fa';
import { HiBriefcase } from 'react-icons/hi2';
import { IoIosPricetags } from 'react-icons/io';
import { IoVideocam } from 'react-icons/io5';
import {
  MdChecklist,
  MdFolder,
  MdOutlineContactMail,
  MdOutlineVideoChat,
  MdVideoLibrary,
} from 'react-icons/md';
import Routes from '../../../../routes/app/paths';
import useAuthContext from '../../../../hooks/useAuthContext';

// all categories
export const searchCategory = [
  'videos',
  'clips',
  'recordings',
  'portrait',
  'files',
  'clients',
  'lists',
  'tags',
];

// routes by category
const routesByCategory = {
  videos: Routes.library.substring(0, Routes.library.length - 1), // remove last slash
  clips: Routes.corporativeVideos,
  corporative: Routes.corporativeVideos,
  recordings: Routes.videoRecords,
  record: Routes.videoRecords,
  portrait: Routes.portraits,
  frontPage: Routes.portraits,
  // files: Routes.files,
  lists: Routes.manageList,
  tags: Routes.manageList,
  // direct routes
  clients: Routes.contactProfile,
  companies: Routes.companyProfile,
  users: Routes.userProfile,
};

// group categories
const categoriesBelongToLibrary = ['videos', 'clips', 'recordings', 'portrait'];
const folderCategory = ['files'];
const categoriesManagement = ['lists', 'tags'];
// the rest of categories are not grouped, so they will be used only for query
const categoriesDirectRoute = ['clients', 'companies', 'users'];

export const searchCategoryProtectedAdmin = ['companies', 'users'];
export const searchCategoryProtectedManager = ['users'];

const useSearch = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const isAdmin = user?.role === 'Admin';
  const isManager = user?.role === 'Manager';
  const isAgente = user?.role === 'Agente';

  const categories = {
    lists: {
      label: t('Search.categories.lists'),
      icon: <MdChecklist size={20} />,
    },
    tags: {
      label: t('Search.categories.tags'),
      icon: <IoIosPricetags size={20} />,
    },
    videos: {
      label: t('Search.categories.videos'),
      icon: <MdOutlineVideoChat size={20} />,
    },
    clients: {
      label: t('Search.categories.clients'),
      icon: <MdOutlineContactMail size={20} />,
    },
    clips: {
      label: t('Search.categories.clips'),
      icon: <MdVideoLibrary size={20} />,
    },
    recordings: {
      label: t('Search.categories.recordings'),
      icon: <IoVideocam size={20} />,
    },
    portrait: {
      label: t('Search.categories.portrait'),
      icon: <FaPhotoVideo size={20} />,
    },
    companies: {
      label: t('Search.categories.companies'),
      icon: <HiBriefcase size={20} />,
    },
    users: {
      label: t('Search.categories.users'),
      icon: <FaRegUserCircle size={20} />,
    },
    files: {
      label: t('Search.categories.files'),
      icon: <MdFolder size={20} />,
    },
    unknown: {
      label: '',
      icon: <AiOutlineFileUnknown size={20} />,
    },
  };

  const handleOnClick = (item, query) => {
    if (!item) return;
    const category = item.category;


    const route = category !== 'files' && routesByCategory[category];

    const paramsByGroupCategory = {
      belongToLibrary: {
        search: query,
        id: item.id,
        folder: item.folder_id,
      },
      folder: {
        // search: query,
        folder: item.id,
        // type: item.type, // means library: videos, clips, recordings, front-page (portrait), nut will be used in the route, not in the query
      },
      manageList: {
        search: query,
        id: item.id,
        [category.substring(0, category.length - 1)]: item.name || item.title || query, // remove last s (list, tag)
      },
      onlyQuery: {
        search: item.name || item.title || query,
      },
    };

    const params = categoriesBelongToLibrary.includes(category)
      ? paramsByGroupCategory.belongToLibrary
      : folderCategory.includes(category)
        ? paramsByGroupCategory.folder
        : categoriesManagement.includes(category)
          ? paramsByGroupCategory.manageList
          : paramsByGroupCategory.onlyQuery;
    
    const directRoutes = {
      clients: `${Routes.contactProfile}/${item.id}`,
      companies: `${Routes.companyProfile}/${item.id}`,
      users: isManager ?  `${Routes.userProfile}/${user?.company?.name}/${item.id}` : `${Routes.userProfile}/${item.id}`,
    }

    const folderRoute = item.type === 'video' ? Routes.library.substring(0, Routes.library.length -1) : routesByCategory[item.type];

    const url = categoriesDirectRoute.includes(category) 
      ? directRoutes[category]
      : route 
        ? `${route}?${new URLSearchParams(params).toString()}` 
        : `${folderRoute}?${new URLSearchParams(params).toString()}`; // its a folder, so use type to get the route
      
    navigate(url);
  };

  return {
    categories,
    handleOnClick,
  };
};

export default useSearch;
