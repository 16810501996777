import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { save, saveTexts, selectEditorConfig } from '../../../features/videos/videosSlice';

import './styles/VideoEditor.css';

import TimeLine from './TimeLine';
import BottomControls from './BottomControls';

import FakeVideo from '../utils/FakeVideo';
import ElementOverlayContainer from './others/ElementOverlayContainer';
import InsideControls from './InsideControls';
import VideoPlayer from './VideoPlayer';
import { generateImageUrl } from '../../../config/utils';
import { useTranslation } from 'react-i18next';

export default function VideoEditor(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const presentConfig = useSelector(selectEditorConfig);

  // Lista de todos los videos con algunos atributos añadidos necesarios para la edición
  const [videos, setVideos] = useState([]);

  // Los textos superpuestos sobre imagenes y videos
  const [overlayTexts, setOverlayTexts] = useState([]);

  // El indice del video que se está reproduciendo actualmente
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);

  // Referencia al video que se esta reproduciendo actualmente
  const videoRef = useRef(null);

  // Para poder hacer referencia a la imagen en caso de que se reproduzca una imagen
  const imageRef = useRef(null);

  // Esto es para poder pausar el timer del video anterior en caso de ser un FakeVideo
  const previousVideoRef = useRef(null);

  // El inicio y el final del video actual, que son variables si se corta el video de cualquier forma(por delante, detras, el medio)
  const [startAt, setStartAt] = useState(0);
  const [endAt, setEndAt] = useState(1);

  // Se toma este estado para poder pasarlo al los siguientes videos de la lista
  const [playerData, setPlayerData] = useState({
    paused: true,
    volume: 1,
    muted: false,
  });

  const [loadingProgress, setLoadingProgress] = useState(0); // para poder saber el progreso de carga del video en la parte donde se ha seleccionado
  
  /**
   * El tiempo actual de reproducción del video que se está reproduciendo actualmente
   * Que puede ir desde startAt a endAt
   */
  const [currentTime, setCurrentTime] = useState(0); // Para actualizar puntero de la linea de tiempo

  /**
   * Este es el tiempo total actual de reproducción de todos
   * los videos como si fueran uno sólo en formato de minutos y segundos.
   * Esto es para mostrar el tiempo en el reproductor y en la parte de Cortar en 00:00
   */
  const [currentTotalTime, setCurrentTotalTime] = useState(0);
  const [formatedCurrentTotalTime, setFormatedCurrentTotalTime] = useState({
    minutes: '00',
    seconds: '00',
  });

  // Esta es una referencia del contenedor donde esta el video para poder poner el contenedor de los overlay texts
  const parentRef = useRef(null);

  // Para mostrar un grid sobre el reproductor como guía
  const [guideGrid, setGuideGrid] = useState(false);

  /**
   * Esto es para calcular la sumatoria de la duración de todos
   * los videos anteriores a currentVideoIndex cuando se esta mostrando currentTotalTime
   */
  const summationTime = useMemo(() => {
    return videos.slice(0, currentVideoIndex).reduce((total, video) => total + video.duration, 0);
  }, [videos, currentVideoIndex]);

  // Para cuando se esté cargando del video
  const [, setLoadingVideo] = useState(false);

  const handleVideoEnd = useCallback(() => {
    // Actualiza el índice del video actual para que se reproduzca el siguiente video
    setCurrentVideoIndex((prevIndex) => prevIndex + 1);

    // Reinicia la reproducción del primer video cuando se llega al final del arreglo de videos
    if (currentVideoIndex === videos.length - 1) {
      setCurrentVideoIndex(0);
      videoRef.current.currentTime = 0;
    }
  }, [currentVideoIndex, videos.length]);

  const handleTimeUpdate = useCallback(() => {
    if (videoRef && videoRef.current.currentTime >= endAt) {
      // Para que se reinicie el current time al iniciar un nuevo video en el startAt
      setCurrentTime(0);

      videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      handleVideoEnd();
    } else {
      // Esta condición es para solo ejecutar en caso de que haya cambiado en segundo
      // ya que el evento timeupdate se ejecuta cuatro veces por segundo
      const currTime = Math.floor(videoRef.current.currentTime);
      if (currTime !== previousTime.current) {
        previousTime.current = currTime;

        if (currTime % 1 === 0) {
          previousTime.current = summationTime + (currTime - startAt);

          setCurrentTotalTime(summationTime + (currTime - startAt));

          setCurrentTime(videoRef.current.currentTime);

          /**
           * El currentTotalTime es la sumatoria de la duración de todos los videos anteriores
           * mas lo transcurrido del video actual
           */
          const { formatedMin, formatedSec } = secondsToFormatTime(
            summationTime + (currTime - startAt),
          );

          setFormatedCurrentTotalTime({
            minutes: formatedMin,
            seconds: formatedSec,
          });
        }
      }
    }
  }, [endAt, handleVideoEnd, startAt, summationTime]);

  // Se usa para no cambiar el valor de el tiempo total transcurrido a cada milisegundo sino cada un segundo
  const previousTime = useRef(0);

  useEffect(() => {
    if (props.videos) {
      const videosCopy = JSON.parse(JSON.stringify(props.videos || []));
      setVideos(videosCopy);
    }
  }, [props.videos]);

  // Para recuperar los textos superpuestos sobre imagenes y videos
  useEffect(() => {
    if (props.overlayTexts) {
      const textCopy = JSON.parse(JSON.stringify(props.overlayTexts));
      setOverlayTexts(textCopy);
    }
  }, [props.overlayTexts]);

  // Para recuperar la configuración del reproductor
  useEffect(() => {
    if (presentConfig) {
      const configCopy = Object.assign({}, presentConfig);
      // Esto es para tratar el bug que daba cuando el currentVideoIndex se iba del tamaño de los videos
      if (configCopy.currentVideoIndex >= 0 && configCopy.currentVideoIndex <= videos.length - 1) {
        setCurrentVideoIndex(configCopy.currentVideoIndex);
      } else {
        // Es mejor que vuelva a cero a que dé error
        setCurrentVideoIndex(0);
      }
    }
  }, [presentConfig, videos.length]);

  /**
   * Maneja todo lo relacionado con el saltar de un video real a un FakeVideo, asignar los eventos necesarios para actualizar el tiempo
   * Y más aspectos relacionados con pasar de un video a otro
   */
  useEffect(() => {
    // Inicia la reproducción del primer video cuando se monta el componente
    if (videos.length) {
      // Esto es para actualizar el puntero de la linea de tiempo
      setCurrentTime(0);
      setCurrentTotalTime(summationTime);

      // Obtén el video anterior
      const previousVideo = previousVideoRef.current;

      // Verifica si hay un video anterior y si es un FakeVideo
      if (previousVideo && previousVideo instanceof FakeVideo) {
        /**
         * Llama al método pause() en el video anterior solo en caso de que sea un FakeVideo
         * ya que no para setInterval si se pasa se pronto a otro video
         */
        previousVideo.pause();
      }

      const currentVideo = videos[currentVideoIndex];

      setStartAt(currentVideo?.startSecond || 0);
      setEndAt(currentVideo?.endSecond || 0);

      // Para saltar a un fake video en caso de que se vaya a "reprodicir" una imagen
      const initializeFakeVideo = () => {
        videoRef.current = new FakeVideo();
      };

      if (currentVideo && currentVideo.type === 'image') {
        initializeFakeVideo();
        if (videoRef && videoRef.current) {
          videoRef.current.duration = currentVideo.duration;
          videoRef.current.currentTime = startAt;
          // Añadir el evento en caso de que sea una imagen, si es un video se pone directo en la etiqueta
          videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }
      } else {
        // En caso contrario continúo con el flujo normal de la edición con videos reales
        if (videoRef && videoRef.current) {
          videoRef.current.currentTime = startAt;
        }
      }

      /**
       * Actualiza la referencia al video anterior con el nuevo video.
       * Tiene que estar en esta línea después que se define si es un FakeVideo o no
       */
      previousVideoRef.current = videoRef.current;

      // Para establcer el src al video actual
      if (typeof videoRef.current != FakeVideo) {
        if (videoRef && videoRef.current && currentVideo && currentVideo.src) {
          videoRef.current.src = generateImageUrl(currentVideo.src);

          // Enseñar el loading
          setLoadingVideo(true);
        }
      }

      return () => {
        if (videoRef.current) videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [
    currentVideoIndex,
    videoRef,
    videos,
    videos.length,
    startAt,
    summationTime,
    handleTimeUpdate,
  ]);

  // Esto es para pasar la configuración al siguiente video
  useEffect(() => {
    // Lo puse asi porque hay veces que lo pones en mute, pero se sigue escuchando aunque muy bajito
    if (!(currentVideoIndex && videoRef && videoRef.current && playerData)) return;
    if (playerData.muted) {
      videoRef.current.muted = true;
      videoRef.current.volume = 0;
    } else {
      videoRef.current.volume = playerData.volume;
    }

    const playVideo = () => {
      const videoElement = videoRef.current;

      if (!playerData.paused) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    };

    // const handleProgress = () => {
    //   const percent = (videoRef.current?.buffered.end(0) / videoRef.current?.duration) * 100;
    //   setLoadingProgress(percent);
    // };

    videoRef.current?.addEventListener('loadedmetadata', playVideo);
    // videoRef.current.addEventListener('progress', handleProgress);

    if (videoRef.current instanceof FakeVideo) {
      videoRef.current?.simulateLoadedMetadata();
    }

    return () => {
        videoRef.current?.removeEventListener('loadedmetadata', playVideo);
        // videoRef.current.removeEventListener('progress', handleProgress);
    }
  }, [currentVideoIndex, playerData, playerData.paused, playerData.muted, playerData.volume]);

  // Para sincronizar los cambios que ocurren en la línea de tiempo
  const syncChanges = (changedVideos, selectedIndex) => {
    const videosCopy = JSON.parse(JSON.stringify(changedVideos));
    const indexCopy = JSON.parse(JSON.stringify(selectedIndex));
    dispatch(save({ newVideos: videosCopy, config: { currentVideoIndex: indexCopy } }));
  };

  const handleSelectVideo = (selectedIndex) => {
    setCurrentVideoIndex(selectedIndex);
  };

  // Para cambiar el tiempo desde la linea de tiempo
  const handleTimeChange = (event) => {
    const value = Number(event.target.value);
    videoRef.current.currentTime = value;
    setCurrentTime(value);
  };

  // Para avanzar en un video con los botones de derecha e izquierda
  const handleTimeChangeByKeyDown = (event) => {
    event.preventDefault();
    if (event.key === 'ArrowRight') {
      if (videoRef.current.currentTime + 5 >= endAt) {
        handleVideoEnd();
      } else {
        const newValue = videoRef.current.currentTime + 5;
        videoRef.current.currentTime = newValue;
        setCurrentTime(newValue);
      }
    } else if (event.key === 'ArrowLeft') {
      if (videoRef.current.currentTime - 5 < startAt) {
        // Por si da para atrás desde el primer video
        let index = 0;
        if (currentVideoIndex - 1 >= 0) {
          index = index = currentVideoIndex - 1;
        }

        setCurrentVideoIndex(index);
        setCurrentTime(0);
      } else {
        const newValue = videoRef.current.currentTime - 5;
        videoRef.current.currentTime = newValue;
        setCurrentTime(newValue);
      }
    }
  };

  // Herramientas
  // Para dar formaro a los segundos
  const secondsToFormatTime = (seconds) => {
    const formatedMin = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const formatedSec = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0');
    return { formatedMin, formatedSec };
  };

  // Acciones de eliminar o guardar los cambios de un text overlay
  const onRemoveTextOverlay = (index) => {
    const newTexts = [...overlayTexts];
    newTexts.splice(index, 1);

    const textsCopy = JSON.parse(JSON.stringify(newTexts));

    dispatch(saveTexts(textsCopy));
  };

  const onSaveTextOverlay = (index, data) => {
    const newTexts = [...overlayTexts];
    newTexts.splice(index, 1);
    newTexts.splice(index, 0, data);

    const textsCopy = JSON.parse(JSON.stringify(newTexts));
    dispatch(saveTexts(textsCopy));
  };

  const showGuideGrid = () => {
    setGuideGrid(!guideGrid);
  };

  return (
    <div className="video_editor">
      <div className="video_editor-container z-20">
        <div ref={parentRef}>
          <VideoPlayer
            videos={videos}
            currentVideoIndex={currentVideoIndex}
            handleTimeUpdate={handleTimeUpdate}
            videoRef={videoRef}
            imageRef={imageRef}
            // loadingProgress={loadingProgress}
          />

          {/* Para superponer textos sobre los videos */}
          {videos.length > 0 && overlayTexts && (
            <ElementOverlayContainer
              className="z-10" // aca tenemos un problema de superposicion de elementos, con el InsideControls
              key="textContainer"
              videoRef={videos[currentVideoIndex]?.type === 'video' ? videoRef : imageRef}
              containerRef={parentRef}
              overlayElements={overlayTexts}
              type={videos[currentVideoIndex]?.type}
              videoIndex={currentVideoIndex} // Esto es solo para que se actualice el tamaño cuando se cambie de video
              onDelete={onRemoveTextOverlay}
              onSave={onSaveTextOverlay}
              currentTime={currentTotalTime} // Tiene que ser el total porque no necesariamente se debe poner un texto dentro de un video específico
              guideGrid={guideGrid}
            />
          )}
        </div>

        <InsideControls
          className="video_editor-inside-controls z-20" // aca se faja con ElementOverlayContainer. por prioridad pondremos este encima
          videos={videos}
          startAt={startAt}
          endAt={endAt}
          handleVideoEnd={handleVideoEnd}
          videoRef={videoRef}
          playerData={playerData}
          setPlayerData={setPlayerData}
          currentVideoIndex={currentVideoIndex}
          setCurrentVideoIndex={setCurrentVideoIndex}
          currentTotalTime={currentTotalTime}
          formatedCurrentTotalTime={formatedCurrentTotalTime}
          setCurrentTime={setCurrentTime}
          overlayTexts={overlayTexts}
          showGuideGrid={showGuideGrid}
        />
      </div>

      <div className="video_editor-outside-controls z-0">
        <div className="control_time-line">
          {!videos.length > 0 || (
            <TimeLine
              videos={videos}
              syncChanges={syncChanges}
              handleSelectItem={handleSelectVideo}
              currentTime={currentTime}
              handleTimeChange={handleTimeChange}
              handleTimeChangeByKeyDown={handleTimeChangeByKeyDown}
              currentVideoIndex={currentVideoIndex}
            />
          )}
          <button className="video_editor-outside-controls-add flex gap-2" onClick={props.handleOnAddVideo}>
            {t('VideoNew.edition.actions.add_clip')}
            <svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
              <g
                stroke="#F73757"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M1.415 9.192H16.97M9.192 1.414v15.557" />
              </g>
            </svg>
          </button>
        </div>
        <BottomControls
          videos={videos}
          setCurrentVideoIndex={setCurrentVideoIndex}
          currentVideoIndex={currentVideoIndex}
          currentTime={currentTime}
          currentTotalTime={formatedCurrentTotalTime}
          disabled={!videos.length > 0}
        />
      </div>
    </div>
  );
}

VideoEditor.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object),
  overlayTexts: PropTypes.array,
  handleOnAddVideo: PropTypes.func,
};
