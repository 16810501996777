import { useContactsStepContext } from "../../../../components/video/videoSteps/ContactsStepElements/context/ContactsStepContext";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ContactsFilters from "../../../../components/video/videoSteps/ContactsStepElements/ContactsTable/ContactsFilters";
import { Spin, Table } from "../../../../components/common";
import { classNames } from "../../../../config/utils";


const ContactsPicker = ({ id, onSendClients, isValid, isSending }) => {
  // states
  const [selectedItems, setSelectedItems] = useState([])

  // hooks
  const { clients, loadingClients, bodyTable, orderTable } = useContactsStepContext();
  const { t } = useTranslation();

  // constants
  const headersTable = useMemo(() => [
    {
      title: t('Contact.table.name'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
    },
    {
      title: t('Contact.table.last_name'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
    },
    {
      title: t('Contact.table.email'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
    },
    {
      title: t('Contact.table.sent_videos'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
    },
    {
      title: t('Contact.table.last_send'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
    },
    {
      title: t('Contact.table.info'),
      dropdown: false,
      sort: false,
      resizable: false,
      type: 'text',
    },
  ], []);

  const handleConfirm = () => {
    onSendClients(clients.filter(client => selectedItems.map(item => item.id).includes(client.id)));
  };
  
  return (
    <div className="relative flex flex-col w-full h-full space-y-3">
      {/* filter group */}
      <ContactsFilters />

      <div className='h-[20em]'>
        <Table
          header={headersTable}
          body={bodyTable}
          hasCheckable="true"
          selectedItems={selectedItems}
          clients={clients}
          onSelect={setSelectedItems}
          onOrder={orderTable}
          isLoading={loadingClients}
        />
      </div>

      {/* info */}
      <div className="flex items-center py-2 rounded-xl">
        <span className="text-gray-400 text-sm">
          {clients.length} {t('Contact.meta').toLocaleLowerCase()}
        </span>
        {selectedItems?.length > 0 && <span className="text-gray-400 text-sm">
          , {selectedItems.length} {t('Contact.selected')}
        </span>}
      </div>

      {/* Buttons */}
      <div className="flex pt-2 2xl:pt-9">
        <button
          type="button"
          className={classNames(
            'flex gap-2 items-center justify-center h-10 2xl:h-12 px-4 rounded-xl  text-white font-semibold text-sm 2xl:text-lg hover:shadow-lg active:scale-[0.98]',
            selectedItems.length && isValid && !isSending ? 'bg-primary' : 'bg-gray-400 cursor-not-allowed',
          )}
          onClick={handleConfirm}
          disabled={selectedItems.length === 0 || !isValid || isSending}
        >
          { isSending && <Spin />}
          {t('Library.send')}
        </button>
      </div>
    </div>
  );
};

export default ContactsPicker;