import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Routes from '../../../../../routes/app/paths';
import { useDispatch } from 'react-redux';
import { setRefetch } from '../../../../../features/common/commonSlice';
import { useQuery, useQueryClient } from 'react-query';
import { useToast2 } from '../../../../../hooks/useToast2';
import useAuthContext from '../../../../../hooks/useAuthContext';
import { ClientsList } from '../../../../../services/client/ClientService';

const contactsStepContext = createContext();

const ContactsStepProvider = ({ children }) => {
  // hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = useToast2();
  const { user } = useAuthContext();
  
  // get clients from cache
  const queryClient = useQueryClient();
  const clientsCache = queryClient.getQueryData(['CLIENTS', { token: user.access_token }]);
  
  // states
  const [clients, setClients] = useState(clientsCache?.data?.data || []);
  const [loadingClients, setLoadingClients] = useState(false)
  const [filteredList, setFilteredList] = useState(clients);
  const [order, setOrder] = useState({ column: 'name', type: 'desc' });

  // rq
  const {isLoading: isLoadingDataClient} = useQuery(['CLIENTS', { token: user.access_token }], ClientsList, {
    onSuccess: (res) => {
      if (res) {
        dispatch(setRefetch(false));
        setClients(res.data?.data || [])
      }
      setLoadingClients(false)
    },
    onError: (error) => {
      console.log(error)
      showToast({ show: true, type: 'error', message: t('Message.client.error.load_all') });
      setLoadingClients(false)
    },
    refetchOnWindowFocus: false,
  });
  
  // constants
  const bodyTable = useMemo(() => (
    filteredList.map((item) => {
      const tdName = { type: 'text', value: item.name, name: 'name', id: item.id };
      const tdLastName = { type: 'text', value: item.last_name, name: 'last_name' };
      const tdEmail = { type: 'text', value: item.email, name: 'email' };
      const tdSendVideos = {
        type: 'number',
        value: item.client_video.length,
        name: 'send_videos',
      };
      const tdLastVideo = {
        type: 'date',
        value:
          item.client_video.length > 0
            ? item.client_video[item.client_video.length - 1]?.updated_at.split('T')[0]
            : '',
        name: 'last_video',
      };
      const tdInfo = {
        type: 'button',
        value: t('Contact.info_button'),
        route: `${Routes.videoContactProfile}/${item.id}`,
      };
      return [tdName, tdLastName, tdEmail, tdSendVideos, tdLastVideo, tdInfo]
    })
    .sort((a, b) => {
      let x = null;
      let y = null;
      if (order.column === t('Contact.table.name')) {
        x = a[0].value !== null ? a[0].value.toLowerCase() : 'zzz';
        y = b[0].value !== null ? b[0].value.toLowerCase() : 'zzz';
      } else if (order.column === t('Contact.table.last_name')) {
        x = a[1].value !== null ? a[1].value.toLowerCase() : 'zzz';
        y = b[1].value !== null ? b[1].value.toLowerCase() : 'zzz';
      } else if (order.column === t('Contact.table.email')) {
        x = a[2].value !== null ? a[2].value.toLowerCase() : 'zzz';
        y = b[2].value !== null ? b[2].value.toLowerCase() : 'zzz';
      } else if (order.column === t('Contact.table.sent_videos')) {
        x = a[3].value;
        y = b[3].value;
      } else if (order.column === t('Contact.table.last_send')) {
        x = a[4].value !== null ? a[4].value.toLowerCase() : 'zzz';
        y = b[4].value !== null ? b[4].value.toLowerCase() : 'zzz';
      }
      if (x < y) {
        return order.type === 'asc' ? -1 : 1;
      }
      if (x > y) {
        return order.type === 'asc' ? 1 : -1;
      }
      return 0;
    })
  ), [filteredList, order]);

  // handlers
  const orderTable = useCallback((column) => {
    let orderType = 'asc';
    if (order.column === column && order.type === 'asc') {
      orderType = 'desc';
    }
    // Actualizamos el estado del orden
    setOrder({
      column: column,
      type: orderType,
    });
  }, [order]);

  // effects
  useEffect(() => {
    if (isLoadingDataClient) {
      setLoadingClients(true)
    }
  }, [isLoadingDataClient])

  const value = useMemo(() => ({
    // states
    clients,
    setClients,
    filteredList, 
    setFilteredList,
    loadingClients,
    setLoadingClients,

    // constants
    bodyTable,

    // handlers
    orderTable,
  }), [
    clients,
    setClients,
    filteredList, 
    setFilteredList,
    loadingClients,
    setLoadingClients,
    bodyTable,
    orderTable,
  ])
  
  return (
    <contactsStepContext.Provider value={value}>
      {children}
    </contactsStepContext.Provider>
  )
}

export const useContactsStepContext = () => {
  const context = useContext(contactsStepContext);  
  if (!context) {
    throw new Error('useContactsStepContext must be used within a ContactsStepProvider');
  }
  return context;
}

export default ContactsStepProvider