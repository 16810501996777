import { useState } from 'react';
import { Input } from './';
import { useTranslation } from 'react-i18next';
import '../../../config/i18next-config';
import { Spin } from '../../../components/common';
import { classNames } from '../../../config/utils';
import { Link } from 'react-router-dom';
import { PASSWORD_RECOVERY, REGISTER } from '../../../routes/app/paths';

export const LoginForm = ({loading, onSubmit, onRecovery }) => {
  const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const { t } = useTranslation();

  const handleEmailChange = (event) => {
		setEmailError(null);
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    /* if (loading) return; */

    // validate email
		const regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
		if (!regexEmail.test(email)) return setEmailError({
			message: t('Validation.not_email')
		});

    localStorage.setItem('RememberSession', JSON.stringify(remember));
		
    onSubmit({email, password, remember});
  };

  return (
    <form onSubmit={handleSubmitForm} className="w-3/5 p-4 select-none flex flex-col space-y-10">
      <div className="flex flex-col space-y-2">
        <h3 className="text-primary text-2xl font-bold">{t('Auth.Login.title')}</h3>
        {/* <div className="flex space-x-2 text-md">        // ocultado temporalmente
          <span>{t('Auth.Login.not_account')}</span>
          <Link to={REGISTER} className={classNames("text-primary font-bold", !loading ? 'hover:underline cursor-pointer' : 'opacity-50')}
          >
            {t('Auth.Login.register')}
          </Link>
        </div> */}
      </div>

      <div className="flex flex-col w-full space-y-2">
        <div className="flex flex-col space-y-8">
          <Input 
            type="email" 
            placeholder={t('Placeholder.email')} 
            value={email} 
            onChange={handleEmailChange} 
            showError={emailError} 
            disabled={loading}
          />
          <Input
            type="password"
            placeholder={t('Placeholder.password')}
            value={password}
            onChange={handlePasswordChange}
            disabled={loading}
          />
        </div>
        <div className={classNames("flex justify-between items-center", loading && 'opacity-50')}>
          <div className="flex space-x-2 items-center text-md">
            <input
              type="checkbox"
              className={classNames("w-5 h-5 rounded-md border-2 border-gray-400 focus:outline-none cursor-pointer  duration-100 checked:bg-primary checked:border-transparent focus:ring-0 focus:ring-offset-0 focus:ring-primary focus:text-primary hover:text-primary hover:border-primary", loading && 'cursor-not-allowed opacity-50')}
              checked={remember}
              onChange={(event) => setRemember(event.target.checked)}
              disabled={loading}
            />
            <span>{t('Auth.Login.remember')}</span>
          </div>
          <Link to={PASSWORD_RECOVERY} className={classNames("text-primary", !loading && 'hover:underline cursor-pointer')}
            onClick={() => { if (!loading) onRecovery() }}
          >
            {t('Auth.Login.forgot_password')}
          </Link>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          type="submit"
          className={classNames("w-full flex justify-center items-center text-white rounded-lg px-4 py-2", !loading ? 'bg-primary hover:shadow-xl hover:shadow-gray-300 active:font-semibold' : 'bg-red-300')}
          disabled={loading}
        >
          {loading && <span className='mr-2'><Spin/></span>}
          {loading ? t('Auth.Login.signing') : t('Auth.Login.signin')}
        </button>
      </div>
    </form>
  );
};
