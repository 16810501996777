import ApiRoutes from '../../routes/api/paths';
import { headers, headersFile } from '../../config/headers';


const handleResponse = async (response) => {
  if (!response.ok) {
    return Promise.reject(response.status);
  }
  const data = await response.json();
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve({
      status: response.status,
      data: data,
    });
  }
};

const sendRequest = async (url, requestOptions) => {
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      return Promise.reject({
        status: error,
      });
    });
};

// Get user or company by id
export const getUserOrCompanyById = async ({ token, search, id }) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ search, id })
  };
  return sendRequest(ApiRoutes.listUserOrCompanyById, requestOptions);
};

// Get companies
export const getCompanies = async ({ token }) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listCompanies, requestOptions);
};

// Add company
export const postAddCompany = async ({ token, avatar, name, email, phone, web }) => {
  const formData = new FormData();
  avatar && formData.append('avatar', avatar);
  formData.append('name', name || '');
  formData.append('email', email || '');
  formData.append('phone', phone || '');
  formData.append('web', web || '');

  const requestOptions = {
    method: 'POST',
    headers: headersFile(token),
    body: formData,
  };
  return sendRequest(ApiRoutes.addCpmpany, requestOptions);
};

// Update company
export const postUpdateCompany = async ({ token, companyId, avatar, name, email, phone, web }) => {
  const formData = new FormData();
  avatar && formData.append('avatar', avatar);
  formData.append('name', name || '');
  formData.append('email', email || '');
  formData.append('phone', phone || '');
  formData.append('web', web || '');

  const requestOptions = {
    method: 'POST',
    headers: headersFile(token),
    body: formData,
  };
  return sendRequest(ApiRoutes.updateCpmpany(companyId), requestOptions);
};

// Update landing company
export const postUpdateLandingDefault = async ({ token, companyId, data }) => {
  const formData = new FormData();
  formData.append('template', data.template || '');
  formData.append('title', data.title || '');
  formData.append('message', data.message || '');
  data?.pdf_file && formData.append('pdf_file', data.pdf_file);
  formData.append('pdf_text', data.pdf_text || '');
  formData.append('pdf_text_color', data.pdf_text_color || '');
  formData.append('pdf_button_color', data.pdf_button_color || '');
  formData.append('link_url1', data.link_url1 || '');
  formData.append('link_text1', data.link_text1 || '');
  formData.append('link_url2', data.link_url2 || '');
  formData.append('link_text2', data.link_text2 || '');
  formData.append('link_url3', data.link_url3 || '');
  formData.append('link_text3', data.link_text3 || '');
  formData.append('link_url4', data.link_url4 || '');
  formData.append('link_text4', data.link_text4 || '');
  formData.append('link_url5', data.link_url5 || '');
  formData.append('link_text5', data.link_text5 || '');
  formData.append('link_button_color', data.link_button_color || '');
  formData.append('whatsapp', data.whatsapp || '');
  formData.append('phone', data.phone || '');
  formData.append('email', data.email || '');
  data?.first_logo && formData.append('first_logo', data.first_logo);
  data?.second_logo && formData.append('second_logo', data.second_logo);
  data?.main_image && formData.append('main_image', data.main_image);

  const requestOptions = {
    method: 'POST',
    headers: headersFile(token),
    body: formData,
  };
  return sendRequest(ApiRoutes.updateLandingDefault(companyId), requestOptions);
};

// Delete company
export const archiveCompany = async ({ token, companyId }) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.archivedCompanies(companyId), requestOptions);
};

// Delete company
export const deleteCompany = async ({ token, companyId }) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ id: companyId })
  };
  return sendRequest(ApiRoutes.deleteCompanies, requestOptions);
};

// Restore company
export const restoreCompany = async ({ token, companyId }) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ id: companyId })
  };
  return sendRequest(ApiRoutes.restoreCompany, requestOptions);
};

// Get users companies
export const getUsersCompanies = async ({ token, companyId }) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listUsersCompanies(companyId), requestOptions);
};

// Delete user company
export const deleteUserCompany = async ({ token, user_ids }) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(token),
    body: JSON.stringify({ user_ids })
  };
  return sendRequest(ApiRoutes.deleteUsersCompanies, requestOptions);
};

// Restore user company
export const restoreUserCompany = async ({ token, user_ids }) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ user_ids })
  };
  return sendRequest(ApiRoutes.restoreUserCompany, requestOptions);
};

// Get videos by user
export const getVideoByUsers = async ({ token, userId }) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listVideosByUser(userId), requestOptions);
};

// Add user
export const postAddUser = async ({ token, data }) => {
  const { name, email, surname, second_surname, phone, role, company_id, department, active } = data;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ name, email, last_name: surname, second_last_name: second_surname, phone: phone || '', role, company_id, department, status: active === true ? 1 : 0 }),
  };
  return sendRequest(ApiRoutes.addUser, requestOptions);
};

// Update user
export const postUpdateUser = async ({ token, data, userId }) => {
  const { name, email, surname, second_surname, phone, role, company_id, department, active } = data;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ name, email, last_name: surname, second_last_name: second_surname, phone, role, company_id, department, status: active === true ? 1 : 0 }),
  };
  return sendRequest(ApiRoutes.updateUser(userId), requestOptions);
};