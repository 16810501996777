import { useState } from 'react';
import { Input } from '.';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import '../../../config/i18next-config';
import { LOGIN, PASSWORD_CHANGE } from '../../../routes/app/paths';
import { ForgotPasswordService } from '../../../services/auth/AuthService';
import { useToast2 } from '../../../hooks/useToast2';
import { Spin } from '../../../components/common';

export const RecoveryForm = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const { t } = useTranslation();
  const { showToast } = useToast2();
  const navigate = useNavigate();

  // querys
  const { mutate: mutateChangePass, isLoading: loadingChangePass } = useMutation(
    (opt) => ForgotPasswordService(opt), {
    onSuccess: (resp) => {
      showToast({ show: true, type: 'success', message: t('Message.auth.success.email_sent') });
      setTimeout(() => {
        navigate(LOGIN)
      }, 3000);
    },
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.auth.error.email_not_sent') });
    }
  });

  const handleEmailChange = (event) => {
    setEmailError(null);
    setEmail(event.target.value);
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    // validate email
    const regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!regexEmail.test(email)) return setEmailError({
      message: t('Validation.not_email')
    });
    mutateChangePass(email);
  };

  return (
    <form onSubmit={handleSubmitForm} className="w-3/5 p-4 select-none flex flex-col space-y-16">
      <div className="flex flex-col space-y-2">
        <h3 className="text-primary text-2xl font-bold">{t('Auth.PasswordRecovery.meta')}</h3>
        <span>{t('Auth.PasswordRecovery.subtitle')}</span>
      </div>

      <div className="flex flex-col w-full space-y-2">
        <div className="flex flex-col space-y-8">
          <Input type="email" placeholder={t('Placeholder.email')} value={email} onChange={handleEmailChange} showError={emailError} />
        </div>
      </div>

      <div className="flex pt-2">
        <button
          type="submit"
          className="bg-primary text-white rounded-lg px-4 py-2 hover:shadow-xl hover:shadow-gray-300 active:font-semibold"
        >
          <div className='flex gap-3'>
            {t('Auth.PasswordRecovery.meta')} {loadingChangePass && <Spin />}
          </div>
        </button>
      </div>
    </form>
  );
};
