import { useEffect, useState } from 'react';
import pusher from '../services/notification/pusher';
import useAuthContext from './useAuthContext';

/* const publicChannel = {
  channelName: 'notification',
  eventName: 'notification',
}; */

// const privateEventsName = [user.id]; // por si se necesitan mas eventos privados (lo dudo, se manejan por type en la data)

const usePusher = () => {
  const [data, setData] = useState(null);
  const { user, isAuthenticated } = useAuthContext();

  // console.log(data, new Date().toLocaleTimeString());
  
  useEffect(() => {
    if (!isAuthenticated) return;
    const privateChannel = pusher.subscribe(`vvv-${user.id}`);
    const privateEvent = privateChannel.bind(`${user.id}`, (notify) => {
      setData(notify);
    });

    return () => {
      privateEvent.unbind();
      if (isAuthenticated) pusher.unsubscribe(`vvv-${user.id}`);
    };
  }, [isAuthenticated]);

  return data;
};

export default usePusher;
