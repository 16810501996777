import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
//import ListRecords from './ListRecords';
import PlayerPreview from './PlayerPreview';
import useRecordContext from '../context/useRecordContext';
import { postAddRecording } from '../../../../services/library/LibraryService';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { useToast2 } from '../../../../hooks/useToast2';

const RecordPreview = ({ video, onClose, onRetry, handleUpload }) => {
  // context
  const { screenRecorder, cameraRecorder, config, duration } = useRecordContext();

  // hooks
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showToast } = useToast2();

  // functions
  const handleSave = () => {
    const screenUrl = screenRecorder?.mediaBlobUrl;
    const cameraUrl = cameraRecorder?.mediaBlobUrl;

    const audio = { // send audio from camera or screen, not from another source, yet...
      screen: config.audio === 'screen' ? 1 : 0,
      camera: config.audio === 'camera' ? 1 : 0,
    }

    // set global state with initial data
    const title = `record_${moment().format('DD-MM-YYYY_HH:mm:ss')}`
    
    handleUpload({ // upload using the global state, but send up to parent component to avoid error when dismounting
      title,
      preview: screenUrl || cameraUrl,
      type: 'video',
      service: postAddRecording,
      data: {
        screen: screenUrl,
        camera: config.camera === 'camera' && cameraUrl,
        audio,
        video_duration: duration,
      },
      onUploaded: () => {
        queryClient.invalidateQueries(['record']);
        showToast({
          show: true,
          type: 'success',
          message: t('Video.uploaded')
        })
      }
    })
    onClose()
  };
  
  return (
    <Transition.Root show={video} as={Fragment}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          {/* Modal panel, show/hide based on modal state. */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          >
            <div className="lg:w-[917px] lg:h-[600px] bg-white px-3 pt-3 pb-4">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold"></h3>
                <button
                  type="button"
                  className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none hover:shadow-md"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <AiOutlineClose className="h-6 w-6" />
                </button>
              </div>
              <div className="relative flex flex-col items-center px-10 space-y-6">
                <div className="w-fit flex flex-col gap-6">
                  {/* <ListRecords /> */}
                  <PlayerPreview />
                  <div className="flex w-full">
                    <button
                      type="button"
                      className="flex items-center justify-center w-28 h-12 mr-4 rounded-xl  text-gray-400 border-2 border-gray-400 font-semibold text-lg hover:shadow-lg active:scale-[0.98]"
                      onClick={onRetry}
                    >
                      {t('Video.repeat')}
                    </button>
                    <button
                      type="button"
                      className="flex items-center justify-center w-32 h-12 rounded-xl bg-primary text-white font-semibold text-lg hover:shadow-lg active:scale-[0.98]"
                      onClick={handleSave}
                    >
                      {t('Video.save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
};

export default RecordPreview;
