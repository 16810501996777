import { HiOutlinePlay, HiOutlinePause } from "react-icons/hi2";
import ProgressBar from "./ProgressBar";
import useRecordContext from "../context/useRecordContext";
import OverlayVideos from "../MediaRecorder/OverlayVideos";
import { useEffect, useRef, useState } from "react";
import { classNames } from "../../../../config/utils";

const PlayerPreview = () => {
  const { screenRecorder, cameraRecorder, setDuration } = useRecordContext();
  
  const videoScreenRef = useRef(null);
  const videoCameraRef = useRef(null);
  
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  
  const [isVideoAtStart, setIsVideoAtStart] = useState(true);
  const [screenDuration, setScreenDuration] = useState(0);
  
  // stop video when it ends
  useEffect(() => {
    const videoScreen = videoScreenRef.current;
    const videoCamera = videoCameraRef.current;

    if (!videoScreen || !videoCamera) return;
    
    // update state
    setIsVideoAtStart(videoScreenRef.current?.currentTime === 0);
    setScreenDuration(videoScreenRef.current?.duration || 0);

    const handleVideoEnd = () => {
      videoScreen.pause();
      videoScreen.currentTime = 0;
      videoCamera.pause();
      videoCamera.currentTime = 0;
      setPlaying(false);
    }

    videoScreen.addEventListener('ended', handleVideoEnd);
    videoCamera.addEventListener('ended', handleVideoEnd);

    return () => {
      videoScreen.removeEventListener('ended', handleVideoEnd);
      videoCamera.removeEventListener('ended', handleVideoEnd);
    }
  }, [videoScreenRef.current, videoCameraRef.current]);
  
  // update progress bar
  useEffect(() => {
    const videoScreen = videoScreenRef.current;
    const videoCamera = videoCameraRef.current;

    if (!videoScreen || !videoCamera) return;
    
    const handleTimeUpdate = () => {
      const progress = (videoScreen.currentTime / videoScreen.duration) * 100;
      setProgress(progress);
    }

    videoScreen.addEventListener('timeupdate', handleTimeUpdate);
    videoCamera.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoScreen.removeEventListener('timeupdate', handleTimeUpdate);
      videoCamera.removeEventListener('timeupdate', handleTimeUpdate);
    }
  }, [videoScreenRef.current, videoCameraRef.current]);

  const handlePlayPause = () => {
    if (videoScreenRef.current.paused) {
      videoScreenRef.current.play();
      videoCameraRef.current.play();
      return setPlaying(true);
    } 
    videoScreenRef.current.pause();
    videoCameraRef.current.pause();
    setPlaying(false);
  }
  
  // useEffect(() => {
  //   const videoScreen = videoScreenRef.current;
  //   const videoCamera = videoCameraRef.current;

  //   if (!videoScreen && !videoCamera) return;

  //   const durationRef = videoScreen.duration || videoCamera.duration;
  //   !isNaN(durationRef) && durationRef !== 'Infinity' && setDuration(durationRef);
  // }, [videoScreenRef.current?.duration, videoCameraRef.current?.duration]);
  
  return (
    <div className="flex relative w-fit h-[459px] justify-center items-center rounded-2xl overflow-hidden group">
      <div className={classNames("absolute w-full h-full transition bg-gradient-to-t z-10 from-black via-gray-600 to-gray-600 ", 
      playing 
        ? "opacity-0 group-hover:opacity-30"
        : "opacity-50"
      )} />
      <div className="absolute w-full h-full flex justify-center items-center z-10"
        onClick={handlePlayPause}
      >
        <HiOutlinePlay
          size={150}
          className={classNames("text-white cursor-pointer rounded-full active:scale-[0.98]", !playing ? 'opacity-60' : 'opacity-0 hidden')}
        />
        <HiOutlinePause
          size={150}
          className={classNames("text-white cursor-pointer rounded-full active:scale-[0.98] transition opacity-0 group-hover:opacity-60", !playing && 'opacity-0 hidden')}
        />
      </div>
      
      <OverlayVideos overlay={
        <video ref={videoCameraRef} src={cameraRecorder.mediaBlobUrl} className="h-full object-cover"/>
      } >
        <video ref={videoScreenRef} src={screenRecorder.mediaBlobUrl} className="h-full object-cover"/>
      </OverlayVideos>

      <div className={classNames("absolute bottom-12 w-full px-16 transition opacity-0 ", !playing ? 'opacity-100' : 'group-hover:opacity-80')}>
        <ProgressBar progress={progress} total={screenDuration} />
      </div>
    </div>
  );
};

export default PlayerPreview;