import { useEffect } from "react"
import usePusher from "./usePusher"
import { useTranslation } from "react-i18next"
import { useToast2 } from "./useToast2"
import { useDispatch, useSelector } from "react-redux"
import { addToRenderQueue, removeFromRenderQueue, renderQueue } from "../features/videos/videosSlice"
import { CheckoutRender } from "../services/video/VideoService"
import useAuthContext from "./useAuthContext"
import { useQuery, useQueryClient } from "react-query"
import { LibraryQueryKeys } from "../config/common"

export const notifyTypes = [
  "video_render",
  "toast",
  "clip_render",
  "logout",
]

const useNotification = () => {
  const { user, logout } = useAuthContext();
  const response = usePusher()
  const { showToast } = useToast2()
  const { t } = useTranslation()
  const renders = useSelector(renderQueue);
  const dispatch = useDispatch()
  const queryClient = useQueryClient();
  
  // functions
  const updateRenderQueue = (data) => {
    // get the renders into data that not exist in renderQueue
    const newRenders = data?.filter((render) => !renders?.find((r) => r.id === render.id))
    newRenders?.length && newRenders.forEach((render) => dispatch(addToRenderQueue(render)))
    
    // get missing renders that are not in data
    const missingRenders = renders?.filter((render) => !data?.find((r) => r.id === render.id))
    missingRenders?.length && missingRenders.forEach((render) => dispatch(removeFromRenderQueue(render)))    
  }
  
  // Effects
  useQuery(['RENDER_QUEUE'], () => CheckoutRender({
      token: user?.access_token,
      userId: user?.id,
    }), {
      onSuccess: (data) => {
        updateRenderQueue(data)
      },
      onError: (error) => {
        console.log(error)
      },
      staleTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    })
  
  useEffect(() => {
    if (!response) return;
    
    // toast notification type
    if (response.type === 'toast') {
      const params = response?.data?.params || {}
      const message = response?.data?.message
      
      showToast({
        show: true,
        type: response?.data?.type,
        message: message ? t(message, params) : '',
      })

      // invalidate the library cache
      const libraries = Object.keys(LibraryQueryKeys).map((key) => LibraryQueryKeys[key])
      libraries.forEach((key) => queryClient.invalidateQueries([key]))
    };

    // renders
    if (response.type === 'video_render') {
      updateRenderQueue(response.data)
    }

    // logout
    if (response.type === 'logout') {
      logout()
      showToast({
        show: true,
        type: 'error',
        message: t('Message.sessionExpired'),
      })
    }

  }, [response])
  
  return {}
}
export default useNotification