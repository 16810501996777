import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { LIMIT_IMG_SIZE, LIMIT_VIDEO_SIZE } from '../../../config/common';
import { useQueryClient } from 'react-query';

export function Dropzone({ onUpload, type = 'video' }) {
  /* State */
  const [files, setFiles] = useState([]);

  // hooks
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // rq
  const systemInfo = queryClient.getQueryData('systemInfo');

  const types = {
    video: {
      title: t('Library.video'),
      type: 'video',
      accept: { 'video/*': [] },
      clickAccept: 'video/*',
      sizeLimit: systemInfo?.maximo_clip * 1024 || LIMIT_VIDEO_SIZE,
    },
    portrait: {
      title: t('Library.frontPage'),
      type: 'image',
      accept: { 'image/*': [] },
      clickAccept: 'image/*',
      sizeLimit: LIMIT_IMG_SIZE,
    },
};

  /* Functions */
  function validator(file) {
    if (file.size > types[type].sizeLimit) {
      return {
        code: 'size-too-large',
        message: t('Message.drop.size_too_large', {size: types[type].sizeLimit / 1024 / 1024}),
      };
    }
    if (!file.type.includes(types[type].type)) {
      return {
        code: 'invalid-extension',
        message: t('Message.drop.invalid_file'),
      };
    }

    return null;
  }

  const { getRootProps, getInputProps, fileRejections, acceptedFiles, open } = useDropzone({
    accept: types[type].accept,
    noClick: true,
    noKeyboard: true,
    validator,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
        
      if (acceptedFiles.length > 0) onUpload(acceptedFiles);
    },
  });

  /* Consts */
  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: 12,
      width: '100%',
      flex: 1,
    }),
  };
  return (
    <section className="container cursor-pointer" onClick={open}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} id="dropzone-input" onChange={onUpload} accept={types[type].clickAccept} />
        <div className="col-span-full">
          <div className="w-full text-center rounded-xl border-none bg-gray-100 border-gray-300 flex flex-col items-center justify-center cursor-pointer duration-100 hover:shadow-xl active:border-primary py-24 2xl:py-32">
            {acceptedFiles.length === 0 && fileRejections.length === 0 ? (
              <>
                <IoCloudUploadOutline className="text-[50px] 2xl:text-[80px] text-gray-600 select-none" />
                <span className="text-md underline underline-offset-4 font-bold mt-3 mb-1 select-none text-gray-600">
                  {t('Message.drop.drag_select', {text: types[type].title})}
                </span>
                <span className="text-gray-400 text-sm 2xl:text-md select-none cursor-pointer">
                  {t('Message.drop.accepted_formats')} [...]
                  <br />
                </span>
              </>
            ) : acceptedFiles.length > 0 ? (
              <div className="flex flex-col justify-center items-center">
                <CheckCircleIcon className="w-16 h-16 text-green-500" />
                {acceptedFiles.length > 1 ? (
                  <h4 className="font-light">{t('Message.drop.files_uploaded', {count: acceptedFiles.length})}</h4>
                ) : (
                  <h4 className="font-light">{t('Message.drop.file_uploaded')}</h4>
                )}
              </div>
            ) : (
              fileRejections?.length > 0 && (
                <div className="flex flex-col justify-center items-center">
                  <XCircleIcon className="w-16 h-16 text-primary" />
                  <h4 className="font-light">{t('Message.drop.invalid_file')}</h4>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
