import { useEffect, useMemo, useState } from "react";
import { dataPanel, timeLimitForRecord } from "../config/configRecord";
import PanelButton from "./PanelButton";
import Logo from '../../../../assets/img/logo@3x.png';
import AuxiliarPanel from "./AuxiliarPanel";
import useRecordContext from "../context/useRecordContext";
import { useQueryClient } from "react-query";

const PanelRecordOptions = () => {
  // Context
  const { handleStartRecording , isActive,  config, setConfig } = useRecordContext();

  // hooks
  const queryClient = useQueryClient();

  // rq
  const systemInfo = queryClient.getQueryData('systemInfo');

  // States
  const [panel, setPanel] = useState(dataPanel);
  const [auxiliarPanel, setAuxiliarPanel] = useState(null);
  const [selections, setSelections] = useState([0, 0, 0]); // the number is the subItem selected, and each index is the item's position of the panel

  const currentConfig = useMemo(() => ({
    screen: selections[0] === 0 ? 'screen' : 'camera',
    camera: selections[1] === 0 && 'camera',
    audio: selections[2] === 0 
      ? selections[0] === 1 // aunque diga camera, es el audio del screen, pues la camera sale por el screen
        ? 'screen'
        : 'camera' 
      : 'screen',
  }), [selections]);

  // Functions
  const handleButtonClick = (e, index) => {
    // adjust the position of the auxiliar panel to the button
    const top = e.target.offsetTop + e.target.offsetParent.offsetTop;
    const left = e.target.offsetParent.offsetLeft;
    setAuxiliarPanel({
      top,
      left,
      index,
    });
  };
  
  const handleUpdateSelection = (index, subIndex) => {
    setSelections((prev) => {
      const newData = [...prev];
      newData[index] = subIndex;
      return newData;
    });
    setAuxiliarPanel(null);
  };

  // Effects
  useEffect(() => { // if any camera/screen is not selected, disabled audio option
    if (selections[0] === 0 && selections[1] === 1) { // if screen is selected and camera is not
      setPanel((prev) => {
        const newData = [...prev];
        newData[2].subItems[0].disabled = true;
        newData[2].subItems[1].disabled = false;
        newData[1].disabled = false;
        return newData;
      });
      if (selections[2] === 0 ) { // prevent infinite render
        setSelections((prev) => {
          const newData = [...prev];
          newData[2] = 1;
          return newData;
        });
      }
      return;
    } 
    if (selections[0] === 1 ) { // if camera is selected and screen is not, but has two cameras selected
      setPanel((prev) => {
        const newData = [...prev];
        newData[2].subItems[0].disabled = false;
        newData[2].subItems[1].disabled = true;
        newData[1].disabled = true;
        return newData;
      });
      if (selections[2] === 1 ) {
        setSelections((prev) => {
          const newData = [...prev];
          newData[2] = 0;
          return newData;
        });
      }
      if (selections[1] === 0 ) {
        setSelections((prev) => {
          const newData = [...prev];
          newData[1] = 1;
          return newData;
        });
      }
      return;
    }
    
    // if not before, reset the disabled option
    const newData = [...panel];
    newData[2].subItems[0].disabled = false;
    newData[2].subItems[1].disabled = false;
    newData[1].disabled = false;
    setPanel(newData);

  }, [selections]);
  
  useEffect(() => { // update setConfig
    if (JSON.stringify(currentConfig) === JSON.stringify(config)) return;
    setConfig(currentConfig);
  }, [currentConfig, config]);

  if (isActive) return null

  return (
    <div
      className="absolute top-0 right-0 mt-12 mr-11 p-7 py-9 overflow-hidden rounded-3xl bg-white shadow-xl"
      style={{ width: 350 }}
    >
      {auxiliarPanel && 
        <AuxiliarPanel 
          {...auxiliarPanel} 
          subItemSelected={selections[auxiliarPanel.index]}
          updateSelection={handleUpdateSelection} 
        />}
      <div className="flex h-8 w-full items-center mt-2 mb-9">
        <img className="h-full aspect-auto" src={Logo} alt="Your Company" />
      </div>
      {panel.map((item, index) => (
        <PanelButton
          key={index}
          icon={item.subItems[selections[index]].icon}
          mb={4}
          text={item.subItems[selections[index]].text}
          onClick={(e) => handleButtonClick(e, index)}
          active={false}
          disabled={item.disabled}
        />
      ))}

      <button
        type="button"
        className="flex items-center justify-center w-full px-5 py-3 mt-7 text-lg font-semibold rounded-2xl bg-primary text-white hover:text-primary-lighter transition-colors"
        onClick={handleStartRecording}
      >
        Empezar a grabar
      </button>

      <div className="flex items-center justify-center w-full mt-4 text-gray-400 text-sm">
        {systemInfo?.max_tiempo_grabacion && `${systemInfo.max_tiempo_grabacion} min de grabación límite`}
      </div>
    </div>
  );
};

export default PanelRecordOptions;