import { useTranslation } from 'react-i18next';
import '../../config/i18next-config';

import login from '../../assets/img/login.webp'
import logo from '../../assets/img/logo@3x.png'
import { LoginForm } from './form'


export const LoginComp = ({loading, onSubmit, onRecovery}) => {
    const { t } = useTranslation();
    return (
        <div className="w-screen h-screen flex select-none">
            <div className="w-1/2 flex flex-grow flex-shrink shadow-right">
                <div className="flex flex-col justify-center items-center space-y-10">
                    <h2 className="text-3xl font-bold text-gray-800">{t('Auth.Login.welcome')}</h2>
                    <img src={login} alt="login" className="w-1/2 object-fit object-center" />
                    <img src={logo} alt="logo" className="w-1/5 pt-4" />
                </div>
            </div>
            <div className="w-1/2 flex">
                <div className="w-full flex flex-col justify-center items-center">
                    <LoginForm loading={loading} onSubmit={onSubmit} onRecovery={onRecovery} />
                </div>
            </div>
        </div>
    )
}