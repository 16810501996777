import { useTranslation } from 'react-i18next';
import '../../config/i18next-config';

import recovery from '../../assets/img/recovery.png'
import logo from '../../assets/img/logo@3x.png'
import { RecoveryForm } from './form'


export const PassRecovery = () => {
    const { t } = useTranslation();
    return (
        <div className="w-screen h-screen flex select-none">
            <div className="w-1/2 flex flex-grow flex-shrink shadow-right">
                <div className="flex flex-col justify-center items-center space-y-0">
                    <div className="flex flex-col items-center">
                        <h2 className="text-3xl font-bold text-gray-800">{t('Auth.PasswordRecovery.title1')}</h2>
                        <h2 className="text-3xl font-bold text-primary">{t('Auth.PasswordRecovery.title2')}</h2>
                    </div>
                    <img src={recovery} alt="recovery" className="w-3/4 object-fit object-center" />
                    <img src={logo} alt="logo" className="w-1/5 pt-10" />
                </div>
            </div>
            <div className="w-1/2 flex">
                <div className="w-full flex flex-col justify-center items-center">
                    <RecoveryForm />
                </div>
            </div>
        </div>
    )
}