import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { CheckCircleIcon, XCircleIcon , ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

export function Dropzone({ onUpload, errorState, }) {
  /* Hooks */
  const { t } = useTranslation();
  
  const onDrop = useCallback((acceptedFiles) => {
    if (fileRejections.length === 0 && acceptedFiles) onUpload(acceptedFiles[0]);
  }, []);
  
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    validator: extensionValidator,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.csv'],
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.csv', '.xlsx'],
      // 'application/vnd.ms-excel': ['.xls'],
    },
  });

  // Effects
  useEffect(() => {
    if (errorState?.error){
      errorState?.setError(false);
    }
  }, [acceptedFiles]);

  /* Functions */
  function extensionValidator(file) {
    if (
      // file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      // file.type !== 'application/vnd.ms-excel' &&
      file.type !== 'text/csv'
    ) {
      return {
        code: 'invalid-extension',
        message: `Tipo de archivo no válido`,
      };
    }

    return null;
  }

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container cursor-pointer">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input 
          {...getInputProps()} 
          id="dropzone-input" 
          onChange={onUpload} 
          accept=".csv" //, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
          multiple={false}
        />
        <div className="col-span-full">
          <div className="w-full text-center rounded-xl border-2 border-gray-300 py-6 2xl:py-10 flex flex-col items-center justify-center cursor-pointer duration-100 hover:shadow-xl active:border-primary">
            {acceptedFiles.length === 0 && fileRejections.length === 0 ? (
              <>
                <IoCloudUploadOutline className="text-[50px] 2xl:text-[80px] text-gray-300 select-none" />
                <span className="text-lg font-bold mt-3 mb-1 select-none text-gray-700">
                  {t('Message.drop.drag_excel')}
                </span>
                <span className="text-gray-400 text-sm 2xl:text-md select-none cursor-pointer">
                  {t('Message.drop.accepted_formats')} <br />
                  {t('Message.drop.format_restrictions')}
                </span>
              </>
            ) : acceptedFiles.length > 0 && errorState?.error ? (
              <div className="flex flex-col justify-center items-center">
                <ExclamationTriangleIcon className="w-16 h-16 text-red-500" />
                <p>{files}</p>
              </div>
            ) : acceptedFiles.length > 0 ? (
              <div className="flex flex-col justify-center items-center">
                <CheckCircleIcon className="w-16 h-16 text-green-500" />
                <p>{files}</p>
              </div>
            ) : (
              fileRejections.length === 1 && (
                <div className="flex flex-col justify-center items-center">
                  <XCircleIcon className="w-16 h-16 text-primary" />
                  <h4 className="font-light">{t('Message.drop.invalid_file')}</h4>
                </div>
              ),
              fileRejections.length > 1 && (
                <div className="flex flex-col justify-center items-center">
                  <XCircleIcon className="w-16 h-16 text-primary" />
                  <h4 className="font-light">{t('Message.drop.many_files')}</h4>
                </div>
              )
            )}
            <p
              className="underline mt-5 text-sm 2xl:text-md underline-offset-2 text-primary hover:text-red-700"
              onClick={() => window.open('/files/import-clients.csv', '_self')}
            >
              {t('Message.drop.download_sample')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
