import { memo, useMemo } from "react"

const Bar = memo(() => {
  // random value between 10 and 40
  const height = useMemo(() => Math.floor(Math.random() * 30) + 10, []) 
  
  return (
    <div 
      className="w-1 rounded-full bg-gray-400"
      style={{ height: `${height}px` }}
    />
  )
});

Bar.displayName = "Bar";

const SimulatedBars = ({ width = 0, onSelectEvent }) => {

  return (
    <div className='flex gap-1 items-center p-1 overflow-hidden'
      style={{ width }}
      onClick={onSelectEvent}
    >
      {Array.from({ length: (width || 0) / 8}).map((_, i) => (
        <Bar key={`bar-${i}`} />
      ))}
    </div>
  )
}


export default SimulatedBars