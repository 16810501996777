import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Routes from '../../routes/app/paths';

export function Notice() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="mx-auto lg:flex lg:items-center lg:gap-x-10 lg:px-6 p-6 rounded-xl  bg-gray-dark">
      <div className="lg:ml-3 flex-1 md:flex justify-between">
        <p className="text-lg text-gray-darker">
          {t('Dashboard.subtitle2')}
        </p>
      </div>
      <button onClick={() => navigate(Routes.corporativeVideos)} 
        className="bg-gray-darkest px-2.5 py-1.5 rounded-md text-white mt-2 lg:mt-0 hover:bg-primary duration-200">
        {t('Dashboard.upload_videos')}
      </button>
    </div>
  );
}
