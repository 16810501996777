import { useLocation } from 'react-router-dom';
import { useQueries, useQuery } from 'react-query';
import useAuthContext from '../../../../hooks/useAuthContext';
import { useEffect, useRef, useState } from 'react';
import { getFolders } from '../../../../services/library/LibraryService';



const useFound = () => {
  // state
  const [searchCancelled, setSearchCancelled] = useState(false);

  // refs
  const elementRef = useRef(null);

  // hooks
  const { user } = useAuthContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idParam = Number(searchParams.get('id') || searchParams.get('idFolder'))

  // rq: check when the data is loaded to scroll to the element
  const queries = [
    'video',
    'corporative',
    'record',
    'portrait',
  ].map(type => ({
    queryKey: [type],
    queryFn: () => getFolders({
      token: user?.access_token,
      type: type === 'portrait' ? 'frontPage' : type,
      archived: 0,
    }),
    enabled: Boolean(idParam),
  }));
  const results = useQueries(queries);
    
  // constants
  const isAllRequestSuccess = results.every(r => r.isSuccess);
  
  // effects
  useEffect(() => {
    if(idParam && isAllRequestSuccess){
      // check (10seg max) if the element has render if the search its not cancelled, to scroll to the element
      let counter = 0;
      const intervalId = setInterval(() => {
        if (counter === 20 || searchCancelled || elementRef.current) clearInterval(intervalId);
        counter ++;
        elementRef.current = document.getElementById(idParam);
        if (elementRef.current && !searchCancelled) {
          elementRef.current.scrollIntoView({ behavior: 'smooth' });
          // setSearchCancelled(true);
          clearInterval(intervalId);
        }
      }, 500);
    }
  }, [idParam, isAllRequestSuccess, searchCancelled, elementRef]);
  return {
    elementRef,
    setSearchCancelled,
  }
}

export default useFound;