import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import '../../config/i18next-config';

// components
import { LoginComp, PassRecoveryComp, PassChangeComp } from '../../components/login';

// hooks
/* import { useToast } from '../hooks/useToast'; */
import useAuthContext from '../../hooks/useAuthContext';
// services
import { TokenService, UserService } from '../../services/auth/AuthService';
import { DASHBOARD, PASSWORD_RECOVERY } from '../../routes/app/paths';
import { Toast } from '../../components/common';
import { useToast2 } from '../../hooks/useToast2';
// routes

export function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { login, isAuthenticated } = useAuthContext();
  const { showToast } = useToast2();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(DASHBOARD);
    }
  }, [isAuthenticated]);

  /* Handlers */
  const handleSubmit = async (values) => {
    setLoading(true);
    const { email: username, password, remember } = values;

    const userInfo = {
      grant_type: 'password',
      client_id: 2,
      client_secret:
        /* process.env.REACT_APP_CLIENT_SECRET ||  */ 'zxFKbYUGTcUB5DuqUalcDzaaMZov6tOqSsZwHeUk',
      username,
      password,
      scope: '',
    };

    try {
      const fetchToken = await queryClient.fetchQuery([userInfo], TokenService);
      if (fetchToken.status === 200) {
        const token = fetchToken.token;
        const fetchUser = await queryClient.fetchQuery([token], UserService);
        if (fetchUser.status === 200) {
          login(fetchUser.user);
        }
      }
    } catch (error) {
      if (error.status === 400) {
        showToast({
          show: true,
          type: 'error',
          message: t('Message.auth.error.credential'),
        });
      } else {
        showToast({
          show: true,
          type: 'error',
          message: t('Message.problem'),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="main-content bg-image-login">
        <title>{t('Auth.Login.meta')}</title>
        {/* <MetaTags>
        </MetaTags> */}
        <LoginComp loading={loading} onSubmit={handleSubmit} />
      </div>
    </React.Fragment>
  );
}
