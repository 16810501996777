import { IoPause, IoPlayOutline, IoReturnUpBack } from 'react-icons/io5';
import { BiTrash } from 'react-icons/bi';
import useRecordContext from '../context/useRecordContext';
import { classNames } from '../../../../config/utils';
import CameraPreview from '../MediaRecorder/CameraPreview';
import RecordCountdown from './RecordCountdown';

const RecordControl = ({ showPreviewModal, onClose}) => {
  const { isPaused, isActive, handlePauseResumeRecording, discardRecording, config } = useRecordContext();

  const handleDiscard = () => {
    discardRecording();
  }

  const handleDelete = () => {
    discardRecording();
    onClose();
  }

  return (
    <div className="fixed items-end flex left-16" style={{ bottom: '13.6vh' }}>
      {config.camera === 'camera' && <CameraPreview />}

      <div
        className={classNames("flex items-center rounded-2xl h-fit bg-white shadow-xl  py-3 px-3 ml-12", isActive ? 'text-gray-800' : 'text-gray-300 font-semibold')}
      >
        <RecordCountdown showPreviewModal={showPreviewModal} />

        <div className="w-0.5 bg-gray-300" style={{ height: 46 }} />

        <button
          type="button"
          disabled={!isActive}
          className={classNames('p-1 ml-3 rounded-xl hover:text-primary hover:shadow-lg disabled:cursor-default', !isActive && 'pointer-events-none')}
          onClick={handleDiscard}
        >
          <IoReturnUpBack size={30} />{' '}
        </button>
        <button
          type="button"
          disabled={!isActive}
          className={classNames('p-1 ml-2 rounded-xl hover:text-primary hover:shadow-lg disabled:cursor-default', !isActive && 'pointer-events-none')}
          onClick={handlePauseResumeRecording}
        >
          {!isPaused ? <IoPause size={30} /> : <IoPlayOutline size={30} />}{' '}
        </button>
        <button
          type="button"
          disabled={!isActive} 
          className={classNames('p-1 ml-1 rounded-xl hover:text-primary hover:shadow-lg disabled:cursor-default', !isActive && 'pointer-events-none')}
          onClick={handleDelete}
        >
          <BiTrash size={30} />{' '}
        </button>
      </div>
    </div>
  );
};

export default RecordControl;