import { useEffect, useMemo, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { classNames } from '../../config/utils';
import { useTranslation } from 'react-i18next';
import { Table } from '../common';
import Routes from '../../routes/app/paths';
import GeneralConfirModal from '../../components/common/modal/GeneralConfirModal';
import { deleteUserCompany, getUsersCompanies, restoreUserCompany } from '../../services/admin/adminService';
import useAuthContext from '../../hooks/useAuthContext';
import { useToast2 } from '../../hooks/useToast2';
import moment from 'moment';
import { CustomSingleValue } from '../../components/common/SelectComponents';
import { MdSettingsBackupRestore } from 'react-icons/md';
import ConfirmDelete from './elements/ConfirmDelete';

/* local const */
const styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: 30,
    border: '2px solid #c0c0c0',
    color: '#c0c0c0',
    fontWeight: 500,
    flex: 1,
    padding: '0 0.2rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px', // Tamaño base del texto
    '@media (min-width: 1536px)': {
      padding: '0 0.1rem',
      fontSize: '16px', // Tamaño para 2xl
    },
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'white',
    color: 'gray',
    fontSize: '12px', // Tamaño base del texto
    '@media (min-width: 1536px)': {
      fontSize: '16px', // Tamaño para 2xl
    },
    '&:hover': {
      backgroundColor: '#ccc',
    },
  }),
};

export const UsersList = ({ archived }) => {
  /* state */
  const [users, setUsers] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [order, setOrder] = useState({ column: 'name', type: 'desc' });
  // selects
  const [companies, setCompanies] = useState([]);
  const [lastSend, setLastSend] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenRestore, setModalOpenRestore] = useState(false);
  const [filter, setFilter] = useState({
    company: null,
    lastSend: { from: null, to: null },
    search: '',
  });

  /* hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const queryClient = useQueryClient();

  // const
  const headersTable = [
    { title: t('AdminZone.user.table.name'), dropdown: true },
    { title: t('AdminZone.user.table.last_name'), dropdown: true },
    { title: t('AdminZone.user.table.company'), dropdown: true },
    { title: t('AdminZone.user.table.role'), dropdown: true },
    { title: t('AdminZone.user.table.sent_videos'), dropdown: true },
    { title: 'Info.', dropdown: false },
  ];

  //Effects
  useEffect(() => {
    setSelectedItem([]);
  }, [archived]);

  useEffect(() => {
    const filteredCompanies = users
      ?.filter(u => archived ? u.deleted_at : !u.deleted_at)
      ?.map((u, i) => ({ value: i, label: u.company.name }))
      .filter((fc, index, arr) => {
        return arr.findIndex((o) => o.label === fc.label) === index;
      });
    const filteredLastSend = users
      ?.filter(u => archived ? u.deleted_at : !u.deleted_at)
      ?.map((u, i) => ({ value: i, label: u.lastVideoDate?.split('T')[0] }))
      .filter((e) => e.label !== undefined);
    setCompanies(filteredCompanies);
    setLastSend(filteredLastSend);
  }, [users, archived]);

  //Querys
  const {
    data: dataUsers,
    isLoading: loadingUsers,
    error: errorUsers,
  } = useQuery(['USERS'], () => getUsersCompanies({ token: user.access_token, companyId: 'all' }), {
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    setUsers(dataUsers?.data?.data
      ?.filter((c) => archived ? c.deleted_at : !c.deleted_at) || []);    
  }, [dataUsers, archived]);

  const {
    mutate: mutateDeleteUsers,
    isLoading: loadingDelete,
    error: errorDelete,
  } = useMutation((opt) => {
    if (opt.restore) return restoreUserCompany(opt);
    return deleteUserCompany(opt)
  }, {
    onSuccess: async (_, opt) => {
      await queryClient.invalidateQueries(['USERS']);
      setSelectedItem([]);
      setModalOpen(false);
      setModalOpenRestore(false);
      showToast({ show: true, type: 'success', message: opt.restore
      ? t('Message.user.success.restore') 
      : t('Message.user.success.delete') });
    },
    onError: (err, opt) => {
      queryClient.invalidateQueries(['USERS']);
      console.log(err);
      showToast({ show: true, type: 'error', message: opt.restore
        ? t('Message.user.error.restore')
        : t('Message.user.error.delete') });
    },
  });

  /* handlers */
  const handleNewUser = () => {
    navigate(Routes.newUser);
  };

  const removeItems = () => {
    selectedItem.length && (setModalOpen(true), setModalOpenRestore(false));
  };

  const restoreItems = () => {
    selectedItem.length && (setModalOpen(false), setModalOpenRestore(true));
  };
  
  const handleConfirmModal = (confirm, restore) => {
    confirm
      ? mutateDeleteUsers({ 
        token: user.access_token, 
        user_ids: selectedItem.map((u) => u.id), 
        restore 
      })
      : (setModalOpen(false), setModalOpenRestore(false));
  };
  
  // usuarios filtrados
  const usersFiltered = useMemo(() => {
    if (!users?.length) return [];
    return users
      // filter by company
      .filter((user) => {
        if (!filter.company) return true;
        return user.company?.name === filter.company?.label;
      })
      // filter by last send from
      .filter((user) => {
        if (!filter.lastSend.from) return true;
        return moment(user.lastVideoDate?.split('T')[0], 'YYYY-MM-DD').isSameOrAfter(
          moment(filter.lastSend.from?.label, 'YYYY-MM-DD'),
        );
      })
      // filter by last send to
      .filter((user) => {
        if (!filter.lastSend.to) return true;
        return moment(user.lastVideoDate?.split('T')[0], 'YYYY-MM-DD').isSameOrBefore(
          moment(filter.lastSend.to?.label, 'YYYY-MM-DD'),
        );
      })
      // filter by search in any field
      .filter((user) => {
        if (!filter.search) return true;
        return (
          user.name.toLowerCase().includes(filter.search.toLowerCase()) ||
          user.last_name.toLowerCase().includes(filter.search.toLowerCase()) ||
          user.company.name.toLowerCase().includes(filter.search.toLowerCase()) ||
          user.role.toLowerCase().includes(filter.search.toLowerCase()) ||
          user.totalVideos.toString().includes(filter.search.toLowerCase())
        );
      });
  }, [users, filter]);
  
  //Creando el cuerpo de la tabla
  const bodyTable = useMemo(() => (
    usersFiltered
    ?.filter((item) => archived ? item.deleted_at : !item.deleted_at)
    ?.map((item) => {
      return [
        { type: 'text', value: item.name, name: 'name', id: item.id },
        { type: 'text', value: item.last_name, name: 'last_name' },
        { type: 'text', value: item.company.name, name: 'company' },
        { type: 'text', value: item.role, name: 'role' },
        { type: 'text', value: item.totalVideos, name: 'sent_videos' },
        {
          type: 'button',
          value: 'Ver',
          route: `${Routes.userProfile}/${item.id}`,
        }
      ]
    })
      // order by column
      .sort((a, b) => {
        let x = null;
        let y = null;
        if (order.column === t('AdminZone.user.table.name')) {
          x = a[0].value !== null ? a[0].value.toLowerCase() : 'zzz';
          y = b[0].value !== null ? b[0].value.toLowerCase() : 'zzz';
        } else if (order.column === t('AdminZone.user.table.last_name')) {
          x = a[1].value !== null ? a[1].value.toLowerCase() : 'zzz';
          y = b[1].value !== null ? b[1].value.toLowerCase() : 'zzz';
        } else if (order.column === t('AdminZone.user.table.company')) {
          x = a[2].value !== null ? a[2].value.toLowerCase() : 'zzz';
          y = b[2].value !== null ? b[2].value.toLowerCase() : 'zzz';
        } else if (order.column === t('AdminZone.user.table.role')) {
          x = a[3].value !== null ? a[3].value.toLowerCase() : 'zzz';
          y = b[3].value !== null ? b[3].value.toLowerCase() : 'zzz';
        } else if (order.column === t('AdminZone.user.table.sent_videos')) {
          x = a[4].value;
          y = b[4].value;
        }
        if (x < y) {
          return order.type === 'asc' ? -1 : 1;
        }
        if (x > y) {
          return order.type === 'asc' ? 1 : -1;
        }
        return 0;
      })
    ) || [], [usersFiltered, order, archived])

  const orderTable = (column) => {
    let orderType = 'asc';
    if (order.column === column && order.type === 'asc') {
      orderType = 'desc';
    }

    setOrder({
      column: column,
      type: orderType,
    });
  };

  const handleFilter = (type, value) => {
    setFilter((prev) => ({ ...prev, [type]: value }));
  }

  const isSelfUserSelected = useMemo(() => selectedItem.some((item) => item.id === user?.id), [selectedItem, user]);

  return (
    <>
      <h1 className="text-md 2xl:text-xl mt-5 font-bold text-gray-800">{t('User.meta')}</h1>
      <div className="flex items-center p-1 space-x-5 mt-2 justify-between">
        <div className="flex space-x-1 2xl:space-x-2">
          <Select
            components={{ SingleValue: CustomSingleValue }}
            options={companies}
            isClearable={true}
            value={filter.company}
            isLoading={loadingUsers}
            placeholder={t('Company.company')}
            styles={styles}
            className={'z-30 w-40 2xl:w-56'}
            onChange={(newValue) => {
              handleFilter('company', newValue);
            }}
          />
          <Select
            components={{ SingleValue: CustomSingleValue }}
            options={lastSend}
            isClearable={true}
            value={filter.lastSend?.from}
            isLoading={loadingUsers}
            placeholder={t('User.last_send_from')}
            styles={styles}
            className={'z-30 w-44 2xl:w-56'}
            onChange={(newValue) => {
              handleFilter('lastSend', { ...filter.lastSend, from: newValue });
            }}
          />
          <Select
            components={{ SingleValue: CustomSingleValue }}
            options={lastSend}
            isClearable={true}
            value={filter.lastSend?.to}
            isLoading={loadingUsers}
            placeholder={t('User.last_send_to')}
            styles={styles}
            className={'z-30 w-44 2xl:w-56'}
            onChange={(newValue) => {
              handleFilter('lastSend', { ...filter.lastSend, to: newValue });
            }}
          />
        </div>
        <div className="flex items-center space-x-2 2xl:space-x-4 justify-end w-full">
          <button
            onClick={handleNewUser}
            className="bg-primary flex text-white px-4 py-2 rounded-xl whitespace-nowrap select-none hover:shadow-md active:scale-[0.98] transition-shadow text-sm 2xl:text-md disabled:opacity-0"
            disabled={archived}
          >
            {t('User.new')}
          </button>
          <input
            type="text"
            placeholder={t('Placeholder.search')}
            value={filter.search}
            className="border-gray-300 text-sm 2xl:text-md rounded-lg focus:border-gray-300 focus:ring-1 focus:ring-gray-300 w-32 2xl:w-56 placeholder:text-gray-300"
            onChange={(e) => handleFilter('search', e.target.value)}
          />
          {archived && <MdSettingsBackupRestore
            className={classNames(
              selectedItem.length !== 0
                ? 'cursor-pointer text-black hover:text-primary'
                : 'cursor-not-allowed text-gray-200',
                'font-extralight w-6 2xl:w-8 h-6 2xl:h-8',
            )}
            disabled={selectedItem.length === 0}
            onClick={restoreItems}
          />}
          <div className='relative'>
            {!archived && <TrashIcon
              className={classNames(
                selectedItem.length !== 0 && !isSelfUserSelected
                  ? 'cursor-pointer text-black hover:text-primary'
                  : 'cursor-not-allowed text-gray-200',
                'font-extralight w-6 2xl:w-8 h-6 2xl:h-8',
              )}
              onClick={() => !isSelfUserSelected && removeItems()}
            />}
            {isSelfUserSelected && <div className='absolute -top-10 left-0 w-full h-full flex items-center justify-center'>
              <span className='bg-black bg-opacity-50 p-1 rounded-md whitespace-nowrap text-white text-xs 2xl:text-sm'>{t('User.cant_delete_self')}</span>
            </div>}
          </div>
        </div>
      </div>
      <div className="sm:px-6 lg:px-0">
        <div className="flow-root">
          <div className="-ml-7">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 2xl:pr-0">
              <div className="relative  h-[330px] 2xl:h-[490px]">
                <Table
                  header={headersTable}
                  body={bodyTable}
                  hasCheckable="true"
                  selectedItems={selectedItem}
                  onSelect={setSelectedItem}
                  onOrder={orderTable}
                  clients={users}
                  isLoading={loadingUsers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDelete
        isOpen={modalOpen}
        label='Confirme que desea eliminar el usuario'
        subtitle={!archived ? 'Este usuario será archivado.' : 'Se eliminará permanentemente. Esta acción es irreversible.'}
        action='custom'
        onConfirm={(confirmed) => handleConfirmModal(confirmed)}
        loading={loadingDelete}
      />
      <ConfirmDelete
        isOpen={modalOpenRestore}
        label='Confirme que desea restaurar el usuario'
        action='custom'
        onConfirm={(confirmed) => handleConfirmModal(confirmed, true)}
        loading={loadingDelete}
      />
    </>
  );
};
