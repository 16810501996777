import { useState, useEffect, useRef } from 'react';

function useBatchLoader(items, batchSize = 50, triggerElementId = 'loadMoreTrigger') {
  const [loadedItems, setLoadedItems] = useState([]);
  const itemsRef = useRef(items);
  const loadedItemsRef = useRef(loadedItems);
  const observer = useRef();

  // Actualiza las referencias cuando cambian los items o los items cargados
  useEffect(() => {
    itemsRef.current = items;
    loadedItemsRef.current = loadedItems;
  }, [items, loadedItems]);

  useEffect(() => {
    const triggerElement = document.getElementById(triggerElementId);
    if (triggerElement) {
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setLoadedItems((prevItems) => {
            const nextItems = itemsRef.current.slice(prevItems.length, prevItems.length + batchSize);
            return [...prevItems, ...nextItems];
          });
        }
      }, { threshold: 0.5 });

      observer.current.observe(triggerElement);
    }

    return () => {
      if (triggerElement && observer.current) observer.current.unobserve(triggerElement);
    };
  }, [triggerElementId, batchSize]);

  useEffect(() => {
    setLoadedItems(items.slice(0, batchSize));
  }, [items, batchSize]);

  if (!items?.length) return [];
  if (items?.length <= batchSize) return items;
  return loadedItems;
}

export default useBatchLoader;