import { FolderItem } from './FolderItem';
import { Skeleton } from '../../components/common';

export function FolderList({ items = [], isLoading = false }) {
  return (
    <div className="flex gap-4 flex-wrap">
      {isLoading
        ? [1, 2, 3].map((key) => (
            <Skeleton key={key} width={240} height={56} className="w-60 2xl:w-72 h-14 2xl:h-16" />
          ))
        : items.map((item, index) => (
            <FolderItem key={index} item={item} id={item.id} zIndex={items.length - index} />
          ))}
    </div>
  );
}
