import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../config/utils';
import { Fragment, useState, useContext, useRef, useEffect } from 'react';
import { FolderContext } from '../../contexts/folderContext';
import { querys } from '../../pages/Library/tools/querys';
import useAuthContext from '../../hooks/useAuthContext';
import { updateStructureByParentId } from '../../pages/Library/tools/pathUtils';
import { Skeleton } from '../../components/common';
import { getTypeFilesByCategory } from '../../pages/Library/tools/types';
import { Tooltip } from '@chakra-ui/react';

function MenuItem({ id, name, children, status, parent_id, sublevel = false, selected, onSelect }) {
  /* State */
  const [opened, setOpened] = useState(status === 'new');
  const [editing, setEditing] = useState(status === 'new');
  const [nameEdit, setNameEdit] = useState({
    prev: name,
    current: name,
  });
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef(null);

  /* Hooks */
  const { library, foldersSelected } = useContext(FolderContext);
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { mutate: createNewFolder, isLoading: isCreatingFolder } = querys().createFolder(library);

  /* Effects */
  useEffect(() => {
    if (inputRef.current !== null) {
      setInputFocused(true);
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (status === 'new') {
      setEditing(true);
      setOpened(true);
    }
  }, [status, children]);

  /* Functions and const*/
  const disabled = foldersSelected?.includes(id);

  const handleSelect = () => {
    if (children?.length) setOpened(!opened);
    if (onSelect && id !== 'temp') onSelect(id);
  };

  const handleChangeName = (e) => {
    e.preventDefault();
    setNameEdit((p) => ({
      ...p,
      current: e.target.value,
    }));
    setInputFocused(true);
  };

  const renameFolder = () => {
    if (nameEdit.current === '') return;
    setEditing(false);
    createNewFolder({
      token: user.access_token,
      name: nameEdit.current || name,
      parentId: parent_id,
      type: library,
    });
  };

  const handleRenameFolder = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      renameFolder();
    }
  };

  return (
    <div className="mt-1 pl-2">
      <Tooltip
        label={t('Library.cannot_move_folder')}
        openDelay={500}
        display={disabled ? 'block' : 'none'}
      >
        <div
          key={id}
          className={classNames(
            'pl-2 rounded-md w-full hover:bg-gray-menu-shadow',
            selected === id && 'bg-primary-lighter',
            !isCreatingFolder && 'cursor-pointer',
          )}
          onClick={handleSelect}
        >
          <div
            className={classNames(
              !disabled ? ' text-gray-900' : 'text-gray-400',
              'flex items-center space-x-2',
            )}
          >
            {children.length && opened && !disabled ? (
              <ChevronDownIcon className="w-4 2xl:w-5 h-4 2xl:h-5 pointer-events-none" />
            ) : children.length ? (
              <ChevronRightIcon className="w-4 2xl:w-5 h-4 2xl:h-5 pointer-events-none" />
            ) : (
              <></>
            )}
            {editing ? (
              <input
                ref={inputRef}
                onKeyDown={handleRenameFolder}
                type="text"
                className={classNames(
                  'w-full py-1 rounded-md',
                  inputFocused && 'border-none outline-none focus:ring-0 bg-blue-400/30',
                )}
                onChange={handleChangeName}
                value={nameEdit.current}
                onBlur={renameFolder}
              />
            ) : (
              <div className="relative w-full">
                <span
                  className={classNames(
                    'block px-1 py-2 text-[14px] 2xl:text-[16px]',
                    isCreatingFolder && 'text-primary',
                  )}
                >
                  {nameEdit.current} {isCreatingFolder && '(agregando...)'}
                </span>
                <div className="absolute inset-0">{isCreatingFolder && <Skeleton />}</div>
              </div>
            )}
          </div>
        </div>
      </Tooltip>
      {!disabled &&
        opened &&
        children?.map((chldNode) => (
          <MenuItem
            key={chldNode.id}
            sublevel={true}
            selected={selected}
            onSelect={onSelect}
            {...chldNode}
          >
            {chldNode.children}
          </MenuItem>
        ))}
    </div>
  );
}

export function OptionItem({ item: originalItem }) {
  const {
    idFolder,
    refsNeverDeselectFolder,
    setRefsNeverDeselectFolder,
    foldersSelected,
    filesSelected,
    setFoldersSelected,
    setFoldersLoading,
    onActions,
    setCheckMode,
    setFilesLoading,
    setFilesSelected,
    library,
    setModals,
    setDeleteItemsProps,
  } = useContext(FolderContext);
  const [idSelected, setIdSelected] = useState(0);
  const [options, setOptions] = useState(originalItem?.subheader?.options || []);
  const [item, setItem] = useState(originalItem);

  const ref = useRef(null);
  const refItems = useRef(null);
  const { t } = useTranslation();

  /* Hooks */
  const { user } = useAuthContext();
  const { moveFolderQuery, moveFilesQuery } = querys();
  const { mutate: moveFolder } = moveFolderQuery(library);
  const { mutate: moveFiles } = moveFilesQuery(library);

  /* Effects */
  useEffect(() => {
    const idItemToRefContext = [2, 3];
    if (idItemToRefContext.includes(item.id) && !refsNeverDeselectFolder?.includes(ref)) {
      const localRefs = [ref].concat(refItems.current || []);
      setRefsNeverDeselectFolder((prev) => [...prev, ...localRefs]);
    }
  }, []);

  useEffect(() => {
    setOptions(originalItem?.subheader?.options || []);
    setItem(originalItem);
  }, [originalItem]);

  /* Handlers */
  const handleSelect = (id) => {
    setIdSelected(id);
  };

  const handleNewFolder = () => {
    const updatedOptions = updateStructureByParentId(item.subheader.options, {
      parentId: idSelected,
    });
    const updatedSubheader = { ...item.subheader, options: updatedOptions };
    const updatedItem = { ...item, subheader: updatedSubheader };
    setOptions(updatedOptions);
    setItem(updatedItem);
  };

  const handleMove = (fnClose) => {
    if (foldersSelected?.includes(idSelected)) return; // no puede mover una carpeta dentro de si misma
    if (foldersSelected?.length) {
      moveFolder({
        token: user.access_token,
        folderId: foldersSelected,
        newParentId: idSelected,
        type: getTypeFilesByCategory(library),
      });
      setFoldersLoading((prev) => [...prev, ...foldersSelected]);
      setFoldersSelected([]);
    }
    setCheckMode(false);
    if (filesSelected?.length) {
      moveFiles({
        token: user.access_token,
        ids: filesSelected,
        newFolderId: idSelected,
        type: getTypeFilesByCategory(library),
      });
      setFilesLoading((prev) => [...prev, ...filesSelected]);
      setFilesSelected([]);
    }
    fnClose();
  };

  const params = () => {
    switch (item.id) {
      case 3: {
        //delete
        return {
          foldersSelected,
          filesSelected,
          type: getTypeFilesByCategory(library),
        };
      }
      default:
        return {};
    }
  };

  return (
    <Menu ref={ref} as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={classNames(
            'inline-flex w-full justify-center gap-x-1 rounded-full p-1 text-sm font-semibold',
            !item.disabled
              ? 'hover:text-primary hover:shadow-lg text-gray-900 cursor-pointer'
              : 'text-gray-300',
          )}
          disabled={item.disabled}
        >
          <div
            onClick={() => {
              //en caso de que sea delete se abre el modal de confirmacion, en caso contrario se ejecuta la accion directamente
              item.id === 3
                ? (setModals((prev) => ({ ...prev, deleteItems: true })),
                  setDeleteItemsProps((prev) => ({
                    ...prev,
                    params: params(),
                    label: t('Library.the_selected_elements'),
                  })))
                : onActions(item.action, params());
            }}
          >
            <item.icon className="w-6 2xl:w-7 h-6 2xl:h-7" />
          </div>
        </Menu.Button>
      </div>

      {(item.options.length > 0 || item.show) && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            ref={refItems}
            className={classNames(
              item.header ? 'w-[450px] px-0 ' : 'w-48',
              'absolute p-2 divide-y divide-gray-100 right-0 z-10 mt-2 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none select-none',
            )}
          >
            {item.header && (
              <div className="px-6 py-2">
                <p className="truncate text-md 2xl:text-lg font-semibold text-gray-900">
                  {t(item.title)}
                </p>
              </div>
            )}
            <div className={classNames(item.subheader ? 'py-3 px-6' : 'p-2 px-1')}>
              {item.subheader ? (
                <>
                  <div
                    className={classNames(
                      'p-3 flex items-center space-x-3 rounded-lg opacity-70 cursor-pointer',
                      !idSelected && 'bg-primary-lighter',
                    )}
                    onClick={() => handleSelect(0)}
                  >
                    <item.icon className="w-6 h-6"></item.icon>
                    <p className="truncate text-md 2xl:text-lg font-semibold text-gray-900">
                      {item.subheader.title}
                    </p>
                  </div>
                  <div className="max-h-48 overflow-auto">
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        {...option}
                        selected={idSelected}
                        onSelect={handleSelect}
                      />
                    ))}
                  </div>
                </>
              ) : (
                item.options.map((item) => (
                  <Menu.Item
                    key={item.id}
                    className="rounded-md px-2 truncate w-full hover:bg-gray-menu-shadow cursor-pointer"
                    onClick={() => {
                      onActions(item.action, params());
                    }}
                  >
                    {({ active }) => (
                      <span
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        )}
                      >
                        {t(item.title)}
                      </span>
                    )}
                  </Menu.Item>
                ))
              )}
            </div>
            {item.footer && (
              <div className="pt-5 pb-3 px-6">
                <div className="flex justify-between">
                  <div>
                    <button
                      className="flex items-center space-x-2 px-2 py-1.5 pr-3 bg-white ring-1 ring-primary text-primary rounded-xl hover:shadow-md active:scale-[0.98]"
                      onClick={handleNewFolder}
                    >
                      <PlusIcon className="w-5 h-5" />
                      <span>{t('Library.new_folder')}</span>
                    </button>
                  </div>
                  <Menu.Item>
                    {({ close }) => (
                      <div className="flex space-x-2">
                        <button
                          className="px-2 py-1.5 bg-white ring-1 ring-gray-300 text-gray-300 rounded-xl hover:shadow-md active:scale-[0.98]"
                          onClick={close}
                        >
                          {t('Library.cancel')}
                        </button>

                        <button
                          className={classNames(
                            'px-4 py-1.5 text-white rounded-xl hover:shadow-md active:scale-[0.98]',
                            foldersSelected?.includes(idSelected) || idSelected === idFolder
                              ? 'bg-black-soft cursor-default'
                              : 'bg-primary',
                          )}
                          disabled={
                            foldersSelected?.includes(idSelected) || idSelected === idFolder
                          }
                          onClick={() => handleMove(close)}
                        >
                          {t('Library.move')}
                        </button>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </div>
            )}
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
}
