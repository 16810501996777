import { FrameItem } from './FrameItem';
import { Skeleton } from '../../components/common';
import { useTranslation } from 'react-i18next';

export function FrameList({ videos = [], isLoading = false, setSelected }) {
  const { t } = useTranslation();
  return (
    <div className="frame-list grid grid-cols-4 gap-6">
      {isLoading ? (
        [1, 2, 3].map((key) => (
          <Skeleton
            key={key}
            width={180}
            height={128}
            className="w-60 2xl:w-72 h-14 2xl:h-16 mx-2 flex flex-col rounded-lg"
          />
        ))
      ) : (
        <>
          {videos && videos.length ? (
            videos.map((video, index) => (
              <FrameItem key={index} item={video} setSelected={setSelected} />
            ))
          ) : (
            <div>
              <h5>{t('Video.not_videos')}</h5>
            </div>
          )}
        </>
      )}
    </div>
  );
}
