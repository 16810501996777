import { Hero, Notice } from '../components/home';
import { LibraryList } from '../components/home/List';

export function Home() {
  return (
    <>      
      <Hero />
      <Notice />
      <LibraryList />
    </>
  );
}
