import { useState } from 'react';
import { Input } from '.';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../config/i18next-config';
import { useMutation } from 'react-query';
import { ResetPasswordService } from '../../../services/auth/AuthService';
import { useToast2 } from '../../../hooks/useToast2';
import { LOGIN } from '../../../routes/app/paths';
import { Spin } from '../../../components/common';

export const ChangeForm = () => {
  const [pass, setPass] = useState({
    password: '',
    confirmPassword: '',
  });
  const [passError, setPassError] = useState(null);
  const [passSuccess, setPassSuccess] = useState(null);
  const { t } = useTranslation();
  const { showToast } = useToast2();
  const navigate = useNavigate();
  const { token } = useParams();

  // querys
  const { mutate: mutateResetPass, isLoading: loadingResetPass } = useMutation(
    (opt) => ResetPasswordService(opt), {
    onSuccess: (resp) => {
      showToast({ show: true, type: 'success', message: t('Message.password.success.update') });
      setTimeout(() => {
        navigate(LOGIN);
      }, 3000);
    },
    onError: (error) => {
      console.log(error)
      showToast({ show: true, type: 'error', message: t('Message.password.error.update') });
    }
  });

  const handlePassChange = (event, type) => {
    const newValue = event.target.value;
    setPassError(null);
    setPass({
      ...pass,
      [type]: newValue,
    });


    const otherType = type === 'password' ? 'confirmPassword' : 'password';
    setPassSuccess(pass[otherType] === newValue
      && pass.password !== '')
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    if (!passSuccess) return setPassError({
      message: t('Validation.not_match_password')
    });

    mutateResetPass({ token, password: pass?.password })
  };

  return (
    <form onSubmit={handleSubmitForm} className="w-3/5 p-4 select-none flex flex-col space-y-16">
      <div className="flex flex-col space-y-2">
        <h3 className="text-primary text-2xl font-bold">{t('Auth.PasswordRecovery.meta')}</h3>
      </div>

      <div className="flex flex-col w-full space-y-2">
        <div className="flex flex-col space-y-7">
          <Input
            type="password"
            placeholder={t('Auth.PasswordChange.new_pass')}
            value={pass.password}
            onChange={(e) => handlePassChange(e, 'password')}
            showSuccess={passSuccess}
          />
          <Input
            type="password"
            placeholder={t('Auth.PasswordChange.confirm_pass')}
            value={pass.confirmPassword}
            onChange={(e) => handlePassChange(e, 'confirmPassword')}
            showError={passError}
            showSuccess={passSuccess}
          />
        </div>
      </div>

      <div className="flex pt-0">
        <button
          type="submit"
          className="bg-primary text-white rounded-lg px-4 py-2 hover:shadow-xl hover:shadow-gray-300 active:font-semibold"
        >
          <div className='flex gap-3'>
            {t('Auth.PasswordRecovery.meta')} {loadingResetPass && <Spin />}
          </div>
        </button>
      </div>
    </form>
  );
};
