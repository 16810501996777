import { useContext } from 'react';
import { PortraitItem } from './PortraitItem';
import { FolderContext } from '../../contexts/folderContext';
import { compairDates } from '../../config/utils';

export function PortraitList({ items, options, editPortrait }) {
  const classMiniature = 'flex gap-12 flex-wrap px-1';
  const classList = 'flex flex-col gap-3 px-1';
  //context
  const { itemsDisplay } = useContext(FolderContext);

  return (
    <div className={itemsDisplay === 'list' ? classList : classMiniature}>
      {items
      ?.sort((a, b) => compairDates(b.created_at, a.created_at))
      .map((file, index) => (
        <PortraitItem
          options={options}
          key={`${file.id}${index}`}
          file={file}
          editPortrait={editPortrait}
        />
      ))}
    </div>
  );
}
