import { useTranslation } from 'react-i18next';
import { Progress } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { renderQueue } from '../../../features/videos/videosSlice';

export const RenderProgressModal = () => {
  const { t } = useTranslation();
  const renders = useSelector(renderQueue)

  if (renders.length === 0) return null;

  return (
    <div className="flex flex-col rounded-xl border-[1px] border-gray-200 bg-white overflow-hidden shadow-md">
      <h3 className="mt-2 mx-5 text-sm font-semibold">
        {t('Message.video.progress_modal.message4')}
      </h3>
      <span className="text-xs mb-2 mx-5 text-gray-600">
        {renders.length} {renders.length === 1 ? 'video' : 'videos'}
      </span>
			<Progress size='xs' isIndeterminate colorScheme='green' />
    </div>
  );
};
