import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  videoStep: 0, //posicion entre los 5 pasos del modulo video
  /**
   * Este es el historial con los videos en distintos momentos,
   * la configuración del editor(currentVideoIndex, volumen, etc),
   * la configuración de textos superpuestos
   */
  history: [], // Videos del editor
  historyEditorConfig: [], // Configuración del editor
  historyTexts: [], // Configuración de los textos superpuestos
  currentHistoryIndex: -1, // Para moverme entre los distintos momentos del historial al deshacer y rehacer
  // Esta es la carpeta y el nombre con el que se guardará el video
  videoData: {
    folderId: null,
    videoName: 'Nuevo video',
  },
  //Parametros de edicion de landing
  editLanding: {
    template: 0,
    config: {
      texts: { title: '', message: '' },
      pdf_links: {
        pdf: {
          fileName: null,
          file: null,
          dowloadButton: {
            text: 'Descargar',
            textColor: '#FFFFFF',
            buttonColor: '#f73757',
          },
        },
        links: {
          link: [{ textLink: 'Texto enlace', urlLink: '' }],
          colorButtonsLinks: '#f73757',
          check: '1',
        },
      },
      contacts: { whatsapp: '', phone: '', email: '' },
      images: { firstLogo: null, secondLogo: null, mainImage: null },
    },
  },
  selectedContacts: [],
  // El objeto que contiene los datos de la portada, id, src, etc.
  frontPage: {},
  // El id del video ya renderizado con todo
  createdVideoData: null,
  // Almacenar una cola de renders, para mostrar en toda la app
  // los renders que se estan haciendo
  renderQueue: [],
  // los archivos que se estan subiendo
  uploadingFiles: [],
};

export const videosSlice = createSlice({
  name: 'videos',
  initialState,

  reducers: {
    // logout
    logout: () => initialState,

    //Accion para cambiar entre los steps pasandole un valor
    changeStep: (state, action) => {
      state.videoStep = action.payload;
    },

    // Acciones del editor
    save: (state, action) => {
      const { newVideos, config } = action.payload;
      // Guardar videos
      state.history.push(newVideos);
      // Guardar configuración de editor
      state.historyEditorConfig.push(config);

      // Guardando los textos actuales en el nuevo memento
      const texts = state.historyTexts[state.currentHistoryIndex];
      state.historyTexts.push(texts);

      // Cambiar memento
      state.currentHistoryIndex = state.history.length - 1;
    },

    // Para guardar solamente los textos
    saveTexts: (state, action) => {
      const texts = action.payload;

      if (texts) {
        const videos = state.history[state.currentHistoryIndex];
        const config = state.historyEditorConfig[state.currentHistoryIndex];

        state.history.push(videos);
        state.historyEditorConfig.push(config);
        state.historyTexts.push(texts);

        state.currentHistoryIndex = state.historyTexts.length - 1;
      }
    },

    saveVideoData: (state, action) => {
      const { folderId, videoName } = action.payload;
      state.videoData.folderId = folderId;
      state.videoData.videoName = videoName.length > 0 ? videoName : state.videoData.videoName;
    },

    /**
     * Esto es para las imagenes que se reproducen, una vez que se
     * renderiza el clip a partir de la imagen que se sube debe cambiarsele
     * el id provisional que se le pone, por el id del clip renderizado en el back
     * pero para hacerlo de forma asincrona se utilizó esto. Asi no hay que esperar
     * a que se renderice el clip a partir de la imagen para esperear el id
     */
    setRealIdToImage: (state, action) => {
      const presentHistory = state.history[state.currentHistoryIndex];
      const imageIndex = presentHistory.findIndex((elem) => elem.id === action.payload.tempId);

      // Es un for porque pudo haberse recortado la imagen en lo que se creaba el clip
      presentHistory.forEach((elem, index) => {
        if (elem.id === action.payload.tempId) {
          const imageElem = Object.assign({}, elem)

          const realClip = {
            ...imageElem,
            src: action.payload.realFile,
            id: action.payload.realId,
            type: 'video',
            isLoading: false,
          }

          presentHistory.splice(index, 1);
          presentHistory.splice(index, 0, realClip);

          const presentHistoryCopy = JSON.parse(JSON.stringify(presentHistory));

          state.history.splice(state.currentHistoryIndex, 1);
          state.history.splice(state.currentHistoryIndex, 0, presentHistoryCopy);
        }
      })
    },

    undo: (state) => {
      if (state.currentHistoryIndex > 0) {
        state.currentHistoryIndex--;
      }
    },

    redo: (state) => {
      if (state.currentHistoryIndex < state.history.length - 1) {
        state.currentHistoryIndex++;
      }
    },

    redoOriginal: (state) => {
      if (state.currentHistoryIndex > 0) {
        const originalVideos = state.history[0];
        const originalConfig = state.historyEditorConfig[0];
        const originalTexts = state.historyTexts[0];

        state.history.push(originalVideos);
        state.historyEditorConfig.push(originalConfig);
        state.historyTexts.push(originalTexts);

        state.currentHistoryIndex = state.history.length - 1;
      }
    },

    // Para agregar una portada
    saveFrontPage: (state, action) => {
      state.frontPage = action.payload;
    },

    //Acciones para la edicion de landings
    changeTemplate: (state, action) => {
      state.editLanding.template = action.payload;
    },

    changeLandingConfig: (state, action) => {
      state.editLanding.config = action.payload;
    },

    changeLogos: (state, action) => {
      state.editLanding.config.images = action.payload;
    },

    changePdfFile: (state, action) => {
      state.editLanding.config.pdf_links.pdf.file = action.payload;
    },

    //Aciones para el step de contactos
    changeSelectedContacts: (state, action) => {
      state.selectedContacts = action.payload;
    },

    setCreatedVideoData: (state, action) => {
      state.createdVideoData = action.payload;
    },

    resetState: (state) => {
      state.videoStep = 0;
      state.history = [];
      state.historyEditorConfig = [];
      state.historyTexts = [];
      state.currentHistoryIndex = -1;
      state.videoData = {
        folderId: null,
        videoName: 'Nuevo video',
      };
      state.editLanding = {
        template: 0,
        config: {
          texts: { title: '', message: '' },
          pdf_links: {
            pdf: {
              file: null,
              fileName: null,
              dowloadButton: {
                text: 'Descargar',
                textColor: '',
                buttonColor: '',
              },
            },
            links: {
              link: [{ textLink: 'Texto enlace', urlLink: '' }],
              colorButtonsLinks: '',
              check: '1',
            },
          },
          contacts: { whatsapp: '', phone: '', email: '' },
          images: { firstLogo: null, secondLogo: null, mainImage: null },
        },
      };
      state.selectedContacts = [];
      state.frontPage = {};
      state.createdVideoData = null;
    },

    // agregar y eliminar en la cola de renders
    addToRenderQueue: (state, action) => {
      state.renderQueue.push(action.payload);
    },
    
    removeFromRenderQueue: (state, action) => {
      const index = state.renderQueue.findIndex((elem) => elem.id === action.payload);
      state.renderQueue.splice(index, 1);
    },

    // definir archivos que se estan subiendo
    setUploadingFiles: (state, action) => {
      state.uploadingFiles = action.payload;
    },
    
    // actualizar progreso de los archivos en subida
    updateUploadingFiles: (state, action) => {
      const { id, progress, total } = action.payload;
      const index = state.uploadingFiles.findIndex((elem) => elem.id === id);
      if (index === -1) return;
      state.uploadingFiles[index].progress = progress;
      state.uploadingFiles[index].total = total;
    },
    
    // agregar (uno o varios), y eliminar un elemento a la cola de subida
    addToUploadingFiles: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.uploadingFiles = state.uploadingFiles.concat(action.payload);
        return;
      }
      state.uploadingFiles.push(action.payload);
    },

    removeFromUploadingFiles: (state, action) => {
      const index = state.uploadingFiles.findIndex((elem) => elem.id === action.payload);
      state.uploadingFiles.splice(index, 1);
    },

    cancelUpload: (state, action) => {
      const index = state.uploadingFiles.findIndex((elem) => elem.id === action.payload);
      if (index === -1) return;
      state.uploadingFiles[index].cancelled = true;
    }
  },
});

export const selectVideos = (state) => state.videos.history[state.videos.currentHistoryIndex];

export const selectEditorConfig = (state) =>
  state.videos.historyEditorConfig[state.videos.currentHistoryIndex];

export const selectTexts = (state) => state.videos.historyTexts[state.videos.currentHistoryIndex];

export const selectVideoStep = (state) => state.videos.videoStep;

export const selectLandingConfig = (state) => state.videos.editLanding.config;

export const selectEditLanding = (state) => state.videos.editLanding;

export const selectPdfData = (state) => state.videos.editLanding.config.pdf_links.pdf;

export const selectFrontPage = (state) => state.videos.frontPage;

export const selectVideoData = (state) => state.videos.videoData;

export const templateSelected = (state) => state.videos.editLanding.template;

export const selectCreatedVideoData = (state) => state.videos.createdVideoData;

export const renderQueue = (state) => state.videos.renderQueue;

export const uploadingFiles = (state) => state.videos.uploadingFiles;

export const {
  logout,
  changeStep,
  save,
  saveVideoData,
  setRealIdToImage,
  saveTexts,
  undo,
  redo,
  redoOriginal,
  saveFrontPage,
  changeTemplate,
  changeLandingConfig,
  changeLogos,
  changePdfFile,
  changeSelectedContacts,
  setCreatedVideoData,
  resetState,
  addToRenderQueue,
  removeFromRenderQueue,
  setUploadingFiles,
  updateUploadingFiles,
  addToUploadingFiles,
  removeFromUploadingFiles,
  cancelUpload,
} = videosSlice.actions;

export default videosSlice.reducer;
