import { changeSelectedContacts, changeStep } from "../../../../features/videos/videosSlice";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ContactsFilters from "./ContactsTable/ContactsFilters";
import { Table } from "../../../../components/common";
import { useContactsStepContext } from "./context/ContactsStepContext";
import { IoIosArrowBack } from "react-icons/io";
import { classNames, compairArrays } from "../../../../config/utils";
import { useVideo } from "../../context/VideoContext";


const ContactsTable = () => {
  // states
  const selectedContacts = useSelector((state) => state.videos.selectedContacts);
  const [selectedItems, setSelectedItems] = useState(selectedContacts || [])

  // hooks
  const { saveLogosAndPdf, backStep, nextStep } = useVideo();
  const { clients, loadingClients } = useContactsStepContext();
  const { bodyTable, orderTable } = useContactsStepContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // constants
  const headersTable = useMemo(() => [
    {
      title: t('Contact.table.name'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
    },
    {
      title: t('Contact.table.last_name'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
    },
    {
      title: t('Contact.table.email'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
      maxWidth: '200px',
    },
    {
      title: t('Contact.table.sent_videos'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
      textWrap: true,
      maxWidth: '100px',
    },
    {
      title: t('Contact.table.last_send'),
      dropdown: true,
      sort: true,
      resizable: false,
      type: 'text',
      textWrap: true,
      maxWidth: '100px',
    },
    {
      title: t('Contact.table.info'),
      dropdown: false,
      sort: false,
      resizable: false,
      type: 'text',
    },
  ], []);
  
  const handlePrev = () => {
    backStep();
  };

  const handleNext = () => {
    nextStep();
    // Mandar iniciar el proceso de renderizado, que empieza por la subida de los archivos
    saveLogosAndPdf();
  };

  const selectedItemsFromTable = useMemo(() => {
    return clients?.filter(client => selectedItems?.map(item => item.id)?.includes(client.id)) 
    || []
  }, [clients, selectedItems]);

  // effects
  useEffect(() => {
    if (compairArrays(selectedItemsFromTable, selectedContacts)) return;
    
    dispatch(changeSelectedContacts(selectedItemsFromTable));
  }, [dispatch, selectedItemsFromTable]);

  useEffect(() => {
    setSelectedItems(selectedContacts);
  }, [selectedContacts]);
  
  return (
    <div className="relative flex flex-col w-full h-full space-y-3">
      {/* filter group */}
      <ContactsFilters />

      <div className='h-[20em]'>
        <Table
          header={headersTable}
          body={bodyTable}
          hasCheckable="true"
          selectedItems={selectedItems}
          clients={clients}
          onSelect={setSelectedItems}
          onOrder={orderTable}
          isLoading={loadingClients}
        />
      </div>

      {/* info */}
      <div className="flex items-center py-2 rounded-xl">
        <span className="text-gray-400 text-sm">
          {clients.length} {t('Contact.meta').toLocaleLowerCase()}
        </span>
        {selectedItems?.length > 0 && <span className="text-gray-400 text-sm">
          , {selectedItems.length} {t('Contact.selected')}
        </span>}
      </div>

      {/* Buttons */}
      <div className="flex pt-2 2xl:pt-9">
        <button
          type="button"
          className="flex items-center justify-center  h-10 2xl:h-12 px-2 mr-4 rounded-xl  text-gray-300 border-2 border-gray-300 font-semibold text-lg hover:shadow-lg active:scale-[0.98]"
          onClick={handlePrev}
        >
          <IoIosArrowBack className="text-xl 2xl:text-3xl" />
        </button>
        <button
          type="button"
          className={classNames(
            'flex items-center justify-center h-10 2xl:h-12 px-4 rounded-xl  text-white font-semibold text-sm 2xl:text-lg hover:shadow-lg active:scale-[0.98]',
            selectedContacts.length ? 'bg-primary' : 'bg-gray-400 cursor-not-allowed',
          )}
          onClick={handleNext}
          disabled={selectedContacts.length === 0}
        >
          {t('Contact.save_continue')}
        </button>
      </div>
    </div>
  );
};

export default ContactsTable;