import { useEffect, useRef, useState } from 'react';
import useRecordContext from '../context/useRecordContext';
import { classNames } from '../../../../config/utils';
import { Spin } from '../../../../components/common';
import { useQueryClient } from 'react-query';

function formatTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds} min`;
}

const RecordCountdown = ({ showPreviewModal }) => {
  const { isActive, isPaused, isInitializing, handleStopRecording, handleGetDuration } = useRecordContext();
  
  // hooks
  const queryClient = useQueryClient();

  // rq
  const systemInfo = queryClient.getQueryData('systemInfo');
  const timeLimitForRecord = (systemInfo?.max_tiempo_grabacion || 0)

  // states
  const [countdown, setCountdown] = useState(timeLimitForRecord * 60);
  const isPauseRef = useRef(isPaused);

  const handleStop = () => {
    if (!isActive) return;
    handleStopRecording();
    showPreviewModal();
  };

  useEffect(() => {
    isPauseRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    let timer;
    `flex items-center ${
      isActive && 'bg-gray-200 hover:shadow-lg cursor-pointer active:scale-[0.97]'
    } p-2 px-3 rounded-lg mr-2`;

    if (isActive && !isPaused && !isInitializing) {
      timer = setInterval(() => {
        if (!isPauseRef.current) {
          setCountdown(timeLimitForRecord * 60 - handleGetDuration());
        }
      }, 200);
    }

    if (!isActive) {
      setCountdown(timeLimitForRecord * 60);
    }

    if (countdown <= 0) {
      handleStopRecording();
      showPreviewModal();
    }

    return () => {
      clearInterval(timer);
    };
  }, [isActive, isPaused, countdown, handleStopRecording, isInitializing]);

  return (
    <div
      className={classNames(
        'flex items-center',
        isActive && 'bg-gray-200 hover:shadow-lg cursor-pointer active:scale-[0.97]',
        'p-2 px-3 rounded-lg mr-2',
        isPaused && 'animate-pulse',
      )}
      onClick={handleStop}
    >
      {isInitializing ? (
        <Spin color='red' />
      ) : (
        <>
          <div
            className={`${isActive ? 'flex' : 'hidden'} w-6 h-6 mr-2 bg-primary rounded-md`}
          ></div>
          <div className={`text-xl ${!isActive && 'cursor-default'}`}>{formatTime(isActive ? countdown +1 : countdown)}</div>
        </>
      )}
    </div>
  );
};

export default RecordCountdown;
