import { Menu, Transition } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  LinkIcon,
  PaperAirplaneIcon,
  PlayCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { classNames, copyToClipboard, generateImageUrl } from '../../config/utils';
import { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { HiOutlinePlayCircle } from 'react-icons/hi2';
import { FolderContext } from '../../contexts/folderContext';
import { querys } from '../../pages/Library/tools/querys';
import useAuthContext from '../../hooks/useAuthContext';
import { VideoUpdateByLibrary } from '../../services/video/VideoService';
import { Skeleton, DialogV2 } from '../../components/common';
import { useEffect } from 'react';
import { getTypeFilesByCategory } from '../../pages/Library/tools/types';
import { DownloadLandingLinks } from '../../services/video/VideoService';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../hooks/useToast2';
import Routes, { BASE_URL } from '../../routes/app/paths';
import { Menu as MenuChakra, MenuButton, IconButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import SendVideo from './Video/SendVideo';
import { useLocation, useNavigate } from 'react-router-dom';
import ClientsMenu from './VideoItemElements/ClientsMenu';
import RenderBadge from './VideoItemElements/RenderBadge';

export function VideoItem({ file }) {
  /* STATES */
  const [toggleRename, setToggleRename] = useState(false);
  const [rename, setRename] = useState({
    name: '',
    id: file.id,
    action: 'rename',
  });
  const [name, setName] = useState({
    prevName: file.title,
    newName: file.title,
  });
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);

  const id = file.id;

  //context
  const { user } = useAuthContext();
  const {
    itemsDisplay,
    toggleArchive,
    library,
    filesSelected,
    addFilesSelected,
    addFilesLoading,
    removeFilesSelected,
    filesLoading,
    setModals,
    setDeleteItemsProps
  } = useContext(FolderContext);

  //hooks
  const inputRef = useRef(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useToast2();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idParam = Number(searchParams.get('id'));

  //const
  const menuItems = useMemo(() => {
    const data = !toggleArchive
    ? [
        {
          id: 1,
          title: t('Library.menu.rename'),
          type: 'rename',
        },
        {
          id: 2,
          title: t('Library.menu.reuse'),
          type: 'edit',
        },
        {
          id: 3,
          title: t('Library.menu.stadistics'),
          type: 'stadistics',
          disabled: true,
        },
        {
          id: 5,
          title: toggleArchive ? t('Library.menu.unarchive') : t('Library.menu.archive'),
          type: 'archive',
        },
        {
          id: 6,
          title: t('Library.menu.delete'),
          type: 'delete',
        },
      ]
    : [        
        {
          id: 5,
          title: toggleArchive ? t('Library.menu.unarchive') : t('Library.menu.archive'),
          type: 'archive',
        },
        {
          id: 6,
          title: t('Library.menu.delete'),
          type: 'delete',
        },
      ];

    const itemsOnlyLibrary = [2, 3]

    return data.filter((item) => library !== 'video' && !toggleArchive 
      ? !itemsOnlyLibrary.includes(item.id) 
      : item);
  }, [toggleArchive, library]);

  const videoDate = moment(file.created_at).format('DD/MM/YYYY');

  const {
    changeStatusVideo,
    deleteFilesQuery,
    changeStatusClip,
    changeStatusRecording,
    changeStatusPortrait,
  } = querys();

  const { mutate: mutateStatusVideo } = changeStatusVideo(library);
  const { mutate: mutateDeleteFile } = deleteFilesQuery(library);
  const { mutate: mutateStatusClip } = changeStatusClip(library);
  const { mutate: mutateStatusRecording } = changeStatusRecording(library);
  const { mutate: mutateStatusPortrait } = changeStatusPortrait(library);

  /* REACT QUERY */
  const { mutate, isLoading: isLoadingRenameFolder } = useMutation((opt) => VideoUpdateByLibrary(opt), {
    onSuccess: () => {
      setTimeout(() => {
        setRename({
          action: 'rename',
          name: '',
          id,
        });
        setName((prev) => ({ ...prev, prevName: prev.newName }));
      }, 1000);
      queryClient.invalidateQueries(library);
      queryClient.invalidateQueries(`${library}_arch`);
    },
    onError: () => {
      setRename({
        action: 'rename',
        name: '',
        id,
      });
      setError(true);
      queryClient.invalidateQueries(library);
      queryClient.invalidateQueries(`${library}_arch`);
    },
  });

  //handlers
  const handleRename = () => {
    setToggleRename(false);
    if (name.newName.length === 0) return setName((prev) => ({ ...prev, newName: prev.prevName }));

    if (rename.name.length > 0) {
      //normal rename
      const videoUpdated = Object.assign(file, { title: rename.name });
      mutate({
        library,
        token: user.access_token,
        video: {
          ...videoUpdated,
          thumbnail: undefined,
        },
      });
    }
  };

  const startRename = () => {
    setToggleRename(true);
    setRename({ ...rename, id });
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  };

  const handleOnChangeName = (e) => {
    setName({ ...name, newName: e.target.value });
    setRename({ ...rename, name: e.target.value });
  };

  const handleSelectFile = () => {
    if (filesSelected.includes(id)) return removeFilesSelected(id);
    addFilesSelected(id);
  };

  const handleArchive = () => {
    switch (library) {
      case 'video':
        mutateStatusVideo({ token: user.access_token, videoId: id });
        addFilesLoading(id);
        break;
      case 'corporative':
        mutateStatusClip({ token: user.access_token, clipId: id });
        addFilesLoading(id);
        break;
      case 'record':
        mutateStatusRecording({ token: user.access_token, recordingId: id });
        addFilesLoading(id);
        break;
      case 'portrait':
        mutateStatusPortrait({ token: user.access_token, portraitId: id });
        addFilesLoading(id);
        break;
      default:
        break;
    }
  };

  const handleDelete = () => {
    setDeleteItemsProps((prev) =>
    ({
      ...prev,
      label: t('Library.the_video'),
      params: {
        foldersSelected: [],
        filesSelected: [file.id],
        type: getTypeFilesByCategory(library),
      }
    }))
    setModals((prev) => ({...prev, deleteItems: true}))
  };

  const handleCopyUrl = (token) => {
    copyToClipboard(`${BASE_URL}/landing/${token}`)
      ? showToast({ show: true, type: 'success', message: t('Message.copy.success', { text: 'URL' }) })
      : showToast({ show: true, type: 'error', message: t('Message.copy.error', { text: 'URL' }) })
  }

  const downloadXlsx = (id) => {
    DownloadLandingLinks({
      token: user?.access_token,
      videoId: id
    })
      .then((blob) => {
        const url = URL.createObjectURL(blob.data);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'landing-links.xlsx';
        document.body.appendChild(link);

        // Simula un clic en el enlace para descargar el archivo
        link.click();

        // Elimina el objeto URL y el enlace del DOM
        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });      
  }

  //effects
  useEffect(() => {
    setName({
      prevName: file.title,
      newName: file.title,
    });
  }, [file]);

  const isAgente = user?.role === 'Agente';
  const isCorporative = library === 'corporative';

  return itemsDisplay === 'list' ? (
    <div id={id} className={classNames("relative bg-gray-100 py-6 px-4 rounded-lg",
    idParam === id && 'border-[1px] border-primary shadow-md shadow-primary')}>
      <div className="flex items-center justify-between">
        <div className="flex truncate flex-1 items-center justify-between"
          style={{
            maxWidth: 'calc(100% - 50px)',
          }}
        >
          <div className="flex truncate">
            <div className='h-auto flex items-center'>
              <input
                type="checkbox"
                className="rounded w-5 h-5 focus:ring-0 focus:outline-none outline-none ring-0  text-primary bg-white border-[1px] border-gray-400 focus:border-[1px] cursor-pointer shadow-md disabled:opacity-0 disabled:cursor-default"
                disabled={isAgente && isCorporative}
                checked={filesSelected.includes(id)}
                onChange={handleSelectFile}
              />
            </div>
            <div
              className="px-6 ml-6 border rounded-lg bg-black py-2 cursor-pointer hover:shadow-md hover:shadow-gray-400"
              onClick={() => setShowPreview(true)}
              style={{
                backgroundImage: `url(${generateImageUrl(file.thumbnail)})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <HiOutlinePlayCircle className="text-4xl text-gray-400" />
            </div>
            <div className="ml-6 select-none truncate">
              {/* toggle the classname, to preserve the ref */}
              <input
                ref={inputRef}
                type="text"
                className={classNames(
                  'border-none bg-gray-video focus:ring-0 focus:bg-blue-400/30 px-0 py-0 focus:text-black text-black font-bold text-sm 2xl:text-md w-full',
                  toggleRename ? 'block' : 'hidden',
                )}
                placeholder={name.newName}
                name={name.newName}
                value={name.newName}
                onChange={handleOnChangeName}
                /* press enter to handleRename */
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleRename();
                  }
                }}
                onBlur={handleRename}
                disabled={isLoading}
              />
              <p
                className={classNames(
                  'font-bold text-md truncate overflow-hidden overflow-ellipsis max-w-[500px] 2xl:max-w-[900px]',
                  toggleRename ? 'hidden' : 'block',
                  error && 'text-red-error',
                )}
              >
                {file.title}
              </p>
              <p className="font-light text-xs">{videoDate}</p>
            </div>
          </div>

          {/* badge to re-render */}
          {file.id && 
            <div className="flex items-center">
              <RenderBadge id={file.id} renderized={file.renderized} processing={file.processing} />
            </div>
          }
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button disabled={isAgente && isCorporative} className="inline-flex w-full justify-center gap-x-1.5 rounded-full hover:shadow-md text-sm font-semibold text-gray-900 disabled:opacity-40 disabled:cursor-default">
              <EllipsisHorizontalIcon className="w-6 h-6 font-bold" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute p-2 right-0 z-10 mt-2 w-36 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {menuItems.map((item) => (
                  <Menu.Item key={item.id} className={classNames("rounded-md pl-6", !item.disabled && 'hover:bg-gray-menu-shadow')} disabled={item.disabled}>
                    {({ active }) => (
                      <span
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : item.disabled ? 'text-gray-400 cursor-default' : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer',
                        )}
                        onClick={() => {
                          switch (item?.type) {
                            case 'rename':
                              startRename();
                              break;
                            case 'edit':
                              navigate(`${Routes.reuseVideos}/${file.id}`)
                              break;
                            case 'stadistics':
                              break;
                            case 'move':
                              break;
                            case 'archive':
                              handleArchive();
                              break;
                            case 'delete':
                              handleDelete();
                              break;

                            default:
                              break;
                          }
                        }}
                      >
                        {item.title}
                      </span>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {/* loader to async operations */}
      <div
        className={classNames(
          'absolute w-full bottom-0 inset-x-0 transition-all duration-150 overflow-hidden',
          filesLoading.includes(id) || error ? 'h-full' : 'h-0',
        )}
      >
        {filesLoading.includes(id) && <Skeleton />}
        {error && <div className="w-full h-full animate-pulse bg-red-error" />}
      </div>
      <DialogV2 open={showPreview} onClose={() => setShowPreview(false)}>
        {file.file ? (
          <div className="relative w-full h-full mt-1">
            <video
              className="w-full h-full"
              controls
              autoPlay
              src={generateImageUrl(file.file)}
              type="video/mp4"
            />
          </div>
        ) : (
          <div className="relative w-full h-full">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-2xl font-bold text-gray-500">{t('Message.video.error.cant_not_play')}</p>
              <p className="text-sm font-light text-gray-500">{t('Message.video.error.not_found')}</p>
            </span>
          </div>
        )}
      </DialogV2>
    </div>
  ) : (
    <div id={id} className={classNames("relative p-3 bg-gray-video rounded-lg min-w-[250px] max-w-[250px]", idParam === id && 'border-[1px] border-primary shadow-md shadow-primary')}>
      <div
        className="bg-white w-full h-[160px] rounded-lg p-2"
        style={{
          backgroundImage: `url(${generateImageUrl(file.thumbnail)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className=" flex justify-between h-[56px] items-start">
          <input
            type="checkbox"
            className="rounded w-5 h-5 focus:ring-0 focus:outline-none outline-none ring-0  text-primary bg-white border-[1px] border-gray-400 focus:border-[1px] cursor-pointer shadow-md disabled:opacity-0 disabled:cursor-default"
            disabled={isAgente && isCorporative}
            onChange={handleSelectFile}
            checked={filesSelected.includes(id)}
          />
          {file?.clients?.length > 1 && (
            <div className="flex flex-col items-end space-y-2">
              <ClientsMenu clients={file.clients} handleCopyUrl={handleCopyUrl} />
              <ArrowDownTrayIcon className="w-6 h-6 p-1 rounded-full bg-gray-video cursor-pointer hover:text-primary" 
                onClick={() => downloadXlsx(file.id)}
              />
            </div>
          )} 
          { file?.clients?.length === 1 && (
            library === 'video' && (
              <div className="flex flex-col items-end space-y-2"
              >
                <IconButton
                  aria-label='Options'
                  icon={<LinkIcon className="w-6 h-6 p-1 rounded-full bg-gray-video cursor-pointer hover:text-primary" />}
                  size={'xs'}
                  borderRadius='full'
                  onClick={() => handleCopyUrl(file.clients[0].token)}
                />
              </div>
            )
          )}
        </div>
        <div
          className="flex flex-1 align-middle self-center justify-center items-center"
          onClick={() => setShowPreview(true)}
        >
          <PlayCircleIcon className="w-9 h-9  text-black-icon cursor-pointer hover:text-gray-500" />
        </div>
      </div>

      {/* badge to re-render */}
      {file.id && 
        <div className="absolute right-5 top-[140px]">
          <RenderBadge id={file.id} renderized={file.renderized} processing={file.processing} />
        </div>
      }

      <div className="flex justify-between items-start mt-[10px]">
        <div>
          {/* toggle the classname, to preserve the ref */}
          <input
            ref={inputRef}
            type="text"
            className={classNames(
              'border-none bg-gray-video focus:ring-0 focus:bg-blue-400/30 px-0 py-0 focus:text-black text-black font-bold text-sm 2xl:text-md w-full',
              toggleRename ? 'block' : 'hidden',
            )}
            placeholder={name.newName}
            name={name.newName}
            value={name.newName}
            onChange={handleOnChangeName}
            /* press enter to handleRename */
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRename();
              }
            }}
            onBlur={handleRename}
            disabled={isLoading}
          />
          <p
            className={classNames(
              'font-bold text-md whitespace-normal overflow-hidden overflow-ellipsis max-w-[100px] 2xl:max-w-[220px]',
              toggleRename ? 'hidden' : 'block',
              error && 'text-red-error',
            )}
          >
            {file.title}
          </p>
          <p className="font-light text-xs">{videoDate}</p>
        </div>
        <div>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button disabled={isAgente && isCorporative} className="inline-flex w-full justify-center gap-x-1.5 text-sm font-semibold text-gray-900 rounded-full hover:shadow-md disabled:opacity-40 disabled:cursor-default">
                <EllipsisHorizontalIcon className="w-6 h-6 font-bold" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute p-2 right-0 z-10 mt-2 w-36 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {menuItems.map((item) => (
                    <Menu.Item key={item.id} className={classNames("rounded-md pl-6", !item.disabled && 'hover:bg-gray-menu-shadow')} disabled={item.disabled}>
                      {({ active }) => (
                        <span
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : item.disabled ? 'text-gray-400 cursor-default' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer',
                          )}
                          onClick={() => {
                            switch (item?.type) {
                              case 'rename':
                                startRename();
                                break;
                              case 'edit':
                                navigate(`${Routes.reuseVideos}/${file.id}`)
                                break;
                              case 'stadistics':
                                break;
                              case 'move':
                                break;
                              case 'unarchive':
                                handleArchive();
                                break;
                              case 'archive':
                                handleArchive();
                                break;
                              case 'delete':
                                handleDelete();
                                break;

                              default:
                                break;
                            }
                          }}
                        >
                          {item.title}
                        </span>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {library === 'video' && (
        <div className="mt-4">
          <Button className="flex items-center py-1.5 bg-primary px-4 rounded-md text-sm"
            leftIcon={<PaperAirplaneIcon className="w-6 h-6" />}
            onClick={() => setCurrentVideoId(file.id)}
            colorScheme={'primary'}
            color='white'
          >
            {t('Library.send')}
          </Button>
        </div>
      )}
      
      {/* loader to async operations */}
      <div
        className={classNames(
          'absolute w-full bottom-0 inset-x-0 transition-all duration-150 overflow-hidden',
          filesLoading.includes(id) || error ? 'h-full' : 'h-0',
        )}
      >
        {filesLoading.includes(id) && <Skeleton />}
        {error && <div className="w-full h-full animate-pulse bg-red-error" />}
      </div>
      <DialogV2 open={showPreview} onClose={() => setShowPreview(false)}>
        {file.file ? (
          <div className="relative w-full h-full mt-1">
            <video
              className="w-full h-full"
              controls
              autoPlay
              src={generateImageUrl(file.file)}
              type="video/mp4"
              // close modal when video ends
              onEnded={() => setShowPreview(false)}
            />
          </div>
        ) : (
          <div className="relative w-full h-full">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-2xl font-bold text-gray-500">{t('Message.video.error.cant_not_play')}</p>
              <p className="text-sm font-light text-gray-500">{t('Message.video.error.not_found')}</p>
            </span>
          </div>
        )}
      </DialogV2>
      <DialogV2 open={currentVideoId} onClose={() => {setCurrentVideoId(null)}} width='w-10/12' noScroll >
        <SendVideo id={currentVideoId} onClose={() => {setCurrentVideoId(null)}} />
      </DialogV2>
    </div>
  );
}
