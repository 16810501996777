import { createSlice } from '@reduxjs/toolkit';
import {
  fetchDataDelete,
  fetchDataPOST,
  fetchDataPOSTDUPLICATE,
  fetchDataPUTArchived,
  fetchDataPUTMove,
} from '../../pages/Library/tools/fetchData';
import { queryClient } from '../../Providers';

const initialState = {
  step: 1,
  videoUploadState: false,

  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  action: '',
  typeLibrary: '',
  folders: [],
  listVideos: false,
  archived: [],
  toggleArchived: false,
  tabsSelected: [
    {
      id: 1,
      title: 'Videos',
      selected: true,
    },
    {
      id: 2,
      title: 'Archivados',
      selected: false,
    },
  ],
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    logout: () => initialState,
    setAction: (state, action) => {
      // state.action = action.payload;
      switch (action.payload.action) {
        case 'activeArchived':
          state.toggleArchived = true;
          state.tabsSelected = [
            {
              id: 1,
              title: 'Videos',
              selected: false,
            },
            {
              id: 2,
              title: 'Archivados',
              selected: true,
            },
          ];
          break;
        case 'desactiveArchived':
          state.toggleArchived = false;
          state.tabsSelected = [
            {
              id: 1,
              title: 'Videos',
              selected: true,
            },
            {
              id: 2,
              title: 'Archivados',
              selected: false,
            },
          ];
          break;
        case 'archive':
          fetchDataPUTArchived(action.payload).then((response) => {
            queryClient.refetchQueries({ queryKey: action.payload.library });
          });
          break;
        case 'unarchive':
          fetchDataPUTArchived(action.payload).then((response) => {
            queryClient.refetchQueries({ queryKey: action.payload.library + 'arch' });
          });
          break;
        case 'miniature':
          state.listVideos = false;
          break;
        case 'list':
          state.listVideos = true;
          break;
        case 'move':
          fetchDataPUTMove(action.payload)
            .then((response) => {
              queryClient.refetchQueries({ queryKey: action.payload.library });
            })
            .catch((err) => console.log(err));
          break;
        case 'delete':
          fetchDataDelete(action.payload)
            .then((response) => {
              queryClient.refetchQueries({ queryKey: action.payload.library });
            })
            .catch((err) => console.log(err));
          break;
        case 'rename':
          queryClient.refetchQueries({ queryKey: action.payload.library });
          break;
        case 'duplicate':
          fetchDataPOSTDUPLICATE(action.payload)
            .then((response) => {
              queryClient.refetchQueries({ queryKey: action.payload.library });
            })
            .catch((err) => console.log(err));
          break;

        default:
          break;
      }
    },
    setUploadingVideoState: (state, action) => {
      state.videoUploadState = action.payload;
    },
    handleAddFolder: (state, action) => {
      fetchDataPOST(action.payload)
        .then((response) => {
          queryClient.refetchQueries({ queryKey: action.payload.library });
        })
        .catch((error) => console.log(error));
    },
    setTypeLibrary: (state, action) => {
      state.typeLibrary = action.payload;
    },
    setArchived: (state, action) => {
      state.archived = action.payload;
    },
    setFolders: (state, action) => {
      state.folders = action.payload;
    },
  },
});

export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    //dispatch(incrementByAmount(amount));
  }, 1000);
};

/* Selectors */
export const action = (state) => state.library.action;
export const uploadVideoStatus = (state) => state.library.videoUploadState;
export const typeLibrary = (state) => state.library.typeLibrary;
export const folders = (state) => state.library.folders;
export const listVideos = (state) => state.library.listVideos;
export const archived = (state) => state.library.archived;
export const toggleArchived = (state) => state.library.toggleArchived;
export const tabsSelected = (state) => state.library.tabsSelected;

/* Actions */
export const {
  logout,
  setAction,
  setUploadingVideoState,
  handleAddFolder,
  setTypeLibrary,
  setFolders,
  setArchived,
} = librarySlice.actions;
export default librarySlice.reducer;
