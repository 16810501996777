import { useTranslation } from 'react-i18next';
import '../../config/i18next-config';

import changePic from '../../assets/img/pass-change.png'
import logo from '../../assets/img/logo@3x.png'
import { ChangeForm } from './form'


export const PassChange = () => {
    const { t } = useTranslation();
    return (
        <div className="w-screen h-screen flex select-none">
            <div className="w-1/2 flex flex-grow flex-shrink shadow-right">
                <div className="flex flex-col justify-center items-center space-y-0">
                    <div className="flex flex-col items-center">
                        <h2 className="text-3xl font-bold text-gray-800">{t('Auth.PasswordChange.title1')}</h2>
                        <h2 className="text-3xl font-bold text-primary">{t('Auth.PasswordChange.title2')}</h2>
                    </div>
                    <img src={changePic} alt="change pass" className="w-2/4 object-fit object-center" />
                    <img src={logo} alt="logo" className="w-1/5 pt-10" />
                </div>
            </div>
            <div className="w-1/2 flex">
                <div className="w-full flex flex-col justify-center items-center">
                    <ChangeForm  />
                </div>
            </div>
        </div>
    )
}