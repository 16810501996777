// @flow

import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, useLayoutEffect, useState } from 'react';

const Ctx = React.createContext();

// Styled Components
// ==============================

const Toast = ({ onDismiss, title, type, message }) => {
  const [show, setShow] = useState(false);
  let bgColor = '';
  let Icon = null;
  switch (type) {
    case 'error':
      bgColor = 'red-500';
      Icon = XCircleIcon;
      break;
    case 'success':
      bgColor = 'green-500';
      Icon = CheckCircleIcon;
      break;
    case 'info':
      bgColor = 'blue-400';
      Icon = ExclamationCircleIcon;
      break;
    case 'warning':
      bgColor = 'yellow-400';
      Icon = ExclamationTriangleIcon;
      break;
  }

  useLayoutEffect(() => setShow(true), []);

  const hideToast = () => {
    setShow(false);
    onDismiss;
  };
  setTimeout(() => {
    hideToast();
  }, 3000);
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none  inset-0 flex items-end px-4 pb-4  sm:items-start"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-${bgColor} shadow-lg ring-1 ring-black ring-opacity-5`}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {Icon && <Icon className="h-6 w-6 text-white" aria-hidden="true" />}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium  text-white">{title}</p>
                  <p className="mt-1 text-sm  text-white">{message}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={hideToast}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className={`h-5 w-5 bg-${bgColor} text-white aria-hidden="true`} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

// Provider
// ==============================

let toastCount = 0;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const showToast = (type, title, message) => {
    const id = toastCount++;
    const toast = { type, title, message, id };
    setToasts([...toasts, toast]);
  };
  const remove = (id) => {
    const newToasts = toasts.filter((t) => t.id !== id);
    setToasts(newToasts);
  };
  // avoid creating a new fn on every render
  const onDismiss = (id) => () => remove(id);

  return (
    <Ctx.Provider value={{ showToast, remove }}>
      {children}
      <div className="w-96 absolute right-0 top-4">
        {toasts.map(({ content, id, ...rest }) => (
          <Toast key={id} onDismiss={onDismiss(id)} {...rest} />
        ))}
      </div>
    </Ctx.Provider>
  );
}

// Consumer
// ==============================

export const useToasts = () => React.useContext(Ctx);
