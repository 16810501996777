import { useState } from 'react';
import CheckList from '../../assets/img/checkList.png';
import Resume from '../../components/contacts/Resume';
import { useDispatch, useSelector } from 'react-redux';
import {
  file as fileSelector,
  finalClients,
  hasList,
  hasTags,
  previewClients,
  replace,
  resetStore,
} from '../../features/client/clientSlice';
import { useNavigate } from 'react-router-dom';
import { multiClinetsImport } from '../../services/client/ClientService';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Spin, Title } from '../../components/common';
import useAuthContext from '../../hooks/useAuthContext';
import { useToast2 } from '../../hooks/useToast2';

export function CheckImportedList() {
  const [loading, setLoading] = useState(false);
  /* Hooks */
  const items = useSelector(previewClients);
  const fClients = useSelector(finalClients);
  const isReplacing = useSelector(replace);
  const tags = useSelector(hasTags);
  const list = useSelector(hasList);
  const file = useSelector(fileSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToast2();
  const { user } = useAuthContext();

  /* Mutations */
  const mutationImportClients = useMutation('IMPORT_CLIENTS', multiClinetsImport, {
    onError: (error) => {
      setLoading(false);
      showToast({ show: true, type: 'error', message: `Error ${error.status}` });
    },
    onSuccess: ({ data }) => {
      setLoading(false);
      showToast({ show: true, type: 'success', message: t('Message.contact.success.import') });
      cleanImportedList();
    },
  });

  /* Functions */
  const cleanImportedList = () => {
    dispatch(resetStore());
    navigate(-2);
  };

  const completeImportedList = () => {
    const data = fClients.filter(fc => fc !== null && fc !== undefined);
    data.length && mutationImportClients.mutate({ token: user.access_token, data });
  };

  return (
    <>
      <div className="grid grid-cols-2 space-x-6 justify-between">
        <div className="mt-16">
          <Title title={t('Contact.import_contacts.final_step.meta')} />
          <div className="mt-10">
            <Resume />
          </div>
          <div className="flex space-x-4 mt-40 ms-1">
            <button
              onClick={cleanImportedList}
              className="flex py-2.5 px-6 text-black-soft rounded-md bg-white ring-2 ring-gray-button"
            >
              {t('Contact.import_contacts.final_step.delete')}
            </button>
            <button
              onClick={completeImportedList}
              className={`flex justify-center items-center gap-2 py-2.5 px-6 text-white rounded-md  
              ${fClients.filter(fc => fc !== null && fc !== undefined).length === 0 || mutationImportClients.isLoading ? ' bg-gray-400 cursor-not-allowed ' : ' bg-primary '}`}
              disabled={fClients.filter(fc => fc !== null && fc !== undefined).length === 0 || mutationImportClients.isLoading}
            >
              {mutationImportClients.isLoading && (
                <Spin />
              )}
              {t('Contact.import_contacts.final_step.complete')}
            </button>
          </div>
        </div>
        <div className="">
          <img src={CheckList} className="w-full mr-14" />
        </div>
      </div>
    </>
  );
}
