import {
  token,
  user,
  forgotPassword,
  resetPassword,
  confirmRegister
} from '../../routes/api/paths';
import { BASE_URL } from "../../routes/app/paths";

/**
 * @description Get token
 * @param {*} userInfo
 * @param {*} social
 * @returns data
 */
export const TokenService = async function (userInfo, social) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInfo.queryKey[0])
  }

  return await fetch(token, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        const access_token = data.access_token;
        return Promise.resolve({
          status: response.status,
          token: access_token,
        });
      }
    })
    .catch(error => {
      console.log(error);
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Log in
 * @returns user
 * @param token
 */
export const UserService = (token) => {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.queryKey[0]
    }
  }
  return fetch(user, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      const userInfo = {
        ...data,
        access_token: token.queryKey[0]
      }
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          user: userInfo
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Send email to reset password
 * @param {*} email
 * @returns status
 */
export const ForgotPasswordService = (email) => {
  const form = new FormData();
  form.append('email', email);

  const requestOptions = {
    method: 'POST',
    body: form
  }

  return fetch(forgotPassword, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Send email to reset password
 * @param {*} email
 * @returns status
 */
export const ResetPasswordService = ({ token, password }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "token": token,
      "password": password,
    })
  }
  return fetch(resetPassword, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Send email to reset password
 * @param {*} email
 * @returns status
 */
export const ConfirmRegisterService = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data.queryKey[0])
  }
  return fetch(confirmRegister, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Send email to reset password
 * @param {*} email
 * @returns status
 */
export const ValidateEmailService = (data) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return fetch(`${confirmRegister}/${data.queryKey[1].token}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}
