import { useTranslation } from 'react-i18next';
import { Title } from '../../components/common';
import { UsersList } from '../../components/Admin';
import { useEffect, useState } from 'react';
import { HOME } from '../../routes/app/paths';
import { useToast2 } from '../../hooks/useToast2';
import useAuthContext from '../../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { Tabs } from '../../components/library';

export function UsersAdmin() {
  /* State */
  const [toggleArchive, setToggleArchive] = useState(false);

  /* Hooks */
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { showToast } = useToast2();

  // effects
  useEffect(() => {
    if (user?.role !== 'Admin') {
      navigate(HOME);
      showToast({
        show: true,
        type: 'error', 
        message: t('AdminZone.error')
      });
    }
  }, [user, navigate]);

  // functions
  const handleSelect = (item) => {
    if (item.title === t('Library.tabs.archived')) {
      return setToggleArchive(true);
    }
    setToggleArchive(false);
  };

  return (
    <>
      <Title title={t('AdminZone.title')} size={'text-xl 2xl:text-3xl'} />
      <div className="mt-[28px]">
        <Tabs
          tabs={[
            { id: 1, title: 'Activos', selected: !toggleArchive },
            { id: 2, title: t('Library.tabs.archived'), selected: toggleArchive },
          ]}
          onSelect={handleSelect}
        />
      </div>
      <UsersList archived={toggleArchive} />
    </>
  );
}
