import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../config/utils';

export const ContextMenu = ({ options, open, onClose, position }) => {
  const menuRef = useRef(null);

  const handleOptionClick = (option) => {
    option.onClick();
    onClose();
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {open && (
        <div
          ref={menuRef}
          className={classNames("absolute p-2 origin-top-left rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-out duration-100 transform opacity-100 scale-100", position || 'left-0 top-0')}
        >
          {options.map((option) => (
            <div
              key={option.id}
              className="rounded-md pl-6 hover:bg-gray-menu-shadow cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              {option.title}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

ContextMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.string,
};
/* 
const FolderItem = () => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ left: 0, top: 0 });

  const handleContextMenu = (event) => {
    event.preventDefault();

    const { clientX, clientY } = event;

    setContextMenuPosition({ left: clientX, top: clientY });
    setIsContextMenuOpen(true);
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setIsContextMenuOpen(!isContextMenuOpen);
  };

  const handleCloseContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <button onClick={handleButtonClick}>
        <HiEllipsisHorizontal className="text-3xl 2xl:text-4xl font-bold cursor-pointer" />
      </button>
      <ContextMenu
        options={menuOptions}
        open={isContextMenuOpen}
        onClose={handleCloseContextMenu}
        position={`left-${contextMenuPosition.left} top-${contextMenuPosition.top}`}
      />
    </div>
  );
};
 */