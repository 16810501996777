import { Fragment, useEffect, useState } from 'react';
import { InformationCircleIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  decrement,
  previewClients,
  replace,
  setFinalClients,
} from '../../../features/client/clientSlice';
import Routes from '../../../routes/app/paths';
import { Popover, Subtitle } from '../../../components/common';
import { classNames } from '../../../config/utils';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import TableWithErrors from './tableElements/TableWithErrors';
import TableWithoutErrors from './tableElements/TableWithoutErrors';

export function ImportList({ listsAndTags }) {
  /* State */
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [showErrorTable, setShowErrorTable] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [isCorrect, setIsCorrect] = useState(true);
  const [errors, setErrors] = useState(null);

  /* Hooks */
  const { clients, headers } = useSelector(previewClients);
  const replaceClients = useSelector(replace);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const haveErrors = clients.filter((c) => c.error).length > 0;
    haveErrors ? setErrors(clients.filter((c) => c.error)) : setErrors(null);
  }, [clients]);

  /* Consts */
  // if (headers) {
  const menuOptions = headers.labels.map((item, index) => ({ id: index, title: item.should_be }));
  menuOptions.push({ id: -1, title: 'Sin asignar' });
  // }

  /* Functions */
  const handleMenuItem = (header, item) => {
    const newHeaders = selectedHeaders.map((e) => {
      let o;
      if (e.order === header.order) {
        o = Object.assign({}, e, {
          alias: item.title,
        });
      }

      return o !== undefined ? o : e;
    });
    setSelectedHeaders(newHeaders);
  };

  const confirmImport = () => {
    const finalClients = clients
      // filter clients without error in level bg-red-error
      .filter((client) => {
        if (!client.error) return true;
        return !Object.keys(client.error).some(errorKey => 
          client.error[errorKey].level === 'bg-red-error' && !(replaceClients && client.error[errorKey].description === 'Ya existe un usuario con este correo')
        )
      })
      .map((client) => {
        const newClient = {};
        selectedHeaders.forEach((header) => {
          if (header.real_name in client) {
            client.error?.email?.description === 'Ya existe un usuario con este correo'
              ? replaceClients && (newClient[header.real_name] = client[header.real_name])
              : (newClient[header.real_name] = client[header.real_name]);
          }
        });
        if (Object.keys(newClient).length > 0) return {
          ...newClient,
          lists: listsAndTags.lists,
          tags: listsAndTags.tags,
        };
      });
      
    dispatch(setFinalClients(finalClients));
    navigate(Routes.checkImportedList);
  };

  const checkIsCorrect = () => {
    const isCorrect =
      selectedHeaders.filter((item) => item.name === 'email' || item.name === 'nombre').length >= 2;

    return isCorrect;
  };

  const handleErrorTable = () => {
    if (checkIsCorrect()) {
      setShowErrorTable(true);
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <div>
      <div className="p-1 sm:px-6 lg:px-1">
        <div className="mt-8 flow-root">
          <div>
            <Subtitle title={t('Contact.import_contacts.step2.confirm_list')} />
            <div className="my-3">
              <Subtitle
                dense
                weight="light"
                color="text-gray-subtitle-softer"
                title={t('Contact.import_contacts.step2.number_contacts', { number: clients.length })}
              />
            </div>
          </div>
          {!isCorrect && (
            <div className="flex  items-center justify-between py-2 w-full border border-primary rounded-md px-4 text-white text-lg mt-4 bg-primary">
              <span>{t('Contact.import_contacts.step2.error_selection')}</span>
              <XMarkIcon
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  setIsCorrect(true);
                }}
              />
            </div>
          )}
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8  h-[490px]">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                {showErrorTable 
                ? <TableWithErrors
                    setSelectedPeople={setSelectedPeople}
                    replaceClients={replaceClients}
                    checkIsCorrect={checkIsCorrect}
                    selectedPeople={selectedPeople}
                    setSelectedHeaders={setSelectedHeaders}
                    selectedHeaders={selectedHeaders}
                    menuOptions={menuOptions}
                    headers={headers}
                    clients={clients}
                    errors={errors}
                    handleMenuItem={handleMenuItem}
                 /> 
                 : <TableWithoutErrors
                    setSelectedPeople={setSelectedPeople}
                    replaceClients={replaceClients}
                    checkIsCorrect={checkIsCorrect}
                    selectedPeople={selectedPeople}
                    setSelectedHeaders={setSelectedHeaders}
                    selectedHeaders={selectedHeaders}
                    menuOptions={menuOptions}
                    headers={headers}
                    clients={clients}
                    errors={errors}
                  />}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <Subtitle
              dense
              weight="light"
              color="text-gray-subtitle-softer"
              title={`${t('Contact.import_contacts.step2.number_imported_columns', { number: selectedHeaders.length })},
               ${t('Contact.import_contacts.step2.number_notImported_columns', {
                number: headers.labels.filter(
                  (item) => !selectedHeaders.some((selected) => selected.name === item.name),
                ).length
              })}`}
            />
          </div>

          <div className="flex space-x-4 mt-6">
            <button
              onClick={() => {
                setSelectedHeaders([]);
                dispatch(decrement());
              }}
              className="flex ms-1 py-2 px-6 text-black-soft rounded-md bg-white ring-2 ring-gray-button"
            >
              {t('Contact.import_contacts.step2.back')}
            </button>
            {showErrorTable ? (
              <button
                onClick={confirmImport}
                className={classNames(
                  'flex py-2.5 px-6 text-white rounded-md',
                  // errors && !replaceClients ? 'bg-gray-400 cursor-not-allowed' : ' bg-primary',
                  false ? 'bg-gray-400 cursor-not-allowed' : ' bg-primary',
                )}
                // disabled={errors && !replaceClients}
                disabled={false}
              >
                {t('Contact.import_contacts.step2.finish')}
              </button>
            ) : (
              <button
                onClick={handleErrorTable}
                className="bg-primary flex py-2 px-6 text-white rounded-md "
              >
                {t('Contact.import_contacts.step2.confirm')}
              </button>
            )}
          </div>
        </div>
      </div>
      <Popover />
    </div>
  );
}
