import { Fragment, useEffect, useState, useContext } from 'react';
import { Transition } from '@headlessui/react';
import { VideoCameraIcon } from '@heroicons/react/24/outline';
import { classNames, filterMostRecent } from '../../../../config/utils';
import FolderList from '../../../../components/library/Video/Destination/FolderList';

import './styles/SelectClipsModal.css';
import SelectFolder from '../../../../components/library/Video/Destination/SelectFolder';
import { FolderContext } from '../../../../contexts/folderContext';
import { getElementById, getPathById } from '../../../../pages/Library/tools/pathUtils';
import { querys } from '../../../../pages/Library/tools/querys';
import useAuthContext from '../../../../hooks/useAuthContext';
import { FileList } from '../../../../components/library/Video/Destination/FileList';
import { Breadcumbs } from '../../../../components/library';
import { FileListLoading } from '../../../../components/library/Video/Destination/FolderListLoading';
import { typesRequestLibrary } from '../../../../pages/Library/tools/types';
import { useTranslation } from 'react-i18next';
import { DialogV2, Spin } from '../../../../components/common';
import { useVideoStepsContext } from '../../../../components/video/videoSteps/context/VIdeoStepsContext';
import { useToast2 } from '../../../../hooks/useToast2';
import moment from 'moment';

export function SelectClipsModal({
  onReadyDownloads,
  showModal,
  onClose,
  hasBackdrop,
  rootFolder,
  onLibraryChange,
  isLoading,
}) {
  const { setOpenSelfRecord } = useVideoStepsContext();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState({
    lastFiles: [],
    restFiles: [],
  });

  const [progressData, setProgressData] = useState([]);

  const {
    idFolder = null,
    setIdFolder,
    currentFolder,
    setCurrentFolder,
    filesSelected,
    setFilesSelected,
    library,
  } = useContext(FolderContext);

  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { showToast } = useToast2();

  // Para poder saber la libreria que se esta mostrando actualmente
  const [libraryType, setLibraryType] = useState(typesRequestLibrary.CORPORATIVE);

  const { mutate: mutateAddCLip, progress, dataProgress } = querys().addClip(library);

  const isUploading = progressData.length > 0;

  /* Effects */
  useEffect(() => {
    const getFolder = () => {
      if (!idFolder) return rootFolder;
      return getElementById(idFolder, rootFolder);
    };

    setCurrentFolder(getFolder());
  }, [idFolder, rootFolder]);

  // para setear los datos de progreso de los archivos subidos
  useEffect(() => {
    if (progress.length) {
      const data = progress.map((item, i) => {
        return {
          ...item,
          title: dataProgress[i].title,
          preview: dataProgress[i].preview,
          type: dataProgress[i].type,
        };
      });
      setProgressData(data);
    }
  }, [progress, dataProgress]);

  useEffect(() => {
    //reset progress, empty progressData
    if (
      progressData.length &&
      progressData.filter((obj) => obj.progress === 100).length === progressData.length
    ) {
      setTimeout(() => {
        setProgressData([]);
        setFilesSelected([]);
      }, 500);
      return;
    }
  }, [progressData]);

  // Para filtrar los elementos más recientes
  useEffect(() => {
    const files = currentFolder?.files
      ?.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
    const { lastElements, restElements } = filterMostRecent(files);

    setFiles({ lastFiles: lastElements, restFiles: restElements });

    return () => {
      setFiles({ lastFiles: [], restFiles: [] });
    };
  }, [currentFolder]);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  const hanldeDownload = () => {
    if (currentFolder && currentFolder.files) {
      const clipsToEdit = currentFolder.files
        .filter((file) => filesSelected.includes(file.id))
        .map((file) => ({ ...file, libraryType: libraryType === 'record' ? 'record' : 'clip' }));

      onReadyDownloads(clipsToEdit);
      setFilesSelected([]); // Volver a restaurar lo seleccionado
    }
  };

  const handleUpload = (e) => {
    let newFiles = [];
    if (!e?.target?.files?.length) return;

    // validate if files are videos
    if (e.target.files.length) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (file.type.includes('video')) {
          newFiles.push(Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),);
        }
      }
    }

    if (!newFiles.length) {
      showToast({
        show: true,
        type: 'warning',
        message: t('Library.upload_file_error'),
      });
      return;
    };

    let titles = newFiles.map((file) => {
      let name = file.name.split('.');
      name.pop();
      return name.join('.');
    });

    //add clip
    mutateAddCLip({
      token: user.access_token,
      titles,
      clips: newFiles,
      folderId: currentFolder.id || 0,
      thumbnails: [null],
      descriptions: [null],
    });
  };

  const path = () => {
    let path = [
      {
        id: 0,
        name:
          rootFolder.name === typesRequestLibrary.CORPORATIVE
            ? t('Library.company_videos')
            : t('Library.records'),
      },
    ];
    if (!idFolder || !rootFolder) return path;
    const subPath = getPathById(idFolder, rootFolder);
    path = path.concat(subPath);
    return path;
  };

  const libraryChange = (type) => {
    setLibraryType(type);

    onLibraryChange(type);
  };

  const isAgente = user?.role === 'Agente';
  
  return (
    <DialogV2 open={open} onClose={() => onClose(false)}>
      <div className="px-8 mb-4">
        {/* Header area */}
        <div className="flex justify-between items-center mb-2">
          <div className="flex justify-between">
            {rootFolder && rootFolder.children && (
              <>
                <SelectFolder
                  folders={[
                    {
                      id: rootFolder.id,
                      name: 'Videos de empresa',
                      type: typesRequestLibrary.CORPORATIVE,
                    }, // Esto es hasta que estén los demás tipos de videos
                    {
                      id: rootFolder.id,
                      name: 'Grabaciones',
                      type: typesRequestLibrary.RECORD,
                    },
                    ...rootFolder.children,
                  ]}
                  rootId={rootFolder.id}
                  rootName={rootFolder.name} //El nombre del root folder ahora mismo es video, pero deberia ser Videos Creados
                  onLibraryChange={libraryChange}
                />
                <div>
                  <button
                    className="import-file-button flex gap-3 justify-center items-center relative bg-primary text-white rounded-lg disabled:bg-gray-500"
                    disabled={isUploading || isAgente}
                  >
                    <span>{!isUploading ? t('Library.upload_file') : t('Library.uploading')}</span>
                    {isUploading && <Spin />}
                    {!isAgente && <input
                      type="file"
                      className={classNames(
                        'absolute inset-0 appearance-none opacity-0 cursor-pointer disabled:cursor-default',
                      )}
                      accept="video/*"
                      onChange={handleUpload}
                      disabled={isUploading}
                    />}
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="w-full ">
            {/* Header */}
            <div className="flex justify-end items-start">
              <button onClick={() => setOpenSelfRecord(true)}>
                <VideoCameraIcon className="w-12 cursor-pointer h-12 bg-primary px-2 py-2 rounded-full text-white ring-4 ring-red-400" />
              </button>
            </div>
          </div>
        </div>

        {/* Breadcumbs */}
        <div className="mt-5 -mb-2">
          <Breadcumbs path={path()} onClick={setIdFolder} />
        </div>

        {/* Folders */}
        <div className="mt-3 2xl:mt-5">
          <FolderList folders={currentFolder?.children} isLoading={isLoading} />
        </div>

        {/* Section area */}
        {/* Videos correspondientes a la carpeta */}
        <div className="overflow-y-auto h-[200px] pb-30 mb-15 mt-2 2xl:mt-4 2xl:h-[400px]">
          {progressData?.length > 0 && (
            <section>
              <FileListLoading title="Cargando" files={progressData} />
            </section>
          )}
          {files.lastFiles.length > 0 && (
            <section>
              <FileList
                title={t('Library.recents')}
                files={files.lastFiles}
                getFileId
                multiple
                type="video"
              />
            </section>
          )}
          {files.restFiles.length > 0 && (
            <section>
              <FileList
                title={t('Library.videos')}
                files={files.restFiles}
                getFileId
                multiple
                type="video"
              />
            </section>
          )}
        </div>

        <div className="text-gray-500 2xl:text-lg border-t border-gray-200 pt-4">
          <h4>
            {filesSelected.length} {t('Edition.selected_videos')}{' '}
          </h4>
        </div>
        <div className="mt-8 space-x-4">
          <button
            className="px-6 py-2 border  rounded-lg"
            onClick={() => {
              onClose(false);
              setOpen(false);
            }}
          >
            {t('Edition.cancel')}
          </button>
          <button
            className="text-white bg-primary px-6 py-2 rounded-lg"
            onClick={() => {
              hanldeDownload();
              onClose(false);
              setOpen(false);
            }}
          >
            {t('Edition.select')}
          </button>
        </div>
      </div>
    </DialogV2>
  );
}
