import { PlayCircleIcon } from '@heroicons/react/24/outline';

import './styles/FrameItem.css';
import { useEffect, useState } from 'react';
import { generateImageUrl } from '../../config/utils';

export function FrameItem({ item, setSelected }) {
  const [formatTime, setformatTime] = useState({
    minutes: '00',
    seconds: '00',
  });

  const secondsToFormatTime = (seconds) => {
    const formatedMin = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const formatedSec = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0');
    return { formatedMin, formatedSec };
  };

  useEffect(() => {
    if (item && item.duration) {
      const { formatedMin, formatedSec } = secondsToFormatTime(item.duration);
      setformatTime({
        minutes: formatedMin,
        seconds: formatedSec,
      });
    }
  }, [item, item.duration]);

  return (
    <div className="frame_item">
      {item.thumbnail && (
        <img className="frame_item-img" src={generateImageUrl(item.thumbnail)} alt="" />
      )}
      <div className="frame_item-controls">
        <div className="p-2" style={{ minHeight: 24 }}>
          {/* Si no se manda una funcion se entiende que no son seleccionables */}
          {setSelected && (
            <input
              type="checkbox"
              className="w-6 h-6 focus:outline-0 rounded-md text-primary ring-primary outline-primary focus:ring-0 border-[1px] border-gray-400 focus:border-[1px] cursor-pointer shadow-md"
              onClick={() => setSelected(item)}
            />
          )}
        </div>
        <PlayCircleIcon className="w-10 h-10 cursor-pointer text-gray-600 mx-auto" />
        <div className="text-white flex justify-between p-2">
          <span className="frame_item-controls-name">
            {item.title.substring(0, 15)}
            {item.title.length > 15 ? '...' : ''}
          </span>
          <span className="frame_item-controls-time">{`${formatTime.minutes}:${formatTime.seconds}`}</span>
        </div>
      </div>
    </div>
  );
}
