import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineUser } from 'react-icons/ai';
import { generateImageUrl, inputClick } from '../../config/utils'

export const Avatar = ({ selectedImage, avatar, onUploadImage }) => {

  const { t } = useTranslation();
  
  const src = selectedImage ? generateImageUrl(selectedImage) : avatar;
  
  return (
    <div
      className='bg-gray-200 rounded-full overflow-hidden relative w-40 h-40 min-w-40 min-h-40 xl:min-w-[200px] xl:min-h-[200px] aspect-square shadow-lg flex flex-col justify-center'
    >
      {src && <img 
        src={src} 
        alt=""
        className="object-cover w-full h-full"
      />}
      {
        !avatar && <AiOutlineUser className={'text-gray-500 font-normal w-full h-full object-cover'} />
      }
      <div 
        className='rounded-full w-full aspect-square xl:w-[200px] xl:h-[200px] absolute z-10 top-[70%] bg-gray-100/80 backdrop-blur-sm inset-x-0 cursor-pointer' 
        onClick={() => inputClick(onUploadImage, 'image/*')}
      />
      <p className='absolute z-20 bottom-[8%] inset-x-0 text-center font-light text-gray-500 pointer-events-none'>{t('Common.avatar.upload_photo')}</p>
    </div>
  )
}
