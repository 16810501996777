import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { save, undo, redo, redoOriginal } from '../../../features/videos/videosSlice';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

// icons
const DivisorIcon = () => (
  <svg className="mr-2" viewBox="0 0 24 24" fill="none" width="25" height="25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C12.5523 3 13 3.44772 13 4L13 5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5L11 4C11 3.44772 11.4477 3 12 3ZM5.77215 6.22781C5.62629 6.08194 5.42846 6 5.22218 6H3.66663C3.11434 6 2.66663 5.55228 2.66663 5C2.66663 4.44772 3.11434 4 3.66663 4H5.22218C5.95889 4 6.66543 4.29266 7.18637 4.81359C7.7073 5.33453 7.99996 6.04107 7.99996 6.77778V17.2222C7.99996 17.9589 7.7073 18.6655 7.18637 19.1864C6.66543 19.7073 5.95889 20 5.22218 20H3.66663C3.11434 20 2.66663 19.5523 2.66663 19C2.66663 18.4477 3.11434 18 3.66663 18H5.22218C5.42846 18 5.62629 17.9181 5.77215 17.7722C5.91802 17.6263 5.99996 17.4285 5.99996 17.2222V6.77778C5.99996 6.5715 5.91802 6.37367 5.77215 6.22781ZM18.2276 6.22781C18.3734 6.08194 18.5713 6 18.7775 6H20.3331C20.8854 6 21.3331 5.55228 21.3331 5C21.3331 4.44772 20.8854 4 20.3331 4H18.7775C18.0408 4 17.3343 4.29266 16.8133 4.81359C16.2924 5.33453 15.9998 6.04107 15.9998 6.77778V17.2222C15.9998 17.9589 16.2924 18.6655 16.8133 19.1864C17.3343 19.7073 18.0408 20 18.7775 20H20.3331C20.8854 20 21.3331 19.5523 21.3331 19C21.3331 18.4477 20.8854 18 20.3331 18H18.7775C18.5713 18 18.3734 17.9181 18.2276 17.7722C18.0817 17.6263 17.9998 17.4285 17.9998 17.2222V6.77778C17.9998 6.5715 18.0817 6.37367 18.2276 6.22781ZM13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9L11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10L13 9ZM12 13C12.5523 13 13 13.4477 13 14L13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15L11 14C11 13.4477 11.4477 13 12 13ZM13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19L11 20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20L13 19Z"
      fill="currentColor"
    ></path>
  </svg>
)
const TrashIcon = () => (
  <svg
    className="mr-2"
    width="25"
    height="25"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
  >
    <path d="M261-120q-24.75 0-42.375-17.625T201-180v-570h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438v-570ZM367-266h60v-399h-60v399Zm166 0h60v-399h-60v399ZM261-750v570-570Z" />
  </svg>
);

export default function BottomControls({
  videos,
  setCurrentVideoIndex,
  currentVideoIndex,
  currentTime,
  currentTotalTime,
  disabled,
}) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // Permite borrar un elemento de la lista de videos de la línea de tiempo
  const handleDeleteItem = (selectedIndex) => {
    const newItems = [...videos];
    newItems.splice(selectedIndex, 1);

    const arrayCopy = JSON.parse(JSON.stringify(newItems));

    if (selectedIndex === 0) {
      setCurrentVideoIndex((prevIndex) => prevIndex + 1);
      const indexCopy = JSON.parse(JSON.stringify(currentVideoIndex));
      dispatch(
        save({
          newVideos: arrayCopy,
          config: { currentVideoIndex: indexCopy },
        }),
      );
    } else {
      setCurrentVideoIndex((prevIndex) => prevIndex - 1);
      const indexCopy = JSON.parse(JSON.stringify(currentVideoIndex));
      dispatch(
        save({
          newVideos: arrayCopy,
          config: { currentVideoIndex: indexCopy - 1 },
        }),
      );
    }
  };

  // Permite cortar un elemento de video, pero solo por el medio
  const handleSplitVideo = (indexSplit, splitSecond) => {
    const splitVideo = videos[indexSplit];

    const startSecond = splitVideo.startSecond;
    const endSecond = splitVideo.endSecond;

    const duration = splitSecond - startSecond;

    const duration2 = endSecond - splitSecond;

    if (duration > 0 && duration2 > 0) {
      const firstItem = {
        ...videos[indexSplit],
        duration,
        startSecond,
        endSecond: splitSecond,
        originalStartSecond: startSecond,
        originalEndSecond: splitSecond,
        originalDuration: duration,
      };

      const secondItem = {
        ...videos[indexSplit],
        duration: duration2,
        startSecond: splitSecond + 0.01,
        endSecond: endSecond,
        originalStartSecond: splitSecond + 0.01,
        originalEndSecond: endSecond,
        originalDuration: duration2,
      };

      let newItems = [...videos];
      newItems.splice(indexSplit, 1);
      newItems.splice(indexSplit, 0, firstItem, secondItem);

      const arrayCopy = JSON.parse(JSON.stringify(newItems));
      const indexCopy = JSON.parse(JSON.stringify(currentVideoIndex));
      dispatch(save({ newVideos: arrayCopy, config: { currentVideoIndex: indexCopy } }));
    }
  };

  // Acciones de undo y redo
  const handleUndo = () => {
    // Regreso a un momento más atrás
    dispatch(undo());
  };

  const handleRedo = () => {
    // Voy a un momento más adelante
    dispatch(redo());
  };

  const handleRedoOriginal = () => {
    dispatch(redoOriginal());
  };

  return (
    <div className="flex gap-5 w-full justify-center items-center my-4">
      <div className="flex rounded-lg border-[1px] border-gray-300">
        <Button 
          size='sm'
          leftIcon={<DivisorIcon />}
          variant='ghost'
          onClick={() => handleSplitVideo(currentVideoIndex, currentTime)}
          isDisabled={disabled}
        >
          <span className="text-md 2xl:text-lg">
            {t('VideoNew.edition.actions.split')}{' '}
            {`${currentTotalTime.minutes || '00'}:${currentTotalTime.seconds || '00'}`}
          </span>
        </Button>
        <Button
          size='sm'
          leftIcon={<TrashIcon />}
          variant='ghost'
          onClick={() => handleDeleteItem(currentVideoIndex)}
          isDisabled={disabled}
        >
          <span className="text-md 2xl:text-lg">{t('VideoNew.edition.actions.remove')}</span>
        </Button>
      </div>
      <div className="flex rounded-lg border-[1px] border-gray-300">
        <Button
          size='sm'
          variant='ghost'
          onClick={handleRedoOriginal}
          isDisabled={disabled}
        >
          <span className="text-md 2xl:text-lg">
            {t('VideoNew.edition.actions.redo_original')}
          </span>
        </Button>
        <Button
          size='sm'
          variant='ghost'
          onClick={handleUndo}
          isDisabled={disabled}
        >
          <span className="text-md 2xl:text-lg">
            {t('VideoNew.edition.actions.undo')}
          </span>
        </Button>
        <Button
          size='sm'
          variant='ghost'
          onClick={handleRedo}
          isDisabled={disabled}
        >
          <span className="text-md 2xl:text-lg">
            {t('VideoNew.edition.actions.redo')}
          </span>
        </Button>
      </div>
    </div>
  );
}

BottomControls.propTypes = {
  videos: PropTypes.array,
  setCurrentVideoIndex: PropTypes.func,
  currentVideoIndex: PropTypes.number,
  currentTime: PropTypes.number,
  currentTotalTime: PropTypes.object,
  disabled: PropTypes.bool,
};
