import { useEffect, useState } from "react";

const getName = (file) => file.name.split('.').slice(0, -1).join('.')

const UploadFileItem = ({ file, onChangeName, type = 'video' }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [rename, setRename] = useState(getName(file))

  const extension = file.name.split('.').pop()

  useEffect(() => {
    if (file.name === rename) return;
    !isEditing && onChangeName && onChangeName(`${rename}.${extension}`)
  }, [isEditing, rename, extension])

  return (
    <div className="flex gap-1 flex-col justify-center min-w-0 m-0.5 max-w-[102px] 2xl:max-w-[130px] 2xl:w-32 truncate">
      <div className="flex min-w-0 overflow-hidden rounded-lg w-24 2xl:w-32 h-24 2xl:h-32">
        {type === 'video' 
          ? <video
              src={file.preview}
              className="block  object-cover object-center shadow-lg"
              // eslint-disable-next-line react/no-unknown-property
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
            />
          : <img
              src={file.preview}
              className="block  object-cover object-center shadow-lg"
              // eslint-disable-next-line react/no-unknown-property
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
            />
        }
      </div>
      {!isEditing 
        ? <span 
            className="text-gray-700 text-xs truncate p-0.5 px-1 mr-2 "
            onClick={() => setIsEditing(true)}
          >
            {rename}
          </span>
        : <input
            type="text"
            value={rename}
            onChange={(e) => setRename(e.target.value)}
            className="text-gray-700 text-xs truncate p-0.5 px-1 mr-1 rounded-sm border-none bg-blue-200 focus:outline-none focus:ring-0"
            onBlur={() => setIsEditing(false)}
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsEditing(false)
              }
            }}
          />
      }
    </div>
  )
}
export default UploadFileItem