import { ArrowsUpDownIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { classNames } from "../../config/utils";
import { Spin } from "../../components/common";
import useAuthContext from "../../hooks/useAuthContext";

export function HeaderControl(
   name, 
  orderTable,
  query,
  handleSearchQuery,
  createList,
  selectedItem,
  removeItems,
  isLoading,
) {

  const { user } = useAuthContext();
  const {t} = useTranslation();

  const isAgente = user?.role === 'Agente';

  return (
    <div className="mt-4 mb-8 flex justify-between">
      <div className="flex items-center space-x-2 justify-between">
        <ArrowsUpDownIcon
          className="w-10 2xl:w-12 h-8 2xl:h-10 cursor-pointer text-gray-400 hover:shadow-md rounded-full p-1 active:text-primary active:scale-[0.97]"
          onClick={orderTable}
        />
        <input
          value={query}
          type="text"
          placeholder={t('Placeholder.filter_name')}
          className="border-gray-300 rounded-2xl focus:border-gray-300 focus:ring-1 focus:ring-gray-300 w-full placeholder:text-gray-400 px-4 text-sm 2xl:text-md"
          onChange={handleSearchQuery}
        />
      </div>
      <div className="flex items-center space-x-2">
        <button
          onClick={createList}
          className="flex items-center px-4 py-2 gap-2 rounded-xl text-white text-center bg-primary cursor-pointer text-sm 2xl:text-md disabled:bg-gray-500 disabled:cursor-default" disabled={isLoading}
        >
          {!isLoading ? <PlusIcon className="w-6 h-6" /> : <Spin />}
          {!isLoading ? `${t('Common.client_items.create')} ${name}` : `${t('Common.client_items.creating')} ${name}`}
        </button>
        {!isAgente && <TrashIcon
          className={classNames(
            selectedItem.length !== 0
              ? 'cursor-pointer text-gray-600 focus:text-primary hover:shadow-md active:text-primary active:scale-[0.97]'
              : 'cursor-not-allowed text-gray-200',
            'font-extralight w-10 2xl:w-12 h-10 2xl:h-12 rounded-full p-1 ',
          )}
          onClick={removeItems}
        />}
      </div>
    </div>
  );
}

export const ContextMenu = ({showMenu, setShowMenu, index, menuOptions, handleMenuItem, list}) => {
  
    return (
      <>
        <div className={classNames("fixed inset-0 backdrop-blur-xl opacity-10 z-10", !showMenu[index] && 'hidden')} onClick={() => setShowMenu(prev => ({...prev, [index]: false}))}/>
        
        <div className={classNames("absolute p-1 2xl:p-2 top-0 right-0 z-10 mt-2 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-gray-600 duration-100", !showMenu[index] && 'hidden')}>
          <>
            {menuOptions.map((item, index) => (
              <div
                key={item.id}
                className="rounded-md p-2 hover:bg-gray-menu-shadow w-full"
                onClick={() => handleMenuItem(list, item)}
              >
                  <span
                    className={classNames(
                      'text-gray-700 active:text-primary',
                      'block text-xs 2xl:text-sm', 'w-full select-none'
                    )}
                  >
                    {item.title}
                  </span>
              </div>
            ))}
          </>
        </div>
      </>
    );
  }