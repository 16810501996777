import { DOMAIN } from '../../../routes/server/paths';
import { typesRequestLibrary } from './types';

export const fetchDataGET = async (opts) => {
  const { url, token } = opts;

  return fetch(url, {
    headers: typesRequestLibrary.headers(token),
  }).then((response) => response.json());
};

export const fetchDataPOST = async (opts) => {
  const { url, token, library } = opts;

  return fetch(url, {
    method: 'POST',
    headers: typesRequestLibrary.headers(token),
    body: JSON.stringify({
      name: 'nueva carpeta',
      type: library,
    }),
  }).then((response) => response.json());
};

export const fetchDataDelete = async (opts) => {
  const { url, token, id } = opts;

  return fetch(url, {
    method: 'DELETE',
    headers: typesRequestLibrary.headers(token),
    body: JSON.stringify({
      folderId: Number(id),
    }),
  }).then((response) => response.json());
};

export const fetchDataPUT = async (opts) => {
  const { url, token, id, name } = opts;

  return fetch(url, {
    method: 'PUT',
    headers: typesRequestLibrary.headers(token),
    body: JSON.stringify({
      id: Number(id),
      name,
    }),
  }).then((response) => response.json());
};

export const fetchDataPUTArchived = async (opts) => {
  const { token, id, action } = opts;

  return fetch(`${DOMAIN}api/v1/folders/${id}/status/${action}`, {
    method: 'PUT',
    headers: typesRequestLibrary.headers(token),
  }).then((response) => response.json());
};

export const fetchDataPUTMove = async (opts) => {
  const { url, token, from, to } = opts;

  return fetch(url, {
    method: 'PUT',
    headers: typesRequestLibrary.headers(token),
    body: JSON.stringify({
      folderId: Number(from),
      newParentId: Number(to),
    }),
  }).then((response) => response.json());
};

export const fetchDataPOSTDUPLICATE = async (opts) => {
  const { url, token, id } = opts;

  return fetch(url, {
    method: 'POST',
    headers: typesRequestLibrary.headers(token),
    body: JSON.stringify({
      id: Number(id),
    }),
  }).then((response) => response.json());
};
