import { useTranslation } from 'react-i18next';
import ContactList from '../../components/contacts/List';
import { Title } from '../../components/common';

export function Contacts() {
  const {t} = useTranslation();
  return (
    <>
      <Title title={t('Contact.meta')} />
      <ContactList />
    </>
  );
}
