import ApiRoutes from '../../routes/api/paths';
import { headers, headersFile } from '../../config/headers';


const handleResponse = async (response) => {
  if (!response.ok) {
    return Promise.reject(response.status);
  }
  const data = await response.json();
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve({
      status: response.status,
      data: data,
    });
  }
};

const sendRequest = async (url, requestOptions) => {
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      return Promise.reject({
        status: error,
      });
    });
};

// Update user info
export const postUpdateUser = async ({ token, data, userId}) => {
  const { name, email, surname, second_surname, phone, role, department, avatar } = data;
  var formData = new FormData();
  formData.append('id', userId);
  avatar && typeof avatar !== 'string' && formData.append('avatar', avatar);
  formData.append('name', name);
  formData.append('email', email);
  formData.append('last_name', surname);
  formData.append('second_last_name', second_surname);
  formData.append('phone', phone);
  formData.append('role', role);
  formData.append('department', department);
  const requestOptions = {
    method: 'POST',
    headers: headersFile(token),
    body: formData,
  };
  return await sendRequest(ApiRoutes.updateUserProfile, requestOptions);
};

// Change password
export const putUpdatePass = async ({ token, id, ...restData }) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify({ id, ...restData.data })
  };
  return sendRequest(ApiRoutes.updatePassword, requestOptions);
};