import * as yup from 'yup';

export const NewContactSchema = yup.object().shape({
    email: yup
        .string()
        .required('Validation.required')
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Validation.wrong_email_format'),
    name: yup
        .string()
        .required('Validation.required')
        .matches(/^[^0-9]+$/, 'Validation.not_numeric'),
    last_name: yup
        .string()
        .notRequired(),
    second_last_name: yup
        .string()
        .notRequired(),
    phone: yup
        .string()
        .notRequired()
        .matches(
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{2,4}[\s.-]?\d{2,4}$|^$/,
            'Validation.not_phone'
        ),
    company: yup
        .string()
        .notRequired()
});

export const NewCompanySchema = yup.object().shape({
    email: yup
        .string()
        .required('Validation.required')
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Validation.wrong_email_format'),
    name: yup
        .string()
        .required('Validation.required'),
    phone: yup
        .string()
        .notRequired()
        .matches(
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{2,4}[\s.-]?\d{2,4}$|^$/,
            'Validation.not_phone'
        ),
    web: yup
        .string()
        .matches(
            /^(?:(?:https?:\/\/)?(?:www\.)?(?!www\.)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})?$/i,
          'Validation.not_url'
        )
        .notRequired()
});

export const NewUserSchema = yup.object().shape({
    email: yup
        .string()
        .required('Validation.required')
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Validation.wrong_email_format'),
    name: yup
        .string()
        .required('Validation.required')
        .matches(/^[^0-9]+$/, 'Validation.not_numeric'),
    phone: yup
        .string()
        .notRequired()
        .matches(/^\d*$/, 'Validation.only_numeric'),
    surname: yup
        .string()
        .required('Validation.required')
        .matches(/^[^0-9]+$/, 'Validation.not_numeric'),
    second_surname: yup
        .string()
        .notRequired()
        .matches(/^(?:[^0-9]*)$/, 'Validation.not_numeric'),
    company: yup
        .string()
        .required('Validation.required'),
    role: yup
        .string()
        .required('Validation.required')
});

export const ChangePasswordSchema = yup.object().shape({
    currentPassword: yup
        .string()
        .required('Validation.required'),
    newPassword: yup
        .string()
        .required('Validation.required')
        .min(8, 'Validation.min_size_pass')
        .matches(/[a-zA-Z]/, 'Validation.contain_letters')
        .matches(/[0-9]/, 'Validation.contain_numbers')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Validation.contain_special_characters'),
    confirmedPassword: yup
        .string()
        .required('Validation.required')
        .oneOf([yup.ref('newPassword')], 'Validation.confirm_pass_equiality'),
});
