import { useState } from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { save, saveTexts, setRealIdToImage } from '../../../features/videos/videosSlice';

import './styles/InsideControls.css';
import { ImportImageToEdit } from '../../../services/video/VideoService';
import useAuthContext from '../../../hooks/useAuthContext';
import { useMutation } from 'react-query';
import { useRandomId } from '../../../hooks/useRamdonId';

import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../../hooks/useToast2';

export default function InsideControls({
  className,
  videos,
  startAt,
  endAt,
  handleVideoEnd,
  videoRef,
  playerData,
  setPlayerData,
  currentVideoIndex,
  setCurrentVideoIndex,
  setCurrentTime,
  currentTotalTime,
  formatedCurrentTotalTime,
  overlayTexts,
  showGuideGrid = () => {},
}) {
  const classname = `inside-controls ${className}`;

  const dispatch = useDispatch();

  const [showSlider, setShowSlider] = useState(false);

  const { user } = useAuthContext();

  const { t } = useTranslation();
  const { showToast } = useToast2();

  /**
   * Esto es para ponerle un id temporal a la imagen que se carga y cuando
   * llegue el id del clip renderizado en el back a partir de la imagen entonces se le ponga este id
   */
  const [tempImgId, setTempImgId] = useState('');

  const mutationImportImage = useMutation('IMPORT_IMAGE', ImportImageToEdit, {
    onError: (error) => {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.video.error.create_clip_by_image'),
      });
    },
    onSuccess: (response) => {
      if (response.status === 200) {
        dispatch(
          setRealIdToImage({
            tempId: tempImgId,
            realId: response.data.clip_id,
            realFile: response.data.src,
            isLoading: false,
          }),
        );
      }
    },
  });

  // Para manejar todas los controles del lado izquierdo del reproductor
  const handlePlayPause = () => {
    if (playerData.paused) {
      setPlayerData({
        ...playerData,
        paused: false,
      });
      videoRef.current.play();
    } else {
      setPlayerData({
        ...playerData,
        paused: true,
      });
      videoRef.current.pause();
    }
  };

  //Esto es para si da backward y se pasa del inicio del video vaya al video anterior si no es el primero
  const handleBackward = () => {
    if (videoRef.current.currentTime - 15 < startAt) {
      // Por si da para atrás desde el primer video
      let index = 0;
      if (currentVideoIndex - 1 >= 0) {
        index = index = currentVideoIndex - 1;
      }
      setCurrentVideoIndex(index);
      setCurrentTime(0);
    } else {
      videoRef.current.currentTime = videoRef.current.currentTime - 15;
      setCurrentTime(videoRef.current.currentTime);
    }
  };
  //Esto es para si da forward y se pasa del final del video vaya al video siguiente
  const handleForward = () => {
    // Esto es para que si el video llega al final cuando se adelante 15 segundos
    if (videoRef.current.currentTime + 15 >= endAt) {
      handleVideoEnd();
    } else {
      videoRef.current.currentTime = videoRef.current.currentTime + 15;
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  // Para ocultar o mostrar el slider de volumen
  const handleSliderOpen = () => {
    setShowSlider(!showSlider);
  };

  // Para cambiar el volumen
  const handleVolumeChange = (event) => {
    if (event.target.value <= 0.1) {
      setPlayerData({
        ...playerData,
        volume: 0,
        muted: true,
      });
      videoRef.current.muted = true;
    } else {
      setPlayerData({
        ...playerData,
        volume: event.target.value,
        muted: false,
      });
      videoRef.current.volume = playerData.volume;
      videoRef.current.muted = false;
    }
  };

  // Para poner en mute los videos
  const handleMuted = () => {
    if (playerData.muted) {
      setPlayerData({
        ...playerData,
        volume: 0.5,
        muted: false,
      });
      videoRef.current.muted = false;
      videoRef.current.volume = 0.5;
    } else {
      setPlayerData({
        ...playerData,
        volume: 0,
        muted: true,
      });
      videoRef.current.muted = true;
    }
  };

  // Funciones para controlar los textos superpuestos
  const onAddTextOverlay = () => {
    if (videos && videos.length) {
      const newTexts = [...overlayTexts];
      const newText = {
        text: '',
        position: { x: 0, y: 0 },
        styleText: {
          color: '#ffffff',
          fontSize: 28,
          fontFamily: 'Arial',
        },
        parentSize: { width: 0, height: 0 },
        startSecond: currentTotalTime,
        duration: 10, // Los textos duran 10 segundos por defecto luego se pueden cambiar
      };

      newTexts.push(newText);

      const textsCopy = JSON.parse(JSON.stringify(newTexts));

      dispatch(saveTexts(textsCopy));
    }
  };

  // Para importar las imagenes
  const handleImportImage = () => {
    const handleFileChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        const randomId = useRandomId('image');

        setTempImgId(randomId);

        const imgUrl = URL.createObjectURL(file);

        const newMedia = {
          id: randomId,
          duration: 30,
          src: imgUrl,
          type: 'image',
          startSecond: 0,
          endSecond: 30,
          originalStartSecond: 0,
          originalEndSecond: 30,
          originalDuration: 30,
          isLoading: true,
        };

        const newVideos = [...videos];
        newVideos.push(newMedia);

        // Guardamos la imagen con el id temporal
        const videosCopy = JSON.parse(JSON.stringify(newVideos));
        dispatch(save({ newVideos: videosCopy, config: { currentVideoIndex: currentVideoIndex } }));
        
        setTimeout(() => {
          // Preparamos el form data para enviar la imagen
          const formData = new FormData();
          
          formData.append('image', file);
          
          showToast({
            show: true,
            type: 'info',
            message: t('Message.imgtovideo.success.preparing'),
          })
          // Luego cuando llegue el id del clip se le agregará este id a la imagen con el id random
          mutationImportImage.mutate({ token: user.access_token, formData });
        }, 500);
      }
    };

    // Se le asigna un evento click y se da click programaticamente
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', handleFileChange);
    fileInput.click();
  };

  return (
    <div className={classname}>
      <div className="left-controls space-x-2">
        <button
          className="play-pause"
          onClick={() => handlePlayPause()}
          disabled={!videos.length > 0}
        >
          {playerData.paused ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30"
              viewBox="0 0 24 24"
              width="30"
              fill="#fff"
            >
              <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30"
              viewBox="0 0 24 24"
              width="30"
              fill="#fff"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
            </svg>
          )}
        </button>
        <button
          className="play-backward"
          onClick={() => handleBackward()}
          disabled={!videos.length > 0}
        >
          <svg
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4314 16.9203H12.1414C11.7314 16.9203 11.3914 16.5803 11.3914 16.1703C11.3914 15.7603 11.7314 15.4203 12.1414 15.4203H14.4314C14.8614 15.4203 15.2114 15.0703 15.2114 14.6403C15.2114 14.2103 14.8614 13.8603 14.4314 13.8603H12.1414C11.9014 13.8603 11.6714 13.7403 11.5314 13.5503C11.3914 13.3603 11.3514 13.1003 11.4314 12.8703L12.1914 10.5803C12.2914 10.2703 12.5814 10.0703 12.9014 10.0703H15.9614C16.3714 10.0703 16.7114 10.4103 16.7114 10.8203C16.7114 11.2303 16.3714 11.5703 15.9614 11.5703H13.4414L13.1814 12.3603H14.4314C15.6914 12.3603 16.7114 13.3803 16.7114 14.6403C16.7114 15.9003 15.6814 16.9203 14.4314 16.9203Z"
              fill="#fff"
            />
            <path
              d="M9.54041 16.9208C9.13041 16.9208 8.79041 16.5808 8.79041 16.1708V12.7808L8.60041 13.0008C8.32041 13.3108 7.85041 13.3308 7.54041 13.0608C7.24041 12.7808 7.21041 12.3108 7.49041 12.0008L8.99041 10.3308C9.20041 10.1008 9.53041 10.0208 9.82041 10.1308C10.1104 10.2408 10.3004 10.5208 10.3004 10.8308V16.1808C10.2904 16.5908 9.96041 16.9208 9.54041 16.9208Z"
              fill="#fff"
            />
            <path
              d="M12.0016 3.47945C11.9216 3.47945 11.8416 3.48945 11.7616 3.48945L12.5816 2.46945C12.8416 2.14945 12.7916 1.66945 12.4616 1.41945C12.1316 1.16945 11.6716 1.20945 11.4116 1.53945L9.44156 3.99945C9.43156 4.00945 9.43156 4.01945 9.42156 4.03945C9.39156 4.07945 9.37156 4.12945 9.35156 4.16945C9.33156 4.21945 9.31156 4.25945 9.30156 4.29945C9.29156 4.34945 9.29156 4.38945 9.29156 4.43945C9.29156 4.48945 9.29156 4.53945 9.29156 4.58945C9.29156 4.60945 9.29156 4.61945 9.29156 4.63945C9.30156 4.66945 9.32156 4.68945 9.33156 4.71945C9.35156 4.76945 9.37156 4.80945 9.39156 4.85945C9.42156 4.89945 9.45156 4.93945 9.49156 4.96945C9.51156 4.99945 9.52156 5.02945 9.55156 5.04945C9.57156 5.05945 9.58156 5.06945 9.60156 5.07945C9.62156 5.09945 9.65156 5.10945 9.68156 5.11945C9.73156 5.14945 9.79156 5.16945 9.84156 5.17945C9.88156 5.19945 9.91156 5.19945 9.94156 5.19945C9.97156 5.19945 9.99156 5.20945 10.0216 5.20945C10.0516 5.20945 10.0716 5.19945 10.0916 5.18945C10.1216 5.18945 10.1516 5.19945 10.1816 5.18945C10.8216 5.03945 11.4216 4.96945 11.9916 4.96945C16.4816 4.96945 20.1316 8.61945 20.1316 13.1095C20.1316 17.5994 16.4816 21.2495 11.9916 21.2495C7.50156 21.2495 3.85156 17.5994 3.85156 13.1095C3.85156 11.3695 4.42156 9.68945 5.50156 8.24945C5.75156 7.91945 5.68156 7.44945 5.35156 7.19945C5.02156 6.94945 4.55156 7.01945 4.30156 7.34945C3.02156 9.04945 2.35156 11.0395 2.35156 13.1095C2.35156 18.4195 6.67156 22.7495 11.9916 22.7495C17.3116 22.7495 21.6316 18.4295 21.6316 13.1095C21.6316 7.78945 17.3116 3.47945 12.0016 3.47945Z"
              fill="#fff"
            />
          </svg>
        </button>
        <button
          className="play-forward"
          onClick={() => handleForward()}
          disabled={!videos.length > 0}
        >
          <svg
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4314 16.9203H12.1414C11.7314 16.9203 11.3914 16.5803 11.3914 16.1703C11.3914 15.7603 11.7314 15.4203 12.1414 15.4203H14.4314C14.8614 15.4203 15.2114 15.0703 15.2114 14.6403C15.2114 14.2103 14.8614 13.8603 14.4314 13.8603H12.1414C11.9014 13.8603 11.6714 13.7403 11.5314 13.5503C11.3914 13.3603 11.3514 13.1003 11.4314 12.8703L12.1914 10.5803C12.2914 10.2703 12.5814 10.0703 12.9014 10.0703H15.9614C16.3714 10.0703 16.7114 10.4103 16.7114 10.8203C16.7114 11.2303 16.3714 11.5703 15.9614 11.5703H13.4414L13.1814 12.3603H14.4314C15.6914 12.3603 16.7114 13.3803 16.7114 14.6403C16.7114 15.9003 15.6814 16.9203 14.4314 16.9203Z"
              fill="#fff"
            />
            <path
              d="M9.54041 16.9208C9.13041 16.9208 8.79041 16.5808 8.79041 16.1708V12.7808L8.60041 13.0008C8.32041 13.3108 7.85041 13.3308 7.54041 13.0608C7.24041 12.7808 7.21041 12.3108 7.49041 12.0008L8.99041 10.3308C9.20041 10.1008 9.53041 10.0208 9.82041 10.1308C10.1104 10.2408 10.3004 10.5208 10.3004 10.8308V16.1808C10.2904 16.5908 9.96041 16.9208 9.54041 16.9208Z"
              fill="#fff"
            />
            <path
              d="M19.6916 7.3488C19.4416 7.0188 18.9716 6.9488 18.6416 7.1988C18.3116 7.4488 18.2416 7.9188 18.4916 8.2488C19.5716 9.6888 20.1416 11.3688 20.1416 13.1088C20.1416 17.5988 16.4916 21.2488 12.0016 21.2488C7.51156 21.2488 3.86156 17.5988 3.86156 13.1088C3.86156 8.6188 7.51156 4.9788 12.0016 4.9788C12.5816 4.9788 13.1716 5.0488 13.8116 5.1988C13.8416 5.2088 13.8716 5.1988 13.9016 5.1988C13.9316 5.1988 13.9516 5.2188 13.9716 5.2188C14.0016 5.2188 14.0216 5.2088 14.0516 5.2088C14.0816 5.2088 14.1116 5.1988 14.1516 5.1888C14.2116 5.1788 14.2616 5.1488 14.3116 5.1288C14.3416 5.1088 14.3716 5.0988 14.4016 5.0788C14.4116 5.0688 14.4316 5.0688 14.4416 5.0588C14.4716 5.0388 14.4816 5.0088 14.5016 4.9888C14.5416 4.9488 14.5716 4.9188 14.6016 4.8688C14.6316 4.8288 14.6416 4.7788 14.6616 4.7288C14.6716 4.6988 14.6916 4.6688 14.7016 4.6388C14.7016 4.6188 14.7016 4.6088 14.7016 4.5888C14.7116 4.5388 14.7116 4.4888 14.7016 4.4388C14.7016 4.3888 14.7016 4.3488 14.6916 4.2988C14.6816 4.2588 14.6616 4.2188 14.6416 4.1688C14.6216 4.1188 14.6016 4.0688 14.5716 4.0288C14.5716 4.0188 14.5716 4.0088 14.5616 3.9988L12.5816 1.5288C12.3216 1.2088 11.8516 1.1488 11.5316 1.4088C11.2116 1.6688 11.1616 2.1388 11.4116 2.4588L12.2316 3.4788C12.1516 3.4788 12.0716 3.4688 11.9916 3.4688C6.68156 3.4688 2.35156 7.7888 2.35156 13.1088C2.35156 18.4288 6.67156 22.7488 11.9916 22.7488C17.3116 22.7488 21.6316 18.4288 21.6316 13.1088C21.6416 11.0388 20.9616 9.0488 19.6916 7.3488Z"
              fill="#fff"
            />
          </svg>
        </button>
        <div
          className="volume-control"
          onMouseEnter={() => !videos.length > 0 || handleSliderOpen()}
          onMouseLeave={() => !videos.length > 0 || handleSliderOpen()}
        >
          <button
            className="play-volume"
            onClick={() => handleMuted()}
            disabled={!videos.length > 0}
          >
            {!playerData.muted ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#fff"
                className="bi bi-volume-down"
                viewBox="0 0 16 16"
              >
                <path d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zM6.312 6.39 8 5.04v5.92L6.312 9.61A.5.5 0 0 0 6 9.5H4v-3h2a.5.5 0 0 0 .312-.11zM12.025 8a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#fff"
                className="bi bi-volume-off"
                viewBox="0 0 16 16"
              >
                <path
                  transform="translate(-2 0)"
                  d="M10.717 3.55A.5.5 0 0 1 11 4v8a.5.5 0 0 1-.812.39L7.825 10.5H5.5A.5.5 0 0 1 5 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM10 5.04 8.312 6.39A.5.5 0 0 1 8 6.5H6v3h2a.5.5 0 0 1 .312.11L10 10.96V5.04z"
                />
              </svg>
            )}
          </button>
          {showSlider && (
            <input
              className="volume-slider"
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={playerData.volume}
              onChange={handleVolumeChange}
            />
          )}
        </div>
      </div>
      <div className="controls-time">{`${formatedCurrentTotalTime.minutes}:${formatedCurrentTotalTime.seconds}`}</div>
      <div className="rigth-controls space-x-2">
        <button
          onClick={onAddTextOverlay}
          title={t('Message.video.info.overlay_texts')}
          disabled={!videos.length > 0}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#fff"
            className="bi bi-circle"
            viewBox="0 0 16 16"
            style={{ margin: 5 }}
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 -960 960 960"
              fill="#fff"
            >
              <path d="m168-284 148-398h48l149 398h-48l-39-111H254l-39 111h-47Zm101-151h142l-70-196h-2l-70 196Zm393 160q-44 0-70-23.5T566-362q0-42 30.5-68.5T676-457q21 0 41 4t34 12v-20q0-35-19-54t-55-19q-21 0-39 7.5T604-504l-29-25q21-22 45.5-32t56.5-10q57 0 86 28.5t29 85.5v173h-40v-38h-4q-14 23-35.5 35T662-275Zm3-35q37 0 62-27.5t25-68.5q-13-8-31.5-12t-36.5-4q-35 0-55.5 16T608-362q0 24 15.5 38t41.5 14Z" />
            </svg>
          </svg>
        </button>
        <button
          onClick={handleImportImage}
          title={t('Message.video.info.images')}
          disabled={!videos.length > 0}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 -960 960 960"
            fill="#fff"
          >
            <path d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm56-97h489L578-473 446-302l-93-127-117 152Zm-56 97v-600 600Zm160.118-390Q361-570 375.5-584.618q14.5-14.617 14.5-35.5Q390-641 375.382-655.5q-14.617-14.5-35.5-14.5Q319-670 304.5-655.382q-14.5 14.617-14.5 35.5Q290-599 304.618-584.5q14.617 14.5 35.5 14.5Z" />
          </svg>
        </button>
        <button
          onClick={showGuideGrid}
          title={t('Message.video.info.guide_grid')}
          disabled={!videos.length > 0}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="26"
            viewBox="0 -960 960 960"
            width="26"
            fill="#fff"
          >
            <path d="M215-80v-135H80v-60h135v-175H80v-60h135v-175H80v-60h135v-135h60v135h175v-135h60v135h175v-135h60v135h135v60H745v175h135v60H745v175h135v60H745v135h-60v-135H510v135h-60v-135H275v135h-60Zm60-195h175v-175H275v175Zm235 0h175v-175H510v175ZM275-510h175v-175H275v175Zm235 0h175v-175H510v175Z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

InsideControls.propTypes = {
  className: PropTypes.string,
  videos: PropTypes.array,
  startAt: PropTypes.number,
  handleVideoEnd: PropTypes.func,
  endAt: PropTypes.number,
  videoRef: PropTypes.object,
  playerData: PropTypes.object,
  setPlayerData: PropTypes.func,
  currentVideoIndex: PropTypes.number,
  setCurrentVideoIndex: PropTypes.func,
  setCurrentTime: PropTypes.func,
  currentTotalTime: PropTypes.number,
  formatedCurrentTotalTime: PropTypes.object,
  overlayTexts: PropTypes.array,
};
