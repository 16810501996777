import Skeleton from "../../loaders/Skeleton/Skeleton";

const SkeletonRow = ({ hasCheckable, header }) => {
  return (
    <tr className="relative px-6 sm:w-12 sm:px-rounded-tl-xl rounded-bl-xl">
      {hasCheckable && (
        <td className="flex justify-center items-center w-full h-full p-1 2xl:p-2">
          <Skeleton height={20} width={20} type="circle" />
        </td>
      )}
      {header.map((item, index) => (
        <td
          key={index}
          className="border-r h-full border-gray-border whitespace-nowrap p-1 2xl:p-2 text-xs 2xl:text-sm text-left font-medium "
        >
          <Skeleton height={40} />
        </td>
      ))}
    </tr>
  );
};

export default SkeletonRow;