import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import './styles/TextOverlay.css';
import { useTranslation } from 'react-i18next';

const commonFonts = [
  'Helvetica',
  'Times New Roman',
  'Courier New',
  'Verdana',
  'Georgia',
  'Palatino',
  'Garamond',
  'Comic Sans MS',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Trebuchet MS',
  'Arial Black',
  'Consolas',
  'Monaco',
  'Arial Narrow',
  'Century Gothic',
  'Book Antiqua',
  'Copperplate',
  'Franklin Gothic Medium',
  'Brush Script MT',
  'Symbol',
  'Webdings',
  'Wingdings',
];

const TextOverlayMenu = ({
  isOpen,
  options,
  setOptions,
  onDelete,
  mergeTag = true,
  onAddMergeTag,
  onChanged,
}) => {
  const colorPicker = useRef();
  const { t } = useTranslation();

  const handleTextColorChange = (event) => {
    setOptions({
      ...options,
      color: event.target.value,
    });
    onChanged(true);
  };

  const handleFontSizeChange = (event) => {
    setOptions({
      ...options,
      fontSize: `${event.target.value}`,
    });
    onChanged(true);
  };

  const handleFontFamilyChange = (event) => {
    setOptions({
      ...options,
      fontFamily: event.target.value,
    });
    onChanged(true);
  };

  const handleOpenColorPicker = () => {
    colorPicker.current.click();
  };

  return (
    <div
      className="text_overlay-menu"
      style={{ display: isOpen ? 'block' : 'none', zIndex: 99999 }}
    >
      <ul className="text_overlay-menu-list">
        <li className="select-font">
          <select value={options.fontFamily} onChange={handleFontFamilyChange}>
            <option value="Arial">Arial</option>
            {commonFonts.map((font, index) => (
              <option key={index} value={font}>
                {font}
              </option>
            ))}
          </select>
        </li>
        <li className="color-option" onClick={handleOpenColorPicker}>
          <label htmlFor="colorPicker">Color</label>
          <input
            ref={colorPicker}
            id="colorPicker"
            type="color"
            value={options.color}
            onChange={handleTextColorChange}
            style={{ backgroundColor: options.color }}
          />
        </li>
        {mergeTag && (
          <li onClick={onAddMergeTag}>
            <div className="merge-tag-option">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                viewBox="0 -960 960 960"
                width="30"
                fill="#d9d9d9"
                style={{ marginRight: 10 }}
              >
                <path d="M450-200v-250H200v-60h250v-250h60v250h250v60H510v250h-60Z" />
              </svg>
              <span style={{ marginRight: 20 }}>{t('Edition.ToolBar.add_name')}</span>
            </div>
          </li>
        )}
        <li className="font-size-option">
          <label htmlFor="fontSize">{t('Edition.size')}</label>
          <input
            type="number"
            id="fontSize"
            min={5}
            max={200}
            value={parseFloat(options.fontSize)}
            checked={parseFloat(options.fontSize) < 200 && parseFloat(options.fontSize) > 0}
            onChange={handleFontSizeChange}
            autoComplete="off"
            autoSave="off"
            autoFocus={false}
          />
        </li>

        <li onClick={onDelete}>
          <span>{t('Edition.ToolBar.delete')}</span>
        </li>
      </ul>
    </div>
  );
};

export default function TextOverlay({
  parentRef,
  index,
  data,
  currentTime,
  onDelete = () => {},
  onSave = () => {},
  className = '',
  editable = true,
  mergeTag = true,
  onInEdition
}) {
  const classname = `text_overlay ${className}`;

  const { t } = useTranslation();
  const draggableRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  // Para poner el texto en edición o no
  const [inEdition, setInEdition] = useState(data.text?.length === 0 || false);

  // Para abrir y cerrar el menu de opciones para definir tamaño, fuente y color de la letra
  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  // Guarda el valor del texto, incluyendo saltos de linea
  const [text, setText] = useState('');

  const textareaRef = useRef(null);

  const preTextRef = useRef(null);

  // Guarda la posición del texto dentro del video
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [startSecond, setStartSecond] = useState(0);

  const [duration, setDuration] = useState(20);

  const [hidden, setHidden] = useState(false);

  // Para saber si se hizo algún cambio y no guardar por gusto
  const [changed, setChanged] = useState(false);

  // Para guardar las opciones del texto escogidas
  const [styleText, setStyleText] = useState(
    data.styleText || {
      color: '#ffffff',
      fontSize: '16px',
      fontFamily: 'Arial',
    },
  );

  // Para calcular el ancho que ocupa el texto que se ha escrito para aplicarle este ancho al textarea
  const calculateTextWidth = useMemo(() => {
    // Crear un elemento de lienzo (canvas)
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Establecer el tamaño de fuente y la familia de fuente
    context.font = `${parseFloat(styleText.fontSize)}px ${styleText.fontFamily}`;

    const newText = text.length > 0 ? text : t('Edition.write_here');

    const lineArray = newText.split('\n'); // Dividir el texto en un array de líneas

    let majorWidth = -1;

    lineArray.forEach((text) => {
      const width = context.measureText(text).width;
      if (width > majorWidth) {
        majorWidth = width;
      }
    });

    const width = majorWidth;

    // Devolver el ancho en píxeles
    return width;
  }, [text, styleText.fontSize, styleText.fontFamily]);

  // Para establecer el tamaño máximo del textarea para que no se salga del contendor
  const setMaxSize = useCallback(() => {
    if (textareaRef && textareaRef.current) {
      const containerWidth = parentRef.current.offsetWidth;
      const containerHeight = parentRef.current.offsetHeight;

      const textarea = textareaRef.current;
      textarea.style.maxWidth = `${containerWidth - position.x}px`;
      textarea.style.maxHeight = `${containerHeight - position.y}px`;
    }
  }, [parentRef, position.x, position.y]);

  // Para ajustar el tamaño del textarea a medida que se escribe en el
  const adjustTextArea = useCallback(() => {
    if (textareaRef && textareaRef.current) {
      const width = calculateTextWidth;

      const textarea = textareaRef.current;

      textarea.style.width = `${width + 2 * 12}px`;

      textarea.style.height = 'auto'; // Restablece la altura a la predeterminada
      // Establece la altura del textarea para que se ajuste al contenido
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [calculateTextWidth]);

  // Para guadar los cambios realizados sobre el texto
  const handleOnSave = useCallback(() => {
    if (changed) {
      const parentSize = {
        width: parentRef.current.style.width,
        height: parentRef.current.style.height,
      };

      const newData = {
        text,
        position,
        styleText,
        startSecond,
        duration, // Los textos duran 10 segundos por defecto luego se pueden cambiar
        parentSize,
      };

      if (text.length > 0) {
        onSave(index, newData);
        setIsFocus(false);
        setIsOpen(false);
        setChanged(false);
        setInEdition(false);
      }
    }
  }, [changed, styleText, duration, index, onDelete, onSave, position, startSecond, text]);

  // Para establcer los valores guardados en redux
  useEffect(() => {
    if (data) {
      // Esto es para que me permita editar
      const textCopy = JSON.parse(JSON.stringify(data.text || ''));
      const positionXcopy = JSON.parse(JSON.stringify(data.position?.x || 0));
      const positionYcopy = JSON.parse(JSON.stringify(data.position?.y || 0));
      const startSecondCopy = JSON.parse(JSON.stringify(data.startSecond || 0));
      const durationCopy = JSON.parse(JSON.stringify(data.duration || 20));
      const styleTextCopy = Object.assign(
        {},
        data.styleText || {
          color: '#ffffff',
          fontSize: '16px',
          fontFamily: 'Arial',
        },
      );

      setText(textCopy);
      setPosition({ x: positionXcopy, y: positionYcopy });
      setStartSecond(startSecondCopy);
      setDuration(durationCopy);
      setStyleText(styleTextCopy);
    }
  }, [data]);

  // Para ocultar o mostrar el texto cuando llega el tiempo inicial o final para mostrarse
  useEffect(() => {
    const endSecond = startSecond + duration;
    setHidden(currentTime >= startSecond && currentTime <= endSecond);
  }, [currentTime, startSecond, duration]);

  // Para el drag and drop del textarea
  useEffect(() => {
    if (parentRef) {
      const containerRect = parentRef.current.getBoundingClientRect();
      const draggableRect = draggableRef.current.getBoundingClientRect();

      const containerWidth = containerRect.width;
      const containerHeight = containerRect.height;

      const draggableWidth = draggableRect.width;
      const draggableHeight = draggableRect.height;

      const handleMouseMove = (e) => {
        if (!isDragging || editable == false) return;

        const offsetX = e.clientX - dragStart.x;
        const offsetY = e.clientY - dragStart.y;

        let newX = position.x + offsetX;
        let newY = position.y + offsetY;

        // Verificar límites del contenedor
        if (newX < 0) newX = 0;
        if (newY < 0) newY = 0;
        if (newX + draggableWidth > containerWidth) newX = containerWidth - draggableWidth;
        if (newY + draggableHeight > containerHeight) newY = containerHeight - draggableHeight;

        setPosition({ x: newX, y: newY });
        setDragStart({ x: e.clientX, y: e.clientY });

        // Ajustar tamaño máximo de acuerdo a posicón
        setMaxSize();
        setChanged(true);
      };

      const handleMouseUp = () => {
        setIsDragging(false);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isDragging, dragStart, position, parentRef, setMaxSize]);

  // Para ajustar el tamaño del texto del textarea cuando se cambie el tamaño con el responsive del contendor
  useEffect(() => {
    // Para ajustar los tamaños de todas las partes de componente de manera responsiva
    if (parentRef && preTextRef && preTextRef.current) {
      const adjustFontSize = () => {};

      window.addEventListener('resize', adjustFontSize);

      adjustFontSize();

      return () => {
        window.removeEventListener('resize', adjustFontSize);
      };
    }
  }, [parentRef, preTextRef]);

  // Para ocultar el menu y quitar el foco del textarea cuando se da un click fuera de este
  useEffect(() => {
    if (parentRef) {
      const showOptions = (event) => {
        if (draggableRef.current && !draggableRef.current.contains(event.target)) {
          setIsOpen(false);
          setIsFocus(false);
        }
      };

      parentRef.current.addEventListener('click', showOptions, true);
    }
  }, [parentRef]);

  // para mandar que ya no esta enfocado
  useEffect(() => {
    if (onInEdition) onInEdition(isFocus)
  }, [isFocus])

  // Para ajustar el tamaño del text area e imperdir que salga del tamaño del texto
  useEffect(() => {
    adjustTextArea();
    setMaxSize();
  }, [text, setMaxSize, adjustTextArea]);

  // Para guardar el texto del textarea
  const handleChange = (event) => {
    setText(event.target.value);
    if (text.length > 0) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  // Para manejar los saltos de línea en el textarea y poder guardar el texto con saltos de linea incluidos
  const handleLineBreak = (event) => {
    if (textareaRef && textareaRef.current && event.key === 'Enter') {
      event.preventDefault();

      const { selectionStart, selectionEnd } = textareaRef.current;

      const firstPart = text.slice(0, selectionStart);
      const secondPart = text.slice(selectionEnd, text.length);

      const newText = `${firstPart}\n${secondPart}`;
      setText(newText);
      textareaRef.current.focus();
    }
  };

  // Para el movimiento del textarea
  const handleMouseDown = (e) => {
    setDragStart({ x: e.clientX, y: e.clientY });
    setIsDragging(true);
  };

  const handleResize = () => {};

  // Para abrir y cerrar el menu de opciones
  const toggleMenu = () => {
    if (textareaRef && textareaRef.current) {
      setIsOpen(!isOpen);
      textareaRef.current.focus();
    }
  };

  // Para cuando se enfoca el textarea
  const handleOnFocus = () => {
    setIsFocus(true);
  };

  // Para añadir nombre a los textos
  const addMergeTag = () => {
    if (textareaRef && textareaRef.current) {
      const { selectionStart, selectionEnd } = textareaRef.current;
      const mergeTag = t('Edition.merge_tag');
      const firstPart = text.slice(0, selectionStart);
      const secondPart = text.slice(selectionEnd, text.length);
      const newText = `${firstPart}${mergeTag}${secondPart}`;
      setText(newText);
      // Por si solo pone una etiqueta merge en el textarea
      setChanged(true);
      textareaRef.current.focus();
      textareaRef.current.selectionStart = selectionStart + mergeTag.length;
      textareaRef.current.selectionEnd = selectionStart + mergeTag.length;
    }
  };

  const calculateScale = useMemo(() => {
    const originalWidth = 681; // Ancho original
    const originalHeight = 383; // Altura original
  
    const destinationWidth = parentRef?.current?.clientWidth
    const destinationHeight = parentRef?.current?.clientHeight
  
    const scaleX = destinationWidth / originalWidth;
    const scaleY = destinationHeight / originalHeight;
  
    // Usamos el mínimo para mantener la relación de aspecto
    const scale = Math.min(scaleX, scaleY);
  
    return scale;
  }, [parentRef.current?.clientWidth, parentRef.current?.clientHeight]);

  return (
    <div
      className={classname}
      ref={draggableRef}
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
      }}
      hidden={!hidden}
      onBlur={handleOnSave}
    >
      <div
        className="text_overlay-move"
        onMouseDown={handleMouseDown}
        style={{ display: isFocus ? 'flex' : 'none' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          viewBox="0 -960 960 960"
          width="18"
          fill="#faebd7"
        >
          <path d="M480-80 317-243l44-44 89 89v-252H198l84 84-44 44L80-480l159-159 44 44-85 85h252v-252l-84 84-44-44 158-158 158 158-44 44-84-84v252h252l-84-84 44-44 158 158-158 158-44-44 84-84H510v252l89-89 44 44L480-80Z" />
        </svg>
      </div>

      <div className="text_overlay-options" style={{ display: isFocus ? 'flex' : 'none' }}>
        <button className="text_overlay-options-button" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            viewBox="0 -960 960 960"
            width="18"
            fill="#faebd7"
          >
            <path d="M479.858-160Q460-160 446-174.142q-14-14.141-14-34Q432-228 446.142-242q14.141-14 34-14Q500-256 514-241.858q14 14.141 14 34Q528-188 513.858-174q-14.141 14-34 14Zm0-272Q460-432 446-446.142q-14-14.141-14-34Q432-500 446.142-514q14.141-14 34-14Q500-528 514-513.858q14 14.141 14 34Q528-460 513.858-446q-14.141 14-34 14Zm0-272Q460-704 446-718.142q-14-14.141-14-34Q432-772 446.142-786q14.141-14 34-14Q500-800 514-785.858q14 14.141 14 34Q528-732 513.858-718q-14.141 14-34 14Z" />
          </svg>
        </button>

        <button className="text_overlay-save-button" onClick={() => handleOnSave(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            viewBox="0 -960 960 960"
            width="18"
            fill="#faebd7"
          >
            <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
          </svg>
        </button>

        {/* Este es un menú desplegable */}
        <TextOverlayMenu
          isOpen={isOpen}
          options={styleText}
          setOptions={setStyleText}
          onDelete={() => onDelete(index)}
          onAddMergeTag={addMergeTag}
          onChanged={setChanged}
          mergeTag={mergeTag}
        />
      </div>

      {inEdition ? (
        <textarea
          className="text_overlay-text"
          ref={textareaRef}
          value={text}
          onChange={handleChange}
          placeholder="Escriba aqui..."
          onFocus={handleOnFocus}
          onInput={handleResize}
          onKeyDown={handleLineBreak}
          autoFocus={false}
          style={{
            ...styleText,
            fontSize: styleText.fontSize * calculateScale,
            resize: isFocus ? 'vertical' : 'none',
          }}
        />
      ) : (
        <div
          className="text_overlay-edited-text"
          onClick={() => {
            if (editable) {
              setInEdition(true);
              setIsFocus(true);
              setTimeout(() => adjustTextArea(), 300);
            }
          }}
          style={{ padding: 12 }}
        >
          <pre
            className="text_overlay-pretext"
            ref={preTextRef}
            style={{
              ...styleText,
              fontSize: styleText.fontSize * calculateScale,
            }}
          >
            {text}
          </pre>
        </div>
      )}
    </div>
  );
}

TextOverlayMenu.propTypes = {
  isOpen: PropTypes.bool,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  onDelete: PropTypes.func,
  onAddMergeTag: PropTypes.func,
  onChanged: PropTypes.func,
};

TextOverlay.propTypes = {
  parentRef: PropTypes.object, // Referencia a ElementOverlayContainer
  index: PropTypes.number, // Indice en la lista de textos superpuestos
  currentTime: PropTypes.number,
  data: PropTypes.object, // Datos del texto superpuesto
  onDelete: PropTypes.func, // Para eliminar este texto superpuesto
  onSave: PropTypes.func, // Para guardar los cambios realizados en un texto superpuesto (sirve para ocultarlo o mostrarlo también)
};
