import { useTranslation } from 'react-i18next';
import HeroImg from '../../assets/img/hero-img.png';
import { Title } from '../../components/common';
import useAuthContext from '../../hooks/useAuthContext';

export function Hero() {
  const { t } =  useTranslation();
  const { user } = useAuthContext();
  
  return (
    <div className="bg-white">
      <div className="relative isolate ">
        <div className="lg:flex lg:items-start lg:gap-x-10 ">
          <div className="mx-auto max-w-2xl mt-10 lg:mx-0 lg:flex-auto">
            <Title title={t('Dashboard.title2', { name: user?.name || ''})} size='text-lg 2xl:text-xl' />
            <p className="mt-6 text-xl font-normal leading-9" style={{ color: '#666' }}>
              {t('Dashboard.subtitle1-1')}
              <span className="font-bold"> Virtual Vendor </span>
              {t('Dashboard.subtitle1-2')}
            </p>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow md:w-2/5 mx-auto">
            <img src={HeroImg} alt="" className="aspect-auto w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}
