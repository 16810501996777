import { Title } from '../common';
import { InsightList } from '.';
import { Breadcumbs } from '../../components/library';
import Select from 'react-select/creatable';
import { PlayIcon } from '@heroicons/react/20/solid';
import Chart from 'react-apexcharts';
import { useState } from 'react';

const styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: 48,
    width: '100%',
    flex: 1,
  }),
};

const Details = ({ name, agent = 'Agustina Ch.', dateList = [] }) => {
	/* State */
	const [options, setOptions] = useState({
    chart: {
      id: 'basic-line',
    },
    xaxis: {
      categories: ["", '', 10,"", '', 20,"", 31],
    },
    colors: ['#f73757', '#E91E63', '#9C27B0'],
  });

  const [series, setSeries] = useState([
    {
      name: 'series-1',
      data: [10, 90, 30, 70, 10, 105, 25, 85],
    },
  ]);
	
  return (
    <div className="my-10 space-y-8">
      <div className="w-fit">
        <Select options={dateList} placeholder="Fechas" styles={styles} />
      </div>
      <div className="flex w-full 2xl:space-x-10 2xl:space-y-0 space-y-4 flex-wrap">
        <div className="flex 2xl:flex-col 2xl:space-y-3 2xl:space-x-0 space-x-4">
          <div className="relative flex w-[298px] h-[171px] bg-gray-900 rounded-xl justify-center items-center">
            <div className="absolute flex w-1/5 aspect-square border-4 border-gray-200 rounded-full items-center-cen justify-center cursor-pointer hover:border-primary transition-colors">
              <PlayIcon className="text-gray-200 ml-1 p-2" />
            </div>
            <div className="absolute bottom-0 left-0 m-2 mx-4 text-sm text-gray-500">00:00</div>
          </div>
          <div className='flex flex-col justify-evenly 2xl:items-start items-center space-y-2'>
            <div className="flex flex-col">
              <span className="text-xl text-gray-500 font-bold">{name}</span>
              <span className="text-md text-gray-500 font-bold">{agent}</span>
              <span className="text-sm text-gray-400 font-bold mt-1">10/03/2023</span>
            </div>
            <button className="flex justify-center items-center w-[163px] h-[45px] bg-primary font-semibold text-white rounded-xl hover:shadow-xl">
              Ir a landing
            </button>
          </div>
        </div>
        <div className="flex-grow" style={{minHeight: 300}}>
          <Chart options={options} series={series} type="line" height={'100%'} />
        </div>
      </div>
    </div>
  );
};

const InsightVideo = ({ insights, name = 'Video final RRHH' }) => {
  return (
    <>
      <Breadcumbs path={`Estadísticas de vídeo/${name}`} className={'mb-4 -mt-4'} />
      <Title size="text-2xl" title="Estadísticas de vídeo" />
      <Details name={name} />
      <InsightList items={insights} downloads searchBar={false} />
    </>
  );
};

export default InsightVideo;
