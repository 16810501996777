
// lang
import i18n from 'i18next';

export const headers = (token) => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token,
    'lang': i18n.language
  }
  return headers;
}

export const headersFile = (token) => {
  const headers = {
    'Authorization': 'Bearer ' + token,
    'lang': i18n.locale
  }
  return headers;
}