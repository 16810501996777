import { classNames } from "../../../../config/utils";

export const MenuLinks = ({ links }) => {
  /* functions */
  const urlParsed = (url) => {
    return /^https?:\/\//.test(url) ? url : `http://${url}`;
  };

  return (
    <div
      className={`absolute -top-1 left-[100%] w-fit bg-white z-20 rounded-xl shadow-lg p-1 pb-0 flex flex-col justify-center items-center space-y-0 border-[1px] border-gray-300`}
    >
      {links.link.map((link, index) => (
        <a
          href={urlParsed(link.urlLink)}
          target={'_blank'}
          className={classNames(
            `w-full text-center text-[8px] text-gray-700 2xl:text-[12px] py-1 px-3 font-semibold select-none whitespace-nowrap hover:underline hover:text-primary`,
            index > 0 && 'border-t border-gray-300',
          )}
          //style={{ color: `${links.colorButtonsLinks}` }}
          key={index}
        >
          {link.textLink}
        </a>
      ))}
    </div>
  );
};