import ApiRoutes from '../../routes/api/paths';
import { headers } from '../../config/headers';

const handleResponse = async (response) => {
  if (!response.ok) {
    return Promise.reject(response.status);
  }
  const data = await response.json();
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve(data);
  }
};

const sendRequest = async (url, requestOptions) => {
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      return Promise.reject({
        status: error,
      });
    });
};

export const SearchService = async (params) => {
  const { token, query } = params;
  const url = `${ApiRoutes.search}/${query}`;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(url, requestOptions);
}