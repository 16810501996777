import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  labelFor: '',
  acceptAction: null,
  openConfirmModal: false,
  elements: [],
  refetch: false,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    logout: () => initialState,
    setOpen: (state, action) => {
      state.openConfirmModal = action.payload;
    },
    setLabelFor: (state, action) => {
      state.labelFor = action.payload;
    },
    setAcceptAction: (state, action) => {
      state.acceptAction = action.payload;
    },
    setElements: (state, action) => {
      state.elements = action.payload;
    },
    setRefetch: (state, action) => {
      state.refetch = action.payload;
    },
    resetStore: (state, action) => {
      state.openConfirmModal = false;
      state.openAddModal = false;
      state.refetch = false;
      state.acceptAction = null;
      state.elements = [];
      state.labelFor = '';
    },
  },
});

/* Selectors */
export const open = (state) => state.common.openConfirmModal;
export const labelFor = (state) => state.common.labelFor;
export const elements = (state) => state.common.elements;
export const refetch = (state) => state.common.refetch;
export const action = (state) => state.common.acceptAction;

/* Actions  */
export const { logout, setOpen, setLabelFor, setAcceptAction, setElements, setRefetch, resetStore, setOpenAddModal } =
  commonSlice.actions;

export default commonSlice.reducer;
