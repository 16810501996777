import { classNames } from "../../../../config/utils";
import { useTable } from "../context/TableContext";
import ListField from "./Fields/ListField";

const ItemRow = ({ index, row }) => {
  const {
    selectedRow,
    handleSelectRow,
    hasSelectable,
    hasCheckable,
  } = useTable()
  
  return (
    <tr
      key={`tr-${row[0]?.id || index}`}
      className={classNames(
        selectedRow.some(s => s.id === row[0]?.id) ? 'bg-gray-300' : undefined,
        hasSelectable && 'cursor-pointer',
        hasSelectable && selectedRow.some(s => s.id === row[0]?.id) && 'bg-red-100',
        'bg-gray-100 relative px-6 sm:w-12 sm:px-rounded-tl-xl rounded-bl-xl',
      )}
      onClick={() => hasSelectable && handleSelectRow(row[0].id)}
    >
      {/* add checkbox field, if checkable */}
      {hasCheckable && (
        <td
          className="relative border-r border-gray-border first:rounded-tl-xl first:rounded-bl-xl"
        >
          <div className="py-2.5 flex justify-center items-center">
            <input
              checked={Boolean(selectedRow.find((e) => e.id === row[0].id))}
              type={'checkbox'}
              className="w-4 2xl:w-6 h-4 2xl:h-6 rounded 2xl:rounded-md border-2 focus:outline-none cursor-pointer hover:border-primary duration-100 checked:bg-primary checked:border-transparent focus:ring-0 focus:ring-offset-0 focus:ring-primary hover:text-primary focus:text-primary"
              onChange={() => {
                !hasSelectable && handleSelectRow(row[0].id);
              }}
            />
          </div>
        </td>
      )}

      {/* rest fields */}
      <ListField 
        index={index} 
        row={row} 
        selectedRow={selectedRow} 
      />
    </tr>
  )
}
export default ItemRow