import { Skeleton } from '../../../../components/common';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { classNames } from '../../../../config/utils';

const FileLoading = ({ file }) => {
  return (
    <div
      className={classNames(
        'relative w-[130px] 2xl:w-[183.3px] h-[115px] 2xl:h-[128px] rounded-md shadow-md cursor-pointer overflow-hidden bg-gray-500',
      )}
    >
      {file?.type === 'video' ? (
        <video
          src={file.preview}
          className="absolute inset-0 object-cover object-center shadow-lg"
          // eslint-disable-next-line react/no-unknown-property
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      ) : (
        <img
          src={file.preview}
          className="absolute inset-0 object-cover object-center shadow-lg"
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      )}
      {file.title && (
        <div className="absolute bottom-0 left-0 w-full h-10 flex items-center z-10 justify-start px-3">
          <span className="text-sm text-gray-200 select-none truncate">{file.title}</span>
        </div>
      )}
      <div className="absolute w-8 aspect-square top-2 right-2 flex items-center justify-center z-10 rounded-full p-1 bg-gray-700">
        <CircularProgressbar
          value={file.progress}
          maxValue={100}
          styles={buildStyles({
            pathColor: '#f73757',
          })}
        />
      </div>
      <div className='absolute inset-0'>
        <Skeleton />
      </div>
    </div>
  );
};

export const FileListLoading = ({ title, files = [] }) => {
  return (
    <div className="w-full mb-5 2xl:mb-7 select-none">
      <div className="flex items-center justify-between mb-2 2xl:mb-4">
        <h3 className="text-md 2xl:text-lg text-gray-700 font-semibold">{title}</h3>
      </div>
      <div className="grid grid-cols-4 gap-2 2xl:gap-4">
        {files.map((file, index) => (
          <FileLoading key={file.id + '-loading-' + index} file={file} />
        ))}
      </div>
    </div>
  );
};
