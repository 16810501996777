import { Fragment, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Dropzone } from '..';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Title } from '../../common';
import FolderContextProvider, { FolderContext } from '../../../contexts/folderContext';
import { classNames } from '../../../config/utils';
import { BsPlus } from 'react-icons/bs';
import useAuthContext from '../../../hooks/useAuthContext';
import { DestinationView } from './Destination/DestinationView';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import useUpload from '../../../hooks/useUpload';
import { postAddPortrait } from '../../../services/library/LibraryService';
// import { LIMIT_IMG_SIZE } from '../../../config/common';
import { useToast2 } from '../../../hooks/useToast2';
import UploadFileItem from './CreateModal/UploadFileItem';

const ImageLoader = ({ className = '', handleAddMorePortrait }) => {
  return (
    <div
      className={classNames(
        className,
        'w-[105px] 2xl:w-[133px] h-[105px] 2xl:h-[133px] relative bg-gray-100 rounded-xl flex justify-center items-center overflow-hidden',
      )}
    >
      <div className="w-10 relative aspect-square rounded-full flex justify-center items-center bg-gray-200 cursor-pointer duration-200 hover:shadow-md z-10 opacity-70">
        <BsPlus size={40} className="text-gray-400" />
        <input
          className="absolute w-full h-full opacity-0"
          type="file"
          accept="image/*"
          onChange={handleAddMorePortrait}
        />
      </div>
    </div>
  );
};

export function CreatePortraitModal({ onReadyDownloads, open }) {
  const [files, setFiles] = useState([]);
  const [showDestination, setShowDestination] = useState(false);
  const { upload } = useUpload();
  const { showToast } = useToast2();

  const { setModals, library } = useContext(FolderContext);

  /* Hooks */
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // rq
  // const { maximo_portraits } = queryClient.getQueryData('systemInfo');

  /* Functions */
  const handleFiles = (files) => {
    onReadyDownloads(files);
    setFiles(files);
  };

  const handleAddMorePortrait = (e) => {
    setFiles((prev) => {
      let newFiles = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (!file.type.includes('image')) continue;
        // if (file.size > LIMIT_IMG_SIZE) {
        //   showToast({
        //     type: 'error',
        //     message: t('Message.drop.size_too_large', { size: LIMIT_IMG_SIZE / 1024 / 1024 }),
        //   });
        //   continue;
        // };
        
        newFiles.push(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
      }
      newFiles = newFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      return [...prev, ...newFiles];
    });
  };

  const handleAddPortrait = (folderId) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.type.includes('image')) continue;
      let name = file.name.split('.');
      name.pop();
      name = name.join('.');
      upload({
        title: name,
        preview: URL.createObjectURL(file),
        type: 'image',
        service: postAddPortrait,
        data: {
          folderId,
          image: file,
        },
        onUploaded: () => {
          queryClient.invalidateQueries([library]);
        },
      });
    };

    onClose();
  };

  const onClose = () => {
    setModals((prev) => ({ ...prev, uploadPortrait: false }));
    setTimeout(() => {
      setFiles([]);
    }, 500); //darle tiempo para la transicion
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-[#000000] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 max-w-7xl w-6/12 sm:p-2">
                <div className="flex justify-end">
                  <XMarkIcon
                    className="w-6 2xl:w-7 h-6 2xl:h-7 text-gray-600 hover:text-gray-800 cursor-pointer"
                    onClickCapture={onClose}
                  />
                </div>
                <div
                  className={classNames(
                    'mx-10 space-y-6 2xl:space-y-8',
                    showDestination ? 'mt-2' : 'mt-10',
                  )}
                >
                  <div className="flex justify-between items-center">
                    <Title title={t('Library.upload_portrait')} />
                  </div>
                  {showDestination ? (
                    <FolderContextProvider library="portrait">
                      <DestinationView
                        onSelected={handleAddPortrait}
                        library="portrait"
                        onClose={() => setShowDestination(false)}
                      />
                    </FolderContextProvider>
                  ) : (
                    <>
                      <div className="">
                        {!files.length ? (
                          <div>
                            <form className="mt-2 mb-12 2xl:mb-16">
                              <Dropzone onUpload={handleFiles} type="portrait" />
                            </form>
                          </div>
                        ) : (
                          <div className=" bg-gray-dropzone px-4 rounded-lg mb-4 space-x-1 flex flex-wrap items-start gap-2 p-4 h-[300px] overflow-scroll">
                            {files.map((file, index) => (
                              <div
                                className="relative inline-flex rounded-lg margin-0 py-1"
                                key={file.name}
                              >
                                <UploadFileItem 
                                  file={file}  
                                  type='image'
                                  onChangeName={(name) => {
                                    if (name === file.name) return;
                                    const newFile = new File([file], name, {type: file.type});
                                    const fileWithPreview = Object.assign(newFile, {
                                      preview: URL.createObjectURL(newFile),
                                    });
                                    setFiles((prev) => {
                                      const newFiles = [...prev];
                                      newFiles[index] = fileWithPreview;
                                      return newFiles;
                                    });                                  
                                  }}
                                />
                                <div
                                  className="absolute top-0 right-0 cursor-pointer h-fit bg-white rounded-full shadow-gray-700"
                                  onClick={() => {
                                    setFiles((prev) => {
                                      const newFiles = [...prev];
                                      newFiles.splice(index, 1);
                                      return newFiles;
                                    });
                                  }}
                                >
                                  <XMarkIcon className="w-4 h-4" />
                                </div>
                              </div>
                            ))}
                            <ImageLoader handleAddMorePortrait={handleAddMorePortrait} />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-start">
                        {files.length > 0 && (
                          <>
                            <button
                              className="text-sm 2xl:text-md text-gray-300 px-6 rounded-xl py-2 2xl:py-2.5 mb-10 hover:shadow mt-2 border-gray-300 border-[1px] mr-2"
                              onClick={onClose}
                            >
                              {t('Library.cancel')}
                            </button>
                            <button
                              className="bg-primary text-sm 2xl:text-md text-white px-4 rounded-xl py-2 2xl:py-2.5 mb-10 hover:shadow mt-2"
                              onClick={() => {
                                setShowDestination(true);
                              }}
                            >
                              {t('Library.select_location')}
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
