import { FolderContext } from '../../../../contexts/folderContext';
import { useContext } from 'react';
import { IoImageOutline } from 'react-icons/io5';
import { classNames, generateImageUrl } from '../../../../config/utils';

const File = ({ file, getFileId, multiple, type }) => {
  const { filesSelected, setFilesSelected, addFilesSelected, removeFilesSelected } =
    useContext(FolderContext);

  const handleSelectFile = () => {
    if (!getFileId) return;

    if (multiple) {
      if (filesSelected.includes(file.id)) return removeFilesSelected(file.id);
      return addFilesSelected(file.id);
    }

    if (filesSelected.includes(file.id)) return setFilesSelected([]);
    setFilesSelected([file.id]);
  };

  const isDisabled = file.processing || (file.renderized !== undefined && !file.renderized);

  return (
    <div
      className={classNames(
        'relative w-[130px] 2xl:w-[183.3px] h-[115px] 2xl:h-[128px] rounded-md shadow-md cursor-pointer overflow-hidden bg-gray-500',
        filesSelected.includes(file.id) && !isDisabled && 'border-2 border-primary',
        isDisabled && 'opacity-50 pointer-events-none'
      )}
      style={{
        backgroundImage: `url(${generateImageUrl(file.thumbnail ? file.thumbnail : file.file)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      onClick={handleSelectFile}
    >
      {isDisabled && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <span className="text-white text-sm 2xl:text-lg font-semibold bg-gray-800 p-1 px-2 rounded-md opacity-90">Procesando</span>
        </div>
      )}
      {multiple && (
        <input
          type="checkbox"
          className="w-5 h-5 2xl:w-6 2xl:h-6 m-1 cursor-pointer focus:outline-0 rounded-md text-primary ring-primary outline-primary focus:ring-0 z-10 border-[1px] border-gray-400 focus:border-[1px] shadow-md"
          checked={filesSelected.includes(file.id)}
          onChange={handleSelectFile}
        />
      )}
      {type === 'portrait' && (
        <IoImageOutline
          size={50}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white opacity-40 font-light"
        />
      )}
      {type === 'video' && <div className="absolute inset-0 -z-10" />}
      {file.title && (
        <div className="absolute bottom-0 left-0 w-full h-10 flex items-center justify-start px-3">
          <span className="text-sm text-gray-200 select-none truncate">{file.title}</span>
        </div>
      )}
    </div>
  );
};

export const FileList = ({ title, files = [], getFileId, multiple, type = 'portrait' }) => {
  return (
    <div className="w-full mb-5 2xl:mb-7 select-none">
      <div className="flex items-center justify-between mb-2 2xl:mb-4">
        <h3 className="text-md 2xl:text-lg text-gray-700 font-semibold">{title}</h3>
      </div>
      <div className="flex flex-wrap justify-evenly gap-2 2xl:gap-4">
        {files.map((file, index) => (
          <File
            key={file.id + '-file-' + index}
            file={file}
            getFileId={getFileId}
            multiple={multiple}
            type={type}
          />
        ))}
        {/* hack to add a justify-start to lasted elements while the before has justify-evenly jj */}
        {Array.from({ length: files.length % 4 ? 4 - (files.length % 4) : 0 }).map((_, index) => (
          <div
            key={'ghost-file-' + index}
            className='w-[130px] 2xl:w-[183.3px]'
          />
        ))}
      </div>
    </div>
  );
};
