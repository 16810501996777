import ApiRoutes, {
  checkoutRender,
  clipUpdate,
  downloadLinks,
  importImageToEdit as importImage,
  importLandingFiles,
  reRenderizedVideo,
  resendVideo,
  sendLandingEmail,
  updateStatusVideo,
  videoAdd,
  videoAddMultiple,
  videoDelete,
  videoList,
  videoReuse,
  videoSharing,
  videoToken,
  videoUpdate,
} from '../../routes/api/paths';
import { headers, headersFile } from '../../config/headers';
import { sendRequest } from '../HandlerServices';

//listar videos
export const VideosList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.token),
  };
  return fetch(videoList, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

//agregar video
export const VideoAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.videosData),
  };

  return fetch(videoAdd, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

//agregar multiples videos
export const VideoAddMultiple = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.videos),
  };
  return fetch(videoAddMultiple, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

//actualizar video
export const Update = async (params, url) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify(params.video),
  };
  return fetch(url, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const VideoUpdateByLibrary = async (params) => {
  const urls = {
    video: videoUpdate,
    corporative: clipUpdate,
    record: ApiRoutes.updateRecording(params?.video?.id),
  }
  return Update(params, urls[params?.library]);
};

//archivar o desarchivar video
export const VideoChangeStatus = async (params) => {
  const { token, videoId } = params;
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
  };
  return fetch(updateStatusVideo(videoId), requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

//eliminar video
export const VideoDelete = async (params) => {
  const { token, videoIds } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ video_ids: [...videoIds] }),
  };
  return fetch(videoDelete, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

//reutilizar video
export const VideoReuse = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.token),
  };
  return fetch(`${videoReuse}/${params.id}`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

//info clientes associados a un video
export const VideoClients = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.token),
  };
  return fetch(`${videoToken}/${params.id}`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

//info compartir video
export const VideoSharing = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.token),
  };
  return fetch(`${videoSharing}/${params.id}`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

// Para guardar las imagenes que se agregan a la edición de un video
export const ImportImageToEdit = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.formData,
  };

  return fetch(importImage, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const DownloadLandingLinks = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headersFile(params.token),
  };

  return fetch(downloadLinks(params.videoId), requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }

      const data = await response.blob();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ImportLandingFiles = async (params, onProgress = () => { }) => {
  const { token, file } = params;
  const formData = new FormData();
  formData.append('file', file);

  const xhr = new XMLHttpRequest();
  xhr.open('POST', importLandingFiles);
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100;
      onProgress({
        progress,
        total: event.total,
      });
    }
  };

  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({ text: xhr.responseText, action: params.action });
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };

    xhr.send(formData);
  });
};

export const SendLandingLink = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.emailData),
  };

  return fetch(sendLandingEmail, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ResendVideo = async (params) => {
  const { token, id_video, id_client, asunto_email } = params;
  
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ 
      id_video,
      id_client,
      asunto_email
    }),
  };

  return fetch(resendVideo, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }

      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
}

export const CheckoutRender = async (params) => {
  const { token, userId } = params;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return fetch(`${checkoutRender}/${userId}`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }

      if (response.status === 200) {
        const data = await response.json();
        return Promise.resolve(data);
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
}

export const ReRenderizedVideo = async (params) => {
  const { token, id } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ id }),
  };
  return sendRequest(reRenderizedVideo, requestOptions)
}