import { FolderContext } from '../../../../contexts/folderContext';
import { useContext, useEffect, useState } from 'react';
import FolderList from './FolderList';
import SelectFolder from './SelectFolder';
import { useLibrary } from '../../../../pages/Library/hook/useLibrary';
import { filterMostRecent } from '../../../../config/utils';
import moment from 'moment';
import { IoCameraOutline, IoImageOutline } from 'react-icons/io5';
import { Breadcumbs } from '../../../../components/library/Breadcumbs';
import { getPathById, getElementById } from '../../../../pages/Library/tools/pathUtils';
import { classNames } from '../../../../config/utils';
import { Skeleton } from '../../../../components/common';
import { FileList } from './FileList';
import { AiOutlinePlus } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

export const DestinationView = ({
  onClose,
  onSelected,
  library = 'portrait',
  getFileId,
  uploadImage,
  noSelectView,
}) => {
  const [files, setFiles] = useState({
    lastFiles: [],
    restFiles: [],
  });

  const {
    idFolder = null,
    setIdFolder,
    currentFolder,
    setCurrentFolder,
    filesSelected,
  } = useContext(FolderContext);
  const { rootFolder, isLoading } = useLibrary(library);
  const { t } = useTranslation();

  const homeFolders = {
    video: t('Library.created_videos'),
    portrait: t('Library.frontPages'),
    corporative: t('Library.company_videos'),
  };

  /* Effects */
  useEffect(() => {
    const getFolder = () => {
      if (!idFolder) return rootFolder;
      return getElementById(idFolder, rootFolder);
    };

    setCurrentFolder(getFolder());
  }, [idFolder, rootFolder]);

  // Para filtrar los elementos más recientes
  useEffect(() => {
    const files = currentFolder?.files;
    const { lastElements, restElements } = filterMostRecent(files);

    setFiles({ lastFiles: lastElements, restFiles: restElements });

    return () => {
      setFiles({ lastFiles: [], restFiles: [] });
    };
  }, [currentFolder]);

  /* FUNCTIONS */
  const handleSelect = () => {
    if (getFileId) {
      if (!filesSelected.length) return;
      if (onSelected) onSelected(filesSelected[0]);
      if (uploadImage?.handleSaveFrontPage) uploadImage.handleSaveFrontPage();
      return onClose();
    }
    onSelected(idFolder);
    onClose();
  };

  const path = () => {
    let path = [
      {
        id: 0,
        name:
          (library === 'video' && t('Library.created_videos')) ||
          (library === 'corporative' && t('Library.company_videos')) ||
          (library === 'record' && t('Library.records')) ||
          (library === 'portrait' && t('Library.frontPages')),
      },
    ];
    if (!idFolder || !rootFolder) return path;
    const subPath = getPathById(idFolder, rootFolder);
    path = path.concat(subPath);
    return path;
  };

  return (
    <div style={{
      minWidth: '300px',
    }}>
      <div className="-mt-4 2xl:-mt-5 mb-3 w-full flex justify-between items-center">
        <Breadcumbs path={path()} onClick={setIdFolder} />

        <div className="flex items-center">
          {uploadImage?.handleImportFrontPage && (
            <button
              type="button"
              className="flex items-center justify-center h-9 2xl:h-12 px-6 rounded-xl bg-primary text-white text-md 2xl:text-lg hover:shadow-lg active:scale-[0.98]"
              onClick={uploadImage?.handleImportFrontPage}
            >
              <AiOutlinePlus className="mr-2 text-2xl 2xl:text-3xl" />
              <span>{t('Library.upload_image')}</span>
            </button>
          )}
          {uploadImage?.onOpenShootPicture && (
            <button
              type="button"
              className="flex items-center justify-center ml-7 p-1 font-extralight rounded-full text-gray-700 hover:shadow-lg active:scale-[0.98]"
              onClick={() => {
                onClose();
                uploadImage?.onOpenShootPicture();
              }}
            >
              <IoCameraOutline className="text-[40px] 2xl:text-[50px]" />
            </button>
          )}
        </div>
      </div>
      {/* Select folder */}
      <div className="flex justify-between items-center mb-2 z-50">
        {rootFolder && rootFolder.children && !noSelectView && (
          <SelectFolder
            folders={[{ id: rootFolder.id, name: homeFolders[library] }, ...rootFolder.children]}
            rootId={rootFolder.id}
            rootName={rootFolder.name} //El nombre del root folder ahora mismo es video, pero deberia ser Videos Creados
          />
        )}
      </div>

      {/* Folders */}
      <div className="folder-list-container mt-3 2xl:mt-5">
        {isLoading ? (
          <div className="flex space-x-2">
            <Skeleton width={180} height={50} />
            <Skeleton width={180} height={50} />
            <Skeleton width={180} height={50} />
          </div>
        ) : (
          <FolderList folders={currentFolder?.children} isLoading={isLoading} />
        )}
      </div>

      {/* Section area */}
      <div className="overflow-y-auto mt-0 h-[250px] pb-30 mb-15 2xl:mt-4 2xl:h-[400px]">
        {isLoading && (
          <div className="flex space-x-2">
            <Skeleton width={132} height={132} />
            <Skeleton width={132} height={132} />
            <Skeleton width={132} height={132} />
            <Skeleton width={132} height={132} />
          </div>
        )}

        {!isLoading && files.lastFiles.length > 0 && (
          <section>
            <FileList
              title={t('Library.recents')}
              files={files.lastFiles}
              getFileId={getFileId}
              type={library === 'corporative' ? 'video' : 'portrait'}
            />
          </section>
        )}

        {!isLoading && files.restFiles.length > 0 && (
          <section>
            <FileList
              title={homeFolders[library]}
              files={files.restFiles}
              getFileId={getFileId}
              type={library === 'corporative' ? 'video' : 'portrait'}
            />
          </section>
        )}

        {files.lastFiles.length === 0 && files.restFiles.length === 0 && !isLoading && (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="flex flex-col items-center justify-center">
              <IoImageOutline className="text-[40px] 2xl:text-[50px] text-gray-300" />
              <span className="text-sm 2xl:text-md text-gray-300 mt-3">
                {t('Library.no_files')}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="w-full h-[1px] bg-gray-200 hidden 2xl:block" />

      <div className="flex w-full justify-start mt-2 2xl:mt-12  mb-3 2xl:mb-5">
        <button
          className="text-sm 2xl:text-md text-gray-300 px-6 rounded-xl py-2 2xl:py-2.5 hover:shadow mt-2 border-gray-300 border-[1px] mr-2"
          onClick={onClose}
        >
          {t('Library.cancel')}
        </button>
        <button
          className={classNames(
            'text-sm 2xl:text-md text-white px-4 rounded-xl py-2 2xl:py-2.5 hover:shadow mt-2',
            getFileId && !filesSelected.length ? 'bg-black-soft' : 'bg-primary',
          )}
          onClick={handleSelect}
          disabled={getFileId && !filesSelected.length}
        >
          {getFileId ? t('Library.select') : t('Library.save')}
        </button>
      </div>
    </div>
  );
};
