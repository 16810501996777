import ApiRoutes from '../../routes/api/paths';
import { headers } from '../../config/headers';


export const ClientsList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token),
  };
  return fetch(ApiRoutes.clientList, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ClientGet = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token),
  };
  return fetch(`${ApiRoutes.clientGet}/${params.queryKey[1].id}`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ClientAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.client),
  };
  return fetch(ApiRoutes.clientAdd, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const multiClinetsImport = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify({ "clients": params.data }),
  };
  return fetch(ApiRoutes.multiClientImport, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ClientUpdate = async ({ token, client }) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify(client),
  };
  return fetch(`${ApiRoutes.clientUpdate}/${client.id}`, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ClientDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      client_id: params.id,
    }),
  };
  return fetch(ApiRoutes.clientDelete, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ClientImportPreview = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${params.token}` },
    body: params.client,
  };

  return fetch(ApiRoutes.previewImportedClients, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const { data } = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

export const ClientImport = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: params.client,
  };

  return fetch(ApiRoutes.clientImport, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const { data } = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};



export const ClientDeleteMultiple = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      clientIds: params.ids,
    }),
  };
  return fetch(ApiRoutes.clientDeleteMultiple, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data,
        });
      }
    })
    .catch((error) => {
      return Promise.reject({
        status: error,
      });
    });
};

