// DOMAIN
export const DOMAIN = import.meta.env.VITE_DOMAIN || 'http://localhost/vv-server/public/';
// export const DOMAIN = import.meta.env.VITE_DOMAIN || 'http://localhost:8000/';

// IMAGES
export const IMAGES = DOMAIN + 'img/';
export const IMAGES_PRODUCTS = IMAGES + 'products/';

// VIDEOS
export const COMAPANY = DOMAIN + 'videos/';
export const COMAPANY_CLIPS = '/clips/';
export const COMAPANY_OUTPUTS = '/outputs/';
export const COMAPANY_VIDEOS = '/videos/';
export const COMAPANY_TEMPLATES = '/templates/';
export const COMAPANY_DOCS = '/docs/';
export const COMAPANY_LANDING = '/landing/';
