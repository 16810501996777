import moment from 'moment';
import { DOMAIN } from '../routes/server/paths';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    console.error('Error al copiar texto al portapapeles:', error);
    return false;
  }
}

export function dowloadFileByUrl(url) {
  window.open(url, '_self');
}

/**
 * Esta función toma un arreglo con objetos que posean una fecha y los filtra
 * por los más recientes y devuelve también el resto de elementos que no son los más recientes
 * @param {*} elements
 * @returns Objeto con los dos arreglos resultantes
 */
export function filterMostRecent(elements) {
  const filterElements = {
    lastElements: [],
    restElements: [],
  };

  const currentDate = moment().format('YYYY-MM-DD').split('-');

  elements?.map((f) => {
    if (!f.created_at) return;
    const dateFile = f.created_at.split('T')[0].split('-');
    if (dateFile[0] == currentDate[0] && dateFile[1] == currentDate[1]) {
      filterElements.lastElements.push(f);
    } else {
      filterElements.restElements.push(f);
    }
  });

  return filterElements;
}

export const generateImageUrl = (path) => {
  let url = null;
  path?.split('/')[0] === 'data:image' ? (url = path) : (url = `${DOMAIN}${path}`);
  return url;
};

export const generateUpdateLandingData = (config) => {
  let data = {};
  config?.template && Object.assign(data, { template: config.template });

  config?.config.texts.title && Object.assign(data, { title: config.config.texts.title });

  config?.config.texts.message && Object.assign(data, { message: config.config.texts.message });

  config?.config.pdf_links.pdf.file?.split('/')[0] === 'data:application'
    ? Object.assign(data, { pdf_file: dataURLToFile(config.config.pdf_links.pdf.file) })
    : Object.assign(data, { pdf_file: config.config.pdf_links.pdf.file });

  config?.config.pdf_links.pdf.dowloadButton.text &&
    Object.assign(data, { pdf_text: config.config.pdf_links.pdf.dowloadButton.text });

  config?.config.pdf_links.pdf.dowloadButton.textColor &&
    Object.assign(data, { pdf_text_color: config.config.pdf_links.pdf.dowloadButton.textColor });

  config?.config.pdf_links.pdf.dowloadButton.buttonColor &&
    Object.assign(data, {
      pdf_button_color: config.config.pdf_links.pdf.dowloadButton.buttonColor,
    });

  config?.config.pdf_links.links.link[0] &&
    Object.assign(data, { link_url1: config.config.pdf_links.links.link[0]?.urlLink });

  config?.config.pdf_links.links.link[0] &&
    Object.assign(data, { link_text1: config.config.pdf_links.links.link[0]?.textLink });

  config?.config.pdf_links.links.link[1] &&
    Object.assign(data, { link_url2: config.config.pdf_links.links.link[1].urlLink });

  config?.config.pdf_links.links.link[1] &&
    Object.assign(data, { link_text2: config.config.pdf_links.links.link[1].textLink });

  config?.config.pdf_links.links.link[2] &&
    Object.assign(data, { link_url3: config.config.pdf_links.links.link[2].urlLink });

  config?.config.pdf_links.links.link[2] &&
    Object.assign(data, { link_text3: config.config.pdf_links.links.link[2].textLink });

  config?.config.pdf_links.links.link[3] &&
    Object.assign(data, { link_url4: config.config.pdf_links.links.link[3].urlLink });

  config?.config.pdf_links.links.link[3] &&
    Object.assign(data, { link_text4: config.config.pdf_links.links.link[3].textLink });

  config?.config.pdf_links.links.link[4] &&
    Object.assign(data, { link_url5: config.config.pdf_links.links.link[4].urlLink });

  config?.config.pdf_links.links.link[4] &&
    Object.assign(data, { link_text5: config.config.pdf_links.links.link[4].textLink });

  config?.config.pdf_links.links.colorButtonsLinks &&
    Object.assign(data, { link_button_color: config.config.pdf_links.links.colorButtonsLinks });

  config?.config.contacts.whatsapp &&
    Object.assign(data, { whatsapp: config.config.contacts.whatsapp });

  config?.config.contacts.phone && Object.assign(data, { phone: config.config.contacts.phone });

  config?.config.contacts.email && Object.assign(data, { email: config.config.contacts.email });

  config?.config.images.firstLogo?.split('/')[0] === 'data:image'
    ? Object.assign(data, { first_logo: dataURLToFile(config.config.images.firstLogo) })
    : Object.assign(data, { first_logo: config.config.images.firstLogo });

  config?.config.images.secondLogo?.split('/')[0] === 'data:image'
    ? Object.assign(data, { second_logo: dataURLToFile(config.config.images.secondLogo) })
    : Object.assign(data, { first_logo: config.config.images.secondLogo });

  config?.config.images.mainImage?.split('/')[0] === 'data:image'
    ? Object.assign(data, { main_image: dataURLToFile(config.config.images.mainImage) })
    : Object.assign(data, { main_image: config.config.images.mainImageFile });

  return data;
};

export const urlParsed = (url) => {
  return /^https?:\/\//.test(url) ? url : `http://${url}`;
};

export const arrayBufferToFile = (arrayBuffer, fileName, fileType) => {
  const blob = new Blob([arrayBuffer], { type: fileType });
  return new File([blob], fileName);
};

export const getExtensionFromMimeType = (mimeType) => {
  const mimeTypes = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif',
    'image/bmp': '.bmp',
    'image/webp': '.webp',
    'video/mp4': '.mp4',
    'video/mpeg': '.mpeg',
    'video/quicktime': '.mov',
    'video/x-msvideo': '.avi',
    'audio/mpeg': '.mp3',
    'audio/wav': '.wav',
    'audio/ogg': '.ogg',
    'application/pdf': '.pdf',
    // Agrega más tipos MIME y sus extensiones aquí si es necesario
  };

  const extension = mimeTypes[mimeType];
  return extension ? extension : '';
};

export const dataURLToFile = (dataURL, fileName) => {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const type = getExtensionFromMimeType(mime);

  return new File([u8arr], `${fileName}${type}`, { type: mime });
};

export const inputClick = (onAction, accept = '*', onError) => {
  // create validator
  const isValid = (file) => {
    // when accept images/* and file is image/png, etc
    if (accept.includes('image') && file.type.includes('image')) return true;
    // when accept video/* and file is video/mp4, etc
    if (accept.includes('video') && file.type.includes('video')) return true;
  };
  
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = accept;
  fileInput.addEventListener('change', e => {
    // validate file by accept
    if (accept !== '*' && !isValid(e.target.files[0])) {
      return onError && onError('invalid_file_type');
    }
    return onAction && onAction(e);
  });
  fileInput.click();
}

export const downloadVideo = (url) => {
  if (url) {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'video/mp4',
      },
    })
      .then((response) => 
        response.blob()
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', url.split('/').pop());
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Error downloading video:', error));
  }
}

/**
 * Generates a unique identifier.
 *
 * @returns {string} The unique identifier.
 */
export const uniqueId = () => {
  return Math.random().toString(36).substring(2, 9);
};

/**
 * Retrieves the duration of a video from the given URL.
 * @param {string} url - The URL of the video.
 * @returns {Promise<number>} - A promise that resolves with the duration of the video in seconds.
 * @throws {string} - An error message if there is an issue loading the video file.
 */
export const getVideoDuration = (url) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onloadedmetadata = function() {
      resolve(video.duration);
    };
    video.onerror = function() {
      reject('Error loading video file');
    };
    video.src = url;
  });
};

// compair two arrays
export const compairArrays = (arr1, arr2) => {
  return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
};

// compair two dates with moment, and consider the time zone. return -1 if date1 is before date2, 0 if are equals, 1 if date1 is after date2
export const compairDates = (date1, date2) => {
  const date1Moment = moment(date1);
  const date2Moment = moment(date2);
  if (date1Moment.isBefore(date2Moment)) return -1;
  if (date1Moment.isSame(date2Moment)) return 0;
  if (date1Moment.isAfter(date2Moment)) return 1;
};

// get date parsed to xx/xx/xxxx with moment from a string date
export const getDateParsed = (date) => {
  return moment(date).format('DD/MM/YYYY');
};
