import { classNames, generateImageUrl, urlParsed } from '../../../config/utils';
import { useRef, useEffect, useState } from 'react';
import { FaPhone, FaPlay } from 'react-icons/fa';
import { MdOutlineEmail, MdOutlineFileDownload } from 'react-icons/md';
import Logo from '../../../assets/img/logo-light.png';
import { BsWhatsapp } from 'react-icons/bs';
import { MenuLinks } from './elements/MenuLinks';
import { selectFrontPage, templateSelected } from '../../../features/videos/videosSlice';
import { useSelector } from 'react-redux';
import { PlayerTemplate } from './elements/PlayerTemplate';
import { useTranslation } from 'react-i18next';
import SocialLinksTemplate from './elements/SocialLinksTemplate';

const SecondTemplateMini = () => {
  const mainRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const container = mainRef.current.parentElement;
    const mainWidth = mainRef.current.offsetWidth;
    const newWidth = container.offsetWidth;

    /* escalar mainRef segun el width del contenedor */
    const onResize = () => {
      const scale = newWidth / mainWidth;
      mainRef.current.style.transform = `scale(${scale})`;
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div
      ref={mainRef}
      className="w-[346px] h-[262px] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end p-7 py-10"
    >
      <div className="w-[700px] h-[700px] absolute top-[50%] bg-gray-400" />
      <div className="relative flex justify-center items-center w-full h-full bg-white z-10 rounded-xl p-5 space-x-5">
        {/* info */}
        <div className="flex flex-col justify-center h-full space-y-2">
          <div className="flex w-full justify-between">
            <span className="uppercase text-[9px] text-gray-400 font-bold">logo</span>
            <span className="uppercase text-[9px] text-gray-400 font-bold">logo</span>
          </div>

          <span className="uppercase font-semibold text-[11px] text-gray-400">¡Text!</span>
          <div className="w-full flex flex-col">
            <span className="w-20 h-1 pt-[6px] mt-[4px] bg-gray-200" />
            <span className="w-16 h-1 pt-[6px] mt-[4px] bg-gray-200" />
          </div>
          <div className="w-full flex justify-evenly space-x-1">
            <div className="w-full h-3 bg-primary rounded-sm" />
            <div className="w-4/5 h-3 border-2 border-gray-200 rounded-sm" />
          </div>
        </div>
        {/* video */}
        <div className="flex w-[100%] justify-center items-center bg-gray-600 h-4/6 rounded-md">
          <div className="w-8 h-8 border-2 border-white rounded-full flex justify-center items-center">
            <FaPlay className="text-white ml-0.5" size={14} />
          </div>
        </div>
      </div>
    </div>
  );
};

const SecondTemplate = ({
  video,
  color = '7482F6',
  config,
  className = '',
  isSmall = false,
  selected = null,
}) => {
  const mainRef = useRef(null);
  const [menuShow, setMenuShow] = useState(false);
  const [dinamicTitle, setDinamicTitle] = useState(null);
  const portrait = useSelector(selectFrontPage);
  const { t } = useTranslation();
  const [imagesNotFound, setImagesNotFound] = useState({
    firstLogo: false,
    secondLogo: false,
    mainImage: false,
  });
  
  /* functions */
  const handleAddImgNotFound = (img) => {
    setImagesNotFound((prev) => ({ ...prev, [img]: true }));
  };

  /* effects */
  useEffect(() => {
    // check changes in images
    setImagesNotFound({
      firstLogo: false,
      secondLogo: false,
      mainImage: false,
    });
    if (config?.images?.firstLogo) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.firstLogo);
      img.onerror = () => handleAddImgNotFound('firstLogo');
    }
    if (config?.images?.secondLogo) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.secondLogo);
      img.onerror = () => handleAddImgNotFound('secondLogo');
    }
    if (config?.images?.mainImage) {
      const img = new Image();
      img.src = generateImageUrl(config?.images?.mainImage);
      img.onerror = () => handleAddImgNotFound('mainImage');
    }
  }, [config?.images]);

  selected &&
    useEffect(() => {
      if (config?.texts?.title) {
        let title = config?.texts.title.split('*');
        const temp = title.map((e) => {
          if (e === '|NOMBRE|') {
            return selected.name;
          } else return e;
        });
        const temp2 = temp.join('');
        setDinamicTitle(temp2);
      } else {
        setDinamicTitle('');
      }
    }, [selected, config?.texts]);

  /* functions */
  const urlParsed = (url) => {
    return /^https?:\/\//.test(url) ? url : `http://${url}`;
  };

  return (
    <div
      ref={mainRef}
      className={classNames(
        className,
        isSmall && '2xl:w-[430px] 2xl:h-[320px]',
        'w-[430px] h-[320px] 2xl:w-[800px] 2xl:h-[600px] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end transform origin-top-left p-10 2xl:p-20 shadow-lg',
      )}
    >
      {/* background image */}
      <div className="h-1/2 absolute bottom-0 inset-x-0" style={{ backgroundColor: '#' + color }}>
        {config?.images?.mainImage && !imagesNotFound.mainImage && (
          <img
            src={generateImageUrl(config.images.mainImage)}
            alt="Imagen principal"
            className="w-full object-cover"
          />
        )}
      </div>

      <div className="relative bg-white flex flex-col justify-center items-center w-full h-full z-10 rounded-xl overflow-hidden">
        <div className="flex justify-center items-center w-full p-5 2xl:p-12 space-x-5 2xl:space-x-12">
          {/* info */}
          <div className="flex w-4/12 flex-col justify-center h-full space-y-2 2xl:space-y-4">
            {/* logos */}
            <div className="flex w-full justify-between items-center mb-0 2xl:mb-4">
              {config?.images?.firstLogo && !imagesNotFound.firstLogo && (

                <img src={generateImageUrl(config?.images?.firstLogo)} alt="logo" className="w-10 rounded-lg" />

              )}
              {config?.images?.secondLogo && !imagesNotFound.secondLogo && (

                <img src={generateImageUrl(config?.images?.secondLogo)} alt="logo" className="w-10 rounded-lg" />

              )}
            </div>

            {/* Texts */}
            <div>
              <span
                className={
                  (isSmall && '2xl:text-[16px]',
                  'w-full font-bold text-[16px] 2xl:text-[24px] text-gray-500')
                }
              >
                {config?.texts?.title || <span className="text-gray-400 italic">{t('Landing.hello')}</span>}
              </span>
              <div
                className={
                  (isSmall && '2xl:text-[10px]',
                  'w-full flex text-[10px] 2xl:text-[16px] text-gray-400')
                }
              >
                {config?.texts?.message}
              </div>
            </div>

            {/* Buttons */}
            <div className="w-full flex justify-between text-[7px] 2xl:text-[12px]">
              <div
                className={classNames(
                  isSmall && '2xl:p-0.5',
                  `rounded-sm  w-6/12 bg-primary px-2 2xl:px-3 p-0.5 2xl:p-1 flex items-center space-x-2 cursor-pointer hover:shadow-lg`,
                  !config?.pdf_links?.pdf?.dowloadButton?.text && 'hidden'
                )}
                style={{ backgroundColor: `${config?.pdf_links?.pdf?.dowloadButton?.buttonColor}` }}
              >
                <span
                  className="font-semibold select-none whitespace-pre-wrap text-center"
                  style={{ color: `${config?.pdf_links?.pdf?.dowloadButton?.textColor}` }}
                >
                  {config?.pdf_links?.pdf?.dowloadButton?.text}
                </span>
              </div>
              {config?.pdf_links?.links?.link.length > 1 ? (
                <div
                  className={`relative w-5/12 border-[1px] rounded-sm p-1 px-2 2xl:px-3 flex items-center space-x-1 cursor-pointer hover:shadow-lg`}
                  style={{ borderColor: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  onClick={() => setMenuShow(!menuShow)}
                >
                  <span
                    className={`text-primary text-md font-semibold select-none whitespace-pre-wrap text-center`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  >
                    {t('Landing.links')}
                  </span>
                  {/* <IoIosArrowForward size={10} className={`text-primary`} style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }} /> */}

                  {menuShow && <MenuLinks links={config?.pdf_links.links} />}
                </div>
              ) : (
                <div
                  className={`border-[1px] w-5/12 rounded-sm p-1 px-2 2xl:px-3 truncate flex items-center space-x-2 cursor-pointer hover:shadow-lg`}
                  style={{
                    borderColor: `${config?.pdf_links.links.colorButtonsLinks}`,
                    color: `${config?.pdf_links.links.colorButtonsLinks}`,
                  }}
                >
                  <a
                    href={urlParsed(config?.pdf_links?.links?.link[0]?.urlLink)}
                    target={'_blank'}
                    className={` text-md font-semibold select-none whitespace-nowrap truncate`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  >
                    {config?.pdf_links?.links?.link[0]?.textLink}
                  </a>
                </div>
              )}
            </div>
          </div>

          {/* video */}
          <div className="flex w-8/12 justify-center items-center bg-gray-600 aspect-video rounded-md overflow-hidden relative">
            <div
              className={classNames(
                isSmall && '2xl:w-4 2xl:h-4',
                `absolute z-20 w-4 2xl:w-8 2xl:p-1 h-4 2xl:h-8 border-2 border-white hover:border-[#${color}] rounded-full flex justify-center items-center scale-150 opacity-60 cursor-pointer`,
              )}
            >
              <FaPlay
                className={classNames(
                  isSmall && '2xl:text-[10px]',
                  'text-white ml-0.5 2xl:ml-1 text-[10px] 2xl:text-[30px]',
                )}
              />
            </div>
            <div className="absolute w-full h-full bg-gradient-to-t z-10 from-black via-gray-500 to-gray-500 opacity-50" />
            {portrait.file && (
              <img
                src={generateImageUrl(portrait?.file)}
                alt=""
                className="w-full h-full object-cover select-none"
              />
            )}
          </div>
        </div>

        {/* Contacts */}
        <div
          className={classNames(
            isSmall && '2xl:text-[8px]',
            'absolute inset-x-0 bottom-3 2xl:bottom-6 w-full flex justify-center space-x-5 px-[10%] text-[8px] 2xl:text-[12px]',
          )}
        >
          <SocialLinksTemplate contacts={config?.contacts} variant='second' />
        </div>
      </div>

      {/* footer */}
      <div
        className={classNames(
          isSmall && '2xl:text-[4px]',
          'w-full flex items-center justify-center space-x-5 text-[4px] 2xl:text-[8px] absolute bottom-3 2xl:bottom-6',
        )}
      >
        <img src={Logo} alt="" className={classNames(isSmall && '2xl:h-4', 'h-4 2xl:h-7')} />
        <span className="text-white ">
            {t('Landing.copyright')}
        </span>
        <div className="flex space-x-2">
          <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.privacy')}
          </a>
          <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.conditions')}
          </a>
          <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.cookies')}
          </a>
        </div>
      </div>
    </div>
  );
};

const SecondTemplateFull = ({
  video,
  texConfig,
  color = '7482F6',
  portrait,
  config,
  className = '',
}) => {
  const mainRef = useRef(null);
  const [menuShow, setMenuShow] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      ref={mainRef}
      className={classNames(
        className,
        'h-full aspect-[430/320] relative overflow-hidden bg-gray-200 rounded-xl flex justify-center items-end transform origin-top-left p-20',
      )}
    >
      {/* background image */}
      <div className="h-1/2 absolute bottom-0 inset-x-0" style={{ backgroundColor: '#' + color }}>
        {config?.images?.mainImage && (
          <img
            src={generateImageUrl(config?.images?.mainImage)}
            alt="Imagen principal"
            className="w-full object-cover"
          />
        )}
      </div>

      <div className="relative bg-white flex flex-col justify-center items-center w-full h-full z-10 rounded-xl overflow-hidden">
        <div className="flex justify-center items-center w-full p-10 space-x-9 2xl:space-x-12">
          {/* info */}
          <div className="flex w-4/12 flex-col justify-center h-full space-y-5">
            {/* logos */}
            <div className="flex w-full justify-between items-center mb-0 2xl:mb-4">
              {config?.images?.firstLogo && (
                <img
                  src={generateImageUrl(config?.images?.firstLogo)}
                  alt="logo"
                  className="w-10 rounded-lg"
                />
              )}
              {config?.images?.secondLogo && (
                <img
                  src={generateImageUrl(config?.images?.secondLogo)}
                  alt="logo"
                  className="w-10 rounded-lg"
                />
              )}
            </div>

            {/* Texts */}
            <div>
              <span className={'w-full font-bold text-xl text-gray-500'}>
                {config?.texts?.title || <span className="text-gray-400 italic">{t('Landing.hello')}</span>}
              </span>
              <div className={'w-full flex text-md text-gray-400'}>{config?.texts?.message}</div>
            </div>

            {/* Buttons */}
            <div className="w-full flex justify-between text-xs">
              {config?.pdf_links?.pdf?.fileName && <a
                className={classNames(
                  `rounded-sm  w-6/12 bg-primary px-2 2xl:px-3 p-0.5 2xl:p-1 flex items-center space-x-2 cursor-pointer hover:shadow-lg`,
                )}
                style={{ backgroundColor: `${config?.pdf_links?.pdf?.dowloadButton?.buttonColor}` }}
                href={generateImageUrl(config?.pdf_links?.pdf?.fileName)}
                target="_blank"
                download
              >
                <span
                  className="font-semibold select-none whitespace-nowrap"
                  style={{ color: `${config?.pdf_links?.pdf?.dowloadButton?.textColor}` }}
                >
                  {config?.pdf_links?.pdf?.dowloadButton?.text}
                </span>
              </a>}
              {config?.pdf_links?.links?.link.length > 1 ? (
                <div
                  className={`relative w-5/12 border-[1px] rounded-sm p-1 px-2 2xl:px-3 flex items-center space-x-1 cursor-pointer hover:shadow-lg`}
                  style={{ borderColor: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  onClick={() => setMenuShow(!menuShow)}
                >
                  <span
                    className={`text-primary text-md font-semibold select-none truncate`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  >
                    {t('Landing.links')}
                  </span>
                  {/* <IoIosArrowForward size={10} className={`text-primary`} style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }} /> */}

                  {menuShow && <MenuLinks links={config?.pdf_links.links} />}
                </div>
              ) : (
                <div
                  className={`border-[1px] w-5/12 rounded-sm p-1 px-2 2xl:px-3 truncate flex items-center space-x-2 cursor-pointer hover:shadow-lg`}
                  style={{
                    borderColor: `${config?.pdf_links.links.colorButtonsLinks}`,
                    color: `${config?.pdf_links.links.colorButtonsLinks}`,
                  }}
                >
                  <a
                    href={urlParsed(config?.pdf_links?.links?.link[0]?.urlLink)}
                    target={'_blank'}
                    className={` text-md font-semibold select-none whitespace-nowrap truncate`}
                    style={{ color: `${config?.pdf_links.links.colorButtonsLinks}` }}
                  >
                    {config?.pdf_links?.links?.link[0]?.textLink}
                  </a>
                </div>
              )}
            </div>
          </div>

          {/* video */}
          <div className="flex w-8/12 justify-center items-center bg-gray-600 aspect-video rounded-md overflow-hidden relative">
            <PlayerTemplate video={video} texConfig={texConfig} portrait={generateImageUrl(portrait)} />
          </div>
        </div>

        {/* Contacts */}
        <div
          className={classNames(
            'absolute inset-x-0 bottom-3 2xl:bottom-6 w-full flex justify-center space-x-5 px-[10%] text-xs',
          )}
        >
          <SocialLinksTemplate contacts={config?.contacts} variant='second' />
        </div>
      </div>

      {/* footer */}
      <div
        className={classNames(
          'w-full flex items-center justify-center space-x-5 text-[9px] absolute bottom-6',
        )}
      >
        <img src={Logo} alt="" className={classNames('h-6')} />
        <span className="text-white ">
            {t('Landing.copyright')}
        </span>
        <div className="flex space-x-2">
          <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.privacy')}
          </a>
          <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.conditions')}
          </a>
          <a href="#" className="text-white underline cursor-pointer hover:text-primary-lighter">
              {t('Landing.cookies')}
          </a>
        </div>
      </div>
    </div>
  );
};

export { SecondTemplateMini, SecondTemplate, SecondTemplateFull };
