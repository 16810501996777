import { dataURLToFile, uniqueId } from '../../../config/utils';
import {
  addToRenderQueue,
  changeLandingConfig,
  changeStep,
  changeTemplate,
  removeFromRenderQueue,
  renderQueue,
  selectEditLanding,
  selectFrontPage,
  selectTexts,
  selectVideoData,
  selectVideos,
  setCreatedVideoData,
} from '../../../features/videos/videosSlice';
import useAuthContext from '../../../hooks/useAuthContext';
import { useToast2 } from '../../../hooks/useToast2';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ImportLandingFiles, VideoAdd } from '../../../services/video/VideoService';
import { useQuery } from 'react-query';
import { getUserOrCompanyById } from '../../../services/admin/adminService';

const videoContext = createContext();

const VideoProvider = ({ children, reuseVideo }) => {
  // Selectors
  const { selectedContacts } = useSelector((state) => state.videos);
  const { config } = useSelector((state) => state.videos.editLanding);
  const { videoStep } = useSelector((state) => state.videos);
  const presentVideo = useSelector(selectVideos);
  const presentTexts = useSelector(selectTexts);
  const editLanding = useSelector(selectEditLanding);
  const { folderId, videoName } = useSelector(selectVideoData);
  const frontPage = useSelector(selectFrontPage);
  const renders = useSelector(renderQueue);
  
  // States
  const [openReuseVideoDialog, setOpenReuseVideoDialog] = useState(false);
  const [isLoadingDataReuse, setIsLoadingDataReuse] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // for upload files (pdf n logos)
  const [filesUploaded, setFilesUploaded] = useState({
    // urls of files uploaded
    firstLogo: '',
    secondLogo: '',
    mainImage: '',
    pdf: '',
  });
  const [isSending, setIsSending] = useState(false); // for send the video data to render, just after upload required files
  const [currentRender, setCurrentRender] = useState(null);
  const [stepDisabled, setStepDisabled] = useState([]);

  // Hooks
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const { showToast } = useToast2();

  // rq
  const {
    data: dataCompany,
    isLoading: loadingCompany,
    error: errorCompany,
  } = useQuery( // get company data, to set default template and config of landing by company
    ['COMPANY'],
    () => getUserOrCompanyById({ token: user.access_token, search: 'company', id: user?.company_id }),
    {
      onError: (err) => {
        console.log(err);
      },
    },
  );

  // Constants
  const stepTitles = useMemo(
    () => [
      t('VideoNew.edition.title'),
      'Seleccionar landing',
      'Editar landing',
      'Crea contactos o un nuevo listado',
      'Enviar',
    ],
    [t],
  );

  const requiredFilesToUpload = useMemo(() => {
    const images = editLanding?.config?.images || {};
    const filesUploadRequired = Object.keys(images).filter((key) => images[key]);
    const pdf = editLanding?.config?.pdf_links?.pdf?.file;
    if (pdf) filesUploadRequired.push('pdf');
    return filesUploadRequired;
  }, [editLanding?.config?.images, editLanding?.config?.pdf_links?.pdf?.file]);

  const isFilesUploaded = useMemo(() => {
    // return true if all files required is in fileUploaded state
    const listFileUploaded = Object.keys(filesUploaded).map((key) => filesUploaded[key] && key);
    return requiredFilesToUpload.every((file) => listFileUploaded.includes(file));
  }, [requiredFilesToUpload, filesUploaded]);

  const isRendering = useMemo(() => {
    // return true if the id of the video is in renders queue
    return renders.map(r => r.id).includes(currentRender);
  }, [renders, currentRender]);

  // Functions
  const setVideoStep = (step) => dispatch(changeStep(step))

  const nextStep = () => {
    if (videoStep === 4) return;
    const fowardSteps = stepTitles.slice(videoStep + 1);
    for (let i = 0; i < fowardSteps.length; i++) {
      if (!stepDisabled.includes(videoStep + i + 1)) {
        setVideoStep(videoStep + i + 1);
        break;
      }
    }
  };

  const backStep = () => {
    if (videoStep === 0) return;
    const backwardSteps = stepTitles.slice(0, videoStep).reverse();
    for (let i = 0; i < backwardSteps.length; i++) {
      if (!stepDisabled.includes(videoStep - i - 1)) {
        setVideoStep(videoStep - i - 1);
        break;
      }
    }
  };
  
  const renderVideo = async (params) => {   
    setIsSending(true); 
    return new Promise(async (resolve, reject) => {
      try {
        const response = await VideoAdd(params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  const importFiles = async (params) => {
    return new Promise(async (resolve, reject) => {
      const onProgress = (progressData) => {
        const { progress, total } = progressData;
      };

      try {
        const response = await ImportLandingFiles(params, onProgress);
        resolve(response);
      } catch (error) {
        showToast({
          show: true,
          type: 'error',
          message: t('Message.video.error.upload_files'),
        });
        reject(error);
      }
    });
  };

  const saveLogosAndPdf = () => {
    // Enviar logos y pdf adjunto antes de renderizar video
    const firstLogo = editLanding.config.images.firstLogo;
    const secondLogo = editLanding.config.images.secondLogo;
    const mainImage = editLanding.config.images.mainImage;

    const pdfAttached = editLanding.config.pdf_links.pdf.file;

    try {
      if (firstLogo && firstLogo?.split('/')[0] === 'data:image') {
        const firstLogoFile = dataURLToFile(firstLogo, 'first-logo');
        setIsUploading(true);
        
        importFiles({
          token: user.access_token,
          file: firstLogoFile,
        }).then((response) => {
          if (response) {
            const data = JSON.parse(response.text);
            setFilesUploaded((prev) => ({ ...prev, firstLogo: data.filename }));
          }
        });
      } else setFilesUploaded((prev) => ({ ...prev, firstLogo: firstLogo || '' })); // si no es una dataUrl, es porque ya se subió y se esta reutilizando, o no existe
      
      if (secondLogo && secondLogo?.split('/')[0] === 'data:image') {
        const secondLogoFile = dataURLToFile(secondLogo, 'second-logo');
        setIsUploading(true);
        
        importFiles({
          token: user.access_token,
          file: secondLogoFile,
        }).then((response) => {
          if (response) {
            const data = JSON.parse(response.text);
            setFilesUploaded((prev) => ({ ...prev, secondLogo: data.filename }));
          }
        });
      } else setFilesUploaded((prev) => ({ ...prev, secondLogo: secondLogo || '' }));

      // Por si es la landing 3
      if (mainImage && mainImage?.split('/')[0] === 'data:image') {
        const mainImageFile = dataURLToFile(mainImage, 'main-image');
        setIsUploading(true);
        
        importFiles({
          token: user.access_token,
          file: mainImageFile,
        }).then((response) => {
          if (response) {
            const data = JSON.parse(response.text);
            setFilesUploaded((prev) => ({ ...prev, mainImage: data.filename }));
          }
        });
      } else setFilesUploaded((prev) => ({ ...prev, mainImage: mainImage || '' }));

      if (pdfAttached && pdfAttached?.split('/')[0] === 'data:application') {
        const pdfFile = dataURLToFile(pdfAttached, 'pdf-attached');
        setIsUploading(true);
        
        importFiles({
          token: user.access_token,
          file: pdfFile,
        }).then((response) => {
          if (response) {
            const data = JSON.parse(response.text);
            setFilesUploaded((prev) => ({ ...prev, pdf: data.filename }));
          }
        });
      } else setFilesUploaded((prev) => ({ ...prev, pdf: pdfAttached || '' }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleRenderVideo = () => {
    setIsUploading(false);

    // validations
    if (!presentVideo?.length)
      return showToast({ show: true, type: 'error', message: t('Message.video.error.add') });

    // Data de los videos que hay que mandar para renderizar el video creado
    const clips =
      presentVideo?.map((element) => {
        return element.type == 'image'
          ? {
              id: element.id,
              src: element.realFile || '',
              duration: element.duration || 30,
              type: 'img',
              start_time: element.startSecond || 0,
              end_time: element.endSecond || 30,
              originalStartSecond: element.originalStartSecond || 0,
              originalEndSecond: element.originalEndSecond || 30,
              originalDuration: element.originalDuration || 30,
            }
          : {
              id: element.id,
              src: element.src || '',
              duration: element.duration || 30,
              type: element.libraryType || 'clip', //added clip for the images converted to video
              start_time: element.startSecond || 0,
              end_time: element.endSecond || 30,
              originalStartSecond: element.originalStartSecond || 0,
              originalEndSecond: element.originalEndSecond || 30,
              originalDuration: element.originalDuration || 30,
            };
      }) || [];

    const constantIds = selectedContacts.map((contact) => contact.id);
    const clientIds = constantIds.toString();

    let texts =
      presentTexts?.map((element) => {
        const end_time = element.startSecond + element.duration;

        const parentWidth = parseFloat(element.parentSize?.width) || 200;
        const parentHeight = parseFloat(element.parentSize?.height) || 100;

        const positionX = element?.position?.x || 0;
        const positionY = element?.position?.y || 0;

        const position_percentX = (positionX / parentWidth) * 100;
        const position_percentY = (positionY / parentHeight) * 100;

        return {
          text: element.text,
          position: {
            ...element.position,
          },
          position_percent: { x: `${position_percentX}%`, y: `${position_percentY}%` },
          style: {
            color: element.styleText?.color,
            'font-size': element.styleText?.fontSize,
            'font-family': element.styleText?.fontFamily,
            parentSize: element.parentSize,
          },
          start_time: element.startSecond,
          end_time,
        };
      }) || [];

    const landingData = {
      template: editLanding.template,
      config: {
        texts: {
          title: editLanding.config.texts.title,
          message: editLanding.config.texts.message,
        },
        pdf_links: {
          pdf: {
            file: filesUploaded.pdf,
            fileName: editLanding.config.pdf_links.pdf.fileName,
            dowloadButton: {
              // typo in backend (dowload)
              text: editLanding.config.pdf_links.pdf.dowloadButton.text,
              textColor: editLanding.config.pdf_links.pdf.dowloadButton.textColor,
              buttonColor: editLanding.config.pdf_links.pdf.dowloadButton.buttonColor,
            },
          },
          links: {
            link: [...editLanding.config.pdf_links.links.link],
            colorButtonsLinks: editLanding.config.pdf_links.links.colorButtonsLinks,
            check: editLanding.config.pdf_links.links.check,
          },
        },
        contacts: {
          whatsapp: editLanding.config.contacts.whatsapp,
          phone: editLanding.config.contacts.phone,
          email: editLanding.config.contacts.email,
        },
        images: {
          ...filesUploaded,
          pdf: undefined,
        },
      },
    };

    const videosData = {
      clips: clips,
      text: texts,
      editLanding: landingData,
      video: {
        title: videoName,
        default_text: 'Default text',
        first_color: '#FF0000',
        second_color: '#00FF00',
        folder_id: folderId || 0,
        clients_id: {
          id_clients: clientIds,
        },
        frontPage,
      },
    };

    renderVideo({ token: user.access_token, videosData })
      .then((response) => {
        if (response) {
          // Guardar el id del video creado en el estado global para usarlo en paso 5 para descargar links de landings
          dispatch(setCreatedVideoData(response.data));
          setCurrentRender(response.data?.id);

          // reset files uploaded
          setFilesUploaded({
            firstLogo: '',
            secondLogo: '',
            mainImage: '',
            pdf: '',
          });

          // Para que no se vayan a superponer ambas notificaciones
          setTimeout(() => {
            showToast({ show: true, type: 'success', message: t('Message.video.success.create_video') });
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);

        setTimeout(() => {
          showToast({ show: true, type: 'error', message: t('Message.video.error.create_video') });
        }, 500);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  // effects
  useEffect(() => { // to set default template and config of landing by company
    if (!dataCompany) return;
    dispatch(changeTemplate(Number(dataCompany?.data.landing_default.template)));
    dispatch(changeLandingConfig(dataCompany?.data.landing_default.config));
  }, [dataCompany]);

  useEffect(() => {
    setOpenReuseVideoDialog(Boolean(reuseVideo));
  }, [reuseVideo]);

  useEffect(() => {
    if (videoStep !== 4) return;
    // validate if has required files to upload and is not uploaded yet. If not, render video
    if (!isFilesUploaded && requiredFilesToUpload.length) return;
    handleRenderVideo();
  }, [isFilesUploaded, requiredFilesToUpload, videoStep]);


  useEffect(() => { // disabled step 1 and 2 when user role is Agente
    if (!user) return;
    if (user?.role === 'Agente') setStepDisabled(prev => [...prev, 1, 2]);
  }, [user]);

  // memo
  const value = useMemo(
    () => ({
      openReuseVideoDialog,
      setOpenReuseVideoDialog,
      stepTitles,
      videoStep,
      setVideoStep,
      nextStep,
      backStep,
      config,
      selectedContacts,
      isLoadingDataReuse,
      setIsLoadingDataReuse,
      isUploading,
      isRendering,
      isSending,
      stepDisabled,
      setStepDisabled,
      // functions
      saveLogosAndPdf,
    }),
    [
      openReuseVideoDialog,
      setOpenReuseVideoDialog,
      stepTitles,
      videoStep,
      setVideoStep,
      nextStep,
      backStep,
      config,
      selectedContacts,
      isLoadingDataReuse,
      setIsLoadingDataReuse,
      isUploading,
      isRendering,
      isSending,
      stepDisabled,
      setStepDisabled,
      // functions
      saveLogosAndPdf,
    ],
  );

  return <videoContext.Provider value={value}>{children}</videoContext.Provider>;
};

export const useVideo = () => {
  const context = useContext(videoContext);
  if (!context) throw new Error('useVideo must be used within a VideoProvider');
  return context;
};

export default VideoProvider;
