import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowBack } from 'react-icons/io';
import { FirstTemplateMini, SecondTemplateMini, ThirdTemplateMini } from '../templateLanding';
import { changeStep, changeTemplate } from '../../../features/videos/videosSlice';
import { classNames } from '../../../config/utils';
import { useTranslation } from 'react-i18next';
import { useVideo } from '../context/VideoContext';
import useAuthContext from '../../../hooks/useAuthContext';

const templates = [
  <FirstTemplateMini key={1} />,
  <SecondTemplateMini key={2} />,
  <ThirdTemplateMini key={3} />,
];

const TemplateContainer = ({ children, position, selected, onClick, scope }) => {
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    if (selected === null || selected === position) {
      return setDistance(0);
    }
    // set alwais positive distance coeficient to scale down this component
    const distanceCoeficient = Math.abs(selected - position);
    setDistance(distanceCoeficient);
  }, [selected, position]);

  // create a text scale for tailwind to normal and 2xl mediaqueries
  const scale = `scale-[${1 - distance * 0.3 + (selected !== null ? 0.1 : 0)}] 2xl:scale-[${
    1 - distance * 0.1 + (selected !== null ? 0.1 : 0)
  }]`;

  return (
    <div className={`${scope === 'video' ? 'w-52 h-[157px] 2xl:w-[346px] 2xl:h-[262px]' : 'w-24 h-16 2xl:w-40 2xl:h-32'}`}>
      <div className={`${scope === 'video' ? 'scale-[0.56] 2xl:scale-[1]' : 'scale-[0.3] 2xl:scale-[.4]' } origin-top-left`}>
        <div
          className={classNames(
            `p-2 w-[362px] h-[278px] rounded-2xl duration-100 ${
              selected === position && 'bg-primary'
            }`,
          )}
          style={{
            transform: `scale(${1 - distance * 0.1 + (selected !== null ? 0.1 : 0)})`,
            transformOrigin: 'center',
            opacity: selected !== null && selected !== position ? 0.5 : 1,
          }}
        >
          <div
            className="flex w-[346px] h-[262px] rounded-xl duration-300 hover:shadow-primary hover:shadow-2xl cursor-pointer"
            onClick={onClick}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SelectLanding = ({ scope = 'video' }) => {
  // context
  const { setStepDisabled, nextStep, backStep } = useVideo();

  // redux
  const dispatch = useDispatch();
  const { template } = useSelector((state) => state.videos.editLanding);
  const [selected, setSelected] = useState(template - 1);

  // hooks
  const { user } = useAuthContext();
  const { t } = useTranslation();

  // effects
  useEffect(() => {
    dispatch(changeTemplate(selected + 1));
    setStepDisabled((prev) => !(selected >= 0) || user?.role === 'Agente' ? [...prev, 2] : prev.filter((step) => step !== 2));
  }, [selected]);

  // functions
  const handleSelect = (index) => {
    index === selected ? setSelected(-1) : setSelected(index);
  };

  return (
    <div>
      <div className={`w-full p-7 gap-7 flex items-center justify-evenly flex-wrap`}>
        {templates.map((template, index) => (
          <TemplateContainer
            key={index}
            position={index}
            selected={selected}
            onClick={() => handleSelect(index)}
            scope={scope}
          >
            {template}
          </TemplateContainer>
        ))}
      </div>

      {
        scope === 'video' &&
        <>
          <div className="flex mt-10 2xl:mt-28">
            <button
              type="button"
              className="flex items-center justify-center h-12 px-2 mr-4 rounded-xl  text-gray-300 border-2 border-gray-300 font-semibold text-lg hover:shadow-lg active:scale-[0.98]"
              onClick={backStep}
            >
              <IoIosArrowBack size={30} />
            </button>
            <button
              type="button"
              className="flex items-center justify-center h-12 px-4 rounded-xl bg-primary text-white font-semibold text-lg hover:shadow-lg active:scale-[0.98] disabled:bg-gray-400"
              onClick={nextStep}
              // disabled={!(selected >= 0)}
            >
              {t('Video.continue')}
            </button>
          </div>
        </>
      }

    </div>
  );
};
