import { createContext, useContext, useMemo, useState } from 'react';

const VideoStepsContext = createContext();

const VideoStepsProvider = ({ children }) => {
  const [openSelfRecord, setOpenSelfRecord] = useState(false);

  const value = useMemo(() => ({
    openSelfRecord,
    setOpenSelfRecord
  }), [
    openSelfRecord, 
    setOpenSelfRecord
  ]);
  
  return (
    <VideoStepsContext.Provider value={value}>
      {children}
    </VideoStepsContext.Provider>
  )
}

export const useVideoStepsContext = () => {
  const context = useContext(VideoStepsContext);

  if (!context) {
    throw new Error('useVideoStepsContext must be used within VideoStepsProvider');
  }

  return context;
}

export default VideoStepsProvider;