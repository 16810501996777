import { LibraryComp, Options } from '../../components/library';
import FolderContextProvider from '../../contexts/folderContext';
import { useLibrary } from './hook/useLibrary';
import VideoStepsProvider from '../../components/video/videoSteps/context/VIdeoStepsContext';
import { SelfRecord } from '../../components/video';

export function Library({ category }) {
  const root = useLibrary(category); //{ rootFolder, rootArchived, isLoading }

  return (
    <>
      <VideoStepsProvider> {/* only to provide a optimized disclosure to SelfRecord in recording option */}
        <FolderContextProvider library={category}>
          <div className="flex flex-col items-end">
            <Options />
            <LibraryComp {...root} />

            <SelfRecord />
          </div>
        </FolderContextProvider>
      </VideoStepsProvider>
    </>
  );
}
