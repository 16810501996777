import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { setRefetch } from '../../features/common/commonSlice';
import { formType, resetStore } from '../../features/client/clientSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAuthContext from '../../hooks/useAuthContext';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AddList, AddTag } from '../../services/client/TagService';
import { open, setOpenAddTagListModal } from '../../features/client/clientSlice';
import { useToast2 } from '../../hooks/useToast2';

export function AddTagListModal() {
  const [title, setTitle] = useState('');

  /* Hooks */
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showToast } = useToast2();
  const isOpen = useSelector(open);
  const type = useSelector(formType);
  const { user } = useAuthContext();

  /* Consts */

  /* Mutations */
  const mutationListAdd = useMutation('CREATE_LIST_ITEM', AddList, {
    onError: (error) => {
      showToast({show: true, type: 'error', message: t('Message.client.error.delete')});
    },
    onSuccess: (data) => {
      setTimeout(
        () => showToast({show: true, type: 'success', message: t('Message.client.success.delete')}),
        1000,
      );
      dispatch(setRefetch(true));
      dispatch(setOpenAddTagListModal(false));
    },
  });

  const mutationTagAdd = useMutation('CREATE_TAG_ITEM', AddTag, {
    onError: (error) => {
      showToast({show: true, type: 'error', message: t('Message.client.error.delete')});
    },
    onSuccess: (data) => {
      setTimeout(
        () => showToast({show: true, type: 'success', message: t('Message.client.success.delete')}),
        1000,
      );
      dispatch(setRefetch(true));
      dispatch(setOpenAddTagListModal(false));
    },
  });

  const handleForm = () => {
    if (type === 'list') mutationListAdd.mutate({ token: user.access_token, list: { title } });
    else mutationTagAdd.mutate({ token: user.access_token, tag: { title } });
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          dispatch(setOpenAddTagListModal(false));
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white px-4 text-left shadow-xl transition-all sm:my-8 max-w-xl w-full py-16">
                <div className="px-8 pt-10 flex flex-col items-center justify-center">
                  <div>
                    <label className="text-gray-soft">
                      {t('Common.add_tagList_modal.title', {text: type === 'list' ? t('Common.add_tagList_modal.list') : t('Common.add_tagList_modal.tag')})}
                    </label>
                    <input
                      placeholder={t('Placeholder.write_title')}
                      className="px-2 border-gray-300 border h-10 rounded-lg focus:border-gray-300 focus:ring-1 focus:ring-gray-300 w-full"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="flex space-x-4 mt-12 mb-2">
                    <button
                      className="px-4 py-2.5 border-gray-300 border rounded-md text-gray-300"
                      onClick={() => dispatch(resetStore())}
                    >
                      {t('Common.add_tagList_modal.cancel')}
                    </button>
                    <button
                      className="px-10 py-2.5 border-primary border rounded-md text-white bg-primary"
                      onClick={handleForm}
                    >
                      {t('Common.add_tagList_modal.save')}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
