import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../../config/utils";
import { Tooltip } from "react-tooltip";
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from "react";

const TableWithErrors = ({
  clients,
  headers,
  selectedHeaders,
  selectedPeople,
  replaceClients,
  menuOptions,
  handleMenuItem,
}) => {
  return (
    <table className="w-full border-separate border-spacing-y-3 border-spacing-x-[1px] ">
      <thead>
        <tr>
          {selectedHeaders.sort((a, b) => {
            let indexA = headers.labels.indexOf(a);
            let indexB = headers.labels.indexOf(b);
            return indexA - indexB;
          }).map((header, index) => (
              <th
                key={`th-${header.name}-${index}`}
                scope="col"
                className={classNames(
                  headers.errors && headers.errors[header.name]
                    ? 'bg-red-error'
                    : 'bg-black-soft',
                  'py-1 pr-3 text-left text-sm font-semibold px-2 last:rounded-tr-xl last:rounded-br-xl first:rounded-tl-xl first:rounded-bl-xl',
                )}
              >
                <div className="flex justify-between items-center py-2">
                  <div className="flex items-center space-x-1">
                    <Menu as="div" className="relative inline-block text-left">
                      {/* <div>    // ocultado por desuso
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 ">
                          <PencilSquareIcon className="w-5 h-5 text-white cursor-pointer" />
                        </Menu.Button>
                      </div> */}

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute p-2 left-0 z-10 mt-2 w-48 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {menuOptions.map((item, index) => {
                              const isAvailable =
                                selectedHeaders.find((header) => header.alias === item.title) !==
                                undefined;

                              return (
                                <Menu.Item
                                  disabled={isAvailable}
                                  key={item.id}
                                  className="rounded-md pl-6 hover:bg-primary hover:text-white font-light"
                                  onClick={() => handleMenuItem(header, item)}
                                >
                                  {({ active }) =>
                                    !isAvailable ? (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm',
                                        )}
                                      >
                                        {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                                      </a>
                                    ) : (
                                      <a
                                        href="#"
                                        className="text-gray-400 block px-4 py-2 text-sm"
                                      >
                                        {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                                      </a>
                                    )
                                  }
                                </Menu.Item>
                              );
                            })}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <p className="text-white font-light text-lg">
                      {header.alias.charAt(0).toUpperCase() + header.alias.slice(1)}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    {headers.errors && headers.errors[header.name] && (
                      <div className="rounded-lg">
                        <a data-tooltip-id={header.real_name} data-tooltip-place="top">
                          <InformationCircleIcon className="w-7 h-7 text-white cursor-pointer" />
                        </a>

                        <Tooltip
                          className="z-50 !opacity-80 !rounded-lg !-top-0 !mr-10"
                          id={header.real_name}
                          place="top"
                          content={headers.errors[header.name].description}
                          noArrow
                          openOnClick
                        />
                      </div>
                    )}
                  </div>
                </div>
              </th>
            ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {clients.map((item, index) => (
          <tr
            key={`tr-${item.name}-${index}`}
            className={classNames(
              selectedPeople.includes(item) ? 'bg-gray-50' : undefined,
              'bg-gray-100 relative px-6 sm:w-12 sm:px-rounded-tl-xl rounded-bl-xl',
            )}
          >
            { selectedHeaders.map((header, i) => {
              
              const value = item[header.real_name];
              return (
                  <td
                    key={`item-${header.real_name}-${index}-with-error`}
                    className={classNames(
                      item.error && item.error[header.real_name]
                        ? `${item.error[header.real_name].level === 'bg-red-error' && !(replaceClients && item.error[header.real_name].description === 'Ya existe un usuario con este correo')
                          ? 'bg-red-error text-white'
                          : 'text-gray-text'
                        }  `
                        : 'text-gray-text',
                      'whitespace-nowrap py-3 px-4 text-sm  text-center font-medium border-r border-gray-border first:rounded-tl-xl first:rounded-bl-xl last:rounded-br-xl last:rounded-tr-xl',
                    )}
                  >
                    <div className="flex justify-between items-center">
                      {value}

                      {item.error && item.error[header.real_name] && (
                        <div>
                          <a data-tooltip-id={item[header.real_name]} data-tooltip-place="top">
                            <InformationCircleIcon
                              className={classNames(
                                item.error && item.error[header.real_name]
                                  ? `${item.error[header.real_name].level === 'bg-red-error' && !(replaceClients && item.error[header.real_name].description === 'Ya existe un usuario con este correo')
                                    ? ' text-white'
                                    : 'text-gray-soft hidden'
                                  } `
                                  : '',
                                'w-7 h-7 text-gray-text-darker cursor-pointer',
                              )}
                            />
                          </a>
                          <Tooltip
                            className="z-50 !opacity-80 !rounded-lg !-top-0 !mr-10"
                            id={item[header.real_name]}
                            place="top"
                            content={item.error[header.real_name].description}
                            noArrow
                            openOnClick
                          />
                        </div>
                      )}
                    </div>
                  </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableWithErrors;