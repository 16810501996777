import { useContext } from 'react';
import { VideoItem } from './VideoItem';
import { FolderContext } from '../../contexts/folderContext';
import { Skeleton } from '../../components/common';
import { compairDates } from '../../config/utils';

export function VideoList({ items, options, isLoading = false }) {
  const classMiniature = 'flex gap-12 flex-wrap px-1';
  const classList = 'flex flex-col gap-3 px-1';

  //context
  const { itemsDisplay } = useContext(FolderContext);

  return (
    <div className={itemsDisplay === 'list' ? classList : classMiniature}>
      {isLoading
        ? [1, 2, 3, 4].map((key) => <Skeleton key={key} className="w-60 2xl:w-72 h-60 2xl:h-72" />)
        : items
          ?.sort((a, b) => compairDates(b.created_at, a.created_at))
          ?.map((file, index) => <VideoItem options={options} key={index} file={file} />)}
    </div>
  );
}
