import { Skeleton } from '../../components/common';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { landingGet } from '../../routes/api/paths';
import { TemplateSelectorFull } from '../../components/video/templateLanding';
import { generateImageUrl } from '../../config/utils';
import { useTranslation } from 'react-i18next';
import ElementOverlayContainer from '../../components/video/edition/others/ElementOverlayContainer';

const TemplateSelected = ({ templateNum, video, texConfig, portrait, config }) => {
  const parentRef = useRef(null);
  const videoRef = useRef(null);
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(0);

  const handleTimeUpdate = (event) => {
    setCurrentTime(event.target.currentTime);
  };

  if (!video) return <span className="text-gray-600 text-2xl">{t('Message.landing.no_video_show')}</span>;

  if (!templateNum) {
    return (
      <div ref={parentRef} className="w-full h-full">
        <video 
          ref={videoRef} 
          className="w-full h-full object-cover" 
          src={video} controls autoPlay 
          onTimeUpdate={handleTimeUpdate}
        />
        <ElementOverlayContainer
          videoRef={videoRef}
          containerRef={parentRef}
          currentTime={currentTime || 0}
          overlayElements={texConfig}
          editableElem={false}
        />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex justify-center items-center p-5">
      <TemplateSelectorFull
        number={templateNum}
        video={video}
        texConfig={texConfig}
        portrait={portrait}
        config={config}
      />
    </div>
  );
};

export const Landing = () => {
  const { token } = useParams();
  const [landingData, setLandingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  // Para buscar los datos de la landing según el token
  useEffect(() => {
    fetch(landingGet(token))
      .then((response) => response.json())
      .then((data) => {
        if (data?.success) {
          const { landing_data } = data;

          let links = [];

          if (landing_data.links && landing_data.links.length) {
            landing_data.links.forEach((link) => {
              const newLink = {
                textLink: link?.text || '',
                urlLink: link?.url || '',
              };

              links.push(newLink);
            });
          } else {
            links.push({
              textLink: t('Landing.link'),
              urlLink: '',
            });
          }

          let texts = [];
          if (landing_data.texts_video && landing_data.texts_video.length) {
            landing_data.texts_video.forEach((element) => {
              const duration = element.end_time - element.start_time;

              let title = element.text.split('*');

              const temp = title.map((e) => {
                if (e === '|NOMBRE|' || e === '|NAME|') {
                  return data.client;
                } else return e;
              });

              const temp2 = temp.join('');

              const parentSize = element?.style?.parentSize || { width: 100, height: 100 };

              const newParent = document.querySelector('.element_overlay-container');

              const newParentSize = {
                width: parseFloat(newParent?.style?.width) || 200,
                height: parseFloat(newParent?.style?.height) || 100,
              };

              const newFontSize = Math.min(
                (newParentSize.width / parseFloat(parentSize.width)) *
                  parseFloat(element.style['font-size']),
                (newParentSize.height / parseFloat(parentSize.height)) *
                  parseFloat(element.style['font-size']),
              );

              const newText = {
                text: temp2 || '',
                position: {
                  // Esto es para que la posición se ajuste en porciento
                  x: element?.position_percent?.x || element.position?.x,
                  y: element?.position_percent?.y || element.position?.y,
                },
                styleText: {
                  color: element.style?.color || '',
                  fontSize: `${newFontSize}px`,
                  fontFamily: element.style['font-family'] || '',
                },
                startSecond: element.start_time,
                duration: duration,
              };

              texts.push(newText);
            });
          }

          let title = landing_data?.title?.split('*') || null;

          let temp = null;

          if (title) {
            temp = title.map((e) => {
              if (e === '|NOMBRE|' || e === '|NAME|') {
                return data.client;
              } else return e;
            });
          }

          const temp2 = temp?.join('') || null;
          
          const localData = {
            template: Number(landing_data.template) || 0, // Esta llegando como un string
            video_url: data.video_url || '',
            config: {
              texts: {
                title: temp2 || '',
                message: landing_data.message || '',
              },
              pdf_links: {
                pdf: {
                  fileName: landing_data.pdf_file || '',
                  dowloadButton: {
                    text: landing_data.pdf_text || t('Landing.download'),
                    textColor: landing_data.pdf_text_color || '',
                    buttonColor: landing_data.pdf_button_color || '',
                  },
                },
                links: {
                  link: links,
                  colorButtonsLinks: landing_data.link_button_color || '#1e4372',
                  check: '1',
                },
              },
              contacts: {
                whatsapp: landing_data.whatsapp || '',
                phone: landing_data.phone || '',
                email: landing_data.email || '',
              },
              images: {
                firstLogo: landing_data.first_logo || '',
                secondLogo: landing_data.second_logo || '',
                mainImage: landing_data.main_image || '',
              },
            },
            text: texts,
            portrait: landing_data.frontPage || '',
          };
          // harcodear datos de prueba
          setLandingData(localData);

          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(t('Message.landing.get_data'), error);
        setLoading(false);
      });
  }, [token]);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {loading ? (
        <div className="relative w-full h-full flex justify-center items-center">
          <div className="absolute inset-0">
            <Skeleton />
          </div>
          <span className="text-gray-600 text-2xl">{t('Message.landing.loading')}</span>
        </div>
      ) : landingData ? (
        <TemplateSelected
          templateNum={landingData?.template}
          video={generateImageUrl(landingData?.video_url)}
          texConfig={landingData?.text}
          portrait={landingData?.portrait || ''}
          config={landingData?.config}
        />
      ) : (
        <p className="text-gray-600 text-2xl">{t('Message.landing.error.page_incorrect')}</p>
      )}
    </div>
  );
};
