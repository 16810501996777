import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const Loader = () => (
  <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full w-full aspect-square border-t-2 border-b-2 border-primary"></div>
  </div>
);

export const Spinner = ({ open, title, message }) => {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    // transition only accept boolean values
    setOpenDialog(!!open);
  }, [open]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Transition.Root show={openDialog} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-900/60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg text-center transition-all text-white sm:my-8 w-40 sm:p-2 select-none">
                <div className="relative w-full aspect-square flex justify-center items-center">
                  {(title || message) && (
                    <div className="bg-gray-900/0 p-2 backdrop-filter backdrop-blur-sm flex flex-col whitespace-nowrap justify-center items-center z-10">
                      <span className="text-2xl">{title}</span>
                      <span className="text-md">{message}</span>
                    </div>
                  )}
                  <div className="absolute inset-0">
                    <Loader />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
