import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../config/utils';

export const DialogV2 = ({ open, onClose, children, title, subtitle, footer, width = 'max-w-7xl w-6/12', noScroll }) => {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    // transition only accept boolean values
    setOpenDialog(open ? true : false);
  }, [open]);
  
  const handleClose = () => {
    onClose();
    setOpenDialog(false);
  }
  
  return (
    <Transition.Root show={openDialog} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-[#000000] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className={classNames("fixed inset-0 z-10", !noScroll && 'overflow-y-auto')}>
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={classNames("relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:p-2", width)}>
                <div className="flex justify-end">
                  <XMarkIcon
                    className="w-6 2xl:w-7 h-6 2xl:h-7 text-gray-600 hover:text-gray-800 cursor-pointer"
                    onClick={handleClose}
                  />
                </div>
                { children }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
