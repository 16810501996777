import { useState } from 'react';

import PropTypes from 'prop-types';

import './styles/VideoPlayer.css';

import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../../hooks/useToast2';
import { Spin } from '../../../components/common';

export default function VideoPlayer({
  videos = [],
  currentVideoIndex,
  videoRef,
  imageRef,
  handleTimeUpdate,
  className = '',
  loadingProgress = 0,
}) {
  const classnames = `video-player ${className}`;

  // Para cuando se esté cargando del video
  const [loadingVideo, setLoadingVideo] = useState(false);

  // Para cuando se este cargando el video
  const handleLoadedData = () => {
    setLoadingVideo(false);
  };

  const { t } = useTranslation();
  const { showToast } = useToast2();

  // Para manejar el error en caso de que no cargue el src del video en el reproductor
  const handleSourceError = (event) => {
    showToast({ show: true, type: 'error', message: t('Message.video.error.load') });
  };

  return (
    <div className={classnames}>
      {/* Para cuando no hay nada en el editor */}
      {videos.length != 0 || videos[currentVideoIndex]?.isLoading || (
        <div className="video_player-player-fake">
          <div className="player-fake-content"></div>
        </div>
      )}

      {videos[currentVideoIndex]?.type === 'video' && (
        <video
          key={currentVideoIndex} // Hay que ponerle key aunque no sea el item de una lista porque react tiene  problemas rerenderizando <video>, <audio>
          className="video_player-player"
          ref={videoRef}
          onError={handleSourceError}
          onTimeUpdate={handleTimeUpdate}
          preload="auto"
          onLoadedData={handleLoadedData}
          type="video/mp4"
        />
      )}

      {/* {loadingProgress !== 0 && loadingProgress !== 100 && <div className="absolute inset-0 flex justify-center items-center">
        <div className="relative min-w-min bg-gray-800 opacity-75 p-3 rounded-xl overflow-hidden text-white flex flex-col justify-center items-center shadow-xl">
          <span className="text-xs">Cargando</span>
          <span >{loadingProgress.toFixed(0)} %</span>

          <div className="absolute -z-10 inset-0 flex flex-col justify-end">
            <div className="w-full bg-gray-900" style={{
              height: `${loadingProgress}%`
            }} />
          </div>
        </div>
      </div>} */}

      {loadingVideo && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin key="loading-videos" />
        </div>
      )}
    </div>
  );
}

VideoPlayer.propTypes = {
  videos: PropTypes.array.isRequired,
  currentVideoIndex: PropTypes.number,
  videoRef: PropTypes.object,
  imageRef: PropTypes.object,
  handleTimeUpdate: PropTypes.func,
};
