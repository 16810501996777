import { Suspense } from "react"
import SkeletonRow from "./Body/SkeletonRow"
import ListItemRow from "./Body/ListItemRow"
import { useTable } from "./context/TableContext"

const BodyTable = () => {
  const {
    header, 
    hasCheckable,
    isLoading,
    body,
  } = useTable()
  
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      <Suspense
        fallback={
          <>
            <SkeletonRow header={header} hasCheckable={hasCheckable} />
            <SkeletonRow header={header} hasCheckable={hasCheckable} />
            <SkeletonRow header={header} hasCheckable={hasCheckable} />
          </>
        }
      >
        <ListItemRow />
      </Suspense>
      {body?.length === 0 && isLoading && (
        <>
          <SkeletonRow header={header} hasCheckable={hasCheckable} />
          <SkeletonRow header={header} hasCheckable={hasCheckable} />
          <SkeletonRow header={header} hasCheckable={hasCheckable} />
        </>
      )}
      {isLoading && <SkeletonRow header={header} hasCheckable={hasCheckable} />}
    </tbody>
  )
}
export default BodyTable