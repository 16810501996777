import { DOMAIN } from '../server/paths';

// API
export const API = `${DOMAIN}api/`;
export const API_V1 = `${API}v1/`;

// AUTH
export const token = `${DOMAIN}oauth/token`;
export const user = `${API}user`;
export const forgotPassword = `${API}forgot-password`;
export const resetPassword = `${API}reset-password`;
export const confirmRegister = `${API}confirm-register`;

// COMPANIES
export const companyList = `${API_V1}company-list`;
export const companyGet = `${API_V1}company-get`;
export const companyAdd = `${API_V1}company-add`;
export const companyUpdate = `${API_V1}company-update`;
export const companyDelete = `${API_V1}company-delete`;

// PRODUCTS
export const productList = `${API_V1}product-list`;
export const productGet = `${API_V1}product-get`;
export const productAdd = `${API_V1}product-add`;
export const productUpdate = `${API_V1}product-update`;
export const productDelete = `${API_V1}product-delete`;
export const productClipList = `${API_V1}product-clip-get-all`;

// CATEGORIES
export const categoryList = `${API_V1}category-list`;
export const categoryListByProduct = `${API_V1}category-by-product`;
export const categoryGet = `${API_V1}category-get`;
export const categoryAdd = `${API_V1}category-add`;
export const categoryUpdate = `${API_V1}category-update`;
export const categoryDelete = `${API_V1}category-delete`;
export const categoryClipList = `${API_V1}category-clip-get-all`;
export const categoryClipsUpdate = `${API_V1}category-clip-update`; // category and clips array

// CLIPS
export const clipList = `${API_V1}clip-list`;
export const clipGet = `${API_V1}clip-get`;
export const clipAdd = `${API_V1}clip-add`;
export const clipUpdate = `${API_V1}clip-update`;
export const clipDelete = `${API_V1}clip-delete`;
export const clipCategoriesUpdate = `${API_V1}clip-category-update`; // clip and categories array

// PRESENTATION
export const presentationList = `${API_V1}video-presentation-list`;
export const presentationGet = `${API_V1}video-presentation-get`;
export const presentationAdd = `${API_V1}video-presentation-add`;
export const presentationUpdate = `${API_V1}video-presentation-update`;
export const presentationDelete = `${API_V1}video-presentation-delete`;

// VIDEO
export const videoList = `${API_V1}video-list`;
export const videoAdd = `${API_V1}create-videoss`;
export const progressVideoAdd = `${API_V1}create-videos-progress`;
export const videoAddMultiple = `${API_V1}video-add-multiple`;
export const videoEdit = `${API_V1}video-edit-converted`;
export const videoUpdate = `${API_V1}video-update`; // update video title
export const videoDelete = `${API_V1}video-delete`;
export const videoReuse = `${API_V1}reuse-video`; // send video id
export const videoToken = `${API_V1}get-video-token`; // get video token by id
export const videoSharing = `${API_V1}video-sharing`; // get video token by id
export const importImageToEdit = `${API_V1}up-img-video`;
export const importLandingFiles = `${API_V1}up-files-landing`;
export const downloadLinks = (videoId) => `${API_V1}down-urls/${videoId}`; // Paradescargar los links de los landings creados
export const updateStatusVideo = (videoId) => `${API_V1}archive-video/${videoId}`;
export const sendLandingEmail = `${API_V1}enviar-correo-landing`;
export const resendVideo = `${API_V1}resend-video`;
export const checkoutRender = `${API_V1}checkout-render`;
export const reRenderizedVideo = `${API_V1}re-renderized-clip`;

// RECORDINGS


//TICKETS
export const ticketList = `${API_V1}ticket-list`;
export const ticketAdd = `${API_V1}save-ticket`;

//CATEGORIES
export const categoriesList = `${API_V1}categories-list`;

// USERS
export const userList = `${API_V1}user-list`;
export const userListCompany = `${API_V1}user-list-company`;
export const userGet = `${API_V1}user-get`;
export const userAdd = `${API_V1}user-register`;
export const userUpdate = `${API_V1}user-update`;
export const userDelete = `${API_V1}user-delete`;

// TEMPLATES
export const templateList = `${API_V1}template-list`;
export const templateGet = `${API_V1}template-get`;
export const templateAdd = `${API_V1}template-add`;
export const templateUpdate = `${API_V1}template-update`;
export const templateDelete = `${API_V1}template-delete`;
export const templateEdit = `${API_V1}template-edit-converted`; // edición de template

//COOKIES
export const cookiesList = `${API_V1}cookie-list`;

// STATISTICS
export const statisticsGet = `${API_V1}statistics-get`;
export const statisticsUpdateCTA = `${API_V1}update-statistics-cta`;
export const statisticsUpdateDownload = `${API_V1}update-statistics-download`;

// LANDING
export const landingGet = (token) => `${API}video-landing/${token}`;

// MULTIMEDIA
export const getImg = `${API_V1}multimedia/imagen`;
export const setFrame = `${API_V1}multimedia/thumbnail`;

// NOTIFICATIONS
export const broadcastAuth = `${API_V1}broadcasting/auth`;

// GENERAL
export const systemInfo = `${API_V1}system-info`;

const ApiRoutes = {
  //clients
  clientList: `${API_V1}client-list`,
  clientAdd: `${API_V1}client-add`,
  clientGet: `${API_V1}client-get`,
  clientUpdate: `${API_V1}client-update`,
  clientDelete: `${API_V1}client-delete`,
  clientImport: `${API_V1}file-import`,
  multiClientImport: `${API_V1}add-mult-client`,
  clientDeleteMultiple: `${API_V1}client-delete-multiple`,
  filterByParams: `${API_V1}filter-by-params`,
  tagList: `${API_V1}tag-list`,
  tagAdd: `${API_V1}tag-add`,
  tagUpdate: `${API_V1}tag-update`,
  tagDelete: `${API_V1}tag-delete-multiple`,
  associatedList: `${API_V1}associated-list`,
  listAdd: `${API_V1}list-add`,
  listUpdate: `${API_V1}list-update`,
  assignMultipleTags: `${API_V1}assign-multiple-tags`,
  assignMultipleList: `${API_V1}assign-multiple-list`,
  listDeleteMultiple: `${API_V1}list-delete-multiple`,
  previewImportedClients: `${API_V1}file-import-preview`,

  /*****library*****/
  //files
  moveFiles: `${API_V1}moveFiles`,
  deleteFiles: `${API_V1}deleteFiles`,
  //folders
  listFolders: (type, archived) => `${API_V1}list-folder/${type}/archived/${archived}`,
  listVideosFolder: (id) => `${API_V1}list-videos-folderId/${id}`,
  addFolder: `${API_V1}addFile`,
  deleteFolder: `${API_V1}deleteFolder`,
  renameFolder: `${API_V1}editFile`,
  moveFolder: `${API_V1}moveFolder`,
  duplicateFolder: `${API_V1}duplicateFolder`,
  updateStatusFolder: (folderId, action) => `${API_V1}folders/${folderId}/status/${action}`,
  //portraits
  listPortraits: (folderId) => `${API_V1}list-portadas/${folderId}`,
  listPortraitsByUser: (archived) => `${API_V1}list-all-portadas/${archived}`,
  addPortrait: `${API_V1}addPortada`,
  updatePortrait: (portraitId) => `${API_V1}updatePortada/${portraitId}`,
  deletePortrait: `${API_V1}deletePortada`,
  movePortrait: `${API_V1}mover-portadas`,
  updateStatusPortrait: (portraitId) => `${API_V1}archivar-portadas/${portraitId}`,

  //clips
  listClips: `${API_V1}clip-list`,
  addClip: `${API_V1}clip-add`,
  updateClip: `${API_V1}clip-update`,
  deleteClip: `${API_V1}clip-delete`,
  updateStatusClip: (clipId) => `${API_V1}clip-archived/${clipId}`,
  moveClip: `${API_V1}move-clip`,
  //recordings
  listRecordings: (folderId) => `${API_V1}list-recording/${folderId}`,
  addRecording: `${API_V1}add-recording`,
  updateRecording: (recordingId) => `${API_V1}update-recording/${recordingId}`,
  deleteRecording: (recordingId) => `${API_V1}delete-recording/${recordingId}`,
  moveRecording: `${API_V1}move-recording`,
  updateStatusRecording: (recordingId) => `${API_V1}archived-recording/${recordingId}`,

  /*****Admin*****/
  listUserOrCompanyById: `${API_V1}admin/user-comany-byId`,
  //companies
  listCompanies: `${API_V1}admin/list-companies`,
  addCpmpany: `${API_V1}admin/create-company`,
  updateCpmpany: (companyId) => `${API_V1}admin/update-company/${companyId}`,
  updateLandingDefault: (companyId) => `${API_V1}admin/update-landing/${companyId}`,
  archivedCompanies: (companyId) => `${API_V1}admin/delete-company/${companyId}`,
  deleteCompanies: `${API_V1}admin/company-deleted`,
  restoreCompany: `${API_V1}admin/restore-company`,
  listUsersCompanies: (companyId) => `${API_V1}admin/list-users-company/${companyId}`,
  deleteUsersCompanies: `${API_V1}admin/delete-user`,
  restoreUserCompany: `${API_V1}admin/restore-user`,
  //users
  listVideosByUser: (userId) => `${API_V1}admin/list-user-videos/${userId}`,
  addUser: `${API_V1}admin/create-profile`,
  updateUser: (userId) => `${API_V1}admin/update-user/${userId}`,

  /***** Profile *****/
  updateUserProfile: `${API_V1}update-user-info`,
  updatePassword: `${API_V1}user-update-pass`,

  /***** General *****/
  search: `${API_V1}search`,
};
export default ApiRoutes;
