import { DestinationView } from '../../components/library/Video/Destination/DestinationView';
import { DialogV2, Title } from '../../components/common';
import FolderContextProvider from '../../contexts/folderContext';
import useAuthContext from '../../hooks/useAuthContext';
import { VideoReuse } from '../../services/video/VideoService';
import {
  changeLandingConfig,
  changeTemplate,
  save,
  saveFrontPage,
  saveTexts,
} from '../../features/videos/videosSlice';
import { useDispatch } from 'react-redux';
import { useToast2 } from '../../hooks/useToast2';
import { useNavigate, useParams } from 'react-router-dom';
import { VIDEOS } from '../../routes/app/paths';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useVideo } from './context/VideoContext';

export const ReuseVideoDialog = () => {
  const {openReuseVideoDialog,
    setOpenReuseVideoDialog, isLoadingDataReuse, setIsLoadingDataReuse} = useVideo();
  
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();

  const handleIdSelected = (id) => {
    setOpenReuseVideoDialog(false);
    setIsLoadingDataReuse(true);
    const param = {
      token: user.access_token,
      id,
    };
    VideoReuse(param)
      .then((res) => {
        if (res.status !== 200)
          return showToast({
            show: true,
            type: 'error',
            message: t('Message.video.reuse.error.message1'),
          });

        const config = res?.data?.video?.editLanding?.config;
        const template = Number(res?.data?.video?.editLanding?.template);
        const clips = res?.data?.video?.clips;
        // Los textos superpuestos
        const texts = res?.data?.video?.text;
        const frontPage = res?.data?.video?.video?.frontPage;

        if (!config || typeof template !== 'number' || !clips || !clips.length)
          return showToast({
            show: true,
            type: 'error',
            message: t('Message.video.reuse.error.message2'),
          });

        dispatch(changeLandingConfig(config));
        dispatch(changeTemplate(template));

        // Hay que modificar atributos porque no se guardan exactamente como se usar en el front al editar video
        const reuseClips = clips.map((clip) => ({
          id: clip.id,
          src: clip.src,
          duration: clip.duration || 30,
          type: 'video',
          startSecond: clip.start_time || 0,
          endSecond: clip.end_time || 30,
          originalStartSecond: clip.originalStartSecond || 0,
          originalEndSecond: clip.originalEndSecond || 30,
          originalDuration: clip.originalDuration || 30,
          libraryType: clip.type,
        }));

        const videosCopy = JSON.parse(JSON.stringify(reuseClips));
        dispatch(save({ newVideos: videosCopy, config: { currentVideoIndex: 0 } }));

        let localTexts = [];
        if (texts && texts.length) {
          texts.forEach((text) => {
            const duration = text.end_time - text.start_time;
            const newText = {
              text: text.text || '',
              position: {
                x: text.position.x,
                y: text.position.y,
              },
              styleText: {
                color: text.style?.color || '',
                fontSize: text.style['font-size'] || '',
                fontFamily: text.style['font-family'] || '',
              },
              startSecond: text.start_time,
              duration: duration,
              parentSize: text?.style?.parentSize,
            };

            localTexts.push(newText);
          });
        }

        const textCopy = JSON.parse(JSON.stringify(localTexts));
        // Estos son los textos superpuestos, no se validan porque no son obligatorios
        dispatch(saveTexts(textCopy));

        // Esto es para la portada
        dispatch(saveFrontPage(frontPage));

        showToast({
          show: true,
          type: 'success',
          message: t('Message.video.reuse.success.reuse_selected_video'),
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingDataReuse(false);
        navigate(VIDEOS);
      });
  };

  const handleClose = () => {
    setOpenReuseVideoDialog(false);
    navigate(VIDEOS);
  };

  useEffect(() => {
    if (isLoadingDataReuse) return;
    if (params?.id) handleIdSelected(params.id);
  }, [params?.id]);

  return (
    <DialogV2 open={openReuseVideoDialog} onClose={handleClose}>
      <div className="mx-10 mt-2 space-y-4 2xl:space-y-8">
        <div className="flex justify-between items-center">
          <Title title="Reutilizar vídeo" />
        </div>
        <FolderContextProvider library="video">
          <DestinationView
            onSelected={handleIdSelected}
            getFileId
            library="video"
            onClose={handleClose}
          />
        </FolderContextProvider>
      </div>
    </DialogV2>
  );
};
