import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Spin } from '../loaders/Spin';
import { classNames } from '../../../config/utils';

const GeneralConfirModal = ({ isOpen = true, label, subtitle, alternativeText, onConfirm, onAlternative, loading = false, action = 'delete' }) => {

  const [isAlternativeSelected, setIsAlternativeSelected] = useState(false);
  
  // hooks
  const { t } = useTranslation();

  // handlers
  const handleConfirm = (confirm) => {
    if (onAlternative && isAlternativeSelected) {
      return onAlternative(confirm);
    }
    onConfirm(confirm);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => { }}
        onClick={() => handleConfirm(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white px-4 text-left shadow-xl transition-all sm:my-8 max-w-xl w-full py-16">
                <div className="px-8 pt-10 flex flex-col items-center justify-center">
                  <ExclamationTriangleIcon className="w-24 h-24 text-primary" />
                  <p className="text-xl font-light mt-8">
                    {
                      action === 'logout'
                        ? t('Message.auth.confirm_logout') :
                      action === 'restore'
                        ? t('Message.user.confirm_restore', { text: label}) :
                      action === 'custom'
                        ? label
                        // delete
                        : t('ModalDeleteWithText.title', { text: label })
                    }
                  </p>
                  <p className="text-sm font-light mt-2 text-center mb-10">
                    { subtitle }
                  </p>

                  {/* a checkbox with alternativeText, to switch in callbacks */}
                  {
                    alternativeText && onAlternative && (
                      <div className="flex items-center mt-4">
                        <input
                          type="checkbox"
                          className="rounded border-gray-300 text-primary focus:ring-primary"
                          checked={isAlternativeSelected}
                          onChange={() => setIsAlternativeSelected(!isAlternativeSelected)}
                        />
                        <p className="ml-2 text-sm font-light">
                          { alternativeText }
                        </p>
                      </div>
                    )
                  }

                  <div className="flex space-x-4 mt-5 mb-8">
                    <button
                      className="px-4 py-2.5 border-gray-300 border rounded-md text-gray-300"
                      onClick={() => handleConfirm(false)}
                    >
                      {t('ModalDeleteWithText.cancel')}
                    </button>
                    <button
                      className={classNames('px-10 py-2.5 border-primary border rounded-md text-white bg-primary')}
                      onClick={() => handleConfirm(true)}
                      disabled={loading}
                    >
                      {
                        loading ? <Spin /> : t('ModalDeleteWithText.confirm')
                      }
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default GeneralConfirModal
