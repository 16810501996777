import { memo } from "react";
import Field from "./Field";
import { useTable } from "../../context/TableContext";

const ListField = memo(({index, row, selectedRow }) => {
  const { handleButtonClick } = useTable();
  
  return (
    <>
    {row.map((item, indexItem) => (
      <Field
        item={item}
        key={row[indexItem].name ? `td-${row[indexItem].name}-${row.indexOf(row[index])}` : `td-button${indexItem}-${row.indexOf(row[index])}`}
        selected={selectedRow.includes(row)}
        onClick={handleButtonClick}
      />
    ))}
    </>
  )
})
ListField.displayName = 'ListField';

export default ListField;