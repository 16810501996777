import { FirstTemplate, FirstTemplateMini, FirstTemplateFull } from "./FirstTemplate"
import { SecondTemplate, SecondTemplateMini, SecondTemplateFull } from "./SecondTemplate"
import { ThirdTemplate, ThirdTemplateMini, ThirdTemplateFull } from "./ThirdTemplate"

export const TemplateSelector = ( props ) => {
  switch (props.number) {
    case 1: return <FirstTemplate {...props} />;
    case 2: return <SecondTemplate {...props} />;
    case 3: return <ThirdTemplate {...props} />;
    default: return null;
  }
}

export const TemplateSelectorMini = ( props ) => {
  switch (props.number) {
    case 1: return <FirstTemplateMini />;
    case 2: return <SecondTemplateMini />;
    case 3: return <ThirdTemplateMini />;
    default: return null;
  }
}

export const TemplateSelectorFull = ( props ) => {
  switch (props.number) {
    case 1: return <FirstTemplateFull {...props}/>;
    case 2: return <SecondTemplateFull {...props}/>;
    case 3: return <ThirdTemplateFull {...props}/>;
    default: return null;
  }
}