import { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Container as LibraryContainer, Options } from '../../components/library';
import { getElementById, getPathById } from '../../pages/Library/tools/pathUtils';
import { FolderContext } from '../../contexts/folderContext';
import { Section, Skeleton } from '../../components/common';
import { VideoList, PortraitList } from '../../components/library';
import EditFrontPageModal from './EditFrontPageModal';
import { useLocation } from 'react-router-dom';

export function LibraryComp({ rootFolder, rootArchived, isLoading }) {
  /* Context */
  const {
    toggleArchive,
    setToggleArchive,
    idFolder,
    idFolderArchived,
    setIdFolder,
    setIdFolderArchived,
    currentFolder,
    setCurrentFolder,
    library,
    setFoldersLoading,
    setFilesLoading,
    setFoldersSelected,
    setFilesSelected,
    setRootFolders,
  } = useContext(FolderContext);
  
  const [files, setFiles] = useState({
    lastFiles: [],
    restFiles: [],
  });
  
  // hooks
  const {t} = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const folderParam = Number(searchParams.get('folder') || 0);

  /* Effects */
  useEffect(() => {
    const getFolder = () => {
      if (toggleArchive) {
        if (!idFolderArchived) return rootArchived;
        const id = getElementById(idFolder, rootArchived);
        if (!id || !Object.keys(id).length) return rootArchived;
        return id;
      }
      if (!idFolder) return rootFolder;
      const id = getElementById(idFolder, rootFolder);
      if (!id || !Object.keys(id).length) {
        setIdFolder(0);
        return rootFolder;
      }
      return id;
    };

    setCurrentFolder(getFolder());
    setFoldersLoading([]);
    setFilesLoading([]);
    setFoldersSelected([]);
    setFilesSelected([]);
    setRootFolders(toggleArchive ? rootArchived?.children || [] : rootFolder?.children || [])
  }, [idFolder, idFolderArchived, toggleArchive, rootFolder, rootArchived]);

  useEffect(() => {
    const files = currentFolder?.files;
    const currentDate = moment().format('YYYY-MM-DD');
    let lastFiles = [];
    let restFiles = [];
    files?.map((f) => {
      if (!f.created_at) return;
      const dateFile = moment(f.created_at).format('YYYY-MM-DD');
      if (Math.abs(moment(dateFile).diff(moment(currentDate), 'days')) <= 30) {
        lastFiles.push(f);
      } else {
        restFiles.push(f);
      }
    });
    setFiles({ lastFiles: [...lastFiles], restFiles: [...restFiles] });
    return () => {
      setFiles({ lastFiles: [], restFiles: [] });
    };
  }, [currentFolder]);

  useEffect(() => { // set folder by url param, for search
    // check if exits a params named folder, not the value
    if (!searchParams.has('folder')) return;
    setToggleArchive(false);
    setIdFolder(folderParam);
  }, [folderParam, rootFolder]);

  /* FUNCTIONS */
  const path = () => {
    let path = [
      {
        id: 0,
        name:
          (library === 'video' && t('Library.created_videos')) ||
          (library === 'corporative' && t('Library.company_videos')) ||
          (library === 'record' && t('Library.my_records')) ||
          (library === 'portrait' && t('Library.frontPages')),
      },
    ];
    if (toggleArchive) {
      if (!idFolderArchived || !rootArchived) return path;
      path = path.concat(getPathById(idFolderArchived, rootArchived));
      return path;
    }
    if (!idFolder || !rootFolder) return path;
    const subPath = getPathById(idFolder, rootFolder);
    path = path.concat(subPath);
    return path;
  };

  const [showEditPortrait, setShowEditPortrait] = useState(false);

  const [editPortrait, setEditPortrait] = useState(null);

  const handleInitEditPortrait = (portrait) => {
    setEditPortrait(portrait);
    setShowEditPortrait(true);
  };

  const rootFolders = useMemo(() => (
    toggleArchive ? rootArchived?.children || [] : rootFolder?.children || []
  ), [toggleArchive, rootArchived, rootFolder]);

  return (
    <LibraryContainer
      breadcumbs={{
        path: path(),
        onClick: (id) => {
          toggleArchive ? setIdFolderArchived(id) : setIdFolder(id);
        },
      }}
      title={
        (library === 'video' && t('Library.created_videos')) ||
        (library === 'corporative' && t('Library.company_videos')) ||
        (library === 'record' && t('Library.my_records')) ||
        (library === 'portrait' && t('Library.frontPages'))
      }
      view="videos"
      rootFolders={rootFolders}
      folders={currentFolder?.children || []}
      isLoading={isLoading}
      library={library}
    >
      {/* Options */}
      
      <div>
        {isLoading ? (
          <>
            <Skeleton width={100} height={20} className="mt-8" />
            <div className="flex flex-wrap justify-between mt-4">
              {[1, 2, 3, 4].map((key) => (
                <div
                  key={key + '_skeleton'}
                  className="w-[150px] 2xl:w-[260px] h-[150px] 2xl:h-[285px]"
                >
                  <Skeleton />
                </div>
              ))}
            </div>
          </>
        ) : (
          currentFolder?.files?.length > 0 && (
            <div>
              {files.lastFiles.length > 0 && (
                <Section
                  size="text-[24px]"
                  title={`${(library === 'record' && t('Library.newRecords')) ||
                    (library === 'portrait' && t('Library.newFrontPages')) ||
                    t('Library.newVideos')
                    }`}
                >
                  {library === 'portrait' ? (
                    <PortraitList items={files.lastFiles} editPortrait={handleInitEditPortrait} />
                  ) : (
                    <VideoList items={files.lastFiles} isLoading={isLoading} />
                  )}
                </Section>
              )}
              {files.restFiles.length > 0 && (
                <Section
                  size="text-[24px]"
                  title={`${(library === 'record' && t('Library.records')) ||
                    (library === 'portrait' && t('Library.frontPages')) ||
                    t('Library.videos')
                    }`}
                >
                  {library === 'portrait' ? (
                    <PortraitList items={files.restFiles} editPortrait={handleInitEditPortrait} />
                  ) : (
                    <VideoList items={files.restFiles} isLoading={isLoading} />
                  )}
                </Section>
              )}
            </div>
          )
        )}

        <EditFrontPageModal
          open={showEditPortrait}
          onClose={() => setShowEditPortrait(false)}
          frontPage={editPortrait}
        />
      </div>
    </LibraryContainer>
  );
}
