import { useTranslation } from "react-i18next"
import { classNames } from "../../../config/utils"
import { useTable } from "./context/TableContext"

const WrapperTable = ({ children, className }) => {
  // context
  const { 
    body, 
    isLoading, 
  } = useTable()
  
  // hooks
  const { t } = useTranslation()

  return (
    <div className={classNames(className, 'relative h-[100%]')}>
      <div
        className="table-container h-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-secondary scrollbar-track-primary scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
      >
        <table className="border-separate border-spacing-y-2 border-spacing-x-[1px] w-full">
          { children }
        </table>
        {body?.length === 0 && !isLoading && (
          <div className="text-gray-500 font-semibold text-center">
            {t('AdminZone.company.messages.nondata')}
          </div>
        )}
      </div>
    </div>
  )
}
export default WrapperTable