import { Skeleton } from "../../components/common"

const ClientSkeleton = () => {
  return (
    <div className="my-3 h-12 bg-gray-list py-1.5 px-4 rounded-xl flex justify-between">
      <div className="flex space-x-3 items-center w-full">
        <Skeleton width={20} height={20} className="rounded w-5 h-5 border-gray-200 border-[1px] " />
        <div className="flex flex-col flex-1 gap-1">
          <Skeleton width={100} height={8}  />
          <Skeleton width={200} height={8}  />
        </div>
        <Skeleton width={80} height={25} className="rounded w-5 h-5 border-gray-200 border-[1px] " />
      </div>
    </div>
  )
}
export default ClientSkeleton