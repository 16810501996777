import { useDispatch } from 'react-redux';

import { useEffect, useState, useContext, useRef } from 'react';
import { FrameList } from '../../FrameList';
import { filterMostRecent } from '../../../../config/utils';
import FolderList from '../../../../components/library/Video/Destination/FolderList';
import { FolderContext } from '../../../../contexts/folderContext';
import { getPathById, getElementById } from '../../../../pages/Library/tools/pathUtils';

import './styles/SelectFolderVideo.css';
import { changeStep, saveVideoData } from '../../../../features/videos/videosSlice';
import { Breadcumbs } from '../../../../components/library';
import { useTranslation } from 'react-i18next';
import { DialogV2 } from '../../../../components/common';
import moment from 'moment';

export default function SelectFolderVideo({ showModal, onClose, rootFolder, isLoading, nextStep }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState({
    lastFiles: [],
    restFiles: [],
  });

  // El nombre que se le va a poner al video
  const [videoName, setVideoName] = useState('Nuevo video');
  const inputRef = useRef(null)

  const {
    idFolder = null,
    setIdFolder,
    currentFolder,
    setCurrentFolder,
  } = useContext(FolderContext);

  const { t } = useTranslation();

  /* Effects */
  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus()
  }, [inputRef.current, showModal])
  
  useEffect(() => {
    const getFolder = () => {
      if (!idFolder) return rootFolder;
      return getElementById(idFolder, rootFolder);
    };

    setCurrentFolder(getFolder());
  }, [idFolder, rootFolder]);

  // Para filtrar los elementos más recientes
  useEffect(() => {
    const files = currentFolder?.files
      ?.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
    const { lastElements, restElements } = filterMostRecent(files);

    setFiles({ lastFiles: lastElements, restFiles: restElements });

    return () => {
      setFiles({ lastFiles: [], restFiles: [] });
    };
  }, [currentFolder]);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  const handleChangeName = (event) => {
    setVideoName(event.target.value);
  };

  // Aqui no es que se renderiza y se guarda el video en la ubicación seleccionada
  const handleSaveVideo = () => {
    onClose(false);
    setOpen(false);
    dispatch(saveVideoData({ folderId: idFolder, videoName }));
    // Ir al siguiente paso
    nextStep ? nextStep() : dispatch(changeStep(1));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSaveVideo();
    }
  }

  const path = () => {
    let path = [
      {
        id: 0,
        name: t('Library.created_videos'),
      },
    ];
    if (!idFolder) return path;
    const subPath = getPathById(idFolder, rootFolder);
    path = path.concat(subPath);
    return path;
  };

  return (
    <DialogV2 open={open} onClose={() => onClose(false)} width='max-w-[917px] min-w-[917px] max-h-[774px] min-h-[700px] w-full'>
      <div className="px-8 ">
        {/* Header area */}
        <div className="flex justify-between items-center mb-2">
          <div className="select_folder-title w-full">
            <h3>{t('Edition.save_as')}</h3>
            <input
              ref={inputRef}
              value={videoName}
              type="text"
              onChange={handleChangeName}
              onKeyDown={handleKeyDown}
              placeholder={t('Placeholder.new_video')}
            />
          </div>
        </div>

        <div className="folder-list-container mt-4">
          {/* Folders */}
          <div className="my-3">
            <Breadcumbs path={path()} onClick={setIdFolder} />
          </div>
          <FolderList folders={currentFolder?.children} isLoading={isLoading} />
        </div>

        {/* Section area */}
        {/* Videos correspondientes a la carpeta */}
        <div className="videos-list mt-10">
          {files.lastFiles.length > 0 && (
            <section>
              <h3 className="mb-2 mt-4">{t('Library.recents')}</h3>
              <FrameList videos={files.lastFiles} isLoading={isLoading} />
            </section>
          )}
          {files.restFiles.length > 0 && (
            <section>
              <h3 className="mb-2 mt-4">{t('Library.videos')}</h3>
              <FrameList videos={files.restFiles} isLoading={isLoading} />
            </section>
          )}
        </div>

        <div className="flex mt-8 space-x-4 items-center">
          <button
            className="px-2 border rounded-lg"
            onClick={() => {
              onClose(false);
              setOpen(false);
            }}
            style={{ height: 45 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30"
              viewBox="0 -960 960 960"
              width="30"
              fill="#b4abac"
            >
              <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
            </svg>
          </button>
          <button
            className="text-white bg-primary px-6 rounded-lg"
            onClick={handleSaveVideo}
            style={{ height: 45 }}
          >
            {t('Edition.save')}
          </button>
        </div>
      </div>
    </DialogV2>
  );
}
