import { classNames } from '../../config/utils';

export function Tabs({ tabs, onSelect }) {
  return (
    <div className="flex space-x-6 2xl:space-x-8 select-none">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          onClick={() => !tab.disabled && onSelect(tab)}
          className={classNames(
            tab.selected ? ' border-b-primary border-b-4 text-primary' : '',
            tab.disabled ? 'text-gray-400' : 'text-gray-900 cursor-pointer',
            !tab.selected && !tab.disabled && 'hover:text-gray-900/70',
            'transition-all duration-100',
          )}
        >
          <span
            className={classNames(
              'text-[17px] 2xl:text-[21px] font-bold',
            )}
          >
            {tab.title}
          </span>
        </div>
      ))}
    </div>
  );
}
