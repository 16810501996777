import { useEffect, useMemo, useRef, useState } from 'react';
import { AccordionContainer, Accordion, InfoBox } from '../../components/common';
import { BsPlus, BsWhatsapp } from 'react-icons/bs';
import { RadioGroup } from '@headlessui/react';
import { MdOutlineEmail } from 'react-icons/md';
import { FaPhone } from 'react-icons/fa';
import { classNames, generateImageUrl } from '../../config/utils';
import { useSelector } from 'react-redux';
import { selectLandingConfig, templateSelected } from '../../features/videos/videosSlice';
import { useToast2 } from '../../hooks/useToast2';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Routes from '../../routes/app/paths';

const LogoLoader = ({ className = '', onImageChange, image }) => {
  const { showToast } = useToast2();
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(image || null);
  const [imageNotFound, setImageNotFound] = useState(false)

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // verify if the file is an image
    if (!file?.type.includes('image')) {
      showToast({
        show: true,
        type: 'warning',
        message: t('Message.landing.image_format'),
      });
      return;
    }
        
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
      setImageNotFound(false)
      
      if (onImageChange) {
        onImageChange(reader.result);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // verify url has error
    if (image){
      const img = new Image();
      img.src = generateImageUrl(image);
      img.onerror = () => setImageNotFound(true);
    }
    if (selectedImage){
      const img = new Image();
      img.src = selectedImage;
      img.onerror = () => setImageNotFound(true);
    }
  }, [image, selectedImage]);

  return (
    <div
      className={classNames(
        className,
        'h-20 relative bg-gray-100 rounded-xl flex justify-center items-center overflow-hidden',
      )}
    >
      <div className="w-10 relative aspect-square rounded-full flex justify-center items-center bg-gray-200 cursor-pointer duration-200 hover:shadow-md z-10 opacity-70">
        <BsPlus size={40} className="text-gray-400" />
        <input
          className="absolute w-full h-full opacity-0"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
        />
      </div>
      {selectedImage && !imageNotFound && (
        <div className="absolute top-0 left-0 right-0 bottom-0">
          <img
            src={generateImageUrl(selectedImage)}
            className="h-full w-full object-cover object-center"
            alt="Uploaded"
          />
        </div>
      )}
    </div>
  );
};

const Images = ({ onChange }) => {
  const config = useSelector(selectLandingConfig);
  const [images, setImages] = useState(config?.images);
  const templateNum = useSelector(templateSelected);

  useEffect(() => {
    onChange(images);
  }, [images]);

  const handleImageChange = (image, key) => {
    switch (key) {
      case 'firstLogo':
        setImages((prev) => ({ ...prev, firstLogo: image }));
        break;
      case 'secondLogo':
        setImages((prev) => ({ ...prev, secondLogo: image }));
        break;
      case 'mainImage':
        setImages((prev) => ({ ...prev, mainImage: image }));
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={classNames('w-full gap-3 grid grid-cols-2', templateNum !== 3 && 'grid-rows-2')}
    >
      <LogoLoader
        onImageChange={(img) => handleImageChange(img, 'firstLogo')}
        image={images?.firstLogo}
      />
      <LogoLoader
        onImageChange={(img) => handleImageChange(img, 'secondLogo')}
        image={images?.secondLogo}
      />
      {templateNum !== 3 && (
        <LogoLoader
          className="col-span-2"
          onImageChange={(img) => handleImageChange(img, 'mainImage')}
          image={images?.mainImage}
        />
      )}
    </div>
  );
};

const Texts = ({ onChange }) => {
  const config = useSelector(selectLandingConfig);
  const [state, setState] = useState(config?.texts);
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const addNameFragment = () => {
    const { selectionStart, selectionEnd } = inputRef.current;
    const text = t('Edition.merge_tag');
    const firstPart = state?.title?.slice(0, selectionStart) || '';
    const secondPart = state?.title?.slice(selectionEnd, state?.title.length || 0) || '';
    const newTitle = `${firstPart}${text}${secondPart}`;
    setState((prev) => ({ ...prev, title: newTitle }));
    inputRef.current.focus();
    inputRef.current.selectionStart = selectionStart + text.length;
    inputRef.current.selectionEnd = selectionStart + text.length;
  };

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <div className="w-full gap-4 flex flex-col">
      <div className="w-full space-y-2">
        <span className="font-semibold">{t('Edition.title')}</span>
        <div className="flex space-x-4 justify-center items-center">
          <input
            ref={inputRef}
            type="text"
            className="w-full border-0 bg-gray-100 rounded-md p-3 py-2 placeholder:text-gray-400"
            placeholder='"Hola"'
            value={state?.title || ''}
            onChange={(e) => setState((prev) => ({ ...prev, title: e.target.value }))}
          />
          {/* boton con un icono de + pero usando un div negro con texto blanco */}
          <div
            className="bg-black text-white p-2 py-1 pr-3 rounded-lg cursor-pointer flex items-center justify-center space-x-0"
            onClick={addNameFragment}
          >
            <BsPlus size={30} className="" />
            <span>{t('Edition.name')}</span>
          </div>
        </div>
      </div>
      <div className="w-full space-y-2">
        <span className="font-semibold">{t('Edition.message')}</span>
        <div className="h-24 flex space-x-4">
          <textarea
            type="text"
            className="w-full h-full overflow-y-scroll text-left align-top border-0 bg-gray-100 rounded-md p-3 py-2 placeholder:text-gray-400"
            placeholder="Escriba un mensaje"
            value={state?.message || ''}
            onChange={(e) => setState((prev) => ({ ...prev, message: e.target.value }))}
          />
        </div>
      </div>
    </div>
  );
};

const urlRegex = /^(?:(?:https?:\/\/)?(?:www\.)?(?!www\.)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})$/i;

const Link = ({ number, link, hasCheck = false, onChange }) => {
  const [state, setState] = useState(link);
  const { t } = useTranslation();
  const location = useLocation();
  const isAdminScope = location.pathname.includes(Routes.companyProfile);

  const isValidURL = useMemo(() => {
    return urlRegex.test(state.urlLink);
  }, [state]);
  
  const isValidName = useMemo(() => {
    return state.textLink.length > 0;
  }, [state]);

  const handleLinkChange = (e) => {
    const newState = { ...state, urlLink: e.target.value, isInvalid: !urlRegex.test(e.target.value) || !isValidName };
    setState(newState)
    onChange(newState)
  }
  
  const handleLabelChange = (e) => {
    const newState = { ...state, textLink: e.target.value, isInvalid: e.target.value.length === 0 || !isValidURL };
    setState(newState)
    onChange(newState)
  }

  useEffect(() => {
    const newState = { ...state, isInvalid: !isValidURL || !isValidName };
    onChange(newState);
  }, []);

  return (
    <div className="w-full space-y-4">
      <div className="w-full flex items-center">
        <span className="font-semibold mr-2">{t('Landing.link')}{number && ' ' + number}</span>
      </div>
      <div className="relative w-full flex items-center">
        <input
          type="text"
          className={
            classNames('w-full border-0 bg-gray-100 rounded-md p-3 py-2 placeholder:text-gray-400 ',
            (hasCheck && 'pr-12'), !isValidURL && !isAdminScope && 'border-[1px] border-red-500 bg-red-50')
          }
          placeholder="Enlace"
          value={state?.urlLink || ''}
          onChange={handleLinkChange}
        />
        {/* {hasCheck && (
          <div className="h-4/6 rounded aspect-square shadow-md bg-white absolute right-2 cursor-pointer"></div>
        )} */}
      </div>
      <input
        type="text"
        className={classNames("w-full border-0 bg-gray-100 rounded-md p-3 py-2 placeholder:text-gray-400", 
        !isValidName && !isAdminScope && 'border-[1px] border-red-500 bg-red-50')}
        placeholder="Texto de enlace"
        value={state?.textLink || ''}
        onChange={handleLabelChange}
      />
    </div>
  );
};

const PDFs = ({ onChange }) => {
  const config = useSelector(selectLandingConfig);
  const [check, setCheck] = useState(config?.pdf_links?.links?.check);
  const [linksState, setLinksState] = useState(config?.pdf_links?.links?.link || []);
  const [colorButtonsLinks, setColorButtonsLinks] = useState(
    config?.pdf_links?.links?.colorButtonsLinks || '',
  );
  const [statePdf, setStatePdf] = useState(config?.pdf_links?.pdf);
  const { t } = useTranslation();

  const { showToast } = useToast2();

  const someLinkIsInvalid = useMemo(() => {
    return linksState.some((link) => link.isInvalid);
  }, [linksState]);

  useEffect(() => {
    onChange({
      pdf: statePdf,
      links: {
        link: linksState,
        colorButtonsLinks: colorButtonsLinks,
        check: check,
      },
      someLinkIsInvalid,
    });
  }, [statePdf, linksState, colorButtonsLinks, someLinkIsInvalid]);

  useEffect(() => {
    check === '1' && setLinksState((prev) => [prev[0]]);
  }, [check]);

  /* Handlers */
  const handleNewLink = () => {
    const limit = 5; //no se admiten mas de 5 links
    if (linksState.length >= limit) {
      showToast({
        show: true,
        type: 'warning',
        message: t('Message.landing.link_limit'),
      });
      return;
    }
    setLinksState((prev) => {
      let temp = [...prev];
      temp.push({ textLink: t('Landing.link'), urlLink: '' });
      return temp;
    });
  };

  const handleOnChangeLink = (link, index) => {
    setLinksState((prev) => {
      let temp = [...prev];
      temp[index] = link;
      return temp;
    });
  };

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    
    // verify if the file is a pdf
    if (file?.type !== 'application/pdf') {
      showToast({
        show: true,
        type: 'warning',
        message: t('Message.landing.pdf_format'),
      });
      return;
    }
    const reader = new FileReader();

    reader.onload = () => {
      setStatePdf({ ...statePdf, fileName: file.name, file: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="w-full gap-12 flex flex-col">
      {/* PDF adjuntar */}
      <div className="w-full space-y-2">
        <div className="flex items-center space-x-2">
          <span className="font-semibold">{t('Edition.attach_PDFs')}</span>
          <InfoBox message="¡Adjunta folletos, catálogos, presupuestos y más! Tu contacto podrá desargarlos desde la misma landing haciendo clic en el botón asociado" />
        </div>
        <div className="flex flex-col space-y-4 justify-center items-center">
          <div className="relative flex justify-between items-center w-full border-0 bg-gray-100 rounded-md p-3 py-0 pr-1">
            <input
              type="file"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={handlePdfUpload}
              accept="application/pdf"
            />
            <span className="text-gray-400">
              {statePdf?.fileName ? statePdf.fileName : 'Seleccionar archivo'}
            </span>
            <BsPlus size={40} className="text-gray-400" />
          </div>
          <div className="relative w-full flex items-center">
            <input
              type="text"
              className="w-full border-0 bg-gray-100 rounded-md p-3 py-2 pr-12 placeholder:text-gray-400"
              placeholder="Texto del boton de descarga"
              value={statePdf?.dowloadButton.text || ''}
              onChange={(e) =>
                setStatePdf((prev) => ({
                  ...prev,
                  dowloadButton: { ...statePdf?.dowloadButton, text: e.target.value },
                }))
              }
            />
            <input
              type="color"
              value={config?.pdf_links?.pdf?.dowloadButton?.textColor || ''}
              className="h-8 w-8 p-0 rounded aspect-square shadow-md bg-white absolute right-2 cursor-pointer"
              onChange={(e) =>
                setStatePdf((prev) => ({
                  ...prev,
                  dowloadButton: { ...statePdf?.dowloadButton, textColor: e.target.value },
                }))
              }
            />
          </div>
          <div className="relative w-full flex items-center py-2">
            <input
              type={'color'}
              value={config?.pdf_links?.pdf?.dowloadButton?.buttonColor || ''}
              className="h-8 w-8 mr-3 p-0 rounded aspect-square shadow-md  bg-white cursor-pointer"
              onChange={(e) =>
                setStatePdf((prev) => ({
                  ...prev,
                  dowloadButton: { ...statePdf?.dowloadButton, buttonColor: e.target.value },
                }))
              }
            />
            <span className="text-md font-semibold">{t('Edition.button_color')}</span>
          </div>
        </div>
      </div>

      {/* Enlaces */}
      <div className="w-full space-y-5">
        <div className="flex items-center space-x-2">
          <span className="font-bold">{t('Edition.ToolBar.add_links')}</span>
          <InfoBox message="Añade enlaces a tu página web o a tu Calendly para que tu contacto pueda tomar acción tan pronto como termine de ver el vídeo" />
        </div>
        <RadioGroup value={check} onChange={setCheck}>
          <div className="flex flex-col w-full space-y-2">
            <RadioGroup.Option value="1" className="flex items-center space-x-2 cursor-pointer">
              {({ checked }) => (
                <>
                  <input
                    type="radio"
                    className="text-green-500 bg-gray-200 form-radio border-0 rounded-full scale-125 mr-2"
                    checked={checked}
                    readOnly
                  />
                  <span className="font select-none">{t('Edition.a_link')}</span>
                </>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option value="2" className="flex items-center space-x-2 cursor-pointer">
              {({ checked }) => (
                <>
                  <input
                    type="radio"
                    className="text-green-500 bg-gray-200 form-radio border-0 rounded-full scale-125 mr-2"
                    checked={checked}
                    readOnly
                  />
                  <span className="font select-none">{t('Edition.links_menu')}</span>
                </>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>

        <div className="flex flex-col space-y-6 justify-center items-center">
          {linksState.map((link, index) => (
            //llamada a componete link
            <Link
              key={index}
              number={check !== '1' && index + 1}
              hasCheck={check === '2' && index === 0}
              link={link}
              onChange={(link) => handleOnChangeLink(link, index)}
            />
          ))}
        </div>
        {check === '2' && (
          <div
            className="w-full flex items-center cursor-pointer rounded-xl hover:shadow-lg"
            onClick={handleNewLink}
          >
            <BsPlus size={40} className="text-gray-400" />
            <span className="text-gray-400 ml-2">{t('Edition.new_link')}</span>
          </div>
        )}
        <div className="relative w-full flex items-center py-2">
          <input
            type={'color'}
            value={config?.pdf_links?.links?.colorButtonsLinks || ''}
            className="h-8 w-8 mr-3 p-0 rounded aspect-square shadow-md  bg-white cursor-pointer"
            onChange={(e) => setColorButtonsLinks(e.target.value)}
          />
          <span className="text-md font-semibold">{t('Edition.button_color')}</span>
        </div>
      </div>
    </div>
  );
};

const RowContact = ({ placeholder = '', value, onChange }) => {
  return (
    <input
      type="text"
      className={'w-full border-0 bg-gray-100 rounded-md p-2 py-1 placeholder:text-gray-400'}
      placeholder={placeholder}
      value={value || ''}
      onChange={onChange}
    />
  );
};

const Contacts = ({ onChange }) => {
  const config = useSelector(selectLandingConfig);
  const [data, setData] = useState(config?.contacts);
  const { t } = useTranslation();

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <div className="w-full flex flex-col space-y-4">
      <div className="flex items-center space-x-6">
        <BsWhatsapp className={`text-gray-500`} size={22} />
        <RowContact
          placeholder={t('Placeholder.whatsapp')}
          value={data?.whatsapp}
          onChange={(e) => setData((prev) => ({ ...prev, whatsapp: e.target.value }))}
        />
      </div>

      <div className="flex items-center space-x-6">
        <FaPhone className={`text-gray-500 rotate-90`} size={22} />
        <RowContact
          placeholder={t('Placeholder.phone')}
          value={data?.phone}
          onChange={(e) => setData((prev) => ({ ...prev, phone: e.target.value }))}
        />
      </div>

      <div className="flex items-center space-x-6">
        <MdOutlineEmail className={`text-gray-500`} size={24} />
        <RowContact
          placeholder={t('Placeholder.email')}
          value={data?.email}
          onChange={(e) => setData((prev) => ({ ...prev, email: e.target.value }))}
        />
      </div>
    </div>
  );
};

export const AccordionEditLanding = ({ onConfig }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const { t } = useTranslation();

  /* Handlers */
  const handleImagesOnChange = (data) => {
    onConfig((prev) => ({ ...prev, images: data }));
  };
  const handleTextsOnChange = (data) => {
    onConfig((prev) => ({ ...prev, texts: data }));
  };
  const handleContactsOnChange = (data) => {
    onConfig((prev) => ({ ...prev, contacts: data }));
  };
  const handlePdfOnChange = (data) => {
    onConfig((prev) => ({ ...prev, pdf_links: data }));
  };

  return (
    <AccordionContainer classNameChildren="text-gray-700 text-xs 2xl:text-md">
      <Accordion
        title={t('Edition.logos_background_image')}
        index={0}
        open={activeIndex === 0}
        onOpen={() => setActiveIndex(0)}
      >
        <Images onChange={handleImagesOnChange} />
      </Accordion>

      <Accordion title={t('Edition.ToolBar.texts')} index={1} open={activeIndex === 1} onOpen={() => setActiveIndex(1)}>
        <Texts onChange={handleTextsOnChange} />
      </Accordion>

      <Accordion
        title={t('Edition.pdf_links')}
        index={2}
        open={activeIndex === 2}
        onOpen={() => setActiveIndex(2)}
      >
        <PDFs onChange={handlePdfOnChange} />
      </Accordion>

      <Accordion
        title={t('Edition.contacts')}
        index={3}
        open={activeIndex === 3}
        onOpen={() => setActiveIndex(3)}
      >
        <Contacts onChange={handleContactsOnChange} />
      </Accordion>
    </AccordionContainer>
  );
};
