import useAuthContext from '../hooks/useAuthContext';
import { useState, useMemo, createContext, useCallback, useEffect } from 'react';
import { querys } from '../pages/Library/tools/querys';
import { useNavigate } from 'react-router-dom';
import { useVideoStepsContext } from '../components/video/videoSteps/context/VIdeoStepsContext';

export const FolderContext = createContext();

export default function FolderContextProvider({ children, library }) {
  const { user } = useAuthContext();
  const { setOpenSelfRecord } = useVideoStepsContext();

  // states
  const [idFolder, setIdFolder] = useState(0);
  const [idFolderArchived, setIdFolderArchived] = useState(0);
  const [currentFolder, setCurrentFolder] = useState({});
  const [toggleArchive, setToggleArchive] = useState(false);
  const [itemsDisplay, setItemsDisplay] = useState('miniature');
  const [foldersSelected, setFoldersSelected] = useState([]); //[id]
  const [filesSelected, setFilesSelected] = useState([]); //[id]
  const [foldersLoading, setFoldersLoading] = useState([]); //[id]
  const [filesLoading, setFilesLoading] = useState([]); //[id]
  const [refsNeverDeselectFolder, setRefsNeverDeselectFolder] = useState([]); //[ref]
  const [checkMode, setCheckMode] = useState(false);
  const [modals, setModals] = useState({
    uploadClip: false,
    uploadPortrait: false,
    uploadRecording: false, // Esto va a abrir el plugin que seria como un modal a la vista del usuario, pero un poco diferente
    deleteItems: false,
  });
  const [deleteItemsProps, setDeleteItemsProps] = useState({
    label: '',
    loading: false,
    comfirmDelete: false,
    params: {}
  });
  const [rootFolders, setRootFolders] = useState([]);

  // hooks
  const navigate = useNavigate();

  // rq
  const {
    mutate: mutateDeleteFolder,
    isLoading: isLoadingDeleteFolder,
    error: errorDeleteFolder,
  } = querys().deleteFolderQuery(library);

  const {
    mutate: mutateDeleteFiles,
    isLoading: isLoadingDeleteFiles,
    error: errorDeleteFiles,
  } = querys().deleteFilesQuery(library);

  // effects
  useEffect(() => {
    setDeleteItemsProps((prev) => ({...prev, loading: isLoadingDeleteFolder || isLoadingDeleteFiles}))
  }, [isLoadingDeleteFolder, isLoadingDeleteFiles]);

  useEffect(() => {
    if (user?.role === 'Agente' && library === 'corporative' && toggleArchive) setToggleArchive(false);
  }, [user, library, toggleArchive]);
  
  // functions
  const onActions = useCallback(async (action, params = {}) => {
    switch (action) {
      case 'create_folder':
        //la carpeta se agrega visualmente, luego se crea en backend
        setCurrentFolder((prev) => {
          const newFolder = {
            id: 'temp', //no tenemos el id inicialmente, lo actualizaremos cuando se cree la carpeta
            name: 'Nueva carpeta',
            type: 'folder',
            parentId: toggleArchive ? idFolderArchived : idFolder,
            children: [],
            status: 'creating', // dispara la opcion de renombrar dentro de FolderItem, para luego crearla en backend
          };
          const newChildren = prev && prev.children ? [...prev.children, newFolder] : [newFolder];
          const obj = { ...prev, children: newChildren };
          return obj;
        });
        break;
      case 'create_video':
        navigate('/videos');
        break;
      case 'upload_clip':
        setModals((prev) => ({ ...prev, uploadClip: true }));
        break;
      case 'create_recording':
        setOpenSelfRecord(true)
        break;
      case 'create_portrait':
        break;
      case 'upload_portrait':
        setModals((prev) => ({ ...prev, uploadPortrait: true }));
        break;
      case 'delete':
        setModals((prev) => ({ ...prev, deleteItems: true }));
        if (params?.foldersSelected.length) {
          mutateDeleteFolder({
            token: user.access_token,
            ids: params.foldersSelected,
          });
          setFoldersLoading((prev) => [...prev, ...params.foldersSelected]);
          setFoldersSelected([]);
        }
        setCheckMode(false);
        if (params?.filesSelected.length) {
          mutateDeleteFiles({
            token: user.access_token,
            ids: params.filesSelected,
            type: params.type,
          });
          setFilesLoading((prev) => [...prev, ...params.filesSelected]);
          setFilesSelected([]);
        }
        break;
      case 'list':
        setItemsDisplay('list');
        break;
      case 'miniature':
        setItemsDisplay('miniature');
        break;
      default:
        break;
    }
  }, []);

  // functions
  const handleRemoveTempFolder = useCallback(() => {
    setCurrentFolder((prev) => {
      const newChildren = prev.children.filter((child) => child.id !== 'temp');
      const obj = { ...prev, children: newChildren };
      return obj;
    });
  }, []);

  const addingIds = (ids, fn) => {
    if (!Array.isArray(ids)) ids = [ids];
    const newIds = ids.filter((id) => !foldersLoading.includes(id));
    fn((prev) => [...prev, ...newIds]);
  };
  const removingIds = (ids, fn) => {
    if (!Array.isArray(ids)) ids = [ids];
    fn((prev) => prev.filter((id) => !ids.includes(id)));
  };

  const addFoldersLoading = (ids) => addingIds(ids, setFoldersLoading);
  const removeFoldersLoading = (ids) => removingIds(ids, setFoldersLoading);

  const addFoldersSelected = (ids) => addingIds(ids, setFoldersSelected);
  const removeFoldersSelected = (ids) => removingIds(ids, setFoldersSelected);

  const addFilesLoading = (ids) => addingIds(ids, setFilesLoading);
  const removeFilesLoading = (ids) => removingIds(ids, setFilesLoading);

  const addFilesSelected = (ids) => addingIds(ids, setFilesSelected);
  const removeFilesSelected = (ids) => removingIds(ids, setFilesSelected);

  const value = useMemo(
    () => ({
      idFolder,
      setIdFolder,
      idFolderArchived,
      setIdFolderArchived,
      currentFolder,
      setCurrentFolder,
      toggleArchive,
      setToggleArchive,
      onActions,
      library,
      itemsDisplay,
      setItemsDisplay,
      foldersSelected,
      setFoldersSelected,
      filesSelected,
      setFilesSelected,
      refsNeverDeselectFolder,
      setRefsNeverDeselectFolder,
      foldersLoading,
      setFoldersLoading,
      filesLoading,
      setFilesLoading,
      addFoldersLoading,
      removeFoldersLoading,
      addFoldersSelected,
      removeFoldersSelected,
      addFilesLoading,
      removeFilesLoading,
      addFilesSelected,
      removeFilesSelected,
      checkMode,
      setCheckMode,
      modals,
      setModals,
      deleteItemsProps,
      setDeleteItemsProps,
      rootFolders,
      setRootFolders,
      handleRemoveTempFolder,
    }),
    [
      idFolder,
      idFolderArchived,
      currentFolder,
      toggleArchive,
      library,
      itemsDisplay,
      foldersSelected,
      filesSelected,
      refsNeverDeselectFolder,
      foldersLoading,
      filesLoading,
      checkMode,
      modals,
      deleteItemsProps,
      rootFolders,
      setRootFolders,
      handleRemoveTempFolder,
    ],
  );

  return <FolderContext.Provider value={value}>{children}</FolderContext.Provider>;
}
