import { useEffect, useState, useContext } from 'react';
import { Breadcumbs } from './Breadcumbs';
import { FolderList } from './FolderList';
import { Tabs } from './Tabs';
import { Title } from '../../components/common';
import { CreateVideoModal } from './Video/CreateVideoModal';
import { CreatePortraitModal } from './Video/CreatePortraitModal';
import { FolderContext } from '../../contexts/folderContext';
import useAuthContext from '../../hooks/useAuthContext';
import { useTranslation } from 'react-i18next';
import GeneralConfirModal from '../../components/common/modal/GeneralConfirModal';
import { useVideoStepsContext } from '../../components/video/videoSteps/context/VIdeoStepsContext';

export function Container({
  children,
  title,
  breadcumbs,
  folders = [],
  isLoading = false,
  library,
}) {

  /* State */
  const [files, setFiles] = useState([]);
  const [folderList, setFolderList] = useState(folders);

  /* Hooks */
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const {
    idFolder,
    setModals,
    toggleArchive,
    setToggleArchive,
    modals,
    deleteItemsProps,
    onActions
  } = useContext(FolderContext);

  /* Effects */
  useEffect(() => {
    setFolderList(folders);
  }, [folders]);

  /* Functions */
  const handleSelect = (item) => {
    if (item.title === t('Library.tabs.archived')) {
      return setToggleArchive(true);
    }
    setToggleArchive(false);
  };

  const isAgente = user?.role === 'Agente';

  return (
    <div className="-mt-28 w-full">
      <div className="mb-2 mt-2">
        <Breadcumbs path={breadcumbs.path} onClick={breadcumbs.onClick} />
      </div>
      <Title title={title} />
      <div className="mt-[28px]">
        <Tabs
          tabs={[
            { id: 1, title: library === 'portrait' ? t('Library.tabs.frontPages') : t('Library.tabs.videos'), selected: !toggleArchive },
            { id: 2, title: t('Library.tabs.archived'), selected: toggleArchive, disabled: isAgente && library === 'corporative'},
          ]}
          onSelect={handleSelect}
        />
      </div>
      <div className="mt-[37px] flex items-start justify-between">
        {folders && <FolderList items={folderList} isLoading={isLoading} />}
        {/* <Options items={options()} /> */}
      </div>
      {children}
      <CreateVideoModal
        onReadyDownloads={setFiles}
        open={modals.uploadClip}
      />
      <CreatePortraitModal
        onReadyDownloads={setFiles}
        open={modals.uploadPortrait}
      />

      {/* modal de confirmacion para la eliminacion de items */}
      <GeneralConfirModal
        isOpen={modals.deleteItems}
        label={deleteItemsProps.label}
        onConfirm={(confirm) => {
          confirm && onActions('delete', deleteItemsProps.params)
          setModals((prev) => ({ ...prev, deleteItems: false }))
        }}
      />
    </div>
  );
}
