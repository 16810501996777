import { Fragment, useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { IoCameraOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { saveFrontPage } from '../../features/videos/videosSlice';
import { useMutation } from 'react-query';
import useAuthContext from '../../hooks/useAuthContext';
import { postAddPortrait } from '../../services/library/LibraryService';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../hooks/useToast2';
import { classNames } from '../../config/utils';
import { Spinner } from '../../components/common';
import { DestinationView } from '../../components/library/Video/Destination/DestinationView';

const FramePreview = ({ captureImage, isClosing, onClose }) => {
  const videoRef = useRef();

  // Estilos para cuando se apriete el obtulador
  const [styleCapturing, setStyleCapturing] = useState();

  const { t } = useTranslation();
  const { showToast } = useToast2();

  useEffect(() => {
    const video = videoRef.current;

    // Verifica si el navegador es compatible con la API de MediaDevices
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) 
      return showToast({
      show: true,
      type: 'error',
      message: t('Message.template.error.camera_access'),
    });
    let previewStream = null;
    // Solicita acceso a la webcam
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        previewStream = stream;
        // Muestra el video de la webcam en el elemento de video
        videoRef.current.srcObject = stream;

        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };

        videoRef.current.onpause = () => {
          if (isClosing) stream.getTracks().forEach((track) => track.stop());
        };
      })
      .catch(function () {
        showToast({
          show: true,
          type: 'error',
          message: t('Message.template.error.camera_access'),
        });
      });

    return () => {
      if (video) {
        video.pause();
      }
      if (previewStream) {
        previewStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (isClosing) {
      videoRef.current.pause();
    }
  }, [isClosing]);

  const handleCaptureImage = () => {
    // Imitar obtulador

    setStyleCapturing({ backgroundColor: '#80808065' });

    setTimeout(() => {
      setStyleCapturing({ backgroundColor: 'transparent' });
    }, 100);

    videoRef.current.pause();

    // Crea un lienzo para dibujar la imagen capturada
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const context = canvas.getContext('2d');

    // Dibuja el video actual en el lienzo
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    // Obtiene la imagen capturada en formato base64
    canvas.toBlob((blob) => {
      // Mando el archivo al componente padre que es donde esta el boton guardar
      captureImage(blob);
    }, 'image/jpeg');
  };

  const handleRepeatCapture = () => {
    videoRef.current.play();
  };

  return (
    <div className="flex relative w-full h-[400px] 2xl:h-[505px] justify-center items-center rounded-2xl overflow-hidden mt-2">
      {/* Use this DIV to put the image capture services */}
      <div className="w-full h-full bg-black z-0" style={{ position: 'relative' }}>
        <video
          id="video-webcam"
          className="h-full w-full"
          style={{ margin: '0 auto', objectFit: 'cover' }}
          ref={videoRef}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            ...styleCapturing,
          }}
        ></div>
      </div>
      <div />
      <div className="absolute bottom-16 w-full px-16 z-20 flex justify-center">
        <div className="w-[71px] h-[71px] rounded-full bg-white flex items-center justify-center cursor-pointer hover:shadow-md hover:shadow-primary duration-200">
          <IoCameraOutline
            id="captureButton"
            size={50}
            className="text-gray-400 hover:text-primary"
            onClick={handleCaptureImage}
          />
        </div>
        <span
          className="text-lg absolute -bottom-11 z-20 flex justify-center text-white select-none cursor-pointer hover:text-gray-300"
          onClick={handleRepeatCapture}
        >
          {t('Video.repeat_photo')}
        </span>
      </div>
    </div>
  );
};

const ShootPicture = ({ open, onClose }) => {
  const { user } = useAuthContext();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [showDestination, setShowDestination] = useState(false);
  const [, setFolderId] = useState(0);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { showToast } = useToast2();

  const [image, setImage] = useState(null);

  /**
   * Esto es para que solo detenga el stream en caso de que se
   * este dando cancelar no cuando se apriete el obtulador
   */
  const [isClosing, setIsClosing] = useState(false);

  const { mutate, isLoading } = useMutation('CREATE_FRONT_PAGE', postAddPortrait, {
    onError: (error) => {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.template.error.load_all'),
      });
      setIsClosing(true);
      setTimeout(() => {
        setImage(null);
        onClose();
      }, 500);
    },

    onSuccess: (response) => {
      if (response) {
        setTimeout(
          () =>
            showToast({
              show: true,
              type: 'success',
              message: t('Message.template.success.add'),
            }),
          1000,
        );

        const data = JSON.parse(response);

        const frontPage = {
          id: data.id, // Poner el que debe llegar en la base de datos
          file: data.src,
        };

        dispatch(saveFrontPage(frontPage));
      }
    },
  });

  const handleCaptureImage = (imageBlob) => {
    if (imageBlob) {
      setImage(imageBlob);
    }
  };

  const handleSaveImage = () => {
    // Aqui debe guardar la imagen en la lista de elementos que se presentan en el editor
    const imageBlob = image;

    if (imageBlob) {
      const date = new Date();
      const dateParse = date
        .toLocaleString()
        .replace(/[\/\:]/g, '-')
        .replace(', ', '_');
      const imageFile = new File([imageBlob], `FrontPagePhoto-${dateParse}.jpg`, {
        type: imageBlob.type,
      });

      setSelectedPhoto(imageFile);
      return setShowDestination(true);
    }
    showToast({
      show: true,
      type: 'error',
      message: t('Message.video.error.not_capture_photo'),
    });
  };

  const handleSelectDestination = (newIdFolder) => {
    setFolderId(newIdFolder);
    // random title by date
    const title = `Front_page_photo_${new Date().toLocaleDateString()}`;
    // Mandamos a guardar la portada
    mutate({
      token: user.access_token,
      title: selectedPhoto.name || title,
      folderId: newIdFolder,
      image: selectedPhoto,
    });

    setShowDestination(false);
    setTimeout(() => {
      setIsClosing(true);
      setImage(null);
      onClose();
    }, 1000);
  };

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setImage(null);
      onClose();
      setShowDestination(false);
    }, 500);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed backdrop-filter backdrop-blur-sm inset-0 bg-gray-900/80"
              onClick={handleOnClose}
            />
          </Transition.Child>

          {/* Modal panel, show/hide based on modal state. */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          >
            <div className="relative transform overflow-hidden rounded-[15px] bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all my-4 2xl:my-8 h-10/12 max-w-7xl w-2/4 p-6">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold"></h3>
                <button
                  type="button"
                  className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none hover:shadow-md"
                  onClick={handleOnClose}
                >
                  <span className="sr-only">Close</span>
                  <AiOutlineClose className="h-6 w-6" />
                </button>
              </div>
              <div className="relative px-10 space-y-10">
                {!showDestination ? (
                  <>
                    <FramePreview captureImage={handleCaptureImage} isClosing={isClosing} />
                    <div className="flex w-full justify-center">
                      <button
                        type="button"
                        className="flex items-center justify-center h-12 px-4 mr-4 rounded-xl  text-gray-300 border-2 border-gray-300 font-semibold text-lg hover:shadow-lg active:scale-[0.98]"
                        onClick={handleOnClose}
                      >
                        {t('Video.cancel')}
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          'flex items-center justify-center h-12 px-4 rounded-xl text-white font-semibold text-lg hover:shadow-lg',
                          !image
                            ? 'bg-gray-darker'
                            : 'bg-primary cursor-pointer active:scale-[0.98]',
                        )}
                        onClick={handleSaveImage}
                        disabled={!image}
                      >
                        {t('Video.save')}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="mt-4 2xl:mt-8">
                    <DestinationView onClose={handleOnClose} onSelected={handleSelectDestination} />
                  </div>
                )}
              </div>
              <Spinner open={isLoading} title={t('Message.frontPage.adding_frontPage')} />
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
};

export default ShootPicture;
