import { combineReducers, configureStore } from '@reduxjs/toolkit';
import clientReducer from './client/clientSlice';
import libraryReducer from './library/librarySlice';
import commonReducer from './common/commonSlice';
import videosReducer from './videos/videosSlice';

const rootReducer = combineReducers({
  client: clientReducer,
  library: libraryReducer,
  common: commonReducer,
  videos: videosReducer,
});
export default configureStore({
  reducer: rootReducer,
});
