import React from 'react';
import { Route } from 'react-router-dom';
import Routes from './paths';
import {
  HOME,
  DASHBOARD,
  LOGIN,
  REGISTER,
  REGISTER_SUCCESS,
  VALIDATE_EMAIL,
  LOGOUT,
  PASSWORD_RECOVERY,
  PASSWORD_CHANGE,
  PROFILE,
  TUTORIAL,
  VIDEOS,
  VIDEO_NEW,
  PRESENTATIONS,
  PRESENTATION_NEW,
  CLIENTS,
  CLIENT_NEW,
  CLIENT_DETAIL_ID,
  STATISTICS,
  STATISTIC_DETAIL_ID,
  CLIPS,
  CLIP_NEW,
  USERS,
  USERS_ADMIN,
  USER_NEW,
  USER_DETAIL_ID,
  CATEGORIES,
  CATEGORY_DETAIL_ID,
  SUBCATEGORY_DETAIL_ID,
  TEMPLATES,
  TEMPLATE_NEW,
  COMPANIES,
  COMPANY_NEW,
  PRODUCTS,
  PRODUCT_NEW,
  LANDING_TOKEN,
  LANDING_PREVIEW,
  POLICIES,
  CONDITIONS,
  COOKIES,
  LEGAL,
  MULTIPLE_CLIENT_NEW,
  TICKETS,
  VIDEO,
  LANDING,
} from './paths';
import {
  CheckImportedList,
  ContactProfile,
  Contacts,
  Home,
  ImportContact,
  Insights,
  Library,
  Login,
  Register,
  PassRecovery,
  PassChange,
  NewContact,
  Video,
  VideoInsights,
  ManageList,
  Companies,
  UsersAdmin,
  Landing,
  CompanyProfile,
  UserProfile,
  Account,
  Preferences,
  Page404,
  ErrorPage,
} from '../../pages';

// AUTH

/* import Register from 'components/views/Auth/Register';
import RegisterSuccess from 'components/views/Auth/RegisterSuccess';
import Logout from 'components/views/Auth/Logout';
import PasswordRecovery from 'components/views/Auth/PasswordRecovery';
import PasswordChange from 'components/views/Auth/PasswordChange';
import ValidateEmail from 'components/views/Auth/ValidateEmail';
 */
// DASHBOARD

/* import Dashboard from 'components/views/Dashboard'; */

// PROFILE
/* import Profile from 'components/views/Profile/Profile'; */

// TUTORIAL
/* import Tutorial from 'components/views/Tutorial/Tutorial'; */

// VIDEOS
/* import Videos from 'components/views/Video/Videos';
import VideoNew from 'components/views/Video/VideoNew'; */

// PRESENTATIONS
/* import Presentations from 'components/views/Presentation/Presentations';
import PresentationNew from 'components/views/Presentation/PresentationNew'; */

// STATISTIC

/*import StatisticsDetail from 'components/views/Statistic/StatisticsDetail'; */

// CLIPS

/*import ClipNew from 'components/views/Clip/ClipNew'; */

// USERS
/* import Users from 'components/views/User/Users';
import UsersAdmin from 'components/views/User/UsersAdmin';
import UserNew from 'components/views/User/UserNew';
import UserDetail from 'components/views/User/UserDetail'; */

// CATEGORIES
/* import Categories from 'components/views/Category/Categories';
import CategoryDetail from 'components/views/Category/CategoryDetail'; */

// // SUBCATEGORY
/* import SubCategoryDetail from 'components/views/Category/SubCategoryDetail'; */

// TEMPLATES
/* import Templates from 'components/views/Template/Templates';
import TemplateNew from 'components/views/Template/TemplateNew'; */

// COMPANIES
/* import Companies from 'components/views/Company/Companies';
import CompanyNew from 'components/views/Company/CompanyNew'; */

// PRODUCTS
/* import Products from 'components/views/Product/Products';
import ProductNew from 'components/views/Product/ProductNew'; */

// LANDING
/* import Landing from 'components/views/Landing/Landing';
import LandingPreview from 'components/views/Landing/LandingPreview';
import Policies from "pages/Info/Policies";
import InfoTerms from "components/views/Info/InfoTerms";
import InfoPolicies from "components/views/Info/InfoPolicies";
import InfoCookies from "components/views/Info/InfoCookies";
import ImportClients from "components/views/Client/ImportClients";
import Tickets from "components/views/Tickets/Tickets";
import VideoRecord from "components/VideoRecord";
import Template1 from "components/views/Landing/Templates/template_01";
import Template2 from "components/views/Landing/Templates/template_02";
import Template3 from "components/views/Landing/Templates/template_03";
import Template4 from "components/views/Landing/Templates/template_04"; */

const authProtectedRoutes = [
  { path: Routes.dashboard, element: <Home /> },
  /*{ path: PROFILE, element: Profile },
  { path: TUTORIAL, element: Tutorial },
  { path: VIDEO_NEW, element: VideoNew },
  { path: PRESENTATIONS, element: Presentations },
  { path: PRESENTATION_NEW, element: PresentationNew },
  { path: CLIENTS, element: Clients },
  { path: CLIENT_NEW, element: ClientNew },
  { path: MULTIPLE_CLIENT_NEW, element: ImportClients },
  { path: CLIENT_DETAIL_ID, element: ClientDetail },
  { path: STATISTICS, element: Statistics },
  { path: STATISTIC_DETAIL_ID, element: StatisticsDetail },
  { path: CLIPS, element: Clips },
  { path: CLIP_NEW, element: ClipNew },
  { path: USERS, element: Users },
  { path: USERS_ADMIN, element: UsersAdmin },
  { path: USER_NEW, element: UserNew },
  { path: USER_DETAIL_ID, element: UserDetail },
  { path: CATEGORIES, element: Categories },
  { path: CATEGORY_DETAIL_ID, element: CategoryDetail },
  { path: SUBCATEGORY_DETAIL_ID, element: SubCategoryDetail },
  { path: TEMPLATES, element: Templates },
  { path: TEMPLATE_NEW, element: TemplateNew },
  { path: COMPANIES, element: Companies },
  { path: COMPANY_NEW, element: CompanyNew },
  { path: PRODUCTS, element: Products },
  { path: PRODUCT_NEW, element: ProductNew },
  { path: TICKETS, element: Tickets }, */

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  /* { path: HOME, exact: true, element: () => <Redirect to={DASHBOARD} /> } */
  ,
];

export const publicRoutes = [
  // Auth
  { path: Routes.login, element: <Login /> },
  { path: REGISTER, element: <Register /> },
  { path: PASSWORD_RECOVERY, element: <PassRecovery /> },
  { path: PASSWORD_CHANGE, element: <PassChange /> },
  { path: LANDING_TOKEN, element: <Landing /> },
]

export const protectedRoutes = [
  { path: Routes.home, element: <Home />, redirect: <Home /> },
  { path: Routes.dashboard, element: <Home /> },
  // Contact
  { path: Routes.contacts, element: <Contacts /> },
  { path: `${Routes.contactProfile}/:id`, element: <ContactProfile /> },
  { path: Routes.newContact, element: <NewContact /> },
  { path: Routes.importContacts, element: <ImportContact /> },
  { path: Routes.checkImportedList, element: <CheckImportedList /> },
  { path: Routes.manageList, element: <ManageList /> },
  // Library
  { path: Routes.library, element: <Library category='video' /> },
  { path: Routes.corporativeVideos, element: <Library category='corporative' /> },
  { path: Routes.videoRecords, element: <Library category='record' /> },
  { path: Routes.portraits, element: <Library category='portrait' /> },
  // Insights
  { path: Routes.insights, element: <Insights /> },
  { path: Routes.generalInsights, element: <VideoInsights /> },
  // Video
  { path: Routes.videos, element: <Video /> },
  { path: Routes.reuseVideos, element: <Video reuseVideo /> },
  { path: `${Routes.reuseVideos}/:id`, element: <Video reuseVideo /> },
  { path: Routes.videoNewContacts, element: <NewContact /> },
  { path: Routes.videoImportContacts, element: <ImportContact /> },
  { path: `${Routes.videoContactProfile}/:id`, element: <ContactProfile /> },
  // Admin
  { path: Routes.companies, element: <Companies /> },
  { path: Routes.newCompany, element: <CompanyProfile /> },
  { path: `${Routes.companyProfile}/:id`, element: <CompanyProfile /> },
  { path: Routes.usersAdmin, element: <UsersAdmin /> }, 
  { path: Routes.newUser, element: <UserProfile /> }, 
  { path: `${Routes.newUser}/:companyOwner`, element: <UserProfile /> }, 
  { path: `${Routes.userProfile}/:id`, element: <UserProfile /> } ,
  { path: `${Routes.userProfile}/:companyOwner/:id`, element: <UserProfile /> } ,
  // Profile
  { path: Routes.account, element: <Account /> }, 
  { path: Routes.account_preferences, element: <Preferences /> }, 
  /*{ path: POLICIES, element: InfoPolicies },
  { path: LEGAL, element: InfoTerms },
  { path: COOKIES, element: InfoCookies },
  { path: VIDEO, element: VideoRecord }, */,
];

// const routes = protectedRoutes.map((route, index) => (
//   <Route
//     key={`public-route-${index}`}
//     path={route.path}
//     element={route.element}
//     loader={route.loader}
//     redirect={route.redirect}
//     ErrorBoundary={(props) => <ErrorPage {...props} />}
//   />
// ));

// export default routes;
