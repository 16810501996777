import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../../../hooks/useToast2';
import moment from 'moment';

// context
export const RecordContext = createContext();

// provider
const RecordProvider = ({ children }) => {
  // hooks
  const { t } = useTranslation()
  const { showToast } = useToast2();

  const errorMessages = useMemo(() => ({
    media_aborted: t('Record.message.media_aborted'),
    permission_denied: t('Record.message.permission_denied'),
    no_specified_media_found: t('Record.message.no_specified_media_found'),
    media_in_use: t('Record.message.media_in_use'),
    invalid_media_constraints: t('Record.message.invalid_media'),
    no_constraints: t('Record.message.no_constraints'),
    recorder_error: t('Record.message.recorder_error'),
  }), []);
  
  // configState
  const [config, setConfig] = useState({
    screen: 'screen',
    camera: 'camera',
    audio: 'camera',
  });
  const [isInitializing, setIsInitializing] = useState(false);

  // others states
  const [duration, setDuration] = useState(0);
  const recordInitTime = useRef(null);
  const recordPauseTimes = useRef([]);

  // record hooks
  const screenRecorder = useReactMediaRecorder({ 
    video: true, 
    screen: config.screen === 'screen', 
    audio: config.audio === 'screen',
    blobPropertyBag: {
      type: 'video/webm; codecs="vp9"'
    },
    mediaRecorderOptions:{
      videoBitsPerSecond: 2000000,
      audioBitsPerSecond: 256000,
    }
  });
  const cameraRecorder = useReactMediaRecorder({ 
    video: config.camera === 'camera', 
    audio: config.audio === 'camera',
    blobPropertyBag: {
      type: 'video/webm; codecs="vp9"'
    },
    mediaRecorderOptions:{
      videoBitsPerSecond: 2000000,
      audioBitsPerSecond: 256000,
    }
  });
  
  // constants
  const isScreenWithVideo = useMemo(() => {
    return config.screen === 'screen' && config.camera === 'camera';
  }, [config]);

  const isRecording = useMemo(() => {
    return screenRecorder.status === 'recording' || cameraRecorder?.status === 'recording';
  }, [screenRecorder, cameraRecorder]);

  const isPaused = useMemo(() => {
    return screenRecorder.status === 'paused' || cameraRecorder?.status === 'paused';
  }, [screenRecorder, cameraRecorder]);

  const isActive = useMemo(() => {
    return screenRecorder.status === 'recording' || cameraRecorder?.status === 'recording' || screenRecorder.status === 'paused' || cameraRecorder?.status === 'paused';
  }, [screenRecorder, cameraRecorder]);
  
  // refs
  const durationRef = useRef(0)
  
  // functions
  const handleGetDuration = useCallback(() => {
    const endRecord = moment();
    const duration = moment.duration(endRecord.diff(recordInitTime.current)).asSeconds();
    let pauseDuration = 0;
    recordPauseTimes.current.forEach(pauseTime => {
      pauseDuration += moment.duration(pauseTime.end.diff(pauseTime.start)).asSeconds();
    });
    return duration - pauseDuration;
  }, []);
    
  const handleStartRecording = useCallback(() => {
    setIsInitializing(true);
    screenRecorder.startRecording();
    cameraRecorder.startRecording();
    if (!isScreenWithVideo) cameraRecorder?.clearBlobUrl();
  }, [screenRecorder]);

  const handleStopRecording = useCallback(() => {
    screenRecorder.stopRecording();
    cameraRecorder?.stopRecording();

    //set duration
    const finalDuration = handleGetDuration();
    setDuration(finalDuration);
    // clear
    recordPauseTimes.current = [];
    recordInitTime.current = null;
  }, [screenRecorder, cameraRecorder]);

  const handlePauseResumeRecording = useCallback(() => {
    if (screenRecorder.status === 'recording') {
      screenRecorder.pauseRecording()
      recordPauseTimes.current.push({start: moment()})
    }
    else {
      screenRecorder.resumeRecording()
      recordPauseTimes.current[recordPauseTimes.current.length - 1].end = moment()
    };

    if (cameraRecorder?.status === 'recording') cameraRecorder.pauseRecording();
    else cameraRecorder.resumeRecording();
  }, [screenRecorder, cameraRecorder]);

  const discardRecording = useCallback(() => {
    screenRecorder.stopRecording();
    cameraRecorder?.stopRecording();
    setDuration(0)
  }, [screenRecorder, cameraRecorder]);
  
  // effects
  useEffect(() => {
    if (isScreenWithVideo && screenRecorder.status === 'recording' && isInitializing) {
      cameraRecorder.startRecording();
      recordInitTime.current = moment();
      setIsInitializing(false);
    }
    if (!isScreenWithVideo && isInitializing) {
      recordInitTime.current = moment();
      setIsInitializing(false)
    };
  }, [isScreenWithVideo, cameraRecorder, screenRecorder]);
  
  //use a interval to update duration
  useEffect(() => {
    const interval = setInterval(() => {
      if (isRecording) {
        durationRef.current += (1 / 100);
      }
    }, 10);
    return () => clearInterval(interval);
  }, [isRecording]) 
  
  // handle errors
  useEffect(() => {
    if ((screenRecorder.error && screenRecorder.error !== 'no_constraints') || (cameraRecorder?.error && cameraRecorder.error !== 'no_constraints')) {
      showToast({
        show: true,
        type: 'warning',
        message: errorMessages[screenRecorder.error || cameraRecorder?.error],
      })
      console.warn((screenRecorder.error && 'screen:' + screenRecorder.error ) || (cameraRecorder?.error && 'camera:' + cameraRecorder?.error))
      setIsInitializing(false);

      if (screenRecorder.error === 'media_aborted' || screenRecorder.error === 'permission_denied') {
        discardRecording();
      }
    }
  }, [screenRecorder.error, cameraRecorder?.error])
  
  // value
  const value = useMemo(() => ({
    screenRecorder,
    cameraRecorder,
    isRecording,
    isPaused,
    isActive,
    isInitializing,
    config,
    setConfig,
    handleStartRecording,
    handleStopRecording,
    handlePauseResumeRecording,
    discardRecording,
    duration,
    setDuration,
    handleGetDuration,
  }), [
    screenRecorder,
    cameraRecorder,
    isRecording,
    isPaused,
    isActive,
    isInitializing,
    config,
    setConfig,
    handleStartRecording,
    handleStopRecording,
    handlePauseResumeRecording,
    discardRecording,
    duration,
    setDuration,
    handleGetDuration,
  ]);

  return (
    <RecordContext.Provider value={value}>
      {children}
    </RecordContext.Provider>
  );
};

RecordProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RecordProvider;
