import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { NewUserSchema } from '../../config/formsValidators';
import useAuthContext from '../../hooks/useAuthContext';
import { getCompanies } from '../../services/admin/adminService';
import { CustomSingleValue } from './SelectComponents';
import { useToast2 } from '../../hooks/useToast2';
import { Spin } from './loaders/Spin';
import { postUpdateUser } from '../../services/profile/profileService';

const roles = [
  { value: 0, label: 'Admin' },
  { value: 1, label: 'Agent' },
  { value: 2, label: 'Manager' },
];

const styles = {
  control: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: 'white',
    padding: '.3rem',
    border: '2px  solid rgba(0, 0, 0, .3)',
    borderRadius: '8px',
    zIndex: 1,
  }),
  menu: () => ({
    marginTop: -5,
    padding: 0,
    borderRadius: '0 0 8px 8px',
    position: 'absolute',
    width: '100%'
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    display: state.isSelected && 'none',
    backgroundColor: 'rgb(245,245,245)',
    cursor: 'pointer',
    fontSize: '17px', // Tamaño base del texto
    '@media (min-width: 1536px)': {
      fontSize: '16px', // Tamaño para 2xl
    },
    '&:hover': {
      backgroundColor: '#ccc',
    },
  }),
};

export const ProfileForm = ({ onSubmit, removeItems, scope = 'account', currentProfile, avatar }) => {
  // states
  const [companies, setCompanies] = useState([]);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      surname: '',
      phone: '',
      second_surname: '',
      department: ''
    },
    mode: 'all',
    resolver: yupResolver(NewUserSchema),
  });

  // hooks
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { companyOwner } = useParams();
  const { showToast } = useToast2();

  // RQ
  const { mutate: mutateUpdateUser, isLoading: loadingUpdateUser } = useMutation((opt) => postUpdateUser(opt), {
    onSuccess: () => {
      onSubmit();
      showToast({ show: true, type: 'success', message: t('Message.user.success.update') })
    },
    onError: (err) => {
      console.log(err);
      showToast({ show: true, type: 'error', message: t('Message.user.error.update') })
    }
  });

  // effects
  useEffect(() => {
    setValue('name', currentProfile?.name || '');
    setValue('email', currentProfile?.email || '');
    setValue('surname', currentProfile?.last_name || '');
    setValue('phone', currentProfile?.phone || '');
    setValue('second_surname', currentProfile?.second_last_name || '');
    setValue('department', currentProfile?.department || '');
    setValue('company', currentProfile?.company.name || '');
    setValue('company_id', currentProfile?.company.id);
    setValue('role', currentProfile?.role || '');
    setValue('active', true);
    trigger();
  }, [currentProfile]);

  // querys
  const { data: dataCompanies, isLoading: loadingCompanies, error: errorCompanies } = useQuery(['COMPANIES'],
    () => getCompanies({ token: user.access_token }), {
    enabled: user?.role === 'Admin',
    onSuccess: (resp) => {
      setCompanies(resp.data.map(c => ({ value: c.id, label: c.name })))
      companyOwner && setValue('company', companyOwner)
      companyOwner && setValue('company_id', resp.data.filter(c => c.name === companyOwner)[0]?.id)
      trigger()
    },
    onError: (err) => {
      console.log(err);
    }
  });

  // handlers
  const updateField = (field, value) => {
    setValue(field, value)
    trigger(field);
  };
  
  const onSubmitUserData = async () => {
    const data = watch()
    mutateUpdateUser({ 
      token: user.access_token, 
      data: {...data, avatar}, 
      userId: user.id,
    });
  };

  return (
    <div>
      <form className='grid grid-cols-2 gap-y-8 gap-x-24' onSubmit={handleSubmit(onSubmitUserData)}>
        <div>
          <input
            {...register('name')}
            value={watch().name}
            onChange={(e) => updateField('name', e.target.value)}
            className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
            type="text"
            placeholder={t('Placeholder.name')}
          />
          {errors.name && (
            <p className="text-red-500" role="alert">
              {t(errors.name.message)}
            </p>
          )}
        </div>
        <div>
          <input
            {...register('email')}
            disabled={scope === 'account'}
            value={watch().email}
            onChange={(e) => updateField('email', e.target.value)}
            className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:opacity-50'
            type="text"
            placeholder={t('Placeholder.email')}
          />
          {errors.email && (
            <p className="text-red-500" role="alert">
              {t(errors.email.message)}
            </p>
          )}
        </div>
        <div>
          <input
            {...register('surname')}
            value={watch().surname}
            onChange={(e) => updateField('surname', e.target.value)}
            className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
            type="text"
            placeholder={t('Placeholder.surname')}
          />
          {errors.surname && (
            <p className="text-red-500" role="alert">
              {t(errors.surname.message)}
            </p>
          )}
        </div>
        <div>
          <input
            {...register('phone')}
            value={watch().phone}
            onChange={(e) => updateField('phone', e.target.value)}
            className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
            type="text"
            placeholder={t('Placeholder.phone')}
          />
          {errors.phone && (
            <p className="text-red-500" role="alert">
              {t(errors.phone.message)}
            </p>
          )}
        </div>
        <div>
          <input
            {...register('second_surname')}
            value={watch().second_surname}
            onChange={(e) => updateField('second_surname', e.target.value)}
            className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
            type="text"
            placeholder={t('Placeholder.second_surname')}
          />
          {errors.second_surname && (
            <p className="text-red-500" role="alert">
              {t(errors.second_surname.message)}
            </p>
          )}
        </div>
        <div>
          <input
            {...register('department')}
            value={watch().department}
            onChange={(e) => updateField('department', e.target.value)}
            className='focus:ring-1 focus:ring-gray-300 h-12 rounded-lg  w-full py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:opacity-50'
            type="text"
            placeholder={t('Placeholder.department')}
            disabled={scope === 'account'}
          />
          {errors.department && (
            <p className="text-red-500" role="alert">
              {t(errors.department.message)}
            </p>
          )}
        </div>
        {
          scope !== 'account' &&
          <Select
            components={{ SingleValue: CustomSingleValue }}
            options={companies}
            value={watch().company ? { value: watch().company_id, label: watch().company } : null}
            placeholder={t('Placeholder.company')}
            styles={styles}
            onChange={(newValue) => {
              setValue('company_id', newValue.value)
              setValue('company', newValue.label)
              trigger('company')
            }}
          />
        }
        <Select
          components={{ SingleValue: CustomSingleValue }}
          options={roles}
          value={watch().role ? { value: roles.findIndex(r => r.label === watch().role), label: watch().role } : null}
          placeholder={t('Placeholder.role')}
          styles={{
            ...styles,
            container: (baseStyles) => ({
              ...baseStyles,
              display: scope === 'account' && 'none',
            }),
          }}
          isDisabled={scope === 'account'}
          onChange={(newValue) => {
            setValue('role', newValue.label)
            trigger('role')
          }}
        />
        {scope !== 'account' && <div></div>}
        <div className='flex'>
          {
            scope !== 'account' &&
            <button
              type="button"
              className="flex items-center justify-center h-10 2xl:h-10 px-5 mt-3 rounded-xl bg-black text-white font-semibold hover:shadow-lg active:scale-[0.98]"
              onClick={removeItems}
            >
              {t('AdminZone.user.delete')}
            </button>
          }
          <button
            type="submit"
            className="flex items-center justify-center gap-3 transition-all duration-150 min-w-[150px] whitespace-nowrap h-10 2xl:h-10 px-5 mt-3 ms-2 rounded-xl bg-primary text-white font-semibold hover:shadow-lg active:scale-[0.98] disabled:bg-gray-400 disabled:scale-100"
            disabled={!isValid || loadingUpdateUser}
          >
            { loadingUpdateUser 
              ? <Spin /> 
              : t('AdminZone.user.save')}
          </button>
        </div>
      </form>
    </div>
  )
}
