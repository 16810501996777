import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

// routes
import { LOGIN } from './paths';
// hooks
import useAuthContext from '../../hooks/useAuthContext';
import Container from '../../components/Container';
import { cloneElement } from 'react';

function Authmiddleware({ children, isAuthProtected, ...rest }) {
  const { user, isAuthenticated } = useAuthContext();

  if (isAuthProtected && !isAuthenticated) {
    return <Navigate to={LOGIN} />;
  }

  if (isAuthProtected && isAuthenticated) {
    return (
      <Container>
        {cloneElement(children, rest)}
      </Container>
    );
  }

  return cloneElement(children, rest);
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
};

export default Authmiddleware;
