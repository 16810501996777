function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function Subtitle({
  title,
  dense,
  weight = 'bold',
  color = 'text-gray-subtitle',
  className = '',
}) {
  return (
    <p className={classNames(className, dense ? 'text-sm' : 'text-md', `font-${weight} ${color}`)}>
      {title}
    </p>
  );
}
