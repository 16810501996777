import { sendRequest } from "../HandlerServices";
import { headers } from "../../config/headers";
import { systemInfo } from "../../routes/api/paths";

export const SystemInfoService = async (params) => {
  const { token } = params;
  const url = `${systemInfo}`;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(url, requestOptions);
}