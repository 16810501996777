// Este componente ys está en biblioteca, pero iba a complejizar el componente si se llegaba a hacer adaptable
import { useContext, useRef } from 'react';
import { FolderIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../../config/utils';
import './styles/FolderList.css';
import { FolderContext } from '../../../../contexts/folderContext';
import { Skeleton } from '../../../../components/common';

const FolderItem = ({ folder }) => {
  const { setIdFolder } = useContext(FolderContext);

  const handleOpenFolder = () => {
    setIdFolder(folder.id);
  };

  return (
    <div
      className="flex p-4 items-center w-[179px] bg-gray-100 rounded-xl shadow-md mr-2 cursor-pointer duration-200 hover:shadow-lg select-none"
      onDoubleClick={handleOpenFolder}
    >
      <FolderIcon className={classNames('w-5 2xl:w-6 h-5 2xl:h-6 mr-3')} />
      <span className="text-sm 2xl:text-md truncate">{folder.name}</span>
    </div>
  );
};

export default function FolderList({ folders = [], isLoading = true }) {
  const listRef = useRef(null);

  const handleScrollList = () => {
    listRef.current.scrollLeft += 195;
  };

  return (
    <div className="folder-list">
      {isLoading ? (
        <div className="flex flex-start">
          {[1, 2, 3].map((key) => (
            <div key={key + '_skeleton'} className="p-4 w-[179px] mr-2">
              <Skeleton width={179} height={56} />
            </div>
          ))}
        </div>
      ) : (
        <div className="folder-list-content">
          <div className="w-full">
            <div
              className="list-none scroll-smooth overflow-x-auto flex flex-row items-center justify-start h-full m-0 p-0"
              ref={listRef}
              style={{ 
                width: 'calc(100% - 10px)',
              }}
            >
              {folders.map((folder, index) => (
                <FolderItem key={`folder${index}`} folder={folder} />
              ))}
              <div className="bg-transparent pr-16"></div>
            </div>
          </div>
          {folders.length > 0 && <div className="folder-list-scroll">
            <button className="folder-list-scroll-button z-10" onClick={handleScrollList}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="32"
                viewBox="0 -960 960 960"
                width="32"
              >
                <path d="M530-481 332-679l43-43 241 241-241 241-43-43 198-198Z" />
              </svg>
            </button>
            <div className="absolute -translate-x-20 bg-gradient-to-r from-transparent via-white to-white inset-0 h-full w-20"></div>
          </div>}
        </div>
      )}
    </div>
  );
}
