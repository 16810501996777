import { Fragment, memo, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import RecordPreview from './Preview/RecordPreview';
import PanelRecordOptions from './Panel/PanelRecordOptions';
import RecordControl from './Controls/RecordControl';
import RecordProvider from './context/RecordContext';
import { IconButton } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { useVideoStepsContext } from '../videoSteps/context/VIdeoStepsContext';
import useUpload from '../../../hooks/useUpload';

const SelfRecord = () => {
  const [showPreview, setShowPreview] = useState(false);  
  const { openSelfRecord, setOpenSelfRecord } = useVideoStepsContext();

  const { upload } = useUpload();

  const onClose = () => {
    setOpenSelfRecord(false);
  };
  
  useEffect(() => {
    if (openSelfRecord) {
      setShowPreview(false);
    }
  }, [openSelfRecord]);
  
  return (
    <Transition.Root show={openSelfRecord} as={Fragment} unmount>
      <Dialog as="div" className="relative z-50" onClose={onClose} >
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay onClick={onClose} className="fixed backdrop-filter backdrop-blur-sm inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className="w-full h-full"
              style={{ height: '100vh' }}
            >
              <div className="relative w-screen h-screen">
                <IconButton icon={<AiOutlineClose />} variant={'outline'} colorScheme='red' _hover={{ bg: 'red.500', color: 'white'}} onClick={onClose} 
                position='absolute'
                top='10px'
                left='10px'
              />
                
                <RecordProvider>
                  {!showPreview ? (
                    <>
                      <PanelRecordOptions />
                      <RecordControl showPreviewModal={() => setShowPreview(true)} onClose={onClose} />
                    </>
                  ) : (
                    <RecordPreview 
                      onClose={onClose} 
                      onRetry={() => setShowPreview(false)} 
                      handleUpload={upload}
                    />
                  )}
                </RecordProvider>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default memo(SelfRecord);