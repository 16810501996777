import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiHide, BiShow } from 'react-icons/bi';
import { classNames } from '../../config/utils';

export const Input = ({
  type = 'password',
  placeholder = '',
  disabled,
  className = '',
  value,
  onChange,
  showError, // {message: 'Error Message'}
  showSuccess, // {message: 'Success Message'}
}) => {
  const [showPass, setShowPass] = useState(false);
  const { t } = useTranslation();

  const handleOnChange = (e) => {
    e.preventDefault();
    onChange(e);
  };

  return (
    <div
      className={classNames(
        className,
        showError && 'border-red-500',
        showSuccess && 'border-green-500',
        'relative flex items-center w-full p-1 px-2 rounded-lg border-[2px] space-x-2 border-gray-300',
        disabled ? ' text-gray-300' : 'text-gray-700',
      )}
    >
      <input
        type={showPass ? 'text' : type}
        className={
          'w-full flex-grow border-0 rounded-sm p-3 py-1 placeholder:text-gray-400 ring-0 focus:ring-0 focus:outline-none'
        }
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
      />

      {type === 'password' &&
        (showPass ? (
          <BiHide
            size={24}
            className={classNames(
              'cursor-pointer hover:text-primary',
              disabled ? 'text-gray-300' : 'text-gray-400',
            )}
            onClick={() => setShowPass(!showPass)}
          />
        ) : (
          <BiShow
            size={24}
            className={classNames(
              'cursor-pointer hover:text-primary',
              disabled ? 'text-gray-300' : 'text-gray-400',
            )}
            onClick={() => setShowPass(!showPass)}
          />
        ))}

      {/* show alert message */}
      {showError && (
        <>
          {showError.message && (
            <div className="absolute -top-[25px] -left-[7px] flex items-center justify-center rounded-sm p-2 py-1 bg-gray-200 text-gray-500">
              <span className="text-[10px] whitespace-nowrap font-bold">{t(showError.message)}</span>
            </div>
          )}
          <AiOutlineInfoCircle size={26} className={classNames('selected-none text-primary')} />
        </>
      )}
    </div>
  );
};
