import { HOME } from '../../routes/app/paths';
import Img404 from '../../assets/img/error.png';

const ErrorPage = () => {
  return (
    <div className="w-screen h-screen flex  justify-center items-center gap-3 p-10">
      <div className="flex flex-col w-1/3">
        {/* text */}
        <h1 className="text-3xl font-bold text-secondary-900 mb-5">Error:</h1>
        <span className="text-xl font-semibold text-secondary-800">
          En estos momentos estamos trabajando para brindarle una mejor experiencia.
          <br />
          Le notificaremos cuando estemos listos.
        </span>

        {/* button */}
        <button
          className="flex gap-4 justify-center mt-10 items-center py-2 px-5 rounded-full text-white bg-primary text-xl font-semibold"
          onClick={() => {
            // navigate to home y refrescar la pagina
            window.location.href = HOME;
          }}
        >
          Regresar al inicio
        </button>
      </div>

      {/* image */}
      <div className="flex flex-col justify-center items-center -mb-8 flex-1">
        <img src={Img404} alt="404" className="w-3/4 mt-11 md:mt-5 z-10" />
      </div>
    </div>
  );
};
export default ErrorPage;
