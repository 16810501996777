import { SelectClipsModal } from '../edition/others/SelectClipsModal';
import { useEffect, useMemo, useState } from 'react';
import SelectFrame from '../SelectFrame';
import UploadImage from '../UploadImage';
import ShootPicture from '../ShootPicture';
import VideoEditor from '../edition/VideoEditor';

import { useDispatch, useSelector } from 'react-redux';
import { save, selectTexts, selectVideos } from '../../../features/videos/videosSlice';
import { OpenExtensionCommand } from '../commands/command';
import FolderContextProvider from '../../../contexts/folderContext';
import { typesRequestLibrary } from '../../../pages/Library/tools/types';
import { useLibrary } from '../../../pages/Library/hook/useLibrary';
import SelectFolderVideo from '../edition/others/SelectFolderVideo';
import useAuthContext from '../../../hooks/useAuthContext';
import { InfoBox } from '../../common';
import { useTranslation } from 'react-i18next';
import ApiRoutes from '../../../routes/api/paths';
import SelfRecord from '../record/SelfRecord';
import { useVideoStepsContext } from './context/VIdeoStepsContext';
import { classNames } from '../../../config/utils';
import { useVideo } from '../context/VideoContext';
import { useLocation } from 'react-router-dom';
import Routes from '../../../routes/app/paths';

export function CreateVideo() {
  const { user } = useAuthContext();
  const { openSelfRecord } = useVideoStepsContext();
  const { openReuseVideoDialog, setOpenReuseVideoDialog, nextStep } = useVideo();
  const location = useLocation();

  const isReuse = useMemo(() => location.pathname.includes(Routes.reuseVideos), [location.pathname])

  const libraryClips = useLibrary(typesRequestLibrary.CORPORATIVE);

  const libraryRecordings = useLibrary(typesRequestLibrary.RECORD);

  // Esto es para poder cambiar entre los dos tipos de libreria
  const [rootFolder, setRootFolder] = useState(libraryClips);

  const { t } = useTranslation();

  useEffect(() => {
    if (libraryClips) {
      setRootFolder(libraryClips);
    }
  }, [libraryClips]);

  const libraryFrontPages = useLibrary(typesRequestLibrary.FRONTPAGE);

  const libraryVideos = useLibrary(typesRequestLibrary.VIDEO);

  const [libraryType, setLibraryType] = useState(typesRequestLibrary.CORPORATIVE);

  const dispatch = useDispatch();
  // Videos que se han utilizado
  const presentVideos = useSelector(selectVideos);

  // Textos que se han superpuesto
  const overlayTexts = useSelector(selectTexts);

  /* State */
  const [showModal, setShowModal] = useState(false);
  // Para escoger las carpeta y el nombre del video creado
  const [showSelectFolder, setShowSelectFolder] = useState(false);
  const [showSelectFrame, setShowSelectFrame] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [showShootPicture, setShowShootPicture] = useState(false);

  useEffect(() => {
    if (isReuse && !openReuseVideoDialog) {
      setOpenReuseVideoDialog(true);
    }
  }, [isReuse])

  useEffect(() => {
    if (!presentVideos && !isReuse) setShowModal(true);
  }, [presentVideos]);

  useEffect(() => {
    if (openSelfRecord) {
      setShowSelectFrame(false)
    }
  }, [openSelfRecord])

  // Para cuando se de en el botón Seleccionar en el dialogo de escoger los videos estos se guarden en el estado global de los videos
  const handleSaveVideosToEdit = (videos) => {
    const newVideosCopy = JSON.parse(JSON.stringify(videos || []));
    const presentVideosCopy = JSON.parse(JSON.stringify(presentVideos || []));
    const videosToEdit = [...presentVideosCopy, ...newVideosCopy];

    // Aqui debe ir if (videos && videos.length)
    if (videosToEdit && videosToEdit.length) {
      const listItems = [];
      /**
       * Esto es para cuando se agregue un video de la lista de videos a editar desde el dialogo donde se escogen
       * agregarle los atributos adicionales necesarios para comenzar la edición
       */

      videosToEdit.forEach((element) => {
        let src = '';
        // Por si viene del back o ya se escogió
        if (element.file) src = element.file;
        else src = element.src;

        let type = '';
        // Por si viene del back o ya se escogió
        if (element.type === 'img' || element.type === 'image') type = 'image';
        else type = 'video';

        const item = {
          id: element.id,
          src: src,
          duration: element.duration || 30,
          type, // no tocar acaaaaa, esto es para saber si es un video o una imagen, y asi cargarlo en el editor. no confundir con el type cuando se manda a crear el video
          startSecond: element.startSecond || 0,
          endSecond: element.duration || 30,
          originalStartSecond: element.startSecond || 0,
          originalEndSecond: element.duration || 30,
          originalDuration: element.duration || 30,
          libraryType: element.libraryType || 'clip',
        };

        listItems.push(item);
      });

      const listItemsCopy = JSON.parse(JSON.stringify(listItems || []));

      dispatch(save({ newVideos: listItemsCopy, config: { currentVideoIndex: 0 } }));
    }
  };

  // Esto es para poder cambiar entre la libreria de clips y la de grabaciones en el dialogo de escoger videos a editar
  const handleLibraryChange = (type) => {
    if (type === typesRequestLibrary.CORPORATIVE) {
      setLibraryType(typesRequestLibrary.CORPORATIVE);
      setRootFolder(libraryClips);
    } else if (type === typesRequestLibrary.RECORD) {
      setLibraryType(typesRequestLibrary.RECORD);
      setRootFolder(libraryRecordings);
    }
  };

  const buttonClass = classNames("border p-8 h-44 rounded-xl space-y-5 shadow-xl flex flex-col items-center justify-center shadow-gray-more-video ", !presentVideos?.length > 0 ? "cursor-default opacity-50" : 'cursor-pointer hover:bg-primary-lighter active:bg-primary active:text-white')

  return (
    <>
      {/* Video editor */}
      <div>
        <VideoEditor
          videos={presentVideos || []}
          overlayTexts={overlayTexts || []}
          handleOnAddVideo={() => setShowModal(true)}
        />
      </div>

      {/* Seleccion de portada */}
      <>
        <div className="flex items-center">
          <h3 style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#272727', marginRight: 8 }}>
          {t('VideoNew.edition.actions.frontPage.title')}
          </h3>
          <InfoBox message={t('Message.template.info.select_template')}></InfoBox>
        </div>

        <div className="grid grid-cols-3 grid-flow-col mt-6 gap-6">
          <button
            className={buttonClass} // no se por que no lo hice un comp. jjajaja
            onClick={() => setShowSelectFrame(true)}
            disabled={!presentVideos?.length > 0 || false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
              fill="currentColor"
            >
              <path d="M260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-620h60v620h620v60H140Zm120-740v560-560Z" />
            </svg>
            <span className="font-medium text-md select-none">{t('VideoNew.edition.actions.frontPage.actions.frame')}</span>
          </button>
          <button
            className={buttonClass}
            onClick={() => setShowUploadImage(true)}
            disabled={!presentVideos?.length > 0 || false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              fill="currentColor"
              className="bi bi-image"
              viewBox="0 0 16 16"
            >
              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
            </svg>
            <span className="font-medium text-md">{t('VideoNew.edition.actions.frontPage.actions.importImg')}</span>
          </button>

          <button
            className={buttonClass}
            onClick={() => setShowShootPicture(true)}
            disabled={!presentVideos?.length > 0 || false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
              fill="currentColor"
            >
              <path d="M479.5-266q72.5 0 121.5-49t49-121.5q0-72.5-49-121T479.5-606q-72.5 0-121 48.5t-48.5 121q0 72.5 48.5 121.5t121 49Zm0-60q-47.5 0-78.5-31.5t-31-79q0-47.5 31-78.5t78.5-31q47.5 0 79 31t31.5 78.5q0 47.5-31.5 79t-79 31.5ZM140-120q-24 0-42-18t-18-42v-513q0-23 18-41.5t42-18.5h147l73-87h240l73 87h147q23 0 41.5 18.5T880-693v513q0 24-18.5 42T820-120H140Zm680-60v-513H645l-73-87H388l-73 87H140v513h680ZM480-436Z" />
            </svg>
            <span className="font-medium text-md ">{t('VideoNew.edition.actions.frontPage.actions.photo')}</span>
          </button>
        </div>
      </>

      {/* Save */}
      <div className="mt-16">
        <button
          className="bg-primary text-white px-4 py-3 rounded-lg"
          onClick={() => setShowSelectFolder(true)}
          disabled={!presentVideos?.length > 0 || false}
        >
          {t('Video.continue')}
        </button>
      </div>

      {/* Los puse con las condicionales para que solo apareciera un  FolderContextProvider a la vez*/}
      {/* Modales */}
      {showModal && (
        <FolderContextProvider library={libraryType}>
          <SelectClipsModal
            rootFolder={rootFolder.rootFolder}
            isLoading={rootFolder.isLoading}
            showModal={showModal}
            onClose={() => setShowModal(false)}
            // Esto es para guardar los videos escogido en el estado global de los videos
            onReadyDownloads={handleSaveVideosToEdit}
            onLibraryChange={handleLibraryChange}
            hasBackdrop
          />
        </FolderContextProvider>
      )}

      {showSelectFolder && (
        <FolderContextProvider library={typesRequestLibrary.VIDEO}>
          <SelectFolderVideo
            rootFolder={libraryVideos.rootFolder}
            isLoading={libraryVideos.isLoading}
            showModal={showSelectFolder}
            onClose={() => setShowSelectFolder(false)}
            nextStep={nextStep}
          />
        </FolderContextProvider>
      )}

      {(showSelectFrame || showUploadImage || showShootPicture ) && (
        <FolderContextProvider library={typesRequestLibrary.FRONTPAGE}>
          <SelectFrame
            videos={presentVideos}
            open={showSelectFrame}
            onClose={() => setShowSelectFrame(false)}
          />

          <UploadImage
            rootFolder={libraryFrontPages.rootFolder}
            isLoading={libraryFrontPages.isLoading}
            open={showUploadImage}
            onClose={() => setShowUploadImage(false)}
            onOpenShootPicture={() => {
              setShowShootPicture(true);
            }}
          />

          <ShootPicture open={showShootPicture} onClose={() => setShowShootPicture(false)} />
        </FolderContextProvider>
      )}
    <SelfRecord />
    </>
  );
}
