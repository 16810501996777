import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  PlayPauseIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ConfirmModal, Navbar, GeneralProgressModal, Sidebar } from './common';
import { useInactivityChecker } from '../hooks/useInactivityChecker';
import useAuthContext from '../hooks/useAuthContext';
import useFound from './common/Search/hook/useFound';
import useNotification from '../hooks/useNotification';
import { Hide, IconButton, MenuIcon } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import GeneralQuery from '../services/general/GeneralQuery';

const navigation = [
  { name: 'Dashboard', href: '#', icon: PlayPauseIcon, current: true },
  { name: 'Team', href: '#', icon: UsersIcon, current: false },
  { name: 'Projects', href: '#', icon: FolderIcon, current: false },
  { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
  { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },
  { name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
];
const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Container({ children }) {
  // state
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // hooks
  const { user, logout } = useAuthContext();
  useFound(); // to found any search in the app
  useNotification(); // to handle websocket notifications

  // rq
  GeneralQuery().SystemInfo({ token: user?.access_token }); // to get system info and save in cache

  useEffect(() => {
    const rememberSession = JSON.parse(localStorage.getItem('RememberSession'));
    if (!rememberSession) {
      const sessionTimeout = useInactivityChecker(4 * 60 * 60 * 1000); // tiempo de expiracion de token definido para 4h
      sessionTimeout && logout();
    }
  }, []);

  return (
    <div className="fixed inset-0">
      {/* Menu desplegable vista movil */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={() => setSidebarOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex w-full h-full flex-col overflow-y-auto px-0" >
                  <Sidebar movil onClose={() => setSidebarOpen(false)} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>      
      <IconButton
        className="fixed top-4 left-4 z-10"
        sx={{
          '@media (min-width: 1024px)': { display: 'none' },
        }}
        aria-label="Open sidebar"
        icon={<MdMenu className="h-6 w-6" aria-hidden="true" />}
        onClick={() => setSidebarOpen(true)}
      />

      {/* Static sidebar for desktop */}
      <Sidebar />

      <div className="lg:pl-80 2xl:pl-96 ml-20 mx-12 h-screen absolute inset-0">
        <div className="mx-auto w-full h-full lg:px-0 px-2">
          <Navbar />

          <main
            className="py-0 2xl:py-10 2xl:pr-56 w-full pr-44 overflow-y-auto "
            style={{
              height: 'calc(100vh - 16vh)',
              '@media (maxWidth: 1024px)': {
                height: 'calc(100vh - 139px)',
              },
            }}
          >
            <div>{children}</div>
          </main>
        </div>
      </div>

      <ConfirmModal />
      <GeneralProgressModal />
    </div>
  );
}
