import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Routes from '../../routes/app/paths';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select/creatable';
import { useQuery } from 'react-query';
import { associatedList, tagList } from '../../services/client/TagService';
import useAuthContext from '../../hooks/useAuthContext';
import { Subtitle } from '../../components/common';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../hooks/useToast2';

const headers = [
  { text: '#', value: null },
  { text: 'contact', value: 'Contacto' },
  { text: 'agent', value: 'Agente' },
  { text: 'creationDate', value: 'Fech de Creacion' },
  { text: 'visualizations', value: 'Visualizaciones' },
  { text: 'clickCtas', value: 'Clics CTAs' },
  { text: 'downloads', value: 'Descargas PDF' },
  { text: 'info', value: 'Info' },
];

const people = [
  { value: 1, label: 'Wade Cooper' },
  { value: 2, label: 'Arlene Mccoy' },
  { value: 3, label: 'Devon Webb' },
  { value: 4, label: 'Tom Cook' },
  { value: 5, label: 'Tanya Fox' },
  { value: 6, label: 'Hellen Schmvaluet' },
  { value: 7, label: 'Caroline Schultz' },
  { value: 8, label: 'Mason Heaney' },
  { value: 9, label: 'Claudie Smitham' },
  { value: 10, label: 'Emil Schaefer' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function InsightList({ items, filters, title, downloads = false, searchBar = true }) {
  /* State */
  const [selectedItem, setSelectedIetm] = useState([]);
  const [tags, setTags] = useState([]);
  const { showToast } = useToast2();
  const [lists, setLists] = useState([]);
  const [isLoadingTag, setIsLoadingTag] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [tag, setTag] = useState(null);
  const [list, setList] = useState(null);

  /* Hooks */
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuthContext();
  const { t } = useTranslation();

  /* Querys */
  useQuery(['TAG_LIST', { token: user.access_token }], tagList, {
    onError: (error) => {
      showToast('error', t('Message.error'), t('Message.client.error.load_all'));
    },
    onSuccess: (response) => {
      const { data } = response.data;
      setTags(data);
    },
  });

  useQuery(['ASSOCIATED_LIST', { token: user.access_token }], associatedList, {
    onError: (error) => {
      showToast('error', t('Message.error'), t('Message.client.error.load_all'));
    },
    onSuccess: (response) => {
      const { data } = response.data;
      setLists(data);
    },
  });

  /* Consts */

  /* Functions */
  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  const handleCreateTag = (inputValue) => {
    setIsLoadingTag(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingTag(false);
      setTags((prev) => [...prev, newOption]);
      setTag(newOption);
    }, 1000);
  };

  const handleCreateList = (inputValue) => {
    setIsLoadingList(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingList(false);
      setLists((prev) => [...prev, newOption]);
      setList(newOption);
    }, 1000);
  };
  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: 48,
      width: '100%',
      flex: 1,
    }),
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-0">
        <div className="mt-8 flow-root">
          <span className="text-gray-700 text-md font-bold">
            Otros vídeos de {isAuthenticated && `${user.name} ${user.last_name}`}
          </span>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="mt-4">
                {filters && (
                  <div className="flex justify-between items-center ">
                    <div className="flex space-x-2">
                      {filters.lastVizualizations && (
                        <Select options={tags} placeholder="Ultima visualizacion" styles={styles} />
                      )}
                      {filters.emp && (
                        <Select options={lists} placeholder="Empresa" styles={styles} />
                      )}
                      {filters.date && (
                        <Select options={lists} placeholder="Fecha" styles={styles} />
                      )}
                      {filters.list && (
                        <Select
                          onCreateOption={handleCreateList}
                          options={people}
                          placeholder="Listado"
                          styles={styles}
                        />
                      )}
                      {filters.tags && (
                        <Select
                          onCreateOption={handleCreateTag}
                          options={people}
                          placeholder="Tags"
                          styles={styles}
                        />
                      )}
                    </div>
                    {searchBar && (
                      <div>
                        <input
                          name="search"
                          className=" h-10 rounded-full border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 sm:text-sm sm:leading-6"
                          placeholder={t('Placeholder.search')}
                          type="text"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {title && <Subtitle title="Videos con mejor rendimiento" />}
              <div className="relative mt-4">
                <table className="border-separate border-spacing-y-3 border-spacing-x-[1px] w-full">
                  <thead>
                    <tr>
                      {headers.map((header, index) =>
                        !header.value ? (
                          <th
                            key={`header-${index}`}
                            scope="col"
                            className="whitespace-nowrap  relative pt-[6px] pb-[6.5px] sm:w-12 bg-black-soft rounded-tl-xl rounded-bl-xl"
                          >
                            <div className=" flex justify-center items-center text-white">
                              <span>#</span>
                            </div>
                          </th>
                        ) : (
                          <th
                            key={`header-${index}`}
                            scope="col"
                            className={`${
                              index === 4 ? 'w-48' : ''
                            } pt-[6px] pb-[6.5px] text-left text-sm font-semibold bg-black-soft px-2 last:rounded-tr-xl last:rounded-br-xl`}
                          >
                            <div className="flex justify-between space-x-1 items-center">
                              <p className="text-white mt-[7px] mb-[4.7px] h-[26px]">
                                {' '}
                                {header.value}
                              </p>
                              <div className="flex justify-end">
                                <ArrowsUpDownIcon className="w-5 h-5 text-white cursor-pointer" />
                              </div>
                            </div>
                          </th>
                        ),
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {items.map((client, index) => (
                      <tr
                        key={`tr-${index}`}
                        className={classNames(
                          selectedItem.includes(client) ? 'bg-gray-50' : undefined,
                          'bg-gray-100 relative px-6 sm:w-12 sm:px-rounded-tl-xl rounded-bl-xl',
                        )}
                      >
                        {/* check */}
                        <td
                          key={`td-${client.id}`}
                          className="relative sm:w-12 border-r border-gray-border rounded-tl-xl rounded-bl-xl"
                        >
                          <div className="py-2.5 flex justify-center items-center">
                            <span className="text-sm text-gray-text">{index + 1}.</span>
                          </div>
                        </td>

                        {/* name */}
                        <td
                          key={`td-${client.name}-${index}`}
                          className={classNames(
                            'whitespace-nowrap py-2.5 px-2 text-sm text-left font-medium border-r border-gray-border ',
                            selectedItem.includes(client)
                              ? 'text-black font-bold'
                              : 'text-gray-text',
                          )}
                        >
                          {client.contact}
                        </td>

                        {/* lastName */}
                        <td
                          key={`td-${client.agent}-${index}`}
                          className={classNames(
                            'whitespace-nowrap py-2.5 text-sm text-left font-medium px-2 border-r border-gray-border ',
                            selectedItem.includes(client)
                              ? 'text-black font-bold'
                              : 'text-gray-text',
                          )}
                        >
                          {client.agent}
                        </td>

                        {/* email */}
                        <td
                          key={`td-alter-${client.creationDate}-${index}`}
                          className={classNames(
                            'whitespace-nowrap py-2.5 text-sm text-left font-medium px-2 border-r border-gray-border ',
                            selectedItem.includes(client)
                              ? 'text-black font-bold'
                              : 'text-gray-text',
                          )}
                        >
                          {client.creationDate}
                        </td>

                        {/* Last Video */}
                        <td
                          width={90}
                          key={`td-alter-${client.visualizations}-${index}`}
                          className={classNames(
                            'whitespace-nowrap max-w-xl py-2.5 text-sm text-center font-medium px-2 border-r border-gray-border ',
                            selectedItem.includes(client)
                              ? 'text-black font-bold'
                              : 'text-gray-text',
                          )}
                        >
                          {client.visualizations}
                        </td>

                        {/* Last Video Name */}
                        <td
                          key={`td-alter-${client.phone}-${index}`}
                          className={classNames(
                            'whitespace-nowrap py-2.5 text-sm font-medium px-2 border-r border-gray-border ',
                            selectedItem.includes(client)
                              ? 'text-black font-bold'
                              : 'text-gray-text',
                          )}
                        >
                          {client.clickCtas}
                        </td>

                        <td
                          key={`td-alter-${client.downloads}-${index}`}
                          className={classNames(
                            'whitespace-nowrap py-2.5 text-sm font-medium px-2 border-r border-gray-border ',
                            selectedItem.includes(client)
                              ? 'text-black font-bold'
                              : 'text-gray-text',
                          )}
                        >
                          {client.downloads}
                        </td>
                        <td
                          key={`button-${index}`}
                          className="whitespace-nowrap py-2.5 px-2 text-right text-sm font-medium rounded-br-xl rounded-tr-xl"
                        >
                          <button
                            onClick={() => navigate(`${Routes.contactProfile}/${client.id}`)}
                            className="bg-primary block w-full text-white px-8 py-2.5 rounded-md"
                          >
                            Ver
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {downloads && (
                <div className="flex space-x-4 mt-4">
                  <button className="flex items-center py-2.5 px-3 text-gray-text rounded-md bg-white ring-2 ring-gray-button">
                    Descargar Informe
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
