import { useState } from 'react';
import { Step1 } from './Step1';
import {
  replace,
  setFile as setFileRedux,
  setPreviewClients,
  stepStatus,
} from '../../../features/client/clientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ClientImportPreview } from '../../../services/client/ClientService';
import { useMutation } from 'react-query';
import useAuthContext from '../../../hooks/useAuthContext';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../components/common';
import { ImportList } from './ImportList';
import { classNames } from '../../../config/utils';
import { Tooltip } from 'react-tooltip';
import { useToast2 } from '../../../hooks/useToast2';
import { importXlsxFile, typeValidateErrors, validateXlsxData } from '../../../config/xlsxHandler';

const TabsBox = ({ number, select }) => {
  return (
    <div
      className={classNames(
        'w-[30px] 2xl:w-[40px] h-[28px] 2xl:h-[36px] flex justify-center items-center rounded-lg select-none text-sm 2xl:text-lg duration-100 active:bg-primary active:text-white active:border-0',
        select
          ? 'bg-black text-white hover:text-primary-lighter'
          : 'border-[2px] border-gray-400 text-gray-400',
      )}
    >
      {number}
    </div>
  );
};

export function ImportContact() {
  /* State */
  const [errorWhenUpload, setErrorWhenUpload] = useState(false);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [listsSelected, setListsSelected] = useState([]);

  /* Hooks */
  const { user } = useAuthContext();
  const stepCounter = useSelector(stepStatus);
  const replaceClients = useSelector(replace);
  const previewClients = useSelector((state) => state.client.previewClients);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = useToast2();

  /* Mutations */
  const mutationImportClientsPreview = useMutation(
    'PREVIEW_IMPORTED_CLIENTES',
    ClientImportPreview,
    {
      onError: (error) => {
        if (error.status === 500) showToast({ show: true, type: 'error', message: t('Message.problem') });
        setErrorWhenUpload(true);
      },
      onSuccess: ({ data }) => {
        setErrorWhenUpload(false);
        dispatch(setPreviewClients(data));
        data.clients?.length > 0
          ? showToast({
              show: true,
              type: 'success',
              message: 'Importación previa con éxito.',
              position: 'bottom-right',
            })
          : showToast({
              show: true,
              type: 'error',
              message: 'La lista de contactos esta vacía.',
              position: 'bottom-right',
            });
      },
    },
  );

  /* Functions */
  const validateFileBeforeSend = async (file) => {
    // extensiones permitidas: .xls, .xlsx, .csv
    // const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
    const allowedExtensions = /\.csv$/i;
    if (!allowedExtensions.exec(file.name)) {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.file.error.extension'),
      });
      return false;
    }

    // tamaño permitido: 5MB
    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.file.error.size'),
      });
      return false;
    }

    // other validations
    let typeMessage = '';
    const dataLoaded = await importXlsxFile(file);

    // comprobar contenido
    typeMessage = validateXlsxData(dataLoaded);
    if (typeMessage && typeMessage !== 'ok') {
      showToast({
        show: true,
        type: 'error',
        message: typeValidateErrors[typeMessage].label,
      });
      return false;
    }

    return true;
  }
  
  const handleFile = async (file) => {
    if (file){
      const isValid = await validateFileBeforeSend(file);
      if (!isValid) {
        setErrorWhenUpload(true);
        return;
      };

      importFile(file);
      dispatch(setFileRedux(file));
    }
  };

  const importFile = (file) => {
    const data = new FormData();
    data.append('excel', file);
    data.append('replace', Boolean(replaceClients));
    mutationImportClientsPreview.mutate({ token: user.access_token, client: data });
  };

  return (
    <>
      <Tooltip id="my-tooltip" />
      <div className="flex justify-between items-center">
        <Title title={t('Contact.import_contacts.meta')} />
        <div className="flex space-x-2 items-center">
          <TabsBox number={1} select={stepCounter === 1} />
          <TabsBox number={2} select={stepCounter === 2} />
        </div>
      </div>

      {stepCounter == 1 
        ? <Step1 
            onUpload={handleFile} 
            errorUpload={{
              error: errorWhenUpload,
              setError: setErrorWhenUpload,
            }}
            setListsSelected={setListsSelected}
            setTagsSelected={setTagsSelected}
          /> 
        : <ImportList listsAndTags={{
            tags: tagsSelected,
            lists: listsSelected,
          }} />}
    </>
  );
}
