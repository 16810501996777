import { Box, Icon, Input, InputGroup, InputLeftElement, Spinner, Stack, StackDivider } from '@chakra-ui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useRef, useState } from 'react';
import ItemListSearch from './ItemListSearch';
import useDebounce from '../../../hooks/useDebounce';
import { SearchQuery } from '../../../services/general/SearchQuery';
import useSearch, { searchCategory, searchCategoryProtectedAdmin, searchCategoryProtectedManager } from './hook/useSearch';
import noFoundImg from '../../../assets/img/search-check.png'
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../hooks/useAuthContext';

function SearchMain() {
  // states
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [auxDisclosure, setAuxDisclosure] = useState(true);

  // refs
  const searchRef = useRef(null);
  const searchInputRef = useRef(null);

  // hooks
  const { user } = useAuthContext();
  const debouncedQuery = useDebounce(query);
  const { handleOnClick } = useSearch();
  const { t } = useTranslation();

  // rq
  const { data, isLoading, isError } = SearchQuery().search(debouncedQuery);

  // effects
  useEffect(() => {
    setAuxDisclosure(true); // means that the user typed something, so open the search results
    
    if (!data) return;
    const categoryInResponse = Object.keys(data);
    const items = [...results]

    categoryInResponse.forEach((category) => {
      const results = data[category]
        .map((item) => ({ ...item, category }))
        // avoid not matching results from backend
        .filter((item) => (item.title || item.name || '').toLowerCase().includes(query.toLowerCase()))
      items.push(...results);
    });

    let filteredCategoryByRole = user.role !== 'Admin' && user.role !== 'Manager' ? categoryInResponse : [];
    if (user.role === 'Admin') filteredCategoryByRole = categoryInResponse.filter(category => !searchCategoryProtectedAdmin.includes(category));
    if (user.role === 'Manager') filteredCategoryByRole = categoryInResponse.filter(category => !searchCategoryProtectedAdmin.includes(category) && !searchCategoryProtectedManager.includes(category));

    // clean duplicates and sort by category
    const uniqueItems = items
      .filter((item, index, self) => self.findIndex((i) => i.id === item.id && i.category === item.category) === index)
      .sort((a, b) => searchCategory.indexOf(a.category) - searchCategory.indexOf(b.category));
    
    setResults(uniqueItems);
  }, [data, query]);

  useEffect(() => {
    // close search results when click outside
    const handleClick = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setAuxDisclosure(false);
      }
      // open search results when click on input
      if (searchInputRef.current && searchInputRef.current.contains(event.target)) {
        setAuxDisclosure(true);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []);  
  
  const filteredResult = useMemo(() => results
    .filter(item => (item.title || item.name || '').toLowerCase().includes(query.toLowerCase()))
    // add onClick
    .map(item => ({ ...item, onClick: () => handleOnClick(item, query) }))  
  , [results, query]);  
  
  return (
    <div ref={searchRef} className="relative w-full p-1">
      <InputGroup ref={searchInputRef} >
        <InputLeftElement pointerEvents="none" textColor="gray.400">
          {isLoading ? <Spinner size='sm' /> : <Icon as={MagnifyingGlassIcon} fontSize={20} />}
        </InputLeftElement>
        <Input
          placeholder="Buscar personas, listas, tags, carpetas, videos"
          type="search"
          autoComplete="off"
          color="gray.600"
          _placeholder={{ color: 'gray.400' }}
          focusBorderColor="gray.500"
          value={query}
          onChange={(e) => setQuery(e.target.value || '')}
        />
      </InputGroup>

      {/* Search results */}
      {query && (filteredResult.length > 0 || isError) && auxDisclosure && 
        <Stack
          spacing={2}
          bg="white"
          boxShadow="md"
          rounded="md"
          position="absolute"
          top="102%"
          border='1px solid'
          borderColor='gray.200'
          insetInline={1}
          zIndex={100}
          overflow='hidden'
        >
          <Box 
            className="flex flex-col"
            maxHeight={300}
            overflowY='auto'
          >
            { isError ? (
              <div className='flex flex-col gap-4 items-center p-4'>
                <img src={noFoundImg} className="w-36" alt='No se encontraron resultados' />
                <span className='text-gray-500 text-sm font-semibold'>{t('Search.no_results')}</span>
              </div>
            ) : (
              filteredResult.map((item, i) => (
                <ItemListSearch key={`item-search-${i}`} item={item} query={query} onClose={() => setAuxDisclosure(false)}/>
              ))
            )}
          </Box>
        </Stack>
      }
    </div>
  );
}

export default SearchMain;
