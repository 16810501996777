import { useDispatch, useSelector } from 'react-redux';
import { useToast2 } from './useToast2';
import { uniqueId } from '../config/utils';
import { addToUploadingFiles, removeFromUploadingFiles, updateUploadingFiles, uploadingFiles } from '../features/videos/videosSlice';
import useAuthContext from './useAuthContext';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useReducer, useRef } from 'react';

const useUpload = () => {
  const { user } = useAuthContext();
  const items = useSelector(uploadingFiles);
  const dispatch = useDispatch();
  const { showToast } = useToast2();
  const { t } = useTranslation();

  // store the cancel functions
  const [uploadProcesses, dispatchProcesses] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD_CANCEL_FUNCTION':
        console.log(action.id)
        return { ...state, [action.id]: { cancel: action.cancel } };
      case 'REMOVE_CANCEL_FUNCTION': {
        const newState = { ...state };
        delete newState[action.id];
        return newState;
      }
      default:
        return state;
    }
  }, {});

  const itemsCancelled = items.filter((item) => item.cancelled);

  // effects
  useEffect(() => {
    itemsCancelled.forEach((item) => {
      const { id } = item;
      if (uploadProcesses[id] && uploadProcesses[id].cancel) {
        uploadProcesses[id].cancel();
      }
    });
  }, [itemsCancelled, uploadProcesses]);
  
  const upload = useCallback(async (param) => {
    const {title, preview, type = 'video', service, data, onUploaded} = param;

    const id = uniqueId();
    // initialize, add the new upload
    dispatch(
      addToUploadingFiles({
        id,
        title,
        preview,
        type,
        progress: 0,
        total: 100,
        cancelled: false,
      }),
    );

    // run the services used
    // - first param: the data
    // - second param: the onProgress function
    // - third param: true to indicate that this services should be cancelled
    const { promise, cancel } = await service(
      {
        token: user?.access_token,
        title,
        ...data,
      },
      ({ progress, total }) => {
        dispatch(
          updateUploadingFiles({
            id,
            progress,
            total,
          }),
        );
      },
      true,
    );

    // store the cancel function
    dispatchProcesses({ type: 'ADD_CANCEL_FUNCTION', id, cancel });

    promise
      .then(() => {
        showToast({
          show: true,
          type: 'success',
          message: t('Message.upload.success'),
        });
        if (onUploaded) onUploaded();
      })
      .catch((e) => {
        dispatch(removeFromUploadingFiles(id));
        console.log(e);
        if (e.message === 'Promise was cancelled') {
          showToast({
            show: true,
            type: 'error',
            message: t('Message.upload.cancelled'),
          });
          return;
        }
        showToast({
          show: true,
          type: 'error',
          message: t('Message.upload.error'),
        });
      })
      .finally(() => {
        // remove the cancel function
        dispatchProcesses({ type: 'REMOVE_CANCEL_FUNCTION', id });
      });
  }, [dispatch, showToast, t, user, dispatchProcesses]);

  return { upload };
};

export default useUpload;
