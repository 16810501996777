import VideoProvider from '../../components/video/context/VideoContext';
import VideoMain from '../../components/video/VideoMain';

export function Video({ reuseVideo }) {

  return (
    <VideoProvider reuseVideo={reuseVideo}>
      <VideoMain />
    </VideoProvider>
  );
}
