import Papa from 'papaparse';
import { read, utils } from 'xlsx';

const requiredColumns = [
  'nombre',
  'primer apellido',
  'segundo apellido',
  'email',
  'telefono',
];

const requiredColumnsWithValue = [
  'nombre',
  'email'
]

export const typeValidateErrors = {
  noData: {
    label: 'No hay datos en el archivo',
  },
  noContacts: {
    label: 'No hay contactos en el archivo',
  },
  noColumnMatched: {
    label: 'Hay algunas columnas que no coinciden con las esperadas',
  },
  outLimit: {
    label: 'El archivo no puede tener mas de 500 contactos',
  },
}

export const importXlsxFile = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: false,
      complete: function(results) {
        if (results.data?.length > 0 || results?.meta?.fields?.length > 0) {
          const headers = results.meta.fields;
          const emptyRow = Object.fromEntries(headers.map(header => [header, '']));
          const data = results.data.map(row => {
            return { ...emptyRow, ...row };
          });
          if (data.length === 0) resolve([{ ...emptyRow }]);
          resolve(data);
        } else {
          resolve([]);
        }
      },
      error: reject
    });
  });
}

export const validateXlsxData = (data) => {
  const dataColumns = Object.keys(data[0]);
  const hasAllRequiredColumns = requiredColumns.every(column => dataColumns.includes(column));

  // Comprobar que el archivo tiene todas las columnas requeridas
  if (!hasAllRequiredColumns) {
    return 'noColumnMatched';
  }

  // Comprobar que el archivo tiene elementos
  if (data.length === 0) {
    return 'noData';
  }

  // Comprobar que el archivo no tiene mas de 500 elementos
  if (data.length > 501) {
    return 'outLimit';
  }

  // Comprobar que al menos uno de las filas tiene valores para todas las columnas requeridas con valor
  const hasValuesForAllColumns = data.some(row => requiredColumnsWithValue.every(column => row[column]));
  if (!hasValuesForAllColumns) {
    return 'noContacts';
  }

  return 'ok';
};
