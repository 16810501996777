import { dataPanel } from "../config/configRecord";
import PanelButton from "./PanelButton";

const AuxiliarPanel = ({ top = 112, left = 896, index, subItemSelected, updateSelection }) => {
  
  const subItems = dataPanel[index].subItems;

  const handleClick = (_, subIndex) => {
    updateSelection(index, subIndex);
  }

  return (
    <div
      className="fixed overflow-hidden rounded-lg bg-white shadow-xl"
      style={{ width: 277, top, left: left - 277 - 10 }}
    >
      {subItems.map((item, index) => (
        <PanelButton
          key={index}
          icon={item.icon}
          mb={30}
          text={item.text}
          active={subItemSelected === index}
          hasCheck={subItemSelected === index}
          onClick={(e) => handleClick(e, index)}
          disabled={item.disabled}
        />
      ))}
    </div>
  );
};

export default AuxiliarPanel;