import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { finalClients, list, replace, tags } from '../../features/client/clientSlice';

export default function Resume() {
  /* Hooks */
  const { t } = useTranslation();
  const clients = useSelector(finalClients);
  const replacing = useSelector(replace);

  return (
    <div>
      <p className="text-2xl text-gray-subtitle-softer">
        - {t('Contact.import_contacts.final_step.number_contacts_upload', { number: clients.filter(fc => fc !== null && fc !== undefined).length })}
      </p>
      <p className="text-2xl text-gray-subtitle-softer">
        - {t('Contact.import_contacts.final_step.update_existing_contacts', { text: replacing ? t('Contact.import_contacts.final_step.yes') : t('Contact.import_contacts.final_step.no') })}
      </p>
      <p className="text-2xl text-gray-subtitle-softer">
        - {t('Contact.import_contacts.final_step.assing_lists')} {clients.length > 0 && clients[0]?.lists?.length}
      </p>
      <p className="text-2xl text-gray-subtitle-softer">
        - {t('Contact.import_contacts.final_step.assing_tags')} {clients.length > 0 && clients[0]?.tags?.length}
      </p>
    </div>
  );
}
