import { Subtitle, Title } from '../../components/common';
import { useState } from 'react';
import { InsightList, Section } from '../../components/insights';
import Chart from 'react-apexcharts';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export function Insights() {
  /* State */
  const [options, setOptions] = useState({
    chart: {
      id: 'basic-line',
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
    colors: ['#f73757', '#E91E63', '#9C27B0'],
  });

  const [series, setSeries] = useState([
    {
      name: 'series-1',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ]);

  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: 48,
      width: '100%',
      flex: 1,
      height: 32,
    }),
  };

  const insights = [
    {
      id: 1,
      contact: 'Nombre Empresa',
      agent: 'Agustina',
      creationDate: '10/03/2023',
      visualizations: 15,
      clickCtas: 10,
      downloads: 20,
    },
    {
      id: 2,
      contact: 'Nombre Empresa',
      agent: 'Agustina',
      creationDate: '10/03/2023',
      visualizations: 11,
      clickCtas: 23,
      downloads: 12,
    },
    {
      id: 3,
      contact: 'Nombre Empresa',
      agent: 'Agustina',
      creationDate: '10/03/2023',
      visualizations: 32,
      clickCtas: 21,
      downloads: 12,
    },
  ];
  return (
    <>
      <Title title="Estadísticas Generales de Equipo" />
      <div className="max-w-xs mt-6"></div>
      <div className="mt-4">
        <Section>
          <Subtitle dense={false} weight="bold" title="Videos vistos en Abril" />
          <div className="w-full">
            <Chart options={options} series={series} type="line" height={250} />
          </div>
        </Section>
      </div>
      <div className="grid grid-cols-3 gap-3 mt-6">
        <Section>
          <div className="flex items-center text-left space-x-4 px-8">
            <span className="text-7xl font-semibold text-gray-soft">10</span>
            <p className="text-xl font-semibold text-gray-soft">Videos Creados</p>
          </div>
        </Section>
        <Section>
          <div className="flex items-center text-left space-x-4 px-8 w-[265px]">
            <CircularProgressbar
              value={23}
              text={'66'}
              maxValue={100}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,
                pathColor: '#f73757',
                textColor: '#f73757',
              })}
            />
            <p className="text-xl font-semibold text-gray-soft">Visitas a Landing</p>
          </div>
        </Section>

        <Section>
          <div className="flex items-center text-left space-x-4 px-8 w-[300px]">
            <CircularProgressbar
              value={23}
              text={'66'}
              maxValue={100}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,
                pathColor: '#f73757',
                textColor: '#f73757',
              })}
            />
            <p className="text-xl font-semibold text-gray-soft">Videos visualizados</p>
          </div>
        </Section>
      </div>
      <InsightList title items={insights} />
    </>
  );
}
