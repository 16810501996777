import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateSelector } from '../templateLanding';
import { AccordionEditLanding } from '../AccordionEditLanding';
import { IoIosArrowBack } from 'react-icons/io';
import { selectLandingConfig, changeLandingConfig, changeStep, templateSelected } from '../../../features/videos/videosSlice';
import { SelectLanding } from './SelectLanding';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../components/common';
import { classNames } from '../../../config/utils';

export const EditLanding = ({ scope = 'video' }) => {
  // hooks
  const dispatch = useDispatch();
  const landingConfig = useSelector(selectLandingConfig);
  const templateNum = useSelector(templateSelected);
  const { t } = useTranslation();

  // states
  const [config, setConfig] = useState(landingConfig);

  // effects
  useEffect(() => {
    dispatch(changeLandingConfig(config));
  }, [config]);

  // handlers
  const handlePrev = () => {
    dispatch(changeStep(1));
  };
  const handleNext = () => {
    dispatch(changeStep(3));
  };

  return (
    <div className=" mt-12 flex flex-col">
      {/* Edit Landing */}
      <div className="w-full relative flex flex-wrap justify-center space-x-10">
        <div className="flex-grow">
          <TemplateSelector
            number={templateNum}
            video={'https://www.w3schools.com/html/mov_bbb.mp4'}
            color={'1E4372'}
            config={config}
          />
          {(scope === 'admin' && !templateNum) && (
            <>
              <Title title='Seleccione una landing' size='text-xl' />
              <Title title='Elija una plantilla por defecto para sus videos' />
            </>
          ) }
          {(scope === 'admin' || scope === 'profile') && <SelectLanding scope='admin'/>}
        </div>
        <div className={classNames("w-[270px] 2xl:w-[343px]", (scope === 'admin' && !templateNum) && 'opacity-50 pointer-events-none')}>
          <AccordionEditLanding onConfig={setConfig} />
        </div>
      </div>

      {/* buttons */}
      {
        scope === 'video' &&
        <div className="flex mt-5 2xl:mt-10">
          <button
            type="button"
            className="flex items-center justify-center h-12 px-2 mr-4 rounded-xl  text-gray-300 border-2 border-gray-300 font-semibold text-lg hover:shadow-lg active:scale-[0.98]"
            onClick={handlePrev}
          >
            <IoIosArrowBack size={30} />
          </button>
          <button
            type="button"
            className="flex items-center justify-center h-12 px-4 rounded-xl bg-primary text-white font-semibold text-lg hover:shadow-lg active:scale-[0.98] disabled:opacity-50"
            onClick={handleNext}
            disabled={config.pdf_links.someLinkIsInvalid}
          >
            {t('Landing.continue')}
          </button>
        </div>
      }
    </div>
  );
};
