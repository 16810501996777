// Este es el select que se muestra en el dialogo de escoger videos

import { useEffect, useRef, useState, useContext, useMemo } from 'react';
import { FolderContext } from '../../../../contexts/folderContext';
import { classNames } from '../../../../config/utils';

import './styles/SelectFolder.css';
import { FolderIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function SelectFolder({ folders = [], onLibraryChange = () => {} }) {
  const { setIdFolder } = useContext(FolderContext);

  const [isOpen, setIsOpen] = useState(false);

  const [currentFolder, setCurrentFolder] = useState(folders[0]);
  const [query, setQuery] = useState('');

  const menuRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleCloseMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) setIsOpen(false);
    };

    document.addEventListener('click', handleCloseMenu, true);

    return () => document.removeEventListener('click', handleCloseMenu, true);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectFolder = (folder, type) => {
    setCurrentFolder(folder);
    setIdFolder(folder.id);

    // Para saber si se esta usando varios tipos de librerias en este componente
    if (type) {
      const amount = folders.reduce((counter, folder) => {
        if (folder.id === 0) {
          return counter + 1;
        }
        return counter;
      }, 0);

      if (amount >= 2) {
        onLibraryChange(type);
      }
    }
  };

  const filteredFolder = useMemo(() => {
    return folders.filter((folder) => folder.name.toLowerCase().includes(query.toLowerCase()));
  }, [folders, query]);

  return (
    <div className="select-folder">
      <div className="current-folder" onClick={toggleMenu}>
        <span>
          {currentFolder?.name?.substring(0, 15) || ''}
          {currentFolder?.name?.length > 15 ? '...' : ''}
        </span>
        {isOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25">
            <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25">
            <path d="M530-481 332-679l43-43 241 241-241 241-43-43 198-198Z" />
          </svg>
        )}
      </div>
      <div
        ref={menuRef}
        className="select-folder-menu"
        style={{ display: isOpen ? 'flex' : 'none' }}
      >
        <div className="search-container">
          <input 
            className="select-folder-menu-search" 
            type="text" 
            placeholder={t('Placeholder.search')} 
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <span className="search-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="23"
              viewBox="0 -960 960 960"
              width="23"
              fill="#b4abac"
            >
              <path d="M796-121 533-384q-30 26-69.959 40.5T378-329q-108.162 0-183.081-75Q120-479 120-585t75-181q75-75 181.5-75t181 75Q632-691 632-584.85 632-542 618-502q-14 40-42 75l264 262-44 44ZM377-389q81.25 0 138.125-57.5T572-585q0-81-56.875-138.5T377-781q-82.083 0-139.542 57.5Q180-666 180-585t57.458 138.5Q294.917-389 377-389Z" />
            </svg>
          </span>
        </div>
        <div className="horizontal-separator" />
        <div className="select-folder-options">
          {filteredFolder && filteredFolder.length > 0 && (
            <ul className="select-folder-options-list">
              {filteredFolder.map((folder, index) => (
                <li
                  key={index}
                  style={{
                    borderLeft:
                      folder.name === currentFolder?.name // No pude poner el id porque root folder id de grabaciones y de videos son el mismo (0)
                        ? '2px solid #f73757'
                        : '2px solid transparent',
                  }}
                  onClick={() => handleSelectFolder(folder, folder?.type)}
                >
                  <FolderIcon className={classNames('w-4 h-4 mr-3')} />
                  <span className="truncate">{folder.name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
