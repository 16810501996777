import { MdCheckCircle } from "react-icons/md";

const PanelButton = ({ icon, text, onClick, mb = 0, active = false, hasCheck = false, disabled = false }) => {
  return (
    <button
      type="button"
      className={`flex items-center justify-normal w-full px-5 py-3 mb-${mb} text-lg font-semibold rounded-2xl ${
        active ? 'text-primary bg-primary-lighter' : 'text-gray-600'
      } bg-gray-100 ${!disabled && 'hover:text-primary'} disabled:opacity-50 transition-colors`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="mr-5 ">{icon}</div>
      <div className="flex justify-between w-full">
        {text}
        {hasCheck && <MdCheckCircle className="mt-1" size={25} />}
      </div>
    </button>
  );
};

export default PanelButton;