export const createCancellablePromise = (promise, xhr) => {
  let cancel;
  const cancellablePromise = new Promise((resolve, reject) => {
    promise.then(resolve, reject);
    cancel = () => {
      xhr.abort();
      reject(new Error('Promise was cancelled'));
    };
  });
  return { promise: cancellablePromise, cancel };
};

export const handleResponse = async (response) => {
  if (!response.ok) {
    return Promise.reject(response.status);
  }
  const data = await response.json();
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve(data);
  }
};

export const sendRequest = async (url, requestOptions) => {
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      return Promise.reject({
        status: error,
      });
    });
};
