import { classNames } from '../../../../config/utils';
import { BsWhatsapp } from 'react-icons/bs';
import { FaPhone } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';

const contactsInit = {
  whatsapp: '',
  phone: '',
  email: '',
}

const SocialLinksTemplate = ({ contacts = contactsInit, variant = 'default', style = {} }) => {
  const { whatsapp, phone, email } = contacts;

  const stylesTemplates = {
    default: {
      container: 'flex space-x-2 items-center cursor-pointer group',
      icon: {
        className: 'text-gray-600 group-hover:text-gray-700'
      },
      text: 'text-gray-400 text-[11px] font-semibold group-hover:text-gray-500 whitespace-nowrap',
    },
    second: {
      container: 'flex space-x-2 items-center justify-center cursor-pointer group whitespace-nowrap',
      icon: {
        className: 'text-primary',
        style
      },
      text: 'text-gray-400 font-semibold group-hover:text-gray-500',
    },
    third: {
      container: 'flex space-x-2 items-center cursor-pointer hover:text-primary ',
      icon: {
        className: '',
      },
      text: 'whitespace-nowrap',
    },
  }

  return (
    <>
      <div
        className={classNames(stylesTemplates[variant].container, !whatsapp && 'hidden')}
        onClick={() => window.open(`https://wa.me/${whatsapp}`)}
      >
        <BsWhatsapp className={stylesTemplates[variant].icon.className} style={stylesTemplates[variant].icon.style} />
        <span className={stylesTemplates[variant].text}>
          {whatsapp}
        </span>
      </div>

      <div
        className={classNames(stylesTemplates[variant].container, !phone && 'hidden')}
        onClick={() => window.open(`tel:${phone}`)}
      >
        <FaPhone className={stylesTemplates[variant].icon.className} style={stylesTemplates[variant].icon.style} />
        <span className={stylesTemplates[variant].text}>
          {phone}
        </span>
      </div>
      <div
        className={classNames(stylesTemplates[variant].container, !email && 'hidden')}
        onClick={() => window.open(`mailto:${email}`)}
      >
        <MdOutlineEmail className={stylesTemplates[variant].icon.className} style={stylesTemplates[variant].icon.style} />
        <span className={stylesTemplates[variant].text}>
          {email}
        </span>
      </div>
    </>
  );
};
export default SocialLinksTemplate;
