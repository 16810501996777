/**
 * Esta clase sirve para reproducir poder reproducir imagenes en editor
 */
export default class FakeVideo {
  #duration = 5;
  #currentTime = 0;
  #muted = false;
  #volume = 1;
  #playing = false;

  constructor() {
    this.timer = null;
    this.eventListeners = {};
  }

  get duration() {
    return this.#duration;
  }

  set duration(value) {
    this.#duration = value;
  }

  get currentTime() {
    return this.#currentTime;
  }

  set currentTime(value) {
    this.#currentTime = value;
  }

  get muted() {
    return this.#muted;
  }

  set muted(value) {
    this.#muted = value;
  }

  get volume() {
    return this.#volume;
  }

  set volume(value) {
    this.#volume = value;
  }

  get playing() {
    return this.#playing;
  }

  set playing(value) {
    this.#playing = value;
  }

  play() {
    if (this.#playing) {
      this.stop();
    }

    this.#playing = true;

    this.timer = setInterval(() => {
      this.#currentTime += 1;

      if (this.#currentTime >= this.#duration) {
        this.stop();
      }

      this.triggerEvent('timeupdate');
    }, 1000);
  }

  pause() {
    this.#playing = false;
    clearInterval(this.timer);
  }

  stop() {
    this.#playing = false;
    clearInterval(this.timer);
  }

  simulateLoadedMetadata() {
    setTimeout(() => this.triggerEvent('loadedmetadata'), 200);
  }

  addEventListener(eventName, callback) {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = [];
    }
    this.eventListeners[eventName].push(callback);
  }

  removeEventListener(eventName, callback) {
    if (this.eventListeners[eventName]) {
      this.eventListeners[eventName] = this.eventListeners[eventName].filter(
        (cb) => cb !== callback,
      );
    }
  }

  triggerEvent(eventName) {
    if (this.eventListeners[eventName]) {
      this.eventListeners[eventName].forEach((callback) => {
        callback();
      });
    }
  }
}
