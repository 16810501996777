import { Tooltip } from '@chakra-ui/react';
import { components } from 'react-select';

export const CustomSingleValue = ({ data }) => (
  <Tooltip
    label={data.label}
    aria-label="A tooltip"
    className="text-white bg-gray-600 p-1 rounded-md"
  >
    <span
      className="text-gray-600 absolute inset-0 top-1.5 left-2 overflow-ellipsis whitespace-nowrap"
      style={{
        width: 'calc(100% - 0px)',
        overflow: 'hidden',
      }}
    >
      {data.label}
    </span>
  </Tooltip>
);

export const CustomMultiValue = ({ data, ...props }) => (
  <Tooltip
    label={data.label}
    aria-label="A tooltip"
    className="text-white bg-gray-600 p-1 rounded-md z-50 w-fit"
  >
    <div className="w-fit"
      style={{
        maxWidth: 'calc(100% - 0px)',
      }}
    >
      <components.MultiValue {...props} />
    </div>
  </Tooltip>
);
