import { MdOutlineOndemandVideo, MdMicNone } from "react-icons/md";
import { FiCamera, FiCameraOff } from "react-icons/fi";

export const dataPanel = [
  {
    type: 'screen',
    subItems: [
      { text: 'Pantalla ...', icon: <MdOutlineOndemandVideo size={30} /> },
      { text: 'Cámara', icon: <FiCamera size={30} /> },
    ],
  },
  {
    type: 'camera',
    subItems: [
      { text: 'Cámara', icon: <FiCamera size={30} /> },
      { text: 'Sin cámara', icon: <FiCameraOff size={30} /> },
    ],
  },
  {
    type: 'audio',
    subItems: [
      { text: 'Cámara', icon: <MdMicNone size={30} /> },
      { text: 'Pantalla', icon: <MdMicNone size={30} /> },
    ],
  },
];

export const timeLimitForRecord = 5; // in minutes