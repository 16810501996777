export const useInactivityChecker = (expirationTime) => {
  let expirated = false;

  const checkInactivity = () => {
    const lastActivity = localStorage.getItem('lastActivity');
    const currentTime = new Date().getTime();

    if (lastActivity && currentTime - lastActivity > expirationTime) {
      expirated = true;
    };
  }

  const resetInactivity = () => {
    localStorage.setItem('lastActivity', new Date().getTime());
  };

  const handleUserActivity = () => {
    resetInactivity();
  };

  document.addEventListener('mousemove', handleUserActivity);
  document.addEventListener('keydown', handleUserActivity);

  checkInactivity();
  
  return expirated;
};