import { useContext } from 'react';
import { classNames } from '../../config/utils';
import { FolderContext } from '../../contexts/folderContext';
import { useLocation, useNavigate } from 'react-router-dom';

export function Breadcumbs({ path, onClick, className, category }) {
  const navigate = useNavigate();
  const location = useLocation();
  
  // path : string || [{id, name}]
  let toggleTab = null
  if(category === 'admin'){
    toggleTab = false;
  }else{
    toggleTab =  useContext(FolderContext).toggleArchive;
  } 
  const pathArr =
    typeof path === 'string' ? path.split('/').map((item) => ({ id: null, name: item })) : path;

  const handleClick = (e, id, companyId) => {
    // if has a search query, remove it
    if (location.search) navigate(location.pathname);
    
    if (onClick && !toggleTab) onClick(id, companyId);
  };

  return (
    <div
      className={classNames(
        className,
        'flex space-x-2 select-none text-sm 2xl:text-md',
        toggleTab && 'opacity-0',
      )}
    >
      {pathArr.slice(0, pathArr.length - 1).map((part, index) => (
        <div className="flex space-x-2" key={index}>
          <p
            className={classNames(
              'text-gray-breadcumbs active:text-primary',
              !toggleTab && 'cursor-pointer',
            )}
            onClick={(e) => handleClick(e, part.id, part.companyId)}
          >
            {part.name}
          </p>
          <span>/</span>
        </div>
      ))}
      {pathArr.slice(pathArr.length - 1).map((part, index) => (
        <div
          className={classNames('flex space-x-2 text-black', !toggleTab && 'cursor-pointer')}
          key={index}
        >
          <p className="">{part.name}</p>
        </div>
      ))}
    </div>
  );
}
