import { Disclosure } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, BellIcon, BoltIcon, XMarkIcon } from '@heroicons/react/24/outline';
import useAuthContext from '../../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import Routes, { LOGIN } from '../../routes/app/paths';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast2 } from '../../hooks/useToast2';
import GeneralConfirModal from './modal/GeneralConfirModal';
import { classNames, generateImageUrl } from '../../config/utils';
import { DOMAIN } from '../../routes/server/paths';
import SearchMain from './Search/SearchMain';

function UserAvatar() {
  // states
  const [openUserDropdown, setOpenUserDropdown] = useState(false);
  const [openModalLogout, setOpenModalLogout] = useState(false);

  const dropdownRef = useRef(null);

  // hooks
  const { t } = useTranslation();
  const { isAuthenticated, user, logout } = useAuthContext();
  const navigate = useNavigate();
  const { showToast } = useToast2();

  // effects
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(LOGIN);
    }
  }, [isAuthenticated]);

  useEffect(() => { // to close dropdown when click outside
    const pageClickEvent = (e) => {
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setOpenUserDropdown(!openUserDropdown);
      }
    }
    if (openUserDropdown) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    }
  }, [openUserDropdown]);

  // handlers
  const handleUserDropdown = () => {
    setOpenUserDropdown(!openUserDropdown);
  };

  const handleEditProfile = () => {
    navigate(Routes.account);
  };

  const hanldlePreferences = () => {
    navigate(Routes.account_preferences);
  };
  
  const handleLogout = () => {
    setOpenModalLogout(true);
  };

  const handleConfirmLogout = (confirm) => {
    confirm
      ? (logout(),
        navigate(Routes.login),
        showToast({ show: true, type: 'success', message: t('Message.auth.success.logout') }))
      : setOpenModalLogout(false)
  };

  const isUrlWithoutFile = (url) => url.substring(url.length-1, url.length) === '/';

  return (
    <div
      ref={dropdownRef}
    >
      {isAuthenticated ? (
        <div
          data-dropdown-toggle="userDropdown"
          data-dropdown-placement="bottom-end"
          className={classNames("w-[60px] h-[60px] 2xl:w-[72px] 2xl:h-[72px] text-white text-center align-middle text-4xl flex items-center justify-center rounded-full cursor-pointer shadow-md", user?.avatar ? 'bg-white' : 'bg-gray-700')}
          onClick={handleUserDropdown}
        >
          { user?.avatar 
              ? <img src={!isUrlWithoutFile(user?.avatar) && generateImageUrl(user?.avatar)} alt={user.name.substring(0, 1)} className='object-cover w-full h-full rounded-full' />
              : <span>{user.name.substring(0, 1)}</span>
          }
        </div>
      ) : (
        <img
          id="avatarButton"
          type="button"
          data-dropdown-toggle="userDropdown"
          data-dropdown-placement="bottom-end"
          className="rounded-full cursor-pointer"
          style={{ width: 73, height: 73 }}
          src=""
          alt="User dropdown"
        />
      )}

      <div
        id="userDropdown"
        className={`${!openUserDropdown && 'hidden'} z-50 absolute right-20 mt-3 bg-white divide-y divide-gray-200 rounded-xl shadow-2xl w-80 border-2 border-gray-100`}
        onClick={handleUserDropdown}
      > 
        <div className="px-4 py-3 text-sm text-gray-900 dark:text-white flex justify-end ">
          <XMarkIcon className="w-6 h-6 text-gray-600 cursor-pointer" onClick={handleUserDropdown} />
        </div>
        <div className="!border-0">
          <p className="text-2xl text-center">{user?.name}</p>
          <p className="text-sm text-center font-light">{user?.email}</p>
          <div className="py-2 text-center">
            <button
              type="button"
              className="bg-primary text-white rounded-lg px-6 py-1 my-2 text-sm font-regular"
              onClick={handleEditProfile}
            >
              {t('Profile.edit_profile')}
            </button>
          </div>
        </div>
        <ul
          className="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="avatarButton"
        >
          <li>
            <span className="block px-4 py-4 text-md text-gray-700 cursor-pointer" onClick={hanldlePreferences}>
              {t('Profile.preferences.meta')}
            </span>
          </li>
        </ul>
        <div className="py-1">
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
            <li>
              <span href="#" className="block px-4 py-4 text-md text-gray-700 cursor-pointer" onClick={handleLogout}>
                {t('Auth.Logout.meta')}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <GeneralConfirModal isOpen={openModalLogout} action={'logout'} onConfirm={handleConfirmLogout} />
    </div>
  );
}

export default function Navbar() {
  /* Hooks */
  return (
    <Disclosure as="nav" className="bg-white mt-4 2xl:mt-14">
      {({ open }) => (
        <>
          <div className="sm:px-4 lg:px-0">
            <div className="flex h-20 justify-between items-center">
              {/* Search  */}
              <SearchMain />

              <div className="items-center hidden"> 
                {/* Mobile menu button     (hidden right now) */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              {/* Profile dropdown */}
              <div className="hidden lg:mr-9 ml-20 2xl:ml-28 lg:flex lg:items-center justify-end lg:h-auto lg:w-auto">
                <UserAvatar />
              </div>
            </div>
          </div>

          {/* Menu Mobile  (hidden right now)*/}
          {/* <MenuMobile /> */}
        </>
      )}
    </Disclosure>
  );
}
