import { useQuery } from 'react-query'
import { SystemInfoService } from './GeneralService'
import useAuthContext from '../../hooks/useAuthContext'
import { useToast2 } from '../../hooks/useToast2';
import { useTranslation } from 'react-i18next';

const GeneralQuery = () => {
  const { logout } = useAuthContext();
  const { showToast } = useToast2();
  const { t } = useTranslation();

  const SystemInfo = (data) => useQuery(['systemInfo'], () => SystemInfoService(data),{
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: true,
    retry: false,
    onError: (error) => {
      // error 401 Unauthorized
      if (error.status === 401) {
        logout();
        showToast({
          show: true,
          type: 'error',
          message: t('Message.auth.error.session_expired'),
        })
      }
    }
  })

  return {
    SystemInfo
  }
}

export default GeneralQuery