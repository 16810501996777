import { FaCheckCircle, FaExclamationCircle, FaInfoCircle, FaTimesCircle } from 'react-icons/fa';
import { classNames } from '../../config/utils';
import { motion } from 'framer-motion';

const toastPosition = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

const toastTypeClass = {
  success: 'border-green-500',
  error: 'border-red-500',
  warning: 'border-yellow-500',
  info: 'border-blue-500',
};
const toastTypeIcon = {
  success: <FaCheckCircle size={25} className="text-green-500" />,
  error: <FaTimesCircle size={25} className="text-red-500" />,
  warning: <FaExclamationCircle size={25} className="text-yellow-500" />,
  info: <FaInfoCircle size={25} className="text-blue-500" />,
};
const toastPositionClass = {
  'top-left': 'top-0 left-0',
  'top-right': 'top-0 right-0',
  'bottom-left': 'bottom-0 left-0',
  'bottom-right': 'bottom-0 right-0',
};

export const Toast = ({ show, type, message, position = toastPosition[1] }) => {
  if (!show) return null;
  return (
    <motion.div
      initial={{ translateX: 500, opacity: 0 }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{ translateX: 500, opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(
        'w-[320px] rounded-xl border-2 p-4 m-4 flex items-center space-x-4 shadow-md fixed transition duration-300 bg-white',
        toastTypeClass[type] || '',
        toastPositionClass[position] || 'top-0 right-0',
        show
          ? 'translate-x-0 opacity-100'
          : position.includes('top-right') || position.includes('bottom-right')
          ? 'translate-x-full opacity-0'
          : '-translate-x-full opacity-0',
      )}
      style={{ zIndex: 1000 }}
    >
      {toastTypeIcon[type]}
      <span className="text-sm text-gray-800">{message}</span>
    </motion.div>
  );
};
