import { useMemo } from 'react';
import { useQuery } from 'react-query';
import useAuthContext from '../../../hooks/useAuthContext';
import { getFolders } from '../../../services/library/LibraryService';

export const useLibrary = (library) => {
  const { user } = useAuthContext();
  const token = user?.access_token;
  const type = library === 'portrait' ? 'frontPage' : library;

  //llamada a carpetas no archivadas
  const { data: dataFolder, isLoading: isLoadingFolder } = useQuery([library],
    () => getFolders({ token, type: type, archived: 0 }),
    {
      onError: (err) => {
        console.log(err)
      },
      staleTime: 5000
    });

  //llamadas a carpetas archivadas
  const { data: dataArchived, isLoading: isLoadingArch } = useQuery([`${library}_arch`],
    () => getFolders({ token, type: type, archived: 1 }),
    {
      onError: (err) => {
        console.log(err)
      }
    });

  const rootFolderLoaded = useMemo(() => {
    // return a boolean if the root folder is loaded
    return Boolean(dataFolder?.data[0])
  }, [dataFolder]);

  const root = useMemo(() => {
    return {
      rootFolder: dataFolder?.data[0] || {},
      rootArchived: dataArchived?.data[0] || {},
      isLoading: isLoadingFolder || isLoadingArch,
      rootFolderLoaded
    }
  }, [dataFolder, dataArchived, isLoadingFolder, isLoadingArch]);
  
  return root;
};
