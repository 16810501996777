import { classNames } from '../../../../config/utils';
import { useEffect, useRef } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';

const CameraPreview = () => {
  const { previewStream: stream, status } = useReactMediaRecorder({ video: true, askPermissionOnMount: true, stopStreamsOnStop: false, audio: false });
  
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }

    const handleError = (e) => {
      console.log('error', e);
    }

    if (stream) {
      stream.addEventListener('error', handleError);
    }

    return () => {
      if (stream) {
        let tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        stream.removeEventListener('error', handleError);
      }
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [stream]);

  return (
    <div
      className={classNames("overflow-hidden relative duration-100", stream?.active ? 'w-60 h-60' : 'w-0 h-0')}
      style={{
        borderTopLeftRadius: '25%',
        borderTopRightRadius: '25%',
        borderBottomLeftRadius: '25%',
      }}
    >
      <video ref={videoRef} autoPlay className="h-full object-cover z-10" />
      { (!videoRef.current || !stream?.active)  && (
        <div className="absolute inset-0 bg-gray-800">
          <div className="flex justify-center items-center h-full">
            <div className="flex flex-col justify-center items-center">
              <div className="relative w-10 h-10">
                <div className="absolute inset-0 animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-100" />
                <div className="absolute inset-0 animate-ping rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-100" />
              </div>
              <div className="text-white text-lg font-semibold mt-2">Cargando...</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

export default CameraPreview;
