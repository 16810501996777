import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '../../components/library';
import { Title } from '../../components/common';
import { AddTagListModal } from '../../components/contacts/AddTagListModal';
import ClientTag from '../../components/contacts/ClientTag';
import ClientList from '../../components/contacts/ClientList';
import { useLocation } from 'react-router-dom';

export function ManageList() {
  
  /* Hooks */
  const {t} = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get('tag') ? 1 : 0 ;

  /**  Consts **/
  const tabsList = [
    {
      id: 1,
      title: t('Contact.manage_list_tag.tabs.lists'),
      selected: true,
    },
    {
      id: 2,
      title: t('Contact.manage_list_tag.tabs.tags'),
      selected: false,
    },
  ];

  /* State */
  const [tabs, setTabs] = useState(tabsList);
  const [tabIndex, setTabIndex] = useState(0);

  /* Functions */
  const handleSelect = (item) => {
    item.selected = true;
    const index = tabs.findIndex((tab) => tab.id === item.id);
    const newItemStatus = tabs.map((tab) => {
      if (tab.id !== item.id) tab.selected = false;
      return tab;
    });
    setTabs([...newItemStatus]);
    setTabIndex(index);
  };

  // effects
  useEffect(() => {
    handleSelect(tabs[tabParam]);
  }, [tabParam]);

  return (
    <>
      <Title title={t('Contact.manage_list_tag.meta')} />
      <div className="mt-[10px] 2xl:mt-[28px]">
        <Tabs tabs={tabs} onSelect={handleSelect} />
        {tabIndex === 0 ? <ClientList /> : <ClientTag />}
        <AddTagListModal />
      </div>
    </>
  );
}
