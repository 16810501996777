import { PlayCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { DOMAIN } from '../../routes/server/paths';
import { DialogV2 } from '../../components/common';
import { useTranslation } from 'react-i18next';

const exampleResponseEachFile = {
  id: 1045,
  title: 'Nuevo video',
  file: 'videos/69/outputs/65842ed781652.mp4',
  thumbnail: null,
  clients: [],
  created_at: '2023-12-21T12:25:59.000000Z',
};

export function Item({ item }) {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const [imageNotFound, setImageNotFound] = useState(false);

  return (
    <div className="w-full aspect-video rounded-lg p-2 relative bg-gray-500 group select-none">
      <div 
        className="absolute inset-0 z-20 p-2 rounded-lg transition flex flex-col items-end backdrop-brightness-50 cursor-pointer group-hover:backdrop-brightness-75 group-active:backdrop-brightness-100" 
        onClick={() => setShowPreview(true)}
      />
      <h3 
        className="absolute top-0 left-0 z-20 p-2 text-white font-bold text-sm truncate" 
        style={{
          maxWidth: 'calc(100% - 5px)'
        }}
      >
        {item.title}
      </h3>
      <div
        className="absolute -m-4 z-10 inset-x-0 flex justify-center cursor-pointer"
        style={{
          top: 'calc(50%)',
        }}
      >
        <PlayCircleIcon
          className="w-9 h-9 text-gray-primary cursor-pointer"
        />
      </div>

      <div className="z-0 overflow-hidden absolute inset-0">
        {item.thumbnail && !imageNotFound && (
          <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0 z-10 rounded-lg group-hover:scale-110 duration-500"
          >
            <img 
              src={DOMAIN + item.thumbnail}  
              className="w-full h-full object-cover rounded-lg" 
              onError={() => setImageNotFound(true)}
            />
          </motion.div>
        )}
      </div>

      <DialogV2 open={showPreview} onClose={() => setShowPreview(false)}>
        {item.file ? (
          <div className="relative w-full h-full mt-1">
            <video className="w-full h-full" controls autoPlay src={DOMAIN + item.file} type="video/mp4" />
          </div>
        ) : (
          <div className="relative w-full h-full">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="text-2xl font-bold text-gray-500">
                {t('Message.video.error.cant_not_play')}
              </p>
              <p className="text-sm font-light text-gray-500">
                {t('Message.video.error.not_found')}
              </p>
            </span>
          </div>
        )}
      </DialogV2>
    </div>
  );
}
