

const ProgressBar = ({ progress = 0 }) => {
  return (
    <div className="relative w-full h-2 z-20 bg-gray-200 rounded-md cursor-default">
      <div
        className="absolute top-0 left-0 h-full bg-primary rounded-md"
        style={{ width: `${progress}%` }}
      ></div>
      {/* add a small circle in the progress bar position */}
      <div
        className="absolute top-0 left-0 w-[19px] h-[19px] bg-primary rounded-full border-[3px] border-white shadow-lg"
        style={{ left: `${progress}%`, top: '50%', transform: 'translate(-50%, -50%)' }}
      ></div>
    </div>
  );
};

export default ProgressBar;