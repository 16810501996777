import { useEffect, useRef, useState } from 'react';
import {
  refetch,
  resetStore,
  setAcceptAction,
  setElements,
  setLabelFor,
  setOpen,
  setRefetch,
} from '../../features/common/commonSlice';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AddList, associatedList, updateList } from '../../services/client/TagService';
import { useDispatch, useSelector } from 'react-redux';
import useAuthContext from '../../hooks/useAuthContext';
import { useTranslation } from 'react-i18next';
import Routes from '../../routes/app/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { setStep } from '../../features/client/clientSlice';
import { ContextMenu, HeaderControl } from './ClientItems';
import { useToast2 } from '../../hooks/useToast2';
import ClientSkeleton from './ClientSkeleton';
import { classNames } from '../../config/utils';

export default function ClientList() {
  /* State */
  const [selectedItem, setSelectedItem] = useState([]);
  const [lists, setLists] = useState([]);
  const [query, setQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const inputRefs = useRef([]);
  const [order, setOrder] = useState({
    column: 'name',
    type: 'desc',
  });
  const [showMenu, setShowMenu] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  /* Hooks */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refetching = useSelector(refetch);
  const { user } = useAuthContext();
  const { showToast } = useToast2();
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idParam = Number(searchParams.get('id'));


  useEffect(() => {
    setSelectedItem([]);
  }, []);

  /* Querys */
  const { refetch: refetchList, isLoading } = useQuery(
    ['ASSOCIATED_LIST', { token: user.access_token }],
    associatedList,
    {
      onError: (error) => {
        showToast({ show: true, type: 'error', message: t('Message.client.error.load_all') });
      },
      onSuccess: (response) => {
        const { data } = response?.data || {};
        setLists(data || []);

        data?.map((item) => {
          item.selected = false;
          item.prevText = item.label;
          return item;
        });

        setIsEditing(false);
        dispatch(resetStore());
        setFilteredList(data.sort((a, b) => (a.label > b.label ? -1 : 1)));
      },
    },
  );

  /* Mutations */
  const mutationUpdateList = useMutation('UPDATE_LIST', updateList, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: t('Message.client.error.update') });
    },
    onSuccess: (response) => {
      refetchList();
      setTimeout(
        () => showToast({ show: true, type: 'success', message: t('Message.client.success.update') }),
        1000,
      );
      setIsEditing(false);
    },
  });

  const mutationListAdd = useMutation('CREATE_LIST_ITEM', AddList, {
    onError: (error) => {
      showToast({ show: true, type: 'error', message: error });
    },
    onSuccess: (data) => {
      showToast({ show: true, type: 'success', message: t('Message.client.success.created_list') });
      dispatch(setRefetch(true));
      setIsEditing(false);
    },
  });

  /* Functions */
  const handleMenuItem = (list, item) => {
    setShowMenu(false);
    switch (item.title) {
      case t('Contact.manage_list_tag.options.rename'): {
        list.selected = true;
        setLists([...lists]);
        const index = lists.findIndex((item) => item.value === list.value);
        setTimeout(() => inputRefs.current[index].select(), 100);
        setIsEditing(false);
        break;
      }
      case t('Contact.manage_list_tag.options.delete'): {
        dispatch(setOpen(true));
        dispatch(setLabelFor('List'));
        dispatch(setAcceptAction('removeList'));
        dispatch(setElements([list.value]));
        setIsEditing(false);
        break;
      }
      default:
        break;
    }
  };

  const add = async (item) => {
    item.label.length > 20
      ? showToast({
        show: true,
        type: 'error',
        message: t('Message.client.error.list_size'),
      })
      : mutationListAdd.mutate({ token: user.access_token, list: { title: item.label } });
  };

  const update = async (item) => {
    const list = {
      id: item.value,
      title: item.label,
    };

    if (item.label.length > 20) {
      showToast({
        show: true,
        type: 'error',
        message: t('Message.client.error.list_size'),
      });
    } else {
      mutationUpdateList.mutate({ token: user.access_token, list });
      item.selected = false;
      setLists([...lists]);
    }
  };

  /* Consts */
  const menuOptions = [
    { id: 1, title: t('Contact.manage_list_tag.options.rename') },
    { id: 2, title: t('Contact.manage_list_tag.options.delete') },
  ];

  const setItemLabel = (list, val) => {
    list.label = val;
    list.newText = val;

    setLists([...lists]);
  };

  const cancelEditing = (item) => {
    item.selected = false;
    item.label = item.prevText;
    setIsEditing(false)
    setLists([...lists]);
  };

  if (refetching) {
    refetchList();
  }

  const handleSearchQuery = (e) => {
    const value = e.target.value;
    setQuery(value);
    const newList =
      value === ''
        ? lists
        : lists.filter((list) => list.label.toLowerCase().includes(value.toLowerCase()));
    setFilteredList(newList);
  };

  const createList = () => {
    const element = {
      value: -1,
      label: t('Contact.manage_list_tag.new_element'),
      selected: true,
      created: moment().format('DD/MM/YYYY'),
    };
    setFilteredList([element, ...filteredList]);
    setIsEditing(true);

    setTimeout(() => {
      inputRefs.current[0]?.select();
    }, 100);
  };

  const handleKeyDown = (e, list) => {
    if (e.keyCode === 13) {
      if (list.value !== -1) {
        update(list);
      } else {
        add(list);
      }
    } else if (e.keyCode === 27) {
      if (list.value === -1) {
        const newList = filteredList.filter((item) => item.value !== list.value);
        setFilteredList([...newList]);
      } else {
        cancelEditing(list);
      }
    }
  };

  const removeItems = () => {
    if (selectedItem.length > 0) {
      const ids = selectedItem.map((list) => list.value);

      dispatch(setLabelFor('lista'));
      dispatch(setOpen(true));
      dispatch(setElements(ids));
      dispatch(setAcceptAction('removeList'));
      setSelectedItem([]);
    }
  };

  const orderTable = () => {
    let orderType = 'asc';

    if (order.type === 'asc') {
      orderType = 'desc';
    }

    // Actualizamos el estado del orden
    setOrder({
      column: 'label',
      type: orderType,
    });

    // Creamos una copia de los clientes para no modificar el estado directamente
    const orderedList = [...filteredList];

    orderedList.sort((a, b) => {
      if (String(a.label).toLowerCase() < String(b.label).toLowerCase()) {
        return orderType === 'asc' ? -1 : 1;
      }
      if (String(a.label).toLowerCase() > String(b.label).toLowerCase()) {
        return orderType === 'asc' ? 1 : -1;
      }
      return 0;
    });

    // Actualizamos el estado con la tabla ordenada
    setFilteredList(orderedList);
  };

  // effects
  useEffect(() => { //scroll to the element when is searched
    if (!idParam || !filteredList?.length) return;
    let counter = 0;
    let element = null;
    const intervalId = setInterval(() => {
      if (counter === 20 || element) clearInterval(intervalId);
      counter ++;
      element = document.getElementById(idParam);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        clearInterval(intervalId);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    }
  }, [idParam, filteredList]);

  const isAgente = user?.role === 'Agente';
  
  return (
    <div>
      {HeaderControl(
        t('Contact.manage_list_tag.list'),
        orderTable,
        query,
        handleSearchQuery,
        createList,
        selectedItem,
        removeItems,
        isEditing,
      )}

    <div className='h-[55vh] overflow-y-auto px-1'>
        {isLoading && Array.from({ length: 4}).map((_, index) => (
          <ClientSkeleton key={`list-client-${index}`} />
        ))}
        {filteredList.map((list, index) => (
          <div id={list.value} key={index} className={classNames("my-3 bg-gray-list py-1.5 px-4 rounded-xl flex justify-between",
            idParam === list.value && 'border-[1px] border-primary shadow-md shadow-primary')}>
            <div className="flex space-x-3 items-center w-full">
              <div>
                <input
                  className="rounded w-5 h-5 cursor-pointer border-gray-200 focus:ring-primary text-primary disabled:opacity-0 disabled:cursor-default"
                  type="checkbox"
                  disabled={isAgente}
                  checked={selectedItem.includes(list)}
                  onChange={(e) =>
                    setSelectedItem(
                      e.target.checked
                        ? [...selectedItem, list]
                        : selectedItem.filter((p) => p !== list),
                    )
                  }
                />
              </div>
              {!list.selected ? (
                <div className="py-[4px] flex flex-col">
                  <span className="font-bold text-gray-soft text-sm 2xl:text-md">{list.label}</span>
                  <span className="text-xs 2xl:text-sm font-light text-gray-soft -mt-1">
                    {`${t('Contact.manage_list_tag.created_on')} ${list.created}`}
                  </span>
                </div>
              ) : (
                <div className="flex py-[4px] flex-col">
                  <input
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    onKeyDown={(e) => handleKeyDown(e, list)}
                    onChange={(e) => setItemLabel(list, e.target.value)}
                    className="font-bold px-1 bg-white rounded-md focus:rounded-gray-300 focus:ring-1 focus:ring-gray-200 text-sm 2xl:text-md"
                    value={list.label}
                  />
                  <span className="text-xs 2xl:text-sm font-light text-gray-soft">
                    {`${t('Contact.manage_list_tag.created_on')} ${list.created}`}
                  </span>
                </div>
              )}
            </div>

            <div className="flex justify-center items-center space-x-2">
              {!isAgente && <div className="relative inline-block text-left">
                <div className="flex items-center">
                  <div className="inline-flex w-full justify-center items-center  gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 ">
                    <EllipsisHorizontalIcon
                      className="w-8 2xl:w-10 h-8 2xl:h-10 cursor-pointer text-gray-600 rounded-full p-0 hover:shadow-md active:text-primary active:scale-[0.97]"
                      onClick={() =>
                        setShowMenu((prev) => ({ ...prev, [index]: !prev[index] || true }))
                      }
                    />
                  </div>
                </div>

                <ContextMenu
                  showMenu={showMenu}
                  setShowMenu={setShowMenu}
                  index={index}
                  menuOptions={menuOptions}
                  handleMenuItem={handleMenuItem}
                  list={list}
                />
              </div>}
              <button
                onClick={() => {
                  dispatch(setStep(0));
                  navigate(`${Routes.contacts}?filter=list&id=${list.value}`);
                }}
                className="bg-gray-soft text-white text-xs 2xl:text-sm rounded-lg px-8 py-1.5 hover:shadow-md active:text-primary-lighter active:scale-[0.97]"
              >
                {t('Contact.manage_list_tag.show')}
              </button>
            </div>
          </div>
        ))}
        {filteredList.length === 0 && !isLoading && <span className='text-gray-600 italic'>No hay un listado que mostrar, cree uno nuevo</span>}
      </div>
    </div>
  );
}
