import ApiRoutes, { getImg, setFrame } from '../../routes/api/paths';
import { headers, headersFile } from '../../config/headers';
import { createCancellablePromise } from '../HandlerServices';


const handleResponse = async (response) => {
  if (!response.ok) {
    return Promise.reject(response.status);
  }
  const data = await response.json();
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve({
      status: response.status,
      data: data.data || data,
    });
  }
};

const sendRequest = async (url, requestOptions) => {
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      return Promise.reject({
        status: error,
      });
    });
};

/* FILES */
// delete files
export const deleteFiles = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({ ids: params.ids, type: params.type }),
  };
  return sendRequest(ApiRoutes.deleteFiles, requestOptions);
};

// move files
export const moveFiles = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify({ ids: params.ids, type: params.type, newFolderId: params.newFolderId }),
  };
  return sendRequest(ApiRoutes.moveFiles, requestOptions);
};

// Get folders
export const getFolders = async (params) => {
  const { token, type, archived } = params;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listFolders(type, archived), requestOptions);
};

// List videos of a folder
export const getVideosFolder = async (params) => {
  const { token, id } = params;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listVideosFolder(id), requestOptions);
};

// Add folder
export const postAddFolder = async (params) => {
  const { token, name, type, parentId } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ name, type: type === 'portrait' ? 'frontPage' : type, parentId }),
  };
  return sendRequest(ApiRoutes.addFolder, requestOptions);
};

// Delete folder
export const deleteFolder = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({ ids: params.ids }),
  };
  return sendRequest(ApiRoutes.deleteFolder, requestOptions);
};

// Rename folder
export const putRenameFolder = async (params) => {
  const { token, id, name } = params;
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify({ id, name }),
  };
  return sendRequest(ApiRoutes.renameFolder, requestOptions);
};

// Move folder
export const putMoveFolder = async (params) => {
  const { token, folderId, newParentId, type } = params;
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify({ folderId, newParentId, type }),
  };
  return sendRequest(ApiRoutes.moveFolder, requestOptions);
};

// Duplicate folder
export const postDuplicateFolder = async (params) => {
  const { token, id } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ id }),
  };
  return sendRequest(ApiRoutes.duplicateFolder, requestOptions);
};

// Change status of folder archiving
export const putStatusFolder = async (params) => {
  const { token, folderId, action } = params;
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.updateStatusFolder(folderId, action), requestOptions);
};

// List portraits
export const getPortraits = async (params) => {
  const { token, folderId } = params;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listPortraits(folderId), requestOptions);
};

// List portraits by user
export const getPortraitsByUser = async (params) => {
  const { token, archived } = params;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listPortraitsByUser(archived), requestOptions);
};

// Add portrait
export const postAddPortrait = async (params, onProgress = () => {}, isCancellable) => {
  const { token, title, folderId, image } = params;
  const formData = new FormData();
  formData.append('title', title);
  formData.append('folder_id', folderId);
  formData.append('image', image);

  const xhr = new XMLHttpRequest();
  xhr.open('POST', ApiRoutes.addPortrait);
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100;
      onProgress({
        progress,
        total: event.total,
      });
    }
  };

  const promise = new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.responseText);
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };

    xhr.send(formData);
  });

  if (isCancellable) return createCancellablePromise(promise, xhr);
  return promise;
};

export const postAddMultiplePortraits = async (params, setProgress) => {
  const { token, titles, folderId, images } = params;

  titles.forEach((title, index) => {
    postAddPortrait({ token, title, folderId, image: images[index] }, (dataProgress) => {
      setProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        newProgress[index] = { ...dataProgress, title };
        return newProgress;
      });
    });
  });
};

export const postSelectedFrame = async (params) => {
  const { token, name, video_id, current_time, type, folderId } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ name, video_id, current_time, type, folderId }),
  };
  return sendRequest(setFrame, requestOptions);
}

// Update portrait
export const postUpdatePortrait = async (params) => {
  const { token, name, portraitId, image } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ name, image }),
  };
  return sendRequest(ApiRoutes.updatePortrait(portraitId), requestOptions);
};

export const postGetImg = async (params) => {
  const { token, id, type } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ id, type }),
  };
  return fetch(getImg, requestOptions);
};

export const postUpdateImagePortrait = async (params, onProgress = () => {}) => {
  const { token, name, portraitId, image } = params;
  const formData = new FormData();
  formData.append('name', name);
  formData.append('image', image);

  const xhr = new XMLHttpRequest();
  xhr.open('POST', ApiRoutes.updatePortrait(portraitId));
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100;
      onProgress({
        name,
        progress,
        total: event.total,
        preview: URL.createObjectURL(image),
      });
    }
  };

  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.responseText);
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };

    xhr.send(formData);
  });
};

// Delete portrait
export const deletePortrait = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify({ ids: params.ids }),
  };
  return sendRequest(ApiRoutes.deletePortrait, requestOptions);
};

// Move portrait
export const postMovePortrait = async (params) => {
  const { portraitsIds, folderId } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify({ portada_ids: portraitsIds, folder_id: folderId }),
  };
  return sendRequest(ApiRoutes.movePortrait, requestOptions);
};

// Change status of portrait archiving
export const putStatusPortrait = async (params) => {
  const { token, portraitId } = params;
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.updateStatusPortrait(portraitId), requestOptions);
};

// List clips
export const getClips = async (params) => {
  const { token, folderId } = params;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listClips, requestOptions);
};

// Add clip
export const postAddClip = async (params, onProgress, isCancellable) => {
  // The parameters thumbnail and description are optional
  const { token, title, clip, folderId, thumbnail, description } = params;
  const formData = new FormData();
  formData.append('title', title);
  formData.append('clip', clip);
  formData.append('folder_id', folderId);
  thumbnail && formData.append('thumbnail', thumbnail);
  description && formData.append('description', description);

  const xhr = new XMLHttpRequest();
  xhr.open('POST', ApiRoutes.addClip);
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100;
      onProgress({
        progress, 
        total: event.total,
      });
    }
  };

  const promise = new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.responseText);
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };

    xhr.send(formData);
  });

  if (isCancellable) return createCancellablePromise(promise, xhr);
  return promise;
};

//add multiple clips
export const postAddMultipleClips = async (params, setProgress) => {
  const { token, titles, clips, folderId, thumbnails, descriptions } = params;

  titles.forEach((title, index) => {
    postAddClip(
      {
        token,
        title,
        folderId,
        clip: clips[index],
        thumbnail: thumbnails[index],
        description: descriptions[index],
      },
      (dataProgress) => {
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[index] = { ...dataProgress, title };
          return newProgress;
        });
      },
    );
  });
};

// Update clip
export const putUpdateClip = async (params) => {
  const { token, clipId, title, description } = params;
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify({ id: clipId, title, description }),
  };
  return sendRequest(ApiRoutes.updateClip, requestOptions);
};

// Delete clip
export const deleteClip = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({ clip_id: params.clipId }),
  };
  return sendRequest(ApiRoutes.deleteClip, requestOptions);
};

// Change status of clip archiving
export const postStatusClip = async (params) => {
  const { token, clipId } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.updateStatusClip(clipId), requestOptions);
};

// Move clip
export const postMoveClip = async (params) => {
  const { token, clipId, targetFolderId } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ clip_id: clipId, target_folder_id: targetFolderId }),
  };
  return sendRequest(ApiRoutes.moveClip, requestOptions);
};

// List recordings
export const getRecordings = async (params) => {
  const { token, folderId } = params;
  const requestOptions = {
    method: 'GET',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.listRecordings(folderId), requestOptions);
};

// Add recording
export const postAddRecording = async (params, onProgress = () => {}, isCancellable) => {
  const { token, title, screen, camera, audio, folderId, video_duration, time_screen, time_camera } = params;

  const formData = new FormData();
  if (title) formData.append('title', title);

  // parse mediaBlobUrl to binary
  const screenBlob = screen.split('blob:').length 
    ? await fetch(screen).then((r) => r.blob())
    : screen;
  const screenVideo = new File([screenBlob], 'filename.webm', { type: 'video/webm;codecs=vp9' });
  formData.append('screen', screenVideo);
    
  if (camera) {
    const cameraBlob = camera?.split('blob:').length
      ? await fetch(camera).then((r) => r.blob())
      : camera;

    const cameraVideo = new File([cameraBlob], 'filename.webm', { type: 'video/webm;codecs=vp9' });
    formData.append('camera', cameraVideo);    
  }
  
  if (folderId) formData.append('folder_id', folderId);
  formData.append('audio_screen', audio.screen);
  formData.append('audio_camera', audio.camera);
  formData.append('video_duration', Number(video_duration).toFixed(0)-1); //no decimals, only seconds, and -1 second to avoid a time bigger to the video duration

  const xhr = new XMLHttpRequest();
  xhr.open('POST', ApiRoutes.addRecording);
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100;
      onProgress({
        progress,
        total: event.total,
      });
    }
  };

  const promise = new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.responseText);
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };

    xhr.send(formData);
  });

  if (isCancellable) return createCancellablePromise(promise, xhr);
  return promise;
};

// Update recording
export const putUpdateRecording = async (params) => {
  const { token, recordingId, title, description } = params;
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify({ title, description }),
  };
  return sendRequest(ApiRoutes.updateRecording(recordingId), requestOptions);
};

// Delete recording
export const deleteRecording = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
  };
  return sendRequest(ApiRoutes.deleteRecording(params.recordingId), requestOptions);
};

// Move recording
export const postMoveRecording = async (params) => {
  const { token, recordingId, targetFolderId } = params;
  const requestOptions = {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify({ recording_id: recordingId, target_folder_id: targetFolderId }),
  };
  return sendRequest(ApiRoutes.moveClip, requestOptions);
};

// Change status of recording archiving
export const postStatusRecording = async (params) => {
  const { token, recordingId } = params;
  const requestOptions = {
    method: 'PUT',
    headers: headers(token),
  };
  return sendRequest(ApiRoutes.updateStatusRecording(recordingId), requestOptions);
};
