import { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { encryptStorage } from '../config/encryptStorage';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { logout as logoutClient } from '../features/client/clientSlice';
import { logout as logoutLibrary } from '../features/library/librarySlice';
import { logout as logoutCommon } from '../features/common/commonSlice';
import { logout as logoutVideos } from '../features/videos/videosSlice';
// config

const AUTH_APP = 'AUTH_APP';
const USER = 'USER';

export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(encryptStorage.getItem(AUTH_APP) || false);
  const [user, setUser] = useState(encryptStorage.getItem(USER));
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const login = useCallback(async (user) => {
    setUser(user);
    encryptStorage.setItem(AUTH_APP, true);
    encryptStorage.setItem(USER, JSON.stringify(user));
    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    encryptStorage.removeItem(AUTH_APP);
    encryptStorage.removeItem(USER);
    setIsAuthenticated(false);
    // clean react-query
    queryClient.clear();
    // clean redux
    dispatch(logoutClient());
    dispatch(logoutLibrary());
    dispatch(logoutCommon());
    dispatch(logoutVideos());
  }, []);

  const updateUser = useCallback(async (newUser) => {
    setUser(newUser);
    encryptStorage.setItem(USER, JSON.stringify(newUser));
  }, []);

  const value = useMemo(
    () => ({
      login,
      logout,
      updateUser,
      isAuthenticated,
      user,
    }),
    [login, logout, updateUser, isAuthenticated, user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
  children: PropTypes.any,
};
